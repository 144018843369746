import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-lazy-load',
  templateUrl: './lazy-load.component.html',
  styleUrls: ['./lazy-load.component.scss']
})
export class LazyLoadComponent implements OnInit {

  @Input() isLoaded: boolean;
  @Input() sectionName: string;

  constructor() { }

  ngOnInit() {
   // alert(this.isLoaded);
  }

}
