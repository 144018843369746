import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';

import { CustomCode } from '../shared/CustomCode';
import { MyAccountMonthlyParkingService } from '../services/my-account-monthly-parking.service';
import { ToastrService } from 'ngx-toastr';
import { UserserviceService } from '../services/userservice.service';

@Component({
  selector: 'app-monthlyparkingaccount',
  templateUrl: './monthlyparkingaccount.component.html',
  styleUrls: ['./monthlyparkingaccount.component.scss'],
  providers: [MyAccountMonthlyParkingService, UserserviceService]
})
export class MonthlyparkingaccountComponent implements OnInit {

  // Input Parameters for monthly parking user account number and module

  isLoaded = false;

  formData: any = {
  };
  loggedUser:any;

  accountNumber: number;
  profileModule: string;

  monthlyParkingAccounts: any = {};
  activeUser: any = {};

  activeAccount: any = {};

  showMonthlyParking = true;
  showInnerPages = false;

  noMonthlyAccount = false;
  myaccountLinksArr:any;
  showClassColorActive=0;
  showClassColorInActive=0;
  showornot:boolean=true;
  isTierLoaded:boolean=false;
  dropDownText='Current Balance'
  types='usd';
  constructor(
    private router: Router,
    private activatedRouteObj: ActivatedRoute,
    private toastr: ToastrService,
    private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
    private userserviceServiceObj: UserserviceService) {
  }
  

  ngOnInit() {


    

    this.myaccountLinksArr = [
      "my-account/monthly-parking",
      "current-balance","auto-pay","current-invoice","previous-invoices","make-payment",
      "payment-methods","payment-history","billing-information","frequently-asked-questions",
      "loyalty-club","delete-account","account-cancellation-notice"
    ]

    if(window.location.href.indexOf('monthly-parking/account') > -1){
      let sectionSlug = window.location.href.split('/').slice(-1)[0];
      let sectionSlug_Account = window.location.href.split('/').slice(-2)[0];
      if(this.myaccountLinksArr.indexOf(sectionSlug) > -1){
        //return true;
      }else{
        this.router.navigate(['/my-account','monthly-parking','account',sectionSlug_Account,'current-balance'])        
      }
      
    }

    $(".catValue li a").click(function(){
      $(".linkLabel div").html($(this).html());
      $(this).parent().addClass('active').siblings().removeClass('active');
      $(".catValue").parent('.selectBox').removeClass('open');
    });
    var fn = function(){ $(".catSub li a").click(function(){       
       $(".linkSub div").html($(this).html());
       $(this).parent().addClass('active').siblings().removeClass('active');
       //$(".catValue").hide();
       $(this).parents('.catSub').parent('.selectBox.open').removeClass('open');
    });};
    window.onload = function() { setInterval(fn,500)};
    this.isLoaded = false;
    this.noMonthlyAccount = false;
    this.activatedRouteObj.params.subscribe((params: Params) => {
      if (params['account'] && params['module']) {
        this.accountNumber = params['account'];
        this.profileModule = params['module'];
      }
      if (this.accountNumber && this.profileModule) {
        this.showMonthlyParking = false;
        this.showInnerPages = true;
        this.fetchMonthlyParkingAccount();
      } else {
        this.fetchUserDetails();
      }
    });
    this.loggedUser = CustomCode.getloggedInUserDetails();
    
  }
  ngAfterViewInit() {
    if(this.router.url.indexOf('current-balance') > -1){
      this.textChahge('Current Balance',1,'usd')
    }else if(this.router.url.indexOf('auto-pay') > -1){
      this.textChahge('Manage Autopay',2,'calendar-check-o')
    }else if(this.router.url.indexOf('current-invoice') > -1){
      this.textChahge('Current Invoice',3,'file-text-o')
    }else if(this.router.url.indexOf('previous-invoices') > -1){
      this.textChahge('Previous Invoice',4,'files-o')
    }else if(this.router.url.indexOf('make-payment') > -1){
      this.textChahge('Make Payment',5,'credit-card')
    }else if(this.router.url.indexOf('payment-methods') > -1){
      this.textChahge('Payment Methods',6,'money')
    }else if(this.router.url.indexOf('payment-history') > -1){
      this.textChahge('Payment History',7,'history')
    }else if(this.router.url.indexOf('billing-information') > -1){
      this.textChahge('Billing Information',8,'university')
    }else if(this.router.url.indexOf('frequently-asked-questions') > -1){
      this.textChahge('FAQ',9,'question-circle')
    }else if(this.router.url.indexOf('loyalty-club') > -1){
      this.textChahge('Preferred Customer Program',10,'star')
    }else if(this.router.url.indexOf('delete-account') > -1){
      this.textChahge('Unlink Account',11,'trash-o')
    }else{
      this.textChahge('Cancellation Notice',12,'ban')
    }
	}
  logOut() {
    CustomCode.userLogout();
    this.router.navigate(['/']);
  }
  fetchUserDetails() {
    this.showornot=false
    this.myAccountMonthlyParkingObj.fetchUser()
    .then(
      data => {
        this.activeUser = data['data'];
        let inactive:any=0;
        let active:any=0;
        let activenumber=0;
        let inactivenumber=0;
        let acvtiveInc=0;
        let inActiveInc=0
        this.showClassColorActive=1
        this.showClassColorInActive=1
        setTimeout(()=>{
          this.showornot=true;
          for(let j=0;j<this.activeUser.monthly_parking_facilities.length;j++)
        {
          inactive=0;
          active=0;
          for(let p=0;p<this.activeUser.monthly_parking_facilities[j].accounts.length;p++)
          {
            if(this.activeUser.monthly_parking_facilities[j].accounts[p].active==1)
            {
              active=1
              acvtiveInc++;
              activenumber=this.activeUser.monthly_parking_facilities[j].accounts[p].account_number
            }
            if(this.activeUser.monthly_parking_facilities[j].accounts[p].active==0)
            {
              inactive=1;
              inActiveInc++;
              inactivenumber=this.activeUser.monthly_parking_facilities[j].accounts[p].account_number
            }
          }
          $('#accountD'+activenumber).removeClass('dualAccount');
          $('#accountM'+activenumber).removeClass('dualAccount');
          $('#inaccountD'+inactivenumber).removeClass('dualAccount');
          $('#inaccountM'+inactivenumber).removeClass('dualAccount');
          if(inactive==0)
          {
            
            //alert('#active'+this.activeUser.monthly_parking_facilities[j].id)
            $('#inactive'+this.activeUser.monthly_parking_facilities[j].id).addClass('hide');
          }
          if(active==0)
          {
            $('#active'+this.activeUser.monthly_parking_facilities[j].id).addClass('hide');
          }
          if(inActiveInc==0)
          {
            this.showClassColorInActive=0;
          }else{
            this.showClassColorInActive=1;
          }
          if(acvtiveInc==0)
          {
            this.showClassColorActive=0;
          }else{
            this.showClassColorActive=1;
          }
        //  alert("inactive"+inactive+"active"+active+""+this.activeUser.monthly_parking_facilities[j].id)
        }
        if(inActiveInc==0)
        {
          this.showClassColorInActive=0;
        }else{
          this.showClassColorInActive=1;
        }
        if(acvtiveInc==0)
        {
          this.showClassColorActive=0;
        }else{
          this.showClassColorActive=1;
        }
      //  a
          },300)
          if(inactive==0)
          {
            this.showClassColorInActive=0;
          }
          if(active==0)
          {
            this.showClassColorActive=0
          }
        
        this.monthlyParkingAccounts = data;
        if (this.monthlyParkingAccounts['data'].monthly_parking_accounts.length === 0) {
          this.noMonthlyAccount = true;
        }
        this.isLoaded = true;
      },
      error => {
        this.isLoaded = true;
        this.toastr.error(error.errors.message, 'Sorry!');
        // alert(error.errors.message);
      }
    );
  }
  fetchMonthlyParkingAccount() {
    this.myAccountMonthlyParkingObj.getAccountDetails(this.accountNumber)
    .then(
      data => {
        this.isLoaded = true;
        this.activeAccount = data['data'];
      },
      error => {
        this.isLoaded = true;
        // alert(error.errors.message);
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }

  showAddMonthlyAccount() {
    this.router.navigate(['/my-account/monthly-parking/create-online-account']);
  }
  updateTier(tier){
    this.isTierLoaded = tier;
  }
  removeHeader()
  {
    sessionStorage.removeItem('menuActive')
  }
  addMenuactive()
  {
    sessionStorage.setItem('menuActive','2')
  }
  textChahge(text,id,type)
  {
    
    $('.drp').removeClass('active')
    $('#sub'+id).addClass('active')
    $('#drptext').text(text)
    this.types=type
  }
}
