import { Directive } from '@angular/core';
import * as $ from 'jquery';
declare var Swiper:any;



@Directive({
  selector: '[couponSlider]'
})
export class CouponSliderDirective {

  constructor() { }
  ngAfterViewInit(){


 var mySwiper = new Swiper ('.swiper-container', {
						pagination: '.swiper-pagination',
						paginationClickable: true,
						nextButton: '.swiper-button-next',
						prevButton: '.swiper-button-prev',
						iOSEdgeSwipeDetection:'true',
						autoplayDisableOnInteraction: false
					  
				});

			
}


}