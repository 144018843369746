import { Injectable } from '@angular/core';

//import { Http, Headers, RequestOptions, Response } from '@angular/http';
import {HttpClient,HttpHeaders} from '@angular/common/http';
//import { HttpService } from './http.service';
import { Observable } from 'rxjs/Rx';
import { CustomCode } from '../shared/CustomCode';
import { DomSanitizer } from '@angular/platform-browser';

import * as FileSaver from 'file-saver';

// Import RxJs required methods
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

constructor(private http: HttpClient) { }

private getUsersDetails = CustomCode.APIURL + 'api';
private getChangeUserPassword = CustomCode.APIURL + 'user/';

  
  fetchUser() {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersDetails).subscribe(
        res => {
			
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }
  //{old_password: "adadasdsadasd", password: "aaaaaaaa", password_confirmation: "aaaaaaaa"}
  changeuserpassword(chpadata,userid){
	  
	  //const headers = new Headers();
    const access_token = localStorage.getItem('access_token');
	
	const formDetails = {'old_password': chpadata.old_password,'password': chpadata.password,'password_confirmation': chpadata.password_confirmation};
	
    return this.http.post(this.getChangeUserPassword+userid+'/reset-password', formDetails, {headers: new HttpHeaders({'AuthorizationOkta':  access_token})})
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error.json()));  
	  
  }
  

}

