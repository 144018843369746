import { Injectable } from '@angular/core';

//import { Http, Headers, RequestOptions, Response, ResponseContentType } from '@angular/http';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {HttpService} from './http.service';

import { Observable } from 'rxjs/Rx';
import { CustomCode } from '../shared/CustomCode';
import { DomSanitizer } from '@angular/platform-browser';

import * as FileSaver from 'file-saver';

// Import RxJs required methods
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MyAccountMonthlyParkingService {

  constructor(private http: HttpClient, private http2: HttpClient) { }

  private getUsersDetails = CustomCode.APIURL + 'api';
  private getUsersMonthlyAccountURL = CustomCode.APIURL + 'monthly-parking/account/';
  private monthlyParkingRegisterURL = CustomCode.APIURL + 'mp/register';
  private fetchAttractionURL = CustomCode.APIURL + 'attraction/attractions/';
  private fetchFacilityURL = CustomCode.APIURL + 'facility/slug/';
  private getUsersMonthlyAccountCancelRequestUrl = CustomCode.APIURL;


  // Function/Services Used in Monthly Parking Account Component and Sub Components
  fetchUser() {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersDetails).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  // Function/Services Used in Monthly Parking Account Component and Sub Components
  getAccountDetails(accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersMonthlyAccountURL + accountNumber).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  monthlyParkingRegister(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.monthlyParkingRegisterURL, data).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  fetchAttraction(slug) {
    return new Promise((resolve, reject) => {
      this.http.get(this.fetchAttractionURL + slug).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  fetchFacility(slug) {
    return new Promise((resolve, reject) => {
      this.http.get(this.fetchFacilityURL + slug).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  autoPay(accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersMonthlyAccountURL + accountNumber + '/autopay').subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  setAutoPay(data, accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.put(this.getUsersMonthlyAccountURL + accountNumber + '/autopay', data).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  cancellationNotice(data, accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.post(this.getUsersMonthlyAccountURL + accountNumber + '/cancel', data).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  deleteAccount(accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.delete(this.getUsersMonthlyAccountURL + accountNumber).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  addCreditCard(data, accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.post(this.getUsersMonthlyAccountURL + accountNumber + '/payment-method', data).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  addBankAccount(data, accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.post(this.getUsersMonthlyAccountURL + accountNumber + '/payment-method', data).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  makePayment(data, accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.post(this.getUsersMonthlyAccountURL + accountNumber + '/payment', data).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  currentInvoice(accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersMonthlyAccountURL + accountNumber + '/current-invoice').subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  currentInvoiceJpg(accountNumber) {
    const headers = new Headers();
    const access_token = localStorage.getItem('access_token');
   // headers.append('Authorization', 'Bearer ' + access_token);
    //const options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersMonthlyAccountURL + accountNumber + '/current-invoice/jpg', { responseType: 'blob',headers: new HttpHeaders({'OktaTokenType':  'web','AuthorizationOkta':  access_token})}).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  currentInvoicePdf(accountNumber, date) {
    const headers = new Headers();
    const access_token = localStorage.getItem('access_token');
    headers.append('AuthorizationOkta', access_token);
    headers.append('Content-Type', 'application/pdf');
    //const options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob }); // Create a request option
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersMonthlyAccountURL + accountNumber + '/current-invoice/pdf', { responseType: 'blob',headers: new HttpHeaders({'OktaTokenType':  'web','AuthorizationOkta':  access_token})}).subscribe(
        (response: any) => {
            FileSaver.saveAs(response.blob(), 'invoice_' + date + '.pdf');
            resolve(response);
        });
    });
  }

  previousInvoices(accountNumber, pageNum) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersMonthlyAccountURL + accountNumber + '/past-invoice?page=' + pageNum).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  previousInvoiceJpg(accountNumber, date) {
    const headers = new Headers();
    const access_token = localStorage.getItem('access_token');
    headers.append('Authorization', 'Bearer ' + access_token);
    //const options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersMonthlyAccountURL + accountNumber + '/past-invoice/jpg/' + date, { responseType: 'blob',headers: new HttpHeaders({'Authorization': 'Bearer ' + access_token})}).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  previousInvoicePdf(accountNumber, date) {
    const headers = new Headers();
    const access_token = localStorage.getItem('access_token');
    headers.append('Authorization', 'Bearer ' + access_token);
    headers.append('Content-Type', 'application/pdf');
    //const options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersMonthlyAccountURL + accountNumber + '/past-invoice/pdf/' + date, { responseType: 'blob',headers: new HttpHeaders({'Authorization': 'Bearer ' + access_token})}).subscribe(
        (response: any) => {
                FileSaver.saveAs(response.blob(), 'invoice_' + date + '.pdf');
                resolve(response);
        });
    });
  }
  PaymentHistoryMail(id,account,email) {
    let url='';
    if(email=='')
    {
     url= CustomCode.APIURL + 'monthly-parking/account/' + account + '/' +id+ '/payment/email'
    }else{
     url= CustomCode.APIURL + 'monthly-parking/account/' + account + '/' +id+ '/payment/email?email='+email
    }
    return this.http.get(url)
    .map((res: Response) => res)
    .catch((error: any) => Observable.throw(error.json()));
  }
  paymentHistory(accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersMonthlyAccountURL + accountNumber + '/payment').subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  paymentMethods(accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersMonthlyAccountURL + accountNumber + '/payment-method').subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  // https://api.iconparkingsystems.com/monthly-parking/account/10001556/payment-method/1908810584
  deletePaymentMethod(accountNumber, profile_id) {
    return new Promise((resolve, reject) => {
      this.http.delete(this.getUsersMonthlyAccountURL + accountNumber + '/payment-method/' + profile_id).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }



  billingInformation(accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersMonthlyAccountURL + accountNumber + '/billing').subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  updatebillingInformation(data, accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.put(this.getUsersMonthlyAccountURL + accountNumber + '/billing', data).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  // get tenant number for cancilation request
  monthlyCancellationRequest(accountNumber){
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersMonthlyAccountCancelRequestUrl + 'get-tenants-slots/' + accountNumber).subscribe(
        res => {
          resolve(res
        );
        },
        error => {
          reject(error);
        }
      );
    });
  }

//  send data to cancel monthly marking request 
  
CancelRequestWithTenantNumber(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.getUsersMonthlyAccountCancelRequestUrl + 'cancel-tenants', data).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }




  }





