import { error } from 'util';
import { Component, OnInit , ViewEncapsulation, ElementRef, Pipe, PipeTransform} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SearchpageserviceService } from '../../services/searchpageservice.service';
import { StaticpagesService } from '../../services/staticpages.service';
import { ReservationService } from '../../services/reservation.service';
import { FilterHelper } from './../../classes/filter-helper';
import { CustomCode } from '../../shared/CustomCode';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { LogsService } from '../../services/logs.service';
import { NgForm } from '@angular/forms';
declare var google: any;
import * as $ from 'jquery';
import { UserserviceService } from '../../services/userservice.service';
import { AnimationKeyframesSequenceMetadata } from '@angular/animations';

@Pipe({ name: 'safeHtml'})


export class SafeHtmlConfirmationDailyPipe implements PipeTransform  {
  constructor(public sanitizer: DomSanitizer) {}

  transform(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'app-daily-success',
  templateUrl: './daily-success.component.html',
  styleUrls: ['./daily-success.component.scss'],
  providers: [SearchpageserviceService, StaticpagesService, UserserviceService, LogsService, ReservationService],
  encapsulation: ViewEncapsulation.None
})
export class DailySuccessComponent implements OnInit {
  dateIcon =  '../../../assets/img/v8-design/date-icon.svg';
  visaImg = '../../../assets/img/visa.svg';
  masterImg = '../../../assets/img/mastercard.svg';
  discoverImg = '../../../assets/img/discovercard.svg'
  amexImg = '../../../assets/img/amex.svg';
  jcbImg = '../../../assets/img/jcbcard.svg';

  timeIcon =  '../../../assets/img/v8-design/time-icon.svg';
  park_garage = '../../../assets/img/park_garage.png';
  
  respdata: any = {};

  resId;
  isFrame: any;
  isCollapsed = false;
  page = 'daily-payment-confirmation-page';
  cmsData: any;
  isLoaded = false;
  reservation_data: any;
  reservation_facility_details: any;
  monthly_request: any;
  facility_data: any;
  lat = '40.7589';
  long = '-73.9851';
  loyalityVal = 0;
  responseFromReservation = 0;
  fetchedHours = 0;
  facilityName = '';
  appendMap = 0;
  oversizedFee = '';
  accountNumber = '';
  backBtn = '';
  isloggedInUser: boolean = false;
  ticketId = '';
  refrenceId = '';
  phone = '';
  email = '';
  name = '';
  imageUrl = '../../../assets/img/park_garage.png';
  is_247_open = '';
  transaction_deails = '';
  vehicleType = '';
  reservationCreatedAt = '';
  garageWorkingHours:any=[];
  paymentType = '';
  forLoggedIn = false;
  fetchedReservationData = {};
  facilityId = '';
  amountByCard = '';
  amountByCredit = '';
  amountByPoints = '';
  amountSaved = '';
  ammount = '0.00';
  userDetailsText = '';
  reservationCancel = '';
  reservationDetails;
  statusData = {};
  imageCode: any;
  showDialog = false;
  isValidReservation:boolean = true;
  totalReservatiomAmount:any=0
  isUpdatedEndTimeFlag:boolean = false;
  constructor( private staticObj: StaticpagesService, 
    private searchPageServiceObj: SearchpageserviceService,
    private activatedRouteObj: ActivatedRoute,
    private elementRef: ElementRef,
    private reservationObj: ReservationService,
    private toastr: ToastrService,
    private userserviceServiceObj: UserserviceService,
    private logsService:LogsService,
    private router: Router,
    private sanitizer: DomSanitizer
    ) { }

    receiveShowDialog($event) {
	    this.showDialog = $event;
	}

  ngOnInit() {

    if (localStorage.getItem('userid')) {
      this.isloggedInUser = true;
      this.userDetailsText = 'User Details';
    } else {
      this.isloggedInUser = false;
      this.userDetailsText = 'Guest Details';
    }
    if(localStorage.getItem('is_loyalty_active') && localStorage.getItem('is_loyalty_active')=='1')
    {
      this.loyalityVal = 1;
    } else { this.loyalityVal = 0; }
    if (window.self !== window.top) {
      this.isFrame = true;
    } else {
      this.isFrame = false;
    }
    if (this.router.url.indexOf('my-account') > -1) {
      this.forLoggedIn = true;
    }
    this.activatedRouteObj.params.subscribe((params: Params) => {
      // console.log(params);
      this.resId = params['resId'];
      this.ticketId = params['ticketId'];
      
      this.fetchAppData(this.resId, this.ticketId);
    });
    this.staticObj.getCmsPageContent(this.page).subscribe(
      (data:any) => {
        this.cmsData  = data.result;
        this.cmsData = this.cmsData.post_content;
        
        let c = setInterval( () =>{
          if (this.responseFromReservation == 1) {
            this.refrenceId = 	this.respdata.ticketech_code;
            //this.ammount = this.respdata.transaction.total;
            this.isUpdatedEndTimeFlag = (this.respdata.is_end_time_updated == 1)?true:false
            let moreMss='';
            if(this.isUpdatedEndTimeFlag)
            {
              moreMss='Your reservation was extended at no extra charge!'
            }
            
            if(this.respdata.transaction.total !=="0.00" && this.respdata.credit_used ==="0.00"){
              this.ammount = this.respdata.transaction.total;
            } 
            if(this.respdata.transaction.total ==="0.00" && this.respdata.credit_used !=="0.00"){
              this.ammount = this.respdata.credit_used;
            } 
            if(this.respdata.transaction.total !=="0.00" && this.respdata.credit_used !=="0.00"){
              let amtCredit = parseFloat(this.respdata.transaction.total) +  parseFloat(this.respdata.credit_used);
              this.ammount = amtCredit.toString(); 
            }
            if(Object.keys(this.respdata.facility['photos']).length > 0  && this.respdata.facility.photos['url'] !== '' ){
              this.imageUrl = this.respdata.facility.photos.url
            }
            if (this.respdata.facility['geolocations'] && this.respdata.facility.geolocations['latitude']){
                      this.lat = this.respdata.facility.geolocations.latitude;
                    }
                    if (this.respdata.facility['geolocations'] && this.respdata.facility.geolocations['longitude']){
                      this.long = this.respdata.facility.geolocations.longitude;
                    }
              
                    if (this.respdata.user.phone !== '' && this.respdata.user.phone !== null) {
                      this.phone =  '<p><a class="text-dark">'+this.respdata.user.phone+'</a></p>';
                    } 
                    if (this.respdata.user.email !== '' && this.respdata.user.email !== null) {
                      this.email =  '<p><a class="text-dark">'+this.respdata.user.email+'</a></p>';
                    } 
                    if(this.router.url.indexOf('guest-reservation') > -1 ) 
                      {
                        if ( this.respdata['transaction'] && this.respdata.transaction['name'] !== '' && this.respdata.transaction['name'] !== null) {
                          this.name =  '<p><a class="text-dark">'+this.respdata.transaction.name+'</a></p>';
                        } 
                      }else {
                        if (this.respdata.user.name !== '' && this.respdata.user.name !== null) {
                          this.name =  '<p><a class="text-dark">'+this.respdata.user.name+'</a></p>';
                        } 
                      }
                   
                    if(this.respdata.transaction.payment_details !== null ){
                      this.transaction_deails = this.respdata.transaction.payment_details;
                    }
                    if('transaction' in this.respdata && 'id' in this.respdata.transaction && this.respdata.transaction['id'] != null && this.respdata.transaction.card_type !=null ){
                      let type = this.respdata.transaction.card_type.toLowerCase();
                      if(type=='visa'){
                        this.paymentType = this.visaImg;
                      }else if (type=='mastercard') { 
                        this.paymentType = this.masterImg;
                      }else if (type=='discover') { 
                        this.paymentType = this.discoverImg;
                      }else if (type=='americanexpress') { 
                        this.paymentType = this.amexImg;
                      }else if (type=='jcb') { 
                        this.paymentType = this.jcbImg;
                      }
                    
                      this.transaction_deails = `
                    <p class="cc"><img src="${this.paymentType}" alt="" height="16"><span class="mar-left-xs">${this.respdata.transaction.payment_details}</span></p>`;
                    }
              
                    this.amountByCard = '<span  sourceFont>Amount charged to card</span> &nbsp; <strong>$'+this.respdata.transaction.total+'</strong>';
                      // if(this.respdata.transaction.total > 0){
                      //   this.amountByCard = '<span>Amount charged to card</span> &nbsp; <strong>$'+this.respdata.transaction.total+'</strong>';
                      // }
                      if(this.respdata.credit_used > 0) {
                        this.amountByCredit = '<span sourceFont>Amount paid by credits</span> &nbsp; <strong>$'+this.respdata.credit_used+'</strong>';
                      }
                      if(this.respdata.loyalty_point_used > 0) {
                        this.amountByPoints = '<span sourceFont>Amount paid by points</span> &nbsp; <strong>'+this.respdata.loyalty_point_used+'</strong>';
                      }
                      this.totalReservatiomAmount =  parseFloat(this.respdata.transaction.total) + parseFloat(this.respdata.credit_used) +  parseFloat(this.respdata.discount)

                      if(this.respdata.credit_used !=="0.00" || this.respdata.discount !=="0.00"){
                        let amtCredit= 0;
                        let amountSaved = '';
                         amtCredit = (parseFloat(this.respdata.credit_used) +  parseFloat(this.respdata.discount));
                         amountSaved = amtCredit.toFixed(2).toString();
                        if( amtCredit > 0) {
                          this.amountSaved = `<span sourceFont style="font-style: italic;" class="text-primary">You saved $${amountSaved} !</span>`;
                        }
                      }
                      
                    if(this.garageWorkingHours.length == 0 ){
                      this.is_247_open = `<p class="cc">
                      <img width="12" src="${this.dateIcon}" class="mar-right-xs">7 days a week
                    </p>
                    <p class="cc">
                      <img width="14" src="${this.timeIcon}" class="mar-right-xs">24 hours a day
                    </p>`;
                    } else if ( this.garageWorkingHours.length > 0 ) {
                      let hoursData = '';
                        this.garageWorkingHours.map( ( wh, index )=> {
                          hoursData = hoursData +  `<tr><td>${wh.day}</td> <td>:</td><td>${wh.formatted_open_time}</td><td>-</td> <td>${wh.formatted_close_time} ${this.showtimefornxtornot(wh.close_time)}</td></tr>`;
                        } )
                        this.is_247_open =  `<div class="TimeActive table-responsive">
                                  <h4>Hours of Operation</h4>
                                  <table class="table table-condensed tbl-condensed">
                                          ${hoursData}
                                  </table>
                              </div>`
                      }
                    
                    if(this.respdata.facility){
                       this.vehicleType = '<p>Standard size vehicle</p>';
                      }else {
                       this.vehicleType = '<p>Standard size vehicle</p>';
                    }
              
                    if(this.respdata.created_at !== null){
                      let Arr = this.respdata.created_at.split(' ');
                    }
              // if(this.respdata['created_at'] != null && this.respdata['created_at'] !== ''){
              //   let objToday = new Date(this.respdata.created_at);
              // 	let weekday = new Array('Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'),
              //   dayOfWeek = weekday[objToday.getDay()],
              // 	// dayOfMonth = this.reservationCreatedAt + ( objToday.getDate() < 10) ? '0' + objToday.getDate() : objToday.getDate(),
              // 	dayOfMonth = ( objToday.getDate() < 10) ? '0' + objToday.getDate() : objToday.getDate(),

              //   months = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'),
              // 	curMonth = months[objToday.getMonth()],
              // 	curYear = objToday.getFullYear(),
              // 	curHour = objToday.getHours() > 12 ? objToday.getHours() - 12 : (objToday.getHours() < 10 ? "0" + objToday.getHours() : objToday.getHours()),
              // 	curMinute = objToday.getMinutes() < 10 ? "0" + objToday.getMinutes() : objToday.getMinutes(),
              // 	curSeconds = objToday.getSeconds() < 10 ? "0" + objToday.getSeconds() : objToday.getSeconds(),
              // 	curMeridiem = objToday.getHours() > 12 ? "PM" : "AM";
              // this.reservationCreatedAt =  dayOfWeek + ", " + curMonth + " "+ dayOfMonth + ", " + curYear +" "+ curHour + ":" + curMinute +" "+ curMeridiem;
              // }
              // else {
              //   this.reservationCreatedAt = '';
              // }
             let createdDateFormat = '';
              if (this.respdata['created_at'] != null && this.respdata['created_at'] !== '') {
                 createdDateFormat = this.respdata.created_at_formatted;
              } else {
                 createdDateFormat = this.respdata.created_at_formatted;
               }
               let reservationCanceledAt = '';
              if (this.respdata['cancelled'] == true && this.respdata['formatted_cancelled_at_date'] != null && this.respdata['formatted_cancelled_at_date'] !== '') {
                let CanceledAt = this.respdata.formatted_cancelled_at_date;
                reservationCanceledAt =  `<div class="reservation-tab">
                <div class="row">
                    <div class="col-md-3"><p><strong>Canceled At:</strong></p></div>
                    <div class="col-md-9">
                
                      <p>${CanceledAt} </p>
                    </div>
                </div>
                <div class="devide-border"></div>
               </div>`;
              } else {
                reservationCanceledAt = '';
               }

              if(this.respdata.status == 'Future' ){
                if(this.isloggedInUser){
                  this.reservationCancel = '<div class="col-md-3" style="min-width: 205px;"><a id="cancel-reservation" class="print-reservation btn btn-primary btn-fill theme-btn mar-top-xs btn-radius">Cancel Reservation</a></div>';
                }else {
                  this.reservationCancel = '<div class="col-md-3" style="min-width: 205px;"><a id="cancel-reservation-logout" class="print-reservation btn btn-primary btn-fill theme-btn mar-top-xs btn-radius">Cancel Reservation</a></div>';
                }
              }else {
                this.reservationCancel = '<div class="col-md-3" style="min-width: 205px;"><a id="unable-cancel-reservation" class="print-reservation btn btn-primary btn-fill theme-btn mar-top-xs btn-radius">Cancel Reservation </a></div>';
              }

                    this.reservation_data = `
                  <div class="reservation-head-box white-box-sdw srink-box">
                          <div class="reservation-header bg-primary" style="background-color: #ed8222;-webkit-print-color-adjust: exact;">
                              <h4 class="text-uppercase mar-top-0 mar-bottom-0" style="color: #ffffff;-webkit-print-color-adjust: exact;">Reservation Details</h4>
                          </div>
                          <div class="reservation-body pad-top-xs">
                          <div class="reservation-tab">
                                <div class="row">
                                 <div class="col-md-3"><p><strong>Reference ID:</strong></p></div>
                                 <div class="col-md-9"><p>${this.respdata.ticketech_code}</p></div>
                                </div>
                                <div class="devide-border"></div>
                                </div>
                              <div class="reservation-tab exit-tab">
                              <div class="row mar-bottom-xs">
                                  <div class="col-md-3"><p>  <strong>Enter After</strong></p></div>
                                  <div class="col-md-9">
                                    <p class="cc">
                                      <img width="15" src="/assets/img/enter_after_icon.png" class="mar-right-xs"><img width="13" src="${this.dateIcon}" class="mar-right-xs">${this.respdata.formatted_start_date} &nbsp;&nbsp; <img width="14" src="${this.timeIcon}" class="mar-right-xs">${this.respdata.formatted_start_time}
                                    </p>
                                  </div>
                              </div>
                              <div class="row">
                                <div class="col-md-3"><p><strong>Exit Before</strong></p></div>
                                <div class="col-md-9">
                                  <p class="cc">
                                    <img width="15" src="/assets/img/exit_before_icon.png" class="mar-right-xs"><img width="13" src="${this.dateIcon}" class="mar-right-xs">${this.respdata.formatted_end_date} &nbsp;&nbsp; <img width="14" src="${this.timeIcon}" class="mar-right-xs">${this.respdata.formatted_end_time}
                                    </p>
                                    <p class="mar-top-xs">
                                    ${moreMss}
                                    
                                    </p>
                                </div>
                            </div>
                              <div class="devide-border"></div>
                             </div>
                             <div class="reservation-tab">
                              <div class="row">
                                  <div class="col-md-3"><p><strong>Date Booked:</strong></p></div>
                                  <div class="col-md-9">
                              
                                    <p>${createdDateFormat} </p>
                                  </div>
                              </div>
                              <div class="devide-border"></div>
                             </div>
                             ${reservationCanceledAt}
                             <div class="reservation-tab">
                              <div class="row">
                                  <div class="col-md-3"><p><strong>${this.userDetailsText}:</strong></p></div>
                                  <div class="col-md-9">
                                    ${this.name}
                                    ${this.email}
                                    ${this.phone}
                                  </div>
                              </div>
                              <div class="devide-border"></div>
                             </div>
                             <div class="reservation-tab hide">
                              <div class="row">
                                  <div class="col-md-3"><p><strong>Vehicle:</strong></p></div>
                                  <div class="col-md-9">
                                    ${this.vehicleType}
                                  </div>
                              </div>
                              <div class="devide-border"></div>
                             </div>
                             <div class="reservation-tab">
                              <div class="row">
                                  <div class="col-md-3"><p><strong>Payment Method:</strong></p></div>
                                  <div class="col-md-9">${this.transaction_deails}</div>
                              </div>
                              <div class="devide-border"></div>
                             </div>
                             <div class="reservation-tab">
                              <div class="row mar-top-xs  mar-bottom-xs">
                              <div class="col-md-12 mar-bottom-sm">
                              <p class="reservation-value">
                              ${this.amountByCard} 
                              ${this.amountSaved} 
                              </p>
                              </div>
                                  <div class="col-md-3" style="min-width: 205px;"><a id="print-reservation" class="print-reservation btn btn-primary btn-fill theme-btn mar-top-xs hidden-xs btn-radius">Print Reservation</a></div>
                                  ${this.reservationCancel}
                              </div>
                             </div>
                          </div>
                      </div>
                  `;
                    let facility = this.respdata.facility;
                  this.reservation_facility_details = `
                  <div class="webSection white-box-sdw gray-mob">
                          <div class="clearfix slideShow">
                          <img src="${this.imageUrl}" alt="image" width="100%">
                          </div>
                          <div class="reservation-rt">
                            <h2 sourceFont class="mar-top-0">${facility.facility_name_modified}</h2>
                            <p class="mar-top-xs cc">
                            <span  class="fa fa-phone ismall text-primary" style="font-size: 16px"></span> &nbsp; ${facility.phone_number}
                            </p>
                            ${this.is_247_open}
              
                            <p class="mar-top-xs mar-bottom-0">Between: &nbsp;<span sourceFont>${facility.between_streets}</span></p>
                            <p class="mar-top-0">Entrance: &nbsp;<span sourceFont>${facility.entrance_location}</span></p>
                            <div class="googleMap mar-top-xs" id="map-wrapper">
                              <iframe src="https://maps.google.com/maps?q=${this.lat},${this.long}&hl=es;z=14&output=embed" width='257'  height='170' frameborder='0'  scrolling='no'  marginheight='0'  marginwidth='0'  allowfullscreen></iframe>
                            </div>
                            <p class="mar-top-xs"><a target="_blank" href="https://maps.google.com?q=${this.lat},${this.long}" class="btn theme-btn btn-primary theme-btn btn-block outline-btn" rel="noopener noreferrer">get directions</a></p>
                          </div>
                        </div>
                        `;

            this.cmsData = this.cmsData.replace("{{reference_id}}", this.refrenceId);
            this.cmsData = this.cmsData.replace("{{referenceId}}", this.refrenceId);
            this.cmsData = this.cmsData.replace("{{amount}}", '$' + this.ammount);
            
            this.cmsData = this.cmsData.replace("{{reservationData}}", this.reservation_data);
            this.cmsData = this.cmsData.replace("{{facilityDetails}}", this.reservation_facility_details);

            if (this.loyalityVal == 1) {
             // this.cmsData = this.cmsData.replace("{{class}}", 'hide');
             // this.cmsData = this.cmsData.replace("{{loyalityMemberWeb}}", 'col-md-6');
              //this.cmsData = this.cmsData.replace("{{loyalityMemberWebBox}}", 'col-md-6');
              
               //$('.loyality-member').addClass('hidteste');
              // $('.loyality-member-web').removeClass('col-md-4').addClass('col-md-6');
              // $('.appLinkWrapper').addClass('pad-left-0');
              // $('.loyality-member-web').addClass('black-2');
             }else {
             // this.cmsData = this.cmsData.replace("{{class}}", 'show');
             // this.cmsData = this.cmsData.replace("{{loyalityMemberWeb}}", 'col-md-4');
              //this.cmsData = this.cmsData.replace("{{loyalityMemberWebBox}}", 'col-md-4');
             }

             if(this.forLoggedIn){
              this.cmsData = this.cmsData.replace("{{hideFromAccount}}", 'hide');
              this.cmsData = this.cmsData.replace("{{showFromAccount}}", 'show');
             }else {
              this.cmsData = this.cmsData.replace("{{hideFromAccount}}", 'show');
              this.cmsData = this.cmsData.replace("{{showFromAccount}}", 'hide');
             }
             
             clearInterval(c);
            this.bindElement();
            this.bindElementForMonthly();
            this.bindElementForCancel();
            this.bindElementForNotCancel();
            this.bindElementForLogoutCancel();
          }
        }, 100);
        let d = setInterval( () => {
        $('.googleMap').html("<iframe width='257' height='170' frameborder='0' scrolling='no' marginheight='0' marginwidth='0' src='https://maps.google.com/maps?q="+this.lat+","+this.long+"&hl=es;z=14&amp;output=embed' allowfullscreen>");
          this.appendMap = 1;
          if (this.appendMap) {
            clearInterval(d);
          }
      }, 100);
      }, (error) => { console.log('Error: ' + JSON.stringify(error)); 
      this.isLoaded = true;
    }
    );

  }

  onClickBook(event) {

    if(this.isloggedInUser){
      // for loggedin user
      this.printReservation(this.respdata.id)
    }else {
       // for loggout user
       this.printGuestReservation(this.respdata.id, this.respdata.ticketech_code)
    }

    // $('div#printable').html($('div.printData').html()).show();
    // $('div#content-Wrapper').hide();
    // window.print();
    // $('div#printable').html('').hide();
    // $('div#content-Wrapper').show();
  }

  bindElement()
    {
     let a = setInterval(() => {
        if(this.elementRef.nativeElement.querySelector('#print-reservation')) {
           this.elementRef.nativeElement.querySelector('#print-reservation').addEventListener('click', this.onClickBook.bind(this));
            clearInterval(a);
        }
       }, 100);
    }
    
    bindElementForMonthly()
    {
     let b = setInterval(() => {
        if(this.elementRef.nativeElement.querySelector('#redirectTOMonthly')) {
          this.elementRef.nativeElement.querySelector('#redirectTOMonthly').addEventListener('click', this.formonthly.bind(this));
           clearInterval(b);
       }
    }, 100);
}

bindElementForCancel()
{
 let c = setInterval(() => {
    if(this.elementRef.nativeElement.querySelector('#cancel-reservation')) {
      this.elementRef.nativeElement.querySelector('#cancel-reservation').addEventListener('click', this.cancelReservation.bind(this));
       clearInterval(c);
   }
}, 100);
}

bindElementForNotCancel()
{
 let c = setInterval(() => {
    if(this.elementRef.nativeElement.querySelector('#unable-cancel-reservation')) {
      this.elementRef.nativeElement.querySelector('#unable-cancel-reservation').addEventListener('click', this.errorCancelReservation.bind(this));
       clearInterval(c);
   }
}, 100);
}

bindElementForLogoutCancel()
{
 let c = setInterval(() => {
    if(this.elementRef.nativeElement.querySelector('#cancel-reservation-logout')) {
      this.elementRef.nativeElement.querySelector('#cancel-reservation-logout').addEventListener('click', this.cancelReservationLogoutUser.bind(this));
       clearInterval(c);
   }
}, 100);
}


    showtimefornxtornot(text)
    {
      var res = text.split(':');
      if(res[0]>24)
      {
        return '(next day)'
      }else{
        return '';
      }
    }

    

    fetchAppData(resId, ticketId){
      this.reservationObj.reservationDetailsForDaily(resId, ticketId).map( (res:any) => res ).subscribe(
        data => {
          this.isValidReservation = true;
          this.respdata = data.data;
          this.facilityId = this.respdata['facility_id'];
          this.searchPageServiceObj.getFacilityScheduleWorkingHours(this.facilityId).subscribe( (data:any) => {
            let workingHour = data;
           this.garageWorkingHours = workingHour.data;
           this.isLoaded = true;
           this.responseFromReservation = 1;
          },
          error => {
           let errorData = error.json();
           console.log(errorData.errors.message);
           } 
           )
         },
         error => {
          this.isValidReservation = false;
          this.toastr.error('Sorry! You are not authorized to view this reservation.');
          this.isLoaded = true;
          let startDuration = FilterHelper.defaultStartDuration();
          let startTime = FilterHelper.getFormattedTime(startDuration);
          localStorage.setItem('monthlyDate', startDuration.toString())
          let endDuration = FilterHelper.defaultEndDuration();
          let endTime = FilterHelper.getFormattedTime(endDuration);
          let endTimeDataIfLess = FilterHelper.makeDurationDateObject(endDuration, endTime);
          localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(endDuration, endTime));
          localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(startDuration, startTime));
          let stime =localStorage.getItem('startimehome');
          this.router.navigate(['/search', '40.7589', '-73.9851', stime, 1,  'Times Square','daily', CustomCode.searchMode()]);
         }
      )
    } 

    formonthly()
  {
      let startDuration = FilterHelper.defaultStartDuration();
      let startTime = FilterHelper.getFormattedTime(startDuration);
      localStorage.setItem('monthlyDate', startDuration.toString())
      let endDuration = FilterHelper.defaultEndDuration();
      let endTime = FilterHelper.getFormattedTime(endDuration);
      let endTimeDataIfLess = FilterHelper.makeDurationDateObject(endDuration, endTime);
      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(endDuration, endTime));
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(startDuration, startTime));
      let stime =localStorage.getItem('startimehome');
      this.router.navigate(['/search', '40.7589', '-73.9851', stime, 1,  'Times Square','monthly', CustomCode.searchMode()]);
  }

  cancelReservation() {
    let id = this.respdata.id;
    let reservationCode= this.respdata.ticketech_code;
    let total= this.respdata.transaction.total; 
    let location= this.respdata.facility.short_name;
   console.log(id,reservationCode, total, location )
		if(confirm("Are you sure you want to cancel?")) {
			const reservation_status_cancelRes= '2';
			this.userserviceServiceObj.cancelReservation(id)
		    .then(
				data => {
					this.updateReservationStaus(id, reservation_status_cancelRes);
					this.toastr.success('Reservation Successfully Canceled', 'Great!');
					(<any>window).dataLayer.push(
						{'reservationCode': reservationCode,
						 'location':location,
						 'value':total,
						 'event':'Cancel reservation'
					});
				//	ga('ec:setAction', 'refund', { 'id': id });
					this.router.navigate(['/my-account/reservations']);
				},
		      	error => {
		      		this.toastr.error(error.errors.message);
		      	}
		    );
		}
  }
  
  errorCancelReservation() {
    let reservationStatus = this.respdata.status;
		if(reservationStatus == 'Past'){
			this.toastr.error("Reservation has already begun or is already over and cannot be canceled.", 'Error', {timeOut: 2000, });
		}else if(reservationStatus == 'Cancelled'){
			this.toastr.error("Reservation has already been Canceled", 'Error', {timeOut: 2000, });
		}else if(reservationStatus == 'Current'){
			this.toastr.error("Reservation has already begun or is already over and cannot be canceled.", 'Error', {timeOut: 2000, });
		}else{
			this.toastr.error("Reservation can't be Canceled", 'Error', {timeOut: 2000, });
		}
	}
	
	cancelReservationLogoutUser() {
    let id = this.respdata.id;
    let reservationCode= this.respdata.ticketech_code;
    let total= this.respdata.transaction.total; 
    let location= this.respdata.facility.short_name;
		if(confirm("Are you sure you want to cancel?")) {
		let	resId=this.respdata.id
			this.reservationObj.reservationDetails(resId,reservationCode).then(
	        	(res) => {
	        		this.isLoaded = true;
	          		this.reservationDetails = res;
					  this.reservationDetails = this.reservationDetails.data;
					  
					//alert(this.reservationDetails.id);alert(this.reservationDetails.ticketech_code);alert(this.reservationDetails.reservation_code);
					
					this.userserviceServiceObj.cancelReservationByEmail(this.reservationDetails.reservation_code,this.reservationDetails.ticketech_code,resId)
									.then(
										data => {
											const  reservation_status_cancel = '2';
											this.updateReservationStaus(resId, reservation_status_cancel);
											this.toastr.success('Reservation Successfully Canceled', 'Great!');
											(<any>window).dataLayer.push(
											{'reservationCode': reservationCode,
												 'location':location,
												 'value':total,
												 'event':'Cancel reservation'
											});
											//ga('ec:setAction', 'refund', { 'id': resId });
											let logData = {
												'iq_code':reservationCode,
												'coupon_code':null,
												'bar_code':null,
												'event_type':1
											}
											
											let reservation_id= resId;
											let reservation_status = '2';
											this.updateReservationStaus(reservation_id, reservation_status);
											
											this.logsService.addLog(logData).subscribe( (result)=>{ }, (err) => { });
											/* #End GTM logged in db */
											this.router.navigate(['reservations/cancel/email']);
											
										},
										error => {
											this.toastr.error(error.errors.message);					
											this.router.navigate(['reservations/cancel/email']);
										}
									);
					},
	        		(error) => {
	        			this.toastr.error(error.errors.message);
	        			this.isLoaded = true;
	        		}
	      	);
			
		}
	}


  updateReservationStaus( reservation_id, reservation_status ){
		/* reservation tracking  */
		// kochava.activity('cancel_reservation ', {
		// 	reservation_id: reservation_id,
		// }); //is wale ko uncomment nhi krna h

		this.reservationObj.updateReservation(reservation_id, reservation_status).subscribe(
		  data => {
			this.statusData = data;
		  },
		  error =>{

		}
		)
  }
  

  printReservation(reservation_id) {
    this.userserviceServiceObj.printReservation(reservation_id)
    .then(
      data => {
		  
		// var mediaType = 'application/pdf';
        // var blob = new Blob([data['_body']], {type: mediaType});
        // var filename = 'test.jpeg';
        // FileSaver.saveAs(blob, filename);
        const urlCreator = window.URL;
        const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data['_body']));
        this.imageCode = blobData['changingThisBreaksApplicationSecurity'];
        this.showDialog = true;
      },
      error => {
		    const urlCreator = window.URL;
			const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(error['_body']));
			this.imageCode = blobData['changingThisBreaksApplicationSecurity'];
			this.showDialog = true;
        //this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }

  printGuestReservation(reservation_id, tickID) {
      	this.reservationObj.reservationDetails(reservation_id,tickID).then(
        	(res) => {
        		// this.isLoaded = true;
          		this.reservationDetails = res;
          		this.reservationDetails = this.reservationDetails.data;
				this.userserviceServiceObj.printReservationByEmail(this.reservationDetails.reservation_code,this.reservationDetails.ticketech_code)
				.then(
				  data => {
					const urlCreator = window.URL;
					const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data['_body']));
					this.imageCode = blobData['changingThisBreaksApplicationSecurity'];
					this.showDialog = true;
					
				  },
				  error => {
					  const urlCreator = window.URL;
					  const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(error['_body']));
					  this.imageCode = blobData['changingThisBreaksApplicationSecurity'];
					  this.showDialog = true;
					
					//this.toastr.error(error.errors.message, 'Sorry!');
				  }
				);

			},
        		(error) => {
        			this.toastr.error(error.errors.message);
        			this.isLoaded = true;
        		}
      	);
	}

  }

