import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-icon-rewards-tabs',
  templateUrl: './icon-rewards-tabs.component.html',
  styleUrls: ['./icon-rewards-tabs.component.scss']
})
export class IconRewardsTabsComponent implements OnInit {
  tierImage = 'assets/img/Icon_Reward_Tiers.svg';
  constructor(private routerObj: Router) { }

  ngOnInit() {
  }

  moveToFaq(){
    localStorage.setItem('loyalty_faq', '1');
    this.routerObj.navigate(['/faq/Icon Rewards']);
  }

}
