import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers, RequestOptions, Response } from '@angular/http';

import { Observable } from 'rxjs/Rx';

// Import RxJs required methods
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { StaticpagesService } from '../../services/staticpages.service';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss'],
  providers: [StaticpagesService]
})
export class AboutusComponent implements OnInit {

  page: String = 'about-us';
  pageId:string = 'about-us';
  aboutContent: any = {};
  isLoaded = false;

  cmsPageContent : any = {};

  constructor(private router: Router, private staticObj: StaticpagesService) { }

  ngOnInit() {
    this.isLoaded = true;
    this.staticObj.staticPagesContent(this.page).then(
      (res) => {
        this.aboutContent = res;
        this.aboutContent = this.aboutContent.data;
      },
      (error) => { console.log('Error: ' + JSON.stringify(error)); }
    );


    this.staticObj.getCmsPageContent(this.pageId).subscribe((data:any) => {
        this.cmsPageContent = data.result[0];
        this.isLoaded = true;
      }, (error) => { console.log('Error: ' + JSON.stringify(error));
      this.isLoaded = true;
     }
    );

  }

}
