import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef , TemplateRef, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomCode } from '../../../shared/CustomCode';
import { FilterHelper } from '../../../classes/filter-helper';
import { Ng2DeviceService } from 'ng2-device-detector';
import { UserserviceService } from '../../../services/userservice.service';
import { MyAccountMonthlyParkingService } from '../../../services/my-account-monthly-parking.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Location} from '@angular/common';
import { BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import * as $ from 'jquery';
//import { setTimeout } from 'timers';
@Component({
  selector: 'app-search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss'],
  providers: [UserserviceService, MyAccountMonthlyParkingService]
})
export class SearchFiltersComponent implements OnInit {
  public modalRef: BsModalRef;
  private _picker: BsDatepickerModule;

    @ViewChild('myDiv',{static:true}) myDiv: ElementRef;
    @ViewChild('dateTime2',{static:true}) dateTime2: ElementRef;
    @Input() bookingView?: boolean;
    @Output() updateFilters = new EventEmitter<boolean>();
    @Output() updateFiltersNei = new EventEmitter<boolean>();
    @Output() updateFiltersOnSubmit = new EventEmitter<boolean>();
    @Output() updateFacilityFilters = new EventEmitter<boolean>();
    @Output() updateFiltersMobile = new EventEmitter<boolean>();
    
    sign:any = '';
    month = new Array("Jan","Feb","Feb","Mar","Apr","May", "Jun","Jul","Aug","Sep", "Oct","Nov","Dec")
    todayDate=this.month[new Date().getMonth() + 1]+" "+new Date().getDate()
    //todayDate=new Date();
    startDuration:any;
    startDurationMonthly:any;
    endDuration:any;
    monthlyDate:any;
    is_landing_page:any=false;
    startTime:any;
    show_strip_search:any=false
    is_landing_page_save:any=true;
    is_show_landing_strip:any=false;
    endTime:any;
    showonhomepage=1;
    minDate:any;
    maxDate:any;
    is_deal:boolean=false;
    monthlypark: boolean =false
    motorcycle: boolean =false
    maxEndDate:any;
    filterData:any;
    Couponmessage=''
    Couponmessages=''
    inactiveFacility:any=false;
    isSearch:boolean = false;
    isNeighborhoods:boolean = false;
    isPayment:boolean = false;
    isPartner:boolean = false;
    endMaxDuration:any;
    firstLoad = false;
    dealsBtn = false
    isLoggedIn=false
    oversized: boolean =false
    evcharging: boolean =false
    tesla: boolean =false
    outdoor: boolean =false
    indoor: boolean =false
    twentyhour:boolean=false
    typeOfSearch:any='daily';
    monthlyFilter:any = false;
    dailyFilter:any = true;
    dailyFilters:any=true;
    urlDate:any='';
    a:any = '';
    searchCriteria: any = {
      'type': CustomCode.defaultFacilityType,
      'device': 'web',
      'lat': CustomCode.timeSquareCoords.lat,
      'long': CustomCode.timeSquareCoords.long,
      'currdate': CustomCode.getDateForMapSearch(),
      'radius': CustomCode.facilityDefaultRadius,
      'label': CustomCode.timeSquareCoords.defaultLocation,
      'length_of_stay': 2,
      'firstLoad' : false
    };
    deviceInfo = null;
    timeSlots:any = FilterHelper.getObjectBasedTimeSlots();
    isFrame:boolean=false;
    logoIconDark='assets/img/icon_logo-dark.svg';
    crossSvg='assets/img/cross.svg'
    ev_charge_icon='assets/img/v8-design/icon-small-universalEV.svg';
    motorcycle_icon='assets/img/v8-design/icon-small-motocycle.svg'
    tesla_charger_icon='assets/img/v8-design/icon-small-tesla.svg'
    open_24hr_icon='assets/img/v8-design/icon-small-24hr.svg'
    monthly_icon='assets/img/v8-design/icon-small-monthly.svg'
    oversized_icon='assets/img/v8-design/icon-small-oversized.svg'
    outdoor_icon='assets/img/v8-design/icon-small-outdoorpark-v2.svg'
    indoor_icon='assets/img/v8-design/icon-small-indoorpark-v2.svg'
    filter_icon='assets/img/v8-design/filter_icon.svg'
    date_icon='assets/img/v8-design/date-icon.svg'
    time_icon='assets/img/v8-design/time-icon.svg'
    typeOfSearchDate:any='daily'
    changeOnClickType:any='daily';
    filterBadge = 'filter-badge-loyalty';
    yesdo:boolean=false
    isfacility:any=0
    is_home_page=0;
    isPartnerPage:boolean = false;
    c:any = '';
    ispartner:boolean = false;
    dealstoggle:boolean=false;
    isDedicatedFacility = false;
    showOnMothly:any=0;
    blueBar:boolean = false;
    hideOnPayment = false;
    isLoyality = false;
    @Input() showTies:boolean = true;
    @Output() updateTierToNeighbour = new EventEmitter();
    constructor(
        private location:Location,
        private userServiceObj: UserserviceService,
        private modalService: BsModalService,
        private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
        private deviceService: Ng2DeviceService,
        private toastr: ToastrService,
        private activatedRouteObj: ActivatedRoute,
        private router: Router,
        private changeDetectorRef:ChangeDetectorRef) { 
    
    }
    public config = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: false
    };
    ngAfterViewInit() {
      setTimeout(()=>{
        clearInterval(this.c);
      },10000)
      // this.monthlyDate = this.a;
      setInterval(()=>{    //<<<---    using ()=> syntax
       if(sessionStorage.getItem('editTime'))
       {
        //let el: HTMLElement = this.dateTime2.nativeElement as HTMLElement;
        //this.openModal(this.dateTime2)
        let el: HTMLElement = this.myDiv.nativeElement as HTMLElement;
        el.click();
        
       }
       
        }, 100);    
    }
    todayDateFunction()
    {
     return  this.month[new Date(this.startDuration).getMonth() + 1]+" "+new Date(this.startDuration).getDate()
    }
    ngOnInit() {
      localStorage.removeItem('mapDrag');
      localStorage.removeItem('mapDrag1');
      if(this.router.url.indexOf('payment') > -1){
        this.hideOnPayment = false;
        if(localStorage.getItem('is_loyalty_active') && localStorage.getItem('is_loyalty_active')=='1')
        {
        this.isLoyality = true
        } else{
          this.isLoyality = false
        }
      }else {
        this.hideOnPayment = true;
      }

      if(this.router.url.indexOf('payment') > -1)
        {
          this.blueBar=true;
        }else{
          this.blueBar=false;
        }

      let cp = setInterval(()=>{
        if(localStorage.getItem('navigatesearch'))
        {
          this.searchDate()
          localStorage.removeItem('navigatesearch')
          //clearInterval(cp)
        }
      },2000)

      this.Couponmessages='<b>Great!</b> We’ll automatically apply your promo code '+localStorage.getItem("promocode")+' at checkout.'
      
      //  if(this.router.url.indexOf('partners') > -1 || this.router.url.indexOf('facility') > -1 ) 
        if(this.router.url.indexOf('partners') > -1 ) 
        {

          this.ispartner=true;
        }
        
        
        if(this.router.url.indexOf('facility') > -1 ) 
        {
          this.isDedicatedFacility = true;
        
          $('.searchFilter ').addClass('facilitPageFix');
          $('.homeTopFix').css('margin-top','140px');
        }else {
          this.isDedicatedFacility = false
        }


        sessionStorage.removeItem('openByClick')
        sessionStorage.removeItem('searchTypeByMobile')
        let getUrl = window.location;
        if ($(window).width() < 768  ) {
          setInterval(() => {
            if(sessionStorage.getItem('searchTypeByMobile') == 'daily'){
            // this.dealsBtn = false
            }
            if(sessionStorage.getItem('searchTypeByMobile') == 'monthly'){
              // this.dealsBtn = true
              }
          }, 1000);	
        }
        

        if(getUrl.pathname=='/' || getUrl.pathname==='/variant/' || getUrl.pathname==='/variant')
        {
          this.showonhomepage=0;
        }else{
          this.showonhomepage=1;
        }
        
        if(!sessionStorage.getItem('promoApply'))
        {
          localStorage.removeItem('is_landing_page');
          localStorage.removeItem('routeUrl');
          localStorage.removeItem('template_slug');
          localStorage.removeItem('promotion_id');
          localStorage.removeItem('promocode');
          localStorage.removeItem('action_type');
          localStorage.removeItem('reservation_apply');
        }
        //alert(this.router.url);
        if(this.router.url=='/' ||this.router.url.indexOf('/?') > -1)
        {
          this.is_home_page=1
        }else{
          this.is_home_page=0;
        }
        if (this.router.url=='/' || this.router.url.indexOf('search') > 0 || this.router.url.indexOf('neighborhoods')>0 || this.router.url.indexOf('facility')>0)
        {
          this.show_strip_search=true;
        }
        if(this.router.url.indexOf('facility') > -1 || this.router.url.indexOf('partners') > -1)
        {
          this.yesdo=true;
        }else{
          this.yesdo=false;
        }
        if(localStorage.getItem("promocode") && localStorage.getItem('is_landing_page_monthly_stripe') && this.router.url.indexOf('search') > 0 && this.router.url.indexOf('monthly') > -1 && localStorage.getItem('is_landing_page_monthly'))
        {
          this.showOnMothly=1;
          this.sign = 'dollar';
        }else{
          this.showOnMothly=0;
        }
        //this.show_strip_search=true;
        if(localStorage.getItem('is_landing_page')=='true' && localStorage.getItem('action_type')=='apply' )
        {
           if(localStorage.getItem('is_show_landing_strip')=='true')
           {
             this.is_show_landing_strip=true;
           }
           if (localStorage.getItem('access_token')) {
               let data = {};
               let mode='apply'
               data = {
                      'promocode':localStorage.getItem('promocode'),
                       'user_id' :localStorage.getItem('userid')};
                 
                this.userServiceObj.validatePromoCode(data)
                 .then(
                 data => {
                          if (data['data'].is_promocode_valid) {
                          if (mode == "save") {
                              //this.savePromocode(neighborhood);
                          } else {
                              //this.notifyReservationPromoCodeApply();
                          }
                          } else 
                          {
                             localStorage.setItem('couponMessage',data['data'].message)
                             localStorage.setItem('errorType','1')
                          }
                          this.promocodeApply();
                        },
               error => {

                        localStorage.setItem('couponMessage',error.errors.message)
                        localStorage.setItem('errorType','0')
                        if(error.errors.message == "This Promocode is only for guest user")
                          {
                            localStorage.setItem('errorType','4');
                          }
                          else if (error.errors.message == "Please create an account to avail this") 
                          {
                              localStorage.setItem('errorType','1')
                              //this.notifyAccountRequirement();
                                //this.launchLoginPopup();
                          }
                           else if (error.errors.message == "Please enter a valid Promotion Code. Please note that a prepaid voucher is not a Promo Code.") 
                          {
                              localStorage.setItem('errorType','1')
                          }
                          this.promocodeApply();
                        }
                          );
           }
        }
        this.promocodeApply();
        
        if(localStorage.getItem('inactiveFacility'))
        {
          this.inactiveFacility=true;
        }
        setTimeout(() => {
          localStorage.removeItem('inactiveFacility')
        }, 2000);  
        if(localStorage.getItem('monthlyDate') && localStorage.getItem('monthlyDate') !== null)
        {
          this.monthlyDate = new Date(localStorage.getItem('monthlyDate'));
          
        } else {
          this.monthlyDate = new Date();
        }
        this.minDate = FilterHelper.defaultStartDurationForMin();
        this.maxDate=  FilterHelper.defaultEndstartDate();
        this.maxEndDate=FilterHelper.defaultEndtoDate()
        this.initializeFilters();
        this.activateSearchType();
        this.changeDetectorRef.detectChanges();
        setTimeout(() => {
          this.firstLoad = true;
        }, 200);

        setInterval(()=>{ if(this.monthlyDate ===null) { this.monthlyDate = new Date(); } 
        if(localStorage.getItem('currentLoc'))
       {
         localStorage.removeItem('currentLoc')
         this.searchDates();
       }
      }, 1000);

    }

    iconClick(elementId) {
      document.getElementById(elementId).click();
    }

    hideStripe()
    {
     // $('aside.contentBar').css({'height':'calc(100% - 140px);'})
      this.is_landing_page=false;
      this.is_show_landing_strip=false;
      localStorage.setItem('is_show_landing_strip','false')
     // this.sidebarHeightUpdate();
      $('.payment-section').removeClass('bluebar');
    }
    hidestripfacility()
    {
      this.inactiveFacility=false;
     // this.sidebarHeightUpdate();
    }
    sidebarHeightUpdate(){
    setTimeout(function(){ 
      if($('.navbar-custom').length > 0){
        this.headerBarHeight = 120;
      }else{
        this.headerBarHeight = 0;
      }
      if($('.searchFilter').length > 0){
        this.searchFilterHeight  = 60;
      }else{
        this.searchFilterHeight  = 0;
      }
      if($('.infoBlock').length > 0){
        this.searchFilterHeight  = 121;
      }else{
        this.searchFilterHeight = this.searchFilterHeight;
      }
      
      this.sideBarValue =  this.headerBarHeight + this.searchFilterHeight ; 
      $('aside.contentBar').css({'height':'calc(100% - '+this.sideBarValue+'px)'});
     // $('.searchFilter').css({'margin-top': + this.headerBarHeight + 'px'});
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        $('.negiMap').css({'height':'100vh'});
            $('.negiMap').css({'margin-top': '0px'});
            $('.nghMap').css({'top':'120px'});
      }else{
        $('.negiMap').css({'height':'calc(100vh - '+this.sideBarValue+'px)'});
            $('.negiMap').css({'margin-top': +this.sideBarValue+'px'});
            $('.nghMap').css({'top':+this.sideBarValue+'px'});
      }
    },0);
  }
    promocodeApply()
    {
      if(localStorage.getItem('is_landing_page')=='true' && localStorage.getItem('action_type')=='apply' )
        {
          this.is_landing_page =true;
           if (localStorage.getItem('access_token')) {
             if(localStorage.getItem('errorType')=='4')
             {
               this.sign = 'dollar';
               this.is_landing_page_save=false;
               localStorage.removeItem('is_landing_page')
               this.Couponmessage=localStorage.getItem('couponMessage')
             }
             else if(localStorage.getItem('errorType')=='1')
             {
               this.sign = 'dollar';
               this.is_landing_page_save=false;
               this.Couponmessage=localStorage.getItem('couponMessage')
               if(this.Couponmessage=='Please enter a valid Promotion Code. Please note that a prepaid voucher is not a Promo Code.')
               {
                 this.is_landing_page_save=false;
               }
             }else if(localStorage.getItem('errorType')=='0')
             {
               this.sign = 'dollar';
               this.Couponmessage='Promocode has been expired.'
               this.is_landing_page_save=false;
             }
             else{
               this.sign = 'dollar';
               this.Couponmessage="<b>Great!</b> We"+"'"+"ll automatically apply your promo code "+localStorage.getItem('promocode').toUpperCase()+" at checkout. Or,"
             }
          }else{
             this.sign = 'dollar';
             this.is_landing_page_save=false;
             this.Couponmessage=localStorage.getItem('couponMessage')
             if(localStorage.getItem('errorType')=='2')
             {
               this.is_landing_page_save=true;
               if(localStorage.getItem('couponMessage')=='Please create an account to avail this')
              {
                 this.is_landing_page_save=false;
                 this.Couponmessage='Please create an account or login to your existing account in order to use this promo code.'
               }
             }
            //this.Couponmessage=localStorage.getItem('couponMessage')
          }
         // this.Couponmessage=localStorage.getItem('couponMessage')
        }
        if(localStorage.getItem('showEroor'))
        {
          if(localStorage.getItem('showEroor')=='1')
          {
           this.toastr.success(localStorage.getItem('showEroorMsg'), 'Great!');
          }else{
            this.toastr.error(localStorage.getItem('showEroorMsg'), 'Sorry!');
          }
          localStorage.removeItem('showEroor')
          localStorage.removeItem('showEroorMsg')
        }
    }
    launchLoginPopup() {
       if (localStorage.getItem('access_token')) {
          this.isLoggedIn = true;
          this.validatePromoCode('save');
       }else{
         localStorage.setItem('save', 'true');
         localStorage.setItem('savebylogin', 'true');
      $("a#loginPopup")[0].click();
       }
      
    }
    

  validatePromoCode(mode) {
    let data = {
        'promocode': localStorage.getItem('promocode'),
        'user_id' : localStorage.getItem('userid')
      };
    this.userServiceObj.validatePromoCode(data)
    .then(
      data => {
        if (data['data'].is_promocode_valid) {
          this.savePromocode();
        } else {
          this.toastr.error('Promocode has been expired', 'Sorry!');
          //window.location.reload();
        }
      },
      error => {
        if (error.errors.message == "Please create an account to avail this") {
          this.toastr.error('Please login to your existing account or create a new one in order to save and redeem this promotion.', 'Sorry!');
        } else {
          this.toastr.error(error.errors.message, 'Sorry!');
         // window.location.reload();
        }
      }
    );
  }
  close(){
    $('#featureFilter').removeClass('open');
  }
  savePromocode() {
    this.hideStripe();
    localStorage.removeItem('savebylogin')
    const data = {'promocode' : localStorage.getItem('promocode'), 'user_id' : localStorage.getItem('userid')};
    this.userServiceObj.addPromoCode(data)
    .then(
      data => {
        this.show_strip_search=false
        localStorage.setItem('is_show_landing_strip','false')
        if (!data['errors']) {
           this.show_strip_search=false
            this.toastr.success('Promocode successfully added, Please make a reservation to redeem this offer.', 'Great!');
           // this.navidateToSearch();
        } else {
          this.toastr.error('Something wrong happened, try again later', 'Sorry!');
          //window.location.reload();
        }
      },
      error => {
        this.show_strip_search=false
        localStorage.setItem('is_show_landing_strip','false')
        this.toastr.error(error.errors.message, 'Sorry!');
       // this.navidateToSearch();
        // window.location.reload();
      }
    );
  }
  toTime(timeString){
    if(timeString!=undefined)
    {
      var timeTokens = timeString.split(':');
      //return 'December 17, 1995 ' +timeTokens[0]+':'+timeTokens[1]+':'+timeTokens[2]
      let filterDate= new Date('December 17, 1995 ' +timeTokens[0]+':'+timeTokens[1]+':'+timeTokens[2]);
      let hours = filterDate.getHours();
      let minutes:any = filterDate.getMinutes();
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      let  strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
      //return new Date(1970,0,1, timeTokens[0], timeTokens[1], timeTokens[2]);
    }else{
      let filterDate= new Date('December 17, 1995 00:00:00');
      let hours = filterDate.getHours();
      let minutes:any = filterDate.getMinutes();
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      let  strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    }
    
  }
  toDate(timeString){
      let changeDateFormat=new Date(timeString)
      let afterChnageDate=this.month[changeDateFormat.getMonth() + 1]+" "+changeDateFormat.getDate() 
      return afterChnageDate 
  }
    initializeFilters() {
      //alert(3);
      if (this.router.url.indexOf('search') > 0) { 
        this.isSearch = true;
        this.initializeMapFilters();
      }
      if (this.router.url.indexOf('facility') > 0) { 
        this.isSearch = true;
        this.isfacility=1
        //this.initializeMapFilters();
      }else{
        this.isfacility=0
      }
      if (this.router.url.indexOf('neighborhoods') > 0 || (this.router.url.indexOf('monthly-parking') > 0) ) { 
        this.isNeighborhoods = true;
        this.initializeNeighborhoodsFilters();
        //this.getMonthlyDate();
        this.getNeighbourDetails();
      }

      if (this.router.url.indexOf('payment') > 0) {
        this.isPayment = true;
        this.initializePaymentFilters();
      }

      if (this.router.url.indexOf('facility') > 0) {
        this.isPayment = true;
        this.initializeFacilityFilters();
      }
      
      if (this.router.url.indexOf('partners') > 0) {
        this.isPartner = true;
        this.initializePartnerFilters();
      }

      setTimeout(()=>{
        this.searchDate();
      },1000)
    }
    onShowPicker(event) {
      const dayHoverHandler = event.dayHoverHandler;
      const hoverWrapper = (hoverEvent) => {
          const { cell, isHovered } = hoverEvent;
  
          if ((isHovered &&
            !!navigator.platform &&
            /iPad|iPhone|iPod/.test(navigator.platform)) &&
            'ontouchstart' in window
          ) {
              (this._picker as any)._datepickerRef.instance.daySelectHandler(cell);
          }
  
          return dayHoverHandler(hoverEvent);
      };
      event.dayHoverHandler = hoverWrapper;
  }
    initializeMapFilters() {
     //alert("66")
     // this.router.url
      this.deviceInfo = this.deviceService.getDeviceInfo();
     
      this.startDuration = FilterHelper.defaultStartDuration();
      
      this.startTime = FilterHelper.getFormattedTime(this.startDuration);
      if(localStorage.getItem('endttimehome'))
      {
        this.endDuration = FilterHelper.defaultEndDurationUrlHome(localStorage.getItem('endttimehome'));
      }else{
        this.endDuration = FilterHelper.defaultEndDuration();
      }
      //console.log(this.endDuration+" asasaasasasas");
      this.activatedRouteObj.params.subscribe((params: Params) => {
        if (params['lat'] && params['long'] && params['currdate'] && params['radius'] && params['label'] && params['type'] && params['device']) {
          this.typeOfSearch=this.searchCriteria.type = params['type'];
          this.changeOnClickType = params['type'];
          this.typeOfSearchDate = params['type'];
          this.changeOnClickType = params['type'];
          if(this.typeOfSearch=='monthly'){
            this.dailyFilters=false;
          }
          this.urlDate=params['currdate'];
          this.searchCriteria.device = params['device'];
          this.searchCriteria.lat = +params['lat'];
          this.searchCriteria.long = +params['long'];
          this.updateRecordsOnLabelChangeonLoad(params['type'])
          this.searchCriteria.currdate = FilterHelper.removeSpecialCharacters(params['currdate']);
          this.searchCriteria.radius = params['radius'];
          this.searchCriteria.label = params['label'];
          localStorage.setItem('searchLocation',params['label'].trim())
          let urlDate:any = '';
          if(this.deviceInfo.browser!=='safari')
          {
            urlDate = new Date(this.searchCriteria.currdate);
          }else
          {
            let tzones:any = new Date()
            let startDurati=tzones.toString()
            let locs = startDurati.search("GMT");
              
            let sbustring_timezone = tzones.toString().slice(parseInt(locs)+3,parseInt(locs)+8);
            let  txt2 = sbustring_timezone.slice(0, 3) + ":" + sbustring_timezone.slice(3)
            //let  txt2 = sbustring_timezone.slice(0, 3) + ":" + sbustring_timezone.slice(3)
            let dat = this.searchCriteria.currdate+''+txt2
            urlDate = new Date(dat);
          }
         // let urlDate = new Date(this.searchCriteria.currdate);
         
          let currentDate = FilterHelper.changeNyTime()
          //alert(currentDate);
          if(this.searchCriteria.type=='monthly')
          {
            this.show_strip_search=false;
          }else{
            this.show_strip_search=true;
          }
          currentDate.setHours(currentDate.getHours() - 1);
          if(urlDate<currentDate)
          {
            this.searchCriteria.currdate=FilterHelper.defaultStartDuration();
          //  this.router.navigate(['/search', params['lat'], params['long'], CustomCode.getDateForMapSearchSlashFormat(), params['radius'], params['label'], params['type'], params['device']]);
          }
          
          var searchType=localStorage.getItem('searchType')
      
          if(this.deviceInfo.browser!=='safari')
          {
             let timeZoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone
             this.monthlyDate = this.startDuration = FilterHelper.defaultStartDurationUrlAfterNewHomePage(this.searchCriteria.currdate);
             //this.endDuration = FilterHelper.defaultEndDurationUrl(this.searchCriteria.currdate);
             if(localStorage.getItem('endttimehome'))
            {
              this.endDuration = FilterHelper.defaultEndDurationUrlHome(localStorage.getItem('endttimehome'));
            }else{
              this.endDuration = FilterHelper.defaultEndDurationUrl(this.searchCriteria.currdate);
            }
             if(searchType=='event') {
             let endTime=localStorage.getItem('endtime')
             this.endDuration = FilterHelper.defaultEndDurationUrlEvent(endTime,this.searchCriteria.currdate);
            } 
           if(searchType=='widget') {
             let endTime=localStorage.getItem('endtime')
             this.endDuration = FilterHelper.defaultEndDurationUrlWidget(endTime,this.searchCriteria.currdate);
           }
           if(this.endDuration<this.startDuration) 
           {
            this.endDuration = FilterHelper.defaultEndDurationUrlChnge(this.searchCriteria.currdate);
           }
           
          }
          else{
            let timeZoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone
            this.monthlyDate = this.startDuration = FilterHelper.defaultStartDurationUrlForSafariHome(this.searchCriteria.currdate);
             this.endDuration = FilterHelper.defaultEndDurationUrlForSafari(this.searchCriteria.currdate);
             if(localStorage.getItem('endttimehome'))
            {
              this.endDuration = FilterHelper.defaultEndDurationUrlHomeSfari(localStorage.getItem('endttimehome'));
            }else{
              this.endDuration = FilterHelper.defaultEndDurationUrlForSafari(this.searchCriteria.currdate);
            }
             if(searchType=='event') {
             this.monthlyDate = this.startDuration = FilterHelper.defaultStartDurationUrlForSafariEvents(this.searchCriteria.currdate);
             let endTime=localStorage.getItem('endtime')
             this.endDuration = FilterHelper.defaultEndDurationUrlEventSafari(endTime,this.searchCriteria.currdate);
             }
             if(searchType=='widget') {
             let endTime=localStorage.getItem('endtime')
             this.endDuration = FilterHelper.defaultEndDurationUrlWidgetSafari(endTime,this.searchCriteria.currdate);
           } 
           if(this.endDuration<this.startDuration) 
           {
            this.endDuration = FilterHelper.defaultEndDurationUrlForSafarichange(this.searchCriteria.currdate);
           }
           }
          //this.activateSearchType(2);
          this.startTime = FilterHelper.getFormattedTime(this.startDuration);
          this.endTime = FilterHelper.getFormattedTime(this.endDuration);
          this.updateStartDuration()
        }
      });
    }

    initializeNeighborhoodsFilters() {
      // Do Something When Page is Neighborhoods
      this.startDuration = FilterHelper.defaultStartDuration();
      this.endDuration = FilterHelper.defaultEndDuration();

      this.startTime = FilterHelper.getFormattedTime(this.startDuration);
      this.endTime = FilterHelper.getFormattedTime(this.endDuration);
    }

    initializePaymentFilters() {
      this.deviceInfo = this.deviceService.getDeviceInfo();
      this.activatedRouteObj.params.subscribe((params: Params) => {
        if (params['facId'] && params['facType'] && params['facPay']) {
          let bookingDuration = params['facType'].split('&&');
          let startDuration = bookingDuration[0].split('&');
          let endDuration = bookingDuration[1].split('&');

          let startDate = startDuration[0];
          let startTime = FilterHelper.displayableTimeformat(startDuration[1]);
          let endDate = endDuration[0];
          let endTime = FilterHelper.displayableTimeformat(endDuration[1]);

          this.startTime = startDuration[1];
          this.endTime = endDuration[1];
          this.startDuration = FilterHelper.formatPaymentInitDate(startDate);
          this.endDuration = FilterHelper.formatPaymentInitDate(endDate);

          this.startDuration = FilterHelper.makeDurationDateObject(this.startDuration, startTime);
          let currentDate = FilterHelper.changeNyTime()
          //alert(currentDate);
          if(this.searchCriteria.type=='monthly')
          {
            this.show_strip_search=false;
          }else{
            this.show_strip_search=true;
          }
          currentDate.setHours(currentDate.getHours() - 1);
          if(this.startDuration<currentDate)
          {
            if(this.deviceInfo.browser!=='safari')
            {
              this.searchCriteria.currdate=FilterHelper.defaultStartDuration();
              this.startDuration = FilterHelper.defaultStartDurationUrlAfterNewHomePage(this.searchCriteria.currdate);
              this.startTime = FilterHelper.getFormattedTime(this.startDuration);
            }
          }
          //alert(this.startDuration);
          this.endDuration = FilterHelper.makeDurationDateObject(this.endDuration, endTime);
          if(this.endDuration<=this.startDuration)
          {
            this.endDuration = FilterHelper.makeDurationDateObjectSort(this.startDuration, startTime);
          }
          
          $('#startTime').val(this.startTime);
          $('#endTime').val(this.endTime);

          this.debugDates();

          setTimeout(() => {
            // this.searchCriteria.type = FilterHelper.getCurrentActiveSearchType();
            // this.searchCriteria.currdate = FilterHelper.getArrivalTime(this.startDuration, this.startTime);
            // this.searchCriteria.length_of_stay = this.calculateLengthOfStay();
            // this.firstLoad = true;
            // this.updateFilters.emit(this.searchCriteria);
            this.invokeFilterExecution();
            if(window.self!==window.top){this.isFrame = true;}
          }, 300);
          
        }
      });
    }

    initializeFacilityFilters() {
      this.startDuration = FilterHelper.defaultStartDuration();
      this.endDuration = FilterHelper.defaultEndDuration();
      this.startTime = FilterHelper.getFormattedTime(this.startDuration);
      this.endTime = FilterHelper.getFormattedTime(this.endDuration);
      setTimeout(() => {
        this.invokeFilterExecution();
      }, 300);
    }

    initializePartnerFilters() {
      this.startDuration = FilterHelper.defaultStartDuration();
      this.endDuration = FilterHelper.defaultEndDuration();
      this.startTime = FilterHelper.getFormattedTime(this.startDuration);
      this.endTime = FilterHelper.getFormattedTime(this.endDuration);
      setTimeout(() => {
        this.invokeFilterExecution();
      }, 300);
    }

    activateSearchType() {
      if(this.router.url.indexOf('search') > -1 && this.router.url.indexOf('monthly')>=1)
      {
        this.searchCriteria.type='monthly'
      }
      if (this.searchCriteria.type == 'daily') {
        FilterHelper.activateDailySearchFilter();
      } else if(this.searchCriteria.type == 'monthly') {
        FilterHelper.activateMonthlySearchFilter();
      } else {
        FilterHelper.activateDailySearchFilter();
      }
    }

    debugDates() {
        
    }

    calculateLengthOfStay() {
      let bookingHours:any = FilterHelper.getBookingHours(this.startDuration, this.startTime, this.endDuration, this.endTime);
      //alert(bookingHours)
      return bookingHours;
    }

    updateStartDuration() {
      
      if(this.router.url.indexOf('payment')>-1)
        {
          let p= $('#enddate').val();
          this.endDuration=new Date(p)
          this.endTime=$('#endTime').val();
        }
     if(!sessionStorage.getItem('openByClickNeigh'))
     {
      
     this.endMaxDuration = new Date(this.startDuration);
     this.endMaxDuration.setMonth(this.endMaxDuration.getMonth()+1);
     this.endMaxDuration.setDate(this.endMaxDuration.getDate()-1);
     this.maxEndDate=this.endMaxDuration
      if (this.firstLoad === false) {
        
        if(localStorage.getItem('searchType'))
        {

          this.invokeFilterExecution();
        }
        if(localStorage.getItem('endttimehome'))
        {
          this.invokeFilterExecution();
        }
        
        return;
      } else {
        let  rest = this.startDuration.toString().substring(0, this.startDuration.toString().lastIndexOf(":") + 1);
        let  last = this.startDuration.toString().substring(this.startDuration.toString().lastIndexOf(":") + 1, this.startDuration.toString().length);
        let  replaceRecord = rest.substring(0, rest.length - 6) +" "+this.startTime;
        this.startDuration = replaceRecord+""+last;
        this.startDuration=new Date(this.startDuration);
        let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
        if(startDuration<FilterHelper.changeNyTime())
        {
          this.toastr.error(FilterHelper.PAST_TIME_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
          //his.startTime = FilterHelper.getFormattedTime(FilterHelper.changeNyTime());
        }
        if (startDuration < this.minDate) {
          // if (this.calculateLengthOfStay() < 0.5) {
            this.startTime = FilterHelper.getFormattedTime(this.startDuration);
            $('#startTime').val(this.startTime);
            if (this.calculateLengthOfStay() < 2) {
              this.toastr.error(FilterHelper.PAST_TIME_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
            }
        }
        if (this.calculateLengthOfStay() < 2) {
         
          this.endDuration = new Date(this.startDuration.getTime());
          this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
          
          this.endTime = FilterHelper.getFormattedTime(this.endDuration);
          
          $('#endTime').val(this.endTime);
        }
        
        //alert(this.maxEndDate);
        this.invokeFilterExecution();
      }
    }
    }

    updateStartTime() {
      if(!sessionStorage.getItem('openByClickNeigh'))
     {
      if (this.firstLoad === false) {

        return;

      } else {
        this.debugDates();
        if(this.router.url.indexOf('payment')>-1)
        {
          let p= $('#enddate').val();
          this.endDuration=new Date(p)
          this.endTime=$('#endTime').val();
        }
        let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
        //alert(startDuration)
        //alert(this.minDate)
        if (startDuration < this.minDate) {
          this.startTime = FilterHelper.getFormattedTime(this.startDuration);
          $('#startTime').val(this.startTime);
          this.toastr.error(FilterHelper.PAST_TIME_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
        } else {
          if (this.calculateLengthOfStay() < 2) {
            this.endDuration = new Date(startDuration);
            this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
            this.endTime = FilterHelper.getFormattedTime(this.endDuration);
            $('#endTime').val(this.endTime);
          }
        }
        this.invokeFilterExecution();
      }
    }
    }

    updateEndDuration() {
      if(!sessionStorage.getItem('openByClickNeigh'))
      {
      if (this.firstLoad === false) {
        return;
      } else {
        this.debugDates();
        if(this.router.url.indexOf('payment')>-1)
        {
          let p= $('#enddate').val();
          this.endDuration=new Date(p)
          this.endTime=$('#endTime').val();
        }
        if (this.calculateLengthOfStay() < 2) {
          let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
          this.endDuration = new Date(startDuration);
          this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
          this.endTime = FilterHelper.getFormattedTime(this.endDuration);
          
          $('#endTime').val(this.endTime);
          this.toastr.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
        }
      }
      this.invokeFilterExecution();
    }
    }

    updateEndTime() {
      //alert(333333333)
      if(!sessionStorage.getItem('openByClickNeigh'))
     {
       //alert(3333)
      if (this.firstLoad === false) {
       // alert(2323232323)
        return;
      } else {
        this.debugDates();
        if(this.router.url.indexOf('payment')>-1)
        {
          let p= $('#enddate').val();
          this.endDuration=new Date(p)
          this.endTime=$('#endTime').val();
        }
        let endDuration = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
        if (endDuration < this.endDuration) {
          if (this.calculateLengthOfStay() < 2) {
            let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
            this.endDuration = new Date(startDuration);
            this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
            this.endTime = FilterHelper.getFormattedTime(this.endDuration);
            $('#endTime').val(this.endTime);
            this.toastr.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
          } 
        }
        this.invokeFilterExecution();
      }
    }
    }
    invokeFilterExecution() {
      this.searchCriteria.dats=this.startDuration
      this.searchCriteria.strattimes=this.startTime
      this.searchCriteria.enddats=this.endDuration
      this.searchCriteria.endtimes=this.endTime
      this.searchCriteria.type = FilterHelper.getCurrentActiveSearchType();
      this.searchCriteria.currdate = FilterHelper.getArrivalTime(this.startDuration, this.startTime);
      this.searchCriteria.length_of_stay = this.calculateLengthOfStay();
      this.firstLoad = true;
      this.updateFilters.emit(this.searchCriteria);
    }
 
    
    updateRecordsOnLabelChangeonLoad(val) {
      
      if(val=='monthly')
      {
        // this.dealsBtn = true;
        this.monthlyFilter = true;
        this.dailyFilter = false;
        this.show_strip_search=false;
      }else
      {
        // this.dealsBtn = false;
        this.monthlyFilter =false;
        this.dailyFilter = true;
        this.show_strip_search=true;
      }
      if(val=='daily')
      {
        this.dailyFilter = true;
        this.show_strip_search=false;
      }else
      {
        this.show_strip_search=true;
      }
      this.changeDetectorRef.detectChanges();
      //this.searchDate
    }
    updateRecordsOnLabelChangeMobile(val) {
      localStorage.removeItem('mapDrag');
      localStorage.setItem('searchType',val);
      if(localStorage.getItem('showNeighbordood')){
        localStorage.removeItem('showNeighbordood');
      }
      // console.log('removed')
      // if (this.router.url.indexOf('facility') > -1) { 
      //   return false;
      // }
     this.typeOfSearch=val
     this.changeOnClickType=val;
     //sessionStorage.setItem('openByClick','1');
     if(val=='monthly')
      {
        if(this.router.url.indexOf('facility') > 0 ){
          $('.dailymonthlybuttonsMobile').removeClass('active');
          $('#monthly').addClass('active');
          $('#dailyFilter').addClass('hidden');
          $('#monthlyFilter').removeClass('hidden');
        }
        this.dailyFilters = false;
        this.dailyFilter = false;
        this.monthlyFilter=true;
      } else {
        if(this.router.url.indexOf('facility') > 0 ){
          $('.dailymonthlybuttonsMobile').removeClass('active');
          $('#daily').addClass('active');
          $('#monthlylyFilter').addClass('hidden');
          $('#dailyFilter').removeClass('hidden');
        }
        this.dailyFilters = true;
        this.dailyFilter = true;
        this.monthlyFilter=false;
      }
      this.changeDetectorRef.detectChanges();
    }
    searchFacilityOnSearch()
    {
     localStorage.removeItem('mapDrag');
      this.typeOfSearchDate=this.changeOnClickType
      if (this.router.url.indexOf('neighborhoods') > 0) { 
        $('#closeButton').trigger('click')
        $('#closeButton1').trigger('click')
        sessionStorage.removeItem('openByClickNeigh')
        this.updateStartDuration();
      }if (this.router.url.indexOf('facility') > -1) { 
        $('#closeButton').trigger('click')
        $('#closeButton1').trigger('click')
        this.updateStartDuration();
      }else{
        sessionStorage.removeItem('openByClick')
        let device=this.searchCriteria.device 
        let lat=  this.searchCriteria.lat
        let long = this.searchCriteria.long
        let time= this.urlDate
        let label=this.searchCriteria.label
        if(localStorage.getItem('latNew')){ lat =  localStorage.getItem('latNew') }
        if(localStorage.getItem('longNew')){ long =  localStorage.getItem('longNew') }
        if(localStorage.getItem('locationNew')){ label =  localStorage.getItem('locationNew') }

        this.location.replaceState('/search/'+lat+'/'+long+'/'+time+'/2/'+label+'/'+this.typeOfSearch+'/'+device); 
        sessionStorage.setItem('searchTypeByMobile',this.typeOfSearch)
        $('#startDateWithID').val(this.startDuration)
      $('#endDateWithID').val(this.endDuration)
       this.invokeFilterExecutionMobile(this.typeOfSearch);
        $('#closeButton').trigger('click')
        $('#closeButton1').trigger('click')
        if(this.typeOfSearch == 'monthly'){  
          // this.dealsBtn = true;
        }
      }
      
    }
    invokeFilterExecutionMobile(typeOfSearch) {
      this.searchCriteria.type = FilterHelper.getCurrentActiveSearchType();
      if(typeOfSearch=='monthly')
      {
        this.searchCriteria.currdate = FilterHelper.getArrivalTime(this.monthlyDate, this.startTime);
        $('#dailyFilter').addClass('hidden');
				$('#monthlyFilter').removeClass('hidden');
					let times_run= setInterval(function () {
						$('body').addClass('showChat');
					}, 4000);
					setTimeout(function () {
						clearInterval(times_run);
					}, 10000);
      }else{
        this.searchCriteria.currdate = FilterHelper.getArrivalTime(this.startDuration, this.startTime);
        let times_runhide= setInterval(function () {
          $('body').removeClass('showChat');
        }, 100);
        setTimeout(function () {
          clearInterval(times_runhide);
        }, 7000);
      }
      if(localStorage.getItem('latNew')){ this.searchCriteria.lat =  localStorage.getItem('latNew') }
        if(localStorage.getItem('longNew')){ this.searchCriteria.long =  localStorage.getItem('longNew') }
        if(localStorage.getItem('locationNew')){ this.searchCriteria.label =  localStorage.getItem('locationNew') }
      
      this.searchCriteria.length_of_stay = this.calculateLengthOfStay();
      this.firstLoad = true;
      ////1111111111111111
      this.updateFiltersMobile.emit(this.searchCriteria);

      
    }
    updateRecordsOnLabelChange(val) {
      localStorage.setItem('searchType',val);
      if(localStorage.getItem('showNeighbordood')){
        //localStorage.removeItem('showNeighbordood');
      }
      //alert(3333331111)
      this.typeOfSearch=val
      if(this.router.url.indexOf('partners') > 0 && val=='monthly')
      {
        
        return false
      }
      if(val=='monthly')
      {
        if(this.router.url.indexOf('facility') > 0 ){
          $('.dailymonthlybuttons').removeClass('active');
          $('#monthly').addClass('active');
          $('#dailyFilter').addClass('hidden');
          $('#monthlyFilter').removeClass('hidden');
        }
        this.monthlyFilter = true;
        this.dailyFilter = false;
        this.show_strip_search=false;
      }else
      {
        this.monthlyFilter =false;
        this.dailyFilter = true;
        this.show_strip_search=true;
      }
      if(val=='daily')
      { 
        if(this.router.url.indexOf('facility') > 0 ){
        $('.dailymonthlybuttons').removeClass('active');
        $('#daily').addClass('active');
        $('#dailyFilter').removeClass('hidden');
        $('#monthlyFilter').addClass('hidden');
      }
        this.dailyFilter = true;
        this.show_strip_search=false;
      } else
      {
        this.show_strip_search=true;
      }
      //alert(this.router.url.indexOf('search'))
      if (this.router.url.indexOf('search') > -1) { 
        this.isSearch = true;
        this.updateSearchTypeModule(val);
        //alert(5555555555)
        this.searchDate()
      }
      if (this.router.url.indexOf('neighborhoods') > 0 || this.router.url.indexOf('monthly-parking') > 0) { 
        this.isNeighborhoods = true;
       // alert(2)
        this.updateNeighborhoodTypeModule(val);
      }
      if (this.router.url.indexOf('payment') > 0) {
        this.isPayment = true;
        this.updatePaymentTypeModule(val);
      }
      if (this.router.url.indexOf('partners') > 0) {
        this.isPartner = true;
        this.updatePartnerTypeModule(val);
      }
    }
    tryPrintScaleValue() {
      //alert(1)
      localStorage.removeItem('mapDrag');
     if(this.monthlypark)
     this.searchCriteria.is_monthlypark =this.monthlypark
     else
     this.searchCriteria.is_monthlypark =false
     if(this.motorcycle)
     this.searchCriteria.is_motorcycle_parking =this.motorcycle
     else
     this.searchCriteria.is_motorcycle_parking =false
     if(this.oversized)
     this.searchCriteria.is_oversized =this.oversized
     else
     this.searchCriteria.is_oversized =false
     if(this.evcharging)
     this.searchCriteria.is_generic_ev_charging =this.evcharging
     else
     this.searchCriteria.is_generic_ev_charging =false
     if(this.tesla)
     this.searchCriteria.is_tesla_charging =this.tesla
     else
     this.searchCriteria.is_tesla_charging =false
     if(this.outdoor)
     this.searchCriteria.is_outdoor_parking = this.outdoor
     else
     this.searchCriteria.is_outdoor_parking = false
     if(this.indoor)
     this.searchCriteria.is_indoor_parking =this.indoor
     else
     this.searchCriteria.is_indoor_parking =false
     if(this.twentyhour)
     this.searchCriteria.is_twentyhour =this.twentyhour
     else
     this.searchCriteria.is_twentyhour =false
     if(this.is_deal)
     {
      localStorage.setItem('showMonthlyDeal', '1')
      this.searchCriteria.monthly_campaign =this.is_deal;
     }
     else{
      localStorage.removeItem('showMonthlyDeal');
      this.searchCriteria.monthly_campaign =false;
     }
    
     if(this.searchCriteria.is_monthlypark || this.searchCriteria.monthly_campaign || this.searchCriteria.is_indoor_parking || this.searchCriteria.is_outdoor_parking
      || this.searchCriteria.is_tesla_charging || this.searchCriteria.is_generic_ev_charging || this.searchCriteria.is_oversized
      || this.searchCriteria.is_motorcycle_parking ||  this.searchCriteria.is_monthlypark)
      {
        localStorage.setItem('filterApply','1')
      }else{
        //localStorage.removeItem('filterApply','1')
      }
      if(this.searchCriteria.monthly_campaign)
        {
          localStorage.setItem('filterApplyDeal','1')
        }
     this.updateFacilityFilters.emit(this.searchCriteria);
    }
    check()
    {
      //alert(this.dealstoggle)
      this.is_deal=this.dealstoggle;
      this.tryPrintScaleValue();
    }
    getMonthlyDate() {
      this.searchCriteria.type = "monthly";
      //this.monthlyDate = this.startDuration
      this.startDurationMonthly=this.monthlyDate;
      let currDate = FilterHelper.formatDate(this.monthlyDate) + ' ' + this.startTime;
      this.searchCriteria.currdate = currDate.toLocaleString().replace(/[^ -~]/g,'');

      if (this.router.url.indexOf('monthly') > 0 || (this.router.url.indexOf('monthly-parking') > 0) ) { 
        this.searchCriteria.type = "monthly";
      }else{
        this.searchCriteria.type = "daily";
      }
      if( this.router.url.indexOf('daily') >0 ){
        this.searchCriteria.type = "daily";
      }

      if( this.router.url.indexOf('payment') > -1 ){

      }else{
        this.updateFilters.emit(this.searchCriteria);
      }
    }
    // getNeighbourDetails(){
    //   this.searchCriteria.type = "daily";
    //   //this.monthlyDate = this.startDuration
    //   this.startDurationMonthly=this.monthlyDate;
    //   let currDate = FilterHelper.formatDate(this.monthlyDate) + ' ' + this.startTime;
    //   this.searchCriteria.currdate = currDate.toLocaleString().replace(/[^ -~]/g,'');
    //   this.updateFilters.emit(this.searchCriteria);
    // }
    getNeighbourDetails(){
      if (this.router.url.indexOf('monthly') > 0 || (this.router.url.indexOf('monthly-parking') > 0) ) { 
        this.searchCriteria.type = "monthly";
      }else{
        this.searchCriteria.type = "daily";
      }
      if( this.router.url.indexOf('daily') >0 ){
        this.searchCriteria.type = "daily";
      }
      //this.searchCriteria.type = "daily";
      //this.monthlyDate = this.startDuration
      //alert(this.monthlyDate);
      
      this.a = this.startDurationMonthly=this.monthlyDate;
     this.c = setInterval(()=>{
        this.monthlyDate=this.a;
      },100)
      let currDate = FilterHelper.formatDate(this.monthlyDate) + ' ' + this.startTime;
      this.searchCriteria.currdate = currDate.toLocaleString().replace(/[^ -~]/g,'');
      this.updateFilters.emit(this.searchCriteria);
    }
    getMonthlyDateMobile()
    {
      this.searchCriteria.type = "monthly";
      //this.monthlyDate = this.startDuration
      let currDate = FilterHelper.formatDate(this.monthlyDate) + ' ' + this.startTime;
      this.searchCriteria.currdate = currDate.toLocaleString().replace(/[^ -~]/g,'');
      //this.updateFilters.emit(this.searchCriteria);
    }
    updateEndDurationMobile() {
      sessionStorage.setItem('openByClick','1');
      if (this.router.url.indexOf('neighborhoods') > 0) { 
        sessionStorage.setItem('openByClickNeigh','1');
      }
      if (this.firstLoad === false) {
        return;
      } else {
        this.debugDates();
        if (this.calculateLengthOfStay() < 2) {
          let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
          this.endDuration = new Date(startDuration);
          this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
          this.endTime = FilterHelper.getFormattedTime(this.endDuration);
          $('#endTime').val(this.endTime);
          this.toastr.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
        }
      }
      //this.invokeFilterExecution();
    }
    updateStartDurationMobile() {
      sessionStorage.setItem('openByClick','1');
      if (this.router.url.indexOf('neighborhoods') > 0) { 
        sessionStorage.setItem('openByClickNeigh','1');
      }
      this.endMaxDuration = new Date(this.startDuration);
      this.endMaxDuration.setMonth(this.endMaxDuration.getMonth()+2);
      //this.maxEndDate=this.endMaxDuration
      this.endMaxDuration.setMonth(this.endMaxDuration.getMonth()+1);
     this.endMaxDuration.setDate(this.endMaxDuration.getDate()-1);
     this.maxEndDate=this.endMaxDuration
       if (this.firstLoad === false) {
         if(localStorage.getItem('searchType'))
         {
           //this.invokeFilterExecution();
         }
         
         return;
       } else {
         let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
         if (startDuration < this.minDate) {
           // if (this.calculateLengthOfStay() < 0.5) {
             this.startTime = FilterHelper.getFormattedTime(this.startDuration);
             $('#startTime').val(this.startTime);
             if (this.calculateLengthOfStay() < 2) {
               this.toastr.error(FilterHelper.PAST_TIME_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
             }
         }
         if (this.calculateLengthOfStay() < 2) {
           
           this.endDuration = new Date(this.startDuration.getTime());
           this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
           this.endTime = FilterHelper.getFormattedTime(this.endDuration);
           $('#endTime').val(this.endTime);
         }
         
         //alert(this.maxEndDate);
         //this.invokeFilterExecution();
       }
     }
     updateStartTimeMobile() {
      sessionStorage.setItem('openByClick','1');
      if (this.router.url.indexOf('neighborhoods') > 0) { 
        sessionStorage.setItem('openByClickNeigh','1');
      }
      if (this.firstLoad === false) {

        return;

      } else {
        this.debugDates();
        let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
        if (startDuration < this.minDate) {
          this.startTime = FilterHelper.getFormattedTime(this.startDuration);
          $('#startTime').val(this.startTime);
          this.toastr.error(FilterHelper.PAST_TIME_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
        } else {
          if (this.calculateLengthOfStay() < 2) {
            this.endDuration = new Date(startDuration);
            this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
            this.endTime = FilterHelper.getFormattedTime(this.endDuration);
            $('#endTime').val(this.endTime);
          }
        }
       // this.invokeFilterExecution();
      }
    }
    updateEndTimeMobile() {
      sessionStorage.setItem('openByClick','1');
      if (this.router.url.indexOf('neighborhoods') > 0) { 
        sessionStorage.setItem('openByClickNeigh','1');
      }
      if (this.firstLoad === false) {
        return;
      } else {
        this.debugDates();
        let endDuration = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
        if (endDuration < this.endDuration) {
          if (this.calculateLengthOfStay() < 2) {
            let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
            this.endDuration = new Date(startDuration);
            this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
            this.endTime = FilterHelper.getFormattedTime(this.endDuration);
            $('#endTime').val(this.endTime);
            this.toastr.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
          } 
        }
        //this.invokeFilterExecution();
      }
    }
    updateSearchTypeModule(val) {
      let date_after_merge:any='';
      if(this.searchCriteria.currdate.indexOf("-")>0)
      {
        let date_monthly_daily =this.searchCriteria.currdate.replace(/-/g, "/").split('/')
        let date_time=date_monthly_daily[2].split(' ')
        date_after_merge = date_monthly_daily[1]+'/'+date_time[0]+'/'+date_monthly_daily[0]+' '+date_time[1] 
      }else{
        date_after_merge=this.searchCriteria.currdate
      }

       let currdate=CustomCode.getDateForMapSearchSlashFormatForUrl(date_after_merge)
        if (val === 'daily') {
          FilterHelper.activateDailySearchFilter();
         // this.router.navigate(['/search', this.searchCriteria.lat, this.searchCriteria.long, currdate, this.searchCriteria.radius, this.searchCriteria.label, val, CustomCode.searchMode()]);
        } else {
          FilterHelper.activateMonthlySearchFilter();
         // this.router.navigate(['/search', this.searchCriteria.lat, this.searchCriteria.long,currdate, this.searchCriteria.radius, this.searchCriteria.label, val, CustomCode.searchMode()]);
       }
    }

    updateNeighborhoodTypeModule(val) {

        if (val === 'daily') {
          FilterHelper.activateDailySearchFilter();
          this.searchCriteria.type = 'daily';
          this.searchCriteria.currdate = FilterHelper.getArrivalTime(this.startDuration, this.startTime);
          this.updateFiltersNei.emit(this.searchCriteria);
        } else {
          FilterHelper.activateMonthlySearchFilter();
          this.searchCriteria.type = 'monthly';
          this.searchCriteria.currdate = this.monthlyDate=new Date();
          this.updateFiltersNei.emit(this.searchCriteria);
        }
    }

    updatePartnerTypeModule(val) {
        if (val === 'daily') {
          FilterHelper.activateDailySearchFilter();
          this.searchCriteria.type = 'daily';
          this.searchCriteria.currdate = FilterHelper.getArrivalTime(this.startDuration, this.startTime);
          this.updateFilters.emit(this.searchCriteria);
        } else {
          FilterHelper.activateMonthlySearchFilter();
          this.searchCriteria.type = 'monthly';
          this.searchCriteria.currdate = this.monthlyDate;
          this.updateFilters.emit(this.searchCriteria);
        }
    }

    updatePaymentTypeModule(val) {
      // Code Goes Here ...
    }

    public openModal(template) {
      sessionStorage.removeItem('editTime')
      sessionStorage.setItem('openByClick','1');
      this.modalRef = this.modalService.show(template);
    }

    updateTier(showBadge){
      this.updateTierToNeighbour.emit(showBadge);
    }

    showDeals(){
      if(localStorage.getItem('showDeals')){
        localStorage.removeItem('showDeals');
      }else {
        localStorage.setItem('showDeals','1');
      }      
    }
    searchFacility()
    {
      $('#startDateWithID').val(this.startDuration)
      $('#endDateWithID').val(this.endDuration)
      this.updateFiltersOnSubmit.emit(this.searchCriteria);
    }
    searchDate()
    {
      localStorage.removeItem('mapDrag');
      localStorage.removeItem('filterApply')
    //  localStorage.removeItem('neighbourhoodActive')
   // localStorage.removeItem('showNeighbordood')
   // localStorage.removeItem('sortBy')
      let startTime=CustomCode.getDateForMapSearchSlashFormatHome(this.startDuration,this.startTime)
      let endTime=CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime)
      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
      let lat:any =localStorage.getItem('latNew')
      let long:any =localStorage.getItem('longNew')
      let location:any =localStorage.getItem('locationNew') 
      //
      this.searchCriteria.lat=lat
      this.searchCriteria.long=long
      this.searchCriteria.location=location
      // console.log(this.searchCriteria);
      if(lat=='' || lat =='undefined' || lat ==undefined || lat == null || lat =='null' ||long=='' || long =='undefined' || long ==undefined || long == null || long =='null')
      {
         lat=40.7589
         long =-73.9851
         location ='Times Square'
      }
      this.searchCriteria.lat=lat
      let times=1000
      if(this.router.url.indexOf('monthly-parking') > -1)
      {
        times=5500;
      }
      if(this.router.url.indexOf('search') > -1)
      {
        let number =2;
        if(this.router.url.indexOf('/2/') > -1 && localStorage.getItem('mapDrag1'))
        {
          number=1;
        }
        else{
          number=2;
        }
        localStorage.removeItem('mapDrag1');
        this.router.navigate(['/search', lat, long, startTime, number, location,this.typeOfSearch,CustomCode.searchMode()]);
      }
      if(location=='new-york'){
        $('.selectAuto').val('');
      }else{
        $('.selectAuto').val(location);
      }
      setTimeout(() => {
        this.searchFacility();
      }, times);
      //
      
    }
    searchDates()
    {
      localStorage.removeItem('filterApply');
      let startTime=CustomCode.getDateForMapSearchSlashFormatHome(this.startDuration,this.startTime)
      let endTime=CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime)
      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
      let lat:any =localStorage.getItem('latNew')
      let long:any =localStorage.getItem('longNew')
      let location:any =localStorage.getItem('locationNew') 
      //
      this.searchCriteria.lat=lat
      this.searchCriteria.long=long
      this.searchCriteria.location=location
      // console.log(this.searchCriteria);
      if(lat=='' || lat =='undefined' || lat ==undefined || lat == null || lat =='null' ||long=='' || long =='undefined' || long ==undefined || long == null || long =='null')
      {
         lat=40.7589
         long =-73.9851
         location ='Times Square'
      }
      this.searchCriteria.lat=lat
      $('.selectAuto').val(this.searchCriteria.location)
      //alert(this.searchCriteria.location);
      setTimeout(() => {
        this.searchFacility();
      }, 3500);
    }
    resetSidebarFilter(){
      if(this.router.url.indexOf('monthly-parking') > -1 ) 
      {
      }else{
        localStorage.removeItem('showNeighbordood');
      
      localStorage.removeItem('neighbourhoodActive')
      localStorage.removeItem('showNeighbordood');
     
     
      }
      localStorage.removeItem('sortBy')
      sessionStorage.removeItem('tabview');
      localStorage.setItem('resetSearch','1')
      
    }   
    
    hideStripeMonhthly()
    {
      localStorage.removeItem('is_landing_page_monthly_stripe')
     // $('aside.contentBar').css({'height':'calc(100% - 140px);'})
      this.showOnMothly=0;
      
      //this.sidebarHeightUpdate();
    }

    updateData()
    {
      this.searchDate();
    }  
}

