
import { Component, OnInit, Input } from '@angular/core';
import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-billing-information',
  templateUrl: './billing-information.component.html',
  styleUrls: ['./billing-information.component.scss'],
  providers: [MyAccountMonthlyParkingService]
})
export class BillingInformationComponent implements OnInit {

  @Input() currentActive: string;
  @Input() accountNumber: any;

  formData: any = {};
  phoneMask: any = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  billingInformation: any = {};

  isLoaded = false;
  secondryPhone = false;

  constructor(private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService, private toastr: ToastrService) { }

  ngOnInit() {
    // $(":input").inputmask();
  	this.getBillingInformation();
  }

  getBillingInformation() {
  	this.myAccountMonthlyParkingObj.billingInformation(this.accountNumber)
    .then(
      data => {
        this.isLoaded = true;
        this.formData = data['data'];
        this.updateFormFieldFloats();
        if (this.formData.phone_type_two !== '') {
          this.showSecondryPhone();
        }
      },
      error => {
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }

  showSecondryPhone() {
  	if (this.secondryPhone) {
  		this.secondryPhone = false;
  		$('button.addSecondryButton').html('Add Secondary Number&nbsp;&nbsp;<i class="fa fa-plus"></i>');
      this.formData.phone_number_two = '';
      this.formData.phone_type_two = 'none';
      this.formData.phone_ext_two = '';
      this.formData.phone_contact_two = '';
  	} else {
  		this.secondryPhone = true;
  		$('button.addSecondryButton').html('Remove Secondary Number&nbsp;&nbsp;<i class="fa fa-minus"></i>');
  	}
    this.updateFormFieldFloats();
  }

  updateBillingInformation(formData, form: NgForm) {
    const reg =/<(.|\n)*?>/g; 
    if (reg.test(formData.company_name) == true) {
      this.toastr.error('HTML Tags are not allowed in Company Name.', 'Sorry!');
      return false;
    } else {
        if (reg.test(formData.address_one) == true) {
          this.toastr.error('HTML Tags are not allowed in Address1.', 'Sorry!');
          return false;
        } else{
          if (reg.test(formData.address_two) == true) {
            this.toastr.error('HTML Tags are not allowed  in Address2.', 'Sorry!');
            return false;
          } else {
            if (reg.test(formData.city) == true) {
              this.toastr.error('HTML Tags are not allowed in City.', 'Sorry!');
              return false;
            }else{
              if (reg.test(formData.state) == true) {
                this.toastr.error('HTML Tags are not allowed in State.', 'Sorry!');
                return false;
              }
              else{
                if (reg.test(formData.zip) == true) {
                  this.toastr.error('HTML Tags are not allowed in Zip.', 'Sorry!');
                  return false;
                }
                else{
                  if (reg.test(formData.country) == true) {
                    this.toastr.error('HTML Tags are not allowed in Country.', 'Sorry!');
                    return false;
                  }
                  else{
                    if (reg.test(formData.phone_extn_one) == true) {
                      this.toastr.error('HTML Tags are not allowed in phone extension.', 'Sorry!');
                      return false;
                    }
                    else{
                      if (reg.test(formData.phone_contact_one) == true) {
                        this.toastr.error('HTML Tags are not allowed in phone contact.', 'Sorry!');
                        return false;
                      }
                      else{
                        //return true;
                      }
                    }
                  }
                }
              }
            }
          }
        }
    }
    const data = formData;
    data.phone_number_one = data.phone_number_one.replace(/\D/g, '');
    data.phone_number_two = data.phone_number_two.replace(/\D/g, '');
    this.myAccountMonthlyParkingObj.updatebillingInformation(data, this.accountNumber)
    .then(
      data => {
        if (!data['errors']) {
          this.toastr.success('Billing Information Updated Successfully', 'Great!');
        } else {
          this.toastr.error('Some Error Occured, Try Again', 'Sorry!');
        }
      },
      error => {
        let errorMessage = error.errors.message;
        if (error.errors.message === 'Validation Errors') {
          errorMessage = '';
          for (const key in error.errors.detail) {
              errorMessage += error.errors.detail[key];
          }
        }
        this.toastr.error(errorMessage, 'Sorry!');
      }
    );
  }

  updateFormFieldFloats() {
  	setTimeout(function(){
  		$('form#billingForm input, form#billingForm select').each(function() {
	  		if ($(this).val() != null && $(this).val().toString().trim().length > 0) {
	  			$(this).parents('.form-group').addClass('focused');
	  		}
	  	});
  	}, 500);

  }

  restDigit(event: any){
    if (event.target.value.length > 1 && event.keyCode != 8 && event.keyCode != 9) {
      event.preventDefault();
      this.toastr.error('Please enter valid two digit state code.', 'Sorry!');
    }
  }
}
