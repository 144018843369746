import {Injectable} from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {Subject} from 'rxjs/Rx';
import { UserserviceService } from './userservice.service';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import * as $ from 'jquery';
import { CustomCode } from 'app/shared/CustomCode';

@Injectable({
  providedIn: 'root'
})
export class HttpService  {
  headers = new HttpHeaders();
  access_token_call_status=0;
  constructor (private http: HttpClient) {
    //const token = localStorage.getItem('access_token');
    //const bigApple = localStorage.getItem('X-Big-Apple');
    //options.headers.set('Authorization', 'Bearer ' + token);
    //options.headers.set('X-Big-Apple',bigApple);
    //super(backend, options);
  }

  getokta(url,token): Observable<any>{
    const bigApple = localStorage.getItem('X-Big-Apple');
    return this.http.get<any>(url,{headers: new HttpHeaders({'AuthorizationOkta':  token,'OktaTokenType': 'web','X-Big-Apple':bigApple})});
    
  }

  postsocial(url: string, body: any, headers?: HttpHeaders): Observable<any>{  
  return this.http.post(url,body).catch(this.catchAuthError(this, url,{}));        
  }

  post(url: string, body: any, headers?: HttpHeaders): Observable<any>{
    headers = new HttpHeaders();    
    const token = localStorage.getItem('access_token');
    const bigApple = localStorage.getItem('X-Big-Apple');
    headers.set('AuthorizationOkta',  token);
    headers.set('X-Big-Apple',bigApple);  
      
     return this.http.post(url,body, {headers}).catch(this.catchAuthError(this, url, {headers}));        
          
   }
   Getrequest(url: string): Observable<any> {
    const bigApple = localStorage.getItem('X-Big-Apple');
    return this.http.get<any>(url,{headers: new HttpHeaders({'X-Big-Apple':bigApple})});
  }
  request(url: string, headers?: HttpHeaders): Observable<any> {
    const access_token = localStorage.getItem('access_token');
    const bigApple = localStorage.getItem('X-Big-Apple');
    // this.headers.set('Authorization', 'Bearer ' + access_token);   
      this.headers.set('AuthorizationOkta',  access_token);
      this.headers.set('X-Big-Apple',bigApple);
      this.headers.set('OktaTokenType','web');
    
    //let notLoad=1;
    // if(url['url'].indexOf('facility_llc')>-1 || url['url'].indexOf('mobile-device-version/web')>-1 ) 
    // {
    //   notLoad=0;
    // }else{
    //   notLoad=1
    // }
    
    
    if (!localStorage.getItem('firsttest') ) {
      localStorage.setItem('firsttest', '1')

      let versionUrl = CustomCode.APIURL + 'mobile-device-version/web';
      const options = CustomCode.returnHeaders();
      this.http.get(versionUrl, { headers: headers })
        .map((res: any) => res).subscribe((done) => {
          let res = done['data'].web_version
          let b = this.getCookie('versions')
          console.log('version reload test')
          if (b == '') {
            this.setCookie('versions', res, 10000)
            //location.reload(true);
          } else if (b != res) {
            this.setCookie('versions', res, 10000)
            //location.reload();
            console.log('version reload test 1')
            window.location.href = window.location.href
            //location.reload(true);
          }

        }, (error) => {
          console.log(error)
        });


    }
    setTimeout(() => {
      localStorage.removeItem('firsttest')
     }, 10000);
   // this.setCookie('version','1.0',10000)
    
   // alert(b)
   console.log('aaa',url)
    return this.http.get(url, { headers: new HttpHeaders({'OktaTokenType':  'web','AuthorizationOkta': access_token,'X-Big-Apple':bigApple})}).catch(this.catchAuthError(this, url, { headers: new HttpHeaders({'OktaTokenType':  'web','AuthorizationOkta':  access_token,'X-Big-Apple':bigApple})}));
  }
  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
   getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  private catchAuthError (self: HttpService, url, options) {
    return (res: any) => {
      let response = res;
      if((response.status === 401) && (response.errors.message == "The user credentials were incorrect.") ) {
         return Observable.throwError(res);
      } else if (res.status === 401 || res.status === 403) {
        this.handleSessionRefresh();
      } else if (res.status === 400) {
        // Logout and redirect to Home Page ...
        this.userLogout();
        var urlChunks = window.location.href.split('/');
        var newURL = urlChunks[0] + '//' + urlChunks[2];
        if (window.location.href.indexOf('.com') > 0) {
          window.location.href = newURL;
        }
      } else {
        return Observable.throwError(res);
      }
    };
  }

  public userLogout() {
    localStorage.clear();
  }
  handleSessionRefresh() {
    this.access_token_call_status = 1;
    const refTokenData = {
      'grant_type': 'refresh_token',
      'client_id': CustomCode.clientId,
      'client_secret': CustomCode.clientSecret,
      'refresh_token': localStorage.getItem('refresh_token'),
      'remember_me': null
    };
    this.refreshToken(refTokenData)
      .subscribe(
        (data: any) => {
          let responseData = data.data;
          localStorage.setItem('user', responseData.user);
          localStorage.setItem('access_token', responseData.access_token);
          localStorage.setItem('refresh_token', responseData.refresh_token);
          localStorage.setItem('expires_in', responseData.expires_in);
          localStorage.setItem('token_type', responseData.token_type);
          window.location.reload();
        },
        error => {
          this.userLogout();
          return false;
        }
      );
  }
  refreshToken(data) {
    let loginUrl = CustomCode.APIURL + 'oauth/access_token';
    return this.http.post(loginUrl, data)
      .retry(3)
      .map(
        (res: Response) => res
      )
    // .catch(
    //   this.userLogout();
    //   this.catchAuthError(this, loginUrl, data)
    // );
  }
  public displayLoginUser() {
    const loggedUserName = this.getloggedInUserDetails();
    if (loggedUserName) {
        $('#guestuser').css('display', 'none');
        $('#loggeduser').css('display', 'block');
      } else {
       $('#guestuser').css('display', 'block');
        $('#loggeduser').css('display', 'none');
      }
  }

  public getloggedInUserDetails() {
    let loggedUser = '';
    if (localStorage.getItem('access_token')) {
        loggedUser = localStorage.getItem('username');
    }
    return loggedUser;
  }
}

