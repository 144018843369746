import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nyp',
  templateUrl: './nyp.component.html'
})
export class NypComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
