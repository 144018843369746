import { Injectable } from '@angular/core';

//import { Http, Headers, RequestOptions, Response } from '@angular/http';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { HttpService } from './http.service';
import { Observable } from 'rxjs/Rx';
import { CustomCode } from '../shared/CustomCode';
import { DomSanitizer } from '@angular/platform-browser';

import * as FileSaver from 'file-saver';

// Import RxJs required methods
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResetEmailService {

constructor(private http: HttpClient) { }

private getUsersDetails = CustomCode.APIURL + 'api';
private getResetUserEmail = CustomCode.APIURL + 'user/';

  
  fetchUser() {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersDetails).subscribe(
        res => {
			
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  //{"email":"vishal.gupta@outworx.com","email_confirmation":"vishal.gupta@outworx.com"}
  resetuseremail(chpadata,userid){
	  
	const headers = new HttpHeaders();
    const access_token = localStorage.getItem('access_token');
	headers.append('AuthorizationOkta', 'Bearer ' + access_token);
    //const options = new RequestOptions({ headers: headers });
	const formDetails = {'email': chpadata.email,'email_confirmation': chpadata.email_confirmation};
	
    return this.http.post(this.getResetUserEmail+userid+'/reset-email', formDetails, { headers: headers })
    .map((res: Response) => res)
    .catch((error: any) => Observable.throw(error));  
	  
  }
  

}

