import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { CustomCode } from '../../../shared/CustomCode';
import { UserserviceService } from '../../../services/userservice.service';
import { ToastrService } from 'ngx-toastr';
import { SortHistoryPipe } from '../../../pipes/sort-history.pipe';
//import * as moment from 'moment-timezone';
@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss'],
  providers: [UserserviceService]
})
export class TransactionHistoryComponent implements OnInit {
  @Output() setTransaction = new EventEmitter<boolean>();
  isFetched:boolean = false;
  constructor(
    private userserviceServiceObj: UserserviceService,
    private toasterObj: ToastrService
  ) { }
  
  private reservations: any = []; // array of all items to be paged
  pager: any = {}; // pager object
  pagedItems: any = []; // paged items
  isTransLoaded:boolean = false;
  historyIcon = 'assets/img/icon-tran-history.svg';
  ngOnInit() {
    this.userserviceServiceObj.GetLoyaltyHistory()
		.subscribe(
			data => {
        let resv = data.data.history;
        // let resv = [];
        let resData = [];
        if(resv.length!==0 && resv !==undefined){
          resv.forEach(e => {
            let am = e.amount;
            let str = am.toString().replace().replace(/\-/g,'');
            let frmNo = parseInt(str, 10);
            resData.push({
              //'transaction_date':moment(e.date).format('MM/DD/YYYY'),
              'transaction_date':e.date,
              'facility_full_name':e.message,
              'type':e.type,
              'formatted_point':frmNo,
              'loyalty_point_used':e.amount
            });
          });
        }
        
        resData.sort(function(a, b) {
            a = new Date(a.transaction_date);
            b = new Date(b.transaction_date);
            return a>b ? -1 : a<b ? 1 : 0;
        });
        
        this.reservations = resData;
        if(this.reservations.length!==0 && this.reservations !==undefined){
          // initialize to page 1
          this.setPage(1);
          this.setTransactionVar(true);
        }
        this.isTransLoaded = true;
			},
			error => {
				this.toasterObj.error(error.errors.message);
        this.isTransLoaded = true;
			}
    );
  }

  setPage(page: number) {
    // get pager object from service
    this.pager = CustomCode.getPager(this.reservations.length, page);
    // get current page of items
    this.pagedItems = this.reservations.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  setTransactionVar(fetched){
    this.setTransaction.emit(fetched);
    this.isFetched = true;
  }
}
