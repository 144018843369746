import { Component, Inject, OnInit } from '@angular/core';
import { CustomCode } from '../../shared/CustomCode';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { OktaAuthStateService,OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

import * as $ from 'jquery';
@Component({
  selector: 'app-headeragrigator',
  templateUrl: './headeragrigator.component.html',
  styleUrls: ['./headeragrigator.component.scss']
})
export class HeaderagrigatorComponent implements OnInit {
  icon_logo:string = 'assets/img/icon_logo.svg';
  loggedUserName = '';
  show = 0;
  constructor( private toasterObj: ToastrService,private routerObj: Router,
    public authStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth ) { }

  ngOnInit() {
    this.fetchUserStatusForTracking();
    let times_runhide_check = setInterval( ()=> {
      if(localStorage.getItem('userid'))
      {
        this.show = 1;
        this.loggedUserName = CustomCode.add3Dots(CustomCode.getloggedInUserDetails(), 22);
      }else {
        this.show = 0;
      }
    }, 100);

  }

  isActivetoinside(){
  }

  logoutUser(){
   
    this.fetchUserStatusForTracking();
    this.oktaAuth.signOut();

    localStorage.removeItem('userid');
    localStorage.setItem('resetForm', '1');
    this.toasterObj.success('You have successfully logged out.','Success');
    if(this.routerObj.url.indexOf('aggregator-monthly-booking') > -1 || this.routerObj.url.indexOf('aggregator-monthly-booking-confirmation') > -1){
      this.routerObj.navigate(['/']);
      window.location.reload();
    }
  }

  fetchUserStatusForTracking(){
    let getUserId = '0';
    let getUserEmail = '0';
    let user_login_type = '';
    let isLogin = false;
    if(localStorage.getItem('access_token')){
     isLogin = true;
       getUserId = localStorage.getItem('userid');
       getUserEmail = localStorage.getItem('email');
       user_login_type = localStorage.getItem('user_login_type');
    }
    const loginStatusTrack = {
      'event' : 'pageview',
      'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
      'authenticationMethod' : user_login_type,
      'userId' : getUserId,
      'pageType' : this.routerObj.url,
      'email': getUserEmail,
   };
  (<any>window).dataLayer.push(loginStatusTrack);
  
  }
}
