import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { CustomCode } from '../shared/CustomCode';
import { FormValidationErrors } from '../shared/formValidationErrors';
import * as $ from 'jquery';
import { UserserviceService } from '../services/userservice.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-createaccount',
  templateUrl: './createaccount.component.html',
  styleUrls: ['./createaccount.component.scss'],
  providers: [UserserviceService]
})
export class CreateaccountComponent implements OnInit {

  formErrorsCont = FormValidationErrors.formErrorsContainer;
  
  emailRegex = CustomCode.emailRegex;
  phoneMask: any = CustomCode.phoneMask;
  formData: any = {phone_type:'home'};
  checkusername = false;
  checkemail = false;
  private respdata;
  private parseData;
  infosmall = 'assets/img/info-small.svg';
  passwordErrMsg='Password is required.';
  usernameErrMsg:string;
  emailErrMsg: string;
  confirmPassMsg=false;
  constructor(private routeObj: Router, private userServiceObj: UserserviceService, private toasterObj: ToastrService) { 
   
  }
  @ViewChild('myDiv',{ static: true }) myDiv: ElementRef;
  ngOnInit() {
    setTimeout(()=>{ 
     
      $("input[name='email']").val('');
      $("input[name='password']").val('');
      $("#usename").val('');
      $("#usename").attr('placeholder','Please enter a username (must not contain email address)');
     // $("input[name='password']").attr('placeholder','Please enter the password');

    }, 500);
  }

  registerUser(formValue, formObj: NgForm) {
    const reg =/<(.|\n)*?>/g; 
    
   
    if (reg.test(formValue.username) == true) {
      this.toasterObj.error('HTML Tags are not allowed in username.', 'Sorry!');
      return false;
    }else if (reg.test(formValue.fname) == true) {
      this.toasterObj.error('HTML Tags are not allowed in First Name.', 'Sorry!');
      return false;
    }else if (reg.test(formValue.lname) == true) {
        this.toasterObj.error('HTML Tags are not allowed in Last Name.', 'Sorry!');
        return false;
    } else {
      if (reg.test(formValue.phone) == true) {
        this.toasterObj.error('HTML Tags are not allowed in Primary Phone Number.', 'Sorry!');
        return false;
      }else if (reg.test(formValue.zipcode) == true) {
        this.toasterObj.error('HTML Tags are not allowed in Zip Code.', 'Sorry!');
        return false;
      } else{
        if (reg.test(formValue.password) == true) {
          this.toasterObj.error('HTML Tags are not allowed in Password.', 'Sorry!');
          return false;
        } else {
          if (reg.test(formValue.cpassword) == true) {
            this.toasterObj.error('HTML Tags are not allowed in Confirm Password.', 'Sorry!');
            return false;
          }else{
            //return true;
          }
        }
      }
    }

    if(formValue.phone != undefined && formValue.phone != ''){
      let ph = formValue.phone.replace(/\D/g, '');
      const reg =CustomCode.phonePattern(ph);
      
      if(ph.length !==0 && ph.length < 10){
        formObj.form.controls['phone'].setErrors({'invalid': true});
        return false;
      }else if(reg == false){
        formObj.controls['phone'].setErrors({ 'invalid': true });
        return false;
      }
    }

    const reguser = formValue.username.match(/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/);
    const regemail = formValue.username.match(/^[_.A-Za-z0-9-]+@[a-zA-Z0-9-]+\.[a-z]{2,4}$/);
   // const reguser = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/;   
//console.log(reguser);
    if(formValue.username === undefined || formValue.username === ''){      
      this.usernameErrMsg = 'Username is required.';
      formObj.form.controls['username'].setErrors({'invalid': true});
      return false;
    }else if (reguser == null) {       
      this.usernameErrMsg = 'Please enter valid username.';
      formObj.form.controls['username'].setErrors({'invalid': true});
        return false;
    }else if (regemail!= null) {     // username should not be email   
      this.usernameErrMsg = 'Please enter valid username.';
      formObj.form.controls['username'].setErrors({'invalid': true});
        return false;
    }else if(formValue.username.length < 8){      
      this.usernameErrMsg = 'Username must be atleast 8 characters long.';
      formObj.form.controls['username'].setErrors({'invalid': true});
      return false;
    }

    const reemail= formValue.email.match(/^[_.A-Za-z0-9-]+@[a-zA-Z0-9-]+\.[a-z]{2,4}$/);

    if (reemail == null) {       
      this.emailErrMsg = 'Please enter valid email.';
      formObj.form.controls['email'].setErrors({'invalid': true});
        return false;
    }

  
   if (formValue.username.match(/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/) == null) {       
      this.usernameErrMsg = 'Please enter valid username.';
      //formObj.form.controls['username'].setErrors({'invalid': true});
        return false;
    }else if(formValue.username.length < 8){
      this.usernameErrMsg = 'Username must be atleast 8 characters long.';
     // formObj.form.controls['username'].setErrors({'invalid': true});
      return false;
    }
    const regpassword = formValue.password.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[`_\\-~#$^+=!*()@%&]).{8,20}$/);
   
    if (formValue.password === undefined || formValue.password === '') {
      this.passwordErrMsg = 'Password is required.';
      formObj.form.controls['password'].setErrors({'invalid': true});
      return false;
    }else if (regpassword == null) {      
      this.passwordErrMsg = 'Please enter valid password.';
      formObj.form.controls['password'].setErrors({'invalid': true});
        return false;
    }else if(formValue.password.length < 8){
      this.passwordErrMsg = 'Password must be atleast 8 characters long.';
      formObj.form.controls['password'].setErrors({'invalid': true});
      return false;
    }
    if(formValue.password !== formValue.cpassword){
      this.confirmPassMsg = true;
      formObj.form.controls['cpassword'].setErrors({'invalid': true});
      return false;
    }
    //return false;
    if(this.checkemail == true && this.checkusername == true){
    this.userServiceObj.registerUser(formValue)
    .subscribe(
    (data:any)=> {
      this.respdata = data;
      if (this.respdata.status === 201 ) {
        
            formObj.resetForm();
          //  this.toasterObj.success('Account created successfully. please go back and Login now.Thank you for signing up! Pls login to start your journey');
            this.routeObj.navigate(['/create-account/thanks']);
      }
    },
    error => {
      /* FormValidationErrors.displayServerErrors('signuperrors-container', error.errors); */
      const errorsList = error.error;
      if (errorsList.errors.message !== 'Validation Errors') {
        this.toasterObj.error(errorsList.errors.message, 'Sorry!', {enableHtml:true,  toastClass:'toast register-toaster'});
      } else if(errorsList.errors.message) {
        this.toasterObj.error(errorsList.errors.message, 'Sorry!', {enableHtml:true,  toastClass:'toast register-toaster'});
      }else{
        for (const key in errorsList.detail) {
          if (errorsList.detail.hasOwnProperty(key)) {
            if (typeof errorsList.detail === 'undefined') {
              this.toasterObj.error(errorsList.errors.message, 'Sorry!', {enableHtml:true,  toastClass:'toast register-toaster'});
            } else {
              this.toasterObj.error(errorsList.detail[key], 'Sorry!', {enableHtml:true,  toastClass:'toast register-toaster'});
            }
          }
        }
      }
    }
    
    );
  }
    // this.userServiceObj.registerUser(formValue)
    //   .subscribe(
    //   (data:any)=> {
    //     this.respdata = data;
    //     if (this.respdata.status === 201) {
    //       formObj.resetForm();
          
    //       if (localStorage.getItem('is_landing_page')) {
    //         let action_type = localStorage.getItem('action_type');
    //         let routeUrl = localStorage.getItem('routeUrl');
    //         let promocode = localStorage.getItem('promocode');
    //         let promotion_id = localStorage.getItem('promotion_id');
    //         let template_slug = localStorage.getItem('template_slug');
    //         let landing_page_url = "/"+routeUrl+"/" + template_slug + "/" + promotion_id + "/" + promocode + "/" + action_type;
    //         this.routeObj.navigate([landing_page_url]);
    //       } else {
    //         /* #start login user after registration */
    //         const userVal = {
    //           user:{
    //             name:this.respdata.data.user.name, 
    //             id:this.respdata.data.user.id,
    //             email:this.respdata.data.user.email,
    //             phone:this.respdata.data.user.phone,
    //             is_loyalty:this.respdata.data.user.is_loyalty,
    //             is_loyalty_active:this.respdata.data.user.is_loyalty_active
    //           },
    //           access_token:this.respdata.data.session.access_token,
    //           refresh_token:this.respdata.data.session.refresh_token,
    //           expires_in:this.respdata.data.session.expires_in,
    //           token_type:this.respdata.data.session.token_type,
    //         }

    //         CustomCode.markLoginUser(userVal);
    //         let isFirstRegister = this.respdata.data.is_new_user;

    //         if(localStorage.getItem('frombuymonthly'))
    //         {
    //           let routeNavigate = '/buy-monthly';
    //           let facId=localStorage.getItem('monthlyParkingIds')
    //           let fDate=localStorage.getItem('monthlyParkingDates')
    //           localStorage.removeItem('frombuymonthly')
    //           localStorage.removeItem('monthlyParkingDates')
    //           localStorage.removeItem('monthlyParkingIds');
    //           //console.log('1111111111111111111');
    //           this.routeObj.navigate([routeNavigate, facId, fDate]);
    //         }
    //         else if(sessionStorage.getItem('RedirectfromPayment') == '1'){
    //           let currentUrl = CustomCode.getCookie('currentUrl');
    //           let currentUrlObj = JSON.parse(currentUrl);
    //           CustomCode.deleteCookie('currentUrl');
    //           this.routeObj.navigate(['/payment', currentUrlObj.facId, currentUrlObj.facType, currentUrlObj.facPay]);
    //         }

    //         else if(isFirstRegister){
    //           this.routeObj.navigate(['/account-confirmation']);
    //           return false;
    //         }else{
    //           if(localStorage.getItem('is_loyalty_active')==="0"){
    //             this.routeObj.navigate(['/my-account/manage-icon-rewards-card']);
    //             return false;
    //           }
    //           this.routeObj.navigate(['/my-account/account']);
    //           return false;
    //         }
    //         /* #end login user after registration */
    //       }
    //     }
    //   },
    //   error => {
    //     /* FormValidationErrors.displayServerErrors('signuperrors-container', error.errors); */
    //     const errorsList = error.errors;
    //     if (errorsList.message !== 'Validation Errors') {
    //       this.toasterObj.error(errorsList.message, 'Sorry!', {enableHtml:true,  toastClass:'toast register-toaster'});
    //     } else {
    //       for (const key in errorsList.detail) {
    //         if (errorsList.detail.hasOwnProperty(key)) {
    //           if (typeof errorsList.detail === 'undefined') {
    //             this.toasterObj.error(errorsList.message, 'Sorry!', {enableHtml:true,  toastClass:'toast register-toaster'});
    //           } else {
    //             this.toasterObj.error(errorsList.detail[key], 'Sorry!', {enableHtml:true,  toastClass:'toast register-toaster'});
    //           }
    //         }
    //       }
    //     }
    //   }
    //   );
  }

  restChar(event: any){
    if(!CustomCode.restricSpecialChar(event)){
      event.preventDefault();
    }
  }

  onPaste(event: any){
    if(!CustomCode.restricPaste(event)){
      event.preventDefault();
    }
  }
  restSpace(event: any){
    
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }
  omitSpecialChar(event: any)
{   
   var k;  
   k = event.charCode;  
   return ((k > 64 && k < 91) || (k > 96 && k < 123) || (k > 127 && k < 254) || (k == 126) || k == 8 || k == 32 || (k >= 48 && k <= 57));
}
onFocusOutfname(event:any){
  
  let value = event.target.value;
  this.formData.fname = value.charAt(0).toUpperCase() + value.slice(1);
}
onFocusOutlname(event:any){
  
  let value = event.target.value;
  this.formData.lname = value.charAt(0).toUpperCase() + value.slice(1);
}

keyupphoneFormat(formObj: NgForm, event: any){
  let value = event.target.value;   
  if (value != undefined) {
    let ph = value.replace(/\D/g, '');
    const reg = CustomCode.phonePattern(ph);      
    if (ph.length > 5 && reg == false) { 
      formObj.controls['phone'].setErrors({ 'invalid': true });
     // return false;
    }
  }
  return true;
}
  onFocusOutEvent(formObj: NgForm,event: any) {
    
    let value = event.target.value;
    value = this.formData.username = value.charAt(0).toUpperCase() + value.slice(1);
    
    this.usernameErrMsg = '';
    const reguser = value.match(/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/);
    const regemail = value.match(/^[_.A-Za-z0-9-]+@[a-zA-Z0-9-]+\.[a-z]{2,4}$/);
   // const reguser = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/;   
//console.log(reguser);
    if(value === undefined || value === ''){      
      this.usernameErrMsg = 'Username is required.';
      formObj.form.controls['username'].setErrors({'invalid': true});
      return false;
    }else if (reguser == null) {       
      this.usernameErrMsg = 'Please enter valid username.';
      formObj.form.controls['username'].setErrors({'invalid': true});
        return false;
    }else if (regemail!= null) {     // username should not be email   
      this.usernameErrMsg = 'Please enter valid username.';
      formObj.form.controls['username'].setErrors({'invalid': true});
        return false;
    }else if(value.length < 8){      
      this.usernameErrMsg = 'Username must be atleast 8 characters long.';
      formObj.form.controls['username'].setErrors({'invalid': true});
      return false;
    }else{
      this.userServiceObj.oktaUsernameExist(value)
      .subscribe(
      (data:any)=> {
        if (data.status === 200) {
         formObj.form.controls['username'].setErrors({'invalid': true});
        this.usernameErrMsg = 'This username is already in use please use another option.';
        return false;
      }else{
        this.checkusername =true;
      }
      },
      error => {
        this.checkusername =true;

      });
    }
  }
  onFocusOutEventEmail(formObj: NgForm,event: any) {
    let value = event.target.value;
    this.emailErrMsg = '';
 //   const regemail= value.match(/^[a-zA-Z0-9.!$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-z]{2,4}$/);
    const regemail= value.match(/^[_.A-Za-z0-9-]+@[a-zA-Z0-9-]+\.[a-z]{2,4}$/);

    //const regemail = /^[^@]+@[^@]+\.[a-zA-Z]{2,6}/;
    if (value === undefined || value === '') {
      this.emailErrMsg = 'Email is required';   
      formObj.form.controls['email'].setErrors({'invalid': true});
  
      return false;
    }else if (regemail == null) {       
      this.emailErrMsg = 'Please enter valid email';
      formObj.form.controls['email'].setErrors({'invalid': true});
        return false;
    }else{ 
      
      this.userServiceObj.oktaEmailExist(value)
      .subscribe(
      (data:any)=> {
        if (data.status === 200) {
        this.emailErrMsg = 'This email is already in use please login or use another option.';
        formObj.form.controls['email'].setErrors({'invalid': true});
        return false;

        }else{
          this.checkemail =true;
        }
      },
      error => {
        this.checkemail =true;
      });
      
    } 

  }
  
  phoneFormat(event: any) {
    const pattern = /[0-9\)\-\(\.\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar) && event.keyCode != 9) {
      event.preventDefault();
    }
  }
  allowNumbersOnly(event){
    const charCode = (event.which) ? event.which : event.keyCode;
    if( charCode > 31 && (charCode < 48 || charCode > 57) ){
      return false;
    }
    return true;
  }
  onFocusOutPhone(formObj: NgForm,event: any) {
    let value = event.target.value;
   
    if (value != undefined) {
      let ph = value.replace(/\D/g, '');  
      const reg =CustomCode.phonePattern(ph);
      
      if (ph.length !== 0 && ph.length < 10) {        
        formObj.controls['phone'].setErrors({'invalid': true});
        return false;      
      }else if(reg == false){
        formObj.controls['phone'].setErrors({ 'invalid': true });
        return false;
      }
    }
    return true;     
  }
  
}
