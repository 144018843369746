import { Injectable } from '@angular/core';
//import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { FacebookService, InitParams, LoginResponse } from 'ngx-facebook';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/mergeMap';

import 'rxjs/add/observable/throw';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
export class FacebookAuthService {

    constructor(private http: HttpClient) { }

    private headers = new Headers({ 'Content-Type': 'application/json'});

    getUserFacebookProfileService(accessToken: string): Observable<any>{
        this.headers.set('Authorization', accessToken);
        //var fields = ['id', 'email', 'first_name', 'last_name'];
        // var fields = ['id', 'email', 'first_name', 'last_name', 'link', 'name','picture.type(small)'];
        var fields = ['id', 'email', 'first_name', 'last_name', 'name','picture.type(small)'];
        var graphApiUrl = 'https://graph.facebook.com/v2.5/me?fields=' + fields.join(',');
        return this.http.get(graphApiUrl+'&access_token='+accessToken+'')
                   .map(result => result)
                   .catch(this.handleErrors); 
    }

    private handleErrors(error: Response | any): Observable<any> {
     	return Observable.throw(error.json());
   }
    

}