import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distanceconversion'
})
export class DistanceconversionPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let milesrecord:any=(Math.round(value * 100) / 100).toFixed(2)
    if(milesrecord < '0.2')
    {
      return (Math.floor(milesrecord*5280)) + ' feet away';
    }else{
      return (Math.round(value * 100) / 100).toFixed(2) + ' miles away';
    }
    
  }

}
