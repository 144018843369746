import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-auto-pay',
  templateUrl: './auto-pay.component.html',
  styleUrls: ['./auto-pay.component.scss'],
  providers: [MyAccountMonthlyParkingService]
})
export class AutoPayComponent implements OnInit {

  @Input() currentActive: string;
  @Input() accountNumber: any;
  @Input() accountDetails: any;

  autopay: any;
  autopayMethods: any = {};

  paymentMethods: any = {};

  addCreditCard = false;
  addBankAccount = false;
  payCreditCard = false;
  payBankAccount = false;

  showAddCreditCardBtn = true;
  showAddBankAccountBtn = true;
  showPayCreditCardBtn = true;
  showPayBankAccountBtn = true;
  addingPaymentMethods = false;

  selectedPaymentProfile: any = null;
  secondSelectedPaymentProfile:any = null;
  selectedPaymentProfileObject:any = {};
  secondSelectedPaymentProfileObject:any = null;

  updateAutopayLabel = false;
  showPaymentMethods = true;
  autoPayPaymentProfiles:any = {};

  isLoaded = false;
  showSecondaryOptions:boolean = false;
  showUpdateOptions:boolean = false;
  paymentMethodOneLimit = 0.00;

  constructor(private toastr: ToastrService, private router: Router, private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService) { }

  ngOnInit() {
    this.fetchUserAutoPay();
  }

  receiveCancel($event) {
    this.addingPaymentMethods = $event;
    this.showAddBankAccountBtn = true;
    this.showAddCreditCardBtn = true;
  }

  afterAdd($event) {
    this.getpaymentMethods();
  }

  fetchUserAutoPay() {
    this.myAccountMonthlyParkingObj.autoPay(this.accountNumber)
    .then(
      data => {
        this.autopay = data['data'].autopay;
        this.autopayMethods = data['data'].autopay_methods;
        this.selectedPaymentProfile = null;
        this.getpaymentMethods();
      },
      error => {
        this.isLoaded = true;
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }

  getpaymentMethods() {
    this.myAccountMonthlyParkingObj.paymentMethods(this.accountNumber)
    .then(
      data => {
        this.isLoaded = true;
        this.paymentMethods = data['data'];
        if (this.paymentMethods.payments.length > 0) {
          if (this.autopay == 0) {
            this.selectedPaymentProfile = this.paymentMethods.payments[0].payment_profile_id;
            this.selectedPaymentProfileObject = this.paymentMethods.payments[0];
            setTimeout(() => {
              $('input#' + this.selectedPaymentProfile).prop('checked', true);
            });
          } else {
            this.selectedPaymentProfile = this.autopayMethods[0].payment_profile.payment_profile;
            this.paymentMethodOneLimit = this.autopayMethods[0].amount;
            setTimeout(() => {
              $('input#' + this.selectedPaymentProfile).prop('checked', true);
            }, 200);
            if (this.autopayMethods.length > 1) {
              this.secondSelectedPaymentProfile = this.autopayMethods[1].payment_profile.payment_profile;
              setTimeout(() => {
                $('input#second-' + this.secondSelectedPaymentProfile).prop('checked', true);
              }, 200);
            }

            for (var i = this.paymentMethods.payments.length - 1; i >= 0; i--) {
              if (this.selectedPaymentProfile == this.paymentMethods.payments[i].payment_profile_id) {
                this.selectedPaymentProfileObject = this.paymentMethods.payments[i];
              }
              if (this.secondSelectedPaymentProfile == this.paymentMethods.payments[i].payment_profile_id) {
                this.secondSelectedPaymentProfileObject = this.paymentMethods.payments[i];
              }
            }
          }
        }
      },
      error => {
        this.toastr.error(error.errors.message, 'Sorry!');
        this.isLoaded = true;
      }
    );
  }

  findMethodDetail(autopayMethod) {
    let profileId = autopayMethod.payment_profile.payment_profile;
    if (this.paymentMethods.payments) {
      for (var i = this.paymentMethods.payments.length - 1; i >= 0; i--) {
        if (this.paymentMethods.payments[i].payment_profile_id == profileId) {
          if (this.paymentMethods.payments[i].card != null) {
            return "Card ending in " + 
              this.paymentMethods.payments[i].card.card_number.split('XXXX')[1] + 
              " for " + 
              this.paymentMethods.payments[i].address.first_name + 
              " " +
              this.paymentMethods.payments[i].address.last_name + 
              ", Expires " +
              ('0' + this.paymentMethods.payments[i].expiration_month).slice(-2) +
              "/" +
              this.paymentMethods.payments[i].expiration_year; 
          } else {
             return "Bank ending in " + 
                this.paymentMethods.payments[i].bank.bank_account.split('XXXX')[1] + 
                ", " + 
                this.paymentMethods.payments[i].bank.bank_name; 
          }
        }
      }
      return ' ';
    }
  }

  findHowMuchPay(autopayMethod) {
      if (autopayMethod.amount == 0) {
        if (this.autopayMethods.length > 1) {
          if (autopayMethod.pay_remainder) {
            return "Pays remainder";
          } else {
            return "Pays total";
          }
        } else {
          return "Pays total";
        }
      } else {
        return "Pays up to $" + autopayMethod.amount;
      }
  }

  showAddCreditCard() {
    this.addingPaymentMethods = true;
    this.addBankAccount = false;
    this.showAddBankAccountBtn = true;
    this.showAddCreditCardBtn = false;
    this.addCreditCard = true;
  }

  showAddBankAccount() {
    this.addingPaymentMethods = true;
    this.addCreditCard = false;
    this.addBankAccount = true;
    this.showAddCreditCardBtn = true;
    this.showAddBankAccountBtn = false;
  }

  activateAutopay() {
    if (this.selectedPaymentProfile != null) {
      let data = {};
      if (this.secondSelectedPaymentProfile != null) {
        data = {
            'autopay': 1,
            'payments': [
              {
                'payment_profile_id': this.selectedPaymentProfile,
                'amount': this.paymentMethodOneLimit,
                'pay_remainder': true
              },
              {
                'payment_profile_id': this.secondSelectedPaymentProfile,
                'amount': 0,
                'pay_remainder': true
              }
            ]
        };
      } else {
        data = {
          'autopay': 1,
          'payments': [
            {
              'payment_profile_id': this.selectedPaymentProfile,
              'amount': 0,
              'pay_remainder': true
            }
          ]
        };
      }
      this.updateAutopay(data);
    } else {
      this.toastr.error('Please Select Payment Method First', 'Sorry!');
    }
  }

  deactivateAutopay() {
    const data = { 'autopay' : 0 };
    this.updateAutopay(data);
  }

  updateAutopay(options) {
    this.isLoaded = false;
    this.myAccountMonthlyParkingObj.setAutoPay(options, this.accountNumber)
    .then(
      data => {
        this.paymentMethods = data['data'];
        this.showUpdateOptions = false;
        this.showSecondaryOptions = false;
        if (options.autopay == 0) {
          this.secondSelectedPaymentProfile = null;
          this.secondSelectedPaymentProfileObject = null;
          this.paymentMethodOneLimit = 0.00;
        }
        this.fetchUserAutoPay();
      },
      error => {
        this.toastr.error(error.errors.message, 'Sorry!');
        this.isLoaded = true;
      }
    );
  }

  updateAutoPayPrefs() {
    this.showUpdateOptions = true;
    setTimeout(() => {
      $('input#' + this.selectedPaymentProfile).prop('checked', true);
      if (this.autopayMethods.length > 1) {
        this.showSecondaryOptions = true;
        $('input#addDualPaymentMethod').prop('checked', true);
        setTimeout(() => {
          $('input#second-' + this.secondSelectedPaymentProfile).prop('checked', true);
        }, 200);
      }
    }, 200);
  }

  updateAutoPayNow() {
    if (this.selectedPaymentProfile != null) {
      this.autopayMethods[0].amount = this.paymentMethodOneLimit;
      this.autopayMethods[0].payment_profile_id = this.selectedPaymentProfile;
      let data = {};
      if (this.secondSelectedPaymentProfile != null) {
          data = {
            'autopay': 1,
            'payments': [
              {
                'payment_profile_id': this.selectedPaymentProfile,
                'amount': this.paymentMethodOneLimit,
                'pay_remainder': true
              },
              {
                'payment_profile_id': this.secondSelectedPaymentProfile,
                'amount': 0,
                'pay_remainder': true
              }
            ]
          };
       } else {
        data = {
          'autopay': 1,
          'payments': [
            {
              'payment_profile_id': this.selectedPaymentProfile,
              'amount': 0,
              'pay_remainder': true
            }
          ]
        };
      }
      this.updateAutopay(data);
    } else {
      this.toastr.error('Please Select Payment Method First', 'Sorry!');
    }
  }

  makeOneTimePayment() {
    this.router.navigate(['/my-account/monthly-parking/account/' + this.accountNumber + '/make-payment']);
  }

  secondaryPayment() {
    setTimeout(() => {
      let option = $('input#addDualPaymentMethod').prop('checked');
      if (option) {
        this.showSecondaryOptions = true;
        for (var i = this.paymentMethods.payments.length - 1; i >= 0; i--) {
          if (this.paymentMethods.payments[i].payment_profile_id != this.selectedPaymentProfile) {
            this.secondSelectedPaymentProfile = this.paymentMethods.payments[i].payment_profile_id;
            this.secondSelectedPaymentProfileObject = this.paymentMethods.payments[i];
            setTimeout(() => {
              $('input#second-' + this.secondSelectedPaymentProfile).prop('checked', true);
            }, 300);
          }
        }
      } else {
        this.showSecondaryOptions = false;
        this.secondSelectedPaymentProfile = null;
        this.paymentMethodOneLimit = 0.00;
      }
    }, 200);
  }

  updatePaymentMethod(paymentProfile) {
     this.selectedPaymentProfile = paymentProfile;
     for (var i = this.paymentMethods.payments.length - 1; i >= 0; i--) {
       if (this.paymentMethods.payments[i].payment_profile_id == paymentProfile) {
         this.selectedPaymentProfileObject = this.paymentMethods.payments[i];
       }
     }
  }

  updatePaymentMethodSecondary(paymentProfile) {
     this.secondSelectedPaymentProfile = paymentProfile;
     for (var i = this.paymentMethods.payments.length - 1; i >= 0; i--) {
       if (this.paymentMethods.payments[i].payment_profile_id == paymentProfile) {
         this.secondSelectedPaymentProfileObject = this.paymentMethods.payments[i];
       }
     }
  }

  setPaymentOneLimit(evt) {
    let element = 'input#paymentOneLimit';
    let charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && !(charCode == 46 || charCode == 8))
      return false;
    else {
      let len = $(element).val().length;
      let index = $(element).val().indexOf('.');
      if (index > 0 && charCode == 46) {
        return false;
      }
      if (index > 0) {
        let CharAfterdot = (len + 1) - index;
        if (CharAfterdot > 3) {
          return false;
        }
      }

    }
    setTimeout( () => {
        this.paymentMethodOneLimit = parseFloat($(element).val().toString());
    }, 200);
    return true;
  }
}
