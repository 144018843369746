import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-monthly',
  templateUrl: './monthly.component.html'
})
export class MonthlyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  addMenuactive()
  {
    sessionStorage.setItem('menuActive','2')
  }
}
