import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
// import { WebGarageDetailsComponent } from '../web-garage-details/web-garage-details.component';

declare var google: any;

import * as $ from 'jquery';

import { CustomCode } from '../../../shared/CustomCode';
import { SearchPageScript } from '../../../shared/searchPageScript';


@Component({
  selector: 'app-web-garage-details',
  templateUrl: './web-garage-details.component.html',
  styleUrls: ['./web-garage-details.component.scss']
})
export class WebGarageDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  	window.scrollTo(0,0);
            $("body").animate({ scrollTop: 0 }, 500);

  }

  loadGarageDetails() {
  
  	// if ($(window).width() < 768) {
	  //   $('.contentBar').css({
	  //     'bottom': '0',
	  //     'left': '0%',
	  //     'max-height': '45%'
	  //   });
	  // } else {
	  //   $('.contentBar').css({ 'left': '0%' });
	  //   $('body').addClass('noscroll');
	  //   $('#parkDetail').removeClass('hidden');
	  //   $('#parkList').addClass('hidden');
	  // }
  }

}
