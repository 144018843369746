
import * as $ from 'jquery';

import { CustomCode } from './CustomCode';

export class SearchPageScript {

  public static dateObj = new Date();

  /**
   * Mark click label active, open/hide related forms to daily/monthly facility
   */
  public static markLabelActive(newUserVal) {
      let removeFromLabelRef = CustomCode.monthlyConstant;
      let activeForm = removeFromLabelRef;
      let deactiveForm = CustomCode.defaultFacilityType;
      if (newUserVal === CustomCode.monthlyConstant) {
        removeFromLabelRef = CustomCode.defaultFacilityType;
        activeForm = CustomCode.defaultFacilityType;
        deactiveForm = CustomCode.monthlyConstant;
      }
      $('#' + newUserVal).addClass('active');
      $('#' + removeFromLabelRef).removeClass('active');

      $('#' + activeForm + 'Filter').addClass('hidden');
      $('#' + deactiveForm + 'Filter').removeClass('hidden');
  }

  /**
   * Manage header position while scrolling window
   */
  public static manageHeaderPosition() {
    const header = $('.navbar-custom');
    $(window).scroll(function () {
      const scroll = $(window).scrollTop();
      if (scroll >= 200) {
        header.addClass('whitePaneSearch');
      }
    });
  }

  /**
   * Return today default date for daily and monthly facility input box in YYYY-mm-dd format
   */
  public static defaultDateForDailyMonthlyFacility() {
    let dateNew = new Date().toLocaleString('en-US', { hour12:false } ).split(" ");
    let mdy = dateNew[0];
    let mdy2 = mdy.split('/');
    let month = mdy2[0];
    let day = mdy2[1];
    let year = mdy2[2];
    let yearFix = year.split(',');
    let finalDate = month + '/' + day + '/' + yearFix[0];
    return finalDate;
  }

  public static nextDayDate(newDate) {
    let dateNew = newDate.toLocaleString('en-US', { hour12:false } ).split(" ");
    let mdy = dateNew[0];
    let mdy2 = mdy.split('/');
    let month = mdy2[0];
    let day = mdy2[1];
    let year = mdy2[2];
    let yearFix = year.split(',');
    let finalDate = month + '/' + day + '/' + yearFix[0];
    // return new Date(new Date().setDate(new Date().getDate() + 1));
    return finalDate;
  }

  public static returnDailyLengthOfStay() {
    const startDate = $('#startdate').val().toString().split('-').join('/');
    const startTime = $('#starttime').val();
    const endDate = $('#enddate').val().toString().split('-').join('/');
    const endTime = $('#endtime').val();
    const startDateTime = startDate + ' ' + startTime;
    const endDateTime = endDate + ' ' + endTime;
    let diffTime = 0;
    if (startDate && startTime && endDate && endTime) {
      diffTime = SearchPageScript.getTimeDifference(startDateTime, endDateTime);
    }
    return diffTime;
  }

  public static getLengthOfStay(startDate, startTime, endDate, endTime) {
    const startDateTime = startDate + ' ' + startTime;
    const endDateTime = endDate + ' ' + endTime;
    let diffTime = 0;
    if (startDate && startTime && endDate && endTime) {
      diffTime = SearchPageScript.getTimeDifference(startDateTime, endDateTime);
    }
   // alert(diffTime)
    return diffTime;
  }

  public static getTimeDifference(startDate, endDate) {
    let timeStart: any = {};
    let timeEnd: any = {};
    timeStart = new Date(startDate);
    timeEnd = new Date(endDate);
    const diff:number = (timeEnd - timeStart) / 60000;
    return diff / 60;
  }

  /**
   * Close Garage sidebar
   */
  public static closeSideBar() {
    // $('.floatClose').click(function () {
    //   $('aside.contentBar').animate({ 'left': '-450px' }, 400);
    //   $('body').removeClass('noscroll');
    // });
  }

  public static openTimePicker() {
    // $('.filterBtn').on('click.bs.dropdown', function(e){
    //   const $a  = $(e.target), is_a = $a.is('.is_a');
    //   if ($a.hasClass('dropdown-toggle')) {
    //         $('ul.dropdown-menu', this).toggle(!is_a);
    //     $a.toggleClass('is_a', !is_a);
    //   }
    // }).on('click', function(){
    //   $('ul.dropdown-menu', this).hide();
    //   $('.is_a', this).removeClass('is_a');
    // });
    $('body').on("click", ".dropdown-menu", function (e) {
    $(this).parent().is(".open") && e.stopPropagation();
});
  }

  public static getCurrentMonth(dateObj) {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const now = new Date(dateObj);
    const month =  months[now.getMonth()];
    const todayName = now.toLocaleDateString('en', { weekday: 'long' });
    const currentDate = now.getDate();
    return month + ', ' + todayName + ' ' + currentDate;
  }


}

