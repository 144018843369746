
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { Crypto } from '../../classes/crypto';

@Component({
  selector: 'app-add-credit-card',
  templateUrl: './add-credit-card.component.html',
  styleUrls: ['./add-credit-card.component.scss'],
  providers: [MyAccountMonthlyParkingService, Crypto]
})
export class AddCreditCardComponent implements OnInit {

  @Input() currentActive: string;
  @Input() accountNumber: any;

  dateMask: any = [/[0-9]/, /[0-9]/, '/' , /[0-9]/, /[0-9]/];
  cardMask: any = [
          /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
          /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
          /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
          /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
          ];

  formData: any = {};

  infoIcon = 'assets/img/info.svg';

  cancel = true;

  @Output() cancelEvent = new EventEmitter<boolean>();
  @Output() responseEvent = new EventEmitter<boolean>();

  constructor(
    private crypto: Crypto,
    private toastr: ToastrService,
    private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService
    ) { }

  ngOnInit() {
  }

  hitCancel() {
    this.cancel = false;
    this.cancelEvent.emit(this.cancel);
  }

  responseReturn() {
    this.responseEvent.emit();
  }

  submitAddCreditCard(formData, form: NgForm) {
    formData.card_number = formData.card_number.replace(/\D/g, '');
    formData.expiration_date = formData.expiration_date.replace(/\D/g, '');
    console.log(formData, 'credit card data')
    const nonce = this.crypto.encrypt(formData);
    const data = {
      'payment_type' : 'credit_card',
      'nonce' : nonce
    };
    this.myAccountMonthlyParkingObj.addCreditCard(data, this.accountNumber)
    .then(
      data => {
        this.toastr.success('Payment Method Added Successfully', 'Great!');
        this.responseReturn();
        this.hitCancel();
      },
      error => {
        let errorMessage = error.errors.message;
        if (error.errors.message === 'Validation Errors') {
          errorMessage = '';
          for (const key in error.errors.detail) {
              errorMessage += error.errors.detail[key];
          }
        }
        this.toastr.error(errorMessage, 'Sorry!');
      }
    );
  }

  restrictNumeric(event) {
    let input;
    if (event.metaKey || event.ctrlKey) {
      return true;
    }
    if (event.which === 32) {
     return false;
    }
    if (event.which === 0) {
     return true;
    }
    if (event.which < 33) {
      return true;
    }
    input = String.fromCharCode(event.which);
    return !!/[\d\s]/.test(input);
  }

}
