import { Component, OnInit, Input} from '@angular/core';

import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';

@Component({
  selector: 'app-current-balance',
  templateUrl: './current-balance.component.html',
  styleUrls: ['./current-balance.component.scss'],
  providers: [MyAccountMonthlyParkingService]
})
export class CurrentBalanceComponent implements OnInit {

    @Input() currentActive: string;
    @Input() accountDetails: any;

    constructor(private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService) { }

    ngOnInit() {
        this.justConsoleSomething();
    }

    justConsoleSomething() {
       
    }

}
