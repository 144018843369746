import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// declare var ga: Function;

@Component({
  selector: 'app-gameday',
  templateUrl: './gameday.component.html',
  styleUrls: ['./gameday.component.scss']
})
export class GamedayComponent implements OnInit {

	@Input() promoCode: string;

  @Output() saveEvent = new EventEmitter<boolean>();
  
  gameday_parking_desktop = '../assets/img/campaign/gameday/gameday-parking-desktop.jpg';
  gameday_parking_mobile = '../assets/img/campaign/gameday/gameday-parking-mobile.jpg'

  	constructor() { }

  	ngOnInit() {
  	}

  	savePromocode(location) {
     // ga('send', 'event', { eventCategory: 'HOCKEY10', eventAction: 'click'});
      (<any>window).dataLayer.push(
         {
            'promoCode': 'HOCKEY10',
            'event': 'Promocode Save',
         });
		  this.saveEvent.emit(location);
	}

}
