import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { CustomCode } from '../shared/CustomCode';
import { LoyalityBadgeComponent } from '../pages/loyality-badge/loyality-badge.component'

  @Component({
    selector: 'app-my-account',
    templateUrl: './my-account.component.html'
  })
export class MyAccountComponent implements OnInit {
  @ViewChild(LoyalityBadgeComponent,{static:true}) child:LoyalityBadgeComponent;
  // Input Parameters for monthly parking user account number and module
  menuUser = [
    new CustomCode ('My Account', 'account'),
    // new CustomCode ('Icon Rewards', 'icon-rewards'),
    new CustomCode ('My Monthly Parking', 'monthly-parking'),
    //new CustomCode ('My Offers', 'coupons'),
    new CustomCode ('My Reservations', 'reservations'),
    new CustomCode ('Vehicle Request', 'vehicle-request'),
    new CustomCode ('Brand Partnerships', 'brand-partnership'),
    new CustomCode ('My Promo Codes', 'promo-codes'),
  ];

  accountNumber: any;
  pageSection: string;
  showMonthlyParking: any;
  showInnerPages: any;
  loggedUser:any;
  isLoyaltyMember:boolean=false;
  formData: any = {};
  isTierLoaded:boolean=false;
  menName='My Account'
  constructor(
    private router: Router
  ) {

  }
  
  ngOnInit() {
    $(".catValue li a").click(function(){
      $(".linkLabel div").html($(this).html());
      $(this).parent().addClass('active').siblings().removeClass('active');
      $(".catValue").parent('.selectBox').removeClass('open');
    });

    if(localStorage.getItem('is_loyalty')==="1"){ this.isLoyaltyMember = true; }
    this.loggedUser = CustomCode.getloggedInUserDetails();
  }

  logOut() {
    CustomCode.userLogout();
    this.router.navigate(['/']);
  }

  updateTier(tier){
    this.isTierLoaded = tier;
  }

  showBadgeHome(event){
    this.child.showBadge();
  }
  removeHeader()
  {
    console.log("working");
    sessionStorage.removeItem('menuActive')
  }
  addMenuactive()
  {
    sessionStorage.setItem('menuActive','2')
  }
  changeMenu(text)
  {
    this.menName=text
  }
  ngAfterViewInit() {
    if(this.router.url.indexOf('account/brand-partnership') > -1){
      this.changeMenu('Brand Partnerships')
    }else if(this.router.url.indexOf('account/vehicle-request') > -1){
      this.changeMenu('Vehicle Request')
    }else if(this.router.url.indexOf('account/reservations') > -1){
      this.changeMenu('My Reservations')
    }else if(this.router.url.indexOf('account/coupons') > -1){
      this.changeMenu('My Offers')
    }else if(this.router.url.indexOf('account/monthly-parking') > -1){
      this.changeMenu('My Monthly Parking')
    }
    // else if(this.router.url.indexOf('account/icon-rewards') > -1){
    //   this.changeMenu('Icon Rewards')
    // }
    else if(this.router.url.indexOf('account/account') > -1){
      this.changeMenu('My Account')
    }else{
      this.changeMenu('My Promo Codes')
    }
	}
}