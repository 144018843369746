import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { SearchpageserviceService } from '../../../services/searchpageservice.service';
import {GoogleAnalyticsEventsService} from "../../../shared/google-analytics-events.service";
import { DomSanitizer } from '@angular/platform-browser';
// declare var ga: Function;
import * as $ from 'jquery';
//declare var kochava:any;

@Component({
  selector: 'app-icon-coupon-holiday',
  templateUrl: './icon-coupon-holiday.component.html',
  styleUrls: ['./icon-coupon-holiday.component.scss'],
  providers:[SearchpageserviceService]
})
export class IconCouponHolidayComponent implements OnInit {

  respdata:any;
  printData:any;
  isLoaded;
  mailFormData: any = {};
  

  constructor(
  	private searchPageObj: SearchpageserviceService,
  	private router: Router,
  	private toasterObj: ToastrService,
  	public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
  	public sanitizer: DomSanitizer
  	) { }

  ngOnInit() {
  	
  }

  sendCoupon(formData, formObj: NgForm) {
  	   // ga('send', 'event', { eventCategory: 'icon coupon holiday email', eventAction: 'click'});

		const cId = 3969;
		//this.getVisitorCouponTracking({"couponId":cId,"type":this.EMAIL_COUPON,"to":formData.ucmail});
		this.searchPageObj.mailLandingCoupon(formData, cId)
			.subscribe(
			data => {
				this.respdata = data;
				this.searchPageObj.getMobileCode().subscribe((data)=>{
					(<any>window).dataLayer.push(
						{
						'promoCode': 'SUC931',
						'couponCode':data.data.code,
						'event': 'Email Coupon',
						'location': data.data.facility.short_name,
						'landing page name':'icon coupon holiday email'
						});
 				});
				if (this.respdata.status === 201) {

					// kochava.activity('email_coupon', {
					// 	coupon_id : cId
					// });	

					this.router.navigate(['/thanks-email']);
				}
			},
			error => {
				const errorsList = error.errors;
				if (errorsList.message !== 'Validation Errors') {
					this.toasterObj.error(errorsList.message, 'Sorry!');
				} else {
					for (const key in errorsList.detail) {
						if (errorsList.detail.hasOwnProperty(key)) {
							if (typeof errorsList.detail === 'undefined') {
								this.toasterObj.error(errorsList.message, 'Sorry!');
							} else {
								this.toasterObj.error(errorsList.detail[key], 'Sorry!');
							}
						}
					}
				}
			}
			);

	}

	printCoupon() {
		//ga('send', 'event', { eventCategory: 'icon coupon holiday print', eventAction: 'click'});
		this.isLoaded = false;
		$('#invoicePrint').css('display', 'none');
		const cId = 3969;
		this.searchPageObj.printCoupon(cId).then(
			(couponPrint) => {
				const urlCreator = window.URL;
				const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(couponPrint['_body']));
				this.printData = blobData['changingThisBreaksApplicationSecurity'];
				this.isLoaded = true;
				this.searchPageObj.getMobileCode().subscribe((data)=>{
					(<any>window).dataLayer.push(
						{
						'promoCode': 'SUC931',
						'couponCode':data.data.code,
						'event': 'Print Coupon',
						'location': data.data.facility.short_name,
						'landing page name':'icon coupon holiday email'
						});
 				});
				$('#invoicePrint').css('display', 'block');
			},
			(error) => {
				
				this.isLoaded = true;
			}
		);
		//this.getVisitorCouponTracking({"couponId":facId,"type":this.PRINT_COUPON});

	}

	printMedia() {
		$('#coupon-modal .modal-dialog').removeClass('modal-sm');
		$('div#printable').html($('div#printableContent').html()).show();
		$('div#content-Wrapper').hide();
		window.print();


		$('div#printable').html('').hide();
		$('div#content-Wrapper').show();
		this.router.navigate(['/thanks-print']);
	
	}

	resetForm(formObj: NgForm) {
    formObj.resetForm();
  }



}
