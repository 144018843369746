import { Component, OnInit, NgZone, Input, EventEmitter,ElementRef, Output, ChangeDetectorRef, TemplateRef, ViewChild, AfterViewInit,Pipe,PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
declare var google: any;
declare var MarkerWithLabel:any
import * as $ from 'jquery';
import { Ng2DeviceService } from 'ng2-device-detector';
import { CustomCode } from '../../../shared/CustomCode';
import { SearchPageScript } from '../../../shared/searchPageScript';
import { FormValidationErrors } from '../../../shared/formValidationErrors';
import { FilterHelper } from '../../../classes/filter-helper';

import { NgForm } from '@angular/forms';
//import { NgProgress } from 'ngx-progressbar';
import { Router, ActivatedRoute, Params, NavigationStart } from '@angular/router';
import { AgmCoreModule, AgmMap, AgmMarker } from '@agm/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { SearchpageserviceService } from '../../../services/searchpageservice.service';
import { UserserviceService } from '../../../services/userservice.service';
import { SearchFacilityComponent } from '../../search/search-facility/search-facility.component';
import { MyAccountMonthlyParkingService } from '../../../services/my-account-monthly-parking.service';
import { HomepageserviceService } from '../../../services/homepageservice.service';
import { LogsService  } from '../../../services/logs.service';

import { MobileSearchFilterComponent } from '../../Global/mobile-search-filter/mobile-search-filter.component';
import { MobileSearchFilterNavComponent } from '../../Global/mobile-search-filter-nav/mobile-search-filter-nav.component';
import {GoogleAnalyticsEventsService} from "../../../shared/google-analytics-events.service";
import { StaticpagesService } from '../../../services/staticpages.service';
// declare var ga: Function;
// declare var gtag: Function;
// declare var gtag_report_conversion : Function;

@Pipe({
                name: "sorting"
  })
  export class SearchPageSortPipe  implements PipeTransform {
                transform(array: any,field:string): any[] {

                                if(field=='Distance')
                                {
                                                field='distance'
                                }
                                if(field=='Price')
                                {
                                                field='price'
                                }
                                if(field=='Distancemonthly')
                                {
                                                field='distancemonthly'
                                }
                                if(field=='Distancedaily')
                                {
                                                field='distancedaily'
                                }
                                if(field=='Dailymonthly')
                                {
                                                field='dailymonthly'
                                }
                                
                  if (!Array.isArray(array)) {
                                return;
                  }
                if(field=='price')
                {
                                array.sort((a: any, b: any) => {

                                                if(b.price=='N/A')
                                                {
                                                                b.price=2500000;
                                                }
                                                if(a.price=='N/A')
                                                {
                                                                a.price=2500001;
                                                }
                
                                                if (a.price < b.price) {
                                                  return -1;
                                                } else if (a.price > b.price) {
                                                  return 1;
                                                } else {
                                                  return 0;
                                                } 
                                  });
                }
                else if(field=='pricemonthly')
                {
                  array.sort((a: any, b: any) => {

                                if(b.monthly_rate_mains==0)
                                {
                                                b.monthly_rate_mains=2500000;
                                }
                                if(a.monthly_rate_mains==0)
                                {
                                                a.monthly_rate_mains=2500001;
                                }

                                if (a.monthly_rate_mains < b.monthly_rate_mains) {
                                  return -1;
                                } else if (a.monthly_rate_mains > b.monthly_rate_mains) {
                                  return 1;
                                } else {
                                  return 0;
                                } 
                  });
                }else if(field=='distance'){
                  array.sort((a: any, b: any) => {
                                if (a.distance < b.distance) {
                                  return -1;
                                } else if (a.distance > b.distance) {
                                  return 1;
                                } else {
                                  return 0;
                                } 
                  });
                }else if(field=='daily')
                {
                                array.sort((a: any, b: any) => {
                                                                
                                                                if (parseFloat(a.inventory.showpriceSort) < parseFloat(b.inventory.showpriceSort)) {
                                                                                return -1;
                                                                } else if (parseFloat(a.inventory.showpriceSort) > parseFloat(b.inventory.showpriceSort)) {
                                                                                return 1;
                                                                } else {
                                                                                return 0;
                                                                }
    });
                }else if(field=='dailymonthly')
                {
                                array.sort((a: any, b: any) => {
                                                                
                                                                if (parseFloat(a.inventory.showpriceSort) < parseFloat(b.inventory.showpriceSort)) {
                                                                                return -1;
                                                                } else if (parseFloat(a.inventory.showpriceSort) > parseFloat(b.inventory.showpriceSort)) {
                                                                                return 1;
                                                                } else {
                                                                                return 0;
                                                                }
    });
                }else if(field=='distancedaily'){
                                array.sort((a: any, b: any) => {
                                                if (a.facility.distance < b.facility.distance) {
                                                                return -1;
                                                } else if (a.facility.distance > b.facility.distance) {
                                                                return 1;
                                                } else {
                                                                return 0;
                                                }
                });
                }
                
                else if(field=='distancemonthly'){
                                array.sort((a: any, b: any) => {
                                                if (a.distance < b.distance) {
                                                                return -1;
                                                } else if (a.distance > b.distance) {
                                                                return 1;
                                                } else {
                                                                return 0;
                                                }
                });
                }
                else{
                                array.sort((a: any, b: any) => {
                                                
                                                if(b.monthly_rate_mains==0)
                                {
                                                b.monthly_rate_mains=2500000;
                                }
                                if(a.monthly_rate_mains==0)
                                {
                                                a.monthly_rate_mains=2500001;
                                }
                                                                if (a.monthly_rate_mains < b.monthly_rate_mains) {
                                                                                return -1;
                                                                } else if (a.monthly_rate_mains > b.monthly_rate_mains) {
                                                                                return 1;
                                                                } else {
                                                                                return 0;
                                                                } 
    });
                }
                  
                  return array;
                }
  }


@Pipe({
  name: "sort"
})

export class ArraySortPipe  implements PipeTransform {
  transform(array: any,field:string): any[] {
    if (!Array.isArray(array)) {
      return;
                                }
                
                if(field=='daily')
                {
                                array.sort((a: any, b: any) => {
                                                                
                                                                if (parseFloat(a.inventory.showpriceSort) < parseFloat(b.inventory.showpriceSort)) {
                                                                                return -1;
                                                                } else if (parseFloat(a.inventory.showpriceSort) > parseFloat(b.inventory.showpriceSort)) {
                                                                                return 1;
                                                                } else {
                                                                                return 0;
                                                                }
    });
                }else if(field=='distancedaily'){
                                array.sort((a: any, b: any) => {
                                                if (a.facility.distance < b.facility.distance) {
                                                                return -1;
                                                } else if (a.facility.distance > b.facility.distance) {
                                                                return 1;
                                                } else {
                                                                return 0;
                                                }
                });
                }else{
                                array.sort((a: any, b: any) => {
                                                                if (a.monthly_rate < b.monthly_rate) {
                                                                                return -1;
                                                                } else if (a.monthly_rate > b.monthly_rate) {
                                                                                return 1;
                                                                } else {
                                                                                return 0;
                                                                } 
    });
                }
    
    return array;
  }
}

@Pipe({ name: 'safeHtmlNeighborhood'})

export class safeHtmlNeighborhood implements PipeTransform  {
  constructor(public sanitizer: DomSanitizer) {}

  transform(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}


@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
  providers: [MyAccountMonthlyParkingService, HomepageserviceService, LogsService,StaticpagesService]
})
export class GoogleMapComponent implements AfterViewInit {

                


    gtmUrl:SafeResourceUrl = window.location.pathname;
                @Output() updateFacilityFilters = new EventEmitter<boolean>();
                @Output() updateNeighborhoodFilters = new EventEmitter<boolean>();
                @Output() updateFilters = new EventEmitter<boolean>();
                @Output() updateFiltersDragMap = new EventEmitter<boolean>();
                @ViewChild(MobileSearchFilterComponent,{static:true}) mobileSearchFilter;
                @ViewChild(MobileSearchFilterNavComponent,{static:true}) mobileSearchFilterNav;
                @ViewChild('myDiv',{static:true}) myDiv: ElementRef;
                @ViewChild('bookerrormsgpopupclose',{static:true}) bookerrormsgpopupclose: ElementRef;
                @ViewChild('distancesort',{static:true}) distancesort: ElementRef;
                @ViewChild('workingHours',{static:true}) ScrollContainer: ElementRef;
                fieldName='distance'
                isFrame = 0;
                loginLink = '#login';
                mobileD = 0;
                desktopD=0;
                isAndroid = 0;
                isIphone = 0; 
                yscroll :any;
                sideBarValue = 500;
                mobileFilterNavDisplay:boolean = true;
                phoneMask: any = CustomCode.phoneMask;
                EMAIL_COUPON:string = 'email';
                PRINT_COUPON:string = 'print';
                VIEW_COUPON:string = 'view';
                isPartners:boolean = false;
                afterSort=1;
                mainShow=1;
                mainShow2=0;
                facilitylistprice:any=[];
                distancesorting=1;
                distancelatestsort=0;
                pricesorting=0;
                FulldayWrking:any=0;
                headerBarHeight = $('.navbar-custom').outerHeight();
                searchFilterHeight = $('.searchFilter').outerHeight();
                windowHight = $(window).height();
                calcHightMobile:any;
                calcHight:any;
                startDuration:any;
                endDuration:any;
                facilityDataSort:any='';
                monthlyDate:any;
                userData = {
                                first_name: '',
                                last_name: '',
                                email: '',
                                phone: '',
                                password: '',
                                cpassword: '',
                                enrolledIn: false,
                                promocode:''
                  }
                dStartTime;
                dEndTime;
                baserate:string = '';
                scrollTop:any;
                sideBarValueplus:any;
                showCampaignDeals:boolean = false
                monthlypark: boolean =false
    motorcycle: boolean =false
    oversized: boolean =false
    evcharging: boolean =false
    tesla: boolean =false
    outdoor: boolean =false
    indoor: boolean =false
    twentyhour:boolean=false
                bookingRate:any;
                earlyBirdSpecialCategory:any = 1;
                searchResult: any = {};
                EarlyBrid:any;
                earlybirIcon:any;
                firstLoad = false;
                centerMarker:any ;
                isDraggable = false;
                markerLoaded = false;
                imgSrcLocation:string = '';
                imgSrcAlt:any = '';
                checkupdatedPrice:any;
                checkupdatedPriceForPartner:boolean = false;
                availabilityCheck:boolean;
                alreadyEnroll:any=0;       
                GlobalThreshold:any;
                couponShow = 0;
                is_deal:any=0;
                hideTemp:boolean = false;
				iphoneX:boolean  = false;
				facilityData1 = [];
				counter = 0;
                searchCriteria: any = {
                                'type': CustomCode.defaultFacilityType,
                                'device': 'web',
                                'lat': parseFloat(CustomCode.timeSquareCoords.lat),
                                'long': parseFloat(CustomCode.timeSquareCoords.long),
                                'currdate': CustomCode.getDateForMapSearch(),
                                'radius': CustomCode.facilityDefaultRadius,
                                'label': CustomCode.timeSquareCoords.defaultLocation,
                                'length_of_stay': 2,
                                'firstLoad' : this.firstLoad
                };

                searchCriteriaCampaign: any = {
                                'arrival_time': CustomCode.getDateWithoutTime(),
                                'length_of_stay': 2,
                                'radius': CustomCode.facilityDefaultRadius,
                                'verbose' : true,
                                'monthly_campaign' : 1,
                                neighID: 0,
                                latitude: parseFloat(CustomCode.timeSquareCoords.lat),
                                longitude: parseFloat(CustomCode.timeSquareCoords.long),
                                };
                                currentSearchType = CustomCode.defaultFacilityType;
                webGarage: any;
                deviceInfo = null;
                activeLoading = false;
                enableDefaultMsg:boolean;
                iconMessage:number=0;
                iconMessageRandom:number=0;
                activeBuyOnline:boolean = false;
                ranValue = 0;
                showSelectBox = true;
                text = '';
                value = '';
                couponMainId:any=0;
                getCouponDisabled: boolean = false;
                isClassVisible: boolean = true;
                boardRate: any = [];
                partnerPostedRate: any = [];
                taxRate: any = 0;
                loadWorkingHours = false;

                selectedFacilityId = '';
                selectedFacilityPrice = '';
                selectedFacilityMrate = '';
                isPpopupShow:any=0;
                isPpopupShowMessage;any='';
                isPpopupShowMessageTitle :any = '';
                isPpopupShowMessageText :any = '';
                bylistingurl='';
                bydetailsurl='';
                bylisting:any=0;
                bydetails:any=0;
                facilities_IdArr:any = [];
                facilitiesAll_IdArr:any = []; 
                updatedInventoryData:any;
                allInventoryData:any= []
                isUpdatedEndTimeFlag:boolean = false;
                MTSMMessage:string = '<div class="text-center">Please wait... </div>';
                qrcodeData: any = '';
                barcodeData: any = '';
                searchedLocation='';
                showlisttab=0;
                noFacility=0;
                isCollapsed = false;
                activeNeighbourhoodTitle ;
                campaignDetails:any;
                listNeighbourhood=[];
                campaignTitle:any;
                campaignDescription:any;
                campaignFooter:any;
                thresold:any=0;
                spaceLeftMessage:string;             
                isSearchPage:boolean= false;
                isAgreed: boolean = false;
                showEnrollForm = false;
                checkedCheckbox:boolean = false;
                hideSendEmailButton:boolean = false;
                hideSendEmailButton2:boolean = false;
                neighBourhood=['central park'];
                                counterFac  = 0;
                public config = { ignoreBackdropClick: true }
                constructor(
                                private deviceService: Ng2DeviceService,
                                private modalService: BsModalService,
                                private ngZoneObj: NgZone,
                                private router: Router,
                                private activatedRouteObj: ActivatedRoute,
                                private searchPageObj: SearchpageserviceService,
                                private userServiceObj: UserserviceService,
                                private changeDetObj: ChangeDetectorRef,
                                public sanitizer: DomSanitizer,
                                private toasterObj: ToastrService,
                                private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
                                private homePageServiceObj: HomepageserviceService,
                                public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
                                private elementRef:ElementRef,
                                private logsService:LogsService,
                                //private progress: NgProgress,
                                private StaticpagesService:StaticpagesService,
                                
                ) { 
                                // for animation show hide actions
                                let random = Array(1,2);
                                let ranValue = random[Math.floor(Math.random()*random.length)]
                                if (localStorage.getItem('access_token')) {
                                                this.iconMessage=1;
                                this.iconMessageRandom=ranValue;
                                if(localStorage.getItem('is_loyalty')=='1')
                                {
                                               this.iconMessage=0;
                                }
                                }else{
                                this.iconMessage=1;
                                this.iconMessageRandom=ranValue;
                }
                                this.iconMessage = 1;
                                this.sidebarOn  = false;
                                this.parkDetail = false;
                                this.parkList = false;
                                this.isMonthlyAction = true;
                }

                                public scrollbarOptions = { axis: 'y', theme: 'minimal' };
                               // for sidebar active and inactive state
                                sidebarOn :any;
                                sidebarOn1 :any=true;
                               parkDetail : any;
                               parkList: any;
                               isMonthlyAction :any;
                               showAmenityFilter:boolean = false;

                newStartTime;
                newEndTime;

                searchType: any = {};

                activeGarage: any = {};
                showOversizedMsg = false;
                IdForShowOversizeMsg = '376'
                formData: any = {};
                lat: number;
                long: number;
                facilityData: any = {};
                facilityDataMonthly: any = {};
                selectedDate: string;
                parkingDetails: any = {};
                allParkings: any = {};
                customizeData: any = {};
                private parseData;
                successFailElement = 'success-failed';
                responseData;
                bookShow = 0;
                radius: number;
                vehicletypeTest:any = [{id:1},{id:2},{id:3},{id:4}];
                selectedMonthlyRate ='';
                facilityRadius = 2;
                facilityLabel = 'Testing URl';
                searchsdate = '';
                options: any = {};
                en: any = {};
                facilitySchedule: any = {};
                couponsData;
                dSD:any = SearchPageScript.defaultDateForDailyMonthlyFacility();
                dailyEndDate:any = SearchPageScript.defaultDateForDailyMonthlyFacility();
                dailyEndDateMore:any;
                nMap: any = {};
                // count=0
                // monthlyDate = SearchPageScript.defaultDateForDailyMonthlyFacility();
                currDate = CustomCode.getDateForMapSearch();
                /* currDateObj = new Date();
                startTime = new Date(this.currDateObj.setMinutes(this.currDateObj.getMinutes() + 30));
                endTime = new Date(this.currDateObj.setHours(this.currDateObj.getHours() + 2.5)); */
                currDateObj = new Date();
                
                coeff = 1000 * 60 * 30;
                startTime = new Date(Math.round(this.currDateObj.getTime() / this.coeff) * this.coeff);

                endTime = new Date(Math.round(this.currDateObj.getTime() / this.coeff) * this.coeff);

                stime = (this.startTime.getHours() > 12) ? (this.startTime.getHours() - 12 + ':' + this.startTime.getMinutes() + ' PM') : (this.startTime.getHours() + ':' + this.startTime.getMinutes() + ' AM');
                etime = (this.endTime.getHours() > 12) ? (this.endTime.getHours() - 12 + ':' + this.endTime.getMinutes() + ' PM') : (this.endTime.getHours() + ':' + this.endTime.getMinutes() + ' AM');
    sdate;
                loggedUserName;
                minDate: Date;
                maxDate: Date;
                public modalRef: BsModalRef;
                mailFormData: any = {};
                respdata;
                printData;
                restrictRequestOnInit = 'stop';
                centerMarkerPosition:any;

                activeMarker;
                isOverlayActive: boolean;

                isActiveFacility = false;
                activeFacilityIndex: any = null;
                markers: any = [];
                markersArray: any = [];
                priceArray: any = [];
                isLoaded = false;
                pageSlug: string = 'terms-and-conditions';
                cmsPageContent: any = {};
                isLoadedterms = false;
                preventUnusedAjax = false;
                garageWorkingHours;
                activeUser;
    isPerMonth=false;
                isNeighbourHood:boolean = false;
                passwordErrMsg = 'Password is required';
                  datepickerOptions = {
                                display: 'bottom',
                                theme: 'ios',
                  }
                  mapType = CustomCode.mapDefaultView;
                  mFirstDateTime;
                  mSecondDateTime;
                  sortingType='distance';
                  isFirstLoad = true;
                  locationTrack='assets/img/location-track.svg';
                  logoIconDark='assets/img/icon_logo-dark.svg';
                  crossSvg='assets/img/cross.svg'
                  ev_charge_icon='assets/img/v8-design/icon-small-universalEV.svg';
                  motorcycle_icon='assets/img/v8-design/icon-small-motocycle.svg'
                  tesla_charger_icon='assets/img/v8-design/icon-small-tesla.svg'
                  open_24hr_icon='assets/img/v8-design/icon-small-24hr.svg'
                  monthly_icon='assets/img/v8-design/icon-small-monthly.svg'
                  oversized_icon='assets/img/v8-design/icon-small-oversized.svg'
                  outdoor_icon='assets/img/v8-design/icon-small-outdoorpark-v2.svg'
                  indoor_icon='assets/img/v8-design/icon-small-indoorpark-v2.svg'
                  park_garage='/assets/img/park_garage.png'
                  list_icon='assets/img/list_icon.svg'
                  amenityfilter='assets/img/amenity-filter.svg'
                  satView='assets/img/satView.png'
                  javascriptCode='javascript:void(0)'
                  monthlyParking='/monthly-parking'
                  telephone='tel:888-705-7845'
                  telephone2='tel:1-877-727-5464'
                  payNow_btn='assets/img/v8-design/mobile-pay.svg'
                  parkSafesmall='assets/img/v8-design/parksafe_small.svg'
                  booknowBadge='assets/img/v8-design/bookpark-bttn.png'
                  parksafeSeal='assets/img/v8-design/parkSafeSeal.svg'
                  dropdownArrow="assets/img/v8-design/dropdown-arrow.svg"
                  mapListIcon="assets/img/v8-design/map-list-icon.svg"
                  seal_best_deals='assets/img/v8-design/seal_best_deals.svg'
                  risk_free_cancellation='assets/img/v8-design/riskfree.svg'
    map_market_location='assets/img/v8-design/marker_location_small.svg'
    map_market_location_d='assets/img/v8-design/marker_location_small_d.svg'
    phone_icon_d='assets/img/v8-design/phone_icon_d.svg'
                  isLoyaltyMember:boolean = false;
                  vehicletype:any;
                  selectedvahicletype: any;
                  selectedvahicletypeText:any='';
                  selectedvahicletypeText1:any='';
                  selectedvahicletypeText2:any='';
                  oneAtATime: boolean = true;
                  is_tax_include=0;
                  updateRateType:any;
                  FgarageWorkingHours;
                  isShowDropDown=1;
                  checksearchPage = false;
                  imgpath = 'assets/img/'; 
      rewaredlogodark = this.imgpath + "icon-rewards-logo-wt.svg";
      trophy =  this.imgpath + 'icon-rewards-troffy.svg';
                  box = this.imgpath + 'icon-rewards-gift.svg'
                  popupOn:any;
                FacilityAvailableFetatures: any;
                getUpdatedPrice = false;
                disableMarker: boolean;
                paramPrice: any;
                enableChatbox: boolean;
                barcodeValue: any = 'd37255005006728'
                couponCodeVaue: any = '341fce58';
                showLoader:boolean = true;
                searchTypeText = 'Monthly';
                unformatedPhone = '';
                array_data: any = {
                                "facility": {
                                                "id": 116,
                                                "short_name": "ZEN-DAYS",
                                                "full_name": "ZENITH PARKING LLC",
                                                "facility_name_modified":'',
                                                "garage_code": "8025",
                                                "facility_type_id": "1",
                                                "between_streets": "48th St and 49th St",
                                                "entrance_location": "790-806 8th Avenue and 254 West 49th Street",
                                                "phone_number": "(212) 581-8590 ",
                                                "base_rate": "70.00",
                                                "reservation_bonus_hours": 1,
                                                "reservation_bonus_rate": 5,
                                                "active": "1",
                                                "base_event_rate": "40.00",
                                                "oversize_fee": "15.00",
                                                "processing_fee": "0.00",
                                                "tax_rate": "0.18375",
                                                "base_event_rate_max_stay": "13",
                                                "garage_type_id": "0",
                                                "service_type_id": "0",
                                                "vehicle_type_id": "0",
                                                "garage_located": "",
                                                "accept_cc": "1",
                                                "accept_echeck": "1",
                                                "monthly_rate": "300.00",
                                                "monthly_rate_main": "300.00",
                                                "neighborhood_id": "8",
                                                "legacy_id": "139",
                                                "elimiwait_location_id": "299",
                                                "beacon_status": "0",
                                                "is_indoor_parking": "1",
                                                "is_outdoor_parking": "0",
                                                "is_tesla_charging": "0",
                                                "is_generic_ev_charging": "0",
                                                "is_motorcycle_parking": "0",
                                                "realtime_window": null,
                                                "coupon_threshold": null,
                                                "merchant_id": "650000009794770",
                                                "slug": "zen-days",
                                                "has_ticketech": true,
                                                "has_monthly": true,
                                                "is_elimiwait_active": false,
                                                "display_name": "790 Eighth Avenue",
                                                "geolocations": {
                                                                "id": 896,
                                                                "address_1": "790 Eighth Avenue",
                                                                "address_2": "",
                                                                "city": "New York",
                                                                "state": "NY",
                                                                "zip_code": "10019",
                                                                "longitude": "-73.9864679",
                                                                "latitude": "40.7614799"
                                                }
                                },
                                "geolocations": {
                                                "id": 896,
                                                "address_1": "790 Eighth Avenue",
                                                "address_2": "",
                                                "city": "New York",
                                                "state": "NY",
                                                "zip_code": "10019",
                                                "longitude": "-73.9864679",
                                                "latitude": "40.7614799"
                                },
                                "coupons": [
                                                {
                                                                "id": 3531,
                                                                "facility_id": "116",
                                                                "rate_type_id": "2",
                                                                "min_stay": "0.00",
                                                                "max_stay": "3.00",
                                                                "price": "26.00",
                                                                "monday": "1",
                                                                "tuesday": "1",
                                                                "wednesday": "1",
                                                                "thursday": "1",
                                                                "friday": "1",
                                                                "saturday": "1",
                                                                "sunday": "1",
                                                                "active": "1",
                                                                "description": "3 Hours Coupon",
                                                                "entry_time_begin": "00:00:00",
                                                                "entry_time_end": "23:59:59",
                                                                "exit_time_begin": "00:00:00",
                                                                "exit_time_end": "23:59:59",
                                                                "category_id": "27",
                                                                "coupon_code": "dt237",
                                                                "deleted_at": null,
                                                                "details": "",
                                                                "partner_id": null,
                                                                "coupon_restrictions": "NOT VALID ON THANKSGIVING DAY",
                                                                "mobile_coupon_code": "341fce58",
                                                                "split_price": [
                                                                                "26",
                                                                                "00"
                                                                ],
                                                                "open_time_description": "Enter any time",
                                                                "days_open_description": "7 Days a week",
                                                                "time_in": "2:43 AM on Nov 01, 2018",
                                                                "time_out": "Nov 15, 2018",
                                                                "min_stay_details": "",
                                                                "max_stay_details": "Max Stay 3 Hours",
                                                                "exit_time_details": "Exit any time",
                                                                "is_coupon": true,
                                                                "is_partner_coupon": false,
                                                                "is_event": false,
                                                                "can_repeat": false,
                                                                "can_always_enter": true,
                                                                "can_always_exit": true,
                                                                "facility": {
                                                                                "id": 116,
                                                                                "short_name": "ZEN-DAYS",
                                                                                "full_name": "ZENITH PARKING LLC           ",
                                                                                "facility_name_modified":'',
                                                                                "garage_code": "8025",
                                                                                "facility_type_id": "1",
                                                                                "between_streets": "48th St and 49th St",
                                                                                "entrance_location": "790-806 8th Avenue and 254 West 49th Street",
                                                                                "phone_number": "(212) 581-8590 ",
                                                                                "base_rate": "70.00",
                                                                                "reservation_bonus_hours": 1,
                                                                                "reservation_bonus_rate": 5,
                                                                                "active": "1",
                                                                                "base_event_rate": "40.00",
                                                                                "oversize_fee": "15.00",
                                                                                "processing_fee": "0.00",
                                                                                "tax_rate": "0.18375",
                                                                                "base_event_rate_max_stay": "13",
                                                                                "garage_type_id": "0",
                                                                                "service_type_id": "0",
                                                                                "vehicle_type_id": "0",
                                                                                "garage_located": "",
                                                                                "accept_cc": "1",
                                                                                "accept_echeck": "1",
                                                                                "monthly_rate": "300.00",
                                                                                "monthly_rate_main": "300.00",
                                                                                "neighborhood_id": "8",
                                                                                "legacy_id": "139",
                                                                                "elimiwait_location_id": "299",
                                                                                "beacon_status": "0",
                                                                                "is_indoor_parking": "1",
                                                                                "is_outdoor_parking": "0",
                                                                                "is_tesla_charging": "0",
                                                                                "is_generic_ev_charging": "0",
                                                                                "is_motorcycle_parking": "0",
                                                                                "realtime_window": null,
                                                                                "coupon_threshold": null,
                                                                                "merchant_id": "650000009794770",
                                                                                "slug": "zen-days",
                                                                                "has_ticketech": true,
                                                                                "has_monthly": true,
                                                                                "is_elimiwait_active": false,
                                                                                "display_name": "790 Eighth Avenue",
                                                                                "geolocations": {
                                                                                                "id": 896,
                                                                                                "address_1": "790 Eighth Avenue",
                                                                                                "address_2": "",
                                                                                                "city": "New York",
                                                                                                "state": "NY",
                                                                                                "zip_code": "10019",
                                                                                                "longitude": "-73.9864679",
                                                                                                "latitude": "40.7614799"
                                                                                }
                                                                },
                                                                "rate_type": {
                                                                                "id": 2,
                                                                                "rate_type": "Coupons"
                                                                }
                                                },
                                                {
                                                                "id": 2814,
                                                                "facility_id": "116",
                                                                "rate_type_id": "2",
                                                                "min_stay": "0.00",
                                                                "max_stay": "12.00",
                                                                "price": "36.00",
                                                                "monday": "1",
                                                                "tuesday": "1",
                                                                "wednesday": "1",
                                                                "thursday": "1",
                                                                "friday": "1",
                                                                "saturday": "1",
                                                                "sunday": "1",
                                                                "active": "1",
                                                                "description": "12 Hours Coupon",
                                                                "entry_time_begin": "00:00:00",
                                                                "entry_time_end": "23:59:59",
                                                                "exit_time_begin": "00:00:00",
                                                                "exit_time_end": "23:59:59",
                                                                "category_id": "20",
                                                                "coupon_code": "dt237",
                                                                "deleted_at": null,
                                                                "details": "",
                                                                "partner_id": null,
                                                                "coupon_restrictions": "NOT VALID ON THANKSGIVING DAY",
                                                                "split_price": [
                                                                                "36",
                                                                                "00"
                                                                ],
                                                                "open_time_description": "Enter any time",
                                                                "days_open_description": "7 Days a week",
                                                                "time_in": "2:43 AM on Nov 01, 2018",
                                                                "time_out": "Nov 15, 2018",
                                                                "min_stay_details": "",
                                                                "max_stay_details": "Max Stay 12 Hours",
                                                                "exit_time_details": "Exit any time",
                                                                "is_coupon": true,
                                                                "is_partner_coupon": false,
                                                                "is_event": false,
                                                                "can_repeat": false,
                                                                "can_always_enter": true,
                                                                "can_always_exit": true,
                                                                "rate_type": {
                                                                                "id": 2,
                                                                                "rate_type": "Coupons"
                                                                }
                                                },
                                                {
                                                                "id": 2816,
                                                                "facility_id": "116",
                                                                "rate_type_id": "2",
                                                                "min_stay": "0.00",
                                                                "max_stay": "6.00",
                                                                "price": "28.00",
                                                                "monday": "1",
                                                                "tuesday": "1",
                                                                "wednesday": "1",
                                                                "thursday": "1",
                                                                "friday": "1",
                                                                "saturday": "1",
                                                                "sunday": "1",
                                                                "active": "1",
                                                                "description": "6 Hours Coupon",
                                                                "entry_time_begin": "00:00:00",
                                                                "entry_time_end": "23:59:59",
                                                                "exit_time_begin": "00:00:00",
                                                                "exit_time_end": "23:59:59",
                                                                "category_id": "22",
                                                                "coupon_code": "dt237",
                                                                "deleted_at": null,
                                                                "details": "",
                                                                "partner_id": null,
                                                                "coupon_restrictions": "NOT VALID ON THANKSGIVING DAY",
                                                                "split_price": [
                                                                                "28",
                                                                                "00"
                                                                ],
                                                                "open_time_description": "Enter any time",
                                                                "days_open_description": "7 Days a week",
                                                                "time_in": "2:43 AM on Nov 01, 2018",
                                                                "time_out": "Nov 15, 2018",
                                                                "min_stay_details": "",
                                                                "max_stay_details": "Max Stay 6 Hours",
                                                                "exit_time_details": "Exit any time",
                                                                "is_coupon": true,
                                                                "is_partner_coupon": false,
                                                                "is_event": false,
                                                                "can_repeat": false,
                                                                "can_always_enter": true,
                                                                "can_always_exit": true,
                                                                "rate_type": {
                                                                                "id": 2,
                                                                                "rate_type": "Coupons"
                                                                }
                                                }
                                ],
                                "mainCoupon": {
                                                "id": 3531,
                                                "facility_id": "116",
                                                "rate_type_id": "2",
                                                "min_stay": "0.00",
                                                "max_stay": "3.00",
                                                "price": "26.00",
                                                "monday": "1",
                                                "tuesday": "1",
                                                "wednesday": "1",
                                                "thursday": "1",
                                                "friday": "1",
                                                "saturday": "1",
                                                "sunday": "1",
                                                "active": "1",
                                                "description": "3 Hours Coupon",
                                                "entry_time_begin": "00:00:00",
                                                "entry_time_end": "23:59:59",
                                                "exit_time_begin": "00:00:00",
                                                "exit_time_end": "23:59:59",
                                                "category_id": "27",
                                                "coupon_code": "dt237",
                                                "deleted_at": null,
                                                "details": "",
                                                "partner_id": null,
                                                "coupon_restrictions": "NOT VALID ON THANKSGIVING DAY",
                                                "mobile_coupon_code": "341fce58",
                                                "split_price": [
                                                                "26",
                                                                "00"
                                                ],
                                                "open_time_description": "Enter any time",
                                                "days_open_description": "7 Days a week",
                                                "time_in": "2:43 AM on Nov 01, 2018",
                                                "time_out": "Nov 15, 2018",
                                                "min_stay_details": "",
                                                "max_stay_details": "Max Stay 3 Hours",
                                                "exit_time_details": "Exit any time",
                                                "is_coupon": true,
                                                "is_partner_coupon": false,
                                                "is_event": false,
                                                "can_repeat": false,
                                                "can_always_enter": true,
                                                "can_always_exit": true,
                                                "facility": {
                                                                "id": 116,
                                                                "short_name": "ZEN-DAYS",
                                                                "full_name": "ZENITH PARKING LLC           ",
                                                                "facility_name_modified":'',
                                                                "garage_code": "8025",
                                                                "facility_type_id": "1",
                                                                "between_streets": "48th St and 49th St",
                                                                "entrance_location": "790-806 8th Avenue and 254 West 49th Street",
                                                                "phone_number": "(212) 581-8590 ",
                                                                "base_rate": "70.00",
                                                                "reservation_bonus_hours": 1,
                                                                "reservation_bonus_rate": 5,
                                                                "active": "1",
                                                                "base_event_rate": "40.00",
                                                                "oversize_fee": "15.00",
                                                                "processing_fee": "0.00",
                                                                "tax_rate": "0.18375",
                                                                "base_event_rate_max_stay": "13",
                                                                "garage_type_id": "0",
                                                                "service_type_id": "0",
                                                                "vehicle_type_id": "0",
                                                                "garage_located": "",
                                                                "accept_cc": "1",
                                                                "accept_echeck": "1",
                                                                "monthly_rate": "300.00",
                                                                "monthly_rate_main": "300.00",
                                                                "neighborhood_id": "8",
                                                                "legacy_id": "139",
                                                                "elimiwait_location_id": "299",
                                                                "beacon_status": "0",
                                                                "is_indoor_parking": "1",
                                                                "is_outdoor_parking": "0",
                                                                "is_tesla_charging": "0",
                                                                "is_generic_ev_charging": "0",
                                                                "is_motorcycle_parking": "0",
                                                                "realtime_window": null,
                                                                "coupon_threshold": null,
                                                                "merchant_id": "650000009794770",
                                                                "slug": "zen-days",
                                                                "has_ticketech": true,
                                                                "has_monthly": true,
                                                                "is_elimiwait_active": false,
                                                                "display_name": "790 Eighth Avenue",
                                                                "geolocations": {
                                                                                "id": 896,
                                                                                "address_1": "790 Eighth Avenue",
                                                                                "address_2": "",
                                                                                "city": "New York",
                                                                                "state": "NY",
                                                                                "zip_code": "10019",
                                                                                "longitude": "-73.9864679",
                                                                                "latitude": "40.7614799"
                                                                }
                                                },
                                                "rate_type": {
                                                                "id": 2,
                                                                "rate_type": "Coupons"
                                                }
                                },
                                "couponCode": "341fce58",
                                "barcode": "d37255005006728",
                                "restriction": "NOT VALID ON THANKSGIVING DAY",
                                "couponValidity": false
                };
                textHideShow="SHOW";
                ShowCouponListOnLoad: boolean = false;
                                rewarded_points:number = CustomCode.rewarded_points;
                                checkInventor:any=0
                pricesortingdone:any=0;
                distancesortingmonthly:any=1;
                distancesortingloader= 0;
                facilitynameforpostal:any=''
                facilitynameforpostaladdress:any=''
                clickonback:any=0
                LoadCampaignFacilities = false
                searchResultCampaign: any = [];
                existCampaignData = 0;
  campaignArray:any =[];
  partnerPageOn:boolean=false;
  partnerZindexOn:boolean=false;
  partnerLocationOn:boolean=false;
                showcoupon=0;
                showcouponp=1;
                overSizeMsg:any='';
                yesdo:boolean=false;
                activeNeighbourhood: any = 'central-park';
                filterBadge = 'filter-badge-loyalty';
                badgePad:boolean = false;
                pageSlugForSidebarContent: string = 'search-page-content';
                CMSsidebarContent: any = {};
                is_monthly_campaign = false;
                sortType:any='distance'
                sortTypes:any='distancemonthly'
                popUP:any=''
                activeNeighborhoodFilter:boolean = false;
                neighbourhoods: any = {};
                hideListView:boolean = false;
                showMonthlyDeal:boolean = false;
                selectedSortType = 'Distance';
                aminitiesCount:number = 0;
                parkDetailsWrapper = $('.parkDetailsWrapper');
                remaining = 0;
                count = 6;
                isType=true;
                isLoadedForfacility:any=true;
                POSuserLat=0;
                POSuserLong=0;
                settypedata:any='daily';
                notMonthly = 1;
                  showPopenter()
                  {
                                localStorage.setItem('mapEvent','1')
                  }
                
                  showPop()
                  {
                                
                                if(!localStorage.getItem('hide_popup') && localStorage.getItem('headerEvenT') )
                                                if(localStorage.getItem('access_token')) {
                                                                if(localStorage.getItem('is_loyalty')=='1')
                                                                {
                                                                                this.alreadyEnroll=0;
                                                                }else{
                                                                                this.alreadyEnroll=0; // do 1 here 
                                                                }
                                                  }else{
                                                                this.alreadyEnroll=0; // do 1 here 
                                                  }
                  }
                ngOnInit() {



                  if(this.router.url.indexOf('monthly-parking')>-1){
                    this.notMonthly=0;
                  }else {
                    this.notMonthly=1;
                  }
                  this.activeNeighbourhood = 'central-park';
                  localStorage.setItem('activeNeighbourhood', 'central-park');
                  setInterval(()=>{
                    if(this.router.url.indexOf('daily') > -1){
                      this.searchTypeText = 'Daily';
                    }else {
                      this.searchTypeText = 'Monthly';
                    }
                  },100)
                  
                  setInterval(()=>{
                                if(window.location.href.indexOf('daily') > -1)
                                {
                                                this.isType=true;
                                }else if(this.router.url.indexOf('partners')>-1)
                                {
                                  this.isType=true; //ye kiya h
                                }else{
                                                this.isType=false;
                                }
                  },4000)
                                
                                
                                if ($(window).width() < 767) {
            this.count = 4;
        }else{
                                                this.count = 5;
                                }
                                //  mobile 

    if(this.router.url.indexOf('partners') > -1)
        {
                  localStorage.removeItem('neighbourhoodActive')
                  localStorage.removeItem('showNeighbordood')
                  localStorage.removeItem('sortBy')
                  
      this.partnerPageOn = true;
      this.partnerZindexOn = true;
      this.partnerLocationOn = true;
        }else{
      this.partnerPageOn = false;
      this.partnerZindexOn = false;
      this.partnerLocationOn = false;
                }
                if(localStorage.getItem('sortBy')){
                                
                                this.selectedSortType = localStorage.getItem('sortBy');
                                
                }
                                this.hideTemp = false;
                                // let abc = setInterval(()=>{
                                                
                                //   if(localStorage.getItem('facilityResponse'))
                                //   {
                                                
                                //              this.tabList('plist','pview')
                                //              localStorage.removeItem('facilityResponse')
                                //              //clearInterval(abc)
                                //              if(localStorage.getItem('showNeighbordood') || localStorage.getItem('neighbourhoodActive')){
                                                                
                                //                            this.activeNeighborhoodFilter = true;
                                                                
                                //                            this.parkList = false
                                //                            this.hideListView = true
                                //                            }else {
                                                                                
                                //                                            this.activeNeighborhoodFilter = false;
                                //                                            this.parkList = true;
                                //                                            this.hideListView = false;
                                //                                            // $("#sortingFilterList").val("distance");
                                //                                             this.selectedSortType='Distance'
                                                                                
                                //                                            this.sortBy(this.selectedSortType)
                                //                            }
                                //   }
                                  
                                // },1000)

                                if(localStorage.getItem('showNeighbordood')){
                                                //this.fetchNeighbourhoodsData();
                                }
                                

                                if(localStorage.getItem('is_loyalty_active') && localStorage.getItem('is_loyalty_active')=='1')
    {
      if (localStorage.getItem('is_loyalty') === "1") {
                                this.isLoyaltyMember=true;
                                
                  }
                }
                let c =    setInterval(() => {
                                if(localStorage.getItem('showMonthlyDeal')){
                                                this.is_deal = true;
                                } else {
                                                this.is_deal = false;
                                }
                                }, 10);
                
                                localStorage.removeItem('monthlyParkingId')
                                localStorage.removeItem('monthlyParkingDate')
                                this.router.events.filter(event => event instanceof NavigationStart).subscribe((event:NavigationStart) => {
      this.closeActiveGarageOverlay();
                });
                this.activeMarker = new google.maps.Marker({
                                position: new google.maps.LatLng(12344, 234555),
                                map: this.nMap,
                                icon: CustomCode.markerIconConfiguration(CustomCode.googleMapActiveIcon, 75, ''),
                                zIndex: 1000
                });

                
                if(localStorage.getItem('searcgPage') ){
                                this.isSearchPage = true
                }
                                this.availabilityCheck = false;
                                this.disableMarker = false;
                                sessionStorage.removeItem('coupon_threshold_price');
                                sessionStorage.removeItem('tabview');
                                setInterval(() => {
                                                this.showPop();
                                                // if(localStorage.getItem('showDeals') && (this.searchCriteria.type == 'monthly')){
                                                //            this.showCampaignDeals = false
                                                //            // this.back();
                                                //            if(this.parkDetail){
                                                //                            this.back()
                                                //            }
                                                // }else {
                                                //            this.showCampaignDeals = false
                                                // }
                                }, 300);

                                // if ($(window).width() < 768  ) {
                                //            let i = 0;
                                //            setInterval(() => {
                                //                            if(this.searchCriteria.type == 'monthly'){
                                //                            this.CallMonthlyCampaign(i);
                                //                            i++;
                                //                            }
                                //            }, 1000);                                                
                                // }

                                if (window.self !== window.top) {
                                                this.isFrame = 1;
                                }
                                if (this.isFrame != 1) {
                                                setInterval(() => {
                                                                this.showPop();
                                                }, 300);
                                }
                                localStorage.removeItem('hide_popup')
                                localStorage.removeItem('mapEvent')
                                localStorage.removeItem('headerEvenT')
                                this.checksearchPage = true;
                                if (this.checksearchPage == true) {
                                  if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/i)) {
                                    if(this.parkDetail){
                                      $('body').removeClass('noserch-scroll');
                                    }else {
                                      $('body').addClass('noserch-scroll');
                                    }
                                                
                                              } else {  $('body').addClass('noserch-scroll');}         
                                }
                                this.sideBarValue = 0;

                                if (navigator.userAgent.match(/(iPod|iPhone|iPad)/i)) {
                                                this.isIphone = 1;
                                }
                                if (navigator.userAgent.match(/(Android)/i)) {
                                                this.isAndroid = 1;
                                } else {
                                                this.desktopD = 1;
                                                this.mobileD = 0;
                                }

                                if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/i)) {
                                                this.mobileD = 1;
                                                this.desktopD = 0;
                                } else {
                                                this.desktopD = 1;
                                                this.mobileD = 0;
                                }
                                if (window.self !== window.top) {
                                                this.isFrame = 1;
                                                this.mobileD = 1;
                                                this.desktopD = 0;
                                                this.isAndroid = 0;
                                                this.isIphone = 0;   //setTimeout(this.myFunction, 2000);
                                }



                                this.sideBarValue = 0
                                this.deviceInfo = this.deviceService.getDeviceInfo();
                                this.startDuration = FilterHelper.defaultStartDurationUrl(CustomCode.getDateForMapSearchSlashFormat());
                                if (this.deviceInfo.browser !== 'safari') {
                                                this.endDuration = FilterHelper.defaultEndDurationUrl(this.startDuration);
                                }
                                else {
                                                //let timeZoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone
                                                //this.monthlyDate = this.startDuration = FilterHelper.defaultStartDurationUrlForSafari(this.searchCriteria.currdate);
                                                this.endDuration = FilterHelper.defaultEndDurationUrlForSafari(this.startDuration);
                                }

                                window.scrollTo(0, 0);
                                $("body").animate({ scrollTop: 0 }, 500);

                                if (this.router.url.indexOf('partners') > 0) {
                                                this.isPartners = true;
                                }

                                this.activatedRouteObj.params.subscribe((params: Params) => {
                                                this.searchedLocation = '';
                                                if (params['lat'] && params['long'] && params['currdate'] && params['radius'] && params['label'] && params['type'] && params['device']) {
                                                                
                                                                if(this.neighBourhood.indexOf(params['label'].toLowerCase())>-1)
                                                                {
                                                                                localStorage.setItem('neighbourhoodActive','1')
                                                                }else{
                                                                                localStorage.removeItem('neighbourhoodActive')
                                                                                //this.selectedSortType='Distance';
                                                                }
                                                                
                                                                let currdate = FilterHelper.removeSpecialCharacters(params['currdate']);

                                                                let DateInSTring = new Date(currdate.replace('T', ' '));
                                                                this.startDuration = DateInSTring;
                                                                this.endDuration = FilterHelper.defaultEndDurationUrl(DateInSTring);
                                                                this.searchedLocation = params['label'];
                                                }else if(params['location']){
                                                                this.activeNeighborhoodFilter=true;
                                                                this.activeNeighbourhood=params['location']
                                                                this.selectedSortType='Neighborhood';
                                                                localStorage.setItem('neighbourhoodActive','1')
                                                                this.searchedLocation = params['location'];
                                                                this.fetchNeighbourhoodsData();
                                                }else{
                                                                if(this.router.url.indexOf('monthly-parking')>-1)
                                                                {
                                                                                this.activeNeighborhoodFilter=true;
                                                                                this.selectedSortType='Neighborhood';
                                                                                localStorage.setItem('neighbourhoodActive','1')
                                                                                this.searchedLocation = params['location'];
                                                                                this.fetchNeighbourhoodsData();
                                                                }else{
                                                                                this.searchedLocation = params['location'];
                                                                }
                                                                
                                                }
                                });
                                this.parkDetail = true;
                                
                                this.parkList = false;

                                if (this.router.url.indexOf('neighborhoods') > -1) {
                                                this.isNeighbourHood = true;
                                                if(!sessionStorage.getItem('FirstLoad')){
                                                                sessionStorage.setItem('FirstLoad', '1');
                                                }
                                
                                }

                                this.minDate = new Date();
                                this.monthlyDate = FilterHelper.defaultStartDuration();
                                this.startTime = new Date(this.startTime.setMinutes(this.startTime.getMinutes() + 30));
                                this.endTime = new Date(this.endTime.setMinutes(this.endTime.getMinutes() + 150));
                                /* $('#stime').val(this.stime);
                                $('#etime').val(this.etime); */
                                SearchPageScript.openTimePicker();
                                if (this.router.url.indexOf('neighborhoods') < 0) {
                                                this.activatedRouteObj.params.subscribe((params: Params) => {
                                                                this.sidebarOn = false;
                                                                this.parkDetail = false;
                                                                
                                                                this.parkList = false;
                                                                this.lat = +params['lat'];
                                                                this.long = +params['long'];
                                                                this.selectedDate = params['currdate'];
                                                                this.radius = +params['radius'];
                                                                let locationUser = params['label'];
                                                                this.searchType = params['type'];

                                                                // if (this.searchType === CustomCode.monthlyConstant) {
                                                                //            $('#dailyFilter').addClass('hidden');
                                                                //            $('#monthlyFilter').removeClass('hidden');
                                                                //            setTimeout(() => {
                                                                //                            $('body').addClass('showChat')
                                                                //              },1000);
                                                                // }
                                                                // else {
                                                                //            $('body').removeClass('showChat');
                                                                // }
                                                                if (this.searchType === CustomCode.monthlyConstant) {
                                                                                $('#dailyFilter').addClass('hidden');
                                                                                $('#monthlyFilter').removeClass('hidden');
                                                                                let times_run= setInterval(function () {
                                                                                                $('body').addClass('showChat');
                                                                                }, 4000);
                                                                                setTimeout(function () {
                                                                                                clearInterval(times_run);
                                                                                }, 10000);
                                                                }
                                                                else {
                                                                                let times_runhide= setInterval(function () {
                                                                                                $('body').removeClass('showChat');
                                                                                }, 100);
                                                                                setTimeout(function () {
                                                                                                clearInterval(times_runhide);
                                                                                }, 7000);
                                                                                
                                                                }

                                                                setTimeout(() => {
                                                                                $('#auto_suggest_input_field').val(locationUser);
                                                                }, 200);


                                                                this.en = CustomCode.datepickerOptions();
                                                      this.loadMap(this.searchCriteria);
                                                });
                                }
                                SearchPageScript.closeSideBar();
                                //this.buyDailyMonthly();
                                this.loggedUserName = CustomCode.getloggedInUserDetails();
                                CustomCode.displayLoginUser();
                                if (!this.loggedUserName) {
                                                $('#mailcoupon').css('display', 'none');
                                } else {
                                                $('#mailcoupon').css('display', 'block');
                                }
                                if(localStorage.getItem('access_token')) {
                                                this.userServiceObj.GetLoyaltyWallet().subscribe(
                                                                response => {
                                                                                this.rewarded_points = response.data.loyalty_config.POINTS_AT_REGISTRATION;
                                                                }
                                                );
                                }

                                this.StaticpagesService.getCmsPageContent(this.pageSlug).subscribe((data:any) => {
                                                this.cmsPageContent = data.result[0];
                                                this.isLoadedterms = true;
                                  }, (error) => {
                                                this.isLoadedterms = true;
                                  }
                                  );

                                  if(this.router.url.indexOf('facility') > -1 || this.router.url.indexOf('partners') > -1)
        {
          this.yesdo=true;
        }else{
          this.yesdo=false;
                                }
                                this.fetchSearchpageCOntentFromCMS();
                                let neigh= setInterval(()=>{
                                                if(localStorage.getItem('neighbourhoodActiveDropDOwn'))
                                                {
                                                                
                                                                this.sortBy('Neighborhood');
                                                                localStorage.removeItem('neighbourhoodActiveDropDOwn');
                                                                localStorage.removeItem('neighbourhoodActive');
                                                                clearInterval(neigh)
                                                                
                                                }},1000)
                                
                }
                
                

                // CallMonthlyCampaign(counter){
                //            if(counter == 0) {this.fetchMonthlyCampaign();}
                // }

                ngAfterViewInit() {

                                // if (this.mobileSearchFilter) {
                                //            this.mobileSearchFilter.updateStartTimeManually(this.startDuration);
                                //            this.mobileSearchFilter.updateEndTimeManually(this.endDuration);
                                // }
                }

                sidebarHeightUpdate() {
                                setTimeout(function () {
                                                if ($('.navbar-custom').length > 0) {
                                                                this.headerBarHeight = $('.navbar-custom').outerHeight() ;
                                                } else {
                                                                this.headerBarHeight = 0;
                                                }
                                                if ($('.searchFilter').length > 0) {
                                                                this.searchFilterHeight = $('.searchFilter').outerHeight();
                                                } else {
                                                                this.searchFilterHeight = 0;
                                                }
                                                if ($('.floatBtnDeals').length > 0) {
                                                                this.floatdealsHeight = $('.floatBtnDeals').outerHeight();
                                                } else {
                                                                this.floatdealsHeight = 0;
                                                }
                                                
                                                if ($('.infoBlock').length > 0) {
                                                                this.searchFilterHeight = this.searchFilterHeight;
                                                } else {
                                                                this.searchFilterHeight = this.searchFilterHeight;
                                                }
                                                this.sideBarValue = this.headerBarHeight + this.searchFilterHeight + this.floatdealsHeight;
                                                this.sideBarValueplus = this.sideBarValue + 70;
                                                this.sideBarValueplusCustome = this.sideBarValueplus + 150;
                                                this.sideBarValeAdded = this.sideBarValue + 40;
                                                
                                                // $('.facilityList').css({ 'height': 'calc(100% - ' + this.sideBarValue + 'px)' });
                                             
                                                this.calcHight = this.windowHight - this.sideBarValue;
                                                // $('.searchFilter').css({ 'margin-top': +this.headerBarHeight + 'px' });
                                                if ($(window).width() > 768) {
                                                  $('.webSection').css({ 'height': 'calc(100vh - ' + this.sideBarValeAdded + 'px)' });
                                                $('.facilityList').css({ 'max-height': 'calc(100vh - ' + this.sideBarValueplusCustome + 'px)' });
                                                }

                                                this.sideBarValueplus = this.sideBarValue + 50;

                                                if ($(window).width() < 768) {
                                                                this.sideBarValueplusCustome = 0;
                                                                this.calcHightMobile = '100vh';
                                                                $('.webSection').css({ 'height': 'calc(100vh - 130px)!important' });
                                                                $('.transparentunderli').css({ 'height': '100px' });
                                                               


                                                                
                                                }
                                                else {
                                                                this.calcHightMobile = this.calcHight;
                                                                $('.transparentunderli').css({ 'height': '0px' });
                                                }

                                                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                                                                this.calcHightMobile = '100vh';
                                                                //   $('.nghMap').css({'top':'80px'});
                                                }
                                                if (this.isFrame == 1) {
                                                                // $('.negiMap').css({'height':'100vh'});
                                                                // $('.nghMap').css({'top':'0px'});
                                                }
                                                if ($(window).width() < 768) {
                                                                // $('.negiMap').css({'height':'100vh'});
                                                                // $('.nghMap').css({'top':'80px'});
                                                } else {
                                                                // $('.negiMap').css({'height':'calc(100vh - '+this.sideBarValue+'px)'});

                                                                // $('.nghMap').css({'top':+this.sideBarValue+'px'});
                                                }

                                }, 300);


                }

                /*
                                Places active marker over selected garage.
                */
                dropActiveMarker(lat, lng) {
                                this.removeActiveMarker();
                                this.activeMarker = new google.maps.Marker({
                                                position: new google.maps.LatLng(lat, lng),
                                                map: this.nMap,
                                                icon: CustomCode.markerIconConfiguration(CustomCode.googleMapActiveIcon, 75, ''),
                                                zIndex: 1000
                                });

                                google.maps.event.addListener(this.activeMarker,'click', () => {
                                                this.isOverlayActive = true;
                                });

                                this.nMap.setCenter(this.activeMarker.getPosition());

                                if (this.isNeighbourHood) {
                                                this.nMap.panBy(-100, -150);
                                } 
                                if(this.router.url.indexOf('partners') > -1){

                                                this.nMap.panBy(-100, -150);
                                }
                                else {
                                                this.nMap.panBy(-100, -150);
                                }
                }

                /*
                                Removes active marker over selected garage.
                */
                removeActiveMarker() {
                                if (this.activeMarker) {
                                                this.activeMarker.setMap(null);
                                                this.closeActiveGarageOverlay();
                                }
                }
                /*
                                Displays selected garage overlay above active marker pin. 
                                This is called separately from dropActiveMarker, due to obtaining garage details in different states.
                */
                displayActiveGarageOverlay() {
                                // Get and set css position for overlay
                                var markerPixelPoint = this.fromLatLngToPixel(this.activeMarker.getPosition(), this.nMap);
                                var overlayPosition = this.calculateOverlayPosition(markerPixelPoint);
                                this.isOverlayActive = true;
                                var el = document.getElementsByClassName('active-garage-overlay-container');
                                if (el) {
                                                if(this.router.url.indexOf('partners') > -1){
                                                                
                                                var overlayElement = el[0] as HTMLElement;
                                                overlayElement.style.left = overlayPosition.x + 'px';
                                                overlayElement.style.top = overlayPosition.y + - 80 +  'px';
                                                }else if(this.router.url.indexOf('search') > -1 && this.router.url.indexOf('monthly') > -1 ){
                                                                
                                                                var overlayElement = el[0] as HTMLElement;
                                                                overlayElement.style.left = overlayPosition.x + 'px';
                                                                overlayElement.style.top = overlayPosition.y + - 105 +  'px';
                                                }else{
                                                var overlayElement = el[0] as HTMLElement;
                                                overlayElement.style.left = overlayPosition.x + 'px';
                                                overlayElement.style.top = overlayPosition.y - 80  + 'px' ;

                                }
                                                
                                }
                }

                /*
                                Closes selected garage overlay above active marker pin.
                */
                closeActiveGarageOverlay() {
                                this.isOverlayActive = false;
                }

                /*
                                Calculate pixel point from map marker
                */
                fromLatLngToPixel(position, map) {
                                var scale = Math.pow(2, map.getZoom());
                                var proj = map.getProjection();
                                var bounds = map.getBounds();
                
                                var nw = proj.fromLatLngToPoint(
                                                new google.maps.LatLng(
                                                                bounds.getNorthEast().lat(),
                                                                bounds.getSouthWest().lng()
                                                ));
                                var point = proj.fromLatLngToPoint(position);

                                var pointTop = Math.floor((point.x - nw.x) * scale);
                                var pointLeft = Math.floor((point.y - nw.y) * scale);

                                return new google.maps.Point(pointTop, pointLeft);
                }

                /*
                                Calculate overlay position from map marker pixel point
                */
                calculateOverlayPosition(pixelPoint) {
                                var offsetLeft, offsetTop;
                                var pointLeft = pixelPoint.x;
                                var pointTop = pixelPoint.y;

                                if (this.isNeighbourHood) {
                                                offsetLeft = -280;
                                                offsetTop = 200;
                                } else {
                                                offsetLeft = 171;
                                                offsetTop = 200;
                                }
                                
                                return new google.maps.Point(pointLeft-offsetLeft, pointTop-offsetTop);
                }

                getPosition() {
                                var el = document.getElementById('parkDetail');
                                var btn = $("#parkDetail");
                                var position = btn.position();
                                let y = el.scrollTop;
                                this.yscroll = y;

                                if ($(window).width() > 600) {
                                                this.yscroll = 350 - y + 'px';

                                } else {
                                }
                }


                toViewScroll() {
                                // var elmnt = document.getElementById("parkDetail");
                                // elmnt.scrollTop = elmnt.scrollHeight;
                                $("#parkDetail").animate({ scrollTop: 1000 }, "slow");
                                

                                
                }


                btnEarlyBrid() {
                                if (this.EarlyBrid == true) {
                                                this.EarlyBrid = false;
                                                this.earlybirIcon = "fa fa-info-circle";

                                }
                                else {
                                                this.EarlyBrid = true;
                                                this.earlybirIcon = "fa fa-times";

                                }
                }
                modalClose() {
                                this.EarlyBrid = false;
                }
                displayableStartDateWrapper() {
                                let date= new Date($('#startDateWithID').val().trim())
                                                //return 
                                                return  (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear());

                }

                displayStartDateMonthly() {
                                let date= new Date($('#time_search').val().trim())
                                let  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let d = new Date();
        let monthName=months[date.getMonth()]; 
                                                return  ( monthName + ' ' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())));
                }

                navigateBuyMonthlyPage() {

                               // gtag_report_conversion(this.gtmUrl);

                                // gtag('event', 'conversion', {
                                //                 'send_to': 'AW-876742851/rhhgCLKWm3UQw5GIogM',
                                //                 'value': 20.0,
                                //                 'currency': 'USD',
                                //                 'transaction_id': '',
                                //                 'eventCallback': window['callbackgtm'],
                                // });
                                let url = "/buy-monthly/" + this.allParkings.facility_id + "/" + FilterHelper.formatBuyMonthlyDate(FilterHelper.makeDurationDateObject($('#startdate').val(), $('#startTime').val()));
                                
                                this.router.navigate([url]);
                }

                navigateRequestInfoPage(facilityName){
                                
                                localStorage.setItem('preffred_location_type', '1');
                                localStorage.setItem( 'preffred_location_bysearch' , this.FacilityAvailableFetatures.facility.facility_name_modified);
                                this.router.navigate(['/monthly-parking-information']);
                }

                getMonthlyStartDate() {

                                return FilterHelper.formatBuyMonthlyDate(this.startDuration);

                }

                displayableEndDateWrapper() {
                                if(this.isUpdatedEndTimeFlag){
                                                return this.dailyEndDateMore;
                                }else{
                                                let date= new Date($('#endDateWithID').val().trim())
                                                //return 
                                                return  (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear());
                                                //return $('#endDateWithID').val().trim();
                                }
                }

                dateTimeMobile() {
                                sessionStorage.setItem('editTime', '1')
                                sessionStorage.setItem('openByClick', '1')

                }

                buyDailyMonthly() {
                                const dailyMonthly = this.searchCriteria.type;
                                if(0)
                                {
                                                let facilityId = this.allParkings.facility_id;
                                                if(dailyMonthly=='monthly')
                                                {
                                              //  ga('send', 'event', { eventCategory: "Book Now, Monthly", eventLabel:CustomCode.BASEURL+'/search', eventAction: 'click'});            
                                                                localStorage.setItem('clickBuyMonthly','1');
                                                                let user_id_ai='Unknown';
                                                                if(localStorage.getItem('userid'))
                                                                {
                                                                                user_id_ai=localStorage.getItem('userid');
                                                                }else{
                                                                                user_id_ai='Unknown';
                                                                }              
                                                                (<any>window).dataLayer.push(
                                                                                {
                                                                                'event': 'BuyMonthlyClick',
                                                                                                'UserId' :user_id_ai,
                                                                                                'Facility': [{
                                                                                                'name': this.allParkings.entrance_location,                      // Name or ID is required.
                                                                                                'id': facilityId,
                                                                                                'price': this.allParkings.monthly_rate_main,
                                                                                                'category': 'Monthly',
                                                                                                }]
                                                                                })
                                                                                $("a#loginPopup")[0].click();
                                                                                //
                                                                                
                                                                                let mD = $('#monthlydate').val().toString().split('/');
                                                                                let fDate = mD[2] + '-' + mD[0] + '-' + mD[1];
                                                                                //this.router.navigate([routeNavigate, facId, fDate]);
                                                                                localStorage.setItem('monthlyParkingId',facilityId)
                                                                                localStorage.setItem('monthlyParkingDate',fDate)
                                                                                return false;
                                                }
                                                                
                                                
                                }
                               // ga('send', 'event', { eventCategory: 'web reservation', eventAction: 'click'});

                                // const facId = $('#dailymonthlybutton').attr('facId');
                                // const rate = $('#dailymonthlybutton').attr('rate');
                                // const dailyMonthly = $('#dailymonthlybutton').attr('buyType');

                                const facId = this.allParkings.facility_id;
                                
                                if(dailyMonthly=='monthly')
                                {
                                 // ga('send', 'event', { eventCategory: "Book Now, Monthly", eventLabel:CustomCode.BASEURL+'search', eventAction: 'click'});            
                                                let user_id_ai='Unknown';
                                                if(localStorage.getItem('userid'))
                                                {
                                                                user_id_ai=localStorage.getItem('userid');
                                                }else{
                                                                user_id_ai='Unknown';
                                                }              
                                                (<any>window).dataLayer.push(
                                                {
                                                'event': 'BuyMonthlyClick',
                                                                'UserId' :user_id_ai,
                                                                'Facility': [{
                                                                  'name': this.allParkings.entrance_location,                      // Name or ID is required.
                                                                  'id': facId,
                                                                  'price': this.allParkings.monthly_rate_main,
                                                                  'category': 'Monthly',
                                                                }]
                                                })
                                }
                                localStorage.setItem('monthly', this.monthlyDate);
                                let routeNavigate = '/buy-monthly';
                                if (dailyMonthly === 'daily') {

              //  ga('send', 'event', { eventCategory: "Book Now, Daily", eventLabel:CustomCode.BASEURL+'search', eventAction: 'click'});

                                                if(!this.loggedUserName || ( !this.isLoyaltyMember && this.loggedUserName ) ){
                                                                //localStorage.setItem('reserveNowClick', '1');
                                                                //sessionStorage.setItem('promoApply', '1');
                                                                //ocalStorage.setItem('promocode', this.FacilityAvailableFetatures.paynow_promocode);
                                                                //localStorage.setItem('is_landing_page', 'true');
                                                                //localStorage.setItem('action_type', 'apply');
                                                }
                                                
                                                const rate = this.allParkings.price;
                                                let date=new Date($('#startDateWithID').val());
                                                const sDate=   ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + date.getFullYear();
                                                date=new Date($('#endDateWithID').val());
                                                const eDate =    ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + date.getFullYear();
                                                const sTime = $('#startTime').val().toString().trim();
                                                const eTime = $('#endTime').val().toString().trim();
                                                let redirect = sDate + '&' + sTime + '&&' + eDate + '&' + eTime;
                                                redirect = redirect.split('/').join('-');
                                             //   console.log(redirect)
                                                const navURL = "/payment/" + facId + "/" + redirect + "/" + this.paramPrice;
                                                this.bydetailsurl=navURL;
                                                if(this.isPpopupShow==1)
                                                {
                                                                this.loadWorkingHours = true;
                                                                this.bydetails=1;
                                                                let el: HTMLElement = this.myDiv.nativeElement as HTMLElement;                           
                                                                el.click();
                                                }else{
                                                                this.bydetails=0;
                                                                this.loadWorkingHours = true;
                                                                this.router.navigate([navURL]);
                                                }
                                                
                                } else {
                                                if($('#time_search').val()){
                                                                const rate = this.allParkings.monthly_rate_main;
                                                                const mDate = $('#time_search').val().toString().trim();
                                                                if (mDate !== '') {
                                                                const mDate = $('#time_search').val().toString().trim();
                                                                let mD=new Date($('#time_search').val());
                                                //const mDDormat = $('#monthlydate').val().toString().split('/');
                                                                                const fDate = mD.getFullYear()+'-'+((mD.getMonth() > 8) ? (mD.getMonth() + 1) : ('0' + (mD.getMonth() + 1))) + '-' + ((mD.getDate() > 9) ? mD.getDate() : ('0' + mD.getDate()));
                                                                                this.router.navigate([routeNavigate, facId, fDate]);
                                                                } else {
                                                                                // this.toasterObj.error('End time should be greater than start time', 'error');
                                                                }
                                                }
                                }
                //});
}

showMapMarkers(){
  this.sidebarOn = false;
	this.sidebarOn1=false;
  this.parkList = true;
  this.parkDetail = false;
  sessionStorage.removeItem('tabview');
  this.back(1);
}

                back(value) {
					// alert(1);
                                if(value ==1){
                                this.clickonback=1
                //  if(localStorage.getItem('showNeighbordood')){
                //            this.hideListView = true
                //  }
                                if(localStorage.getItem('sortBy') !== 'Neighborhood'){
                                                localStorage.removeItem('showNeighbordood');
                                                localStorage.removeItem('neighbourhoodActiveDropDOwn');
                                }
                                this.removeActiveMarker();
                                // localStorage.setItem('hideForPan','1')
                                this.textHideShow='SHOW';
								this.sidebarOn = false;
								this.sidebarOn1=false;
                                this.EarlyBrid = false;
                             //   console.log(this.parkList, '222');
                                if (this.parkList == true) {
                                                sessionStorage.removeItem('tabview')
                                                setTimeout(() => {
                                                                //alert(25)
                                                                this.parkList = true;;
                                                }, 0);
                                } else {
                                                setTimeout(() => {
                                                                this.hideListView = false;
                                                                // this.parkDetail = false;

                                                                setTimeout(() => {
                                                                                this.parkDetail = false;
                                                                                if (sessionStorage.getItem('tabview')) {
                                                                                                this.tabList('plist', 'pview')
                                                                                }
                                                                }, 300);


                                                                if(localStorage.getItem('showNeighbordood')){
                                                                                
                                                                                this.parkList = false;
                                                                }else {
                                                                                this.parkList = true;
                                                                                this.hideListView = false;
                                                                }
                                                                
                                                                //this.tabList('plist', 'pview')

                                                                // if($(window).width() < 768 && sessionStorage.getItem('showNeigborMarker') ){
                                                                //            // this.tabList('plist', 'pview')
                                                                // }
                                                                // else {
                                                                //            this.tabList('plist', 'pview')
                                                                // }
                                                                
                                                }, 0);
                                }
                                if(localStorage.getItem('showNeighbordood')){
                                                this.parkList == false;
                                                setTimeout(() => {
                                                                this.parkDetail = false;
                                                                if (sessionStorage.getItem('tabview')) {
                                                                                this.tabList('plist', 'pview')
                                                                }
                                                                
                                                                this.activeNeighborhoodFilter = true;
                                                                this.hideListView = true
                                                }, 1000);                
                                }
                                $('body').removeClass('noscroll');
                                
                }else {
                                this.removeActiveMarker();
                                if(localStorage.getItem('sortBy')){
                                                if(localStorage.getItem('sortBy') !== 'Neighborhood' ){
                                                                this.updateSortSelected(localStorage.getItem('sortBy'));
                                                                this.parkList = true;
                                                                this.hideListView = false;
                                                }
                                                if(localStorage.getItem('sortBy') === 'Neighborhood' && localStorage.getItem('showNeighbordood') ){
                                                                
                                                                this.activeNeighborhoodFilter = true;
                                                                this.hideListView = false;
                                                                
                                                                this.parkList = false;
                                                                                setTimeout(() => {
                                                                                                this.parkDetail = false; this.tabList('plist', 'pview');
                                                                }, 300);
                                                                // if(localStorage.getItem('showMonthlyDeal')){
                                                                //            $('#deals').trigger('click');
                                                                // }

                                                                }
                                }
                                else {
                                                if(localStorage.getItem('neighbourhoodActive')){
                                                                
                                                                this.activeNeighborhoodFilter = true;
                                                                this.hideListView = false;
                                                                
                                                                this.parkList = false;
                                                }else {
                                                                this.parkList = true;
                                                                this.hideListView = false;
                                                }
                                                this.sidebarOn = true;

                                                if (this.router.url.indexOf('partners') > -1) {
                                                                setTimeout(() => { this.parkDetail = false; this.tabList('plist', 'pview') }, 500);
                                                }
                                                

                                }
                                this.updateIsActiveFacility();
                }
                }
                // showSideBar()
                // {
                                
                //            setTimeout(()=>{
                //                            localStorage.setItem('facilityResponse','1')
                //                            //$('.listViewShow').trigger('click');
                //            },1000)
                // }
                showSideBar()
                {
                                
                                setTimeout(()=>{
                                                //localStorage.setItem('facilityResponse','1')
                                                this.showNeighborhood(); 
                                                this.tabList('plist','pview')
                                                $('.listViewShow').trigger('click');
                                },1000)
                }
                
                tryPrintScaleValue() {
                                if (this.monthlypark)
                                                this.searchCriteria.is_monthlypark = this.monthlypark
                                else
                                                this.searchCriteria.is_monthlypark = false
                                if (this.motorcycle)
                                                this.searchCriteria.is_motorcycle_parking = this.motorcycle
                                else
                                                this.searchCriteria.is_motorcycle_parking = false
                                if (this.oversized)
                                                this.searchCriteria.is_oversized = this.oversized
                                else
                                                this.searchCriteria.is_oversized = false
                                if (this.evcharging)
                                                this.searchCriteria.is_generic_ev_charging = this.evcharging
                                else
                                                this.searchCriteria.is_generic_ev_charging = false
                                if (this.tesla)
                                                this.searchCriteria.is_tesla_charging = this.tesla
                                else
                                                this.searchCriteria.is_tesla_charging = false
                                if (this.outdoor)
                                                this.searchCriteria.is_outdoor_parking = this.outdoor
                                else
                                                this.searchCriteria.is_outdoor_parking = false
                                if (this.indoor)
                                                this.searchCriteria.is_indoor_parking = this.indoor
                                else
                                                this.searchCriteria.is_indoor_parking = false
                                if (this.twentyhour)
                                                this.searchCriteria.is_twentyhour = this.twentyhour
                                else
                                                this.searchCriteria.is_twentyhour = false
                                if (this.is_deal)
                                                this.searchCriteria.monthly_campaign = this.is_deal
                                else
                                                this.searchCriteria.monthly_campaign = false
                                
                                this.updateFacilityFilters.emit(this.searchCriteria);
                }
                check()
                {
                                this.parkList=false;
                                $('#deals').trigger('click');
                }
                buyFromMobile() {
                              //  ga('send', 'event', { eventCategory: 'mobile reservation', eventAction: 'click' });
                                const facId = $('#dailymonthlybutton').attr('facId');
                                const rate = $('#dailymonthlybutton').attr('rate');
                                const dailyMonthly = $('#dailymonthlybutton').attr('buyType');
                                localStorage.setItem('monthly', this.monthlyDate);
                                let routeNavigate = '/buy-monthly';
                                $('body').addClass('noscroll');
                                this.sidebarOn = true;

                                this.parkDetail = true;
                                if (dailyMonthly === 'daily') {
                                                let date=new Date($('#startDateWithID').val());
                                                const sDate=   ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + date.getFullYear();
                                                //const sDate = $('#startdate').val().toString().trim();
                                                date=new Date($('#endDateWithID').val());
                                                const eDate =    ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + date.getFullYear();
                                                //const eDate = $('#enddate').val().toString().trim();
                                                const sTime = $('#startTime').val().toString().trim();
                                                const eTime = $('#endTime').val().toString().trim();
                                                let redirect = sDate + '&' + sTime + '&&' + eDate + '&' + eTime;
                                                redirect = redirect.split('/').join('-');
                                                const navURL = "/payment/" + facId + "/" + redirect + "/" + this.paramPrice;
                                                this.router.navigate([navURL]);
                                } else {
                                                let buyMonthlyDate = FilterHelper.formatBuyMonthlyDate(this.monthlyDate);
                                                // const mDate = $('#monthlydate').val().toString().trim();

                                                if (buyMonthlyDate !== '') {
                                                                let mD=new Date($('#time_search').val());
                                                //const mDDormat = $('#monthlydate').val().toString().split('/');
                                        const fDate = mD.getFullYear()+'-'+((mD.getMonth() > 8) ? (mD.getMonth() + 1) : ('0' + (mD.getMonth() + 1))) + '-' + ((mD.getDate() > 9) ? mD.getDate() : ('0' + mD.getDate()));
                                
                                                                this.router.navigate([routeNavigate, facId, fDate]);
                                                } else {
                                                                // this.toasterObj.error('End time should be greater than start time', 'error');
                                                }
                                }
                }

                updateData() {
                                $('#petime').val($('#etime').val());
                                $('#pstime').val($('#stime').val());
                                let startDate = new Date($('#startdate').val());
                                let endDate = new Date($('#enddate').val());
                                this.dSD = startDate;
                                this.dailyEndDate = endDate;
                }

                manageDatePicker(val) {
                                $('#' + val).trigger('click');
                }

                updateSearchLengthOfStay(val) {
                                this.searchCriteria.length_of_stay = val;
                }

                getPStartDate() {
                                if (this.searchCriteria.firstLoad === true) {
                                                const sDate = $('#pstartdate').val();
                                                const eDate = $('#penddate').val();
                                                const sTime = $('#pstime').val();
                                                const eTime = $('#petime').val();
                                                if (this.searchCriteria.type == 'daily') {
                                                                const lengthOfStay = SearchPageScript.getLengthOfStay(sDate, sTime, eDate, eTime);

                                                                if (lengthOfStay > 0) {
                                                                                const reservationTime = sDate.toString().split('/');
                                                                                const m = reservationTime[1];
                                                                                const d = reservationTime[0];
                                                                                const y = reservationTime[2];
                                                                                const rTime = y + '-' + d + '-' + m + '' + this.changeToFullTime(this.stime);
                                                                                this.searchCriteria.length_of_stay = lengthOfStay;
                                                                                this.searchCriteria.currdate = rTime;
                                                                                this.updateFilters.emit(this.searchCriteria);
                                                                } else {
                                                                                this.dailyEndDate = this.dSD;
                                                                }
                                                }
                                }
                }

                getPStartDateForNeighbourhood() {
                                                const sDate = $('#pstartdate').val();
                                                const eDate = $('#penddate').val();
                                                const sTime = $('#pstime').val();
                                                const eTime = $('#petime').val();
                                                if (this.searchCriteria.type == 'daily') {
                                                                const lengthOfStay = SearchPageScript.getLengthOfStay(sDate, sTime, eDate, eTime);

                                                                if (lengthOfStay > 0) {
                                                                                const reservationTime = sDate.toString().split('/');
                                                                                const m = reservationTime[1];
                                                                                const d = reservationTime[0];
                                                                                const y = reservationTime[2];
                                                                                const rTime = y + '-' + d + '-' + m + '' + this.changeToFullTime(this.stime);
                                                                                this.searchCriteria.length_of_stay = lengthOfStay;
                                                                                this.searchCriteria.currdate = rTime;
                                                                                this.updateFilters.emit(this.searchCriteria);
                                                                } else {
                                                                                this.dailyEndDate = this.dSD;
                                                                }
                                }
                }

                getPEndDate() {
                                if (this.searchCriteria.firstLoad === true) {
                                                const sDate = $('#pstartdate').val();
                                                const eDate = $('#penddate').val();
                                                const sTime = $('#pstime').val();
                                                const eTime = $('#petime').val();
                                                if (this.searchCriteria.type == 'daily') {
                                                                const lengthOfStay = SearchPageScript.getLengthOfStay(sDate, sTime, eDate, eTime);
                                                                if (lengthOfStay > 0) {
                                                                                const reservationTime = sDate.toString().split('/');
                                                                                const m = reservationTime[1];
                                                                                const d = reservationTime[0];
                                                                                const y = reservationTime[2];
                                                                                const rTime = y + '-' + d + '-' + m + '' + this.changeToFullTime(this.stime);
                                                                                this.searchCriteria.length_of_stay = lengthOfStay;
                                                                                this.searchCriteria.currdate = rTime;
                                                                                this.updateFilters.emit(this.searchCriteria);
                                                                } else {
                                                                                this.dSD = this.dailyEndDate;
                                                                }
                                                }
                                }
                }

                getPEndTime($event) {
                                if (this.searchCriteria.firstLoad === true) {

                                                const cDate = new Date();
                                                let tempEndTime = new Date(this.endTime);
                                                let bookingHours = this.getLengthOfStayEndTime($event);
                                                if (bookingHours < 2 && this.searchCriteria.type == 'daily') {
                                                                setTimeout(() => {
                                                                                this.endTime = new Date(tempEndTime);
                                                                                this.toasterObj.error('In order to book a reservation, minimum booking time must be at least 2 hours', 'Error');
                                                                }, 100);
                                                                return;
                                                } else {
                                                                if ($event.getTime() > cDate.getTime()) {
                                                                                this.endTime = $event;
                                                                                const mnts = this.endTime.getMinutes() < 10 ? '0' + this.endTime.getMinutes() : this.endTime.getMinutes();
                                                                                const tTime = CustomCode.getUserTime(this.endTime);
                                                                                $('#petime').val(tTime);

                                                                                const sDate = $('#pstartdate').val();
                                                                                const eDate = $('#penddate').val();
                                                                                const sTime = $('#pstime').val();
                                                                                const eTime = $('#petime').val();
                                                                                if (this.searchCriteria.type == 'daily') {
                                                                                                const lengthOfStay = SearchPageScript.getLengthOfStay(sDate, sTime, eDate, eTime);
                                                                                                if (lengthOfStay > 0) {
                                                                                                                const reservationTime = sDate.toString().split('/');
                                                                                                                const m = reservationTime[1];
                                                                                                                const d = reservationTime[0];
                                                                                                                const y = reservationTime[2];
                                                                                                                const rTime = y + '-' + d + '-' + m + '' + this.changeToFullTime(this.stime);
                                                                                                                this.searchCriteria.length_of_stay = lengthOfStay;
                                                                                                                this.searchCriteria.currdate = rTime;
                                                                                                                this.updateFilters.emit(this.searchCriteria);
                                                                                                } else {

                                                                                                }
                                                                                }
                                                                } else {
                                                                                this.searchCriteria.length_of_stay = 0;
                                                                                $('#petime').val(CustomCode.getUserTime($event));
                                                                                this.toasterObj.error('You cannot book the reservation in the past', 'error');
                                                                }
                                                }

                                }
                }
                changeRate(rate, vahicletype,withouttaxrate,hours_des,des)
                {
                                this.selectedMonthlyRate = rate;
                                const facRate = (withouttaxrate > 0) ? '$' + withouttaxrate : 'N/A';
                                this.baserate = facRate;
                                this.selectedvahicletype  =  vahicletype;
                                this.selectedvahicletypeText=hours_des+" "+des
                                this.selectedvahicletypeText1 = hours_des
                                this.selectedvahicletypeText2 = des
                                sessionStorage.setItem('selectedvahicletype', vahicletype);
                                $('.dropdown.selectBoxMap').removeClass('open');
                }

                changeRateUpdated(rate) {
                                let facRateNew = '';
                                const facRate = (rate != 'N/A' && rate != '0') ? '$' + rate : 'N/A';
                                if (facRate != 'N/A') {
                                                facRateNew = facRate.replace('.00', '');
                                } else {
                                                facRateNew = 'N/A';
                                }
                                return facRateNew;
                }
                changeRateUpdatedforsort(rate) {
                                if(rate==0 || rate=='N/A')
                                {
                                                return 'N/A';
                                }else{
                                                return '$'+rate.toString().replace('.00','');
                                }
                }
                formatRate(rate) {
                                if (rate) {
                                                let formattedRate = String(rate);
                                                return formattedRate.includes('N/A') ? 'N/A' : '$' + formattedRate.replace('.00', '');
                                }
                }
                updateEndTime() {
                                let ssTime = this.startTime;
                                let newsTime = new Date(ssTime);
                                this.endTime = new Date(newsTime.setMinutes(newsTime.getMinutes() + 120));
                }

                getPStartTime($event) {
                                if (this.searchCriteria.firstLoad === true) {
                                                //const cDate = new Date();
                                                let cDate = new Date();
                                                const updatedDate = new Date(this.dailyEndDate);
                                                cDate.setDate(updatedDate.getDate());
                                                cDate.setMonth(updatedDate.getMonth());
                                                cDate.setFullYear(updatedDate.getFullYear());
                                                if ($event.getTime() > cDate.getTime()) {
                                                                this.startTime = $event;

                                                                const sTime = CustomCode.getUserTime(this.startTime);
                                                                $('#pstime').val(sTime);

                                                                const sDate = $('#pstartdate').val();
                                                                const eDate = $('#penddate').val();
                                                                const eTime = $('#petime').val();
                                                                this.updateEndTime();
                                                                if (this.searchCriteria.type == 'daily') {
                                                                                const lengthOfStay = SearchPageScript.getLengthOfStay(sDate, sTime, eDate, eTime);
                                                                                if (lengthOfStay > 0) {
                                                                                                const reservationTime = sDate.toString().split('/');
                                                                                                const m = reservationTime[1];
                                                                                                const d = reservationTime[0];
                                                                                                const y = reservationTime[2];
                                                                                                const rTime = y + '-' + d + '-' + m + '' + this.changeToFullTime(this.stime);
                                                                                                this.searchCriteria.length_of_stay = lengthOfStay;
                                                                                                this.searchCriteria.currdate = rTime;
                                                                                                this.updateFilters.emit(this.searchCriteria);
                                                                                } else {

                                                                                }
                                                                }
                                                } else {
                                                                this.searchCriteria.length_of_stay = 0;
                                                                $('#pstime').val(CustomCode.getUserTime($event));
                                                                this.toasterObj.error('You cannot book the reservation in the past', 'error');
                                                }


                                }
                }

                getFormattedTime(val) {
                                return CustomCode.getUserTime(val);
                }

                getFormattedDate(val) {
                                const currDate = val.toLocaleDateString().substr(0, 10);
                                return currDate;
                }

                updateMobileEndTime() {
                                let ssTime = this.startTime;
                                let newsTime = new Date(ssTime);
                                this.endTime = new Date(newsTime.setMinutes(newsTime.getMinutes() + 120));

                                /* const stime = CustomCode.getUserTime(ssTime);

                                const splitDate = stime.split(' ');
                                const getHour = splitDate[0].split(':');
                                const amPm = splitDate[1];
                                if(amPm === 'PM' && parseInt(getHour[0]) >= 10) {
                                                                                                const dateObj = new Date();
                                                                                                const nDate = new Date(dateObj.setDate(dateObj.getDate() + 1));
                                                                                                const newMonth = nDate.getMonth() + 1;
                                                                                                this.dailyEndDate = newMonth + '/' + nDate.getDate() + '/' + nDate.getFullYear();
                                } */
                }


                getMobileStartDate(event, inst) {
                                // this.isFirstLoad = false;
                                // const startDate = this.getFormattedDate(new Date(this.startTime));
                                // const startTime = this.getFormattedTime(new Date(this.startTime));

                                // const endDate = this.getFormattedDate(new Date(this.endTime));
                                // const endTime = this.getFormattedTime(new Date(this.endTime));
                                // const lengthOfStay = SearchPageScript.getLengthOfStay(startDate, startTime, endDate, endTime);
                                // if(lengthOfStay <= 0) {
                                //            this.updateMobileEndTime();
                                //            this.getMobileEndDate('', '');
                                // } if(lengthOfStay  < 2) {
                                //            this.searchCriteria.length_of_stay = 0;
                                //            this.toasterObj.error('Minimum booking should be 2 hours', 'error');
                                // }
                }

                getMobileEndDate(event, inst) {
                                // this.isFirstLoad = false;
                                // const startDate = this.getFormattedDate(new Date(this.startTime));
                                // const startTime = this.getFormattedTime(new Date(this.startTime));

                                // const endDate = this.getFormattedDate(new Date(this.endTime));
                                // const endTime = this.getFormattedTime(new Date(this.endTime));

                                // const lengthOfStay = SearchPageScript.getLengthOfStay(startDate, startTime, endDate, endTime);
                                // if(lengthOfStay > 2 ) {
                                //            $('#enddate').val(endDate);
                                //            $('#startdate').val(startDate);
                                //            $('#starttime').val(startTime);
                                //            $('#pendtime').val(endTime);
                                //            this.searchCriteria.length_of_stay = lengthOfStay;
                                //            //this.updateMobileFilters.emit(this.searchCriteria);
                                // } else if(lengthOfStay < 2) {
                                //            this.searchCriteria.length_of_stay = 0;
                                //            this.toasterObj.error('Minimum booking should be 2 hours', 'error');
                                // }else {
                                //            // this.toasterObj.error('End time should be greater than start time', 'error');
                                // }

                }

                receiveNewMobileNavDailyFilters(event) {
                                this.searchCriteria.currdate = event.currdate;
                                this.searchCriteria.length_of_stay = event.length_of_stay;
                                this.startDuration = new Date(event.startTime);
                                this.endDuration = new Date(event.endTime);
                                this.updateFilters.emit(this.searchCriteria);

                                if (this.mobileSearchFilter) {
                                                this.mobileSearchFilter.updateStartTimeManually(this.startDuration);
                                                this.mobileSearchFilter.updateEndTimeManually(this.endDuration);
                                }
                }


                receiveNewMobileDailyFilters(event) {
                                this.searchCriteria.currdate = event.currdate;
                                this.searchCriteria.length_of_stay = event.length_of_stay;
                                this.searchCriteria.mobStarttime = event.startTime
                                this.searchCriteria.mobEndtime = event.endTime
                                this.startDuration = new Date(event.startTime);
                                this.endDuration = new Date(event.endTime);
                                this.updateFilters.emit(this.searchCriteria);
                }

                receiveNewMobileMonthlyFilters(monthlyDate) {
                                this.monthlyDate = new Date(monthlyDate);
                                this.searchCriteria.currdate = FilterHelper.generateArrivalTime(this.monthlyDate);
                                this.updateFilters.emit(this.searchCriteria);
                }

                getStartTime() {
                                const mnts = this.startTime.getMinutes() < 10 ? '0' + this.startTime.getMinutes() : this.startTime.getMinutes();
                                const sTime = (this.startTime.getHours() > 12) ? (this.startTime.getHours() - 12 + ':' + mnts + ' PM') : (this.startTime.getHours() + ':' + mnts + ' AM');
                                $('#stime').val(sTime);
                                $('#pstime').val(sTime);
                                const sDate = $('#startdate').val();
                                const eDate = $('#enddate').val();
                                const facType = $('label.active').attr('id').toLowerCase().trim();
                                this.stime = sTime;
                                if (facType == 'daily') {
                                                const lengthOfStay = SearchPageScript.getLengthOfStay(sDate, this.stime, eDate, this.etime);
                                                if (lengthOfStay > 0) {
                                                                const reservationTime = sDate.toString().split('/');
                                                                const m = reservationTime[1];
                                                                const d = reservationTime[0];
                                                                const y = reservationTime[2];
                                                                const rTime = y + '-' + d + '-' + m + '' + this.changeToFullTime(this.stime);
                                                }
                                }
                }

                garageInfo() {
                }

                mobilePayment() {
                }

                sendApp() {
                                const appText = parseInt((<HTMLInputElement>document.getElementById('sendapp')).value.trim());
                                if (appText) {
                                                const phoneNum = ((<HTMLInputElement>document.getElementById('sendapp'))).value;
                                                const userLat = ((<HTMLInputElement>document.getElementById('navigatetogoogle'))).getAttribute('href');
                                                const text = 'Welcome to icon parking. click on the link below ' + userLat;
                                                this.homePageServiceObj.messageToMobile(phoneNum, text)
                                                                .subscribe(
                                                                                data => {
                                                                                                this.responseData = data;
                                                                                                this.toasterObj.success('Facility Location has been sent Successfully', 'success!');
                                                                                                $('button.close').trigger('click');
                                                                                },
                                                                                error => {
                                                                                                const errorsList = error.errors;
                                                                                                if (errorsList.message !== 'Validation Errors') {
                                                                                                                this.toasterObj.error(errorsList.message, 'Sorry!');
                                                                                                } else {
                                                                                                                for (const key in errorsList.detail) {
                                                                                                                                if (errorsList.detail.hasOwnProperty(key)) {
                                                                                                                                                if (typeof errorsList.detail === 'undefined') {
                                                                                                                                                                this.toasterObj.error(errorsList.message, 'Sorry!');
                                                                                                                                                } else {
                                                                                                                                                                this.toasterObj.error(errorsList.detail[key], 'Sorry!');
                                                                                                                                                }
                                                                                                                                }
                                                                                                                }
                                                                                                }
                                                                                }
                                                                );
                                } else {
                                                this.toasterObj.error('Number is required', 'Sorry!');
                                }
                                (<HTMLInputElement>document.getElementById('sendapp')).value = '';
                }

                changeToFullTime(time) {
                                const date = new Date();
                                let hours;
                                let minutes;
                                let am_pm;
                                const newTime = time.split(':');
                                hours = newTime[0];
                                const newT = newTime[1].split(' ');
                                minutes = newT[0];
                                am_pm = newT[1];
                                if (am_pm == 'PM') {
                                                const convertToFullDate = ['12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
                                                hours = convertToFullDate[hours];
                                }
                                return hours + ':' + minutes + ':00';
                }

                getEndTime() {
                                const sDate = $('#startdate').val();
                                const eDate = $('#enddate').val();
                                const mnts = this.endTime.getMinutes() < 10 ? '0' + this.endTime.getMinutes() : this.endTime.getMinutes();
                                const tTime = (this.endTime.getHours() > 12) ? (this.endTime.getHours() - 12 + ':' + mnts + ' PM') : (this.endTime.getHours() + ':' + mnts + ' AM');
                                $('#etime').val(tTime);
                                $('#petime').val(tTime);
                                const facType = $('label.active').attr('id').toLowerCase().trim();
                                this.etime = tTime;
                                if (this.restrictRequestOnInit != 'stop') {
                                                if (facType == CustomCode.defaultFacilityType) {
                                                                const lengthOfStay = SearchPageScript.getLengthOfStay(sDate, this.stime, eDate, this.etime);
                                                                if (lengthOfStay > 0) {
                                                                                const reservationTime = sDate.toString().split('/');
                                                                                const m = reservationTime[1];
                                                                                const d = reservationTime[0];
                                                                                const y = reservationTime[2];
                                                                                const rTime = y + '-' + d + '-' + m + '' + this.changeToFullTime(this.stime);

                                                                } else {

                                                                }
                                                }
                                }
                }
                load() {
                                this.loadMap(this.searchCriteria)
                }
                loadMap(searchCriteria) {
                  console.log('load Map')
                  if(localStorage.getItem('sortBy')=='Neighborhood'){
                    this.showLoader = false;
                  }else {
                    this.showLoader = true;
                  }
                  
					this.facilityData1 = [];
					this.counterFac = 0;
					
					localStorage.removeItem('mapDrag');
                                this.markersArray=[]
                                for (let i = 0; i < this.markers.length; i++) {
                                                this.markers[i].setMap(null);
								}
								if(this.router.url.indexOf('monthly-parking')>-1){
									this.sidebarOn1=true;
								}else {
									this.sidebarOn1=true;
								}
                               
                                if (typeof google !== 'undefined') {
                                                this.searchCriteria = searchCriteria;
                                                this.searchType = this.searchCriteria.type;
                                                localStorage.setItem('latbeforedrag',this.searchCriteria.lat)
                                                localStorage.setItem('longbeforedrag',this.searchCriteria.long)
                                                localStorage.setItem('locationbeforedrag',this.searchCriteria.location)
                                                const googleInit = CustomCode.getGoogleInitData(this.searchCriteria.lat, this.searchCriteria.long);
                                                this.nMap = new google.maps.Map(document.getElementById('map_canvas'), googleInit);
                                                                
                                                this.centerMarker = new google.maps.Marker({
                                                                position: new google.maps.LatLng(this.searchCriteria.lat, this.searchCriteria.long),
                                                                map: this.nMap,
                                                                /* icon: CustomCode.googleMapDefaultIcon, */
                                                                
                                                });

                                                google.maps.event.addListener(this.nMap, 'dragstart', () => {
                                                                this.removeActiveMarker();
                                                                this.closeActiveGarageOverlay();

                                                                // if(this.router.url.indexOf('partners') > -1 && ($(window).width() > 768)){
                                                                //            this.nMap.setOptions({draggable: false});
                                                                //            }else{}
                                                });

                                                google.maps.event.addListener(this.nMap, 'zoom_changed', () => {
                                                                // if(this.router.url.indexOf('partners') > -1 && ($(window).width() > 768)){
                                                                //            this.nMap.setOptions({draggable: false});
                                                                //            }else{}
                                                                //this.displayActiveGarageOverlay();
                                                });

                                                // google.maps.event.addListener(this.nMap, 'dragend', () => {
                                                //            // if(this.router.url.indexOf('partners') > -1 && ($(window).width() > 768)){
                                                //            //            this.nMap.setOptions({draggable: false});
                                                //            //            }else{}
                                                //            $('.selectAuto').val('');
                                                //            this.centerMarker.setMap(null);
                                                //            this.getMapRadius();
                                                //            this.centerMarker = new google.maps.Marker({
                                                //                            position: new google.maps.LatLng(this.searchCriteria.lat, this.searchCriteria.long),
                                                //                            map: this.nMap,
                                                //                            /* icon: CustomCode.googleMapDefaultIcon, */
                                                //            });
                                                //            this.EarlyBrid = false
                                                //            this.updateIsActiveFacility();

                                                // });

                                                google.maps.event.addListener(this.nMap, 'dragend', () => {
                                                                if(this.router.url.indexOf('partners') > -1 && ($(window).width() > 768)){
                                                                                }else{    
																					localStorage.setItem('mapDrag', '1');
																					localStorage.setItem('mapDrag1', '1');
                                                                                                //$('.selectAuto').val('')
                                                                                                
                                                                                                this.centerMarker.setMap(null);
                                                                                                this.getMapRadius();
                                                                                                //localStorage.setItem('locationNew','new-york')
                                                                                                this.centerMarker = new google.maps.Marker({
                                                                                                                position: new google.maps.LatLng(this.searchCriteria.lat, this.searchCriteria.long),
                                                                                                                map: this.nMap
                                                                                                });
                                                                                                this.EarlyBrid = false
                                                                                                this.updateIsActiveFacility();
                                                                                }
                                                });
                                                // google.maps.event.addListener(this.nMap, 'idle', () => {


                                                // });
                                                this.nMap.setMapTypeId(this.mapType);
                                                // if(this.searchCriteria.type=='monthly' ){
                                                //            this.searchCriteriaCampaign.latitude = this.searchCriteria.lat
                                                //            this.searchCriteriaCampaign.longitude = this.searchCriteria.long
                                                //            this.searchCriteriaCampaign.arrival_time = this.searchCriteria.currdate
                                                //            this.fetchMonthlyCampaign();
                                                // }

                                } else {
                                                window.location.reload();
                                }

                                if(localStorage.getItem('showNeigborMarker2')){
                                  this.showMapMarkers();
                                }
                }

                tempFunction(searchResult) {

                }
                onRedirect(id, price, monthly_rate) {
                                this.moveToReservation(id, price, monthly_rate,0,'');
                }
                openDetailsBind(facdata) {

                                this.openDetails(facdata, this.searchCriteria.type)
                }
                
                sortBy(userVal)
                {
                                
                                if(localStorage.getItem('resetSearch')){
                                                if(userVal!='price' && userVal!='Neighborhood')
                                                {
                                                                userVal = 'distance';
                                                }
                                                
                                                localStorage.removeItem('resetSearch');
                                }
                                
                                $('.filterDropdownMenu').removeClass('open');
                                $('.filterDropdownMenu').removeClass('show');
                                this.selectedSortType = userVal
                                
                                localStorage.setItem('sortBy', userVal);
                                this.sortType=userVal
                                if( userVal === 'Neighborhood' ){
                                                
                                                this.activeNeighborhoodFilter = true
                                                this.parkList = false
                                                if(this.partnerPageOn){
                                                                localStorage.setItem('fromPartner','1');
                                                }
                                
                                                this.fetchNeighbourhoodsData();
                                                localStorage.setItem('showNeighbordood','1');
                                                if(localStorage.getItem('activeNeighbourhood')){
                                                                this.activeNeighbourhood = localStorage.getItem('activeNeighbourhood');
                                                }else {
                                                                this.activeNeighbourhood = 'central-park';
                                                }
                                                
                                                if(localStorage.getItem('showNeigborMarker') ){
                                                                if($(window).width() < 768 ){
                                                                                // this.activeNeighborhoodFilter = false;
                                                                                sessionStorage.removeItem('tabview');
                                                                }else {
                                                                                localStorage.removeItem('showNeigborMarker');
                                                                }
                                                                
                                                                
                                                }
                                }else {
                                                
                                                this.hideListView = false;
                                                this.parkList = true;
                                                
                                                this.activeNeighborhoodFilter = false;
                                                localStorage.removeItem('showNeighbordood');
                                                localStorage.removeItem('neighbourhoodActive');
                                                localStorage.removeItem('neighbourhoodActiveDropDOwn');
                                                // setTimeout(() => {
                                                //            this.parkDetail = false;
                                                //            if (sessionStorage.getItem('tabview') && localStorage.getItem('showNeighbordood')) {
                                                //                            this.tabList('plist', 'pview')
                                                //            }
                                                // }, 500);
                                }
                                this.sortTypes=userVal+'monthly'                             
                }
                // sortBy(userVal) {
                //            if(userVal=='distance')
                //            {
                //                                            if(this.noFacility==0)
                //                                            {
                //                                                            if(this.searchCriteria.type=='daily')
                //                                                            {
                //                                                                            this.distancesorting=0;
                //                                                                            this.distancelatestsort=1;
                //                                                                            this.distancesortingmonthly=0;
                //                                                            }else{
                //                                                                            this.distancesortingmonthly=1;
                //                                                            }
                //                                                            this.distancesortingloader=1;
                //                                                            if(this.pricesortingdone==0)
                //                                                            {
                //                                                                            this.distancesortingloader=0;
                //                                                            }
                //                                                            this.pricesorting=0;
                //                                            }
                                                                
                //            }else{
                                                
                //                            if(this.noFacility==0)
                //                            {
                //                                            if(this.searchCriteria.type=='monthly')
                //                            {
                //                                            this.distancesorting=0;
                //                                            this.distancesortingmonthly=0;
                //                            }else{
                //                                            this.distancesorting=0;
                //                            }
                //                            this.distancesortingloader=1;
                //                            if(this.pricesortingdone==0)
                //                            {
                //                                            this.distancesortingloader=0;
                //                            }
                //                            this.pricesorting=1;
                //                            this.distancelatestsort=0;
                //                            }
                                                
                //            }
                //            this.sidebarHeightUpdate();                        

                // }

                validateFilterButton() {
                                let url = window.location.href;
                                if (url.indexOf('search') > -1 || url.indexOf('partners') > -1 ) {
                                                localStorage.removeItem('neighTypeformob')
                                }
                                if (url.indexOf('daily') > -1 || url.indexOf('partners') > -1 ) {
                                                this.searchCriteria.type='daily'
                                }else{
                                                this.searchCriteria.type='monthly'
                                }
                                if (url.indexOf('search') > 0) {
                                                let partials = url.split("search")[1].split("/")[6];
                                                
                                                $('div.searchFilter div.filterBtn:first').find('label').removeClass('active');
                                                $('div.searchFilter div.filterBtn:first').find('label#' + partials).addClass('active');
                                                $('div#dailyFilter').addClass('hidden');
                                                $('div#monthlyFilter').addClass('hidden');
                                                $('div#' + partials + 'Filter').removeClass('hidden');
                                }
                }

                loadMarkers(searchResult) {
                                
                                if (this.router.url.indexOf('search') > -1) {
                                                localStorage.removeItem('neighTypeformob')
                                }

                                this.showlisttab=1;
                                // if(this.searchCriteria.type=='monthly'){
                                //            this.fetchMonthlyCampaign();
                                // }
                                if(this.searchCriteria.type=='monthly')
                                                {
                                                                this.distancesortingmonthly=1;
                                                }
                                //sessionStorage.removeItem('tabview');
                                this.allInventoryData=[]
                                this.facilitylistprice=[];
                                this.checkInventor=0;
                                this.pricesortingdone=1;
                                //this.back(2);
                                this.EarlyBrid = false;
                                this.distancesorting=1;
                                this.isUpdatedEndTimeFlag = false;
                                this.distancelatestsort=0;
                                this.pricesorting=0;
                                if (sessionStorage.getItem('searchTypeByMobile')) {
                                                this.searchCriteria.type = sessionStorage.getItem('searchTypeByMobile')
                                }
                                this.sidebarHeightUpdate();
                                this.mainShow=1;
                                //this.sidebarOn1=true;
                               // console.log('here 111');
                                if( localStorage.getItem('sortBy')){
                                                //alert(5)
                                                //console.log('aa')
                                                if(localStorage.getItem('sortBy') === 'Neighborhood' ){
                                                                this.parkList = false;
                                                                this.hideListView = true;
                                                               // console.log('bb')
                                                }else {
                                                                $("#parkList").removeClass('hidden');
                                                                setTimeout(()=>{ 
                                                                                this.updateSortBYFilter(localStorage.getItem('sortBy'));
                                                                                $("#parkList").removeClass('hidden');
                                                                }, 1000);
                                                                                this.parkList = true;
                                                                                this.hideListView = false;
                                                                
                                                            //    console.log('cc')
                                                }              
                                }else {
                                                setTimeout(() => { this.parkDetail = false; this.tabList('plist', 'pview') }, 500);
                                }
                                //$("#parkList").removeClass('hidden');
                                
                                //            this.parkList = true;
                                //            this.hideListView = false;
                                
                                //$('.distanceSort').trigger('click');
                                
                                // this.parkDetail = false;
                                // this.parkList = false;
                                if (typeof google !== 'undefined') {
                                                this.validateFilterButton();



                                                this.dStartTime = FilterHelper.displayableTimeformat($('#startTime').val());
                                                this.dEndTime = FilterHelper.displayableTimeformat($('#endTime').val());


                                                this.searchResult = searchResult;
                                                const newDataLength = this.searchResult.facilities.length;
												this.facilityData = this.searchResult;
                        let fdataLength = this.facilityData1
												for(  let z = 0 ; z < this.searchResult.facilities.length; z++){
													this.facilityData1[this.counterFac++] = this.searchResult.facilities[z];
												}
												//this.facilityData1.push(this.searchResult.facilities)
											//	console.log(this.facilityData1);
                                                //this.sidebarOn1 = true;
                                                //this.isLoadedForfacility=true;
                                                this.facilityDataMonthly = JSON.parse(JSON.stringify(this.searchResult));//this.searchResult;
                                                this.facilityDataSort = JSON.parse(JSON.stringify(this.searchResult));
												//console.log(this.markers.length);
                                                for (let i = 0; i < this.markers.length; i++) {
													if(!localStorage.getItem('mapDrag')){
														this.markers[i].setMap(null);
													//	console.log(i + 'hereee1111111111111')
													}
													
													
                                                }
           // console.log(this.markersArray);
                                                if(this.isNeighbourHood){
                                                                this.searchCriteria.type = $('.dailymonthlybuttons.active').attr('id');
                                                }
                                                
                                                if(this.searchCriteria.type=="monthly")
                                                {
                                                                $('.distancesort').removeClass("disabledBtn");
                                                }else{
                                                                if(this.searchResult.facilities.length > 0){
                                                                                //this.updateInventoryOnSidebar(this.searchResult.facilities);
                                                                }
                                                }
                                                
                                                // this.markers = [];
                                                this.priceArray=[]
                                                for (let i = 0; i < newDataLength; i++) {
                                                                if (this.activeFacilityIndex != null) {
                                                                                if (this.allParkings.facility_id == this.searchResult.facilities[i].facility_id) {
                                                                                                this.allParkings = this.searchResult.facilities[i];
                                                                                                
                                                                                }
                                                                }
                                                                
                                                                const facilityId = this.searchResult.facilities[i].facility_id;
                                                                
                                                                const latCurrent = this.searchResult.facilities[i].geolocation.latitude;
                                                                const longCurrent = this.searchResult.facilities[i].geolocation.longitude;
                                                                let typeDailymonthly=1;
                                                                let monthlytaxFlag=this.searchResult.facilities[i].monthlytaxFlag;
                                                                // let is_campaign=this.searchResult.facilities[i].monthly_campaign;
                                                                let is_campaigns=this.campaignArray[i]=this.searchResult.facilities[i].monthly_campaign;
                                                                let currPrice = parseFloat(this.searchResult.facilities[i].monthly_rate_main);

                                                                if(localStorage.getItem('neighTypeformob') && ($(window).width() < 768) ){
                                                                                this.searchCriteria.type = localStorage.getItem('neighTypeformob')
                                                                }              

                                                                if (this.searchCriteria.type === CustomCode.defaultFacilityType) {
                                                                                currPrice = this.searchResult.facilities[i].price;
                                                                                typeDailymonthly=1;
                                                                }else{
                                                                                typeDailymonthly=2;
                                                                }
                                                                const finalPrice = (currPrice > 0) ? '$' + currPrice : 'N/A';
                                                                this.priceArray[i]=finalPrice;
                                                                let defaultSize = 0;
                                                                let labelX = 27.5;
                                                                let fontSize='15px'
                                                                let className='';
                                                                let position1=19;
                                                                let position2=45
                                                                let iconImage:any;
                                                                if (finalPrice.length <= 3) {
                                                                                fontSize = '15px';
                                                                                defaultSize=55;
                                                                }else if (finalPrice.length > 3) {
                                                                                fontSize = '13px';
                                                                                defaultSize=55;
                                                                }
																 if( localStorage.getItem('mapDrag') && facilityId in this.markersArray )
																//if( 0 )
                                                                {
                                                                              //  console.log(facilityId);
                                                                }else{

                                                                
                                                                if(typeDailymonthly==2)
                                                                {
                                                                                defaultSize=75;
                                                                                className='markerdaily'
                                                                                position1=19;
                                                                                position2=50;
                                                                                if(finalPrice=='N/A')
                                                                                {
                                                                                                iconImage=CustomCode.markerIconConfigurationSvg(CustomCode.googleMapDefaultIcon, defaultSize, labelX,'N/A',1,'#FFFFFF',monthlytaxFlag,is_campaigns)
                                                                                }else{
                                                                                                let splitprize= finalPrice.split(".");
                                                                                                if(splitprize[1]==undefined)
                                                                                                {
                                                                                                                splitprize[1]='00'
                                                                                                }
                                                                                                if(splitprize[1].length==1)
                                                                                                {
                                                                                                                splitprize[1]=splitprize[1]+"0";
                                                                                                }
                                                                                    iconImage=CustomCode.markerIconConfigurationSvg(CustomCode.googleMapDefaultIcon, defaultSize, labelX,splitprize[0],splitprize[1],'#FFFFFF',monthlytaxFlag,is_campaigns)
                                                                                }
                                                                                
                                                                                var homeLatLng = new google.maps.LatLng(latCurrent, longCurrent);
                                                                                var marker = new MarkerWithLabel({
                                                                                position: homeLatLng,
                                                                                map: this.nMap,
                                                                                icon: iconImage,
                                                                                labelContent: '',
                                                                                labelClass: className, // the CSS class for the label
                                                                                labelAnchor: new google.maps.Point(position1, position2),
                                                                  });
                                                                }else{
                                                                                
                                                                                var marker = new google.maps.Marker({
                                                                                position: new google.maps.LatLng(latCurrent, longCurrent),
                                                                                map: this.nMap,
                                                                                icon: CustomCode.markerIconConfiguration(CustomCode.googleMapDefaultIcon, defaultSize, labelX),
                                                                                label: {
                                                                                                                text: finalPrice,
                                                                                                                fontWeight: 'normal',
                                                                                                                fontFamily:'Source Sans Pro',
                                                                                                                fontSize: fontSize,
                                                                                                                color: 'white'
                                                                                                }
                                                                                                
                                                                                });
                                                                }
                                                                
                                                                this.activeMarker = new google.maps.Marker({
                                                                                position: new google.maps.LatLng(12344, 234555),
                                                                                map: this.nMap,
                                                                                icon: CustomCode.markerIconConfiguration(CustomCode.googleMapActiveIcon, 75, ''),
                                                                                zIndex: 1000
																});
															//	console.log(facilityId + 'marker value')
                                                                marker.setValues({ id: facilityId, indexId: i, type: this.searchCriteria.type });
                                                                ((marker, l) => {
                                                                                google.maps.event.addListener(marker, 'click', () => {
                                                                                                this.couponShow = 0;
																								this.bookShow = 0;
                                                                                                this.showOversizedMsg = false;
																								this.is_monthly_campaign = false;
																								this.sidebarOn1=true;
																								this.checkupdatedPriceForPartner = false;
																								// if(localStorage.getItem('showNeighbordood')){
																									
																								// }
																								//this.back(2);

																								
                                                                                                // Drop active marker on map pin click and display overlay
                                                                                                localStorage.removeItem('showDeals');
                                                                                                $('.campians_listNeighbourhood').removeClass('show').addClass('d-none');
                                                                                                this.removeActiveMarker();
                                                                                                this.dropActiveMarker(marker.getPosition().lat(), marker.getPosition().lng());
                                                                                                
                                                                                                this.displayActiveGarageOverlay();
                                                                                                // this.fetchFacilityDetails(facilityId);//this.facilityData.facilities[index].facility_id);
                                                                                                this.getCouponDisabled = false;
                                                                                                this.activeLoading = true;
                                                                                                this.disableMarker = true;
                                                                                                this.checkupdatedPrice = false;
                                                                                                this.availabilityCheck = false;
                                                                                                this.enableDefaultMsg = true;
                                                                                                if( !(localStorage.getItem('is_partner_page') ) ){
                                                                                                                $(".gm-style [src$='.svg']").parent().addClass("disabledmarker");
                                                                                                }
                                                                                                sessionStorage.removeItem('selectedvahicletype');
                                                                                                sessionStorage.removeItem('tabview');
                                                                                                let is_campaign=0
                                                //                                                 for (let mi = 0; mi < this.markers.length; mi++) {
                                                                                                                
                                                //                                                                 is_campaign=this.campaignArray[mi]
                                                //                                                                 const lObj = this.markers[mi].label;
                                                //                                                                 lObj.color = 'white';
                                                //                                                                 if(typeDailymonthly==2)
                                                //                                                                 {
																													
                                                //                                                                                 if(this.priceArray[mi]=='N/A' || this.priceArray[mi]==undefined)
                                                //                                                                                 {
                                                //                                                                                                 this.markers[mi].setIcon(CustomCode.markerIconConfigurationSvg(CustomCode.googleMapDefaultIcon, defaultSize, labelX,'N/A',1,'#FFFFFF',monthlytaxFlag,is_campaign));
                                                //                                                                                 }else{
																								// 								//	console.log(this.priceArray[mi]);
                                                //                                                                                                 let splitprizeActive= this.priceArray[mi].split(".");
                                                //                                                                                                 if(splitprizeActive[1]==undefined)
                                                //                                                                                                 {
                                                //                                                                                                                 splitprizeActive[1]='00'
                                                //                                                                                                 }
                                                //                                                                                                 if(splitprizeActive[1].length==1)
                                                //                                                                                                 {
                                                //                                                                                                                 splitprizeActive[1]=splitprizeActive[1]+"0";
                                                //                                                                                                 }
                                                //                                                                                                 this.markers[mi].setIcon(CustomCode.markerIconConfigurationSvg(CustomCode.googleMapDefaultIcon, defaultSize, labelX,splitprizeActive[0],splitprizeActive[1],'#FFFFFF',monthlytaxFlag,is_campaign));
                                                //                                                                                 }
                                                //                                                                 }else{
                                                //                                                                                 this.markers[mi].setIcon(CustomCode.markerIconConfiguration(CustomCode.googleMapDefaultIcon, defaultSize, labelX));
                                                //                                                                 }              
                                                                                                                
                                                                                                                
																								// }
																							//	console.log(this.priceArray);
																							//	console.log(this.priceArray.length);
                                                                                                // if(typeDailymonthly==2)
                                                                                                //            {
                                                                                                                                
                                                                                                //                            if(finalPrice=='N/A')
                                                                                                //                            {
                                                                                                //                                            marker.setIcon(CustomCode.markerIconConfigurationSvg(CustomCode.googleMapDefaultIcon, defaultSize, labelX,'N/A',1,'#C5731B',monthlytaxFlag,is_campaign));
                                                                                                //                            }else{
                                                                                                //                                            let splitprizeActive= finalPrice.split(".");
                                                                                                //                                            if(splitprizeActive[1]==undefined)
                                                                                                //                                            {
                                                                                                //                                                            splitprizeActive[1]='00'
                                                                                                //                                            }
                                                                                                //                                            if(splitprizeActive[1].length==1)
                                                                                                //                                            {
                                                                                                //                                                            splitprizeActive[1]=splitprizeActive[1]+"0";
                                                                                                //                                            }
                                                                                                //                                            marker.setIcon(CustomCode.markerIconConfigurationSvg(CustomCode.googleMapDefaultIcon, defaultSize, labelX,splitprizeActive[0],splitprizeActive[1],'#C5731B',monthlytaxFlag,is_campaign));
                                                                                                //                            }
                                                                                                //            }
                                                                                                const labelObj = marker.label;
                                                                                                this.sidebarHeightUpdate();
                                                                                                this.sidebarOn = true;
                                                                                                this.EarlyBrid = true;
                                                                                                this.earlybirIcon = "fa fa-times";
                                                                                                this.allParkings = {};
																								let idOfMarker= marker.get('id')
																							//	console.log(idOfMarker + '------------');
                                                                                                for(let p=0; p<this.facilityData1.length; p++)
                                                                                                {
                                                                                                                if(this.facilityData1[p].facility_id==idOfMarker)
                                                                                                                {
                                                                                                                                this.allParkings = this.facilityData1[p];
                                                                                                                                break;
                                                                                                                }
																								}
																							//	console.log(this.facilityData.facilities)
                                                                                                // this.allParkings = this.facilityData.facilities[marker.get('indexId')];

																								this.isDraggable = false;
																								
                                                                                                this.loadActiveGrage(marker.get('id'), marker.get('indexId'), marker.get('type'));
                                                                                                $("#parkDetail").animate({ scrollTop: 0 }, 500);
                                                                                                localStorage.removeItem('searchllc');
                                                                                });

                                                                                /***** #Start load defualt marker details *****/
                                                                                let searchLat = this.searchCriteria.lat;
                                                                                let searchLong = this.searchCriteria.long;
                                                                                // if(searchLat == latCurrent && searchLong == longCurrent){
                                                                                                
                                                                                //            setTimeout(function(){
                                                                                //                            google.maps.event.trigger(marker, 'click');
                                                                                //            }, 5000);
                                                                                // }


                                                                                if(searchLat == latCurrent && searchLong == longCurrent){
                                                                                                if(!localStorage.getItem('filterApply'))
                                                                                                {
                                                                                                                setTimeout(function(){
                                                                                                                                google.maps.event.trigger(marker, 'click');
                                                                                                                }, 5000);
                                                                                                                
                                                                                                }
                                                                                                
                                                                                }
                                                                                /***** #End load defualt marker details *****/
                                                                                setTimeout(() => {
                                                                                                $('.distancesort').removeClass('active');
                                                                                                $('#distancesort').addClass('active');
                                                                                                },5000);
                                                                })(marker, i);
                                                                
                                                                this.markerLoaded = false;
                                                                this.isDraggable = false;
                                                                this.updateIsActiveFacility();
                                                                this.markers.push(marker);
                                                                this.markersArray[facilityId]=marker;
                                                }
                                                console.log('1111111111')
                                                setTimeout( ()=>{
                                                  this.showLoader = false;
                                                },500 )
                                                
												}
												
											//	console.log(this.priceArray);
                                                if (searchResult.facilities.length > 0) {
                                                                if (this.isActiveFacility) {
                                                                                this.loadActiveGrage(1, this.activeFacilityIndex, this.searchCriteria.type);
                                                                }
                                                } else {
                                                                this.sidebarOn = false;
                                                                if (this.searchCriteria.device == 'app') {
                                                                                this.mobileFilterNavDisplay = true;
                                                                              //  console.log(this.mobileSearchFilterNav);
                                                                                if(this.mobileSearchFilterNav !== undefined || this.mobileSearchFilterNav !== ''){
                                                                                                this.mobileSearchFilterNav.updateStartTimeManually(this.startDuration);
                                                                                                this.mobileSearchFilterNav.updateEndTimeManually(this.endDuration);
                                                                                                this.mobileSearchFilterNav.updateDurationManually();
                                                                                }
                                                                }
                                                }
                                                // let tempFacilityId = 102;

                                                // for (var i = this.markers.length - 1; i >= 0; i--) {
                                                //            if (this.markers[i].id == tempFacilityId) {
                                                //                            google.maps.event.trigger(this.markers[i], 'click');
                                                //            }
                                                // }
                                } else {
                                                window.location.reload();
                                }
                }

                updateFacilitySchedule(id) {
                                this.searchPageObj.getFacilitySchedule(id).then(
                                                (res) => {
                                                                this.garageWorkingHours = res;
                                                                this.garageWorkingHours = this.garageWorkingHours.data;
                                                },
                                                (error) => {

                                                }
                                );
                }

                fetchFacilityDetails(id) {
        //	alert(id);           
                                this.unformatedPhone = '';
                                let is_bonus_hour_applied = '';
                                if( !(localStorage.getItem('is_partner_page') ) ){
                                                this.fetchFacilityAvailableFetatures(id, this.searchCriteria.currdate, this.searchCriteria.length_of_stay, is_bonus_hour_applied);
                                }else{
                                                this.activeLoading = false;
                                                this.PartnerPagePostedRate(id, this.searchCriteria.currdate, this.searchCriteria.length_of_stay, is_bonus_hour_applied);
                                }
                                this.searchPageObj.getFacilityDetails(id).then(
                                                (res) => {
                                                                this.respdata = res;
                                                                this.respdata = this.respdata.data;
                                                                let is_bonus_hour_applied = '';

                                                                this.searchCriteria.currdate=$('#time_search').val()
                                                                this.searchCriteria.length_of_stay=$('#lenth_stay').val()

                                                                if(this.respdata.monthly_campaign ==='1') { this.is_monthly_campaign = true; }else {this.is_monthly_campaign = false; }
                                                                if( !(localStorage.getItem('is_partner_page') ) ){
                                                                                //this.fetchFacilityAvailableFetatures(this.respdata.id, this.searchCriteria.currdate, this.searchCriteria.length_of_stay, is_bonus_hour_applied);
                                                                }else{
                                                                                this.activeLoading = false;
                                                                                //this.PartnerPagePostedRate(this.respdata.id, this.searchCriteria.currdate, this.searchCriteria.length_of_stay, is_bonus_hour_applied);
                                                                }
                                                                //if(this.isMonthlyAction){
                                                                  
                                                                  let monthly_rate = this.respdata.monthly_rate;
                                                                  this.is_tax_include=this.respdata.monthlytaxFlag;
                                                                  this.unformatedPhone = this.respdata.unformatted_phone_number;
                                                                  for(let data of this.respdata.facilityMonthlyTaxRates ){
                                                                                  
                                                                                  if(data.net_rate == monthly_rate)
                                                                                  {
                                                                                                  this.selectedvahicletype =  data.rate_description.name;
                                                                                                  this.selectedvahicletypeText=data.rate_description.hours_description+" "+data.rate_description.description
                                                                                                  this.selectedvahicletypeText1 = data.rate_description.hours_description
                                                                                                  this.selectedvahicletypeText2 = data.rate_description.description
                                                                                                  sessionStorage.setItem('selectedvahicletype', this.selectedvahicletype);
                                                                                                  this.activeBuyOnline = true;
                                                                                  break;
                                                                                  }else{
                                                                                                this.activeBuyOnline = false;
                                                                                  }
                                                                  }
                                                  //}
                                                                if(this.respdata.facility_type_id==1){
                                                                                $('.flyimg').html("<img src='assets/img/icon-park-gray.svg' alt='icon'>");
                                                                } else if (this.respdata.facility_type_id == 2)
                                                                                $('.flyimg').html("<img src='assets/img/quikpark.svg' alt='icon'>");
                                                },
                                                (error) => {

                                                }
                                );
                }

                                loadActiveGrage_111(id, index, type) {
                                                if(this.isDraggable && this.markerLoaded){
                                                                this.activeFacilityIndex = null;
                                                                this.updateIsActiveFacility();
                                                                return true;
                                                                
                                                }
                                                
                                                $('body').addClass('noscroll');
                                                this.isActiveFacility = true;
                                                this.activeFacilityIndex = index;
                                                
                                                
                                                this.parkDetail = true;
                                                this.parkList = false;
                                                let facilityRate;
                                                let dailymonthlytext;
                                                let monthdailybuttontext;
                                                let couponDisplay;
                                                let updateTime;
                                                // this.mobileFilterType = 'inline';
                                                

                                if (type === CustomCode.monthlyConstant) {
                                                couponDisplay = 'none';
                                                this.isMonthlyAction = true;
                                                updateTime = 'none';
                                                facilityRate = this.searchResult.facilities[index].monthly_rate_main;
                                                dailymonthlytext = '';
                                                monthdailybuttontext = 'buy monthly';
                                                this.isPerMonth = true;
                                } else {
                                                couponDisplay = 'block';
                                                this.isMonthlyAction = false;
                                                updateTime = 'inline-block';
                                                facilityRate = this.searchResult.facilities[index].price;
                                                dailymonthlytext = $('#stime').val() + ' - ' + $('#etime').val();
                                                monthdailybuttontext = 'Book Now';
                                                this.isPerMonth = false;

                                }

                                let mDB = 'none';
                                if (facilityRate > 0) {
                                                mDB = 'block';
                                }
                                this.bookingRate = (facilityRate > 0) ? facilityRate : 'N/A';
                
                                if (this.allParkings.photo_url) {
                                                this.imgSrcLocation = this.allParkings.photo_url;
                                                this.imgSrcAlt = this.allParkings.entrance_location;

                                } else {
                                                this.imgSrcLocation = '/assets/img/park_garage.png';
                                                this.imgSrcAlt = 'parking-name';
                                }

                                // if(this.allParkings.rate_type_id == 2 || this.allParkings.rate_type_id == 4) {
                                $('.spsutoava').html('* Space Subject to Availability');
                                // } else {
                                // $('.spsutoava').html("");
                                // }
                                if (this.facilityData.facilities.length > 0) {
												this.updateFacilitySchedule(this.facilityData.facilities[index].facility_id);
											//	alert(this.facilityData.facilities[index].facility_id + "-++1")
                                                this.fetchFacilityDetails(this.facilityData.facilities[index].facility_id);
                                }
                                $('#update-daily-time').css('display', updateTime);
                                $('#dailymonthlybutton').attr('facId', this.searchResult.facilities[index].facility_id);
                                $('#dailymonthlybutton').attr('rate', facilityRate);
                                $('#dailymonthlybutton').attr('buyType', type);
                                $('#dailymonthlybutton').text(monthdailybuttontext);
                                $('#dailymonthlybutton').css('display', mDB);
                                $('#checkcoupon').css('display', mDB);

                                const facRate = (facilityRate > 0) ? '$' + facilityRate : 'N/A';
                                if(type=='monthly')
                                {
                                                let user_id_ai='Unknown';
                                                if(localStorage.getItem('userid'))
                                                {
                                                                user_id_ai=localStorage.getItem('userid');
                                                }else{
                                                                user_id_ai='Unknown';
                                                }
                                                (<any>window).dataLayer.push(
                                                                {
                                                                'event': 'FacilityClick',
                                                                                'UserId' :user_id_ai,
                                                                                'Facility': [{
                                                                                  'name': this.allParkings.entrance_location,                      // Name or ID is required.
                                                                                  'id': this.searchResult.facilities[index].facility_id,
                                                                                  'price': facilityRate,
                                                                                  'category': 'Monthly',
                                                                                }]
                                                })
                                }
                                
                                // $('#monthdaily').text(dailymonthlytext);
                                $('#checkcoupon').css('display', couponDisplay);
                                $('#couponcontent').css('display', couponDisplay);

                                $('#mcheckcoupon').css('display', couponDisplay);
                                $('#mcouponcontent').css('display', couponDisplay);

                                $('#checkcoupon').attr('data-facid', this.searchResult.facilities[index].facility_id);
                                $('#dailymonthlybutton').attr('data-facid', this.searchResult.facilities[index].facility_id);
                                this.baserate = facRate;
								
                                $('#parkfull_name').text(this.allParkings.geolocation.address_1);
                                $('#geolocationAddress').text(this.allParkings.geolocation.address_1);
                                $('#entranceData').text('Entrance : ' + this.allParkings.entrance_location);

                                let increment = 0;
                                for (var drpdwn of this.allParkings.monthly_rates) {
                                                //this.isShowDropDown=1\
                                                if (drpdwn.rate != '0.00' && drpdwn.active == '1') {
                                                                increment++;
                                                }

                                }
                                this.isShowDropDown = increment;
                                this.vehicletype = this.allParkings.monthly_rates;
                                // let miles = this.allParkings.distance;
                                // miles = Math.round(miles*Math.pow(10,2))/Math.pow(10,2);
                                // $('#locDistance').html('&nbsp;' + miles + ' Miles');
                                $('#garage_phone').text( this.formatPhoneNumber(this.allParkings.phone_number));
                                $('#callData').text('Call ' + this.allParkings.phone_number);

                                const uLat = this.searchResult.facilities[index].geolocation.latitude;
                                const uLong = this.searchResult.facilities[index].geolocation.longitude;

                                if (this.searchCriteria.device == 'app') {
                                                this.initiliazePanormaMobileView(uLat, uLong);
                                }

                                $('[name="plist"] > input#parking').click();
                                $('[name="pview"] > input#parking').click();

                }

                loadActiveGrage(id, index, type) {
					//alert(4)
			//		console.log(this.allParkings);
                                if(this.isDraggable && this.markerLoaded){
                                                this.activeFacilityIndex = null;
                                                this.updateIsActiveFacility();
                                                return true;
                                                
                                }
                                
                               // $('body').addClass('noscroll');
                                if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/i)) {
                                  $('body').removeClass('noscroll');
                                  $('body').removeClass('noserch-scroll');
                                } else {  
                                  $('body').addClass('noserch-scroll');
                                  $('body').addClass('noscroll');
                                }
                                this.isActiveFacility = true;
                                this.activeFacilityIndex = index;
                                
                                
                                this.parkDetail = true;
                                this.parkList = false;
                                let facilityRate;
                                let dailymonthlytext;
                                let monthdailybuttontext;
                                let couponDisplay;
                                let updateTime;
                                // this.mobileFilterType = 'inline';
                                

                if (type === CustomCode.monthlyConstant) {
                                couponDisplay = 'none';
                                this.isMonthlyAction = true;
                                updateTime = 'none';
                                facilityRate = this.allParkings.monthly_rate_main//this.searchResult.facilities[index].monthly_rate_main;// yha pr change kiya
                                dailymonthlytext = '';
                                monthdailybuttontext = 'buy monthly';
                                this.isPerMonth = true;
                } else {
                                couponDisplay = 'block';
                                this.isMonthlyAction = false;
                                updateTime = 'inline-block';
                                facilityRate = this.allParkings.price//this.searchResult.facilities[index].price;// yha pr change kiya
                                dailymonthlytext = $('#stime').val() + ' - ' + $('#etime').val();
                                monthdailybuttontext = 'Book Now';
                                this.isPerMonth = false;

                }

                let mDB = 'none';
                if (facilityRate > 0) {
                                mDB = 'block';
                }
                this.bookingRate = (facilityRate > 0) ? facilityRate : 'N/A';

                if (this.allParkings.photo_url) {
                                this.imgSrcLocation = this.allParkings.photo_url;
                                this.imgSrcAlt = this.allParkings.entrance_location;

                } else {
                                this.imgSrcLocation = '/assets/img/park_garage.png';
                                this.imgSrcAlt = 'parking-name';
                }

                // if(this.allParkings.rate_type_id == 2 || this.allParkings.rate_type_id == 4) {
                $('.spsutoava').html('* Space Subject to Availability');
                // } else {
                // $('.spsutoava').html("");
                // }
                if (this.facilityData.facilities.length > 0) {
                                //this.updateFacilitySchedule(this.facilityData.facilities[index].facility_id);// yha pr change kiya
								  this.updateFacilitySchedule(this.allParkings.facility_id)
							//	  alert(this.allParkings.facility_id + "- 2")
                                  this.fetchFacilityDetails(this.allParkings.facility_id);
                                //this.fetchFacilityDetails(this.facilityData.facilities[index].facility_id);// yha pr change kiya
                }
                $('#update-daily-time').css('display', updateTime);
                $('#dailymonthlybutton').attr('facId', this.allParkings.facility_id);
                //$('#dailymonthlybutton').attr('facId', this.searchResult.facilities[index].facility_id);// yha pr change kiya
                $('#dailymonthlybutton').attr('rate', facilityRate);
                $('#dailymonthlybutton').attr('buyType', type);
                $('#dailymonthlybutton').text(monthdailybuttontext);
                $('#dailymonthlybutton').css('display', mDB);
                $('#checkcoupon').css('display', mDB);

                const facRate = (facilityRate > 0) ? '$' + facilityRate : 'N/A';
                if(type=='monthly')
                {
                                let user_id_ai='Unknown';
                                if(localStorage.getItem('userid'))
                                {
                                                user_id_ai=localStorage.getItem('userid');
                                }else{
                                                user_id_ai='Unknown';
                                }
                                (<any>window).dataLayer.push(
                                                {
                                                'event': 'FacilityClick',
                                                                'UserId' :user_id_ai,
                                                                'Facility': [{
                                                                  'name': this.allParkings.entrance_location,                      // Name or ID is required.
                                                                // 'id': this.searchResult.facilities[index].facility_id,// yha pr kiya
                                                                                'id':this.allParkings.facility_id,
                                                                  'price': facilityRate,
                                                                  'category': 'Monthly',
                                                                }]
                                })
                }
                
                // $('#monthdaily').text(dailymonthlytext);
                $('#checkcoupon').css('display', couponDisplay);
                $('#couponcontent').css('display', couponDisplay);

                $('#mcheckcoupon').css('display', couponDisplay);
                $('#mcouponcontent').css('display', couponDisplay);

                //$('#checkcoupon').attr('data-facid', this.searchResult.facilities[index].facility_id); yha pr change kiya
                //$('#dailymonthlybutton').attr('data-facid', this.searchResult.facilities[index].facility_id);yha pr change kiya
                $('#checkcoupon').attr('data-facid', this.allParkings.facility_id);
                $('#dailymonthlybutton').attr('data-facid', this.allParkings.facility_id);
                this.baserate = facRate;

                $('#parkfull_name').text(this.allParkings.geolocation.address_1);
                $('#geolocationAddress').text(this.allParkings.geolocation.address_1);
                $('#entranceData').text('Entrance : ' + this.allParkings.entrance_location);

                let increment = 0;
                for (var drpdwn of this.allParkings.monthly_rates) {
                                //this.isShowDropDown=1\
                                if (drpdwn.rate != '0.00' && drpdwn.active == '1') {
                                                increment++;
                                }

                }
                this.isShowDropDown = increment;
                this.vehicletype = this.allParkings.monthly_rates;
                // let miles = this.allParkings.distance;
                // miles = Math.round(miles*Math.pow(10,2))/Math.pow(10,2);
                // $('#locDistance').html('&nbsp;' + miles + ' Miles');
                $('#garage_phone').text(this.formatPhoneNumber(this.allParkings.phone_number));
                $('#callData').text('Call ' + this.allParkings.phone_number);

                //const uLat = this.searchResult.facilities[index].geolocation.latitude; yha pr change kiya
                //const uLong = this.searchResult.facilities[index].geolocation.longitude; yha pr change kiya
                const uLat = this.allParkings.geolocation.latitude;
                const uLong = this.allParkings.geolocation.longitude;

                if (this.searchCriteria.device == 'app') {
                                this.initiliazePanormaMobileView(uLat, uLong);
                }

                $('[name="plist"] > input#parking').click();
                $('[name="pview"] > input#parking').click();

}

                initiliazePanormaMobileView(uLat, uLong) {
                                const panorama = new google.maps.StreetViewPanorama(
                                                document.getElementById('pano'),
                                                {
                                                                position: { lat: parseFloat(uLat), lng: parseFloat(uLong) },
                                                                pov: { heading: 165, pitch: 0 },
                                                                zoom: 1
                                                });
                }

                generateMobilePanorama(uLat, uLong) {
                                const panorama = new google.maps.StreetViewPanorama(
                                                document.getElementById('pano'),
                                                {
                                                                position: { lat: uLat, lng: uLong },
                                                                pov: { heading: 165, pitch: 0 },
                                                                zoom: 1
                                                });
                }

                openDetails(facData, searchType) {
                                // Place active marker on map after selecting garage from list view
                                this.is_tax_include=facData.monthlytaxFlag
                                this.markers.forEach(marker => {
                                                if (facData.facility_id == marker.id) {
                                                                this.dropActiveMarker(facData.geolocation.latitude, facData.geolocation.longitude);
                                                                this.displayActiveGarageOverlay();
                                                }
                                });

                                this.sidebarHeightUpdate();
                                this.allParkings = facData;
                                this.EarlyBrid = true;
                                if (this.isMonthlyAction) {
                                                this.selectedMonthlyRate = this.allParkings.monthly_rate_main
                                                let monthly_rate = facData.monthly_rate;
                                                for(let data of this.allParkings.monthly_rates ){
                                                                                if(data.net_rate == monthly_rate)
                                                                                {
                                                                                                this.activeBuyOnline=true;
                                                                                                this.selectedvahicletype = data.rate_type;
                                                                                                this.selectedvahicletypeText=data.hours_description+" "+data.description
                                                                                                this.selectedvahicletypeText1 = data.hours_description
                                                                                                this.selectedvahicletypeText2 = data.description
                                                                                                sessionStorage.setItem('selectedvahicletype', this.selectedvahicletype);
                                                                                break;
                                                                }else{
                                                                                this.activeBuyOnline=false;
                                                                }
                                                }
                                }
                                let is_bonus_hour_applied = '';
                                if( !(localStorage.getItem('is_partner_page') ) ){
                                                this.fetchFacilityAvailableFetatures(facData.facility_id, this.searchCriteria.currdate, this.searchCriteria.length_of_stay, is_bonus_hour_applied);
                                }else{
                                                this.activeLoading = false;
                                                this.PartnerPagePostedRate(facData.facility_id, this.searchCriteria.currdate, this.searchCriteria.length_of_stay, is_bonus_hour_applied);
                                }
                                this.sidebarHeightUpdate();
                                this.allParkings = facData;
                                let facilityRate;
                                let dailymonthlytext;
                                let monthdailybuttontext;
                                let couponDisplay;
                                $('[name="plist"]').removeClass('active')
                                if (searchType === CustomCode.monthlyConstant) {
                                                couponDisplay = 'none';
                                                facilityRate = facData.monthly_rate_main;
                                                dailymonthlytext = '';
                                                monthdailybuttontext = 'buy monthly';
                                                this.isPerMonth = true;
                                                this.parkDetail = true;
                                                this.parkList = false;



                                } else {
                                                couponDisplay = 'block';
                                                facilityRate = facData.price;
                                                dailymonthlytext = this.stime + ' - ' + this.etime;
                                                monthdailybuttontext = 'Book Now';
                                                this.isPerMonth = false;
                                                this.parkDetail = true;
                                                this.parkList = false;


                                }
                                let mDB = 'none';
                                if (facilityRate > 0) {
                                                mDB = 'block';
                                }
                                if (this.allParkings.photo_url) {
                                                this.imgSrcLocation = this.allParkings.photo_url
                                                //$('.parkImg').html("<img src='"+this.allParkings.photo_url+"' class='full-width ttt' height='320'>");
                                } else {
                                                this.imgSrcLocation = '/assets/img/park_garage.png'
                                                //$('.parkImg').html("<img src='/assets/img/park_garage.png' class='full-width' height='320'>");
                                }

                                // if(this.allParkings.rate_type_id==2 || this.allParkings.rate_type_id==4){

                                $('.spsutoava').html('* Space Subject to Availability');
                                // }else{

                                // $('.spsutoava').html("");
                                // }\
                                //            var key in validation_messages
                                let increment = 0;
                                for (var drpdwn of this.allParkings.monthly_rates) {
                                                //this.isShowDropDown=1\
                                                if (drpdwn.rate != '0.00' && drpdwn.active == '1') {
                                                                increment++;
                                                }

                                }
                                this.isShowDropDown = increment;
                                this.vehicletype = facData.monthly_rates;
                                this.updateFacilitySchedule(facData.facility_id);
                                $('#update-daily-time').css('display', couponDisplay);
                                $('#dailymonthlybutton').attr('facId', facData.facility_id);
                                $('#dailymonthlybutton').attr('rate', facilityRate);
                                $('#dailymonthlybutton').attr('buyType', searchType);
                                $('#dailymonthlybutton').text(monthdailybuttontext);
                                const facRate = (facilityRate > 0) ? '$' + facilityRate : 'N/A';
                                // $('#monthdaily').text(dailymonthlytext);
                                $('#checkcoupon').css('display', couponDisplay);
                                $('#checkcoupon').attr('data-facid', facData.facility_id);
                                $('#dailymonthlybutton').attr('data-facid', facData.facility_id);

                                this.baserate = facRate;
                                $('.pmn').toggleClass('hidden');


                }
                showNeighborhood(){
                               // alert(1);
                                if($(window).width() < 768 && localStorage.getItem('showNeigborMarker') ){
                                                localStorage.removeItem('showNeigborMarker');
                                                this.showlisttab = 1;
                                }
                                if($(window).width() < 768 && localStorage.getItem('sortBy') != 'Neighborhood' ){
                                  this.activeNeighborhoodFilter = false;
                                  this.parkList = true
                                  this.hideListView = false;
                                  
                                } 
                }

                tabList(activeTab, inactiveTab) {
                                //alert(1);
                                if($(window).width() < 768 && localStorage.getItem('showNeigborMarker') ){
                                  this.showMapMarkers();
                                                this.showlisttab=0
                                }
                                
                                if(this.showlisttab==1){
                                this.removeActiveMarker();

                                $('body').addClass('noscroll');
                                sessionStorage.removeItem('selectedvahicletype');
                                localStorage.removeItem('showDeals');
                                setTimeout(() => {
                                                this.sidebarHeightUpdate();
                                }, 2000);
                                
                                if (sessionStorage.getItem('tabview')) {

                                } else {
                                                if(this.searchResult.facilities.length > 0 && this.checkInventor==0 && this.searchCriteria.type=='daily'){
                                                                // this.noFacility=0;
                                                                // if( !(localStorage.getItem('is_partner_page') ) ){
                                                                // this.updateInventoryOnSidebar(this.searchResult.facilities);
                                                                // }else{
                                                                                
                                                                //            for ( let i=0; i < this.searchResult.facilities.length; i++ ){
                                                                                                
                                                                //                            $('.onfacilityload_'+this.searchResult.facilities[i].facility_id).addClass('hide');
                                                                //                            $('.onfacilityloadshow_'+this.searchResult.facilities[i].facility_id).removeClass('hide');
                                                                                                
                                                                //            }
                                                                //            this.mergeArrayPartners();
                                                                // }

                                                }
                                                if(this.searchResult.facilities.length <= 0)
                                                {
                                                                this.noFacility=1;
                                                }
                                                sessionStorage.setItem('tabview', '1');
                                                setTimeout(() => {
                                                                                $('.facilityList').animate({
                                                                scrollTop: 0,
                                                }, 'slow');
                                                
                                                },1000 );
                                
                                                
                                                
                                }
                                if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/i)) {
                                                this.iphoneX = true;
                                }else{

                                                
                                }

                                //  if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/i)) {

                                //  $('body').addClass('noscroll');
                                //  $('#content-Wrapper').addClass('iphone-nowraper');
                                //   } else 
                                //   {
                                //    $('body').removeClass('noscroll');
                                //    $('#content-Wrapper').removeClass('iphone-nowraper');
                                //   }
                                this.sidebarHeightUpdate();
                                this.sidebarOn = true;
                                this.sidebarOn1 = true;
                                this.parkDetail = false;
                                if(localStorage.getItem('showNeighbordood') || localStorage.getItem('sortBy') == 'Neighborhood'){
                                                this.parkList = false;
                                }else {
                                                
                                                if( localStorage.getItem('neighbourhoodActive') || localStorage.getItem('neighbourhoodActiveDropDOwn') ) {
                                                                
                                                                this.activeNeighborhoodFilter = true;
                                                                this.parkList = false
                                                                this.hideListView = true
                                                }else {
                                                                this.parkList = true;
                                                                this.hideListView = false;
                                                }
                                                
                                }
                                this.EarlyBrid = false;
                                $('[name=' + activeTab + ']').addClass('active');
                                $('[name=' + inactiveTab + ']').addClass('active');
                                if ($('[name="plist"]').hasClass('active')) {
                                                $('[name="pview"] img').attr('src', 'assets/img/singleView.png');
                                } else {
                                                $('[name="pview"] img').attr('src', 'assets/img/listView.png');
                                }
                                //setTimeout(()=>{ let el: HTMLElement = this.distancesort.nativeElement; el.click(); }, 1000);
                                }}

                mapView(userVal) {

                                let mapT = CustomCode.mapSatelliteView;
                                let img = 'mapView.png';
                                if (userVal === CustomCode.mapSatelliteView) {
                                                mapT = CustomCode.mapDefaultView;
                                                img = 'satView.png';
                                }
                                $('#mapViewLink img').attr('src', 'assets/img/' + img);
                                $('.mapBtnToggle img').attr('src', 'assets/img/' + img);


                                this.mapType = mapT;
                                this.nMap.setMapTypeId(this.mapType);
                }

                parkView() {
                                // const currentFacId = document.getElementById('dailymonthlybutton').getAttribute('data-facid');
                                const currentFacId = this.allParkings.facility_id;
                                if (parseInt(currentFacId) > 0) {
                                                this.parkDetail = true;
                                                this.parkList = false;
                                } else {
                                                this.sidebarOn = false;
                                                this.parkDetail = true;
                                                this.parkList = false;
                                }
                }

                // LATEST MODIFIES CODE STARTS HERE

                manageCoupons() {
                                
                                this.hideSendEmailButton = false;
                                this.hideSendEmailButton2 = false;
                                this.showEnrollForm = false;
                                this.checkedCheckbox = false;
                                this.getCouponDisabled = true;
                                this.ShowCouponListOnLoad = false;;
                                $('.couponsErrorMessage').hide();
                                $('#couponmail').hide();
                                this.isLoaded = false;
                                // ga('send', 'event', { eventCategory: 'coupon view new', eventAction: 'click' });
                             //   ga('send', 'event', { eventCategory: "Pay At Garage Spot Not Guranted", eventLabel:CustomCode.BASEURL+'/search', eventAction: 'click'});
                                this.loggedUserName = CustomCode.getloggedInUserDetails();
                                CustomCode.displayLoginUser();

                                if (!this.loggedUserName) {
                                                $('#mailcoupon').css('display', 'none');
                                } else {
                                                $('#mailcoupon').css('display', 'block');
                                }

                                const currentFacId = this.allParkings.facility_id;
                                const couponId = this.allParkings.rate_id;
                                if (couponId) {
                                                const date = new Date();
                                                const todayName = date.toLocaleDateString('en', { weekday: 'long' }).toLowerCase();
                                                // for (let cd = 0; cd < cData.data.length; cd++) {
                                                // if (cData.data[cd].rate_type === 'Coupons' && cData.data[cd].rates.length > 0) {
                                                // this.couponsData = cData.data[cd].rates;

                                                this.printEmailCoupon(couponId);
                                                this.printCoupon();
                                                // }

                                                // if (cData.data[cd].rate_type === 'Coupons' && cData.data[cd].rates.length == 0) {
                                                // this.isLoaded = true;
                                                // $('#couponsErrorMessage').removeClass('hidden').show();
                                                // }
                                                // }
                                } else {
                                                this.isLoaded = true;
                                                $('.couponsErrorMessage').removeClass('hidden');
                                                $('.couponsErrorMessage').show();
                                }
                                // this.searchPageObj.getFacilityTypeCoupon(currentFacId).then(
                                //            (couponsRes) => {
                                //                            const cData: any = couponsRes;
                                //                            const couponCount = 0;
                                //                            if (cData.data.length > 0) {
                                //                                            const date = new Date();
                                //                                            const todayName = date.toLocaleDateString('en', { weekday: 'long' }).toLowerCase();
                                //                                            for (let cd = 0; cd < cData.data.length; cd++) {
                                //                                                            if (cData.data[cd].rate_type === 'Coupons' && cData.data[cd].rates.length > 0) {
                                //                                                                            this.couponsData = cData.data[cd].rates;
                                //                                                                            
                                //                                                                            this.printEmailCoupon(this.couponsData[0].id);
                                //                                                                            this.printCoupon();
                                //                                                            }

                                //                                                            if (cData.data[cd].rate_type === 'Coupons' && cData.data[cd].rates.length == 0) {
                                //                                                                            this.isLoaded = true;
                                //                                                                            $('#couponsErrorMessage').removeClass('hidden').show();
                                //                                                            }
                                //                                            }
                                //                            } else {
                                //                                            $('#couponsErrorMessage').show();
                                //                            }
                                //            },
                                //            (error) => { 
                                //            }
                                // );
                                // $('#coupon-modal .modal-dialog').removeClass('modal-sm');
                                // $('#coupon-modal .modal-dialog').addClass('modal-lg');
                                $('#couponheading').css('display', 'block');
                                $('#couponprintdata').css('display', 'block');
                                // $('#couponheading').text('Coupons Available for this Facility');
                                $('#couponslisting').css('display', 'block');
                                $('.coupons-other-areas').css('display', 'none');
                }

                printEmailCoupon(couponId) {
                                $('#couponprintdata').css('display', 'block');
                                this.getVisitorCouponTracking({ "couponId": couponId, "type": this.VIEW_COUPON });
                                $('#couponslisting').css('display', 'none');
                                $('#coupon-mail-print').attr('data-id', couponId);

                                // $('#coupon-modal .modal-dialog').removeClass('modal-lg');
                                // $('#coupon-modal .modal-dialog').addClass('modal-sm');
                                // $('#coupon-mail-print').css('display', 'block');
                                // $('#couponheading').text('How would you like to receive your coupon?');
                }

                mailPopup(id, value, text) {
                                this.text = text;
                                this.value = value;
                                this.couponMainId=id
                                var cId = id;// $('#coupon-mail-print').attr('data-id');
                                // $('#coupon-mail-print').css('display', 'none');
                                $('#couponslisting').css('display', 'none');
                                $('#couponmail').show();
                                $('#couponmail1').show();
                                $('.couponprintdata').hide();
                                // $('#couponheading').text('How would you like to receive your coupon?');
                                // $('#coupon-modal .modal-dialog').removeClass('modal-lg');
                                // $('#coupon-modal .modal-dialog').addClass('modal-sm');
                }

                cancelEmail(formValues, formObj: NgForm) {
                                $('#couponmail').hide();
                                $('#couponmail1').hide();
                                $('.couponprintdata').show();
                                formObj.resetForm();
                }

                saveCouponApi(id) {
                            //    ga('send', 'event', { eventCategory: 'save coupon', eventAction: 'click' });
                                const cId = id
                                this.myAccountMonthlyParkingObj.fetchUser()
                                                .then(
                                                                data => {
                                                                                this.activeUser = data;
                                                                                this.activeUser = this.activeUser.data.id;
                                                                                this.searchPageObj.saveCoupon(this.activeUser, cId)
                                                                                                .subscribe(
                                                                                                                data => {
                                                                                                                                this.respdata = data;
                                                                                                                                if (this.respdata.status === 201) {
                                                                                                                                                // $('#coupon-mail-print').css('display', 'none');
                                                                                                                                                //$('#couponmail').css('display', 'block');
                                                                                                                                                //$('#couponmail1').css('display', 'block');
                                                                                                                                                // $('#coupon-modal .modal-dialog').addClass('modal-lg');
                                                                                                                                                // $('#coupon-modal .modal-dialog').removeClass('modal-sm');

                                                                                                                                                if(this.isPartners){
                                                                                                                                                                this.toasterObj.success('Offer has been Saved Successfully', 'success!');
                                                                                                                                                                $('.closeModelpoup').click();
                                                                                                                                                }else {
                                                                                                                                                                this.router.navigate(['/thanks-coupon-saved']);
                                                                                                                                                }
                                                                                                                                }
                                                                                                                },
                                                                                                                error => {
                                                                                                                                /* FormValidationErrors.displayServerErrors('signuperrors-container', error.errors); */
                                                                                                                                const errorsList = error.errors;
                                                                                                                                if (errorsList.message !== 'Validation Errors') {
                                                                                                                                                this.toasterObj.error(errorsList.message, 'Sorry!');
                                                                                                                                } else {
                                                                                                                                                for (const key in errorsList.detail) {
                                                                                                                                                                if (errorsList.detail.hasOwnProperty(key)) {
                                                                                                                                                                                if (typeof errorsList.detail === 'undefined') {
                                                                                                                                                                                                this.toasterObj.error(errorsList.message, 'Sorry!');
                                                                                                                                                                                } else {
                                                                                                                                                                                                this.toasterObj.error(errorsList.detail[key], 'Sorry!');
                                                                                                                                                                                }
                                                                                                                                                                }
                                                                                                                                                }
                                                                                                                                }
                                                                                                                }
                                                                                                );

                                                                },
                                                                error => {

                                                                }
                                                );
                }

                reset(formValues, formObj: NgForm) {
                                formObj.resetForm();
                }
                addgta() {
                               // gtag_report_conversion(this.gtmUrl);

                                // gtag('event', 'conversion', {
                                //                 'send_to': 'AW-876742851/p-W2CPCis3UQw5GIogM',
                                //                 'value': 20.0,
                                //                 'currency': 'USD',
                                //                 'eventCallback': window['callbackgtm']
                                // });


                }
                sendCoupon(formData, formObj: NgForm) {
                                /// cId = $('#coupon-mail-print').attr('data-id');
                                const cId=this.couponMainId
                               /*
                               // ga("ec:addProduct", {
                                                "id": cId,
                                                "name": this.text,
                                                "price": this.value,
                                                "brand": "Email",
                                                "category": "Parking",
                                                "quantity": 1
                                });
                               // ga('ec:setAction', 'purchase', {          // Transaction details are provided in an actionFieldObject.
                                'id': cId,                         // (Required) Transaction id (string).
                                'affiliation': 'Icon Parking', // Affiliation (string).
                                'revenue':this.value,                     // Revenue (currency)
                                });
                
                               // ga('send', 'event', { eventCategory: 'email coupon', eventAction: 'click' });
                                if (this.ranValue == 1) {
                                              //  ga('send', 'event', { eventCategory: 'email slider coupon', eventAction: 'click', label: this.text, value: this.value });
                                } else {
                                              //  ga('send', 'event', { eventCategory: 'email square coupon', eventAction: 'click', label: this.text, value: this.value });
                                }
                                */

                               // gtag_report_conversion(this.gtmUrl);

                                // gtag('event', 'conversion', {
                                //                 'send_to': 'AW-876742851/JeWeCP-Vm3UQw5GIogM',
                                //                 'eventCallback': window['callbackgtm']
                                // });

                                this.getVisitorCouponTracking({ "couponId": cId, "type": this.EMAIL_COUPON, "to": formData.ucmail, 'opentype': this.ranValue });
                                this.searchPageObj.mailCouponNew(formData, cId)
                                                .subscribe(
                                                                data => {
                                                                                this.respdata = data;
                                                                                // this.searchPageObj.getMobileCode().subscribe((data)=>{
                                                                                //            (<any>window).dataLayer.push(
                                                                                //                            {
                                                                                //                            'promoCode': this.allParkings.coupon_code,
                                                                                //                            'couponCode':data.data.code,
                                                                                //                            'barCode':data.data.barcode,
                                                                                //                            'event': 'Email Coupon',
                                                                                //                            'location': data.data.facility.short_name
                                                                                //                            });

                                                                                //                            /* #Start Add GTM logged in db */
                                                                                //                            let logData = {
                                                                                //                                            'iq_code': null,
                                                                                //                                            'coupon_code':data.data.code,
                                                                                //                                            'bar_code':data.data.barcode,
                                                                                //                                            'event_type':0
                                                                                //                            }

                                                                                //                            this.logsService.addLog(logData).subscribe( (result)=>{ }, (err) => { });
                                                                                //                            /* #End GTM logged in db */
                                                                                // });

                                                                                if (this.respdata.status === 201) {
                                                                                                if( sessionStorage.getItem('enrollFromCoupon') ){
                                                                                                                if(this.isPartners){
                                                                                                                                this.toasterObj.success('The offer has been successfully sent to your email id. Please present it at the parking facility to receive your special rate (based on availability)', 'Success!');
                                                                                                                                $('.closeModelpoup').click();
                                                                                                                }
                                                                                                }else {
                                                                                                                //$('#couponmail').css('display', 'block');
                                                                                                                //$('#couponmail1').css('display', 'block');
                                                                                                                formObj.resetForm();
                                                                                                                if(this.isPartners){
                                                                                                                                this.toasterObj.success('The offer has been successfully sent to your email id. Please present it at the parking facility to receive your special rate (based on availability)', 'Success!');
                                                                                                                                $('.closeModelpoup').click();
                                                                                                                }else{
                                                                                                                                this.router.navigate(['/thanks-email']);
                                                                                                                }
                                                                                                }
                                                                                                
                                                                                }
                                                                },
                                                                error => {
                                                                                const errorsList = error.errors;
                                                                                if (errorsList.message !== 'Validation Errors') {
                                                                                                this.toasterObj.error(errorsList.message, 'Sorry!');
                                                                                } else {
                                                                                                for (const key in errorsList.detail) {
                                                                                                                if (errorsList.detail.hasOwnProperty(key)) {
                                                                                                                                if (typeof errorsList.detail === 'undefined') {
                                                                                                                                                this.toasterObj.error(errorsList.message, 'Sorry!');
                                                                                                                                } else {
                                                                                                                                                this.toasterObj.error(errorsList.detail[key], 'Sorry!');
                                                                                                                                }
                                                                                                                }
                                                                                                }
                                                                                }
                                                                }
                                                );

                }

                cancelCoupon() {
                                // $('#couponheading').text('How would you like to receive your coupon?');
                                // $('#coupon-mail-print').css('display', 'block');
                                $('#couponmail').css('display', 'none');
                                $('#couponmail1').css('display', 'none');
                }

                couponOptions() {

                }

                printCoupon() {
                                // ga('send', 'event', { eventCategory: 'coupon print', eventAction: 'click'});
                                const facId = $('#coupon-mail-print').attr('data-id');
                                // $('#coupon-modal .modal-dialog').removeClass('modal-sm');
                                // $('#couponheading').text('');
                                this.isLoaded = false;
                                $(".couponList").addClass('hide');
                                setTimeout(() => {
                                                var random = Array(1, 2);
                                                this.ranValue = random[Math.floor(Math.random() * random.length)];

                                                if (localStorage.getItem('userid')) {
                var setTrggerCoupon = CustomCode.getCookie('trigger_coupon');
                if (setTrggerCoupon == '') {
                    CustomCode.setCookie('trigger_coupon', this.ranValue.toString(), 1);
                }
                else {
                    this.ranValue = parseInt(setTrggerCoupon);
                }
            }
            else {
                this.ranValue = random[Math.floor(Math.random() * random.length)];
            }

                                                if (this.ranValue == 1) {
                                                              //  ga('send', 'event', { eventCategory: 'Get coupon slider-coupon', eventAction: 'click' });
                                                                $('#triggercoupon1').trigger('click');
                                                } else {
                                                                this.showSelectBox=true;
                                                               // ga('send', 'event', { eventCategory: 'Get coupon square coupon box', eventAction: 'click' });
                                                                $('#triggercoupon1').trigger('click');
                                                }

                                                $(window).resize();
                                }, 500);
                                this.ShowCouponListOnLoad = false;;;
                                this.searchPageObj.printCouponDetails(facId).subscribe(
                                                (couponPrint) => {
                                                                this.showSelectBox = true;
                                                                this.getCouponDisabled = false;
                                                                this.ShowCouponListOnLoad = true;
                                                                const urlCreator = window.URL;
                                                                this.qrcodeData  = couponPrint.data.parkonect_id;
                            this.barcodeData  = couponPrint.data.ticketech_id;
                                                                this.barcodeValue = couponPrint.data.barcode;
                                                                this.couponCodeVaue = couponPrint.data.couponCode
                                                                this.array_data = couponPrint.data
                                                                if (this.array_data.coupons.length == 1) {
                                                                                this.isClassVisible = false;
                                                                } else {
                                                                                this.isClassVisible = true;
                                                                }
                                                                //const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(couponPrint['_body']));
                                                                //this.printData = blobData['changingThisBreaksApplicationSecurity'];
                                                                this.isLoaded = true;
                                                                this.searchPageObj.getMobileCode().subscribe((data) => {
                                                                                let popUpType: any = ''
                                                                                if (this.ranValue == 1) {
                                                                                                popUpType = 'Slider-pop up';
                                                                                } else {
                                                                                                popUpType = 'Square-pop up';
                                                                                }
                                                                                (<any>window).dataLayer.push(
                                                                                                {
                                                                                                                'promoCode': this.allParkings.coupon_code,
                                                                                                                'couponCode': data.data.code,
                                                                                                                'barCode': data.data.barcode,
                                                                                                                'event': 'couponClicked',
                                                                                                                'location': data.data.facility.short_name,
                                                                                                                'popup-type': popUpType
                                                                                                }
                                                                                );

                                                                                /* #Start Add GTM logged in db */
                                                                                let logData = {
                                                                                                'iq_code': null,
                                                                                                'coupon_code': data.data.code,
                                                                                                'bar_code': data.data.barcode,
                                                                                                'event_type': 0
                                                                                }

                                                                                this.logsService.addLog(logData).subscribe((result) => { }, (err) => { });
                                                                                /* #End GTM logged in db */
                                                                });
                                                                $('#invoicePrint').css('display', 'block');
                                                                $('#couponvalid').css('display', 'block');
                                                                // setTimeout(() => {
                                                                $('div#couponprintdata').css('display', 'block');
                                                                // }, 200);
                                                                $(window).resize();

                                                },
                                                (error) => {
                                                                this.getCouponDisabled = false;
                                                                $('.couponsErrorMessage').html(error.errors.message);
                                                                $('.couponsErrorMessage').removeClass('hidden').css('display', 'block');
                                                                this.isLoaded = true;
                                                }
                                );
                                // this.getVisitorCouponTracking({"couponId":facId,"type":this.PRINT_COUPON});


                                $('#coupon-mail-print').css('display', 'none');
                                $('#couponprintdata').css('display', 'block');
                                $('#invoicePrint').css('display', 'none');
                                $('#couponvalid').css('display', 'none');
                }

                showCouponbox(id) {
                                $(".couponList").addClass('hide');
                                $("#couponShow" + id).removeClass('hide');
                                this.showSelectBox = false
                }
                backSelectbox(id) {
                                $(".couponList").addClass('hide');
                                this.showSelectBox = true;
                }
                closePopup() {
                                $('button#closeCouponModal').click();
                                // $('#coupon-modal .modal-dialog').addClass('modal-sm');
                                // $('#couponheading').text('How would you like to receive your coupon?');
                                // $('#coupon-mail-print').css('display', 'block');
                                // $('#couponprintdata').css('display', 'none');
                }

                printMedia(id, value, text) {
                                this.text = text
                                this.value = value
                                // $('#coupon-modal .modal-dialog').removeClass('modal-lg');
                                // $('#coupon-modal .modal-dialog').removeClass('modal-sm');
                                this.showSelectBox = false
                                const facId = id;//$('#coupon-mail-print').attr('data-id');
                               /*
                              //  ga("ec:addProduct", {
                                                "id": facId,
                                                "name": this.text,
                                                "price": this.value,
                                                "brand": "Print",
                                                "category": "Parking",
                                                "quantity": 1
                                });
                               // ga('ec:setAction', 'purchase', {          // Transaction details are provided in an actionFieldObject.
                                'id': facId,                         // (Required) Transaction id (string).
                                'affiliation': 'Icon Parking', // Affiliation (string).
                                'revenue':this.value,                     // Revenue (currency)
                                });
                                */

                                $('body').removeClass('modal-open');
                                setTimeout(()=>{
                                                $('div#printable').html($('#table' + id).html()).show();
                                                $('div#content-Wrapper').hide();
                                                window.print();
                                },2000);

                                $('body').removeClass('noserch-scroll noscroll modal-open');
                               // ga('send', 'event', { eventCategory: 'coupon print', eventAction: 'click' });
                                if (this.ranValue == 1) {
                                        //        ga('send', 'event', { eventCategory: 'print slider coupon', eventAction: 'click', label: this.text, value: this.value });
                                } else {
                                            //    ga('send', 'event', { eventCategory: 'print square coupon', eventAction: 'click', label: this.text, value: this.value });
                                }
                               // gtag_report_conversion(this.gtmUrl);

                                // gtag('event', 'conversion', {
                                //                 'send_to': 'AW-876742851/vnq6COWfiXUQw5GIogM',
                                //                 'eventCallback': window['callbackgtm']
                                // });

                                this.getVisitorCouponTracking({ "couponId": facId, "type": this.PRINT_COUPON, 'opnetype': this.ranValue });
                                setTimeout(() => {
                                                $('div#printable').hide();
                                                $('div#content-Wrapper').show();
                                                this.router.navigate(['/thanks-print']);
                                }, 4000);
                }

                // LATEST MODIFIED CODE ENDS HERE

                public openModal(template: TemplateRef<any>, selectedFacilityId) {
                                this.modalRef = this.modalService.show(template);
                                if(this.searchCriteria.type=='monthly'){
                                                this.updateFacilityScheduleFormatted(selectedFacilityId);
                                }
                                
                }
                public openModal2(template: TemplateRef<any>) {
                                this.modalRef = this.modalService.show(template);
                }

                loadGarageDetails() {
                                this.sidebarOn = true;
                                this.parkDetail = true;


                }
                redirecttoList() {
                                this.router.navigate(['/', 'oversized']);
                                this.modalRef.hide();
                }
                checkAvailability() {
                                if (this.searchCriteria.length_of_stay >= 2) {
                                                $('#enddate').val($('#penddate').val());
                                                $('#startdate').val($('#pstartdate').val());
                                                $('#starttime').val($('#pstarttime').val());
                                                $('#endtime').val($('#pendtime').val());
                                                this.updateFilters.emit(this.searchCriteria);
                                                $('button.close').trigger('click');
                                                $('#stime').val($('#pstime').val());
                                                $('#etime').val($('#petime').val());
                                                const dailymonthlytext = $('#stime').val() + ' - ' + $('#etime').val();
                                                // $('#monthdaily').text(dailymonthlytext);
                                } else {
                                                this.toasterObj.error('You can not make booking in the past', 'error');
                                }
                }


                moveToReservation(facId, dailyRate, monthlyRate,is_wran,warn_msg) {
                                if( this.searchCriteria.type =='monthly' ){
                                              //  ga('send', 'event', { eventCategory: "Book Now, Monthly", eventLabel:CustomCode.BASEURL+'/search', eventAction: 'click'});            
                                                if(0){
                                                                localStorage.setItem('clickBuyMonthly','1');
                                                                $("a#loginPopup")[0].click();
                                                                let facilityId = facId;
                                                                let mD = $('#monthlydate').val().toString().split('/');
                                                                let fDate = mD[2] + '-' + mD[0] + '-' + mD[1];
                                                                //this.router.navigate([routeNavigate, facId, fDate]);
                                                                localStorage.setItem('monthlyParkingId',facilityId)
                                                                localStorage.setItem('monthlyParkingDate',fDate);
                                                                return false
                                                }
                                }
                                if( this.searchCriteria.type =='daily' ){
                               // ga('send', 'event', { eventCategory: "Book Now, Daily", eventLabel:CustomCode.BASEURL+'search', eventAction: 'click'});            
                                }
                                let coupon_threshold_price = $('#threshold_'+facId).text();
                                sessionStorage.setItem('coupon_threshold_price', coupon_threshold_price);
                                let routeNavigate = '/buy-monthly';
                                if (this.searchCriteria.type === CustomCode.defaultFacilityType) {
                                                let date=new Date($('#startDateWithID').val());
                                                //new Date($('#startDateWithID').val().trim())
                                                //return 
                                                const sDate=   ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + date.getFullYear();
                                                //const sDate = $('#startdate').val().toString().trim();
                                                date=new Date($('#endDateWithID').val());
                                                const eDate =    ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + date.getFullYear();
                                                //const eDate = $('#enddate').val().toString().trim();
                                                const sTime = $('#startTime').val().toString().trim();
                                                const eTime = $('#endTime').val().toString().trim();
                                                let redirect = sDate + '&' + sTime + '&&' + eDate + '&' + eTime;
                                                redirect = redirect.split('/').join('-');
                                                const navURL = "/payment/" + facId + "/" + redirect + "/" + dailyRate;
                                                this.bylistingurl=navURL
                                                if(is_wran==1)
                                                {              

                                                                this.searchPageObj.getFacilitySchedule(facId).then(
                                                                                                (res) => {
                                                                                                                this.garageWorkingHours = res;
                                                                                                                this.loadWorkingHours = true;
                                                                                                                this.garageWorkingHours = this.garageWorkingHours.data;
                                                                                                },
                                                                                                (error) => {
                                                                                                                this.loadWorkingHours = true;
                                                                                                }
                                                                );

                                                                this.bylisting=1;
                                                                let el: HTMLElement = this.myDiv.nativeElement as HTMLElement;
                                                                this.isPpopupShowMessage=warn_msg;
                                                                // this.isPpopupShowMessageTitle = this.isPpopupShowMessage.split('.').slice(0, -1).join();
                                                                // this.isPpopupShowMessageText = this.isPpopupShowMessage.split('.').splice(-1);
                                                                el.click();
                                                }else{
                                                                this.isPpopupShowMessage='';
                                                                this.router.navigate([navURL]);
                                                                this.bylisting=0;
                                                }              
                                                
                                } else {
                                                let mD=new Date($('#time_search').val());
                                                //const mDDormat = $('#monthlydate').val().toString().split('/');
                                                const fDate = mD.getFullYear()+'-'+((mD.getMonth() > 8) ? (mD.getMonth() + 1) : ('0' + (mD.getMonth() + 1))) + '-' + ((mD.getDate() > 9) ? mD.getDate() : ('0' + mD.getDate()));
                                                this.router.navigate([routeNavigate, facId, fDate]);

                                }
                }

                updateIsActiveFacility() {
                                this.sidebarOn = false;
                                this.isActiveFacility = false;
                                this.isLoaded = false;
                                $('[name="plist"]').removeClass('active');
                }

                mobileReservation(facId, facPrice) {

                }


                getMapRadius() {
                                var bounds = this.nMap.getBounds();
                                var center = bounds.getCenter();
                                var ne = bounds.getNorthEast();
                                var r = 3963.0;
                                var lat1 = center.lat() / 57.2958;
                                var lon1 = center.lng() / 57.2958;
                                var lat2 = ne.lat() / 57.2958;
                                var lon2 = ne.lng() / 57.2958;
                                var radius = r * Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1));
                                radius = radius >= 0.5 ? radius : 0.5
                                this.searchCriteria.radius = radius;
                                this.searchCriteria.lat = (center.lat()).toFixed(8);
                                this.searchCriteria.long = (center.lng()).toFixed(8);
                                this.isDraggable = true;
                                this.markerLoaded = true;
                                this.updateIsActiveFacility();
                                // this.updateFilters.emit(this.searchCriteria);
                                this.updateFiltersDragMap.emit(this.searchCriteria);
                }
                centerLocationNeighbour() {


                }
                centerLocation() {
                  localStorage.setItem('centerClickLocation','1');
                                //localStorage.setItem('centerClick','1')
                                this.findNearMe();
                }

                getLengthOfStayStartDate(event) {
                                let startTime: any = new Date(this.returnFormattedDate(event) + ' ' + this.returnFormattedTime(this.startTime));
                                let endTime: any = new Date(this.returnFormattedDate(this.dailyEndDate) + ' ' + this.returnFormattedTime(this.endTime));
                                const bookingHours: number = ((endTime - startTime) / 60000) / 60;
                                return bookingHours;
                }

                getLengthOfStayStartTime(event) {
                                let startTime: any = new Date(this.returnFormattedDate(this.dSD) + ' ' + this.returnFormattedTime(event));
                                let endTime: any = new Date(this.returnFormattedDate(this.dailyEndDate) + ' ' + this.returnFormattedTime(this.endTime));
                                const bookingHours: number = ((endTime - startTime) / 60000) / 60;
                                return bookingHours;
                }

                getLengthOfStayEndTime(event) {
                                let startTime: any = new Date(this.returnFormattedDate(this.dSD) + ' ' + this.returnFormattedTime(this.startTime));
                                let endTime: any = new Date(this.returnFormattedDate(this.dailyEndDate) + ' ' + this.returnFormattedTime(event));
                                const bookingHours: number = ((endTime - startTime) / 60000) / 60;
                                return bookingHours;
                }

                getLengthOfStayEndDate(event) {
                                let startTime: any = new Date(this.returnFormattedDate(this.dSD) + ' ' + this.returnFormattedTime(this.startTime));
                                let endTime: any = new Date(this.returnFormattedDate(event) + ' ' + this.returnFormattedTime(this.endTime));
                                const bookingHours: number = ((endTime - startTime) / 60000) / 60;
                                return bookingHours;
                }

                returnFormattedDate(date) {
                                let dateNew = date.toLocaleString('en-US', { hour12: false }).split(" ");
                                let mdy = dateNew[0];
                                let mdy2 = mdy.split('/');
                                let month = mdy2[0];
                                let day = mdy2[1];
                                let year = mdy2[2];
                                let yearFix = year.split(',');
                                let finalDate = month + '/' + day + '/' + yearFix[0];
                                return finalDate;
                }

                returnFormattedTime(date) {
                                let dateNew = date.toLocaleString('en-US', { hour12: false }).split(" ");
                                return dateNew[1];
                }

                getVisitorCouponTracking(data) {
                                this.getReferrerForVisitor();
                                this.userServiceObj.getVisitorCouponTracked(data)
                                                .subscribe(
                                                                data => {

                                                                },
                                                                error => {
                                                                                //let errorMessage = error.errors.message;

                                                                }
                                                );


                }

                getReferrerForVisitor() {
                                this.userServiceObj.getReferrerForVisitor()
                                                .subscribe(
                                                                data => {
                                                                },
                                                                error => {

                                                                }
                                                );
                }
                updateFacilityScheduleFormatted(id) {
                                this.searchPageObj.getFacilityScheduleFormatted(id).then(
                                                (res) => {
                                                                this.FgarageWorkingHours = res;
                                                                this.FgarageWorkingHours = this.FgarageWorkingHours.data;
                                                                this.FulldayWrking = 0;

                                                },
                                                (error) => {
                                                                this.FulldayWrking = 1;
                                                }
                                );
                }
                close() {
                                this.alreadyEnroll = 0;
                                localStorage.setItem('hide_popup', '1');
                               // ga('send', 'event', { eventCategory: 'Cancel-button', eventAction: 'Click' });
                }
                redirectToLoyal(eventLabel) {
                              //  ga('send', 'event', { eventCategory: 'Enroll-btn(search)', eventLabel: eventLabel, eventAction: 'click' });
                                let eventlabel = eventLabel;
                                let eventCategory = 'search-enroll-now';
                                if (localStorage.getItem('access_token')) {
                                                if (localStorage.getItem('is_loyalty') == '1') {

                                                }
                                                else {
                                                                this.router.navigate(['/my-account/enroll-in-icon-rewards']);
                                                                if (sessionStorage.getItem('event_for_loyalty') != '1') {
                                                                                sessionStorage.setItem('event_for_loyalty', '1');
                                                                                sessionStorage.setItem('eventlabel', eventlabel);
                                                                                sessionStorage.setItem('eventCategory', eventCategory);
                                                                }
                                                }
                                } else {
                                                if (sessionStorage.getItem('event_for_loyalty') != '1') {
                                                                sessionStorage.setItem('event_for_loyalty', '1');
                                                                sessionStorage.setItem('eventlabel', eventlabel);
                                                                sessionStorage.setItem('eventCategory', eventCategory);
                                                }
                                                this.router.navigate(['/icon-rewards-register']);

                                }
                }


                gtmTrackingEventMoreBtnSearchReadMore(eventLabel) {
                                let eventlabel = eventLabel;
                                if (sessionStorage.getItem('event_for_loyalty') != '1') {
                                                sessionStorage.setItem('event_for_loyalty', '1');
                                                sessionStorage.setItem('eventlabel', eventLabel);
                                                sessionStorage.setItem('eventCategory', 'read-more(Search)');
                                }
                               // ga('send', 'event', { eventCategory: 'read-more(Search)', eventAction: 'Click', eventLabel: eventlabel });
                                this.router.navigate(['/rewards']);
                }

                fetchFacilityAvailableFetatures(facId, arrival_time, length_of_stay, is_bonus_hour_applied) {
                                this.activeLoading = true;
                                let bonus = is_bonus_hour_applied ? 1 : 0;
                                let queryString = facId + "?";
                                queryString += "arrival_time=" + arrival_time + "&";
                                queryString += "length_of_stay=" + length_of_stay + "&";
                                queryString += "use_bonus=" + bonus;
                                localStorage.removeItem('isUpdatedEndTimeFlag');
                                this.searchPageObj.fetchUpdatedBookingRates(queryString).then(
                                                (res) => {
                                                                $("[src$='.svg']").parent().removeClass("disabledmarker")
                                                                this.getUpdatedPrice = true;
                                                                this.activeLoading = false;
                                                                this.checkupdatedPrice = true;
                                                                this.selectedMonthlyRate = this.allParkings.monthly_rate_main;
                                                                this.boardRate = [];
                                                                let record = [];
                                                                this.FacilityAvailableFetatures = res;
                                                                this.facilitynameforpostal=this.FacilityAvailableFetatures.data.facility.facility_name_modified
                                                                // this.getFacId = this.FacilityAvailableFetatures.data.facility.id;
                                                                if(this.FacilityAvailableFetatures.data.facility.is_oversize_allowed==0 ){
                                                                                this.showOversizedMsg = true;
                                                                                this.overSizeMsg=this.FacilityAvailableFetatures.data.facility.oversize_not_allowed_message
                                                                }else {
                                                                                this.showOversizedMsg = false;
                                                                }
                                                                this.facilitynameforpostaladdress=this.FacilityAvailableFetatures.data.facility.entrance_location
                                                                this.taxRate = this.FacilityAvailableFetatures.data.facility.tax_rate * 100
                                                                record = this.FacilityAvailableFetatures.data.facility.facility_on_board_rate
                                                                let cat: any = 0;
                                                                let m = 0;
                                                                let rate = [];
                                                                let records = []
                                                                let name = '';
                                                                for (let i = 0; i < record.length; i++) {
                                                                                rate[m] = record[i]
                                                                                m++;
                                                                }
                                                                let p = 0;
                                                                for (let i = 0; i < record.length; i++) {
                                                                                name = record[i].category.category
                                                                                if (!this.checkDuplicate(records, name)) {
                                                                                                records[p] = { 'name': name, 'rates': rate }
                                                                                                p++;
                                                                                }

                                                                }
                                                                this.boardRate = records
                                                                this.FacilityAvailableFetatures = this.FacilityAvailableFetatures.data;
                                                                this.paramPrice = this.FacilityAvailableFetatures.price.replace('.00', '');
                                                                this.isUpdatedEndTimeFlag = false;
                                                                if(this.FacilityAvailableFetatures.availability > 0 && ( this.FacilityAvailableFetatures.ticketech_id !=='' || this.FacilityAvailableFetatures.parkonect_id !=='' )){
                                                                                this.isUpdatedEndTimeFlag = (this.FacilityAvailableFetatures.isUpdatedEndTimeFlag)?true:false;
                                                                }
                                                                if(this.FacilityAvailableFetatures.ticketech_id =='' && (this.FacilityAvailableFetatures.parkonect_id =='' || this.FacilityAvailableFetatures.parkonect_id ==null)){
                                                                                this.isUpdatedEndTimeFlag = false;
                                                                }
                                                                if(this.isUpdatedEndTimeFlag){
                                                                                let updateDate = this.FacilityAvailableFetatures.updated_end_time;
                                                                                if (this.deviceInfo.browser === 'safari' || this.deviceInfo.browser === 'ie') {
                                                                                                updateDate = FilterHelper.defaultEndDurationUrlForSafariForMoreTime(updateDate);
                                                                                }
                                                                                let endDate = this.FacilityAvailableFetatures.updatedDateWeb//FilterHelper.formatDate(new Date(updateDate));
                                                                                let endTime = FilterHelper.getFormattedTime(new Date(updateDate));
                                                                                this.dailyEndDateMore = endDate;
                                                                                this.dEndTime = (this.FacilityAvailableFetatures.updatedTimeWeb=='00:00 AM')?'12:00 AM':this.FacilityAvailableFetatures.updatedTimeWeb//FilterHelper.displayableTimeformat(endTime);
                                                                                this.MTSMMessage = `<table><tr><td><img width="30" src="/assets/img/car-popover.png"></td><td>At this Icon Parking facility, ${this.displayableStartDateWrapper()} ${this.dStartTime} to ${this.displayableEndDateWrapper()} ${this.dEndTime} costs the same as the time you selected. The extra time has been added to your booking - stay until ${this.displayableEndDateWrapper()} ${this.dEndTime}.</td></tr></table>`;
                                                                }else{
                                                                                this.dEndTime = FilterHelper.displayableTimeformat($('#endTime').val());
                                                                                let endDate = new Date($('#enddate').val());
                                                                                this.dailyEndDate = endDate;
                                                                }

                                                                if(this.FacilityAvailableFetatures.warning_on_reservation == 1){
                                                                                this.isPpopupShow = 1;
                                                                                this.isPpopupShowMessage=this.FacilityAvailableFetatures.warning_on_reservation_msg;
                                                                }else{
                                                                                this.isPpopupShow = 0;
                                                                                this.isPpopupShowMessage='';
                                                                }

                                                                if ('coupon_threshold_price' in this.FacilityAvailableFetatures) {
                                                                                this.GlobalThreshold = this.FacilityAvailableFetatures.coupon_threshold_price;
                                                                } else {
                                                                                this.GlobalThreshold = 0;
                                                                }
                                                                sessionStorage.setItem('coupon_threshold_price', this.GlobalThreshold);

                                                                if (this.FacilityAvailableFetatures.availability == 0) {
                                                                                this.availabilityCheck = true;
                                                                } else {
                                                                                this.availabilityCheck = false;
                                                                }
                                                                if ((this.FacilityAvailableFetatures.coupon_price_applied == 0)) {
                                                                                this.enableDefaultMsg = true;
                                                                } else {
                                                                                this.enableDefaultMsg = false;
                                                                }
                                                                
                                                                this.displayActiveGarageOverlay();
                                                },
                                                (error) => {
                                                                this.activeLoading = false;
                                                                $("[src$='.svg']").parent().removeClass("disabledmarker");
                                                }
                                )
                }
                
                checkFormat(value)
  {
                if(this.searchType=='monthly')
                {
                                if(value.indexOf('.')>-1)
                                {
                                                let splitprize= value.split(".");
                                                if(splitprize[1]==undefined)
                                                {
                                                splitprize[1]='00'
                                                }
                                                if(splitprize[1].length==1)
                                                {
                                                splitprize[1]=splitprize[1]+"0";
                                                }
                                                value=splitprize[0]+'.'+splitprize[1];
                                }else{
                                                if(value !=='N/A'){
                                                                value=value+'.00';
                                                }
                                }
                }
    if(value.toString().length==8)
    {
      let a = value.toString();;
      let b = ",";
      let position = 2;
      let output = [a.slice(0, position), b, a.slice(position)].join('');
      return output
    }else{
      return value;
    }
   
  }

                checkDuplicate(arr, name) {
                                return arr.some(function (el) {
                                                return el.name == name;
                                });
                }
                showHide()
                {
                                if($('.panel-collapse').hasClass('show'))
                                {
                                                this.textHideShow='HIDE'
                                }else{
                                                this.textHideShow='SHOW'
                                }
                }

                backToFacilities(){
                                let closepopup: HTMLElement = this.bookerrormsgpopupclose.nativeElement as HTMLElement;
                                closepopup.click();
                                // this.back();
                                // if(!sessionStorage.getItem('tabview')){
                                //            sessionStorage.setItem('tabview', '1');
                                // }
                                
                                this.bydetails = 0;
                }
                backToFacilitieslistPop()
                {
                                let closepopup: HTMLElement = this.bookerrormsgpopupclose.nativeElement as HTMLElement;
                                closepopup.click();
                                this.bylisting = 0;
                }
                
                redirectTobuyNowFromPopup(){
                                let closepopup: HTMLElement = this.bookerrormsgpopupclose.nativeElement as HTMLElement;
                                closepopup.click();
                                sessionStorage.setItem('alreadyShowPopup', '1');
                                this.router.navigate([this.bydetailsurl]);
                                
                } 
                redirectTobuyNowFromPopuplisting(){
                                let closepopup: HTMLElement = this.bookerrormsgpopupclose.nativeElement as HTMLElement;
                                closepopup.click();
                                sessionStorage.setItem('alreadyShowPopup', '1');
                                this.router.navigate([this.bylistingurl]);
                                
                }

// call first five
                
                updateInventoryOnSidebar(facilitiesData){
                                this.getPStartDateForNeighbourhood();
                                if (this.searchCriteria.type == 'daily') {
                                                //$('.distancesort').addClass("disabledBtn");
                                }
                                for( let i = 0; i < this.searchResult.facilities.length; i++ ){
                                                this.facilities_IdArr[i] = facilitiesData[i].facility_id;
                                                if(i==4)
                                                {
                                                                break;
                                                }
                                }
                                let is_bonus_hour_applied = '';  
                                let bonus = is_bonus_hour_applied ? 1 : 0;
                  let        queryString = "facilities=" + this.facilities_IdArr + "&";
                                queryString += "arrival_time=" + this.searchCriteria.currdate+ "&";
                                queryString +="length_of_stay="+ this.searchCriteria.length_of_stay+ "&";
                                queryString +="use_bonus="+bonus;
                                this.checkInventoryFunction(queryString,0);
                                let m=0;
                                for(let k=5;k<this.searchResult.facilities.length;)
                                {
                                                m=k+3;
                                                if(m>=this.searchResult.facilities.length)
                                                {
                                                                m=this.searchResult.facilities.length-1
                                                }
                                                this.updateInventoryOnSidebarforRemaining(k,m);
                                                k=k+4;
                                }
                }

                updateInventoryOnSidebarforRemaining(val1,val2){
                                let k=0;
                                this.facilitiesAll_IdArr.length = 0; 
                                                for( let i = val1; i <=val2; i++ ){
                                                                this.facilitiesAll_IdArr[k] = this.searchResult.facilities[i].facility_id;
                                                                k++;
                                                }
                                                let is_bonus_hour_applied = '';  
                                  let bonus = is_bonus_hour_applied ? 1 : 0;
                                                let           queryString = "facilities=" + this.facilitiesAll_IdArr + "&";
                                queryString += "arrival_time=" +  this.searchCriteria.currdate+ "&";
                                queryString +="length_of_stay="+ this.searchCriteria.length_of_stay+ "&";
                                queryString +="use_bonus="+bonus;
                                                this.checkInventoryFunction(queryString,val2);
                                                //this.progress.done();
                                }
                                
                                
                                checkInventoryFunction(queryString,last){
                                //this.progress.done();
                                this.searchPageObj.getInventoryUpdatedForSidebar(queryString).then(
                                                (res) => {
                                                                this.updatedInventoryData = res;
                                                                this.updatedInventoryData = this.updatedInventoryData.data;                   
                                                                this.arrayPushData(this.updatedInventoryData);
                                                                for ( let i=0; i < this.updatedInventoryData.length; i++ ){
                                                                                $('.onfacilityload_'+this.updatedInventoryData[i].facility_id).addClass('hide');
                                                                                $('.onfacilityloadshow_'+this.updatedInventoryData[i].facility_id).removeClass('hide');
                                                                                $('.coupon_threshold_price').text(this.updatedInventoryData[i].coupon_threshold_price);
                                                                                if(this.updatedInventoryData[i].availability!='0' ){
                                                                                                if(this.updatedInventoryData[i].ticketech_id=='' && (this.updatedInventoryData[i].parkonect_id=='' || this.updatedInventoryData[i].parkonect_id==null))
                                                                                                {
                                                                                                                let text='';
                                                                                                                if(this.updatedInventoryData[i].price!='N/A')
                                                                                                                {
                                                                                                                                text = '<span> '+this.changeRateUpdatedforsort(this.updatedInventoryData[i].price)+'</span>';
                                                                                                                }else{
                                                                                                                                text = '<span> '+this.changeRateUpdatedforsort(this.updatedInventoryData[i].price)+'</span>';
                                                                                                                }
                                                                                                                
                                                                                                                $('.sorting_'+this.updatedInventoryData[i].facility_id).html(text);
                                                                                                                $('.hidden_Price_'+this.updatedInventoryData[i].facility_id).text(this.updatedInventoryData[i].price);
                                                                                                                $('.sorting_'+this.updatedInventoryData[i].facility_id).addClass('disablebtntoclick');
                                                                                                                $('.hidden_Price_'+this.updatedInventoryData[i].facility_id).addClass('disablebtntoclick');
                                                                                                                
                                                                                                }else{
                                                                                                                if(this.updatedInventoryData[i].price!='N/A')
                                                                                                                {
                                                                                                                                let text = '<span> '+this.changeRateUpdatedforsort(this.updatedInventoryData[i].price)+' | Book Now</span>';
                                                                                                                                $('.sorting_'+this.updatedInventoryData[i].facility_id).html(text);
                                                                                                                                $('.hidden_Price_'+this.updatedInventoryData[i].facility_id).text(this.updatedInventoryData[i].price);
                                                                                                                                $('.hidden_Price_'+this.updatedInventoryData[i].facility_id).removeClass('disablebtntoclick');
                                                                                                                                $('.sorting_'+this.updatedInventoryData[i].facility_id).removeClass('disablebtntoclick');
                                                                                                                }else{
                                                                                                                                $('.sorting_'+this.updatedInventoryData[i].facility_id).addClass('hide');
                                                                                                                                $('.narecord_'+this.updatedInventoryData[i].facility_id).removeClass('hide')
                                                                                                                                $('.hidden_Price_'+this.updatedInventoryData[i].facility_id).text('N/A');
                                                                                                                }
                                                                                                }
                                                                                                
                                                                                }
                                                                                if(this.updatedInventoryData[i].availability=='0' ){
                                                                                                if(this.updatedInventoryData[i].ticketech_id=='' && (this.updatedInventoryData[i].parkonect_id=='' || this.updatedInventoryData[i].parkonect_id==null))
                                                                                                {
                                                                                                                let text='';
                                                                                                                if(this.updatedInventoryData[i].price_without_availibility!='N/A')
                                                                                                                {
                                                                                                                                text = '<span> '+this.changeRateUpdatedforsort(this.updatedInventoryData[i].price_without_availibility)+'</span>';
                                                                                                                }else{
                                                                                                                                text = '<span> '+this.changeRateUpdatedforsort(this.updatedInventoryData[i].price)+'</span>';
                                                                                                                }
                                                                                                                
                                                                                                                $('.sorting_'+this.updatedInventoryData[i].facility_id).html(text);
                                                                                                                $('.sorting_'+this.updatedInventoryData[i].facility_id).addClass('disablebtntoclick');
                                                                                                                
                                                                                                                $('.hidden_Price_'+this.updatedInventoryData[i].facility_id).text(this.updatedInventoryData[i].price);
                                                                                                                $('.hidden_Price_'+this.updatedInventoryData[i].facility_id).addClass('disablebtntoclick');
                                                                                                }else{
                                                                                                                if(this.updatedInventoryData[i].price_without_availibility!='N/A')
                                                                                                                {
                                                                                                                                let text = '<span> '+this.changeRateUpdatedforsort(this.updatedInventoryData[i].price_without_availibility)+' | Book Now</span>';
                                                                                                                                //let text ='112'

                                                                                                                                $('.sorting_'+this.updatedInventoryData[i].facility_id).html(text);
                                                                                                                                $('.sorting_'+this.updatedInventoryData[i].facility_id).addClass('mobile-disable-price');
                                                                                                                                $('.hidden_Price_'+this.updatedInventoryData[i].facility_id).removeClass('disablebtntoclick');
                                                                                                                                $('.sorting_'+this.updatedInventoryData[i].facility_id).removeClass('disablebtntoclick');
                                                                                                                $('.hidden_Price_'+this.updatedInventoryData[i].facility_id).text(this.updatedInventoryData[i].price_without_availibility);
                                                                                                                }else{
                                                                                                                                $('.sorting_'+this.updatedInventoryData[i].facility_id).addClass('hide');
                                                                                                                                $('.narecord_'+this.updatedInventoryData[i].facility_id).removeClass('hide')
                                                                                                                                $('.sorting_'+this.updatedInventoryData[i].facility_id).removeClass('mobile-disable-price');
                                                                                                                                $('.hidden_Price_'+this.updatedInventoryData[i].facility_id).text('N/A');
                                                                                                                }
                                                                                                
                                                                                }
                                                                                
                                                                }
                                                                if (this.router.url.indexOf('neighborhoods') > -1) {
                                                                                if(sessionStorage.getItem('FirstLoad')){
                                                                                                setTimeout(() => {
                                                                                                                $('.distancesort').removeClass("disabledBtn");
                                                                                                }, 10000);
                                                                                                sessionStorage.removeItem('FirstLoad');
                                                                                }
                                                                                else{
                                                                                                setTimeout(() => {
                                                                                                                $('.distancesort').removeClass("disabledBtn");
                                                                                                }, 5000);
                                                                                }
                                                                }
                                                                
                                                                if(this.allInventoryData.length>=this.searchResult.facilities.length)
                                                                {
                                                                                this.mergeArray();
                                                                                $('.distancesort').removeClass("disabledBtn");
                                                                                this.checkInventor=1;
                                                                }else{
                                                                                setTimeout(() => {
                                                                                                $('.distancesort').removeClass("disabledBtn");
                                                                                }, 15000);
                                                                                this.checkInventor=1;
                                                                }
                                                }
                                                }
                                                );
                                                //this.progress.done();
                
                                }

                arrayPushData(array)
                {
                                for(let i=0;i<array.length;i++)
                                this.allInventoryData.push(array[i])
                }
                mergeArray()
                {
                                let totalinventory=[];
                                let a=0;
                                for(let p=0;p<this.searchResult.facilities.length;p++)
                                {
                                                for(let t=0;t<this.allInventoryData.length;t++)
                                                {
                                                                if(this.searchResult.facilities[p].facility_id==this.allInventoryData[t].facility_id)
                                                                {
                                                                                totalinventory[a]={'inventory' : this.allInventoryData[t],'facility':this.searchResult.facilities[p]}
                                                                                a++;
                                                                                break;
                                                                }
                                                }
                                }
                                
                                this.sortarray_byprice(totalinventory)
                }
                mergeArrayPartners()
                {
                                let totalinventory=[];
                                let a=0;
                                for(let p=0;p<this.searchResult.facilities.length;p++)
                                {
                                                
                                                                
                                                                                totalinventory[a]={'inventory' : {},'facility':this.searchResult.facilities[p]}
                                                                                a++;
                                                                                
                                                
                                }
                                
                                this.sortarray_byprice_partners(totalinventory)
                }
                sortarray_byprice_partners(arr)
                {
                                for(let c=0;c<arr.length;c++)
                                {
                
                                
                                                                if(arr[c].facility.price=='N/A')
                                                                {
                                                                                arr[c].inventory.showprice='N/A';
                                                                                arr[c].inventory.showpriceSort='2000.00'
                                                                }else{
                                                                                arr[c].inventory.showprice=arr[c].facility.price
                                                                                arr[c].inventory.showpriceSort=arr[c].facility.price
                                                                }
                                
                                                arr[c].inventory.is_gray=0;
                                                arr[c].ticketech_id=arr[c].facility.ticketech_id
                                                arr[c].parkonect_id=arr[c].facility.parkonect_id
                                }
                                this.facilitylistprice=arr
                                this.pricesortingdone=0;
                                this.distancesortingloader=0;
                }
                sortarray_byprice(arr)
                {
                                for(let c=0;c<arr.length;c++)
                                {
                                                if(arr[c].inventory.availability<=0)
                                                {
                                                                if(arr[c].inventory.price_without_availibility=='N/A')
                                                                {
                                                                                arr[c].inventory.showprice='N/A';
                                                                                arr[c].inventory.showpriceSort='20000.00'
                                                                }else{
                                                                                arr[c].inventory.showprice=arr[c].inventory.price_without_availibility
                                                                                arr[c].inventory.showpriceSort=arr[c].inventory.price_without_availibility
                                                                }
                                                                
                                                }else{
                                                                if(arr[c].inventory.price=='N/A')
                                                                {
                                                                                arr[c].inventory.showpriceSort='20000.00';
                                                                                arr[c].inventory.showprice='N/A';
                                                                }else{
                                                                                arr[c].inventory.showprice=arr[c].inventory.price
                                                                                arr[c].inventory.showpriceSort=arr[c].inventory.price
                                                                }
                                                }
                                                if(arr[c].inventory.availability<=0 && arr[c].inventory.price_without_availibility!='N/A')
                                                {
                                                                arr[c].inventory.is_gray=1
                                                }else{
                                                                arr[c].inventory.is_gray=0;
                                                }
                                                arr[c].ticketech_id=arr[c].inventory.ticketech_id
                                                arr[c].parkonect_id=arr[c].inventory.parkonect_id
                                }
                                this.facilitylistprice=arr
                                this.pricesortingdone=0;
                                this.distancesortingloader=0;
                }
                
                PartnerPagePostedRate(facId, arrival_time, length_of_stay, is_bonus_hour_applied) {
                                let bonus = is_bonus_hour_applied ? 1 : 0;
                                let queryString = facId + "?";
                                queryString += "arrival_time=" + arrival_time + "&";
                                queryString += "length_of_stay=" + length_of_stay + "&";
                                queryString += "use_bonus=" + bonus;
                                this.searchPageObj.fetchUpdatedBookingRates(queryString).then(
                                                (res) => {
                                                                this.boardRate = [];
                                                                let record = [];
                                                                this.checkupdatedPriceForPartner = true;
                                                                this.partnerPostedRate = res;
                                                                this.facilitynameforpostal = this.partnerPostedRate.data.facility.facility_name_modified;
                                                                this.facilitynameforpostaladdress = this.partnerPostedRate.data.facility.entrance_location;
                                                                this.taxRate = this.partnerPostedRate.data.facility.tax_rate * 100;
                                                                record = this.partnerPostedRate.data.facility.facility_on_board_rate;
                                                                let cat: any = 0;
                                                                let m = 0;
                                                                let rate = [];
                                                                let records = []
                                                                let name = '';
                                                                for (let i = 0; i < record.length; i++) {
                                                                                rate[m] = record[i]
                                                                                m++;
                                                                }
                                                                let p = 0;
                                                                for (let i = 0; i < record.length; i++) {
                                                                                name = record[i].category.category
                                                                                if (!this.checkDuplicate(records, name)) {
                                                                                                records[p] = { 'name': name, 'rates': rate }
                                                                                                p++;
                                                                                }

                                                                }
                                                                this.boardRate = records
                                                              },
                                                              			(error) => {
                                                              				this.checkupdatedPriceForPartner = false;
                                                                }

                                )

                }
                showtimefornxtornot(text)
                {
                                var res = text.split(':');
                                if(res[0]>24)
                                {
                                                return '(next day)'
                                }else{
                                                return '';
                                }
                }

                checkData(var1,var2)
  {
                                                let t=this.checkAvaillity_campaign_remaining( parseInt(var1),parseInt(var2))
                                if(t>0)
                                {
                                return '40%';
                                }if(t<0 || t==0)
                                {
                                return '40%';
                                }
                                else{
                                return '80%'
                                }
    
  }
  convertIntoInteger(val)
  {
    return(parseInt(val))
  }

  checkAvaillity_campaign_remaining(totalAvailable, usedAvailable){
                let val =  parseInt(totalAvailable) - parseInt(usedAvailable);
                return val 
                 }
                checkAvaillity_campaign_remaining_text(totalAvailable, usedAvailable,text)
                {
                let val =  parseInt(totalAvailable) - parseInt(usedAvailable);
                let newText='';
                if(val>1)
                {
                   newText=text.replace('number Space',val+' Spaces')
                }else{
                   newText=text.replace('number',val)
                }
                
                return newText;
                }
                sort(val)
                {
                                if(val == 'distance'){
                                                $('.campaignSortingDistance').removeClass('active').addClass('active');
                                                $('.campaignSortingPrice').removeClass('active');
                                }else {
                                                $('.campaignSortingPrice').removeClass('active').addClass('active');
                                                $('.campaignSortingDistance').removeClass('active');
                                }
                  this.sortingType=val
                }


                buyMonthlyCampaign(monthlyRate, facID, netPrice, TaxPrice, IsMonthly_campaign){

               // ga('send', 'event', { eventCategory: "Book Now, Monthly", eventLabel:CustomCode.BASEURL+'/search', eventAction: 'click'});      

                                if(0)
                                {
                                                localStorage.setItem('clickBuyMonthly','1');
                                                let facilityId = facID;
                                                let mD = $('#monthlydate').val().toString().split('/');
                                                                                let fDate = mD[2] + '-' + mD[0] + '-' + mD[1];
                                                                                //this.router.navigate([routeNavigate, facId, fDate]);
                                                                                localStorage.setItem('monthlyParkingId',facilityId)
                                                                                localStorage.setItem('monthlyParkingDate',fDate)
                                                                                $("a#loginPopup")[0].click();
                                }else {
                                                // kochava.activity('clickBuyMonthlyAccountByCampaign', {
                                //   //user_name: this.respdata.display_name,                      // Name or ID is required.
                                //   id: facID,
                                //   price: monthlyRate,
                                //   category: 'Monthly_Campaign',
                                //   });
                
                                

                                sessionStorage.setItem('campaignMonthlyRate', monthlyRate);
                                sessionStorage.setItem('campaignNetPrice', netPrice);
                                sessionStorage.setItem('campaignTaxPrice', TaxPrice);
                                sessionStorage.setItem('isMonthlyCampaign', IsMonthly_campaign);
                                let Cdate = this.searchCriteria.currdate.replace(/-/g,'/').split(' ');
                                //  let mD = $('#monthlydate').val().toString().split('/');
                                //  let fDate = mD[2] + '-' + mD[0] + '-' + mD[1];
                                //  let currentDate = Cdate[0].split('/')
                                //  let date = currentDate[2]+'-'+currentDate[0]+'-'+currentDate[1];
                                let mD=new Date($('#monthlydate').val());
                                                //const mDDormat = $('#monthlydate').val().toString().split('/');
                                const fDate = mD.getFullYear()+'-'+((mD.getMonth() > 8) ? (mD.getMonth() + 1) : ('0' + (mD.getMonth() + 1))) + '-' + ((mD.getDate() > 9) ? mD.getDate() : ('0' + mD.getDate()));
                                this.router.navigate(['/buy-monthly/'+ facID + '/' + fDate]);
                                }
    
                  }

                // fetchMonthlyCampaign(){
                //            this.searchCriteriaCampaign.arrival_time = this.searchCriteria.currdate
                //            this.fetch_monthly_campaign();
                //            this.searchPageObj.getFacilityMonthlyCampaign(this.searchCriteriaCampaign)
                //                                            .subscribe(
                //                                            data => {
                //                            this.LoadCampaignFacilities = true;
                //                            this.searchResultCampaign = data.data.facilities;
                //                            if(data.data.facilities.length>0)
                //                            {
                //                                            this.existCampaignData=1;
                //                                            this.showCampaignDeals = false
                //                                            localStorage.setItem('showDeals', '1');
                //                            }else{
                //                                            this.existCampaignData=0;
                //                                            this.showCampaignDeals = false
                //                                            localStorage.removeItem('showDeals');
                //                            }
                                                
                //                                            },
                //                                            error => {
                //                                            this.toasterObj.error(error.errors.message, 'Sorry!');
                //                                            this.LoadCampaignFacilities = true;
                //                                            }
                //                            );
                // }

                // showDeals() {
                //            this.back();
                //            this.showCampaignDeals = false;
                //            this.isCollapsed = true
                // }
                // hideDeals(){
                //            // this.showCampaignDeals = false
                //            sessionStorage.removeItem('tabview')
                //            this.parkList = false;
                //            this.sidebarOn = false;
                //            localStorage.removeItem('showDeals');
                // }

                // fetch_monthly_campaign(){
                //            this.StaticpagesService.getMonthlyCampaignContent().subscribe(
                //              data => {
                //              this.campaignDetails  = data;
                //                            this.campaignDetails = this.campaignDetails.result[0];
                //               this.campaignTitle = this.campaignDetails.campaign_title;
                //               this.campaignFooter = this.campaignDetails.campaign_footer;
                //               this.thresold=this.campaignDetails.threshold;
                //               this.spaceLeftMessage = this.campaignDetails.space_message;
                //              let default_neighbour = this.campaignDetails.default_neighborhood.split('?');                     
                //            //   let activeNeighbourhoodTitle = default_neighbour[1];
                //            //    activeNeighbourhoodTitle = activeNeighbourhoodTitle.toUpperCase();
                //               this.campaignDescription = this.campaignDetails.campaign_desc.replace("{{neighbour hood name}}",'');
                                   
                //              }
                //              , (error) => { console.log('Error: ' + JSON.stringify(error)); 
                //            }
                //            )
                //            }

                                checkForEnrollNew(event ){
                                                const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                                if(event.target.checked){
                                                                if( 'ucmail' in this.mailFormData && this.mailFormData.ucmail !=''){
                                                                                if(pattern.test(this.mailFormData.ucmail)){
                                                                                                this.showEnrollForm = true;
                                                                                                this.checkedCheckbox = true;
                                                                                                this.hideSendEmailButton = true
                                                                                }else {
                                                                                                this.toasterObj.error('The email must be a valid email address.', 'Error');
                                                                                setTimeout(() => {
                                                                                                document.getElementById('enrolledInCheck').click();
                                                                                }, 1000);
                                                                                }
                                                                                
                                                                }else {
                                                                                this.toasterObj.error('Please enter your Email', 'Error');
                                                                                setTimeout(() => {
                                                                                                document.getElementById('enrolledInCheck').click();
                                                                                }, 1000);
                                                                }
                                                }else {
                                                                this.hideSendEmailButton = false
                                                                this.showEnrollForm = false;
                                                                this.checkedCheckbox = false;
                                                                this.userData.first_name = '';
                                                                this.userData.last_name = '';
                                                                this.userData.phone = '';
                                                                this.userData.password = '';
                                                                this.userData.cpassword = '';
                                                                this.userData.enrolledIn = false
                                                }
                                }
                                checkForEnrollNew2(event ){
                                                const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                                if(event.target.checked){
                                                                if( 'ucmail' in this.mailFormData && this.mailFormData.ucmail !=''){
                                                                                if(pattern.test(this.mailFormData.ucmail)){
                                                                                                this.showEnrollForm = true;
                                                                                                this.checkedCheckbox = true;
                                                                                                this.hideSendEmailButton2 = true;
                                                                                }else {
                                                                                                this.toasterObj.error('The email must be a valid email address.', 'Error');
                                                                                setTimeout(() => {
                                                                                                document.getElementById('enrolledInCheck2').click();
                                                                                }, 1000);
                                                                                }
                                                                                
                                                                }else {
                                                                                this.toasterObj.error('Please enter your Email', 'Error');
                                                                                setTimeout(() => {
                                                                                                document.getElementById('enrolledInCheck2').click();
                                                                                }, 1000);
                                                                }
                                                }else {
                                                                this.hideSendEmailButton2 = false
                                                                this.showEnrollForm = false;
                                                                this.checkedCheckbox = false;
                                                                this.userData.first_name = '';
                                                                this.userData.last_name = '';
                                                                this.userData.phone = '';
                                                                this.userData.password = '';
                                                                this.userData.cpassword = '';
                                                                this.userData.enrolledIn = false
                                                                                
                                                }
                                                
                                }

                                phoneFormat(event: any) {
                                                const pattern = /[0-9\)\-\(\.\ ]/;
                                                let inputChar = String.fromCharCode(event.charCode);
                                                if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
                                                  event.preventDefault();
                                                }
                                  }

                                moveToNext(userData, formRef: NgForm) {
                                                window.scrollTo(0, 0);
                                                $("body").animate({ scrollTop: 0 }, 0);
                                                let Data = {
                                                  name: this.userData.first_name + ' ' + this.userData.last_name,
                                                  email: this.mailFormData.ucmail,
                                                  password: this.userData.password,
                                                  cpassword: this.userData.cpassword,
                                                  phone: this.userData.phone,
                                                  is_loyalty: this.userData.enrolledIn,
                                                  invitation_code: this.userData.promocode,
                                                  iq_code:(sessionStorage.getItem('iq_code')!==null)?sessionStorage.getItem('iq_code'):'',
                                                }
                                                if(Data.phone != undefined && Data.phone != ''){
                                                  let ph = Data.phone.replace(/\D/g, '');
                                                  if(ph.length !==0 && ph.length < 10){
                                                                formRef.form.controls['phone'].setErrors({'invalid': true});
                                                                return false;
                                                  }
                                                }
                                
                                                if (Data.password === undefined || Data.password === '') {
                                                  this.passwordErrMsg = 'Password is required';
                                                  formRef.form.controls['password'].setErrors({'invalid': true});
                                                  return false;
                                                }else if(Data.password.length < 8){
                                                  this.passwordErrMsg = 'Password must be atleast 8 characters long';
                                                  formRef.form.controls['password'].setErrors({'invalid': true});
                                                  return false;
                                                }else if(Data.password !== Data.cpassword){
                                                  formRef.form.controls['cpassword'].setErrors({'invalid': true});
                                                  return false;
                                                }else if(!Data.is_loyalty){
                                                  formRef.form.controls['enrolledIn'].setErrors({'invalid': true});
                                                  return false;
                                                }
                                let phoneRegix = "(___) ___-____";
                                if(Data.phone.includes(phoneRegix)  ){
                                                Data.phone = '';
                                }
                                                this.userServiceObj.registerLoyaltyUser(Data)
                                                  .subscribe(
                                                                data => {
                                                                  let resData = data;
                                                                  localStorage.setItem('isInvAsso', '1');
                                                                  // localStorage.setItem('isFirstRegister', resData.data.is_new_user); // commented for new loyalty changes IPSD-1776
                                                                  /* #start login user after registration */
                                                                  const userVal = {
                                                                                user: {
                                                                                  name: resData.data.user.name,
                                                                                  email: resData.data.user.email,
                                                                                  id: resData.data.user.id,
                                                                                  phone: resData.data.user.phone,
                                                                                  is_loyalty: resData.data.user.is_loyalty,
                                                                                  is_loyalty_active: resData.data.user.is_loyalty_active
                                                                                },
                                                                                access_token: resData.data.session.access_token,
                                                                                refresh_token: resData.data.session.refresh_token,
                                                                                expires_in: resData.data.session.expires_in,
                                                                                token_type: resData.data.session.token_type,
                                                                  }
                                
                                                                  CustomCode.markLoginUser(userVal);
                                                                  sessionStorage.setItem('enrollFromCoupon', '1');
                                                                  document.getElementById('submitEmailCoupon').click();

                                                                  /* #end login user after registration */
                                                                  if (sessionStorage.getItem('event_for_loyalty') == '1') {
                                                                                let eventlabel = sessionStorage.getItem('eventlabel');
                                                                                let eventcategory = sessionStorage.getItem('eventCategory');
                                                                               // ga('send', 'event', { eventCategory: eventcategory + " Inactive", eventAction: 'Click', eventLabel: eventlabel, eventValue: '10' });
                                                                                sessionStorage.removeItem('event_for_loyalty');
                                                                                sessionStorage.removeItem('eventlabel');
                                                                                sessionStorage.removeItem('eventCategory');
                                                                  }
                                                                 // ga('send', 'event', { eventCategory: 'Enroll User Successfull', eventAction: 'click' });
                                                                  (<any>window).dataLayer.push({ 'event': 'rewardsEnrolled-Inactive' });
                                                                  if(this.isPartners){
                                                                                this.toasterObj.success('You have successfully enrolled in Icon Rewards', 'Great!');
                                                                                $('.closeModelpoup').click();
                                                                                //window.location.reload();
                                                                  }else {
                                                                                this.router.navigate(['my-account/account']);
                                                                  }
                                                                  
                                                                  // window.location.href = '/icon-rewards-register'; // commented for new loyalty changes IPSD-1776
                                                                },
                                                                error => {
                                                                  const errorsList = error.errors;
                                                                  if (errorsList.message !== 'Validation Errors') {
                                                                                this.toasterObj.error(errorsList.message, 'Sorry!', { enableHtml: true, toastClass: 'toast register-toaster' });
                                                                  } else {
                                                                                for (const key in errorsList.detail) {
                                                                                  if (errorsList.detail.hasOwnProperty(key)) {
                                                                                                if (typeof errorsList.detail === 'undefined') {
                                                                                                  this.toasterObj.error(errorsList.message, 'Sorry!', { enableHtml: true, toastClass: 'toast register-toaster' });
                                                                                                } else {
                                                                                                  this.toasterObj.error(errorsList.detail[key], 'Sorry!', { enableHtml: true, toastClass: 'toast register-toaster' });
                                                                                                }
                                                                                  }
                                                                                }
                                                                  }
                                                                }
                                                  );
                                  }

                                  restChar(event: any) {
                                                if (!CustomCode.restricSpecialChar(event)) {
                                                  event.preventDefault();
                                                }
                                  }
                                
                                  onPaste(event: any) {
                                                if (!CustomCode.restricPaste(event)) {
                                                  event.preventDefault();
                                                }
                                  }
                                  restSpace(event: any){
                                                if (event.keyCode == 32) {
                                                  event.preventDefault();
                                                }
                                  }

                                  confirm(): void {
                                                this.isAgreed = true;
                                                this.userData.enrolledIn = true;
                                                this.modalRef.hide();
                                  }
                                
                                  decline(): void {
                                                this.userData.enrolledIn = false;
                                                this.isAgreed = false;
                                                this.modalRef.hide();
                                  }
                                
                                  openTermModalWithCheck(AcceptTerms: TemplateRef<any>, event) {
                                                if (event.target.checked) {
                                                  this.modalRef = this.modalService.show(AcceptTerms, this.config);
                                                }
                                                this.userData.enrolledIn = false;
                                                return true;
                                  }
                                
                                  openTermModal(AcceptTerms: TemplateRef<any>) {
                                                this.modalRef = this.modalService.show(AcceptTerms, this.config);
                                                return false;
                                  }
                                
                                  closeModel() {
                                                this.modalRef.hide();
                                                if (!this.isAgreed) {
                                                  this.userData.enrolledIn = false;
                                                }
                                  }

                                  cancelEnrollForm(){
                                                  document.getElementById('enrolledInCheck').click();
                                                  document.getElementById('enrolledInCheck2').click();
                                  }

                                  checkCouponShow(){
                                                  this.couponShow = 1;
                                  }

                                  checkBookShow(){
                                                  this.bookShow = 1;
                                  }
                                  chnageText(val)
                                  {
                                                  if(1)
                                                  {
                                                                  if(val!=undefined)
                                                                  {
                                                                                return val.replace("LLC", "LLC.")
                                                                  }else{
                                                                                  return val
                                                                  }
                                                  }else{
                                                                  return val
                                                  }
                                  }
                                  formatPhoneNumber(phoneNumberString) {
                                    if(phoneNumberString !== '' && phoneNumberString != undefined){
                                      phoneNumberString = phoneNumberString.trim().replace('-', '').replace('(', '').replace(')', '');
                                                var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
                                                var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
                                                if (match) {
                                                //   var intlCode = (match[1] ? '+1 ' : '')
                                                let fcontact = [ '(', match[2], ') ', match[3], '-', match[4]].join('');
                                                  return fcontact;
                                                }
                                                return null
                                    }
                                                
                                  }
                                  updateRecordsOnLabelChange(type){

                                  }
                                  reloadMap(location) {
                                                this.activeNeighbourhood = location;
                                  //               if ($(window).width() < 768  ) {
                                  //                 localStorage.setItem('showNeigborMarker2', '1');
                                  // }
                                                //this.activeNeighbourhood
                                                localStorage.setItem('activeNeighbourhood', location)
                                                localStorage.setItem('clickNeighborhood', location)
                                                this.fetchSingleNeighbourhoodData(location)
                                                if(this.searchCriteria.type =='monthly')
                                                {
                                                                //this.types='monthly';
                                                }
                                                if ($(window).width() < 768  ) {
                                                                localStorage.setItem('showNeigborMarker', '1');
                                                                localStorage.setItem('showNeigborMarker2', '1');
                                                }
                                  }

                                  updateTier(showBadge){
                                                this.badgePad = showBadge;
                                  }
                                  priceReturn(price)
                                  {
                                                if(price=='N/A' || price==2500000 || price== 2500001)
                                                {
                                                                return 'N/A'
                                                }else{
                                                                return '$'+price;
                                                }
                                  }

                                  fetchSearchpageCOntentFromCMS(){
                                                this.StaticpagesService.getCmsPageContent(this.pageSlugForSidebarContent).subscribe((data:any) => {
                                                                this.CMSsidebarContent = data.result[0];
                                                  }, (error) => {
                                                  }
                                                  );
                                  }
                                                  
                                  showSavingFlexibilityMsg(msg){
                                                if(msg){
                                                  let string = msg.toLowerCase()
                                                  if(string.includes("6 month") || string.includes("6 months") ){
                                                                return true;
                                                  }
                                                }
                                                
                                                //
                                  }
                                  
                                  makeSelectableMontheke(selectedvahicletype,rate_type){
                                                if( selectedvahicletype===rate_type ){
                                                                $('.activeMontlyPlan').click();
                                                }else {
                                                                
                                                }
                                  }
                                  mouseEnter(val,val1)
                                  {
                                                this.popUP=$('#'+val+''+val1).val();
                                  }

                                  fetchNeighbourhoodsData() {
                                                
                                                this.searchPageObj.getNeighbourhoods()
                                                  .subscribe(
                                                  data => {
                                                                
                                                                if (data.data) {
                                                                                for (let i = 0; i < data.data.length; i++) {
                                                                                                this.neighbourhoods =  data.data;
                                                                                // this.firstLoad = true;
                                                                                
                                                                  }
                                                                  this.fetchSingleNeighbourhoodData(this.activeNeighbourhood)
                                                                } else {
                                                                  this.toasterObj.error('Error Occured While Loading Neighborhoods', 'Sorry!');
                                                                }
                                                  },
                                                  error => {
                                                                this.toasterObj.error(error.error.message, 'Sorry!');
                                                  }
                                                );
                                  }

                                  fetchSingleNeighbourhoodData(activeNeighbourhood){
                                                  
                                                if (this.neighbourhoods) {
                                                             //   console.log(this.neighbourhoods)
                                                                for (let i = 0; i < this.neighbourhoods.length; i++) {
                                                                                if(this.neighbourhoods[i].slug == activeNeighbourhood){
                                                                                                
                                                                                                this.activeNeighbourhoodTitle = this.neighbourhoods[i].title;
                                                                                                this.searchCriteria.lat= this.neighbourhoods[i].latitude
                                                                                                this.searchCriteria.long = this.neighbourhoods[i].longitude
                                                                                                // this.searchCriteria.label = this.neighbourhoods[i].title                                
                                                                                                this.searchCriteria.label = activeNeighbourhood 
                                                                                                                                                                
                                                                                }
                                                                }
                                                                
                                                                let type='monthly'
                                                                let is_true=0;
                                                                let is_from_home_landing=0;
                                                                if(localStorage.getItem('fromhomelanding'))
                                                                {
                                                                                is_from_home_landing=1
                                                                }
                                                                localStorage.setItem('searchLocation',activeNeighbourhood)
                                                                if(this.router.url.indexOf('monthly-parking')>-1)
                                                                {
                                                                                if(this.router.url.indexOf('daily')>-1)
                                                                                {
                                                                                                type='daily'
                                                                                }else{
                                                                                                type='monthly'
                                                                                }
                                                                                let p =this.router.url.split('/');
                                                                                if(p.length>2){
                                                                                                is_true=1
                                                                                }else{
                                                                                                is_true=1
                                                                                }
                                                                }
                                                                if(this.router.url.indexOf('search')>-1)
                                                                {
                                                                                is_true=0
                                                                }
                                                                
                                                                if(is_true)
                                                                {
                                                                                if(is_from_home_landing==0)
                                                                                {
                                                                                                this.router.navigate(['/monthly-parking',activeNeighbourhood,type]);
                                                                                }else{
                                                                                                localStorage.removeItem('fromhomelanding')
                                                                                                this.router.navigate(['/monthly-parking']);
                                                                                }
                                                                                //alert(12);
                                                                                this.updateNeighborhoodFilters.emit(this.searchCriteria);
                                                                }else{
                                                                                this.router.navigate(['/search', this.searchCriteria.lat, this.searchCriteria.long, CustomCode.getDateForMapSearchSlashFormatForUrl($('#startDateWithID').val()), 2, this.searchCriteria.label,this.searchCriteria.type,CustomCode.searchMode()]);
                                                                                this.updateNeighborhoodFilters.emit(this.searchCriteria);
                                                                }
                                                                
                                                                

                                                }

                                  }

                                  updateSortSelected(type){
                                                if(type === 'distance'){
                                                                $("#sortingFilterList").val("distance");
                                                }else {
                                                                if(type === 'price'){
                                                                                $("#sortingFilterList").val("price");
                                                                }
                                                }
                                  }
                                  updateSortBYFilter(type){
                                              //    console.log('get')
                                                $("#sortingFilterList").val(type);
                                  }
                                  hideFromSideIcon(){
                                             //     console.log('here')
                                                                this.sidebarOn1=false;
                                                  this.parkList = true;
                                                                this.hideListView = false;
                                  }

                                  aminitiesCounter(facilityD){
                                                this.aminitiesCount = 1;
                                                let c  = 0;
                                                this.remaining = 0;
                                                                if(facilityD.is_mobile_pay_enabled =='1'){
                                                                                c++
                                                                  }
                                                                  if(facilityD.is_parksafe =='1'){
                                                                                c++
                                                                  }
                                                                  if(facilityD.is_24hour_open =='1'){
                                                                                c++
                                                                  }
                                                                  if(facilityD.is_indoor_parking =='1'){
                                                                                c++
                                                                  }
                                                                  if(facilityD.is_outdoor_parking =='1'){
                                                                                c++
                                                                  }
                                                                  if(facilityD.is_tesla_charging =='1'){
                                                                                c++
                                                                  }
                                                                  if(facilityD.is_generic_ev_charging =='1'){
                                                                                c++
                                                                  }
                                                                  if(facilityD.is_oversize =='1'){
                                                                                c++
                                                                  }
                                                                  if(facilityD.is_motorcycle_parking =='1'){
                                                                                c++
                                                                  }
                                                                  if(facilityD.is_monthly =='1'){
                                                                                c++
                                                                  }
                                                                  return c;
                                                  }                            
                                                  aminitiesCounter2(facilityD){
                                                                let d = 0
                                                                if(facilityD.is_mobile_pay_enabled =='1'){
                                                                                d++
                                                                  }
                                                                  if(facilityD.is_parksafe =='1'){
                                                                                d++
                                                                  }
                                                                  if(facilityD.facility.is_24hour_open =='1'){
                                                                                d++
                                                                  }
                                                                  if(facilityD.facility.is_indoor_parking =='1'){
                                                                                d++
                                                                  }
                                                                  if(facilityD.facility.is_outdoor_parking =='1'){
                                                                                d++
                                                                  }
                                                                  if(facilityD.facility.is_tesla_charging =='1'){
                                                                                d++
                                                                  }
                                                                  if(facilityD.facility.is_generic_ev_charging =='1'){
                                                                                d++
                                                                  }
                                                                  if(facilityD.facility.is_oversize =='1'){
                                                                                d++
                                                                  }
                                                                  if(facilityD.facility.is_motorcycle_parking =='1'){
                                                                                d++
                                                                  }
                                                                  if(facilityD.facility.is_monthly =='1'){
                                                                                d++
                                                                  }
                                                                  return d;
                                                  }                            
                                                  
                                                  scrollToDiv(el){
                                                                
                                                                                // let myDiv = document.getElementsByClassName("parkDetailsWrapperWeb");
                                                                                // myDiv.scrollTop = myDiv.scrollHeight;
                                                
                                                                };

                                                                returnToList(){
                                                                                sessionStorage.removeItem('tabview')
                                                                }
                                                                checkLenght(val)
                                                                {
                                                                                
                                                                                if('facilities' in val)
                                                                                {
                                                                                                if(val.facilities.length>0)
                                                                                                {
                                                                                                                return true;
                                                                                                }else{
                                                                                                 console.log('no facility')
                                                                                                 setTimeout(() => {
                                                                                                  this.showLoader = false;
                                                                                                 }, 500);
                                                                                                                return false
                                                                                                }
                                                                                }
                                                                                return false;
                                                                }


                                                                loadView()
                                                {
                                                                setTimeout(()=>{
                                                                                this.tabList('plist','pview')
                                                                                                localStorage.removeItem('facilityResponse')
                                                                                                //clearInterval(abc)
                                                                                                if(localStorage.getItem('showNeighbordood') || localStorage.getItem('neighbourhoodActive')){
                                                                                                   
                                                                                                  this.activeNeighborhoodFilter = true;
                                                                                                  
                                                                                                  this.parkList = false
                                                                                                  this.hideListView = true
                                                                                                  }else {
                                                                                                                  
                                                                                                                  this.activeNeighborhoodFilter = false;
                                                                                                                  this.parkList = true;
                                                                                                                  this.hideListView = false;
                                                                                                                  // $("#sortingFilterList").val("distance");
                                                                                                                // alert(this.selectedSortType)
                                                                                                                  if(this.selectedSortType=='price' || this.selectedSortType=='Price')
                                                                                                                  {
                                                                                                                                this.selectedSortType='Price'
                                                                                                                  }else{
                                                                                                                                this.selectedSortType='Distance'
                                                                                                                  }
                                                                                                                                
                                                                                                                  
                                                                                                                  this.sortBy(this.selectedSortType)
                                                                                                  }
                                                                },1000)
                                                                
                                                }


                                                findNearMe() {
                                                  // $('.test').trigger('click');
                                                 //  ga('send', 'event', { eventCategory: 'FIND PARKING', eventLabel:CustomCode.BASEURL, eventAction: 'click'});
                                                   const newYorkBounds = new google.maps.LatLngBounds(
                                                       new google.maps.LatLng(40.518673, -74.266017),
                                                       new google.maps.LatLng(40.861947, -73.785519),
                                                       //new google.maps.LatLng(28.702680, 77.428900)
                                                   );
                                               
                                                    if (window.navigator.geolocation) {
                                                       window.navigator.geolocation.getCurrentPosition(position => {
                                                       let userCLat =position.coords.latitude;
                                                       let userCLong =position.coords.longitude;
                                                       $.get("https://maps.googleapis.com/maps/api/geocode/json?latlng="+userCLat+","+userCLong+"&key="+ CustomCode.GlobalGoogleMapAPIKey, ( data ) => {
                                                       
                                                       //alert(userCLong);
                                                   //40.7623737/-73.97391189999996
                                                    //userCLat = 40.7623737;
                                                        //userCLong = -73.97391189999996;
                                                   var myLatlng = new google.maps.LatLng(userCLat, userCLong);
                                                   let exacTime = FilterHelper.defaultStartDurationUrlHomePageData(FilterHelper.changeNyTime());
                                                   const mnts = (this.endTime.getHours() > 12) ? (this.endTime.getHours() - 12 + ':' + this.endTime.getMinutes() + ' PM') : (this.endTime.getHours() + ':' + this.endTime.getMinutes() + ' AM');
                                                   //alert(mnts);
                                                   //alert(this.startDuration);
                                                   let endTimeData = FilterHelper.makeDurationDateObject(this.endDuration,mnts);
                                                   //alert(endTimeData);
                                                   //this.endTime = FilterHelper.getFormattedTime(this.endDuration);
                                                   if(this.toTimestamp(this.startDuration)<this.toTimestamp(exacTime))
                                                   {
                                                     let startDuration = FilterHelper.defaultStartDuration();
                                                     let startTime = FilterHelper.getFormattedTime(startDuration);
                                                     localStorage.setItem('monthlyDate',this.startDuration)
                                                      let endDuration = FilterHelper.defaultEndDuration();
                                                     let endTime = FilterHelper.getFormattedTime(endDuration);
                                                     let endTimeDataIfLess = FilterHelper.makeDurationDateObject(endDuration,endTime);
                                                     //alert(endTimeDataIfLess)
                                                     if(this.toTimestamp(endTimeData)<this.toTimestamp(endTimeDataIfLess))
                                                     {
                                                       //alert(2)
                                                       //localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
                                                     }
                                                     localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(endDuration,endTime));
                                                     localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(startDuration,startTime));
                                                   }
                                                   //let endTimeDataIfLess = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
                                                  
                                                   let lat:any=40.7589
                                                   let long:any =-73.9851
                                                   let location:any ='Times Square'
                                                   let startTime=FilterHelper.defaultStartDuration();
                                                   let startTimes = FilterHelper.getFormattedTime(startTime);
                                                   //alert()
                                                   if(this.router.url.indexOf('search')>-1)
                                                   {
                                                     localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHomeForFindParking($('#startDateWithID').val()));
                                                   }else{
                                                     localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(startTime,startTimes));
                                                   }
                                                   
                                                   
                                                   let stime =localStorage.getItem('startimehome');
                                                   localStorage.removeItem('startimehome');
                                                   if(localStorage.getItem('seachLat'))
                                                   {
                                                     lat =localStorage.getItem('seachLat')
                                                     long =localStorage.getItem('seachLong')
                                                     location =localStorage.getItem('seachLocation')
                                                   }
                                                   if (((data.results[0].formatted_address.indexOf("nY")>-1 || data.results[0].formatted_address.indexOf("Ny")>-1 || data.results[0].formatted_address.indexOf("NY")>-1 || data.results[0].formatted_address.indexOf("ny")>-1) && data.results[0].formatted_address.indexOf("USA")>-1) ||  data.results[0].formatted_address.indexOf("jjhjhjhjh")>-1)
                                                   {
                                                       this.POSuserLat=userCLat;
                                                      this.POSuserLong=userCLong;
                                                      //let url = '/search/'+userCLat+'/'+userCLong+'/'+stime+'/1/'+data.results[0].formatted_address+'/'+this.facilityType+'/'+CustomCode.searchMode()
                                                       //alert(1)
                                                       if(this.router.url!='1')
                                                       {
                                                          //alert(3)
                                                          if(this.router.url.indexOf('monthly-parking')>-1)
                                                          {
                                                            
                                                           
                                                            //$('.test').trigger('click');
                                                           // localStorage.setItem('currentLoc','1')
                                                           this.locationIcon()
                                                            
                                                          }
                                                         if(this.router.url.indexOf('search')>-1)
                                                         {
                                                           this.locationIcon()
                                                          
                                                           //$('.test').trigger('click');
                                                          // localStorage.setItem('currentLoc','1')
                                                          localStorage.setItem('datafromlocation','1')
                                                          
                                                           
                                                         }
                                                         
                                                         
                                                         let typeMode=this.settypedata
                                                         if(this.router.url.indexOf('search')>-1)
                                                         {
                                                           if(this.router.url.indexOf('daily')>-1)
                                                           {
                                                             typeMode='daily';
                                                           }else{
                                                             typeMode='monthly';
                                                           }
                                                         }
                                                         if(this.router.url.indexOf('monthly-parking')>-1)
                                                       {
                                                         if(this.router.url.indexOf('daily')>-1)
                                                         {
                                                           typeMode='daily';
                                                         }else{
                                                           typeMode='monthly';
                                                         }
                                                       }
                                                         let url = '/search/'+userCLat+'/'+userCLong+'/'+stime+'/1/'+data.results[0].formatted_address+'/'+typeMode+'/'+CustomCode.searchMode()
                                                         //window.location.href=url
                                                         let number=3
                                                    if(this.router.url.indexOf('/3/')>-1)
                                                    {
                                                        number=2
                                                    }else{
                                                        number =3;
                                                    }
                                                    localStorage.setItem('latNew',userCLat.toString())
                                                    localStorage.setItem('longNew',userCLong.toString())
                                                    localStorage.setItem('locationNew',data.results[0].formatted_address) 
                                                     $('.selectAuto').val(data.results[0].formatted_address);
                                                         this.router.navigate(['/search', userCLat, userCLong, stime, number,  data.results[0].formatted_address, typeMode, CustomCode.searchMode()]);
                                                         
                                                       }
                                                       
                                                      // 
                                                       
                                                     }
                                                    else{
                                                     
                                                     let userLat = CustomCode.timeSquareCoords.lat;
                                                     let userLong = CustomCode.timeSquareCoords.long;
                                                     const locationUser = CustomCode.timeSquareCoords.defaultLocation;
                                                    // let url = '/search/'+userLat+'/'+userLong+'/'+stime+'/1/'+locationUser+'/'+this.settypedata+'/'+CustomCode.searchMode()
                                                     //console.log(2)
                                                    // alert(url);
                                                     //alert(this.routerObj.url.replace('%20',' '));
                                                     //alert(this.routerObj.url.replace('%20',' ')!=url)
                                                     if(this.router.url!='1')
                                                     {
                                                       //alert(5)
                                                       if(this.router.url.indexOf('monthly-parking')>-1)
                                                         {
                                                           
                                                          
                                                           //$('.test').trigger('click');
                                                          // localStorage.setItem('currentLoc','1')
                                                          this.locationIcon()
                                                           
                                                         }
                                                       if(this.router.url.indexOf('search')>-1)
                                                       {
                                                         this.locationIcon()
                                                         //localStorage.setItem('datafromlocation','1')
                                                        // $('.test').trigger('click');
                                                       //  localStorage.setItem('currentLoc','1')
                                                       
                                                         
                                                       }
                                                       
                                                       
                                                       let typeMode=this.settypedata
                                                       if(this.router.url.indexOf('search')>-1)
                                                       {
                                                         if(this.router.url.indexOf('daily')>-1)
                                                         {
                                                           typeMode='daily';
                                                         }else{
                                                           typeMode='monthly';
                                                         }
                                                       }
                                                       if(this.router.url.indexOf('monthly-parking')>-1)
                                                       {
                                                         if(this.router.url.indexOf('daily')>-1)
                                                         {
                                                           typeMode='daily';
                                                         }else{
                                                           typeMode='monthly';
                                                         }
                                                       }
                                                       let url = '/search/'+userLat+'/'+userLong+'/'+stime+'/1/'+locationUser+'/'+typeMode+'/'+CustomCode.searchMode()
                                                       let number=3
                                                    if(this.router.url.indexOf('/3/')>-1)
                                                    {
                                                        number=2
                                                    }else{
                                                        number =3;
                                                    }
                                                    localStorage.setItem('latNew',userCLat.toString())
                                                    localStorage.setItem('longNew',userCLong.toString())
                                                    localStorage.setItem('locationNew',locationUser) 
                                                     $('.selectAuto').val(locationUser);
                                                       this.router.navigate(['/search', userLat, userLong, stime, number, locationUser,  typeMode, CustomCode.searchMode()]);
                                                       //window.location.href=url
                                                       }
                                                     
                                                     // 
                                                    }
                                                   })
                                                     }, this.blockLocationCallback.bind(this));
                                                   } 
                                                 
                                               if(this.POSuserLat!=0 && this.POSuserLong!=0){
                                                 var geocoder;
                                                 geocoder = new google.maps.Geocoder();
                                                 var latlng = new google.maps.LatLng(this.POSuserLat, this.POSuserLong);
                                                 var georoute=this.router;
                                                 var add;
                                                   geocoder.geocode(
                                                     {'latLng': latlng}, 
                                                     function(results, status,georoute) {
                                                        add= results[0].formatted_address ;
                                                       //alert("city name is: " + add);  
                                                        $('#googleautosearch').val(add);     
                                                     }
                                                   );
                                                   
                                               }
                                               
                                          }
                                          toTimestamp(strDate){
                                              var datum = Date.parse(strDate);
                                              return datum/1000;
                                          }
                                          blockLocationCallback() {
                                              let exacTime = FilterHelper.defaultStartDurationUrlHomePageData(FilterHelper.changeNyTime());
                                              //alert(this.endDuration);
                                              const mnts = (this.endTime.getHours() > 12) ? (this.endTime.getHours() - 12 + ':' + this.endTime.getMinutes() + ' PM') : (this.endTime.getHours() + ':' + this.endTime.getMinutes() + ' AM');
                                              let endTimeData = FilterHelper.makeDurationDateObject(this.endDuration, mnts);
                                              //alert(endTimeData);
                                              //this.endTime = FilterHelper.getFormattedTime(this.endDuration);
                                            
                                              let startTime=FilterHelper.defaultStartDuration();
                                                let startTimes = FilterHelper.getFormattedTime(startTime);
                                                if(this.router.url.indexOf('search')>-1)
                                                {
                                                  localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHomeForFindParking($('#startDateWithID').val()));
                                                }else{
                                                  localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(startTime,startTimes));
                                                }
                                                //localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHomeForFindParking($('#startDateWithID').val()));
                                                let stime =localStorage.getItem('startimehome');
                                            
                                              if(this.toTimestamp(this.startDuration)<this.toTimestamp(exacTime))
                                              {
                                                let startDuration:any = FilterHelper.defaultStartDuration();
                                                let startTime = FilterHelper.getFormattedTime(startDuration);
                                                localStorage.setItem('monthlyDate',startDuration)
                                                let endDuration = FilterHelper.defaultEndDuration();
                                                let endTime = FilterHelper.getFormattedTime(endDuration);
                                                let endTimeDataIfLess = FilterHelper.makeDurationDateObject(endDuration,endTime);
                                                //alert(endTimeDataIfLess)
                                                if(this.toTimestamp(endTimeData)<this.toTimestamp(endTimeDataIfLess))
                                                {
                                                  //alert(2)
                                                  //localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
                                                }
                                                localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
                                                localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.startDuration,this.startTime));
                                              }
                                              let lat:any=40.7589
                                              let long:any =-73.9851
                                              let location:any ='Times Square'
                                               stime =localStorage.getItem('startimehome')
                                               localStorage.removeItem('startimehome');
                                              const getTimeCoords: any = JSON.parse(JSON.stringify(CustomCode.timeSquareCoords));
                                              const userLat = getTimeCoords.lat;
                                              const userLong = getTimeCoords.long;
                                              const defaultLoc = getTimeCoords.defaultLocation;
                                            // let url = '/search/'+userLat+'/'+userLong+'/'+stime+'/1/'+defaultLoc+'/'+this.settypedata+'/'+CustomCode.searchMode()
                                              //alert(url)
                                              if(this.router.url!='1')
                                                {
                                                  if(this.router.url.indexOf('monthly-parking')>-1)
                                                      {
                                                        
                                                       
                                                        //$('.test').trigger('click');
                                                       // localStorage.setItem('currentLoc','1')
                                                       this.locationIcon()
                                                        
                                                      }
                                                  if(this.router.url.indexOf('search')>-1)
                                                      {
                                                        this.locationIcon()
                                                        //localStorage.setItem('datafromlocation','1')
                                                        
                                                      // // $('.test').trigger('click');
                                                       // localStorage.setItem('currentLoc','1')
                                                        
                                                    }
                                                    
                                                    let typeMode=this.settypedata
                                                    if(this.router.url.indexOf('search')>-1)
                                                    {
                                                      if(this.router.url.indexOf('daily')>-1)
                                                      {
                                                        typeMode='daily';
                                                      }else{
                                                        typeMode='monthly';
                                                      }
                                                    }
                                                    if(this.router.url.indexOf('monthly-parking')>-1)
                                                    {
                                                      if(this.router.url.indexOf('daily')>-1)
                                                      {
                                                        typeMode='daily';
                                                      }else{
                                                        typeMode='monthly';
                                                      }
                                                    }
                                                    let url = '/search/'+userLat+'/'+userLong+'/'+stime+'/1/'+defaultLoc+'/'+typeMode+'/'+CustomCode.searchMode()
                                                    //window.location.href=url
                                                    let number=3
                                                    if(this.router.url.indexOf('/3/')>-1)
                                                    {
                                                        number=2
                                                    }else{
                                                        number =3;
                                                    }
                                                    localStorage.setItem('latNew',userLat.toString())
                                                    localStorage.setItem('longNew',userLong.toString())
                                                    localStorage.setItem('locationNew',defaultLoc) 
                                                     $('.selectAuto').val(defaultLoc);
                                                    this.router.navigate(['/search', userLat, userLong, stime, number, defaultLoc, typeMode, CustomCode.searchMode()]);
                                                  }
                                              //this.routerObj.navigate(['/search', userLat, userLong, stime, 1, defaultLoc, this.settypedata, CustomCode.searchMode()]);
                                            }
                                  
                                            locationIcon(){
                                              if(0) 
                                              {
                                              }else{
                                                localStorage.removeItem('showNeighbordood');
                                              
                                              localStorage.removeItem('neighbourhoodActive')
                                              localStorage.removeItem('showNeighbordood');
                                            
                                             
                                              }
                                              localStorage.removeItem('sortBy')
                                              sessionStorage.removeItem('tabview');
                                              localStorage.setItem('resetSearch','1')
                                              
                                            } 


               formatRateMonthly(rate)
                {
                  if (rate) {
                    let formattedRate = String(rate);
                    return formattedRate.includes('N/A') ? 'Sold Out' : '$' + formattedRate.replace('.00', '');
                    }
                }
                                  

ovelay()
         {
        let url='from search'
        if(this.router.url.indexOf('monthly-parking')>-1)
         {
              url='from monthly parking page'
        }
        if(this.router.url.indexOf('partner')>-1)
            {
          url='from partner page'
        }
        let text = 'Book button click on overlay  '+url
      sessionStorage.setItem('clickType',text)
     }

     sideBarClick()
      {
        let url='from search'
        if(this.router.url.indexOf('monthly-parking')>-1)
         {
              url='from monthly parking page'
        }
        if(this.router.url.indexOf('partner')>-1)
            {
          url='from partner page'
        }
        let text = 'Book button click on sidebar window '+url
      sessionStorage.setItem('clickType',text)
     }
     detailsSidebarClick()
      {
        let url='from search '
        if(this.router.url.indexOf('monthly-parking')>-1)
         {
              url='from monthly parking page'
        }
        if(this.router.url.indexOf('partner')>-1)
            {
          url='from partner page'
        }
        let text = 'Book button click on details window '+url
      sessionStorage.setItem('clickType',text)
     }



     tabRouting(val) //ye kiya h
  {
    if(this.router.url.indexOf('search')>-1)
    {
      if(this.router.url.indexOf('daily')>-1)
      {
        if(val=='monthly')
        {
          sessionStorage.setItem('tabclick','1')
          let url=this.router.url.replace('daily','monthly')
          this.router.navigate([url]);
        }
      }
      //alert(val+'2')
      if(this.router.url.indexOf('monthly')>-1)
      {
        //alert(val)
        if(val=='daily')
        {
          //alert(val+'1')
          sessionStorage.setItem('tabclick','1')
          let url=this.router.url.replace('monthly','daily')
          this.router.navigate([url]);
        }
      }
    }
    if(this.router.url.indexOf('monthly-parking')>-1)
    {
      if(this.router.url.indexOf('daily')>-1)
      {
        if(val=='monthly')
        {
          sessionStorage.setItem('tabclick','1')
          let url=this.router.url.replace('daily','monthly')
          this.router.navigate([url]);
        }
      }else{
        if(val=='daily')
        {
          let url_split=this.router.url.split('/')
          //alert(url_split.length)
          if(url_split.length<3)
          {
            let url='monthly-parking/central-park/daily'
           // alert(url)
            this.router.navigate([url]);
          }else{
            let url='monthly-parking/'+url_split[2]+'/'+'daily'
            //alert(url)
            this.router.navigate([url]);
          }
        }
      }
    }
    
  }

}
