import { Component, OnInit } from '@angular/core';

@Component({
    selector : 'app-perferred-customer-peogram',
    templateUrl: './preferred-customer-program.component.html',
    styleUrls: ['./preferred-customer-program.component.scss']

})
export class preferredCustomerProgramComponent implements OnInit{
    constructor(){}
    ngOnInit(){

    }
}