import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
declare var google: any;

import * as $ from 'jquery';
import { CustomCode } from '../../shared/CustomCode';
import { GoogleMapComponent } from '../../Components/Global/google-map/google-map.component';


@Component({
  selector: 'app-mobileheader',
  templateUrl: './mobileheader.component.html',
  styleUrls: ['./mobileheader.component.scss'],
  host: {
        '(window:scroll)': 'updateHeader($event)'
	}
})

export class MobileHeaderComponent implements OnInit {

    @ViewChild(GoogleMapComponent,{static:true}) gMapObj: GoogleMapComponent;

	 isScrolled = false;
    currPos: Number = 0;
    startPos: Number = 0;
    changePos: Number = 100;
    datetime: Date;
    datetimeSettings1;
    datetimeSettings2;
    dateTimeText;
    partnerSearchHideOn:boolean=false;



    facilityRadius = CustomCode.facilityDefaultRadius;

  currDate = CustomCode.getDateForMapSearch();
  
  constructor(private routerObj: Router, private router: Router) { 


  }

    myFunction() {
         $('.navbar,.menuToggle,.listBtn,footer,.listToggleFilter').hide();
         $('.searchFilter.hidden-xs').css('margin-top','0px');
         $('.SearchPanelMobile').css('top','15px');
              setTimeout(()=>{
        $('.mobile-search-filter-nav').addClass('widget');
         },1000);
      }
        ngOnInit() {
          if(this.router.url.indexOf('partners') > -1)
        {
         this.partnerSearchHideOn = true;
        }else{
        this.partnerSearchHideOn = false;
        }
          
          if(window.self!==window.top)
          {
           this.myFunction()      //setTimeout(this.myFunction, 2000);
          }
    CustomCode.openMapMobileList();
    //    $('#listBtn').click(function(){
    //     $('#mobilesorting').toggleClass('hidden');
    //     $('aside.contentBar').removeClass('active');
    // })
    
  }

  listButtonClick() {
      $('.listToggleFilter').toggleClass('hidden');
      $('aside.contentBar').removeClass('active');
      $('.listTabMobile').toggle();
      const toggleText = $('.listBtn');
      if (toggleText.find('i').hasClass('ti-menu-alt')) {

          $(toggleText).find('i').addClass('ti-map-alt').removeClass('ti-menu-alt');


      } else {
          $(toggleText).find('i').removeClass('ti-map-alt').addClass('ti-menu-alt');

      }
  }

  toggleData() {
    $('#mySidenav').toggleClass('open');
      $('body').addClass('noscroll');
       $('.sidenavBackDrop').toggleClass('open');
  }

  mSortBy(val) {
    $('[name=' + val + ']').trigger('click');
    //this.gMapObj.sortBy(val);
  }

  updateHeader(evt) {
        this.currPos = (window.pageYOffset || evt.target.scrollTop) - (evt.target.clientTop || 0);
        if (this.currPos >= this.changePos ) {
            this.isScrolled = true;
        } else {
            this.isScrolled = false;
        }
    }

  openMobileList() {
      //$('.listTabMobile').css('display', 'block');

      const toggleText = $('.listBtn');
      $(toggleText).click(function() {
          if (toggleText.find('i').hasClass('ti-menu-alt')) {

              $(toggleText).find('i').addClass('ti-map-alt').removeClass('ti-menu-alt');


          } else {
              $(toggleText).find('i').removeClass('ti-map-alt').addClass('ti-menu-alt');

          }

          //$('.listToggleFilter').toggle();
          //$('#map_canvas').toggle();
          $('.listTabMobile').toggle();
          //$('.MobSearch').toggleClass('bgWhite');
      });
  }

}
