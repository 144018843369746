import { Component, OnInit } from '@angular/core';
import { HomepageserviceService } from '../../../services/homepageservice.service';
import * as $ from 'jquery';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-errorlanding',
  templateUrl: './errorlanding.component.html',
  styleUrls: ['./errorlanding.component.scss']
})
export class ErrorlandingComponent implements OnInit {

  activeUser:any;
  responseData:any;
  constructor(

    private homePageServiceObj: HomepageserviceService,


    private toasterObj: ToastrService,
	
	) { }

  ngOnInit() {
  }


    subscription() {
	    const subscribeText = (<HTMLInputElement>document.getElementById('addsubscription')).value.trim();
	    if (subscribeText) {
	      let data = {};
	      if (localStorage.getItem('access_token')) {
	        data = {
	          "email" : (<HTMLInputElement>document.getElementById('addsubscription')).value.trim(),
	          "user_id" : localStorage.getItem('userid')
	        };
	      } else {
	        data = {
	          "email" : (<HTMLInputElement>document.getElementById('addsubscription')).value.trim()
	        };
	      }
	      
	      this.homePageServiceObj.subscribeToApplication(data)
	        .subscribe(
	        data => {
	          this.responseData = data;
	          if (this.responseData.status === 201) {
	            this.toasterObj.success('Email subscribed successfully', 'success!');
	          } else {
	            this.toasterObj.error('This email address is already subscribed to our mailing list', 'Sorry!');
	          }
	          (<HTMLInputElement>document.getElementById('addsubscription')).value = '';
	        },
	        error => {
	          const errorsList = error.errors;
	          if (errorsList.message !== 'Validation Errors') {
	            this.toasterObj.error(errorsList.message, 'Sorry!');
	          } else {
	            for (const key in errorsList.detail) {
	              if (errorsList.detail.hasOwnProperty(key)) {
	                let errorMessage = "This email address is already subscribed to our mailing list";
	                if (typeof errorsList.detail === 'undefined') {
	                  if (errorsList.message != "The email has already been taken.") {
	                    errorMessage = errorsList.message
	                  }
	                  // this.toasterObj.error(errorsList.message, 'Sorry!');
	                } else {
	                  if (errorsList.detail[key] != "The email has already been taken.") {
	                    errorMessage = errorsList.detail[key]
	                  }
	                  // this.toasterObj.error(errorsList.detail[key], 'Sorry!');
	                }
	                this.toasterObj.error(errorMessage, 'Sorry!');
	              }
	            }
	          }
	        }
	        );
	      (<HTMLInputElement>document.getElementById('addsubscription')).value = '';
	    } else {
	      this.toasterObj.error('email is required', 'Sorry!');
	    }
  }

}
