import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { GoogleMapComponent } from '../../Components/Global/google-map/google-map.component';

import { CustomCode } from '../../shared/CustomCode';
import { SearchPageScript } from '../../shared/searchPageScript';
import { FormValidationErrors } from '../../shared/formValidationErrors';
import { SearchpageserviceService } from './../../services/searchpageservice.service';
import { ToastrService } from 'ngx-toastr';
import { StaticpagesService } from './../../services/staticpages.service';
//declare var kochava:any;
@Component({
  selector: 'app-monthly-special-campaign',
  templateUrl: './monthly-special-campaign.component.html',
  styleUrls: ['./monthly-special-campaign.component.scss'],
  providers: [SearchpageserviceService,StaticpagesService]
})
export class MonthlySpecialCampaignComponent implements OnInit {

	@ViewChild(GoogleMapComponent,{static:true}) googleMap: GoogleMapComponent;



	toggleMap :any ;

  showMap:boolean = true;
  firstLoad:boolean = false;
  LoadFacilities:boolean=false;

	allfacility:any=''
	searchCriteria: any = {
    'arrival_time': CustomCode.getDateWithoutTime(),
    'length_of_stay': 2,
		'radius': CustomCode.facilityDefaultRadius,
    'verbose' : true,
    'monthly_campaign' : 1
	};

    ev_charge_icon='assets/img/amenities/ev_charge_icon.svg';
	  motorcycle_icon='assets/img/amenities/motorcycle_icon.svg'
	  tesla_charger_icon='assets/img/amenities/tesla_charger_icon.svg'
	  open_24hr_icon='assets/img/amenities/open_24hr_icon.svg'
	  monthly_icon='assets/img/amenities/monthly_icon.svg'
	  oversized_icon='assets/img/amenities/oversized_icon.svg'
	  outdoor_icon='assets/img/amenities/outdoor_icon.svg'
	  indoor_icon='assets/img/amenities/indoor_icon.svg'
	  park_garage='/assets/img/park_garage.png'
	  list_icon='assets/img/list_icon.svg'
	sidebarOn :any;
	swipeOn : any;
	searchResult: any = [];
	neighbourhoods: any = {};
  activeNeighbourhood: any ;
  activeNeighbourhoodTitle ;
  startDuration:any;
  campaignDetails:any;
  listNeighbourhood=[];
  campaignTitle:any;
  campaignDescription:any;
  campaignFooter:any;
  checkLoadFacility:boolean;
  thresold:any=0;
  is_show=1;
  is_show_loader:any=1;
  opens24Hours=0;
  opensOnWeekends=0
  opensOnWeekDays=0
  temporaryClosed=0
  opens24HoursM: boolean =false
  opensOnWeekendsM: boolean =false
  opensOnWeekDaysM: boolean =false
  temporaryClosedM: boolean =false
  selectedLocation:any=''
  spaceLeftMessage:string;
  existData=1;
  facilityText=1;
  showListView = false;
  	constructor(private router: Router,private activatedRouteObj: ActivatedRoute,private toastr: ToastrService,
    private searchPageObj: SearchpageserviceService,
    private StaticpagesService : StaticpagesService
    ) { 
  		this.toggleMap = true;
		this.sidebarOn  = false;
		this.swipeOn = false;
	}

	ngOnInit() {
    // setTimeout(() => {
    //   $('body').addClass('showChat');
    // },1000);
    if (this.router.url.indexOf('hours-of-operation') > -1) {
      this.is_show_loader=0;
      let times_run= setInterval(function () {
        $('body').removeClass('showChat');
      }, 3000);
     // $('body').removeClass('showChat');
		}else{
      this.is_show_loader=1;
    }
    let times_run= setInterval(function () {
      $('body').addClass('showChat');
    }, 4000);
    setTimeout(function () {
      clearInterval(times_run);
    }, 10000);
		this.fetch_monthly_campaign();
	}

  fetch_monthly_campaign(){
    this.StaticpagesService.getMonthlyCampaignContent().subscribe(
      data => {
        this.campaignDetails  = data;
          this.campaignDetails = this.campaignDetails.result[0];
         this.campaignTitle = this.campaignDetails.campaign_title;
         this.campaignFooter = this.campaignDetails.campaign_footer;
         this.thresold=this.campaignDetails.threshold;
         this.spaceLeftMessage = this.campaignDetails.space_message;
        let default_neighbour = this.campaignDetails.default_neighborhood.split('?');
        this.activeNeighbourhood = default_neighbour[0];
        this.activeNeighbourhoodTitle = default_neighbour[1];
        this.activeNeighbourhoodTitle = this.activeNeighbourhoodTitle.toUpperCase();
        this.campaignDescription = this.campaignDetails.campaign_desc.replace("{{neighbour hood name}}",this.activeNeighbourhoodTitle);
        this.fetchNeighbourhoodsData(this.activeNeighbourhood);
       
      }
      , (error) => { console.log('Error: ' + JSON.stringify(error)); 
    }
    )
  }
  tryPrintScaleValue() {
    if(this.opens24HoursM)
    {
      this.opens24Hours=1;
    }else{
      this.opens24Hours=0;
    }
    //2nd 
    if(this.opensOnWeekendsM)
    {
      this.opensOnWeekends=1;
    }else{
      this.opensOnWeekends=0;
    }
    //3 rd 
    if(this.opensOnWeekDaysM)
    {
      this.opensOnWeekDays=1;
    }else{
      this.opensOnWeekDays=0;
    }
    //4th
    if(this.temporaryClosedM)
    {
      this.temporaryClosed=1;
    }else{
      this.temporaryClosed=0;
    }
    //alert();
    this.showListView = true;
    this.reloadMap(this.selectedLocation);
  }
  fetchCampaignsNeighbourhood(){
    let is_new_page=0
    if (this.router.url.indexOf('special-campaign-neighborhood') > -1) {
			is_new_page=1;
		}else{
      is_new_page=0;
    }
    if (this.router.url.indexOf('hours-of-operation') > -1) {
      this.is_show=0;
      is_new_page=1;
		}else{
      this.is_show=1;
      is_new_page=0;
    }
    this.StaticpagesService.getCampaignNeighbourhood(is_new_page).subscribe(
      data => {
        let NeighbourhoodList = data;
        for( let activeCampaign in NeighbourhoodList ){
          this.listNeighbourhood.push(NeighbourhoodList[activeCampaign].slug);
        }
        this.fetchFacilityData();
      }
    )
  }

  reloadMap(location) {
    this.showListView = true;
    this.selectedLocation=location
    this.listNeighbourhood = [];
    let is_new_page=0
    if (this.router.url.indexOf('special-campaign-neighborhood') > -1) {
			is_new_page=1;
		}else{
      is_new_page=0;
    }
    if (this.router.url.indexOf('hours-of-operation') > -1) {
      this.is_show=0;
      is_new_page=1;
		}else{
      this.is_show=1;
      is_new_page=0;
    }
    this.StaticpagesService.getCampaignNeighbourhood(is_new_page).subscribe(
      data => {
        let NeighbourhoodList = data;
        for( let activeCampaign in NeighbourhoodList ){
          this.listNeighbourhood.push(NeighbourhoodList[activeCampaign].slug);
        }
      }
    )
    this.LoadFacilities = false;
    this.activeNeighbourhood = location;
    
    this.searchCriteria.latitude = this.neighbourhoods[location].latitude;
    this.searchCriteria.longitude = this.neighbourhoods[location].longitude;
    if (this.router.url.indexOf('special-campaign-neighborhood') > -1) {
			this.searchCriteria.neighID = this.neighbourhoods[location].id;
		}else{
      this.searchCriteria.neighID=0;
    }
    if (this.router.url.indexOf('hours-of-operation') > -1) {
      this.searchCriteria.neighID = this.neighbourhoods[location].id;
		}else{
     // this.searchCriteria.neighID=0;
    }
    this.activeNeighbourhoodTitle = this.neighbourhoods[location].title;
    this.activeNeighbourhoodTitle = this.activeNeighbourhoodTitle.toUpperCase();
    this.campaignDescription = this.campaignDetails.campaign_desc.replace("{{neighbour hood name}}",this.activeNeighbourhoodTitle);
    this.fetchFacilityData();

 
    if(window.innerWidth < 768){
      if(this.showListView){
        this.showMap = true;
      }else {
        this.showMap = false;
      }
      this.nbhMapToggle();
      
    }

	}

  // checkAvaillity_campaign(totalAvailable, usedAvailable)
  // {
  //   if(parseInt(totalAvailable)>parseInt(usedAvailable))
  //   {
  //     return true;
  //   }else{
  //     return false
  //   }
  // }

  checkAvaillity_campaign_remaining(totalAvailable, usedAvailable){
   let val =  parseInt(totalAvailable) - parseInt(usedAvailable);
   return val 
  }
  checkAvaillity_campaign_remaining_text(totalAvailable, usedAvailable,text)
  {
    let val =  parseInt(totalAvailable) - parseInt(usedAvailable);
    let newText='';
    if(val>1)
    {
      newText=text.replace('number Space',val+' Spaces')
    }else{
      newText=text.replace('number',val)
    }
   
    return newText;
  }
	fetchNeighbourhoodsData(activeNeighbourhood) {
		this.searchPageObj.getNeighbourhoods()
			.subscribe(
			data => {
				if (data.data) {
					for (let i = 0; i < data.data.length; i++) {
            this.neighbourhoods[data.data[i].slug] = data.data[i];
            if(activeNeighbourhood == data.data[i].slug){
              this.searchCriteria.latitude = data.data[i].latitude;
              this.searchCriteria.longitude = data.data[i].longitude;
              if (this.router.url.indexOf('special-campaign-neighborhood') > -1) {
                this.searchCriteria.neighID=data.data[i].id
              }else{
                this.searchCriteria.neighID=0;
              }
              if (this.router.url.indexOf('hours-of-operation') > -1) {
                this.searchCriteria.neighID = data.data[i].id;
                this.selectedLocation=data.data[i].slug
              }else{
                //this.searchCriteria.neighID=0;
              }
              
            }
          }
          this.fetchCampaignsNeighbourhood();
				} else {
					this.toastr.error('Error Occured While Loading Neighborhoods', 'Sorry!');
				}
			},
			error => {
				this.toastr.error(error.error.message, 'Sorry!');
			}
		);
	}


	fetchFacilityData() {
  this.searchCriteria.opens24Hours=this.opens24Hours
  this.searchCriteria.opensOnWeekends=this.opensOnWeekends
  this.searchCriteria.opensOnWeekDays=this.opensOnWeekDays
  this.searchCriteria.temporaryClosed=this.temporaryClosed
  // console.log(this.searchCriteria)
		this.searchPageObj.getFacilityMonthlyCampaign(this.searchCriteria)
			.subscribe(
			data => {
        this.LoadFacilities = true;
        this.firstLoad = true;
          this.searchResult = data.data.facilities;
          if(data.data.facilities.length>0)
          {
            this.existData=0;
          }else{
            this.existData=1;
          }
          
			},
			error => {
        this.toastr.error(error.errors.message, 'Sorry!');
        this.LoadFacilities = true;
        this.firstLoad = true;
			}
		);
  }
  showtimefornxtornot(text)
	{
		var res = text.split(':');
		if(res[0]>24)
		{
			return '(next day)'
		}else{
			return '';
		}
	}
  buyMonthlyCampaign(monthlyRate, facID, netPrice, TaxPrice, IsMonthly_campaign){
    
    // kochava.activity('clickBuyMonthlyAccountByCampaign', {
    //   //user_name: this.respdata.display_name,                      // Name or ID is required.
    //   id: facID,
    //   price: monthlyRate,
    //   category: 'Monthly_Campaign',
    //   });

    sessionStorage.setItem('campaignMonthlyRate', monthlyRate);
    sessionStorage.setItem('campaignNetPrice', netPrice);
    sessionStorage.setItem('campaignTaxPrice', TaxPrice);

    sessionStorage.setItem('isMonthlyCampaign', IsMonthly_campaign);

    let Cdate = this.searchCriteria.arrival_time;
    this.router.navigate(['/buy-monthly-campaign/'+ facID + '/' + Cdate]);

  }

  nbhMapToggle(){
    $(window).scrollTop(0);
    if(this.showMap == true){
      this.showMap = false;
      $('.mapSvg').slideUp();
      $('.mapList').slideDown();
    }
    else{
      this.showMap = true;
      $('.mapSvg').slideDown();
      $('.mapList').slideUp();
    }
  }
  convertIntoInteger(val)
  {
    return(parseInt(val))
  }
  checkData(var1,var2)
  {
    let t=this.checkAvaillity_campaign_remaining(var1,var2)
    if(t>0)
    {
      return '40%';
    }if(t<0 || t==0)
    {
      return '40%';
    }
    else{
      return '80%'
    }
  }
}
