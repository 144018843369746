import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortHistory'
})
export class SortHistoryPipe implements PipeTransform {
  transform(array: Array<string>, args: string): Array<string> {
    if(!array || array === undefined || array.length === 0) return null;
      array.sort((a: any, b: any) => {
        if (new Date(a.transaction_date) > new Date(b.transaction_date)) {
          return -1;
        } else if (new Date(a.transaction_date) < new Date(b.transaction_date)) {
          return 1;
        } else {
          return 0;
        }
      });
      return array;
    }
}