import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { GoogleMapComponent } from '../../Global/google-map/google-map.component';

import { CustomCode } from '../../../shared/CustomCode';
import { SearchPageScript } from '../../../shared/searchPageScript';
import { FormValidationErrors } from '../../../shared/formValidationErrors';
import { SearchpageserviceService } from '../../../services/searchpageservice.service';
import { ToastrService } from 'ngx-toastr';
import { MyAccountMonthlyParkingService } from '../../../services/my-account-monthly-parking.service';

@Component({
	selector: 'app-search-facility',
	templateUrl: './search-track.component.html',
	providers: [SearchpageserviceService, MyAccountMonthlyParkingService]
})
export class SearchTrackComponent implements OnInit {
	constructor(
		private router: Router,
		private activatedRouteObj: ActivatedRoute,
		private toastr: ToastrService,
		private searchPageObj: SearchpageserviceService,
		private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService
	) { }
	searchCriteria: any = {
		'type': CustomCode.defaultFacilityType,
		'device': 'web',
		'lat': CustomCode.timeSquareCoords.lat,
		'long': CustomCode.timeSquareCoords.long,
		'currdate': CustomCode.getDateForMapSearch(),
		'radius': CustomCode.facilityDefaultRadius,
		'label': CustomCode.timeSquareCoords.defaultLocation,
		'length_of_stay': 2
	};
	ngOnInit() {
		this.activatedRouteObj.params.subscribe((params: Params) => {
			if (params['lat'] && params['long'] && params['currdate'] && params['radius'] && params['label'] && params['type'] && params['device']) {
				localStorage.setItem('companyName',params['device']);
				this.searchCriteria.type = params['type'];
				this.searchCriteria.device = 'app';
				this.searchCriteria.lat = +params['lat'];
				this.searchCriteria.long = +params['long'];
				this.searchCriteria.currdate = params['sdate']
				this.searchCriteria.radius = params['radius'];
				this.searchCriteria.label = params['label'];
				if(params['edate']!=='undefined')
				{
					localStorage.setItem('searchType','widget');
					localStorage.setItem('endtime',params['edate']);
				}
				else{
					localStorage.removeItem('searchType');
					localStorage.removeItem('endtime');
				}
				this.router.navigate(['/search', this.searchCriteria.lat, this.searchCriteria.long, this.searchCriteria.currdate, this.searchCriteria.radius, this.searchCriteria.label,'daily', this.searchCriteria.device]);
				//window.location.href='/search/'+this.searchCriteria.lat+'/'+this.searchCriteria.long+'/'+this.searchCriteria.currdate+'/'+this.searchCriteria.radius+'/'+this.searchCriteria.label+'/daily/'+this.searchCriteria.device;
			}
			if (params['masquerade']){
				let val = params['masquerade'].split('lbha ');
				localStorage.setItem('username',val[0].replace(/XANDX/g,'&').replace(/XBROX/g,'(').replace(/XBRCX/g,')'))
				localStorage.setItem('email',val[1])
				localStorage.setItem('access_token',val[2])
				localStorage.setItem('refresh_token',val[3])
				localStorage.setItem('userid',val[4].slice(0, -2))
				localStorage.setItem('masquerade_set',val[4].slice(0, -2))
				localStorage.setItem('is_loyalty',val[5])
				localStorage.setItem('is_loyalty_active',val[6])
				localStorage.setItem('access_token1',val[7])
				localStorage.setItem('refresh_token1',val[8])
				localStorage.setItem('invalidName', '0');

				this.myAccountMonthlyParkingObj.fetchUser()
				.then(
					data => {
						let loggedUsername = data['data'].name;
						localStorage.setItem('username', loggedUsername);
						window.location.href='/my-account/account';
					},
					error => {
						window.location.href='/my-account/account';
					}
				);
			}
			if (params['admin']){
				let val = params['admin'].split('lbha ');
				localStorage.setItem('username',val[0].replace(/XANDX/g,'&').replace(/XBROX/g,'(').replace(/XBRCX/g,')'))
				localStorage.setItem('email',val[1])
				localStorage.setItem('access_token',val[2])
				localStorage.setItem('refresh_token',val[3])
				localStorage.setItem('userid',val[4].slice(0, -2))
				localStorage.setItem('is_admin','true')
				localStorage.setItem('is_loyalty',val[5])
				localStorage.setItem('is_loyalty_active',val[6])
				localStorage.setItem('invalidName', '0');
				this.myAccountMonthlyParkingObj.fetchUser()
				.then(
					data => {
						let loggedUsername = data['data'].name;
						localStorage.setItem('username', loggedUsername);
						window.location.href='/my-account/account';
					},
					error => {
						window.location.href='/my-account/account';
					}
				);
			}
		});

	}

	
}
