import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-b2b-landing',
  templateUrl: './b2b-landing.component.html',
  styleUrls: ['./b2b-landing.component.scss']
})
export class B2bLandingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  b2bCall()
  {
    (<any>window).dataLayer.push({
      'event': 'B2B Landing Page Click',
      'Action': 'Click',
      'Button Function': ' Call',
    });
  }
  b2bEmail()
  {
    (<any>window).dataLayer.push({
      'event': 'B2B Landing Page Click',
      'Action': 'Click',
      'Button Function': 'Email',
  });

  }
}
