import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
declare var google: any;
import { SearchpageserviceService } from '../../../services/searchpageservice.service';
import { CustomCode } from '../../../shared/CustomCode';
import * as $ from 'jquery';

@Component({
  selector: 'app-buymonthly-thanks',
  templateUrl: './buymonthly-thanks.component.html',
  styleUrls: ['./buymonthly-thanks.component.scss'],
  providers: [SearchpageserviceService]
})
export class BuymonthlyThanksComponent implements OnInit {

  facId;
  monId;
  respdata;
  CuTrackingCode;
  park_garage = '/assets/img/park_garage.png';
  garage_map = '/assets/img/garage-map.png'
  customer_phone = 'tel:(877) 727-5464';
  isFrame:any;



  monthlyEmailAddress;
  constructor(private activatedRouteObj: ActivatedRoute,
			  private searchPageServiceObj: SearchpageserviceService) { }

  ngOnInit() {
    if(window.self!==window.top)
    {
      this.isFrame = true;
    }else{
      this.isFrame = false;
    }
	  this.activatedRouteObj.params.subscribe((params: Params) => {
      this.facId = +params['facId'];
	  this.monId = +params['monId'];
	  this.CuTrackingCode=CustomCode.getTrackingCode();
	  this.monthlyEmailAddress=CustomCode.contactInfoMail;
	  
	  this.searchPageServiceObj.getFacilityDetails(this.facId).then(
        (res) => {
          this.respdata = res;
          this.respdata = this.respdata.data;
		  
		  var lat = parseFloat(this.respdata.geolocations.latitude);
				var lng = parseFloat(this.respdata.geolocations.longitude);

				 $( document ).ready(function() {
					
								$('#map-wrapper').html("<iframe   width='257'  height='170' frameborder='0'  scrolling='no'  marginheight='0'  marginwidth='0' src='https://maps.google.com/maps?q="+lat+","+lng+"&hl=es;z=14&amp;output=embed' allowfullscreen>");
								
		//$('#map-wrapper').html("<iframe width='257'  height='170' frameborder='0' style='border:0' src='https://www.google.com/maps/embed/v1/place?q="+lat+","+lng+"&amp;key=AIzaSyAHDD5yV6IIlT8mt6zqfadbuzdROG9wxQE'></iframe>");
								
								//<iframe width='257'  height='170' frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=40.7127837,-74.0059413&amp;key=AIzaSyAHDD5yV6IIlT8mt6zqfadbuzdROG9wxQE"></iframe>
								
					  });
        },
        (error) => { console.log('Error: ' + JSON.stringify(error)); }
      );
	  
	  });
	  
	  
  }

}
