import { Injectable } from '@angular/core';
//import { Http, Headers, RequestOptions, Response } from '@angular/http';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { HttpService } from './http.service';
import { Observable } from 'rxjs/Rx';
import { CustomCode } from '../shared/CustomCode';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class FAQContentServic{
	constructor(private http: HttpClient) { }
	private getUsersDetails = CustomCode.APIURL + 'api';
	private getResetUserEmail = CustomCode.APIURL + 'user/';

  fetchContent(slug_name) {
   if(slug_name=='all')
   {
   	slug_name=''
   }
   return  this.http.get(CustomCode.imageUrl+'cms/API_queans.php?temp_cat=New%20FAQ&sub_cat='+slug_name).map((res: Response) => res.json())
  }
  
}

