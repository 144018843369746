import { Injectable } from '@angular/core';
//import { Http, Headers, RequestOptions, Response } from '@angular/http';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { CustomCode } from '../shared/CustomCode';
import {HttpService} from './http.service';
// Import RxJs required methods
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class HomepageserviceService {
  constructor(private http: HttpClient) { }
  private subscribe = CustomCode.APIURL + 'email-signup';
  private sendApp = CustomCode.APIURL + 'facility/text';
  private campaignURL = CustomCode.APIURL + 'getwordpressPost';
  private cmiApiUrl = CustomCode.cmsUrl + 'cms/api_loading_model.php?id=';
  subscribeToApplication(data) {
    // const uData = userData;
    // const fData = new FormData();
    // fData.append('email', uData);
    return this.http.post(this.subscribe, data)
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }
  messageToMobile(userData, text) {
    const details = {'to': userData, 'mobileapplink': text };
    return this.http.post(this.sendApp, details)
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }
  
  userEmailConfirmation(userId, encryptCode) {
    //const details = {'to': userData, 'mobileapplink': text };
    return this.http.post(CustomCode.APIURL +'user/'+ userId +'/email/'+encryptCode, {})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }

  fetchCampaignTemplate(slug) {
    return new Promise((resolve, reject) => {
      this.http.get(this.campaignURL + '/' + slug).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  
  

  getCmsBaseballModelContent( pageId ){
    return this.http.get(this.cmiApiUrl+pageId).map(
      (res:Response) => res.json()
    );
  }


}

