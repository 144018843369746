import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import * as $ from 'jquery';

@Component({
  selector: 'app-saver-load',
  templateUrl: './saver-load.component.html',
  styleUrls: ['./saver-load.component.scss']
})
export class SaverLoadComponent implements OnInit {

  @Input() imageUrl: string;
  @Input() showPopup: boolean;
  @Input() isValidReservation:boolean;

  popOverride: boolean;
  is_image_pdf=0
  @Output() popEvent = new EventEmitter<boolean>();

  constructor(private router: Router,private sanitizer: DomSanitizer,private activatedRouteObj: ActivatedRoute) { }

  ngOnInit() {
    if(this.router.url.indexOf('current-invoice') > 0 || this.router.url.indexOf('previous-invoices') > 0) 
    {
      this.is_image_pdf=1
    }else{
      this.is_image_pdf=0
    }
  }

  closePopup() {
    this.popOverride = false;
    this.popEvent.emit(this.popOverride);
	
	this.activatedRouteObj.params.subscribe((params: Params) => {
			if(!params['ticketechCode']){}else{this.router.navigate(['/']);}
    	});
  }

  printMedia() {
    $('div#printable').html($('div#printableContent').html()).show();
    $('div#content-Wrapper').hide();
    window.print();
    $('div#printable').html('').hide();
    $('div#content-Wrapper').show();
	//this.router.navigate(['/thanks-print']);
  }

}

