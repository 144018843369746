
export default {
  oidc: {
    issuer: 'https://iconparkingciamsandbox.oktapreview.com/oauth2/default',
    clientId: '0oa3rwskfgSDWCWSI1d7',
   // RedirectUri: 'https://staging7.iconparkingsystems.com/login/callback',
   
  },
 
  // resourceServer: {
  //   messagesUrl: 'http://localhost:4200/api/messages',
  // },
};
