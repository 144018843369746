import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-monthly-parking-thanks',
  templateUrl: './monthly-parking-thanks.component.html',
  styleUrls: ['./monthly-parking-thanks.component.scss']
})
export class MonthlyParkingThanksComponent implements OnInit {
  isFrame:any;
  constructor() { }
   
  ngOnInit() {
    if(window.self!==window.top)
    {
      this.isFrame = true;
    }else{
      this.isFrame = false;
    }
  }

}
