import { Component, OnInit,TemplateRef,Pipe, PipeTransform, ViewChild, ElementRef} from '@angular/core';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { SafeResourceUrl } from '@angular/platform-browser';
import { StaticpagesService } from '../../../services/staticpages.service';
//import * as moment from 'moment-timezone';
import { NgForm } from '@angular/forms';
declare var google: any;
import { CustomCode } from '../../../shared/CustomCode';
import { FormValidationErrors } from '../../../shared/formValidationErrors';
import * as $ from 'jquery';
import { UserserviceService } from '../../../services/userservice.service';
import { ReservationService } from '../../../services/reservation.service';
import { SearchpageserviceService } from '../../../services/searchpageservice.service';

import { Crypto } from '../../../classes/crypto';
import { FilterHelper } from '../../../classes/filter-helper';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
// declare var gtag:Function;
// declare var ga: Function;
// declare var gtag_report_conversion : Function;

@Component({
  selector: 'app-voucher-redemption',
  templateUrl: './voucher-redemption.component.html',
  styleUrls: ['./voucher-redemption.component.scss'],
  providers: [UserserviceService, ReservationService, SearchpageserviceService, Crypto, StaticpagesService]
})
export class VoucherRedemptionComponent implements OnInit {



  @ViewChild('PromocodePopup',{static:true}) PromocodePopup: ElementRef;


  tc_accept_btn_clicked=false;
  showtcextra=true;
  public disabled: boolean = true;
  public disabled_btn: boolean = true;

  phoneNumber ='tel:888-705-7845';
  infoIcon =  'assets/img/info-icon.png';
  cardSecurityIcon ='assets/img/cardSecurity.png';
  calendarIcon = '/assets/img/calendar.svg';
  clockIcon =  '/assets/img/clock.svg';
  helpIcon =  '/assets/img/info-icon.png';
  iconAlert =  '/assets/img/icon-alert.png';
  iconparkSafe=  '/assets/img/iconpark-safe.png';
  redboxstrip=  '/assets/img/redbox-strip.svg';
  isOpen = false;
  gtmUrl:SafeResourceUrl = window.location.pathname;
  formData: any = {};
  loginData: any = {};
  respdata;
  monthlyRate;
  parseData;
  emailRegex = CustomCode.emailRegex;
  phoneMask: any = CustomCode.phoneMask;
  loggedUserName;
  facId;
  monthlyDate: any = {};
  newMonthlyDate: any = {};
  minDate: Date;
  months: any = [];
  years: any = [];
  facilityImage : any ='';
  isCollapsed: boolean = false;
  selectedvahicletype:any;
  selectedvahicletype_Id:any;
  public modalRef: BsModalRef;
  garageWorkingHours;
  FgarageWorkingHours;
  isFrame = false;
  searchTypeMonthly:boolean= true;
  getZipCode= '';
  reservationDataEventLog:any;
  getFacilityName = '';
  getFacilityId = '';
  rateDetails:any='00.00';
  rateDetailsNetRate:any='00.00';
  monthlyPromocodeApplied = '';
  taxForThis:any='00.00';
  dateMask: any = [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/];
  cardMask: any = [
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
  ];
  isCardReq:boolean=true;
  sameAsabove:boolean=false
  taxHideShow:any=1;
  make:any=[]
  modalName:any=[]
  extraCharge:any=0;
  des_surchage:any='';
  heading:any='';
  price_surcharge:any=''
  price_surcharge_value:any=''
  extraChargeCheck:boolean=false;
  feeType:any=''
  make_id:any=0;
  model_id:any=0;
  main_price_with_or_withouot_surcharge:any=0
  isLogin=false;
  surchargeType='Oversize Vehicle'

  page: String = 'monthly-parking-terms-conditions';
  staticPageContent: any = {};
  isLoadeds = false;
  dateFound=false
  userLogin=false
  disableSubmit = false;
  islogintrue=false;
  passwordErrMsg='Password is required';
  passwordLengthErrMsg = '';
  validPwd:boolean = false
  validCPwd:boolean = false
  cPasswordErrMsg = '';
  dontahowmessge:any=''
  notShow=1;
  notoversized=1;
  overSizedMessage:any='';
  isLoyaltyMember:boolean = false;
  paymentError=false;
  paymentError1=false
  errormessage=''
  discount:any=0;
  discountPrice=0;
  surchargeValue = 0;
  //couponapply=['1 Month','12 Months']
  couponapply={'1 Month':10,'12 Months':20,'6 Months':5}
  couponapply1=[];
    max_discount_applicable=0
  minimum_transaction_applicable=0;
  discountWithApi:any=0;
  discountType='percentage'
  checkName=''
  validPromocode=4;
  minimun_amount_error_msg=''
  max_percentage_amount_error_msg=''
  autoload=0
  successautoload=0
  processing_fees=0
  processing_fees_changes:any=0
  fromSearch = '';
  userEmailForLog = '';
  device_type = 'web';
  fetchedreservationData:any ='';
  enableAutoPay: boolean = false;
  reservationDate:any;
  selectedFacilityDetails:any;
  pricePaid = '';
  isLoaded = false;
  formatedDate:string = '';
  selectedGetVoucher :any = '';
  aggregator_name: string = '';
  is_aggregator_booking= 1;
  phoneLengthErrMsg= '';
  validPhone:boolean = false;
  loadedAPiData = false;
  voucher_name:string = '';
  priceTermsConditions = '';
  showErrorMsg = 0;
  no_sur_no_auto = 'text 1 {{price}}';
  no_sur_auto= 'text 2 {{price}}';
  sur_no_auto = 'text 3 {{price}}';
  sur_auto = 'text 4 {{price}}';
  termsConditionText = '';
  currentFacData = {};
  termsPrice:any = 0;
  termsPrice1:any = 0;
  checkDisableBtn = 0;
  cPasswordReqMsg = true;
  showSavedVehicles=false;
  selectedVehicleIndex: any;
  savedvehicle: any;
  display_surcharge: string;
  saved_vehicle_height='0px';
  showCancelBtn: boolean=false;
  drriverShow=0;
  driveronereadonly: boolean=false;
  drivertworeadonly: boolean=false;
  driverthreereadonly: boolean=false;
  constructor(private activatedRouteObj: ActivatedRoute,
    private routerObj: Router,
    private userServiceObj: UserserviceService,
    private reservationServObj: ReservationService,
    private searchPageServiceObj: SearchpageserviceService,
    private cryptoObj: Crypto,
    private toasterObj: ToastrService,
    private modalService: BsModalService,
    private staticObj:StaticpagesService
  ) { }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  
  showAllVehicle(){
    this.showSavedVehicles=false;
    this.selectedVehicleIndex =undefined;
    this.extraCharge=0;
  }
  hideAllVehicle(){
    this.showSavedVehicles=true; 
    this.formData.make_vehicle="";
    this.formData.make_modal="";
    this.formData.licenceplate="";
    this.formData.vehiclecolor=""
    this.formData.ep1="";
    this.formData.ep2="";
    this.formData.ep3="";
    this.formData.ep4="";
    //for autoenable payment
    this.surchargeValue=0;
     
  }
  toggleShow(formData) {
    this.drriverShow++;
  }
  vehicleDetailsWithModal_new(index)
  {
    // if(!modal){
    //   this.dontahowmessge='';
    // }
    this.extraCharge=0;
    this.extraChargeCheck=false;
    this.surchargeValue=0;
    this.formData.surcharge_amount=false;
    let value_of_vehicle= this.savedvehicle[index]
    this.price_surcharge=value_of_vehicle.price_description
    this.price_surcharge_value=value_of_vehicle.price
    this.model_id=value_of_vehicle.model_id
    this.des_surchage=value_of_vehicle.description
    this.heading=value_of_vehicle.heading

    //this.display_surcharge=value_of_vehicle.display_surcharge
    //let name_surcharge=value_of_vehicle.name
    
    
    let is_monthly_exotic_charge=value_of_vehicle.is_monthly_exotic_charge
    let is_monthly_oversized_fee=value_of_vehicle.is_monthly_oversized_fee
    let is_monthly_electric_surcharge=value_of_vehicle.is_monthly_electric_surcharge
    
    if(is_monthly_oversized_fee && this.notoversized==0)
    {
      is_monthly_oversized_fee=0;
      this.dontahowmessge=this.overSizedMessage
      //this.showoversizemsg=true;
      this.notShow=0;
      this.toasterObj.error(this.overSizedMessage, 'Sorry!');
    }else{
      this.dontahowmessge=''
      this.notShow=1;
    }
    if(this.price_surcharge_value=='')
    {
      is_monthly_oversized_fee=0;
      is_monthly_electric_surcharge=0;
      is_monthly_exotic_charge=0;
    }
    if(is_monthly_oversized_fee || is_monthly_exotic_charge ||is_monthly_electric_surcharge)
    {
      this.extraCharge=1;
      this.surchargeValue=1;
      if(is_monthly_oversized_fee)
      {
        this.feeType='oversized'
        //this.surchargeType='Oversized Vehicle'
        this.surchargeType=this.display_surcharge;
      }
      if(is_monthly_exotic_charge)
      {
        this.feeType='exotic'
        //this.surchargeType='Exotic Vehicle'
        this.surchargeType=this.display_surcharge;
      }
      if(is_monthly_electric_surcharge)
      {
        this.feeType='electric'
        //this.surchargeType='Electric Vehicle'
        this.surchargeType=this.display_surcharge;
      }
      this.extraChargeCheck = true;
    }else{
      this.extraCharge=0
    }
  }
  vehicleDetailsWithModal(make,modal)
  {
    if(!modal){
      this.dontahowmessge='';
    }
    this.extraCharge=0;
    this.surchargeValue=0;
    this.extraChargeCheck=false
    this.formData.surcharge_amount=false;
    let value_of_vehicle= this.make[make].mst_model[modal]
    this.price_surcharge=value_of_vehicle.price_description
    this.price_surcharge_value=value_of_vehicle.price
    this.display_surcharge=value_of_vehicle.display_surcharge
    this.model_id=value_of_vehicle.id
    let name_surcharge=value_of_vehicle.name
    this.des_surchage=value_of_vehicle.description
    this.heading=value_of_vehicle.heading
    let is_monthly_exotic_charge=value_of_vehicle.is_monthly_exotic_charge
    let is_monthly_oversized_fee=value_of_vehicle.is_monthly_oversized_fee
    let is_monthly_electric_surcharge=value_of_vehicle.is_monthly_electric_surcharge
    if(is_monthly_oversized_fee && this.notoversized==0)
    {
      is_monthly_oversized_fee=0;
      this.dontahowmessge=this.overSizedMessage
      //this.showoversizemsg=true;
      this.notShow=0;
      this.toasterObj.error(this.overSizedMessage, 'Sorry!');
    }else{
      this.dontahowmessge=''
      this.notShow=1;
    }
    if(this.price_surcharge_value=='')
    {
      is_monthly_oversized_fee=0;
      is_monthly_electric_surcharge=0;
      is_monthly_exotic_charge=0;
    }
    if(is_monthly_oversized_fee || is_monthly_exotic_charge ||is_monthly_electric_surcharge)
    {
      this.extraCharge=1;
      this.surchargeValue = 1;
      if(is_monthly_oversized_fee)
      {
        this.feeType='oversized'
        //this.surchargeType='Oversized Vehicle'
        this.surchargeType=this.display_surcharge;
      }
      if(is_monthly_exotic_charge)
      {
        this.feeType='exotic'
        //this.surchargeType='Exotic Vehicle'
        this.surchargeType=this.display_surcharge;
      }
      if(is_monthly_electric_surcharge)
      {
        this.feeType='electric'
        //this.surchargeType='Electric Vehicle'
        this.surchargeType=this.display_surcharge;
      }
      this.extraChargeCheck = true;
    }else{
      this.extraCharge=0
    }
    // if(!this.isLogin){
    // localStorage.setItem('vehicelinfo',  JSON.stringify({'make':make,'model':modal }) );
    // }

}
  checkFormatWithSurcharge(value)
{
  value=(parseFloat(value)-this.discountPrice)
  value=value+this.processing_fees_changes
  if(this.price_surcharge_value=='')
  {
    this.price_surcharge_value=0;
  }
  if(value.toString().length==7)
  {
    let a = value.toString();;
    let b = ",";
    let position = 1;
    //let output = a.substring(0, 1) + "," + a.substring(1, a.length);
    let output =value// [a.slice(0, position), b, a.slice(position)].join('');
    
    if(this.extraChargeCheck)
    {
      this.main_price_with_or_withouot_surcharge=parseFloat(output)+parseFloat(this.price_surcharge_value)
      return this.main_price_with_or_withouot_surcharge
    }else{
      this.main_price_with_or_withouot_surcharge=output
      return output;
    }
   // return 
  }else{
    if(this.extraChargeCheck)
    {
      this.main_price_with_or_withouot_surcharge = parseFloat(value)+parseFloat(this.price_surcharge_value)
      return this.main_price_with_or_withouot_surcharge
    }else{
      this.main_price_with_or_withouot_surcharge=value
      return value;
    }
    
  }

  
 
}
surcharge(val)
{
  this.extraChargeCheck=val
}

  selectVehicle(index){
    $('#selectv'+index).addClass('active');
    for(let i=0;i<this.savedvehicle.length;i++){
      if(i!=index){
        $('#selectv'+i).removeClass('active');
      }
    }
    this.selectedVehicleIndex=index;
    
    this.vehicleDetailsWithModal_new(index);
    //alert(brand_id);
  }
  activate_term_btn(){
    if(this.disabled_btn==true){
      this.disabled_btn=false;
    }
    else{
      this.tc_accept_btn_clicked=false;
      this.disabled_btn=true;
    }
    
  }
  
  ngOnInit() {
   

    let timesruined = setInterval( ()=> {
      if(localStorage.getItem('userid') && this.loadedAPiData )
      {
        if(localStorage.getItem('anotherLogin')){
          let namesData:any=localStorage.getItem('username');
          let namesDatas:any =namesData.split(' ');
          
          this.formData.firstname=namesDatas[0];
          this.formData.email=localStorage.getItem('email');
          this.userEmailForLog = localStorage.getItem('email');
          if(localStorage.getItem('phone_number') != undefined && localStorage.getItem('phone_number') != '' && localStorage.getItem('phone_number') != null && localStorage.getItem('phone_number') != 'null'  ) {
            this.formData.phone=localStorage.getItem('phone_number');
            this.validPhone = true;
          }
          let myString = namesData.substring(namesData.indexOf(' ')+1)
          if(namesDatas[1]==undefined)
          {
            myString='';
          }
          // if(myString != ''){
          // }
          this.formData.lastname=myString;
          this.userLogin=true;
          this.isLogin = true;
          localStorage.removeItem('anotherLogin');
        }
        
        // clearInterval(timesruined);
      }else {
        this.isLogin = false;
        this.userLogin=false;
      }
    }, 500);


    let timeReset = setInterval( ()=> {
      if(localStorage.getItem('resetForm') ){
        this.formData.phone= '';
        this.formData.firstname='';
        this.formData.lastname='';
        this.formData.email='';
        localStorage.removeItem('resetForm');
      }
    }, 500);

   

    this.isLoaded = false;
    if ($(window).width() < 767) {
      this.device_type  = 'web responsive';
    }else{ this.device_type  = 'web'; }
    setInterval(()=>{
      $('#mainBody').removeClass('noscroll');
    },100)
  
    if(localStorage.getItem('is_landing_page_monthly') && localStorage.getItem('promocode') ){
      sessionStorage.setItem('monthlyPromocode',localStorage.getItem('promocode'));
      this.formData.promocode = localStorage.getItem('promocode');
    }else {
      this.formData.promocode = '';
      sessionStorage.removeItem('monthlyPromocode')
    }
    this.isLoadeds = false;
    if(localStorage.getItem('is_loyalty')==="1" && localStorage.getItem('is_loyalty_active')=='1'){
      this.isLoyaltyMember = true;
    }
    
    this.formData.make_vehicle=''
    this.formData.make_modal=''

    let times_run= setInterval(function () {
      $('body').addClass('showChat');
    }, 4000);
    setTimeout(function () {
      clearInterval(times_run);
    }, 4000);


    if(window.self!==window.top)
    {
      this.isFrame = true;
    }else{
      this.isFrame = false;
    }
    this.minDate = new Date();
    var offsetTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //moment.tz.setDefault(offsetTimezone)//('America/New_York');
    //moment.tz.setDefault('America/New_York');
    this.minDate.setDate(this.minDate.getDate());
    this.activatedRouteObj.params.subscribe((params: Params) => {
      // console.log(params);
      let getVoucher = params['facId'];

      if( getVoucher !== undefined && getVoucher !== '' ){
        this.selectedGetVoucher = getVoucher;
        // this.searchPageServiceObj.getAggrigatorVoucher(getVoucher).subscribe( res=> {
          this.searchPageServiceObj.checkAggrigatorVoucher(getVoucher).then(
            (res) => {
              console.log(res);
              this.isLoaded = true;
              this.loadedAPiData = true;
              this.fetchedreservationData =  res['data'];
          if(this.fetchedreservationData['errors']){
            this.toasterObj.error('Voucher is not valid, please enter a valid voucher','Sorry');
         }else {
          this.reservationDate = this.fetchedreservationData['aggregator_detail'].start_time;
          this.pricePaid =  this.fetchedreservationData['aggregator_detail'].price_paid.USD;
          this.priceTermsConditions = this.fetchedreservationData['aggregator_detail'].price_paid.USD;
          this.selectedFacilityDetails = this.fetchedreservationData['facility_detail'];
          this.selectedFacilityDetails = this.fetchedreservationData['facility_detail'][0]
          this.facId = this.selectedFacilityDetails.id;
          localStorage.setItem('facId',this.facId);
          this.getFacilityId = this.facId;
          this.getFacilityName =  this.selectedFacilityDetails.full_name
          let customerDetails = this.fetchedreservationData['aggregator_detail'].customer_detail;
          this.aggregator_name = this.fetchedreservationData['aggregator_detail'].aggregator_name;
          this.voucher_name = this.fetchedreservationData['aggregator_detail'].voucher_name;
          
          this.newMonthlyDate =  this.reservationDate;
          this.monthlyDate=  this.reservationDate;
          let newDate = this.reservationDate.split("-");
          this.formatedDate =  newDate[1] + '/' + newDate[2] + '/' +  newDate[0];
        
          if(this.selectedFacilityDetails.active === "0") {
              this.toasterObj.error('This facility is inactive for now.','Sorry');
              this.routerObj.navigate(['/']);
            }

          this.searchPageServiceObj.getVehicleDetails(this.facId).then(
            (res) => {
              this.make=res['data'].makeModels;
            },
            (error) => { console.log('Error: ' + JSON.stringify(error)); }
          );

          this.searchPageServiceObj.getFacilityDetails(this.facId).then(
            (res) => {
              this.currentFacData = res;
              this.currentFacData = this.currentFacData['data'];
              this.no_sur_no_auto = this.currentFacData['no_sur_no_auto'];
              this.no_sur_auto = this.currentFacData['no_sur_auto'];
              this.sur_no_auto = this.currentFacData['sur_no_auto'];
              this.sur_auto = this.currentFacData['sur_auto'];
              this.notoversized=this.currentFacData['is_oversize_allowed'];
           this.overSizedMessage=this.currentFacData['oversize_not_allowed_message'];
          //  this.getFacilityName = this.currentFacData.facility_name_modified;
              //alert(this.no_sur_no_auto);
              //pricePaid
              // if(this.no_sur_no_auto.indexOf('$xxx.xx') > -1){

              // }
              // this.termsConditionText = this.no_sur_no_auto;

              
            },
               (error) => { console.log('Error: ' + JSON.stringify(error)); }
          );
        }

         if(localStorage.getItem('userid') )
        {
        if(1){

          this.userServiceObj.fetchUserDriver().subscribe((data:any)=>
          {
            let uData = data['data'];
           
            if(uData.driver_one!="" && uData.driver_one!=null){
              this.driveronereadonly = true
            }
            if(uData.driver_two!=""  && uData.driver_two!=null){
              this.drivertworeadonly = true;
              this.drriverShow = 1;
            }
            if(uData.driver_three!=""  && uData.driver_three!=null){
              this.driverthreereadonly = true;
              this.drriverShow = 2;  

            }
            this.formData.driver_one = uData.driver_one;
            this.formData.driver_two = uData.driver_two;
            this.formData.driver_three = uData.driver_three;
    
          });
          let namesData:any=localStorage.getItem('username');
          let namesDatas:any =namesData.split(' ');
          
          this.formData.firstname=namesDatas[0];
          this.formData.email=localStorage.getItem('email');
          this.userEmailForLog = localStorage.getItem('email');
          if(localStorage.getItem('phone_number') != undefined && localStorage.getItem('phone_number') != '' && localStorage.getItem('phone_number') != null && localStorage.getItem('phone_number') != 'null'  ) {
            this.formData.phone=localStorage.getItem('phone_number');
            this.validPhone = true;
          }
          let myString = namesData.substring(namesData.indexOf(' ')+1)
          if(namesDatas[1]==undefined)
          {
            myString='';
          }
          // if(myString != ''){
          // }
          this.formData.lastname=myString;
          this.userLogin=true;
          this.isLogin = true;
        }
        
        // clearInterval(timesruined);
      }

        },
        (error) => { 
          console.log(error);
          this.isLoaded = true;
          //let error = JSON.parse(errors['_body']);
          //let error = errors;
          let message = error.errors.message;
          this.toasterObj.error(message,'Sorry');
          this.routerObj.navigate(['/']);
        }
        );
      }
      let getUserId = localStorage.getItem('userid');
      let fId = localStorage.getItem('facId');

      this.searchPageServiceObj.fetchProfileVehicleDetails( fId, getUserId ).subscribe(
        (data:any)=> { 
          this.showSavedVehicles=true;
          this.savedvehicle=data.data;
          if(this.savedvehicle.length>0){
            this.saved_vehicle_height="105px"
          }
          if(this.savedvehicle.length>2){
            this.saved_vehicle_height="165px"
          }
          this.showCancelBtn=true;
          //this.savedmake=data.data;
        //  console.log(this.savedmake);
          // if(this.isLogin){
          //   //console.log('sssssss'+localStorage.getItem('vehicelinfo'));
    
          //       if(localStorage.getItem('vehicelinfo')!==null)
          //       {              
          //       this.showSavedVehicles= false;
          //       var retrievedObject =  JSON.parse(localStorage.getItem('vehicelinfo'));
    
          //       this.formData.make_vehicle= retrievedObject.make;
          //       this.vehicleDetails(retrievedObject.make);
          //       this.formData.make_modal=retrievedObject.model;
          //       this.formData.licenceplate = retrievedObject.license;
          //       this.formData.vehiclecolor = retrievedObject.color;
          //       this.formData.ep1 = retrievedObject.ep1;
          //       this.formData.ep2 = retrievedObject.ep2;
          //       this.formData.ep3 = retrievedObject.ep3;
          //       this.formData.ep4 = retrievedObject.ep4;
          //       localStorage.removeItem("vehicelinfo");
                
          //       }
          //   }
          
         },
         error => {
           if(error.status==500){
            this.showSavedVehicles=false;
            console.log(error.error.errors.message)
           }
           
         }
      )
      CustomCode.manageFormLabelsPosition();
      this.loggedUserName = CustomCode.getloggedInUserDetails();
      if (this.loggedUserName === '') {
        $('#secondloginbtn').removeClass('hidden');
        // $('#secondlogininfo').removeClass('hidden');
      } else {
       $('#secondloginbtn').addClass('hidden');
       // $('#secondlogininfo').addClass('hidden');
      }
      CustomCode.displayLoginUser();
    });
    CustomCode.loadMapOnInit();
    this.getMonthAndYear();

    this.staticObj.getCmsPageContent(this.page).subscribe((data:any) => {
      this.staticPageContent = data.result[0];
      this.isLoadeds = true;
    }, (error) => { console.log('Error: ' + JSON.stringify(error));
      this.isLoadeds = true;
      }
  ); 

  
      setTimeout(() => {
      this.ReservationEventLogStatusForBuyMonthlyClick(this.facId, this.getFacilityName, '', '', '' )
  }, 10000);

  setInterval( ()=> {
    if(localStorage.getItem('userid')){
      this.userLogin=true;
      this.isLogin = true;
    }else {
      this.isLogin = false;
      this.userLogin=false;
    }
    }, 500);
  }

  savedmake(savedmake: any) {
    throw new Error('Method not implemented.');
  }

  checkdate()
  {
    //this.monthlyDate = moment(this.newMonthlyDate).format('YYYY-MM-DD');
    this.monthlyDate = this.newMonthlyDate;
    //alert(2)
    this.validateCoupon();
  }
  sortArray(array)
  {
    if(!array || array === undefined || array.length === 0) return null;
  
      // array.sort((a: any, b: any) => {
      //   if (parseFloat(a.net_rate.replace(',','')) < parseFloat(b.net_rate.replace(',',''))) {
      //     return -1;
      //   } else if (parseFloat(a.net_rate.replace(',','')) > parseFloat(b.net_rate.replace(',',''))) {
      //     return 1;
      //   } else {
      //    return 0;
      //   }
      // });
      return array;
  }
  // setData(formData){
  //   if(!this.isLogin){
  //   localStorage.setItem('vehicelinfo',  JSON.stringify({'make':formData.make_vehicle,'model':formData.make_modal,'license':this.formData.licenceplate,'color':this.formData.vehiclecolor,'ep1':this.formData.ep1,'ep2':this.formData.ep2,'ep3':this.formData.ep3,'ep4':this.formData.ep4 }) );
  //   }
  // }
  getRate(rateDetails,rateDetailsNetRate,tax,name)
  {
    //alert(name);
    //let nameReplace=name.replace('  ',' ')
    //alert(this.couponapply.indexOf(nameReplace))
    // if(this.validPromocode==1)
    // {
    //   if(nameReplace in this.couponapply)
    //   {
    //     this.discount=this.couponapply[nameReplace];
    //   }else{
    //     let textMeasge=''
    //         if('1 Month' in this.couponapply)
    //         {
    //           textMeasge='1 Month '
    //         }
    //         if('12 Months' in this.couponapply)
    //         {
    //           textMeasge +='12 Months '
    //         }
    //         if('6 Months' in this.couponapply)
    //         {
    //           textMeasge +='6 Months '
    //         }
    //         if('3 Months' in this.couponapply)
    //         {
    //           textMeasge +='3 Months '
    //         }
    //         this.toasterObj.error('Coupon valid for '+textMeasge,'Sorry!')
    //         this.discount=0;
    //         this.discountPrice=0;
    //   }
    // }

    // this.discountWithApi=0;
    //   this.validPromocode = 0;
    //   this.discount=0;
    //   this.discountPrice=0;
    
    // this.checkName=name
    // this.rateDetails=rateDetails
    // this.rateDetailsNetRate=rateDetailsNetRate
    // this.taxForThis=tax
    // this.selectedvahicletype=name
    // sessionStorage.setItem('selectedvahicletype',name)
    // for(let data of this.respdata.facilityMonthlyTaxRates ){
    //   if(data.rate_description.name == this.selectedvahicletype)
    //   {
    //     this.selectedvahicletype_Id = data.id;
    //     break;
    //   }
    //  }
    //  this.validateCoupon();     
  }

  callMonthlyCustomer()
  {
   // (function(a,e,c,f,g,h,b,d){var k={ak:"876742851",cl:"oEyECNqX9nsQw5GIogM",autoreplace:"(888) 705-7845"};a[c]=a[c]||function(){(a[c].q=a[c].q||[]).push(arguments)};a[g]||(a[g]=k.ak);b=e.createElement(h);b.async=1;b.src="//www.gstatic.com/wcm/loader.js";d=e.getElementsByTagName(h)[0];d.parentNode.insertBefore(b,d);a[f]=function(b,d,e){a[c](2,b,k,d,null,new Date,e)};a[f]()})(window,document,"_googWcmImpl","_googWcmGet","_googWcmAk","script");
   /* commented for ga 8-7-2021 by seo team 
   gtag('config', 'AW-876742851/oEyECNqX9nsQw5GIogM', {
      'phone_conversion_number': '(888) 705-7845'
    });
    */
  }
  restrictNumeric(event) {
    CustomCode.restrictNumericInCard(event);
    }
  getMonthlyDate() {
    if ($('#monthlydate').val().toString().trim() !== '') {
      const mD = $('#monthlydate').val().toString().split('/');
      const fDate = mD[2] + '-' + mD[0] + '-' + mD[1];
      /* this.monthlyDate = fDate; */
    }
    $('#dpicker').css('display', 'block');
  }

  // redirectToSignUp() {
  //   $('button.close').trigger('click');
  //   this.routerObj.navigate(['/create-account']);
  // }

  continueAsGuest() {
    $('button.close').trigger('click');
    const userLat = CustomCode.timeSquareCoords.lat;
    const userLong = CustomCode.timeSquareCoords.long;
    const locationUser = CustomCode.timeSquareCoords.defaultLocation;
    this.routerObj.navigate(['/search', userLat, userLong, CustomCode.getDateForMapSearch(), CustomCode.facilityDefaultRadius, locationUser, CustomCode.defaultFacilityType, CustomCode.searchMode()]);
  }

  searchLocation() {
    let userLat = (<HTMLInputElement>document.getElementById('userlat')).value;
    let userLong = (<HTMLInputElement>document.getElementById('userlong')).value;
    let locationUser = (<HTMLInputElement>document.getElementById('locationname')).value;

    if (!userLat || !userLong || !locationUser) {
      userLat = CustomCode.timeSquareCoords.lat;
      userLong = CustomCode.timeSquareCoords.long;
      locationUser = CustomCode.timeSquareCoords.defaultLocation;
    }
    this.routerObj.navigate(['/search', userLat, userLong, CustomCode.getDateForMapSearch(), CustomCode.facilityDefaultRadius
    , locationUser, CustomCode.defaultFacilityType, CustomCode.searchMode()]);
  }
  redirecttoList(){
    this.routerObj.navigate(['/', 'oversized']);
    this.modalRef.hide();
  }

  signInUser(formValues, formObj: NgForm) {
    this.userServiceObj.loginUser(formValues)
      .subscribe(
      data => {
        this.parseData = data;
        if (this.parseData.status === 201) {
          CustomCode.markLoginUser(this.parseData.data);
          this.loggedUserName = CustomCode.getloggedInUserDetails();
          if (this.loggedUserName === '') {
            $('#secondloginbtn').css('display', 'block');
          } else {
            $('#secondloginbtn').css('display', 'none');
          }
          CustomCode.displayLoginUser();
          $('button.close').trigger('click');
          $('#secondloginbtn').css('display', 'none');
          formObj.resetForm();

        }
      },
      error => {
        /* FormValidationErrors.displayErrors(error.errors); */
        const errorsList = error.errors;
        if (errorsList.message !== 'Validation Errors') {
          this.toasterObj.error(errorsList.message, 'Sorry!');
        } else {
          for (const key in errorsList.detail) {
            if (errorsList.detail.hasOwnProperty(key)) {
              if (typeof errorsList.detail === 'undefined') {
                this.toasterObj.error(errorsList.message, 'Sorry!');
              } else {
                this.toasterObj.error(errorsList.detail[key], 'Sorry!');
              }
            }
          }
        }
      }
      );
  }

  logOut() {
    CustomCode.userLogout();
    CustomCode.displayLoginUser();
    $('#secondloginbtn').css('display', 'block');
    this.routerObj.navigate(['/']);
  }

  UpdatePromocodeStatus(promocodeStatus,text){
    this.modalRef.hide();
    this.monthlyPayment(promocodeStatus,text,0)
    
  }

  monthlyPayment(formValue, formObj: NgForm,val) {
    if(this.dontahowmessge != '' ){
      // this.showErrorMsg = 1;
      //this.toasterObj.error(this.dontahowmessge, 'Sorry!');
      return;
    }
    this.phoneLengthErrMsg = '';
    // this.showErrorMsg = 0;
    if(this.validPromocode==2)
    {
      formValue.validationRequired=0
    }else if(this.validPromocode==1)
    {
      formValue.validationRequired=1
    }else {
      formValue.validationRequired=''
    }
    formValue.processing_fees=this.processing_fees_changes
    this.paymentError=false;
    this.paymentError1=false
    formValue.description= 'Web Subscription for '+this.getFacilityName+' beginning at '+this.monthlyDate
    // formValue.price_main=this.main_price_with_or_withouot_surcharge
    formValue.price_main=this.pricePaid;
    let selectd_text=$("input[name='rateDetails']:checked").val()
    let text_span=$("#srate"+selectd_text).text();
    formValue.monthly_duration_value=text_span
    formValue.make_id=this.make_id;
    formValue.model_id=this.model_id;
    if(this.extraCharge)
    {
      formValue.extraCharge=1
      if(this.feeType=='oversized')
      {
        formValue.key='oversize_fee'
        formValue.keyValue=this.price_surcharge_value
      }
      if(this.feeType=='exotic')
      {
        formValue.key='exotic_fee'
        formValue.keyValue=this.price_surcharge_value
      }
      if(this.feeType=='electric')
      {
        formValue.key='electric_fee'
        formValue.keyValue=this.price_surcharge_value
      }
  
      //oversize_fee,exotic_feeelectric_free
    }else{
      formValue.extraCharge=0
      formValue.key=''
      formValue.keyValue=''
    }
      
     
const reg =/<(.|\n)*?>/g; 

if (reg.test(formValue.vehiclemake) == true) {
  this.toasterObj.error('HTML Tags are not allowed in vehicle make.', 'Sorry!');
  return false;
}else{
  if (reg.test(formValue.vehiclemodel) == true) {
    this.toasterObj.error('HTML Tags are not allowed in vehicle model.', 'Sorry!');
    return false;
  }else{
    if (reg.test(formValue.licenceplate) == true) {
      this.toasterObj.error('HTML Tags are not allowed in licence plate.', 'Sorry!');
      return false;
    }else{
      if (reg.test(formValue.vehiclecolor) == true) {
        this.toasterObj.error('HTML Tags are not allowed in vehicle color.', 'Sorry!');
        return false;
      }else{
        if (reg.test(formValue.name_on_card) == true) {
          this.toasterObj.error('HTML Tags are not allowed in name on card.', 'Sorry!');
          return false;
        }else{
          if (reg.test(formValue.promocode) == true) {
            this.toasterObj.error('HTML Tags are not allowed in Promocode.', 'Sorry!');
            return false;
          }else{
            
          }
        } 
      }
    }                          
  }
}                
                
if(formValue.phone != undefined && formValue.phone != ''){
let ph = formValue.phone.replace(/\D/g, '');
  if(ph.length !==0 && ph.length < 10){
    formObj.form.controls['phone'].setErrors({'invalid': true});
    return false;
}
}
if(!this.isLogin){
  this.toasterObj.error('Please login or use another option', 'Sorry!');
  return false;
}

if(formValue.promocode && val==1 && this.validPromocode != 1 ){
// hide the popup was opening on apply promocode on submit form
// let el: HTMLElement = this.PromocodePopup.nativeElement as HTMLElement;
//   el.click();
//   return false;
}
this.getZipCode = formValue.zipcode;
let cryptoData:any;
  if(this.enableAutoPay || this.surchargeValue ){
    cryptoData = this.cryptoObj.encrypt({'name_on_card': formValue.name_on_card, 'card_number': formValue.card_number.replace(/\D/g, ''), 'expiration_date': formValue.expiration_date, 'security_code': formValue.security_code, 'zip_code': formValue.pzipcode});
  }else{
    cryptoData = '';
  }

  if(this.showSavedVehicles==false){
    let mkid=this.make[formValue.make_vehicle].id;
    let mdlid=this.make[formValue.make_vehicle].mst_model[formValue.make_modal].id;
    var match = 0;
    if(this.savedvehicle!==undefined){
        let obj = this.savedvehicle.find(o => {
          if(o.vehicle_license_plate==undefined){
            o.vehicle_license_plate="";
          }
          if(formValue.licenceplate==undefined){
            formValue.licenceplate="";
          }
          
          if (o.brand_id == mkid &&
              o.model_id == mdlid &&
              o.vehicle_license_plate == formValue.licenceplate &&
              o.vehicle_color.toLowerCase() == formValue.vehiclecolor.toLowerCase()
             ) {
            match = 1;
            return true;
          }
          
        });
      }
    if(match==1){
      this.toasterObj.error('This vehicle already added to the list', 'Sorry!');
      return false;
    }
    formValue.vehiclemake=this.make[formValue.make_vehicle].name
    formValue.vehiclemodel=this.make[formValue.make_vehicle].mst_model[formValue.make_modal].name

    if (formValue.ep1) {
      formValue.resident='1';
    }
    else{
      formValue.resident='0';
    }
    if (formValue.ep2) {
      formValue.non_resident='1';
    }
    else{
      formValue.non_resident='0';
    }
    if (formValue.ep3) {
      formValue.place='1';
    }
    else{
      formValue.place='0';
    }
    if (formValue.ep4) {
      formValue.leisure='1';
    }
    else{
      formValue.leisure='0';
    }
  }else{
  //  console.log('sssss'+this.selectedVehicleIndex);
    if(this.showSavedVehicles==true && (this.selectedVehicleIndex==undefined )  ){    
      this.toasterObj.error('Please choose or enter vehicle information', '');
      return false;  
    }
    formValue.vehiclemake=this.savedvehicle[this.selectedVehicleIndex].vehicle_make;
    formValue.vehiclemodel=this.savedvehicle[this.selectedVehicleIndex].vehicle_brand;
    formValue.vehiclecolor=this.savedvehicle[this.selectedVehicleIndex].vehicle_color;
    formValue.licenceplate=this.savedvehicle[this.selectedVehicleIndex].vehicle_license_plate;

    formValue.make_id=this.savedvehicle[this.selectedVehicleIndex].brand_id;
    formValue.vehicle_id=this.savedvehicle[this.selectedVehicleIndex].id;

    formValue.resident=this.savedvehicle[this.selectedVehicleIndex].resident;
    formValue.non_resident=this.savedvehicle[this.selectedVehicleIndex].non_resident;
    formValue.place=this.savedvehicle[this.selectedVehicleIndex].place;
    formValue.leisure=this.savedvehicle[this.selectedVehicleIndex].leisure;
    //formValue.make_modal=this.savedvehicle[this.selectedVehicleIndex].vehicle_license_plate;
    //formValue.make_vehicle=this.savedvehicle[this.selectedVehicleIndex].vehicle_license_plate;
  }
    if( formValue.promocode && localStorage.getItem('is_landing_page_monthly') ){
    formValue.url_referral='1';
  } else {
    formValue.url_referral = '0'
  }
  formValue.aggregator_name = this.aggregator_name;
  formValue.voucher_name = this.selectedGetVoucher;
  formValue.is_aggregator_booking = this.is_aggregator_booking;
  

  formValue.discountPrice=this.discountPrice;
  this.disableSubmit = true;
   if(this.isLogin){
     this.callForCreateMonthlyAccount(formValue, this.rateDetails, this.selectedvahicletype_Id, this.facId, cryptoData, this.monthlyDate);
   }else {
    window.scrollTo(1200, 1200);
    // let signupData = {
    //   email: formValue.email,
    //   name: formValue.firstname+ " "+ formValue.lastname,
    //   password: formValue.password,
    //   phone: formValue.phone,
    //   username: formValue.email,
    // }
    //  this.createUserAccountOnMonthlyPage(signupData, formValue, this.rateDetails, this.selectedvahicletype_Id, this.facId, cryptoData, this.monthlyDate);
   }

  }

  // for makeMonthlyReservation 

  callForCreateMonthlyAccount(formValue, rateDetails, selectedvahicletype_Id, facId, cryptoData, monthlyDate){

  this.reservationServObj.makeMonthlyReservationAggregator(formValue, this.rateDetails, this.selectedvahicletype_Id, this.facId, cryptoData, this.monthlyDate, this.device_type, this.enableAutoPay,this.surchargeValue)
  .subscribe(
  data => {
    sessionStorage.removeItem('monthlyPromocode');
    this.disableSubmit = false
       this.parseData = data['data'];//buy-monthly/thanks/:facId
  //tracking_code
  CustomCode.markTrackingCode(this.parseData.tracking_code);

        /* commented for ga 8-7-2021 by seo team
  
              gtag_report_conversion(this.gtmUrl);

              gtag('event', 'conversion', {
                'send_to': 'AW-876742851/p-W2CPCis3UQw5GIogM',
                'eventCallback':window['callbackgtm'],
              });

              ga("Monthly-payment-success", {
                "id": this.getFacilityId,
                "name": this.getFacilityName,
                "category": "Monthly parking",
                "quantity": 1,
                "device_details":this.device_type
              });
              ga('send', 'event', { eventCategory: "Monthly payment Success", eventLabel:CustomCode.BASEURL+'aggregator-monthly-booking-confirmation/' +' -- tracking_code :'+ this.parseData.tracking_code, eventAction: 'click'});
              */

              // kochava.activity('conversion_Monthly', {
  //   user_name: formValue.display_name,                      // Name or ID is required.
  //   id: formValue.id,
  //   price: this.rateDetailsNetRate,
  //    category: 'Monthly',
  //    user_id :user_id_ai,
  //   });
  let user_id_ai='0';
  let user_email_ai='0';
  if(localStorage.getItem('userid'))
  {
    user_id_ai=localStorage.getItem('userid');
  }else{
    user_id_ai='0';
 }
 if(localStorage.getItem('email'))
  {
    user_email_ai=localStorage.getItem('email');
  }
  (<any>window).dataLayer.push(
    {
    'event': 'Monthly_Reservation_Aggregator',
      'UserId' :user_id_ai,
      'email': user_email_ai,
      'FacilityPageLocation': this.routerObj.url,
      'startDate': this.monthlyDate,
      'accountNumber': this.parseData.account_number,
      'Facility': [{
        'name': this.getFacilityName,                      // Name or ID is required.
        'id': this.facId,
        'price': this.pricePaid,
        'category': 'Monthly - Aggregator',
        'confirmationCode': this.parseData.tracking_code
       }]
  });
  this.fetchUserStatusForTracking();

    let errorMsg = '';
    localStorage.removeItem('is_from_landing');
    localStorage.removeItem('promocode');
    localStorage.removeItem('is_landing_page_monthly');
    this.updateReservationEventLogStatus( this.getFacilityId, this.getFacilityName, this.getZipCode , errorMsg,this.parseData.tracking_code);
    let ls_loyality  = '0';
      if(localStorage.getItem('is_loyalty_active')){
      ls_loyality = localStorage.getItem('is_loyalty_active')
      }
      this.routerObj.navigate(['aggregator-monthly-booking-confirmation/',this.parseData.tracking_code,ls_loyality]);
  
  },
  error => {
    //this.paymentError=true;
    //this.paymentError1=true;
    this.disableSubmit = false
    let errorMsg = '';
    /* FormValidationErrors.displayErrors(error.errors); */
    const errorsList = error.errors;
    this.errormessage=errorsList.message
    if(errorsList.message =='Something went wrong while creating the account, Amount seems to be invalid' || errorsList.message =='Something went wrong while creating the account, please contact Customer Service in case issue persists.')
    {
      this.paymentError=false;
      this.paymentError1=false;
      this.errormessage=errorsList.message
    }else{
      if( this.errormessage.indexOf('unable to authorize')>-1)
      {
        this.paymentError=true;
        this.paymentError1=false;
      }else{
        this.paymentError=false;
        this.paymentError1=false;
      }
      
    }
    if (errorsList.message !== 'Validation Errors') {
      if ( !this.paymentError ){
        this.toasterObj.error(errorsList.message, 'Sorry!');
      }
      errorMsg = errorsList.message;
    } else {
      for (const key in errorsList.detail) {
        if (errorsList.detail.hasOwnProperty(key)) {
          if (typeof errorsList.detail === 'undefined') {
            this.toasterObj.error(errorsList.message, 'Sorry!');
            errorMsg = errorsList.message;
          } else {
            this.toasterObj.error(errorsList.detail[key], 'Sorry!');
            errorMsg = errorsList.detail[key];
            errorMsg =errorMsg[0]
          }
        }
      }
    }
    // console.log(errorMsg);
    this.updateReservationEventLogStatus( this.getFacilityId, this.getFacilityName, this.getZipCode , errorMsg,'');
  }
  );

}

  showPicker() {
    $('#dpicker').css('display', 'block');
  }

  getMonthAndYear() {
    var fullYear = new Date().getFullYear();
    for (var i = 0; i < 15; i++) {
        this.years.push({
          label: fullYear + i,
          value: parseInt(String(fullYear + i).slice(2, 4))
        });
    }
    var month = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
    for (var i = 1; i <= 12; i++) {
        
        this.months.push({
          label: month[i-1] ,
          value: String("00" + i).slice(-2)
        });
      
    }
  }

  // updateFacilitySchedule(id) {
  //   this.searchPageServiceObj.getFacilitySchedule(id).then(
  //     (res) => {
  //       this.garageWorkingHours = res;
  //       this.garageWorkingHours = this.garageWorkingHours.data;
  //     },
  //     (error) => { 

  //     }
  //   );
  // }
  // updateFacilityScheduleFormatted(id) {
  //   this.searchPageServiceObj.getFacilityScheduleFormatted(id).then(
  //     (res) => {
  //       this.FgarageWorkingHours = res;
  //       this.FgarageWorkingHours = this.FgarageWorkingHours.data;
  //     },
  //     (error) => { 

  //     }
  //   );
  // }
  
  restSpace(event: any){
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }

  restDigit(event: any){
    console.log(event.target.value.length);
    if (event.target.value.length > 1 && event.keyCode != 8 && event.keyCode != 9) {
      event.preventDefault();
      this.toasterObj.error('Please enter valid two digit state code.', 'Sorry!');
      return false;
    }else{
      return true;
    }
  }

  // restDigit1(event: any){
  //   console.log(event.clipboardData.getData('text/plain'));
  //   console.log(event.clipboardData.getData('text/plain').length)
  //   if (event.clipboardData.getData('text/plain').length > 1 && event.keyCode != 8 && event.keyCode != 9) {
  //     event.preventDefault();
  //     this.toasterObj.error('Please enter valid two digit state code.', 'Sorry!');
  //     return false;
  //   }else{
  //     return true;
  //   }
  // }

  restDigitZip(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
  }

  checkFormat(value)
  {
    if(value.toString().length==7)
    {
      let a = value.toString();
      let b = ",";
      let position = 1;
      let output = [a.slice(0, position), b, a.slice(position)].join('');
      return output
    }else{
      return value;
    }
  }
  checkFormatForList(value)
  {
    if(value.toString().length==7)
    {
      let a = value.toString();
      let b = ",";
      let position = 1;
      let output = [a.slice(0, position), b, a.slice(position)].join('');
      if(output!= undefined && output != null){
        output = output.toString().replace('.00','');
      }
      return output
    }else{
      // console.log(value, '11')
      if(value!= undefined && value != null){
        value = value.toString().replace('.00','');
      }
      return value;
    }
  }
  phoneFormat(event: any) {
    const pattern = /[0-9\)\-\(\.\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar) && event.keyCode != 9) {
      event.preventDefault();
    }
  }

  licenceplateFormat(event:any){
      const pattern = /^[a-zA-Z0-9-]/;
      let inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar) ) {
        event.preventDefault();
      }
  }

  updateReservationEventLogStatus(facility_id, facility_name, getZipCode, errorMsg, iqcode ){
            let currentLatitude = (localStorage.getItem('currentLatitude')) ? localStorage.getItem('currentLatitude') : '';
            let currentLongitude = (localStorage.getItem('currentLongitude')) ? localStorage.getItem('currentLongitude') : '';
            let getUserId='0';
            
            if(localStorage.getItem('userid')){  
               getUserId=localStorage.getItem('userid'); }
            else{ getUserId='0'; } 
            let reservation_status_data = {
               user_id:getUserId,
               event_name:"Monthly - Aggregator Voucher Redemption reservation complete for voucher "+ this.selectedGetVoucher +' with price $'+this.pricePaid,
               latitude:currentLatitude,
               longitude:currentLongitude,
               facility_id:facility_id,
               facility_name:facility_name,
               zipcode:getZipCode,
               app_version:"",
               error_message:errorMsg,
               device:"web",
               device_detail:this.device_type,
               os_version:"",
               booking_code:iqcode,
               email_id: this.userEmailForLog 
               }
        
        // this.reservationServObj.updateReservationEventLog(reservation_status_data).subscribe(
        //   data => {
        //   this.reservationDataEventLog = data
        // },
        // error => {

        // }
      //)
    }
    checkValue(val)
    {
      this.sameAsabove=val
    }
    setToexpirationField(reference)
    {
      this.formData.exp1=reference.value;
    }
    unmask(val){
      var val1 = val;
      return val.replace(/[/_ ]/g, '');
    }

    vehicleDetails(val)
    {
      this.dontahowmessge='';
      if(val){
        this.modalName=this.make[val].mst_model
        this.make_id=this.make[val].id
        if(this.make[val].sort_at_last==0)
        {
          this.formData.make_modal=''
        }else{
          this.formData.make_modal=0
        }
        this.extraCharge=0
        this.extraChargeCheck=false
        this.formData.surcharge_amount=false;
      }else{
        this.extraCharge=0;
        this.formData.make_modal='';
        this.surchargeValue = 0;
        this.modalName=[];
      }
      
    }
    // vehicleDetailsWithModal(make,modal)
    // {
    //   if(!modal){
    //     this.dontahowmessge='';
    //   }
    //   this.extraCharge=0;
    //   this.surchargeValue = 0;
    //   this.extraChargeCheck=false
    //   this.formData.surcharge_amount=false;
    //   let value_of_vehicle= this.make[make].mst_model[modal]
    //   this.price_surcharge=value_of_vehicle.price_description
    //   this.price_surcharge_value=value_of_vehicle.price
    //   this.model_id=value_of_vehicle.id
    //   let name_surcharge=value_of_vehicle.name
    //   this.des_surchage=value_of_vehicle.description
    //   this.heading=value_of_vehicle.heading
    //   let is_monthly_exotic_charge=value_of_vehicle.is_monthly_exotic_charge
    //   let is_monthly_oversized_fee=value_of_vehicle.is_monthly_oversized_fee
    //   let is_monthly_electric_surcharge=value_of_vehicle.is_monthly_electric_surcharge
    //   if(is_monthly_oversized_fee && this.notoversized==0)
    //   {
    //     is_monthly_oversized_fee=0;
    //      this.dontahowmessge=this.overSizedMessage
    //     this.notShow=0;
    //     this.toasterObj.error(this.dontahowmessge, 'Sorry!');
    //   }else{
    //     this.dontahowmessge=''
    //     this.notShow=1;
    //   }
    //   if(this.price_surcharge_value=='')
    //   {
    //     is_monthly_oversized_fee=0;
    //     is_monthly_electric_surcharge=0;
    //     is_monthly_exotic_charge=0;
    //   }
    //   if(is_monthly_oversized_fee || is_monthly_exotic_charge ||is_monthly_electric_surcharge)
    //   {
    //     this.extraCharge=1;
    //     this.surchargeValue = 1;
    //     if(is_monthly_oversized_fee)
    //     {
    //       this.feeType='oversized'
    //       this.surchargeType='Oversized Vehicle'
    //     }
    //     if(is_monthly_exotic_charge)
    //     {
    //       this.feeType='exotic'
    //       this.surchargeType='Exotic Vehicle'
    //     }
    //     if(is_monthly_electric_surcharge)
    //     {
    //       this.feeType='electric'
    //       this.surchargeType='Electric Vehicle'
    //     }
    //   }else{
    //     this.extraCharge=0;
    //     this.surchargeValue = 0;
    //   }
    // }
  //   checkFormatWithSurcharge(value)
  // {
  //   value=(parseFloat(value)-this.discountPrice)
  //   value=value+this.processing_fees_changes
  //   if(this.price_surcharge_value=='')
  //   {
  //     this.price_surcharge_value=0;
  //   }
  //   if(value.toString().length==7)
  //   {
  //     let a = value.toString();;
  //     let b = ",";
  //     let position = 1;
  //     let output = [a.slice(0, position), b, a.slice(position)].join('');
      
  //     if(this.extraChargeCheck)
  //     {
  //       this.main_price_with_or_withouot_surcharge=parseFloat(output)+parseFloat(this.price_surcharge_value)
  //       return this.main_price_with_or_withouot_surcharge
  //     }else{
  //       this.main_price_with_or_withouot_surcharge=output
  //       return output;
  //     }
  //    // return 
  //   }else{
  //     if(this.extraChargeCheck)
  //     {
  //       this.main_price_with_or_withouot_surcharge = parseFloat(value)+parseFloat(this.price_surcharge_value)
  //       return this.main_price_with_or_withouot_surcharge
  //     }else{
  //       this.main_price_with_or_withouot_surcharge=value
  //       return value;
  //     }
      
  //   }
   
  // }
  // surcharge(val)
  // {
  //   this.extraChargeCheck=val
  //   if(val){
  //     this.showErrorMsg = 0;
  //   }else {
  //     this.showErrorMsg = 1;
  //   }
  // }
  onItemChange(val)
  {
  }
  changeTime(val)
  {
    let dates=val.split('-');
    return  dates[1]+'/'+dates[2]+'/'+dates[0];
  }

  showtimefornxtornot(text)
    {
      var res = text.split(':');
      if(res[0]>24)
      {
        return '(next day)'
      }else{
        return '';
      }
    }

    acceptTermsCondition(){
      this.tc_accept_btn_clicked=true;
      this.formData.terms = true
    }

    closeTermsPopup(){
      if(this.tc_accept_btn_clicked!=true){
        document.getElementById('tcevnt').scrollTop=0; 
        this.disabled_btn=true;
        
        this.disabled=true;
        $('#terms_checkbox').prop('checked',false);
        this.formData.terms = false;
      }
      else{
        this.disabled=false;
        this.formData.terms = true;
        document.getElementById('tcevnt').scrollTop=0;
      }
      //this.formData.terms = false
    }
    openpopupsetup(){
      const myContainer = document.getElementById('tcevnt');
    
    myContainer.addEventListener('scroll', () => {
  
    console.log('offsetHeight',myContainer.offsetHeight);
    console.log('scrollTop',myContainer.scrollTop);
    console.log('scrollHeight',myContainer.scrollHeight);
    if (myContainer.offsetHeight + myContainer.scrollTop >= myContainer.scrollHeight) {
      this.disabled=false;
    }
    });
      if(this.tc_accept_btn_clicked!=true){
        document.getElementById('tcevnt').scrollTop=0;
        this.disabled_btn=true;
        this.disabled=true;
        $('#terms_checkbox').prop('checked',false);
        this.formData.terms = false;
        document.getElementById('tcevnt').scrollTop=0;
        $("#tcevnt").animate({scrollTop:0}, "smooth");
        setTimeout(()=>{
          this.disabled=true;
        }, 1000);
      }
      
      
      
    }

    showSavingFlexibilityMsg(msg){
      if(msg){
        let string = msg.toLowerCase()
        if(string.includes("6 month") || string.includes("6 months") ){
          return true;
        }
      }
      
      //
    }
    redirectToSignUp()
    {
      localStorage.setItem('frombuymonthly','1');
      localStorage.setItem('monthlyParkingDates',this.monthlyDate);
      localStorage.setItem('monthlyParkingIds',this.selectedGetVoucher);
    }
    redirectToSignUpnew()
    {
      
      if(localStorage.getItem('monthlyParkingId')){
        localStorage.setItem('notRemove', '1')
      }
      $('button.close').trigger('click');
      this.routerObj.navigate(['/create-account']);
    }

    createUserAccountOnMonthlyPage(signupdata, formValue, rateDetails, selectedvahicletype_Id, facId, cryptoData, monthlyDate){
      this.userServiceObj.registerUser(signupdata)
      .subscribe(
      data => {
        this.respdata = data;
        if (this.respdata.status === 201) {
            /* #start login user after registration */
            const userVal = {
              user:{
                name:this.respdata.data.user.name, 
                id:this.respdata.data.user.id,
                email:this.respdata.data.user.email,
                phone:this.respdata.data.user.phone,
                is_loyalty:this.respdata.data.user.is_loyalty,
                is_loyalty_active:this.respdata.data.user.is_loyalty_active
              },
              access_token:this.respdata.data.session.access_token,
              refresh_token:this.respdata.data.session.refresh_token,
              expires_in:this.respdata.data.session.expires_in,
              token_type:this.respdata.data.session.token_type,
            }
            localStorage.setItem('username', this.respdata.data.user.name)
            CustomCode.markLoginUser(userVal);
            let isFirstRegister = this.respdata.data.is_new_user;

            this.callForCreateMonthlyAccount(formValue, rateDetails, selectedvahicletype_Id, facId, cryptoData, monthlyDate)
            
            /* #end login user after registration */
          }
      },
      error => {
        this.disableSubmit = false;
        /* FormValidationErrors.displayServerErrors('signuperrors-container', error.errors); */
        const errorsList = error.error.errors;
        if (errorsList.message !== 'Validation Errors') {
          this.toasterObj.error(errorsList.message, 'Sorry!', {enableHtml:true,  toastClass:'toast register-toaster'});
        } else {
          for (const key in errorsList.detail) {
            if (errorsList.detail.hasOwnProperty(key)) {
              if (typeof errorsList.detail === 'undefined') {
                this.toasterObj.error(errorsList.message, 'Sorry!', {enableHtml:true,  toastClass:'toast register-toaster'});
              } else {
                this.toasterObj.error(errorsList.detail[key], 'Sorry!', {enableHtml:true,  toastClass:'toast register-toaster'});
              }
            }
          }
        }
      }
      );
    }

    checkPasswordLength(password){
      if(password.length>0 && password.length<8){
        this.passwordLengthErrMsg = 'Password must be atleast 8 characters long';
        this.validPwd = false;
      } else {
        this.passwordLengthErrMsg = '';
        if(password.length>7){
          this.validPwd = true;
        } 
      }
    }

    // checkPhoneLength(phoneNumber){
    //   let phone = phoneNumber.replace(/\D/g, '');
    //   if(phone.length>=0 && phone.length<10){
    //     this.phoneLengthErrMsg = 'Phone Number must be 10 digit long';
    //     this.validPhone = false;
    //   } else {
    //     this.phoneLengthErrMsg = '';
    //     this.validPhone = true;
    //   }
    //   this.checkDisableBtn = 0;
    // }
    checkPhoneLength(phoneNumber){
      let phone = phoneNumber.replace(/\D/g, '');
      if(phone.length>0 && phone.length<10){
        this.phoneLengthErrMsg = 'Phone Number must be 10 digit long';
      } else {
        this.phoneLengthErrMsg = '';
      }
    }

    checkConfirmPassword(cPassword){
      if(cPassword.length>0){
        this.cPasswordReqMsg = false
      }else {
        this.cPasswordReqMsg = true
      }
      if(cPassword.length>0 && this.formData['password'] !== cPassword){
        this.cPasswordErrMsg = 'Confirm Password and Password should be same';
        this.validCPwd = false;
      }else { 
        this.cPasswordErrMsg = '';
        if(cPassword.length>7){
          this.validCPwd = true;
        }
       }
    }

    enableButton(){
      this.disableSubmit = false;
    }
    // discountData(price)
    // {
    //   let discountValue=0;
    //   if(this.discount>0)
    //   {
    //     this.discountPrice=discountValue=(price*this.discount)/100
    //     return discountValue.toFixed(2);
    //   }
      
    // }

    
discountData(price)
{
  //alert(2)
  let discountValue=0;
  //this.discountType=this.couponapply1[p].Promocode_type;
  if(this.discountType=='value' && this.discountWithApi>0)
  {
    //console.log(this.minimum_transaction_applicable+'min');
    //console.log(this.minimum_transaction_applicable+'price');
    //alert(price);
    if(parseFloat(this.minimum_transaction_applicable.toString()) <= parseFloat(price))
    {
      //alert(2)
      if(this.discountWithApi>parseFloat(price))
      {
        //alert(this.discountWithApi)
        this.discountWithApi=parseFloat(price);
        //alert(this.discountWithApi)
      }
      this.discount=this.discountPrice=discountValue=this.discountWithApi
      if(this.successautoload==0)
      {
        this.successautoload=1
        this.toasterObj.success('Promocode is successfully applied','Great');
      }
      
      return discountValue.toFixed(2);
    }else{
      this.discount=0
      this.discountPrice=0;
      if(this.autoload==0)
        {
          this.autoload=1
          this.toasterObj.error(this.minimun_amount_error_msg,'Sorry');
        }
      
    }
    
  }else if(this.discountType=='percentage' && this.discountWithApi>0){
    
      this.discountPrice=discountValue=(price*this.discountWithApi)/100
     // console.log(this.discountPrice)
      if(parseFloat(this.discountPrice.toString())>parseFloat(this.max_discount_applicable.toString()))
      {
         //console.log('Hello')
        discountValue=this.discountPrice=parseFloat(this.max_discount_applicable.toString())
        if(this.autoload==0)
        {
          this.autoload=1
          this.toasterObj.error(this.max_percentage_amount_error_msg,'Sorry');
        }
        
      }
      if(this.successautoload==0)
      {
        this.successautoload=1
        this.toasterObj.success('Promocode is successfully applied','Great');
      }
      this.discount=discountValue;
      return parseFloat(discountValue.toString()).toFixed(2);
  }
  // this. max_discount_applicable=this.couponapply1[p].max_discount_applicable;
  // this.minimum_transaction_applicable=this.couponapply1[p].minimum_transaction_applicable;
  // if(this.discount>0)
  // {
  //   this.discountPrice=discountValue=(price*this.discount)/100
  //   return discountValue.toFixed(2);
  // }
  
}
    formatChange(val)
    {
      return val.toFixed(2)
    }
    // validateCoupon()
    // {
    //   if(sessionStorage.getItem('monthlyPromocode'))
    //   {
    //   let data={}
    //   let promocode=sessionStorage.getItem('monthlyPromocode');
    //   this.monthlyPromocodeApplied = promocode
    //     data = {
    //       'promocode' : promocode
    //     }
    //   this.userServiceObj.validatePromoCodeMonthly(data)
    // .then(
    //   data => {
    //      if (!data['errors']) {
    //       let record=data['data']
    //       this.validPromocode=record.is_validation_required
    //       this.couponapply= record.monthly_rates_discount
    //       let nameReplace=this.selectedvahicletype.replace('  ',' ')
    //       this.validPromocode=1;
    //       if(this.validPromocode==1)
    //       {
    //         if(nameReplace in this.couponapply)
    //         {
    //           this.discount=this.couponapply[nameReplace];
    //         }else{
    //           this.discount=0;
    //           this.discountPrice=0;
    //           let textMeasge=''
    //           if('1 Month' in this.couponapply)
    //           {
    //             textMeasge='1 Month '
    //           }
    //           if('12 Months' in this.couponapply)
    //           {
    //             textMeasge +='12 Months '
    //           }
    //           if('6 Months' in this.couponapply)
    //           {
    //             textMeasge +='6 Months '
    //           }
    //           if('3 Months' in this.couponapply)
    //           {
    //             textMeasge +='3 Months '
    //           }
    //           this.toasterObj.error('Coupon valid for '+textMeasge,'Sorry!')
    //         }
    //       }else{
    //         this.discount=0;
    //         this.discountPrice=0;
    //         if(this.validPromocode==2)
    //         {
    //           this.toasterObj.success('Promo vouchers will be emailed in separate email after purchase.','Success!')
    //         }
    //         if(this.validPromocode==0)
    //         {
    //           this.toasterObj.success('Promo vouchers will be emailed in separate email after purchase.','Success!')
    //         }
    //       }
    //     }else{
    //       this.toasterObj.success('Promo vouchers will be emailed in separate email after purchase.','Success!')
    //       this.discount=0;
    //       this.discountPrice=0;
    //     }

        
    //   },
    //   error => {
    //     sessionStorage.removeItem('monthlyPromocode')
    //     this.toasterObj.success('Promo vouchers will be emailed in separate email after purchase','Success!')
    //     this.discount=0;
    //     this.discountPrice=0;

    //   }
    // )
      
    // }
     
    //  // this.discount=10;
    //  // alert(this.selectedvahicletype)
      
    // }

    validateCoupon()
    {
      //alert(this.autoload)
     
      //this.successautoload=0
      //alert()
      if(sessionStorage.getItem('monthlyPromocode'))
      {
      let data={}
      let duration=this.selectedvahicletype.split(' ');;
      let promocode=sessionStorage.getItem('monthlyPromocode');
      this.monthlyPromocodeApplied = promocode
      let userid:any=0
      
      if(localStorage.getItem('userid'))
      {
        userid=localStorage.getItem('userid');
        data = {
          'promocode' : promocode,
          'reservation_start_date':this.monthlyDate,
          'is_payment_page' : 1,
          'deviceType':this.device_type,
          'duration':duration[0],
          'user_id':userid
        }
      }else{
        data = {
          'promocode' : promocode,
          'reservation_start_date':this.monthlyDate,
          'is_payment_page' : 1,
          'deviceType':this.device_type,
          'duration':duration[0],
        }
      }
      this.userServiceObj.validatePromoCodeMonthly(data)
    .then(
      data => {
        //sessionStorage.removeItem('monthlyPromocode')
        if (!data['errors']) {
          let record=data['data']
          this.validPromocode=record.is_validation_required
          this.couponapply1= record.monthly_rates_discount
          //this.couponapply1= this.couponapply1
          let nameReplace=this.selectedvahicletype
          let p=-1;
          //alert(nameReplace);
          for(let t=0;t<this.couponapply1.length;t++)
          {
           // alert(this.couponapply1[t].package)
            if(this.couponapply1[t].package==nameReplace)
            {
             // alert(2)
              p=t;
              break;
            }
          }
        // alert(p)
          //this.validPromocode=1;
          if(this.validPromocode==1)
          {
            //alert(p)
            if(p>-1)
            {
              this.discountWithApi=parseFloat(this.couponapply1[p].discount_value);
              this.discountType=this.couponapply1[p].promocode_type;
              this.max_discount_applicable=this.couponapply1[p].max_discount_applicable;
              this.minimum_transaction_applicable=this.couponapply1[p].minimum_transaction_applicable;
              this.minimun_amount_error_msg=this.couponapply1[p].minimun_amount_error_msg;
              this.max_percentage_amount_error_msg=this.couponapply1[p].max_percentage_amount_error_msg;
              this.successautoload=0
            }else{
              
              this.discount=0;
              this.discountPrice=0;
              this.discountWithApi=0
              let textMeasge=''
              let textMeasges=''
              let error12=0
              let error06=0
              let error03=0
              let error01=0
              for(let t=0;t<this.couponapply1.length;t++)
              {
                  if('12 Months' == this.couponapply1[t].package)
                  {
                    //alert(2)
                    textMeasge +='12 Months '
                    error12=1
                  }
                  if('6 Months' == this.couponapply1[t].package)
                  {
                    textMeasge +='6 Months '
                    error06=1
                  }
                  if('3 Months' == this.couponapply1[t].package)
                  {
                    textMeasge +='3 Months '
                    error03=1
                  }
                  if('1  Month' == this.couponapply1[t].package)
                  {
                    textMeasge +='1 Month '
                    error01=1
                  }
              }
              //alert(2)
              this.toasterObj.error('Discount is available only for '+textMeasge,'Sorry')
            }
          }else{
            this.discount=0;
            this.discountPrice=0;
            if(this.validPromocode==2)
            {
              this.toasterObj.success('Promo vouchers will be emailed in separate email after purchase.','Great')
              this.discount=0;
              this.discountPrice=0;
              this.discountWithApi=0
            }
            if(this.validPromocode==0)
            {
              this.toasterObj.error(record.message,'Sorry')
              this.discount=0;
              this.discountPrice=0;
              this.validPromocode=0;
              this.discountWithApi=0
            }
          }
        }else{
          this.toasterObj.error('There is something wrong with this promocode','Sorry')
          this.discount=0;
          this.discountPrice=0;
          this.validPromocode=0;
          this.discountWithApi=0
        }

        this.autoload=0;
      },
      error => {
        this.validPromocode=0;
        sessionStorage.removeItem('monthlyPromocode')
        this.toasterObj.error(error.errors.message,'Sorry')
        this.discount=0;
        this.discountPrice=0;
        this.discountWithApi=0
        this.autoload=0;

      }
      
    )
      
    }
     
     // this.discount=10;
     // alert(this.selectedvahicletype)
      
    }
    removeoupon()
    {
      this.discountWithApi=0;
      this.validPromocode = 0;
      this.discount=0;
      this.discountPrice=0;
    }
    apply()
    {
      //alert(this.selectedvahicletype)
      let promocode= $('#promocodes').val()
      if(promocode!='')
      {
        sessionStorage.setItem('monthlyPromocode',$('#promocodes').val())
        this.validateCoupon();
      }else{
        this.toasterObj.error('Please enter the valid PromoCode','Sorry')
      }
      
      
    }
    // CheckForSoldOut( price ){
    //     if(price > 0 ){
    //       this.disableSubmit = false;
    //       return `$${price}`;
    //     } else {
    //       this.disableSubmit = true;
    //       return 'Sold Out';
    //     }
    // }



    checkProcessing(val)
    {
      let processing_fees_changes =this.formatter.format(val)
      let pr_without_$=processing_fees_changes.replace('$','');
      this.processing_fees_changes=parseFloat(pr_without_$);
     return this.formatter.format(val)
     
     // return val
    }
    formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    })

    ReservationEventLogStatusForBuyMonthlyClick(facility_id, facility_name, getZipCode, errorMsg, iqcode ){
      let currentLatitude = (localStorage.getItem('currentLatitude')) ? localStorage.getItem('currentLatitude') : '';
      let currentLongitude = (localStorage.getItem('currentLongitude')) ? localStorage.getItem('currentLongitude') : '';
      let getUserId='0';
      if(localStorage.getItem('userid')){  
         getUserId=localStorage.getItem('userid'); }
      else{ getUserId='0'; } 
      let reservation_status_data = {
         user_id:getUserId,
         event_name:"Monthly - Aggregator Voucher Redemption with Voucher "+this.selectedGetVoucher,
         latitude:currentLatitude,
         longitude:currentLongitude,
         facility_id:facility_id,
         facility_name:facility_name,
         zipcode:getZipCode,
         app_version:"",
         error_message:errorMsg,
         device:this.device_type,
         device_detail:this.device_type,
         os_version:"",
         booking_code:iqcode,
         email_id:this.userEmailForLog 
         }
  
  this.reservationServObj.updateReservationEventLog(reservation_status_data).subscribe(
    data => {
    this.reservationDataEventLog = data
  },
  error => {

  }
)
}



// fetchFacilityDetailsForAggrigator(voucher){
//   this.searchPageServiceObj.getFacilityDetailsForAggrigator(voucher);
// }


chengeAutopayFormHandler(checkAutoPay){
  this.enableAutoPay = checkAutoPay;
}

logoutUser(){
  localStorage.removeItem('userid');
  this.userLogin=false;
  this.isLogin = false;
  this.toasterObj.success('You have successfully logged out.','Success');
}

getUpdatedPrce(price){
  let total:any = 0;
  //console.log(this.surchargeValue, this.price_surcharge_value);
  if(this.surchargeValue && this.price_surcharge_value != '' && this.price_surcharge_value > 0){
    this.termsPrice = '$'+parseFloat(price).toFixed(2);
    this.termsPrice1 = '$'+parseFloat(this.price_surcharge_value).toFixed(2);
    total = '$'+(parseFloat(price) + parseFloat( this.price_surcharge_value)).toFixed(2);
    // return (parseFloat(price) + parseFloat( this.price_surcharge_value)).toFixed(2);
   // return (parseFloat( this.price_surcharge_value)).toFixed(2);
  }else {
    this.termsPrice = '$'+parseFloat(price).toFixed(2);
    total = 0;
   // return parseFloat(price).toFixed(2);
  }

  if(this.surchargeValue && this.enableAutoPay ){
   let a = this.sur_auto.replace("{{price}}", this.termsPrice1);
   let b = a.replace("{{price1}}", total);
    this.termsConditionText = b;
  }
  if(!this.surchargeValue && this.enableAutoPay ){
   let a = this.no_sur_auto.replace("{{price}}", this.termsPrice);
    this.termsConditionText = a;
   } 
  if(!this.enableAutoPay && this.surchargeValue ){
    let a = this.sur_no_auto.replace("{{price}}", this.termsPrice1);
   let b = a.replace("{{price1}}", total);
    this.termsConditionText = b;
   } 
   if(!this.enableAutoPay && !this.surchargeValue ){
   let a = this.no_sur_no_auto.replace("{{price}}", this.termsPrice);
    this.termsConditionText = a;
   } 
}

// disableBtnValue(){
//   this.checkDisableBtn = this.checkDisableBtn+1;
//   if(this.checkDisableBtn < 1000 ){
//     console.log(this.disableSubmit, 'disable btn -', this.checkDisableBtn);
//   }
// }

// test(make_vehicle , make_modal , vehiclecolor ,validPhone ,firstname ,lastname ,address1 ,city ,state , zipcode ,name_on_card ,card_number , expiration_date , security_code ,pzipcode ,terms , validPwd ,validCPwd, disableSubmit){
//   this.checkDisableBtn = this.checkDisableBtn+1;
//   if(this.checkDisableBtn < 5 ){
//   console.log(make_vehicle+' make_vehicle ' , make_modal+ " make_modal " , vehiclecolor + " vehiclecolor ",validPhone+" validPhone " ,firstname+ " validPhone " ,lastname+ " validPhone " ,address1 + " address1 ",city+" city " ,state+" state " , zipcode+" zipcode " ,name_on_card+ " name_on_card " ,card_number + " card_number ", expiration_date+" expiration_date " , security_code+" security_code " ,pzipcode+" pzipcode " ,terms+" terms " , validPwd+" validPwd " ,validCPwd+" validCPwd ", disableSubmit+" disableSubmit ");
//   }
// }

checkCard(card_no){
  this.checkCardByvalue(card_no.value[0],card_no.value);
}

checkCardByvalue(card_no,totalnumber){
  let flag = 0;
   //SET CURSOR POSITION
   $.fn.setCursorPosition = function(pos) {
     this.each(function(index, elem) {
       if (elem.setSelectionRange) {
         elem.setSelectionRange(pos, pos);
       } else if (elem.createTextRange) {
         var range = elem.createTextRange();
         range.collapse(true);
         range.moveEnd('character', pos);
         range.moveStart('character', pos);
         range.select();
       }
     });
     return this;
   };
   if(card_no=='3'){
     if(totalnumber[1] == '_' && flag ==0 ){
     $('#cardNumber').setCursorPosition(1);
     flag = 1;
     }
     this.cardMask = [
       /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
       /[0-9]/, /[0-9]/, /[0-9]/, ' ',
       /[0-9]/, /[0-9]/, /[0-9]/, ' ',
       /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
     ];
   }else{
    this.cardMask = [
       /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
       /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
       /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
       /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
     ];
   }
 }

 fetchUserStatusForTracking(){
  let getUserId = '0';
  let getUserEmail = '0';
  let user_login_type = '';
  let isLogin = false;
  if(localStorage.getItem('access_token')){
   isLogin = true;
     getUserId = localStorage.getItem('userid');
     getUserEmail = localStorage.getItem('email');
     user_login_type = localStorage.getItem('user_login_type');
  }
  const loginStatusTrack = {
    'event' : 'pageview',
    'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
    'authenticationMethod' : user_login_type,
    'userId' : getUserId,
    'pageType' : this.routerObj.url,
    'email': getUserEmail,
 };
(<any>window).dataLayer.push(loginStatusTrack);

}

}

