import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomCode } from '../../../shared/CustomCode';
import {GoogleAnalyticsEventsService} from "../../../shared/google-analytics-events.service";
// declare var ga: Function;



@Component({
  selector: 'app-holiday-landing',
  templateUrl: './holiday-landing.component.html',
  styleUrls: ['./holiday-landing.component.scss']
})
export class HolidayLandingComponent implements OnInit {

	@Input() promoCode: string;

	@Output() saveEvent = new EventEmitter<boolean>();
	

	constructor(public googleAnalyticsEventsService: GoogleAnalyticsEventsService) { }

	ngOnInit() {
	}

	savePromocode(location) {
	//	ga('send', 'event', { eventCategory: 'save holidays', eventAction: 'click'});
		(<any>window).dataLayer.push(
         {
            'promoCode': 'save holidays',
            'event': 'Promocode save',
         });
		this.saveEvent.emit(location);
	}
}
