import { Directive, EventEmitter, ElementRef, Input,Output  } from '@angular/core';


declare var $:any;

@Directive({
  selector: '[app-range-slider]'
})
export class rangeSliderDirective {

  @Input() options:any;
  @Output() rangeSliderChange = new EventEmitter();

  thisRef: any;

  constructor(private el: ElementRef) {
      setTimeout(() => {
        this.init();
      }, 0);
      this.thisRef = this;
  }

  init() {
    let thisRef = this.thisRef;
    var pathname = window.location.pathname;
  //   $(this.el.nativeElement).mgSpace({
  //     // Breakpoints at which the accordian changes # of columns
  //     breakpointColumns: [
  //       {
  //         breakpoint: 0,
  //         column: 1
  //       },
  //       {
  //         breakpoint: 768,
  //         column: 2
  //       },
  //       {
  //         breakpoint: 1025,
  //         column: 3
  //       },
  //       {
  //         breakpoint: 1400,
  //         column: 3
  //       }
  //     ],
  //     rowWrapper: ".mg-rows",
  //     row: ".mg-row",
  //     targetWrapper: ".mg-targets",
  //     target: ".mg-target",
  //     trigger: ".mg-trigger",
  //     close: ".mg-close",
  //     rowMargin: 30,
  //     targetPadding: 0,
  //     useHash: false,
  //     useOnpageHash: false,
  //     hashTitle: "#/item-",
  //     useIndicator: true
  // });
  $(this.el.nativeElement).inlinePopup({
    itemSelector : ".article",
    closeinnerelem:"X",

  // active the first item on page load
  "activeFirst"           : false,

  // auto scroll the page to the active item
  "scrollToViewPort"      : true,

  // shows indicator arrow
  "arrow"                 : true,

  // scroll offset in pixels
  "scrollOffset"          : 0,

  // // custom close icon
  // "closeinnerelem"         : "<i class='fa fa-close'></i>" 
  });

      $(this.el.nativeElement).rangeslider('update', true);

  }

}
