import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RouterExtService } from '../../../services/router-ext.service';
import { UserserviceService } from '../../../services/userservice.service';
import { Router } from '@angular/router';
import { CustomCode } from '../../../shared/CustomCode';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-icon-rewards-details',
  templateUrl: './icon-rewards-details.component.html',
  styleUrls: ['./icon-rewards-details.component.scss'],
  providers:[UserserviceService]
})
export class IconRewardsDetailsComponent implements OnInit {
  @Output() enrolledNowProf = new EventEmitter<any>();
  @Input() isBtnDisabled: boolean;
  monthsList = CustomCode.getMonthList();
  yearsList = CustomCode.getBeforeYearList();
  daysList = CustomCode.getDayList();
  genderList = [{id:1, value:'Male'}, {id:2, value:'Female'}, {id:9, value:'Prefer not to answer'}];
  questionList:any;
  maxLimit:number = 100;
  user = {
    phone:'',
    zip:'',
    month:'',
    gender:0,
   day:'01',
    year:'',
    ques1:'',
    ques2:'',
    parked:'',
    loyalty_status:0
  }
  currentUrl: string;
  isReferal:boolean = false;
  isLoaded:boolean = false;
  pageHeading:string = 'ADD MY DETAILS';
  btnText:string = 'SAVE DETAILS';
  phoneMask: any = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  maxrange:number = 100;
  rangerValue1:any=0;
  rangerValue2:any=0;
  actualRange:any = 0;
  dontKnow:boolean = false;
  invalidNumber1:boolean = false;
  invalidNumber2:boolean = false;
  isProfileCompleted:number = 0;
  icon_rewards_logo_wt =  'assets/img/icon-rewards-logo-wt.svg';
  submtDisabled:boolean=false
  rewarded_points:number = CustomCode.rewarded_points
  isPointsLoaded:boolean=false;
  constructor(
    private RouterExtService: RouterExtService, 
    private userServiceObj: UserserviceService,
    private routeObj: Router,
    private toasterObj: ToastrService) {
    this.currentUrl = this.RouterExtService.getcurrentUrl();  
    if(this.currentUrl === '/my-account/manage-icon-rewards-details'){
      this.isReferal = true;
   }
   }
  ngOnDestroy() {
    $('#menus1').removeClass('active')
}
  ngOnInit() {
    $('#menus1').addClass('active')
    this.userServiceObj.GetLoyaltyProfile().subscribe(
      res => {
        let uData = res.data;
        this.questionList = uData.security_questions;
        this.isProfileCompleted = uData.is_loyalty_profile_completed;
        // if(parseInt(uData.is_loyalty_profile_completed)){
          this.pageHeading = 'EDIT MY DETAILS';
          this.btnText = 'NEXT';
          sessionStorage.setItem('is_loyalty_active', uData.is_loyalty_active.toString());
          if(sessionStorage.getItem('is_loyalty_active') === '1'){
            this.btnText = 'UPDATE DETAILS';
          }
          
          this.user.phone = uData.profile.voice;
          this.user.zip = (uData.profile.address !== undefined)?uData.profile.address.zip:'';
          this.user.gender = (uData.profile.gender)?uData.profile.gender:0;
          if(uData.profile.birthDate != null  && uData.profile.birthDate !='0000-00-00'){
            uData.profile.birthDate=uData.profile.birthDate.replace('01T','05T')
            let bd = new Date(uData.profile.birthDate);
            this.user.day = String(bd.getDate());
            this.user.month = String(bd.getMonth()+1);
            this.user.year = String(bd.getFullYear());
         }
          if(uData.profile.customFields!== undefined){
            uData.profile.customFields.forEach(el => {
              if(el.name === this.questionList[1].alias){
                if(el.value == '-1'){
                  this.dontKnow = true;
                  this.user.ques2 = '';
                }else{
                  this.user.ques2 = el.value;
                }
              }
              if(el.name === this.questionList[0].alias){
                this.user.ques1 = el.value;
              }

              if(el.name === 'Active' || el.name === 'Status'){
                this.user.loyalty_status = el.value;
              }
            });
          }
        // }
        this.isLoaded = true;
      }
    )
    if(localStorage.getItem('access_token')) {
      this.userServiceObj.GetLoyaltyWallet().subscribe(
        response => {
          this.rewarded_points = response.data.loyalty_config.POINTS_AT_REGISTRATION;
          this.isPointsLoaded=true;
        }
      );
    }
  }

  saveUserInfo(user, formRef){
    let ph = user.phone.replace(/\D/g, '');
    if(ph.length !==0 && ph.length<10){
      formRef.form.controls['phone'].setErrors({'invalid': true});
      this.submtDisabled = false;
      return false;
    }else if(parseInt(user.ques1)>this.maxLimit || parseInt(user.ques1)<0){
      formRef.form.controls['ques1'].setErrors({'invalid': true});
      this.submtDisabled = false;
      return false;
    }else if(parseInt(user.ques2)>this.maxLimit || parseInt(user.ques2)<0){
      formRef.form.controls['ques2'].setErrors({'invalid': true});
      this.submtDisabled = false;
      return false;
   }else{
      let userData = {
        phone:user.phone,
        zip:user.zip,
        gender:user.gender,
        birth_date:(user.year !=='' && user.month !=='')?user.year+'-'+user.month+'-01':'',
        custom_fields:[
          {name:this.questionList[0].alias, value:user.ques1},
          {name:this.questionList[1].alias, value:(this.dontKnow)?'-1':user.ques2},
          {name:'Active', value:user.loyalty_status}
        ]
      }

      this.userServiceObj.LoyaltyUpdateProfile(userData)
        .subscribe(
          data => {
            let respdata = data;
            if (respdata.status === 201) {
              localStorage.setItem('point_loaded', respdata.data.pointLoaded);
              formRef.resetForm();
              this.toasterObj.success('You have successfully updated your profile.', 'Great!');
              if(sessionStorage.getItem('is_loyalty_active') === '1'){
                 this.routeObj.navigate(['/my-account/icon-rewards']);
              }else{
                this.routeObj.navigate(['/my-account/manage-icon-rewards-card']);
              }
              
            }else{
              this.routeObj.navigate(['/']);
            }
        },
        error => {
          this.toasterObj.error(error.errors.message, 'Sorry!');
        }
      );
    }
  }

  phoneFormat(event: any) {
    const pattern = /[0-9\)\-\(\.\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar) && event.keyCode != 9) {
      event.preventDefault();
    }
  }

  restricDec(event: any){
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);    
    if ((event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) || event.keyCode == 46 || event.keyCode == 45) {
      event.preventDefault();
    }else if(event.target.value.length>2 && event.keyCode != 8){
      event.preventDefault();
    }
  }

  onKey(event: any, qNo){
    this.invalidNumber1 = false;
    this.invalidNumber2 = false;
    if(event.target.value > 100){
      if(parseInt(qNo) == 1){
        this.invalidNumber1 = true;
      }else{
        this.invalidNumber2 = true;
      }
      // event.target.value = '';
      event.preventDefault();
    }

    if(parseInt(qNo) == 2){
      this.dontKnow = false;
    }
  }

  allowNumber(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8  && event.keyCode != 9 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  allowNumberWithLimit(event: any, limit) {
    const pattern=/[0-9]|[1-9][0-9]|[1][0][0]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar) && (parseInt(event.target.value) <= parseInt(limit))) {
      event.preventDefault();
    }

    if((parseInt(event.target.value) >= parseInt(limit))){
      event.preventDefault();
    }
  }

  enrollNow(userDetails, sUser){
    let profileDetails = {
      phone:(userDetails.phone !== undefined)?userDetails.phone:'',
      zip:userDetails.zip,
      gender:userDetails.gender,
      birth_date:(userDetails.year !=='' && userDetails.month !=='')?userDetails.year+'-'+userDetails.month+'-01':'',
      custom_fields:[
        {name:this.questionList[0].alias, value:userDetails.ques1},
        {name:this.questionList[1].alias, value:(this.dontKnow)?'-1':userDetails.ques2},
        {name:'Active', value:userDetails.loyalty_status}
      ]
    }
    this.enrolledNowProf.emit(profileDetails);
  }

  dontKnowCount(event){
    if(event.target.checked){
      this.actualRange = this.user.ques2;
      this.dontKnow = true;
      this.user.ques2 = '';
    }else{
      this.user.ques2 = this.actualRange;
    }
  }

  UpdateKnow(){
    this.dontKnow = false;
    this.invalidNumber2 = false;
  }

  UpdateKnow1(){
    this.invalidNumber1 = false;
  }
}
