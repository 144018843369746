import { Component, OnInit,AfterViewInit, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { FilterHelper } from '../classes/filter-helper';
import { BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { CustomCode } from '../shared/CustomCode';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HomepageserviceService } from './../services/homepageservice.service';
import { UserserviceService } from '../services/userservice.service';
import { FacebookService, InitParams, LoginResponse,LoginOptions } from 'ngx-facebook';

//  image used in page


import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MyAccountMonthlyParkingService } from '../services/my-account-monthly-parking.service';
import { WebMetaService } from '../shared/web-meta.service';
// declare var ga: Function;
declare var google: any;
@Component({
  selector: 'app-newpage',
  templateUrl: './newpage.component.html',
  styleUrls: ['./newpage.component.scss'],
  providers: [HomepageserviceService,UserserviceService,MyAccountMonthlyParkingService,WebMetaService]
})
export class NewpageComponent implements OnInit {
  contactTelNo = CustomCode.contactTelephoneNumber;
  covidCareUrl = CustomCode.cmsUrl+'cms/daily-updates/';
  isLoggedIn = false;
  isCollapsed = false;
  isCovidWeb = true;
  isLoyaltyMember:boolean = false;
  startDuration:any;
  endDuration:any;
  endMaxDuration:any;
  maxDate=FilterHelper.defaultEndstartDate();
  minDate = FilterHelper.defaultStartDuration();
  maxEndDate=FilterHelper.defaultEndtoDate()
  firstLoad = false;
  startTime:any;
  endTime:any;
  private _picker: BsDatepickerModule;
  timeSlots:any = FilterHelper.getObjectBasedTimeSlots();
  settypedata:any='daily';
  responseData: any = {};
  loyalit_member=0
  tierData: any;
  isTierLoaded: boolean = false;
  isTierExist: boolean = false;
  isTierMsg: string;
  isLogin:boolean=false;
   baseballId = 'home-banner';
   baseballModel:any;
   baseModelStatus = 0;
   baseballContent;
   showContentStatus = false;
   link_to_redirect;
   isLoaded = false;
  loggedUserName:any=''
  customerPhone2 = 'tel:+1(877)727-5464';
  bars:string ='assets/img/new-homepage/menu-bar-icon.svg';
  logo_icon:string ='assets/img/new-homepage/arrow-list-icongo-wt.png';
  logo_reward:string ='assets/img/new-homepage/icon-rewrds.svg';
  icon_logo:string ='assets/img/icon_logo.svg';
  arrow_right:string = 'assets/img/new-homepage/b2b-img/arow-right.png';
  qp_Logo:string = 'assets/img/qp_logo.svg';
  fbLogo:string = 'assets/img/new-homepage/facebook.png';
  twiterLogo:string = 'assets/img/new-homepage/twitter.png';
  instagramLogo = 'assets/img/new-homepage/instagram.png';
  gstore:string = 'assets/img/new-homepage/google-play.svg';
  appstore:string = 'assets/img/new-homepage/AppStore.svg';
  appicon:string = 'assets/img/new-homepage/icon-go.svg';
  topbar:string = 'assets/img/new-homepage/slider/top-image.png';
  logoRight:string = 'assets/img/new-homepage/slider/logos.png';
  crossDark:string = 'assets/img/new-homepage/cross-white.png';
  arrowDown:string = 'assets/img/new-homepage/arrow-down-black.png';
  iconAppLogoNew:string = 'assets/img/app-icon-new.png';
  iOS :any;
  Android : any;
  tier = 'assets/img/new-homepage/tier.svg';
  piggibank = 'assets/img/loyalty/piggybank.svg';
  gift = 'assets/img/icon-rewards-gift.svg';
  price = 'assets/img/price-tag.svg';
  doller_tag = 'assets/img/loyalty/disocunt-credit.svg';
  crossSvg='assets/img/cross.svg';
  sealLogo='/assets/img/new-homepage/seal.png';
  dotsbuttles='assets/img/new-homepage/dots.png';


  appStoreUrl = 'https://itunes.apple.com/us/app/icon-parking/id394377820?mt=8';
  googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.iconparking&hl=en';

  instagramUrl = 'https://www.instagram.com/iconparkingnyc/?hl=en';
  facebookUrl = 'https://www.facebook.com/IconParkingNYC';
  twitterUrl = 'https://twitter.com/IconParkingNYC';
  linkedinUrl = 'https://www.linkedin.com/company/icon-parking/';
  newsTips = '/cms/news/';
  phone = 'tel:+1(877)727-5464'
  customerPhone = 'tel:+1877727-5464';
  is_show:any=true;
  is_show_landing_strip:any=false;
  is_landing_page_save:any=true;
  is_landing_page:any=true;
  sign:any = '';
  Couponmessage=''
  Couponmessages=''
  show_strip_search:any=false
  activeUser:any = {};
  webdata:any;
  descriptionElement:any;
  path = window.location.pathname;
  startDurationForComapre;
  facilityType = CustomCode.defaultFacilityType;
  POSuserLat=0;
   POSuserLong=0;
   iosDevice:any;
   message :any;
  metaTags: any;
  isOpen = false;
  showOnMothly:any=0
  showAppPromotion:boolean = true;
  menuUser = [
    new CustomCode ('My Account', 'account'),
    // new CustomCode ('Icon Rewards', 'icon-rewards'),
    new CustomCode ('My Monthly Parking', 'monthly-parking'),
    //new CustomCode ('My Offers', 'coupons'),
    new CustomCode ('My Reservations', 'reservations'),
    new CustomCode ('Vehicle Request', 'vehicle-request'),
    new CustomCode ('Brand Partnerships', 'brand-partnership'),
    new CustomCode ('My Promo Codes', 'promo-codes'),
   ];
//  main element call
@ViewChild('baseballmodelbtn',{static:true}) baseballmodelbtn: ElementRef;
@ViewChild('hideBaseballPopup',{static:true}) hideBaseballPopup: ElementRef;

  constructor(private toasterObj: ToastrService,private homePageServiceObj:HomepageserviceService,
    private userServiceObj:UserserviceService,private toastr: ToastrService,private router: Router,
     private fb: FacebookService,
     private modalService: BsModalService,
     private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
     private webMetaServiceObj: WebMetaService
     ) { }

  ngOnInit() {

    if(!sessionStorage.getItem('promoApply'))
        {
          localStorage.removeItem('is_landing_page');
          localStorage.removeItem('routeUrl');
          localStorage.removeItem('template_slug');
          localStorage.removeItem('promotion_id');
          localStorage.removeItem('promocode');
          localStorage.removeItem('action_type');
          localStorage.removeItem('reservation_apply');
        }

    if(localStorage.getItem("promocode") && localStorage.getItem('is_landing_page_monthly') && localStorage.getItem('is_landing_page_monthly_stripe'))
    {
      this.Couponmessages='<b>Great!</b> We’ll automatically apply your promo code '+localStorage.getItem("promocode")+' at checkout.'
      this.showOnMothly=1;
    }else{
      this.showOnMothly=0;
    }
    localStorage.removeItem('showNeigborMarker2');
    localStorage.removeItem('neighbourhoodActive')
    localStorage.removeItem('showNeighbordood')
    localStorage.removeItem('sortBy')
    localStorage.setItem('resetSearch','1')
    //window.addEventListener('online', () => alert('came online'));
    //window.addEventListener('offline', () => alert('came offline'));
    //alert(2)
    localStorage.removeItem('couponSave');
    localStorage.removeItem('searchLocation');
    localStorage.removeItem('seachLocation');
    localStorage.removeItem('locationNew');
    localStorage.removeItem('locationBuyMonthly');
    localStorage.setItem('isHone', '1');
    localStorage.removeItem('showNeighbordood');
    localStorage.removeItem('sortBy');
    this.iOS = false;
    this.Android = false;
    setTimeout(()=>{
      sessionStorage.setItem('hellos','1')
    },20000)
    if(!sessionStorage.getItem('loadFirstimeagainText2')) {
      sessionStorage.setItem('loadFirstimeagainText2','1')
      localStorage.removeItem('clickBuyMonthly');
      // window.location.reload();
    }
    let AppPromotion = CustomCode.getCookie('app_promotion');
    if(AppPromotion == '1'){
      this.showAppPromotion = false
    }else {
      this.showAppPromotion = true
    }
    localStorage.removeItem('is_ham')
    let times_run= setInterval(function () {
      $('body').removeClass('showChat');
    }, 300);
    setTimeout(function () {
      clearInterval(times_run);
    }, 10000);
    $(window).scroll( () => {
      // mobile animation
      $('.animated').each( function() {
        let elementTop = $(this).offset().top;
        let elementBottom = elementTop + $(this).outerHeight();
        let viewportTop = $(window).scrollTop();
        let viewportBottom = viewportTop + $(window).height();
        if (elementBottom > viewportTop && elementTop < viewportBottom) {
          $(this).addClass('go');
        } else {
          $(this).removeClass('go');
        }
      });
    });
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/i)) {
      this.Android = true;
    } else if (navigator.userAgent.match(/Android/i)){
      this.iOS = true;
    } else {
      this.iOS = false;
      this.Android = false;
    }

    
    if( navigator.geolocation ) {
      navigator.geolocation.getCurrentPosition(function(position){ 
     console.log("Found your location \nLat : "+position.coords.latitude+" \nLang :"+ position.coords.longitude);
     localStorage.setItem('currentLatitude', position.coords.latitude.toString());
     localStorage.setItem('currentLongitude', position.coords.longitude.toString());
     });
   } else  { 
      //alert("Sorry, your browser does not support geolocation services."); 
   }

    localStorage.setItem('typeMode','daily')
    this.startDurationForComapre=this.startDuration = FilterHelper.defaultStartDuration();
    this.endDuration = FilterHelper.defaultEndDuration();
    this.startTime = FilterHelper.getFormattedTime(this.startDuration);
    this.endTime = FilterHelper.getFormattedTime(this.endDuration);
    //CustomCode.getDateForMapSearchSlashFormatHome(this.startDuration)
    localStorage.setItem('monthlyDate',this.startDuration)
    localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.startDuration,this.startTime));
    localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
    if(localStorage.getItem('userid'))
    {
      this.isLogin=true;
      this.loggedUserName = CustomCode.add3Dots(CustomCode.getloggedInUserDetails(), 22);
    }else{
      this.isLogin=false;
    }
    if(localStorage.getItem('is_loyalty_active') && localStorage.getItem('is_loyalty_active')=='1')
    {
      if (localStorage.getItem('is_loyalty') === "1") {
        this.isLoyaltyMember=true;
        this.userServiceObj.GetTierInfo().subscribe(
          res => {
            if (res.data.tier_exists) {
              this.tierData = res.data.tier;
              this.isTierMsg = res.data.tier_description;
              this.isTierLoaded = true;
              this.isTierExist = true;
            }
          }
        )
      }
      this.loyalit_member=1
    }else{
      this.loyalit_member=0
    }
    
    if (localStorage.getItem('access_token')) { this.isLoggedIn = true; }
    if(localStorage.getItem('is_loyalty')==="1"){ this.isLoyaltyMember = true; }

    this.loggedUserName = CustomCode.add3Dots(CustomCode.getloggedInUserDetails(), 22);
    if (this.router.url=='/' || this.router.url.indexOf('search') > 0 || this.router.url.indexOf('neighborhoods')>0 || this.router.url.indexOf('facility')>0)
    {
          this.show_strip_search=true;
    }
    if(localStorage.getItem('is_landing_page')=='true' && localStorage.getItem('action_type')=='apply' )
    {
       if(localStorage.getItem('is_show_landing_strip')=='true')
       {
         this.is_show_landing_strip=true;
       }
       if (localStorage.getItem('access_token')) {
           let data = {};
           let mode='apply'
           data = {
                  'promocode':localStorage.getItem('promocode'),
                   'user_id' :localStorage.getItem('userid')};
             
            this.userServiceObj.validatePromoCode(data)
             .then(
             data => {
                      if (data['data'].is_promocode_valid) {
                      if (mode == "save") {
                          //this.savePromocode(neighborhood);
                      } else {
                          //this.notifyReservationPromoCodeApply();
                      }
                      } else 
                      {
                         localStorage.setItem('couponMessage',data['data'].message)
                         localStorage.setItem('errorType','1')
                      }
                      this.promocodeApply();
                    },
           error => {

                    localStorage.setItem('couponMessage',error.errors.message)
                    localStorage.setItem('errorType','0')
                    if(error.errors.message == "This Promocode is only for guest user")
                      {
                        localStorage.setItem('errorType','4');
                      }
                      else if (error.errors.message == "Please create an account to avail this") 
                      {
                          localStorage.setItem('errorType','1')
                          //this.notifyAccountRequirement();
                            //this.launchLoginPopup();
                      }
                       else if (error.errors.message == "Please enter a valid Promotion Code. Please note that a prepaid voucher is not a Promo Code.") 
                      {
                          localStorage.setItem('errorType','1')
                      }
                      this.promocodeApply();
                      
                    }
                      );
       }
       this.promocodeApply();
    }
    //

    this.getBaseballModelContent();
    if (localStorage.getItem('access_token')) {
      // this.isLoggedIn = true;
      this.fetchUserDetails();
  }
  this.metaTags = Array.from(document.getElementsByTagName('meta'));
  this.descriptionElement = this.getMetaElement('description');
  this.updatePageMeta();
  }
  hideStripeMonhthly()
    {
      localStorage.removeItem('is_landing_page_monthly_stripe')
     // $('aside.contentBar').css({'height':'calc(100% - 140px);'})
      this.showOnMothly=0;
      
      //this.sidebarHeightUpdate();
    }
  hidePopup(){
    $('.popupButton').trigger('click');
  }
  dismisThis(){
    $('body').removeClass('noscroll');
    $('.appPromotion').remove();
     CustomCode.setCookieForPppPromotion('app_promotion', '1', 120);
  }
  getMetaElement(name){
    return this.metaTags.find((element) => { return element.getAttribute('name') === name; });
  }
  settype(type)
  {
    $('.boxestype').removeClass('active')
    if(type=='daily')
    {
      $('.daily').removeClass('active')
      this.is_show=true;
    }else{
      $('.monthly').removeClass('active')
      this.is_show=false;
    }
    localStorage.setItem('typeMode',type)
    this.settypedata=type;
  }
  
  onShowPicker(event) 
  {
    const dayHoverHandler = event.dayHoverHandler;
    const hoverWrapper = (hoverEvent) => {
        const { cell, isHovered } = hoverEvent;

        if ((isHovered &&
          !!navigator.platform &&
          /iPad|iPhone|iPod/.test(navigator.platform)) &&
          'ontouchstart' in window
        ) {
            (this._picker as any)._datepickerRef.instance.daySelectHandler(cell);
        }

        return dayHoverHandler(hoverEvent);
    };
    event.dayHoverHandler = hoverWrapper;
  }
  updateStartDuration() {
    //alert(this.startDuration)
    localStorage.setItem('monthlyDate',this.startDuration)
    this.endMaxDuration = new Date(this.startDuration);
    this.endMaxDuration.setMonth(this.endMaxDuration.getMonth()+1);
    this.endMaxDuration.setDate(this.endMaxDuration.getDate()-1);
    this.maxEndDate=this.endMaxDuration
     if (0) {
       
       if(localStorage.getItem('searchType'))
       {
        // this.invokeFilterExecution();
       }
       
       return;
     } else {
       let  rest = this.startDuration.toString().substring(0, this.startDuration.toString().lastIndexOf(":") + 1);
       let  last = this.startDuration.toString().substring(this.startDuration.toString().lastIndexOf(":") + 1, this.startDuration.toString().length);
       let  replaceRecord = rest.substring(0, rest.length - 6) +" "+this.startTime;
       this.startDuration = replaceRecord+""+last;
       this.startDurationForComapre=this.startDuration=new Date(this.startDuration);
       let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
       if(startDuration<FilterHelper.changeNyTime())
       {
         this.toastr.error(FilterHelper.PAST_TIME_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
         //his.startTime = FilterHelper.getFormattedTime(FilterHelper.changeNyTime());
       }
       if (startDuration < this.minDate) {
         // if (this.calculateLengthOfStay() < 0.5) {
           this.startTime = FilterHelper.getFormattedTime(this.startDuration);
           $('#startTime').val(this.startTime);
           if (this.calculateLengthOfStay() < 0.5) {
             this.toastr.error(FilterHelper.PAST_TIME_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
           }
       }
       if (this.calculateLengthOfStay() < 0.5) {
         this.endDuration = new Date(this.startDuration.getTime());
         this.endDuration.setMinutes(this.endDuration.getMinutes() + 30);
         this.endTime = FilterHelper.getFormattedTime(this.endDuration);
         $('#endTime').val(this.endTime);
       }
       
       //alert(this.maxEndDate);
       //this.invokeFilterExecution();
     }
     localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.startDuration,this.startTime));
     localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
   
   }
   calculateLengthOfStay() {
    let bookingHours:any = FilterHelper.getBookingHours(this.startDuration, this.startTime, this.endDuration, this.endTime);
    return bookingHours;
  }
  updateEndDuration() {
    if (this.calculateLengthOfStay() < 0.5) {
      let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
      this.endDuration = new Date(startDuration);
      this.endDuration.setMinutes(this.endDuration.getMinutes() + 30);
      this.endTime = FilterHelper.getFormattedTime(this.endDuration);
      $('#endTime').val(this.endTime);
      this.toastr.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
      return
    }
    localStorage.setItem('monthlyDate',this.startDuration)
    localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.startDuration,this.startTime));
    localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
  }
  updateStartTime() {
    let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
    
    if (startDuration < this.minDate) {
      this.startTime = FilterHelper.getFormattedTime(this.startDuration);
      $('#startTime').val(this.startTime);
      this.toastr.error(FilterHelper.PAST_TIME_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
      return
    } else {
      this.startDurationForComapre=startDuration
      if (this.calculateLengthOfStay() < 0.5) {
        this.endDuration = new Date(startDuration);
        this.endDuration.setMinutes(this.endDuration.getMinutes() + 30);
        this.endTime = FilterHelper.getFormattedTime(this.endDuration);
        $('#endTime').val(this.endTime);
      }
    }
    localStorage.setItem('monthlyDate',this.startDuration)
    localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.startDuration,this.startTime));
    localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
  }


  updateEndTime() {
   
      let endDuration = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
      if (endDuration < this.endDuration) {
        if (this.calculateLengthOfStay() < 0.5) {
          let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
          this.endDuration = new Date(startDuration);
          this.endDuration.setMinutes(this.endDuration.getMinutes() + 30);
          this.endTime = FilterHelper.getFormattedTime(this.endDuration);
          $('#endTime').val(this.endTime);
          this.toastr.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
          return
        } 
      }
      localStorage.setItem('monthlyDate',this.startDuration)
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.startDuration,this.startTime));
      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
  }
  toTimestamp(strDate){
    var datum = Date.parse(strDate);
    return datum/1000;
 }
// alert(toTimestamp('02/13/2009 23:31:30'));
  navigate()
  {
    localStorage.removeItem('neighbourhoodActive')
    localStorage.removeItem('showNeighbordood')
    localStorage.removeItem('sortBy')
    localStorage.setItem('resetSearch','1')
    //ga('send', 'event', { eventCategory: "Search Available parking space", eventAction: 'Btnclick'});
    let exacTime = FilterHelper.defaultStartDurationUrlHomePageData(FilterHelper.changeNyTime());
    //alert(this.endDuration);
    let endTimeData = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
    //alert(endTimeData);
    //this.endTime = FilterHelper.getFormattedTime(this.endDuration);
    if(this.toTimestamp(this.startDuration)<this.toTimestamp(exacTime))
    {
      this.startDuration = FilterHelper.defaultStartDuration();
      this.startTime = FilterHelper.getFormattedTime(this.startDuration);
      localStorage.setItem('monthlyDate',this.startDuration)
      this.endDuration = FilterHelper.defaultEndDuration();
      this.endTime = FilterHelper.getFormattedTime(this.endDuration);
      let endTimeDataIfLess = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
      //alert(endTimeDataIfLess)
      if(this.toTimestamp(endTimeData)<this.toTimestamp(endTimeDataIfLess))
      {
        //alert(2)
        //localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
      }
      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.startDuration,this.startTime));
    }
    //let endTimeDataIfLess = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
   
    let lat:any=40.7589
    let long:any =-73.9851
    let location:any ='Times Square'
    let stime =localStorage.getItem('startimehome')
    if(localStorage.getItem('seachLat'))
    {
      lat =localStorage.getItem('seachLat')
      long =localStorage.getItem('seachLong')
      if(localStorage.getItem('seachLocation')){ location =localStorage.getItem('seachLocation')}
      
    }
    this.router.navigate(['/search', lat, long, stime, 2, location,this.settypedata,CustomCode.searchMode()]);
  }
  searchLocation(type)
  {
   // ga('send', 'event', { eventCategory: "Order Online", eventLabel:"redirect to search", eventAction: 'Btnclick'});
    let lat:any=40.7589
    let long:any =-73.9851
    let location:any ='Times Square'
    let stime =localStorage.getItem('startimehome')
    this.router.navigate(['/search', lat, long, stime, 2, location,type,CustomCode.searchMode()]);
  }
  sendApp() {
   // ga('send', 'event', { eventCategory: 'app link', eventAction: 'click'});
    const appText = parseInt((<HTMLInputElement>document.getElementById('sendapp')).value.trim());
    if (appText) {
		const phoneNum = ((<HTMLInputElement>document.getElementById('sendapp'))).value;
    
   
		const text = 'Welcome to Icon Parking. click on the link below Android App https://play.google.com/store/apps/details?id=com.iconparking&hl=en and Iphone App https://itunes.apple.com/us/app/icon-parking/id394377820?mt=8';
      this.homePageServiceObj.messageToMobile(phoneNum, text)
          .subscribe(
          data => {
            this.responseData = data;
            this.toasterObj.success('App link sent successfully', 'success!');
          },
          error => {
            const errorsList = error.errors;
            if (errorsList.message !== 'Validation Errors') {
              this.toasterObj.error(errorsList.message, 'Sorry!');
            } else {
              for (const key in errorsList.detail) {
                if (errorsList.detail.hasOwnProperty(key)) {
                  if (typeof errorsList.detail === 'undefined') {
                    this.toasterObj.error(errorsList.message, 'Sorry!');
                  } else {
                    this.toasterObj.error(errorsList.detail[key], 'Sorry!');
                  }
                }
              }
            }
          }
          );
    } else {
      this.toasterObj.error('Number is required', 'Sorry!');
    }
    (<HTMLInputElement>document.getElementById('sendapp')).value = '';
  }
  redirectToPage(){
   // ga('send', 'event', { eventCategory: "Receive Instant Discounts", eventLabel:"redirect to Reward page", eventAction: 'Btnclick'});
    sessionStorage.setItem('menuActive','1');
    if(localStorage.getItem('is_loyalty')==="1"){ 
      this.isLoyaltyMember = true; 
      this.router.navigate(['my-account/icon-rewards']);
    }else{
      this.router.navigate(['rewards']);
    }
  }
  resetLoginForm(f) {
     
    // const validationFields = ['email', 'password'];
    // for (let i = 0; i < validationFields.length; i++) {
    //    $('input[name="' + validationFields[i] + '"]').parents('div.form-group').removeClass('has-error');
    //    $('input[name="' + validationFields[i] + '"]').parents('div.form-group').find('div.help-block').addClass('hidden');
    // }
 }

  toggleData() {
    //localStorage.removeItem('searchLocation');
    $('#mySidenav').toggleClass('open');
     $('.sidenavBackDrop').toggleClass('open');
      $('body').addClass('sidebar-noscroll');
      localStorage.setItem('is_ham','1')
  }
   callcustomer(){
    (function(a,e,c,f,g,h,b,d){var k={ak:"876742851",cl:"BjciCOKo-nsQw5GIogM",autoreplace:"(877) 727-5464"};a[c]=a[c]||function(){(a[c].q=a[c].q||[]).push(arguments)};a[g]||(a[g]=k.ak);b=e.createElement(h);b.async=1;b.src="//www.gstatic.com/wcm/loader.js";d=e.getElementsByTagName(h)[0];d.parentNode.insertBefore(b,d);a[f]=function(b,d,e){a[c](2,b,k,d,null,new Date,e)};a[f]()})(window,document,"_googWcmImpl","_googWcmGet","_googWcmAk","script");
  }
  isActive()
  {
    if(sessionStorage.getItem('menuActive')=='1')
    {
      return true;
    }else{
      return false;
    }
  }
  logoutmenu()
  {
    if(this.router.url.indexOf('create-account') > -1)
    {
      return true;
    }else{
      return false;
    }
  }

  isActivetoinside(){
    if(this.router.url.indexOf('my-account') > -1)
    {
      if(sessionStorage.getItem('menuActive')=='1')
      {
        return false;
      }else{
        return true;
      }
    }else{
      return false;
    }
  }
  redirectToUser(){
     if(localStorage.getItem('is_admin')!='false' && localStorage.getItem('is_admin') )
     {
      let access_token = localStorage.getItem('access_token');
      let refresh_token = localStorage.getItem('refresh_token');
      window.location.href=CustomCode.adminUrlLogin+'/redirectto/'+access_token+'/'+refresh_token+'/0';
      //  window.location.href=this.adminUrl;
     }else{
      this.router.navigate(['/my-account/account']);
     }
   }

   removeHeader()
  {
    sessionStorage.removeItem('menuActive')
  }

  isActiveforSubMenu()
  {
    if(sessionStorage.getItem('menuActive')=='2')
    {
      return true;
    }else{
      return false;
    }
  } 

  menuactiveFunction()
  {
    sessionStorage.setItem('menuActive','2');
  }

  logOut() {
    let currentUrl= window.location.href;
    let fbl=0
    let gml=0
    if(localStorage.getItem('loginwithfb'))
    {
      fbl=1
    }
    if(localStorage.getItem('loginwithgm'))
    {
      gml=1
    }
   CustomCode.userLogout();
   CustomCode.displayLoginUser();
     if(fbl)
      {
       
        this.fb.logout().then((res: LoginResponse)=> console.log('Logged out!'));
      }
    
    if (this.router.url.indexOf('payment') > -1 || this.router.url.indexOf('buy-monthly') > -1) {
      if(gml)
      {
        window .open("https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=https://gmail.com",'_blank')
        //document.location.href = CustomCode.BASEURL;
        document.location.href = "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue="+CustomCode.BASEURL;
      }else{
          window.location.reload();
      }
     
    } else {

      if(gml)
      {
        window .open("https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=https://gmail.com",'_blank')
        //document.location.href = "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue="+CustomCode.BASEURL;
         document.location.href = CustomCode.BASEURL;
      }else{
        window.location.href=CustomCode.BASEURL;
        //window.location.reload();
      }
      
    }

    // this.routerObj.navigate(['/']);
    
  }
  promocodeApply()
    {
      //alert(this.Couponmessage);
      if(localStorage.getItem('is_landing_page')=='true' && localStorage.getItem('action_type')=='apply' )
        {
          this.is_landing_page =true;
           if (localStorage.getItem('access_token')) {
             
             if(localStorage.getItem('errorType')=='4')
             {
               this.sign = 'dollar';
               this.is_landing_page_save=false;
               localStorage.removeItem('is_landing_page')
               this.Couponmessage=localStorage.getItem('couponMessage')
             }
             else if(localStorage.getItem('errorType')=='1')
             {
               this.sign = 'dollar';
               this.is_landing_page_save=false;
               this.Couponmessage=localStorage.getItem('couponMessage')
               if(this.Couponmessage=='Please enter a valid Promotion Code. Please note that a prepaid voucher is not a Promo Code.')
               {
                 this.is_landing_page_save=false;
               }
             }else if(localStorage.getItem('errorType')=='0')
             {
               this.sign = 'dollar';
               this.Couponmessage='Promocode has been expired.'
               this.is_landing_page_save=false;
             }
             else{
               this.sign = 'dollar';
               this.Couponmessage="<b>Great!</b> We"+"'"+"ll automatically apply your promo code "+localStorage.getItem('promocode').toUpperCase()+" at checkout. Or,"
             }
          }else{
             this.sign = 'dollar';
             this.is_landing_page_save=false;
             this.Couponmessage=localStorage.getItem('couponMessage')
             if(localStorage.getItem('errorType')=='2')
             {
               this.is_landing_page_save=true;
               if(localStorage.getItem('couponMessage')=='Please create an account to avail this')
              {
                 this.is_landing_page_save=false;
                 this.Couponmessage='Please create an account or login to your existing account in order to use this promo code.'
               }
             }
            //this.Couponmessage=localStorage.getItem('couponMessage')
          }
         // this.Couponmessage=localStorage.getItem('couponMessage')
        }
        if(localStorage.getItem('showEroor'))
        {
          if(localStorage.getItem('showEroor')=='1')
          {
           this.toastr.success(localStorage.getItem('showEroorMsg'), 'Great!');
          }else{
            this.toastr.error(localStorage.getItem('showEroorMsg'), 'Sorry!');
          }
          localStorage.removeItem('showEroor')
          localStorage.removeItem('showEroorMsg')
        }
        //alert(this.Couponmessage)
    }

    launchLoginPopup() {
      if (localStorage.getItem('access_token')) {
         this.isLoggedIn = true;
         this.validatePromoCode('save');
      }else{
        localStorage.setItem('save', 'true');
        localStorage.setItem('savebylogin', 'true');
     $("a#loginPopup")[0].click();
      }
     
   }
 
validatePromoCode(mode) {
 let data = {
     'promocode': localStorage.getItem('promocode'),
     'user_id' : localStorage.getItem('userid')
   };
 this.userServiceObj.validatePromoCode(data)
 .then(
   data => {
     if (data['data'].is_promocode_valid) {
       this.savePromocode();
     } else {
       this.toastr.error('Promocode has been expired', 'Sorry!');
       //window.location.reload();
     }
   },
   error => {
     if (error.errors.message == "Please create an account to avail this") {
       this.toastr.error('Please login to your existing account or create a new one in order to save and redeem this promotion.', 'Sorry!');
     } else {
       this.toastr.error(error.errors.message, 'Sorry!');
      // window.location.reload();
     }
   }
 );
}
savePromocode() {
  this.hideStripe();
  localStorage.removeItem('savebylogin')
  const data = {'promocode' : localStorage.getItem('promocode'), 'user_id' : localStorage.getItem('userid')};
  this.userServiceObj.addPromoCode(data)
  .then(
    data => {
      this.show_strip_search=false
      localStorage.setItem('is_show_landing_strip','false')
      if (!data['errors']) {
         this.show_strip_search=false
          this.toastr.success('Promocode successfully added, Please make a reservation to redeem this offer.', 'Great!');
         // this.navidateToSearch();
      } else {
        this.toastr.error('Something wrong happened, try again later', 'Sorry!');
        //window.location.reload();
      }
    },
    error => {
      this.show_strip_search=false
      localStorage.setItem('is_show_landing_strip','false')
      this.toastr.error(error.errors.message, 'Sorry!');
     // this.navidateToSearch();
      // window.location.reload();
    }
  );
}
hideStripe()
    {
     // $('aside.contentBar').css({'height':'calc(100% - 140px);'})
      this.is_landing_page=false;
      this.is_show_landing_strip=false;
      localStorage.setItem('is_show_landing_strip','false')
      //this.sidebarHeightUpdate();
    }

  getBaseballModelContent(){
    return this.homePageServiceObj.getCmsBaseballModelContent(this.baseballId).subscribe(
      data => {
        
        this.isLoaded = true;
        this.baseballModel = data;
          this.baseballModel = this.baseballModel.result[0];  
          
          if((localStorage.getItem('openBaseballModel')!= this.baseballModel.campaign_banner_name) ) {
          if(this.baseballModel.status == 1){
            this.baseballContent = this.baseballModel.model_popup_image;
            this.link_to_redirect = this.baseballModel.link_to_redirect;
            setTimeout(() => {
              this.baseballmodelbtn.nativeElement.click();
            }, 500);
          } 
        }
      },
      (error) => {
       
        this.isLoaded = true;
      }
    )
  }

  findNearMe() {
   // ga('send', 'event', { eventCategory: "Find Parking", eventLabel:CustomCode.BASEURL, eventAction: 'click'});
    const newYorkBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(40.518673, -74.266017),
        new google.maps.LatLng(40.861947, -73.785519),
        //new google.maps.LatLng(28.702680, 77.428900)
    );

     if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(position => {
          console.log(position);
        let userCLat =position.coords.latitude;
        let userCLong =position.coords.longitude;
        $.get("https://maps.googleapis.com/maps/api/geocode/json?latlng="+userCLat+","+userCLong+"&key="+ CustomCode.GlobalGoogleMapAPIKey, ( data ) => {
          console.log(data);
        
       // alert(userCLat)
        //alert(userCLong);
		//40.7623737/-73.97391189999996
		 //userCLat = 40.7623737;
         //userCLong = -73.97391189999996;
    var myLatlng = new google.maps.LatLng(userCLat, userCLong);
    let exacTime = FilterHelper.defaultStartDurationUrlHomePageData(FilterHelper.changeNyTime());
    //alert(this.endDuration);
    let endTimeData = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
    //alert(endTimeData);
    //this.endTime = FilterHelper.getFormattedTime(this.endDuration);
    if(this.toTimestamp(this.startDuration)<this.toTimestamp(exacTime))
    {
      this.startDuration = FilterHelper.defaultStartDuration();
      this.startTime = FilterHelper.getFormattedTime(this.startDuration);
      localStorage.setItem('monthlyDate',this.startDuration)
      this.endDuration = FilterHelper.defaultEndDuration();
      this.endTime = FilterHelper.getFormattedTime(this.endDuration);
      let endTimeDataIfLess = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
      //alert(endTimeDataIfLess)
      if(this.toTimestamp(endTimeData)<this.toTimestamp(endTimeDataIfLess))
      {
        //alert(2)
        //localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
      }
      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.startDuration,this.startTime));
    }
    //let endTimeDataIfLess = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
   
    let lat:any=40.7589
    let long:any =-73.9851
    let location:any ='Times Square'
    let stime =localStorage.getItem('startimehome')
    if(localStorage.getItem('seachLat'))
    {
      lat =localStorage.getItem('seachLat')
      long =localStorage.getItem('seachLong')
      // location =localStorage.getItem('seachLocation')
      if(localStorage.getItem('seachLocation')){ location =localStorage.getItem('seachLocation')}
    }
	//alert(data.results[0].formatted_address.indexOf("USA")>-1);//alert(userCLong);
    if ( ((data.results[0].formatted_address.indexOf("nY")>-1 || data.results[0].formatted_address.indexOf("Ny")>-1 || data.results[0].formatted_address.indexOf("NY")>-1 || data.results[0].formatted_address.indexOf("ny")>-1) && data.results[0].formatted_address.indexOf("USA")>-1) ||  data.results[0].formatted_address.indexOf("jjhjhjhjh")>-1){
     // alert('test2');
		//alert(userCLat);alert(userCLong);
		this.POSuserLat=userCLat;
    this.POSuserLong=userCLong;
    let url = '/search/'+userCLat+'/'+userCLong+'/'+stime+'/1/'+data.results[0].formatted_address+'/'+this.facilityType+'/'+CustomCode.searchMode()

        if(this.router.url!=url)
        {
          this.router.navigate(['/search', userCLat, userCLong, stime, 1,  data.results[0].formatted_address, this.facilityType, CustomCode.searchMode()]);
        }
    //
     }else{
      //alert('test3');
      let userLat = CustomCode.timeSquareCoords.lat;
      let userLong = CustomCode.timeSquareCoords.long;
      const locationUser = CustomCode.timeSquareCoords.defaultLocation;
      let url = '/search/'+userLat+'/'+userLong+'/'+stime+'/1/'+locationUser+'/'+this.settypedata+'/'+CustomCode.searchMode()
      if(this.router.url!=url)
      {
        this.router.navigate(['/search', userLat, userLong, stime, 1, locationUser,  this.settypedata, CustomCode.searchMode()]); 
      }
      //
     }
    })
      }, this.blockLocationCallback.bind(this));
    } 
	
if(this.POSuserLat!=0 && this.POSuserLong!=0){
	var geocoder;
	geocoder = new google.maps.Geocoder();
	var latlng = new google.maps.LatLng(this.POSuserLat, this.POSuserLong);
	var georoute=this.router;
	var add;
		geocoder.geocode(
			{'latLng': latlng}, 
			function(results, status,georoute) {
				 add= results[0].formatted_address ;
				//alert("city name is: " + add);	
				 $('#googleautosearch').val(add);			
			}
		);
		
 }

  }

  blockLocationCallback() {
    let exacTime = FilterHelper.defaultStartDurationUrlHomePageData(FilterHelper.changeNyTime());
    //alert(this.endDuration);
    let endTimeData = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
    //alert(endTimeData);
    //this.endTime = FilterHelper.getFormattedTime(this.endDuration);
    if(this.toTimestamp(this.startDuration)<this.toTimestamp(exacTime))
    {
      this.startDuration = FilterHelper.defaultStartDuration();
      this.startTime = FilterHelper.getFormattedTime(this.startDuration);
      localStorage.setItem('monthlyDate',this.startDuration)
      this.endDuration = FilterHelper.defaultEndDuration();
      this.endTime = FilterHelper.getFormattedTime(this.endDuration);
      let endTimeDataIfLess = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
      //alert(endTimeDataIfLess)
      if(this.toTimestamp(endTimeData)<this.toTimestamp(endTimeDataIfLess))
      {
        //alert(2)
        //localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
      }
      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.startDuration,this.startTime));
    }
    let lat:any=40.7589
    let long:any =-73.9851
    let location:any ='Times Square'
    let stime =localStorage.getItem('startimehome')
    const getTimeCoords: any = JSON.parse(JSON.stringify(CustomCode.timeSquareCoords));
    const userLat = getTimeCoords.lat;
    const userLong = getTimeCoords.long;
    const defaultLoc = getTimeCoords.defaultLocation;
    let url = '/search/'+userLat+'/'+userLong+'/'+stime+'/1/'+defaultLoc+'/'+this.settypedata+'/'+CustomCode.searchMode()
    //alert(this.router.url)
    if(this.router.url!=url)
    {
      this.router.navigate(['/search', userLat, userLong, stime, 1, defaultLoc, this.settypedata, CustomCode.searchMode()]);
    }
    //
  }


  baseballPopuphide(){
    localStorage.setItem('openBaseballModel', this.baseballModel.campaign_banner_name);
   // ga('send', 'event', {eventCategory: this.baseballModel.campaign_banner_name+' - Popup Banner', eventAction: 'click', eventLabel: 'Cancel Button'});
    this.hideBaseballPopup.nativeElement.click();
  }

  redirctToBaseball(){
   // ga('send', 'event', {eventCategory: this.baseballModel.campaign_banner_name+' - Popup Banner', eventAction: 'click', eventLabel: 'redirect'});
    localStorage.setItem('openBaseballModel', this.baseballModel.campaign_banner_name);
    window.location.href=this.link_to_redirect
   // this.router.navigate(['/'+ this.link_to_redirect]);
  } 
  subscription() {
    const subscribeText = (<HTMLInputElement>document.getElementById('addsubscription')).value.trim();
    if (subscribeText) {
      let data = {};
      if (localStorage.getItem('access_token')) {
        data = {
          "email" : (<HTMLInputElement>document.getElementById('addsubscription')).value.trim(),
          "user_id" : this.activeUser.data.id
        };
      } else {
        data = {
          "email" : (<HTMLInputElement>document.getElementById('addsubscription')).value.trim()
        };
      }
     
      this.homePageServiceObj.subscribeToApplication(data)
        .subscribe(
        data => {
         // ga('send', 'event', { eventCategory: "Subscribe", eventAction: 'Btnclick'});
          this.responseData = data;
          if (this.responseData.status === 201) {
            this.toasterObj.success('Email subscribed successfully', 'success!');
          } else {
            this.toasterObj.error('This email address is already subscribed to our mailing list', 'Sorry!');
          }
          (<HTMLInputElement>document.getElementById('addsubscription')).value = '';
        },
        error => {
          const errorsList = error.errors;
          if (errorsList.message !== 'Validation Errors') {
            this.toasterObj.error(errorsList.message, 'Sorry!');
          } else {
            for (const key in errorsList.detail) {
              if (errorsList.detail.hasOwnProperty(key)) {
                let errorMessage = "This email address is already subscribed to our mailing list";
                if (typeof errorsList.detail === 'undefined') {
                  if (errorsList.message != "The email has already been taken.") {
                    errorMessage = errorsList.message
                  }
                  // this.toasterObj.error(errorsList.message, 'Sorry!');
                } else {
                  if (errorsList.detail[key] != "The email has already been taken.") {
                    errorMessage = errorsList.detail[key]
                  }
                  // this.toasterObj.error(errorsList.detail[key], 'Sorry!');
                }
                this.toasterObj.error(errorMessage, 'Sorry!');
              }
            }
          }
        }
        );
      (<HTMLInputElement>document.getElementById('addsubscription')).value = '';
    } else {
      this.toasterObj.error('email is required', 'Sorry!');
    }
  }
  fetchUserDetails() {
    this.myAccountMonthlyParkingObj.fetchUser()
      .then(
      data => {
        this.activeUser = data;
        
      },
      error => {
        this.toasterObj.error(error.errors.message, 'Sorry!');
        }
      );
  }
  updatePageMeta() {
    this.webdata = this.webMetaServiceObj.getSlugMeta(this.path).then(data => {
      document.title = data['data']['meta']['title'];
      this.descriptionElement.setAttribute('content', data['data']['meta']['description']);
      //this.ogTitle.setAttribute('content', data['data']['meta']['title']);
      //this.ogDescription.setAttribute('content', data['data']['meta']['description']);
      //this.ogUrl.setAttribute('content', window.location.href)
    });
  }
  imageRedirect(type)
  {
    //alert(type);
   // ga('send', 'event', { eventCategory: type, eventAction: 'click'});
  }
  viewBenefit()
  {
  //  ga('send', 'event', { eventCategory: "View All Benefits", eventLabel:"redirect to Monthly Parking", eventAction: 'Hrefclick'});
  }
  contactSeo()
  {
  //  ga('send', 'event', { eventCategory: "Contact Our Business Specialist", eventLabel:"redirect to b2bsolutions", eventAction: 'Btnclick'});
  }
  learnSeo()
  {
  //  ga('send', 'event', { eventCategory: "Learn More", eventLabel:"redirect to b2bsolutions", eventAction: 'Hrefclick'});
  }

  showHideData() {
    if(this.showContentStatus){
      this.showContentStatus = false;
      $('.showOnClick').addClass('hidden-xs');
      $('.btn-300-text').text('Learn More');
    }else {
      this.showContentStatus = true;
      $('.showOnClick').removeClass('hidden-xs');
      $('.btn-300-text').text('Show Less');
    }
  }

  triggerEvent(label){
    if('MONTHLY PARKING SPECIALS'==label)
    {
      localStorage.setItem('sortBy','Neighborhood')
      localStorage.setItem('neighbourhoodActive','1')
      localStorage.setItem('showNeighbordood','1');
      localStorage.removeItem('resetSearch')
    }
    
    if('SPECIALS & OFFERS'==label){
     // ga('send', 'event', { eventCategory: label, eventLabel:'redirect to Icon Specials', eventAction: 'Hrefclick'});
    }else {
     // ga('send', 'event', { eventCategory: label, eventLabel:CustomCode.BASEURL, eventAction: 'click'});
    }
  }

  formonthly()
  {
      this.startDuration = FilterHelper.defaultStartDuration();
      this.startTime = FilterHelper.getFormattedTime(this.startDuration);
      localStorage.setItem('monthlyDate',this.startDuration)
      this.endDuration = FilterHelper.defaultEndDuration();
      this.endTime = FilterHelper.getFormattedTime(this.endDuration);
      let endTimeDataIfLess = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.startDuration,this.startTime));
      localStorage.setItem('fromhomelanding','1');
      let stime =localStorage.getItem('startimehome');
      localStorage.setItem('showNeighbordood','1');
      localStorage.setItem('specialParking','1');
      localStorage.setItem('sortBy', "Neighborhood"); 
      this.router.navigate(['/monthly-parking']);
      //this.router.navigate(['/search', '40.7782667', '-73.9698797', stime, 1,  'central-park','monthly', CustomCode.searchMode()]);
  }
  
}
