import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
// declare var ga: Function;
@Component({
  selector: 'loyaltysection',
  templateUrl: './loyalty.component.html',
  styleUrls: ['./loyalty.component.scss']
})
export class LoyaltyComponent implements OnInit {
  alredyEnroll:number=1;
  imgpath = 'assets/img/'; 
  rewaredlogo = this.imgpath + "loyalty/tier.png";
  rewaredLogoMobile = this.imgpath + "loyalty/tierMobile.png";
  piggybank =  this.imgpath + 'loyalty/piggybank.svg';
  credit = this.imgpath + 'loyalty/disocunt-credit.svg'
  phoneimg = this.imgpath + '/loyalty/phone.svg';
  constructor(private router: Router,) { }
  ngOnInit() {
    if(localStorage.getItem('is_loyalty')=='1')
    {
     this.alredyEnroll=0;
    }else{
      this.alredyEnroll=1;;
    }

  }
  redirectToLoyal()
  {
   // ga ('send', 'event', {eventCategory: 'Enroll Now', eventAction: 'Click' , eventLabel: 'Home page' });
    if (localStorage.getItem('access_token')) {
    if(localStorage.getItem('is_loyalty')=='1')
    {
    }else{
      this.router.navigate(['/my-account/enroll-in-icon-rewards']);
    }
  }else{
    this.router.navigate(['/icon-rewards-register']);
  }
  }

  redirectToReward(){
   // ga ('send', 'event', {eventCategory: 'Learn More', eventAction: 'Click' , eventLabel: 'Home page' });
    this.router.navigate(['/rewards']);
  }

}
