import { Directive, EventEmitter, ElementRef, Input,Output  } from '@angular/core';


declare var $:any;

@Directive({
  selector: '[owl-slider]'
})
export class owlSliderDirective {


  @Output() owlsliderChange = new EventEmitter();

  thisRef: any;

  constructor(private el: ElementRef) {
      setTimeout(() => {
        this.init();
      }, 0);
      this.thisRef = this;
  }
  ngAfterViewInit() {
    //this is your dom
    let thisRef = this.thisRef;

    $(this.el.nativeElement).owlCarousel({
        // stagePadding: 50,
        loop:false,
        margin:50,
        nav:true,
        navText: ["<img src='/assets/img/left_nav.svg'>","<img src='/assets/img/right_nav.svg'>"],
        navContainer: '#customNav',
        // move dotsContainer outside the primary owl wrapper
        dotsContainer: '#customDots',
        animateOut: 'slideOutUp', animateIn: 'slideInUp' ,
        singleItem:true,
    
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:1
            }
        }
    });
      //$(this.el.nativeElement).owlslider('update', true);
 }
  init() {
    let thisRef = this.thisRef;

    // $(this.el.nativeElement).owlCarousel({
    //     // stagePadding: 50,
    //     loop:false,
    //     margin:50,
    //     nav:true,
    //     navText: ["<img src='/assets/img/left_nav.svg'>","<img src='/assets/img/right_nav.svg'>"],
    //     navContainer: '#customNav',
    //     // move dotsContainer outside the primary owl wrapper
    //     dotsContainer: '#customDots',
    //     animateOut: 'slideOutUp', animateIn: 'slideInUp' ,

    
    //     responsive:{
    //         0:{
    //             items:1
    //         },
    //         600:{
    //             items:1
    //         },
    //         1000:{
    //             items:1
    //         }
    //     }
    // });
    //   $(this.el.nativeElement).owlslider('update', true);

  }

}
