import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-brand-partnership',
  templateUrl: './brand-partnership.component.html',
  styles: [`
  .article .ip-details .col-xs-12{
    padding:10px;
    background-color:#f1f1f1;
    margin-left: 15px;
    max-width: 96%;
  }
    .article .ip-details .col-xs-12:after {
      content: " ";
      position: absolute;
      top: -15px;
      left:16%;
      border-top: none;
      border-right: 15px solid transparent;
      border-left: 15px solid transparent;
      border-bottom: 15px solid #f1f1f1;
  }
  .article:nth-child(2) .ip-details .col-xs-12:after
  {left: 48%;}
  .article:nth-child(3) .ip-details .col-xs-12:after
  {left: 80%;}

  @media ( min-width:768px ){
    .spaceRows{
      position:relative;padding-bottom: 130px;
    }.spaceRows .article .ip-details{
      position:absolute; width:100%; height:100%; bottom:-100%; left:0;z-index: 99;min-width: 250px;margin-bottom: 125px;
    }
  }
  @media ( max-width:767px ){
    .spaceRows .article .ip-details{
      padding-top: 20px;
    }
    .article .ip-details .col-xs-12 {max-width: calc(100% - 30px);}
    .article .ip-details .col-xs-12:after {left: 50% !important;}
  }
  `]
})
export class BrandPartnershipComponent implements OnInit {
  partner_ratioIcon =  '/assets/img/partner_ratio.png';
  flyCleanersIcon =  '/assets/img/flyCleaners.jpg';
  shareBiteIcon = '/assets/img/shareBite.jpg';
  chelseaSportsIcon = '/assets/img/chelseaSports.jpg';
  chelseaGolfIcon = '/assets/img/chelseaGolf.jpg';
  handyIcon = '/assets/img/handy.jpg';
  carWashIcon = '/assets/img/carWash.jpg';
  ItuneUrl = 'https://itunes.apple.com/us/app/flycleaners/id726536004?mt=8';
  googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.flycleaners.consumer&hl=en'
  sharebite = 'https://sharebite.com/';
  chelseapiersUrl = 'https://www.chelseapiers.com/sc';
  golfUrl = 'https://www.chelseapiers.com/golf';
  handyUrl = 'https://www.handy.com/';
  phoneNumber = 'tel:2123366000';
  phoneNumber2 = 'tel:2123366400';


  constructor() { }
  ngOnInit() {
  		// $('.mg-space-init').window.mgSpace();
      $('.spaceRows .article').on('click', function(){
        $(this).children('.ip-details').toggle();
        $(this).siblings('.article').children('.ip-details').hide();
      })
 	}
}
