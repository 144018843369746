import { Component, OnInit, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { CustomCode } from '../../shared/CustomCode';
import { Router } from '@angular/router';
import { RouterExtService } from '../../services/router-ext.service';
import { UserserviceService } from '../../services/userservice.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-icon-rewards',
  templateUrl: './icon-rewards.component.html',
  styleUrls: ['./icon-rewards.component.scss'],
  providers: [UserserviceService]
})
export class IconRewardsComponent implements OnInit {
  isLoggedIn: boolean = false;
  loggedUserName;
  previousUrl: string;
  isReferal: boolean = false;
  isLoyaltyMember: boolean = false;
  isLoyaltyActive: boolean = false;
  loyaltyDetails: any = {};
  detailsExist: boolean = false;
  loyalty_points: number = 0;
  rewarded_points: number = CustomCode.rewarded_points
  isLoaded = false;
  memberSince: string;
  rewardNo: number;
  loyaltyCards: any;
  cardListLimit: number = 8;
  isProfileUpdated: number = 0;
  genderList = { 1: 'Male', 2: 'Female', 9: 'Prefer not to answer' };
  modalRef: BsModalRef;
  public config = { ignoreBackdropClick: true }
  nullLink = 'javascript:void(0)';

  icon_reward_logo = 'assets/img/icon-rewards-logo-wt.svg';
  icon_rewards_troffy = 'assets/img/loyalty/piggybank.svg';
  icon_rewards_gift = 'assets/img/icon-rewards-gift.svg';
  price_tag = 'assets/img/price-tag.svg';
  doller_tag = 'assets/img/loyalty/disocunt-credit.svg';
  visa_Icon = 'assets/img/visa.svg';
  mastercardIcon = 'assets/img/mastercard.svg';
  discovercardIcon = 'assets/img/discovercard.svg';
  amexIcon = 'assets/img/amex.svg';
  card_grey_Icon = 'assets/img/card-grey.svg';
  jcbIcon = 'assets/img/jcbcard.svg';
  dinersIcon = 'assets/img/dinersclub-icon.svg';
  helpIcon = 'assets/img/help.svg';
  month = new Array("January", "february", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");
  historyCls = 'col-xs-12 col-sm-12 col-md-8 col-lg-8';
  accoutCls = 'col-xs-12 col-sm-3 col-md-3 col-lg-3 pad-left-0';
  cardCls = 'col-xs-8 col-sm-8 col-md-8 col-lg-8 enrolle-card';
  accountContClass = 'col-xs-12 col-sm-12 col-md-12 col-lg-12 ';
  detailsContClass = 'col-xs-12 col-sm-9 col-md-9 col-lg-9 pad-right-0 pad-left-0';
  @ViewChild('tierBadgeInfoModalBtn',{static:true}) tierBadgeInfoModalBtn: ElementRef;
  tierData:any;
  showBadgePopUp:boolean=false;
  basic = 'assets/img/basic.svg';
  elite = 'assets/img/elite.svg';
  elete_plus = 'assets/img/elite_plus.svg';
  diamond = 'assets/img/diamond.svg';
  discount_badge = 'assets/img/discount-badge.png';
  isTierMsg:string;
  constructor(
    private router: Router,
    private RouterExtService: RouterExtService,
    private userserviceServiceObj: UserserviceService,
    private modalService: BsModalService) {
    this.previousUrl = this.RouterExtService.getPreviousUrl();
    if (this.previousUrl === "/my-account/account") { this.isReferal = true; }
  }

  ngOnInit() {
    if (localStorage.getItem('invalidName') === "1" && localStorage.getItem('is_loyalty_active') === "1") {
      this.router.navigate(['/my-account/manage-details']);
      return false;
    }

    if (localStorage.getItem('access_token')) this.isLoggedIn = true;
    this.loggedUserName = CustomCode.getloggedInUserDetails();
    if (localStorage.getItem('is_loyalty_active') === "1") {
      this.isLoyaltyActive = true;
    }

    if (localStorage.getItem('is_loyalty') === "1") {
      this.isLoyaltyMember = true;
      this.userserviceServiceObj.GetLoyaltyProfile().subscribe(
        res => {
          let uData = res.data;
          this.loyaltyDetails = uData.profile;
          this.memberSince = (uData.member_since !== undefined && uData.member_since !== '') ? uData.member_since.date : '';
          this.rewardNo = uData.reward_no;
          this.isProfileUpdated = parseInt(localStorage.getItem('point_loaded'));
          localStorage.removeItem('point_loaded');
          if (uData.is_loyalty_profile_completed) { this.detailsExist = true; }
          this.userserviceServiceObj.GetLoyaltyWallet().subscribe(
            response => {
              this.userserviceServiceObj.LoyaltyGetCards().subscribe(
                cards => {
                  this.loyaltyCards = cards.data;
                  if(!parseInt(localStorage.getItem('hideLoyaltyBadge'))){
                    this.userserviceServiceObj.GetTierInfo().subscribe(
                      res => {
                        if (res.data.tier_exists) {
                          this.tierData = res.data.tier;
                          this.isTierMsg = res.data.tier.user_defined_detail;
                          this.showBadgePopUp = true;
                        }
                      }
                    )
                  }                 
                  this.isLoaded = true;
                }
              )
              this.loyalty_points = response.data.wallet.balance;
              this.rewarded_points = response.data.loyalty_config.POINTS_AT_REGISTRATION;
            }
          )
        }
      )
    } else {
      this.isLoaded = true;
    }
  }
  enrolledCardInfo(ECardInfo: TemplateRef<any>) {
    this.modalRef = this.modalService.show(ECardInfo, this.config);
    return false;
  }

  closeBage() {
    localStorage.setItem('hideLoyaltyBadge', "1");
    this.showBadgePopUp = false;
  }

  close() {
    this.modalRef.hide();
  }

  toDate(timeString) {
    if (timeString !== '' && timeString != 'undefined') {
      timeString = timeString.substr(0, 10);
      let changeDateFormat = new Date(timeString)
      let afterChnageDate = this.month[changeDateFormat.getMonth()] + " " + changeDateFormat.getFullYear()
      return afterChnageDate
    } else {
      return "";
    }
  }

  navigateResetEmail() {
    this.router.navigate(['/my-account/reset-email']);
  }
}
