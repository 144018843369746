import * as $ from 'jquery';
export class FormValidationErrors {

  public static formErrorsContainer = 'errors-container';
  public static errorsColor = 'red';
  public static errorsPId = 'errorp';

  /**
   * display form errors
   * @param errorsList array
   * @param elementRef string
   */
  public static displayErrors(errorsList) {
    const elementRef = document.getElementById(this.formErrorsContainer);
    let i = 1;
    if (errorsList.message !== 'Validation Errors') {
      elementRef.appendChild(this.createElement(i, errorsList, errorsList.message));
    } else {
      for (const key in errorsList.detail) {
        if (errorsList.detail.hasOwnProperty(key)) {
          elementRef.appendChild(this.createElement(i, errorsList, key));
          i = i++;
        }
      }
    }
    setTimeout (() => {
      elementRef.innerHTML = '';
    }, 4000);
  }

  public static createElement(i, errorsList, key) {
    const para = document.createElement('p');
    if (typeof errorsList.detail === 'undefined') {
      para.innerHTML = errorsList.message;
    } else {
      para.innerHTML = errorsList.detail[key];
    }
    /* para.id = this.errorsPId + '' + i; */
    para.style.color = this.errorsColor;
    return para;
  }

  public static displayServerErrors(elemRef, errorsList) {
    $('html').animate({scrollTop: 0}, 'slow'); //IE, FF
    $('body').animate({scrollTop: 0}, 'slow'); //chr
    const elementRef = document.getElementById(elemRef);
    let i = 1;
    if (errorsList.message !== 'Validation Errors') {
      elementRef.appendChild(this.createElement(i, errorsList, errorsList.message));
    } else {
      for (const key in errorsList.detail) {
        if (errorsList.detail.hasOwnProperty(key)) {
          elementRef.appendChild(this.createElement(i, errorsList, key));
          i = i++;
        }
      }
    }
    setTimeout (() => {
      elementRef.innerHTML = '';
    }, 10000);
  }
}
