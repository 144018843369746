import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FilterHelper } from '../../../classes/filter-helper';

@Component({
  selector: 'app-mobile-search-filter',
  templateUrl: './mobile-search-filter.component.html',
  styleUrls: ['./mobile-search-filter.component.scss']
})
export class MobileSearchFilterComponent implements AfterViewInit {

	@Input() monthlyAction: boolean;
	@Input() landingPage: string;
	@Input() googleMapStartTime: any;
	@Input() googleMapEndTime: any;
	@Output() updateMobileDailyFilters = new EventEmitter<boolean>();
	@Output() updateMobileMonthlyFilters = new EventEmitter<boolean>();
	

	startTime:any;
	endTime:any;
	minTime:any;
	monthlyTime:any;
	lengthOfStay:any;
	ariveSvg='assets/img/arive.svg'
	departSvg='assets/img/depart.svg'
	filterData:any = {
		'currdate' : '',
		'length_of_stay' : '',
		'startTime' : '',
		'endTime' : ''
	};

	mobileStartTime = {
		display: 'bottom',
		theme: 'ios',
		dateFormat: 'M dd, D, ',
		steps: {
			hour: 0,
			minute: 30,
			second: 0,
			zeroBased: true
		},
		dateWheels: '|D M d|',
		onSet: (event, inst) => {
			this.updateMobileStartTime(event, inst);
		}
	}

	mobileEndTime = {
		display: 'bottom',
		theme: 'ios',
		dateFormat: 'M dd, D, ',
		steps: {
			hour: 0,
			minute: 30,
			second: 0,
			zeroBased: true
		},
		dateWheels: '|D M d|',
		onSet: (event, inst) => {
			this.updateMobileEndTime(event, inst);
		}
	}

	mobileMonthlyTime = {
		display: 'bottom',
		theme: 'ios',
		min: new Date(),
		dateFormat: 'M dd, D',
		dateWheels: 'MM dd yy',
		// dd D 
		onSet: (event, inst) => {
			this.updateMobileMonthlyTime(event, inst);
		}
	}

	isMonthly:boolean = false;

	constructor(private toaster: ToastrService, private router: Router,) { }

	ngOnInit() {
		this.isMonthly = false;
		if (this.router.url.indexOf('monthly') > -1) {
			this.isMonthly = true;
		}
		this.minTime = FilterHelper.defaultStartDuration();
		this.startTime = new Date(this.googleMapStartTime);
		this.endTime = new Date(this.googleMapEndTime);
		this.monthlyTime = FilterHelper.defaultStartDuration();
		this.InvokeFilterAction();
	}

	ngAfterViewInit() { }

	debugDates() {
		
	}

	updateStartTimeManually(startTime) {
		this.startTime = new Date(startTime);
		
	}

	updateEndTimeManually(endTime) {
		this.endTime = new Date(endTime);
		
	}

	updateMobileStartTime(event, inst) {
		if (this.startTime < this.minTime) {
			this.startTime = new Date(this.minTime);
			this.toaster.error(FilterHelper.PAST_TIME_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
		} else {
			let lengthOfStay = FilterHelper.calculateHours(this.startTime, this.endTime);
			if (lengthOfStay < 0.5) {
				let newEndDuration = new Date(this.startTime);
				newEndDuration.setMinutes(newEndDuration.getMinutes() + 30);
				this.endTime = new Date(newEndDuration);
				this.toaster.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
			}
			this.InvokeFilterAction();
		}
	}

	updateMobileEndTime(event, inst) {
		let lengthOfStay = FilterHelper.calculateHours(this.startTime, this.endTime);
		if (lengthOfStay < 0.5) {
			let currentStartDuration = new Date(this.startTime);
			this.endTime = new Date(currentStartDuration);
			this.endTime.setHours(currentStartDuration.getHours());
			this.endTime.setMinutes(currentStartDuration.getMinutes());
			this.endTime.setMinutes(this.endTime.getMinutes() + 30);
			this.toaster.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
		}
		this.InvokeFilterAction();
	}

	updateMobileMonthlyTime(event, inst) {
		this.updateMobileMonthlyFilters.emit(this.monthlyTime);
	}

	InvokeFilterAction() {
		let startTime:any = new Date(this.startTime);
		let endTime:any = new Date(this.endTime);
		startTime.setSeconds(0);
		endTime.setSeconds(0);
		this.filterData.currdate = FilterHelper.generateArrivalTime(this.startTime);
		this.filterData.length_of_stay = FilterHelper.calculateHours(startTime, endTime);
		localStorage.setItem('mobileChangeStartTime',this.startTime)
		this.filterData.startTime = startTime;
		this.filterData.endTime = endTime;
		this.updateMobileDailyFilters.emit(this.filterData);
	}

}