import { Injectable } from '@angular/core';
//import { Http, Headers, RequestOptions, Response } from '@angular/http';
// import { HttpService } from './http.service';
//import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { CustomCode } from '../shared/CustomCode';
// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class WebMetaService {

  constructor(private http:HttpClient) { }

	private getWebMetaURL = CustomCode.APIURL + 'web-meta/slug?slug=';

	getSlugMeta(slug) {
	    return new Promise((resolve, reject) => {
	      this.http.get(this.getWebMetaURL+slug).subscribe(
	        (res:any)=> {
	          resolve(res);
	        },
	        error => {
	          reject(JSON.parse(error['_body']));
	        }
	      );
	    });	
    }

	getSlugMetaLowerCase(slug) {
		let slugLower = slug.toLowerCase();
	    return new Promise((resolve, reject) => {
	      this.http.get(this.getWebMetaURL+slugLower).subscribe(
	        (res:any)=> {
	          resolve(res.json());
	        },
	        error => {
	          reject(JSON.parse(error['_body']));
	        }
	      );
	    });	
    }
	

}
