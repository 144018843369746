import { Component, OnInit } from '@angular/core';
import { StaticpagesService } from '../../services/staticpages.service';
@Component({
  selector: 'app-icon-reward-terms',
  templateUrl: './icon-reward-terms.component.html',
  styleUrls: ['./icon-reward-terms.component.scss'],
  providers: [StaticpagesService]
})
export class IconRewardTermsComponent implements OnInit {
  pageSlug:string = 'terms-and-conditions';
  cmsPageContent : any = {};
  isLoaded = false;
  constructor(private staticObj: StaticpagesService) { }

  ngOnInit() {
    this.staticObj.getCmsPageContent(this.pageSlug).subscribe((data:any) => {
      this.cmsPageContent = data.result[0];
      this.isLoaded = true;
    }, (error) => { console.log('Error: ' + JSON.stringify(error));
      this.isLoaded = true;
    }
  );
  }

}
