
import { Component, OnInit, NgZone, Input, EventEmitter, Output, ChangeDetectorRef, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
declare var google: any;

import * as $ from 'jquery';
//declare var kochava:any;

import { CustomCode } from '../../../shared/CustomCode';
import { SearchPageScript } from '../../../shared/searchPageScript';
import { FormValidationErrors } from '../../../shared/formValidationErrors';
import { FilterHelper } from '../../../classes/filter-helper';

import { NgForm } from '@angular/forms';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { AgmCoreModule, AgmMap, AgmMarker } from '@agm/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { SearchpageserviceService } from '../../../services/searchpageservice.service';
import { UserserviceService } from '../../../services/userservice.service';
import { SearchFacilityComponent } from '../../search/search-facility/search-facility.component';
import { MyAccountMonthlyParkingService } from '../../../services/my-account-monthly-parking.service';
import { HomepageserviceService } from '../../../services/homepageservice.service';

import { MobileSearchFilterComponent } from '../../Global/mobile-search-filter/mobile-search-filter.component';
import { MobileSearchFilterNavComponent } from '../../Global/mobile-search-filter-nav/mobile-search-filter-nav.component';
import {GoogleAnalyticsEventsService} from "../../../shared/google-analytics-events.service";
// declare var ga: Function;
@Component({
  selector: 'app-partners-map',
  templateUrl: './partners-map.component.html',
  styleUrls: ['./partners-map.component.scss'],
  providers: [MyAccountMonthlyParkingService, HomepageserviceService]
})
export class PartnersMapComponent implements AfterViewInit {



	@Output() updateFilters = new EventEmitter<boolean>();

	@ViewChild(MobileSearchFilterComponent,{static:true}) mobileSearchFilter;
	@ViewChild(MobileSearchFilterNavComponent,{static:true}) mobileSearchFilterNav;



	mobileFilterNavDisplay:boolean = true;
	EMAIL_COUPON:string = 'email';
	PRINT_COUPON:string = 'print';
	VIEW_COUPON:string = 'view';

	location_trackIcon = 'assets/img/location-track.svg';
	satViewIcon = 'assets/img/satView.png';
	park_garageIcon = '/assets/img/park_garage.png';
	loadingIcon ='assets/img/loading.gif';
	compassIcon = 'assets/img/compass.svg';
	listView = 'assets/img/listView.png';
	phoneNumber = 'tel:888-705-7845';
	phoneNumber2 = 'tel:1-877-727-5464';
	icon_park_gray_icon ='assets/img/icon-park-gray.svg';
	quikparkIcon = 'assets/img/quikpark.svg';
	

	startDuration:any;
	endDuration:any;


	monthlyDate:any;

	dStartTime;
	dEndTime;


	bookingRate:any = 40;
	earlyBirdSpecialCategory:any = 1;
	searchResult: any = {};
	EarlyBrid:any;
	earlybirIcon:any;
	firstLoad = false;
	centerMarker:any ;
	isDraggable = false;
	//searchCriteria: any;
	searchCriteria: any = {
		'type': CustomCode.defaultFacilityType,
		'device': 'web',
		'lat': parseFloat(CustomCode.timeSquareCoords.lat),
		'long': parseFloat(CustomCode.timeSquareCoords.long),
		'currdate': CustomCode.getDateForMapSearch(),
		'radius': CustomCode.facilityDefaultRadius,
		'label': CustomCode.timeSquareCoords.defaultLocation,
		'length_of_stay': 2,
		'firstLoad' : this.firstLoad
	};
	webGarage: any;

	constructor(
		private modalService: BsModalService,
		private ngZoneObj: NgZone,
		private router: Router,
		private activatedRouteObj: ActivatedRoute,
		private searchPageObj: SearchpageserviceService,
		private userServiceObj: UserserviceService,
		private changeDetObj: ChangeDetectorRef,
		public sanitizer: DomSanitizer,
		private toasterObj: ToastrService,
		private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
		private homePageServiceObj: HomepageserviceService,
		public googleAnalyticsEventsService: GoogleAnalyticsEventsService
		
	) { 
		// for animation show hide actions

		this.sidebarOn  = false;


		this.parkDetail = false;
		this.parkList = false;



		this.MobSection = false;
		this.swipeOn = false;
		this.showHide = true;
		this.isMonthlyAction = true;
		this.animationImg = false;
	}

		public scrollbarOptions = { axis: 'y', theme: 'minimal' };
 		// for sidebar active and inactive state
 		sidebarOn :any;
 		parkDetail : any;
 		parkList: any;
 		MobSection :any;
 		swipeOn : any;
 		showHide :any;
 		isMonthlyAction :any;
 		animationImg :any;

 


	SWIPE_ACTION = { UP: 'swipeup', DOWN: 'swipedown' };
	swipet(action: any = this.SWIPE_ACTION.UP) {
		this.swipeOn = true;
		this.sidebarOn = true;
		this.MobSection = true;
		this.showHide = false;
		this.mobileFilterNavDisplay = false;
		// this.MobSection.push('open');
		
			     this.animationImg  = true;
			
			
		
	}

	swiped(action: any = this.SWIPE_ACTION.DOWN) {
		this.swipeOn = false;
		this.sidebarOn = false;
		this.MobSection = false;

			setTimeout(()=>{  
			     this.showHide = true;
			    
			 },1000);
			 this.animationImg  = false;
			$('body').removeClass('noscroll');

			// setTimeout(()=>{  
			//      this.showHide = true;
			//  },2000);
		this.mobileFilterNavDisplay = true;
		this.mobileSearchFilterNav.updateStartTimeManually(this.startDuration);
		this.mobileSearchFilterNav.updateEndTimeManually(this.endDuration);
		this.mobileSearchFilterNav.updateDurationManually();
		// this.MobSection.pop('open');

	}

	

	newStartTime;
	newEndTime;

	searchType: any = {};

	activeGarage: any = {};

	/* Previous properties */

	formData: any = {};
	lat: number;
	long: number;
	facilityData: any = {};
	selectedDate: string;
	parkingDetails: any = {};
	allParkings: any = {};
	customizeData: any = {};
	private parseData;
	successFailElement = 'success-failed';
	responseData;

	radius: number;

	facilityRadius = 2;
	facilityLabel = 'Testing URl';
	searchsdate = '';
	options: any = {};
	en: any = {};
	facilitySchedule: any = {};
	couponsData;
	dSD:any = SearchPageScript.defaultDateForDailyMonthlyFacility();
	dailyEndDate:any = SearchPageScript.defaultDateForDailyMonthlyFacility();
	nMap: any = {};
	// monthlyDate = SearchPageScript.defaultDateForDailyMonthlyFacility();
	currDate = CustomCode.getDateForMapSearch();
	/* currDateObj = new Date();
	startTime = new Date(this.currDateObj.setMinutes(this.currDateObj.getMinutes() + 30));
	endTime = new Date(this.currDateObj.setHours(this.currDateObj.getHours() + 2.5)); */
	currDateObj = new Date();
	
	coeff = 1000 * 60 * 30;
	startTime = new Date(Math.round(this.currDateObj.getTime() / this.coeff) * this.coeff);

	endTime = new Date(Math.round(this.currDateObj.getTime() / this.coeff) * this.coeff);

	stime = (this.startTime.getHours() > 12) ? (this.startTime.getHours() - 12 + ':' + this.startTime.getMinutes() + ' PM') : (this.startTime.getHours() + ':' + this.startTime.getMinutes() + ' AM');
	etime = (this.endTime.getHours() > 12) ? (this.endTime.getHours() - 12 + ':' + this.endTime.getMinutes() + ' PM') : (this.endTime.getHours() + ':' + this.endTime.getMinutes() + ' AM');
    sdate;
	loggedUserName;
	minDate: Date;
	maxDate: Date;
	public modalRef: BsModalRef;
	mailFormData: any = {};
	respdata;
	printData;
	restrictRequestOnInit = 'stop';
	centerMarkerPosition:any;

	isActiveFacility = false;
	activeFacilityIndex: any = null;
	markers: any = [];
	isLoaded = false;
	preventUnusedAjax = false;
	garageWorkingHours;
	activeUser;
    isPerMonth=false;
	isNeighbourHood:boolean = false;

	  datepickerOptions = {
		display: 'bottom',
		theme: 'ios',
	  }
	  mapType = CustomCode.mapDefaultView;
	  mFirstDateTime;
	  mSecondDateTime;

	  isFirstLoad = true;

	ngOnInit() {


  		window.scrollTo(0,0);
            $("body").animate({ scrollTop: 0 }, 500);

		this.startDuration = FilterHelper.defaultStartDuration();
		// this.startDuration.setSeconds('00');
        
		this.endDuration = FilterHelper.defaultEndDuration();
		// this.endDuration.setSeconds('00');



		this.animationImg = false;
		this.parkDetail = true;
		this.parkList = false;

		if (this.router.url.indexOf('neighborhoods') > -1) {
    		this.isNeighbourHood = true;
    	}

    	// this.dStartTime = $('#startTime').val();
    	// this.dEndTime = $('#endTime').val();
		this.minDate = new Date();
		this.monthlyDate = FilterHelper.defaultStartDuration();
		this.startTime = new Date(this.startTime.setMinutes(this.startTime.getMinutes() + 30));
		this.endTime = new Date(this.endTime.setMinutes(this.endTime.getMinutes() + 150));
		/* $('#stime').val(this.stime);
		$('#etime').val(this.etime); */
		SearchPageScript.openTimePicker();
		if (this.router.url.indexOf('neighborhoods') < 0) {
			this.activatedRouteObj.params.subscribe((params: Params) => {
				this.sidebarOn = false;
				this.parkDetail = false;
				this.parkList = false;
				this.lat = +params['lat'];
				this.long = +params['long'];
				this.selectedDate = params['currdate'];
				this.radius = +params['radius'];
				let locationUser = params['label'];
				this.searchType = params['type'];

				if (this.searchType === CustomCode.monthlyConstant) {
					$('#dailyFilter').addClass('hidden');
					$('#monthlyFilter').removeClass('hidden');
				}

				setTimeout(() => {
					$('#auto_suggest_input_field').val(locationUser);
				}, 200);
				

				this.en = CustomCode.datepickerOptions();
                
				this.loadMap(this.searchCriteria);
			});
		}
		SearchPageScript.closeSideBar();
		//this.buyDailyMonthly();
		this.loggedUserName = CustomCode.getloggedInUserDetails();
		CustomCode.displayLoginUser();
		if (!this.loggedUserName) {
			$('#mailcoupon').css('display', 'none');
		} else {
			$('#mailcoupon').css('display', 'block');
		}
	}


	ngAfterViewInit() {
		if (this.mobileSearchFilter) {
			this.mobileSearchFilter.updateStartTimeManually(this.startDuration);
			this.mobileSearchFilter.updateEndTimeManually(this.endDuration);
		}
	}

	btnEarlyBrid(){
	  	if(this.EarlyBrid == true){
			this.EarlyBrid = false;
			this.earlybirIcon = "fa fa-info-circle";
			
	  	}
	  	else{
	  		this.EarlyBrid = true;
	  		this.earlybirIcon = "fa fa-times";
	  		
	  	}
  	}

	displayableStartDateWrapper() {
		return $('#startdate').val().trim();
	}

	navigateBuyMonthlyPage() {
		let url = "/buy-monthly/" + this.allParkings.facility_id + "/" + FilterHelper.formatBuyMonthlyDate(FilterHelper.makeDurationDateObject($('#startdate').val(), $('#startTime').val()));
		this.router.navigate([url]);
	}

	getMonthlyStartDate(){
		// return FilterHelper.formatArrivalDate(this.monthlyDate);
		return FilterHelper.formatBuyMonthlyDate(this.startDuration);
		
	}

	displayableEndDateWrapper() {
		return $('#enddate').val().trim();
	}

	buyDailyMonthly() {
		   // ga('send', 'event', { eventCategory: 'web reservation', eventAction: 'click'});

			// const facId = $('#dailymonthlybutton').attr('facId');
			// const rate = $('#dailymonthlybutton').attr('rate');
			// const dailyMonthly = $('#dailymonthlybutton').attr('buyType');

			const facId = this.allParkings.facility_id;
			const dailyMonthly = this.searchCriteria.type;

			localStorage.setItem('monthly', this.monthlyDate);
			let routeNavigate = '/buy-monthly';
			if (dailyMonthly === 'daily') {
				const rate = this.allParkings.price;
				const sDate = $('#startdate').val().toString().trim();
				const eDate = $('#enddate').val().toString().trim();
				const sTime = $('#startTime').val().toString().trim();
				const eTime = $('#endTime').val().toString().trim();
				let redirect = sDate + '&' + sTime + '&&' + eDate + '&' + eTime;
				redirect = redirect.split('/').join('-');
				const navURL = "/payment/" + facId + "/" + redirect + "/" + this.bookingRate;
				this.router.navigate([navURL]);
			} else {
				if($('#monthlydate').val()){
					const rate = this.allParkings.monthly_rate;
					const mDate = $('#monthlydate').val().toString().trim();
					if (mDate !== '') {
						const mD = $('#monthlydate').val().toString().split('/');
						const fDate = mD[2] + '-' + mD[0] + '-' + mD[1];
						this.router.navigate([routeNavigate, facId, fDate]);
					} else {
						// this.toasterObj.error('End time should be greater than start time', 'error');
					}
                }
			}
		//});
	}

	backLinkBtn(){
		// this.mobileFilterNavDisplay = true;
		// this.sidebarOn = false;
		// this.animationImg = false;
		// this.parkDetail = true;
		// this.parkList = false;
		// this.swipeOn = false;
		// this.mobileFilterNavDisplay = true;
		// $('body').removeClass('noscroll');

		this.swipeOn = false;
		this.sidebarOn = false;
		this.MobSection = false;
			setTimeout(()=>{  
			     this.showHide = true;
			    
			 },1000);
			 this.animationImg  = false;
		$('body').removeClass('noscroll');
		this.mobileFilterNavDisplay = true;
		this.mobileSearchFilterNav.updateStartTimeManually(this.startDuration);
		this.mobileSearchFilterNav.updateEndTimeManually(this.endDuration);
		this.mobileSearchFilterNav.updateDurationManually();


	}
	
	buyFromMobile() {
	//	ga('send', 'event', { eventCategory: 'mobile reservation', eventAction: 'click'});
		const facId = $('#dailymonthlybutton').attr('facId');
		const rate = $('#dailymonthlybutton').attr('rate');
		const dailyMonthly = $('#dailymonthlybutton').attr('buyType');
		localStorage.setItem('monthly', this.monthlyDate);
		let routeNavigate = '/buy-monthly';
		$('body').addClass('noscroll');
		this.sidebarOn = true;

		this.animationImg = false;
		this.parkDetail = true;
		if (dailyMonthly === 'daily') {
			let startDate = FilterHelper.formatDate(this.startDuration);
			let startTime = FilterHelper.getFormattedTime(this.startDuration);
			let endDate = FilterHelper.formatDate(this.endDuration);
			let endTime = FilterHelper.getFormattedTime(this.endDuration);
			let redirect = startDate + '&' + startTime + '&&' + endDate + '&' + endTime;
			redirect = redirect.split('/').join('-');
			const navURL = "/payment/" + facId + "/" + redirect + "/" + this.bookingRate;
			this.router.navigate([navURL]);
		} else {
			let buyMonthlyDate = FilterHelper.formatBuyMonthlyDate(this.monthlyDate);
			// const mDate = $('#monthlydate').val().toString().trim();
			if (buyMonthlyDate !== '') {
				// const mD = $('#monthlydate').val().toString().split('/');
				// const fDate = mD[2] + '-' + mD[0] + '-' + mD[1];
				this.router.navigate([routeNavigate, facId, buyMonthlyDate]);
			} else {
				// this.toasterObj.error('End time should be greater than start time', 'error');
			}
		}
	}

	updateData() {
		$('#petime').val($('#etime').val());
		$('#pstime').val($('#stime').val());
		let startDate = new Date($('#startdate').val());
		let endDate = new Date($('#enddate').val());
		this.dSD = startDate;
		this.dailyEndDate = endDate;
	}

	manageDatePicker(val) {
		$('#' + val).trigger('click');
	}

	updateSearchLengthOfStay(val) {
		this.searchCriteria.length_of_stay = val;
	}

	getPStartDate() {
		if(this.searchCriteria.firstLoad === true) {
			const sDate = $('#pstartdate').val();
			const eDate = $('#penddate').val();
			const sTime = $('#pstime').val();
			const eTime = $('#petime').val();
			if (this.searchCriteria.type == 'daily') {
				const lengthOfStay = SearchPageScript.getLengthOfStay(sDate, sTime, eDate, eTime);

				if (lengthOfStay > 0) {
					const reservationTime = sDate.toString().split('/');
					const m = reservationTime[1];
					const d = reservationTime[0];
					const y = reservationTime[2];
					const rTime = y + '-' + d + '-' + m + '' + this.changeToFullTime(this.stime);
					this.searchCriteria.length_of_stay = lengthOfStay;
					this.searchCriteria.currdate = rTime;
					this.updateFilters.emit(this.searchCriteria);
				} else {
					this.dailyEndDate = this.dSD;
				}
			}
		}
	}

	getPEndDate() {
		if(this.searchCriteria.firstLoad === true) {
			const sDate = $('#pstartdate').val();
			const eDate = $('#penddate').val();
			const sTime = $('#pstime').val();
			const eTime = $('#petime').val();
			if (this.searchCriteria.type == 'daily') {
				const lengthOfStay = SearchPageScript.getLengthOfStay(sDate, sTime, eDate, eTime);
				if (lengthOfStay > 0) {
					const reservationTime = sDate.toString().split('/');
					const m = reservationTime[1];
					const d = reservationTime[0];
					const y = reservationTime[2];
					const rTime = y + '-' + d + '-' + m + '' + this.changeToFullTime(this.stime);
					this.searchCriteria.length_of_stay = lengthOfStay;
					this.searchCriteria.currdate = rTime;
					this.updateFilters.emit(this.searchCriteria);
				} else {
					this.dSD = this.dailyEndDate;
				}
			}
		}
	}

	getPEndTime($event) {
		if(this.searchCriteria.firstLoad === true) {

			const cDate = new Date();
			let tempEndTime = new Date(this.endTime);
			let bookingHours = this.getLengthOfStayEndTime($event);
			if (bookingHours < 2 && this.searchCriteria.type == 'daily') {
				setTimeout(() => {
					this.endTime = new Date(tempEndTime);
					this.toasterObj.error('In order to book a reservation, minimum booking time must be at least 2 hours', 'Error');
				}, 100);
				return;
			} else {
				if($event.getTime() > cDate.getTime()) {
					this.endTime = $event;
					const mnts = this.endTime.getMinutes() < 10 ? '0' + this.endTime.getMinutes() : this.endTime.getMinutes();
					const tTime = CustomCode.getUserTime(this.endTime);
					$('#petime').val(tTime);

					const sDate = $('#pstartdate').val();
					const eDate = $('#penddate').val();
					const sTime = $('#pstime').val();
					const eTime = $('#petime').val();
					if (this.searchCriteria.type == 'daily') {
						const lengthOfStay = SearchPageScript.getLengthOfStay(sDate, sTime, eDate, eTime);
						if (lengthOfStay > 0) {
							const reservationTime = sDate.toString().split('/');
							const m = reservationTime[1];
							const d = reservationTime[0];
							const y = reservationTime[2];
							const rTime = y + '-' + d + '-' + m + '' + this.changeToFullTime(this.stime);
							this.searchCriteria.length_of_stay = lengthOfStay;
							this.searchCriteria.currdate = rTime;
							this.updateFilters.emit(this.searchCriteria);
						} else {

						}
					}
				} else {
					this.searchCriteria.length_of_stay = 0;
					$('#petime').val(CustomCode.getUserTime($event));
					this.toasterObj.error('You cannot book the reservation in the past', 'error');
				}
			}
			
		}
	}

	updateEndTime() {
		let ssTime = this.startTime;
		let newsTime = new Date(ssTime);
		this.endTime = new Date(newsTime.setMinutes(newsTime.getMinutes() + 120));
	}

	getPStartTime($event) {
		if(this.searchCriteria.firstLoad === true) {
			//const cDate = new Date();
			let cDate = new Date();
			const updatedDate = new Date(this.dailyEndDate);
			cDate.setDate(updatedDate.getDate());
			cDate.setMonth(updatedDate.getMonth());
			cDate.setFullYear(updatedDate.getFullYear());
			if($event.getTime() > cDate.getTime()) {
				this.startTime = $event;

				const sTime = CustomCode.getUserTime(this.startTime);
				$('#pstime').val(sTime);

				const sDate = $('#pstartdate').val();
				const eDate = $('#penddate').val();
				const eTime = $('#petime').val();
				this.updateEndTime();
				if (this.searchCriteria.type == 'daily') {
					const lengthOfStay = SearchPageScript.getLengthOfStay(sDate, sTime, eDate, eTime);
					if (lengthOfStay > 0) {
						const reservationTime = sDate.toString().split('/');
						const m = reservationTime[1];
						const d = reservationTime[0];
						const y = reservationTime[2];
						const rTime = y + '-' + d + '-' + m + '' + this.changeToFullTime(this.stime);
						this.searchCriteria.length_of_stay = lengthOfStay;
						this.searchCriteria.currdate = rTime;
						this.updateFilters.emit(this.searchCriteria);
					} else {

					}
				}
			} else {
				this.searchCriteria.length_of_stay = 0;
				$('#pstime').val(CustomCode.getUserTime($event));
				this.toasterObj.error('You cannot book the reservation in the past', 'error');
			}


		}
	}

	getFormattedTime(val) {
		return CustomCode.getUserTime(val);
	}

	getFormattedDate(val) {
		const currDate = val.toLocaleDateString().substr(0, 10);
		return currDate;
	}
	
	updateMobileEndTime() {
		let ssTime = this.startTime;
		let newsTime = new Date(ssTime);
		this.endTime = new Date(newsTime.setMinutes(newsTime.getMinutes() + 120));
		
		/* const stime = CustomCode.getUserTime(ssTime);

		const splitDate = stime.split(' ');
		const getHour = splitDate[0].split(':');
		const amPm = splitDate[1];
		if(amPm === 'PM' && parseInt(getHour[0]) >= 10) {
			const dateObj = new Date();
			const nDate = new Date(dateObj.setDate(dateObj.getDate() + 1));
			const newMonth = nDate.getMonth() + 1;
			this.dailyEndDate = newMonth + '/' + nDate.getDate() + '/' + nDate.getFullYear();
		} */
	}


	getMobileStartDate(event, inst) {
		// this.isFirstLoad = false;
		// const startDate = this.getFormattedDate(new Date(this.startTime));
		// const startTime = this.getFormattedTime(new Date(this.startTime));

		// const endDate = this.getFormattedDate(new Date(this.endTime));
		// const endTime = this.getFormattedTime(new Date(this.endTime));
		// const lengthOfStay = SearchPageScript.getLengthOfStay(startDate, startTime, endDate, endTime);
		// if(lengthOfStay <= 0) {
		// 	this.updateMobileEndTime();
		// 	this.getMobileEndDate('', '');
		// } if(lengthOfStay  < 2) {
		// 	this.searchCriteria.length_of_stay = 0;
		// 	this.toasterObj.error('Minimum booking should be 2 hours', 'error');
		// }
	}

	getMobileEndDate(event, inst) {
		// this.isFirstLoad = false;
		// const startDate = this.getFormattedDate(new Date(this.startTime));
		// const startTime = this.getFormattedTime(new Date(this.startTime));

		// const endDate = this.getFormattedDate(new Date(this.endTime));
		// const endTime = this.getFormattedTime(new Date(this.endTime));
		
		// const lengthOfStay = SearchPageScript.getLengthOfStay(startDate, startTime, endDate, endTime);
		// if(lengthOfStay > 2 ) {
		// 	$('#enddate').val(endDate);
		// 	$('#startdate').val(startDate);
		// 	$('#starttime').val(startTime);
		// 	$('#pendtime').val(endTime);
		// 	this.searchCriteria.length_of_stay = lengthOfStay;
		// 	//this.updateMobileFilters.emit(this.searchCriteria);
		// } else if(lengthOfStay < 2) {
		// 	this.searchCriteria.length_of_stay = 0;
		// 	this.toasterObj.error('Minimum booking should be 2 hours', 'error');
		// }else {
		// 	// this.toasterObj.error('End time should be greater than start time', 'error');
		// }

	}

	receiveNewMobileNavDailyFilters(event) {
		this.searchCriteria.currdate = event.currdate;
		this.searchCriteria.length_of_stay = event.length_of_stay;
		this.startDuration = new Date(event.startTime);
		this.endDuration = new Date(event.endTime);
		this.updateFilters.emit(this.searchCriteria);

		if (this.mobileSearchFilter) {
			this.mobileSearchFilter.updateStartTimeManually(this.startDuration);
			this.mobileSearchFilter.updateEndTimeManually(this.endDuration);
		}
	}


	receiveNewMobileDailyFilters(event) {
		this.searchCriteria.currdate = event.currdate;
		this.searchCriteria.length_of_stay = event.length_of_stay;
		this.startDuration = new Date(event.startTime);
		this.endDuration = new Date(event.endTime);
		this.updateFilters.emit(this.searchCriteria);
	}

	receiveNewMobileMonthlyFilters(monthlyDate) {
		this.monthlyDate = new Date(monthlyDate);
		this.searchCriteria.currdate = FilterHelper.generateArrivalTime(this.monthlyDate);
		this.updateFilters.emit(this.searchCriteria);
	}

	getStartTime() {
		const mnts = this.startTime.getMinutes() < 10 ? '0' + this.startTime.getMinutes() : this.startTime.getMinutes();
		const sTime = (this.startTime.getHours() > 12) ? (this.startTime.getHours() - 12 + ':' + mnts + ' PM') : (this.startTime.getHours() + ':' + mnts + ' AM');
		$('#stime').val(sTime);
		$('#pstime').val(sTime);
		const sDate = $('#startdate').val();
		const eDate = $('#enddate').val();
		const facType = $('label.active').attr('id').toLowerCase().trim();
		this.stime = sTime;
		if (facType == 'daily') {
			const lengthOfStay = SearchPageScript.getLengthOfStay(sDate, this.stime, eDate, this.etime);
			if (lengthOfStay > 0) {
				const reservationTime = sDate.toString().split('/');
				const m = reservationTime[1];
				const d = reservationTime[0];
				const y = reservationTime[2];
				const rTime = y + '-' + d + '-' + m + '' + this.changeToFullTime(this.stime);
			}
		}
	}

	garageInfo() {

	}

	mobilePayment() {

	}

	sendApp() {
		const appText = parseInt((<HTMLInputElement>document.getElementById('sendapp')).value.trim());
		if (appText) {
		  const phoneNum = ((<HTMLInputElement>document.getElementById('sendapp'))).value;
		  const userLat = ((<HTMLInputElement>document.getElementById('navigatetogoogle'))).getAttribute('href');
		  const text = 'Welcome to icon parking. click on the link below ' + userLat;
		  this.homePageServiceObj.messageToMobile(phoneNum, text)
			  .subscribe(
			  data => {
				this.responseData = data;
				this.toasterObj.success('Facility Location has been sent Successfully', 'success!');
				$('button.close').trigger('click');
			  },
			  error => {
				const errorsList = error.errors;
				if (errorsList.message !== 'Validation Errors') {
				  this.toasterObj.error(errorsList.message, 'Sorry!');
				} else {
				  for (const key in errorsList.detail) {
					if (errorsList.detail.hasOwnProperty(key)) {
					  if (typeof errorsList.detail === 'undefined') {
						this.toasterObj.error(errorsList.message, 'Sorry!');
					  } else {
						this.toasterObj.error(errorsList.detail[key], 'Sorry!');
					  }
					}
				  }
				}
			  }
			  );
		} else {
		  this.toasterObj.error('Number is required', 'Sorry!');
		}
		(<HTMLInputElement>document.getElementById('sendapp')).value = '';
	  }

	changeToFullTime(time) {
		const date = new Date();
		let hours;
		let minutes;
		let am_pm;
		const newTime = time.split(':');
		hours = newTime[0];
		const newT = newTime[1].split(' ');
		minutes = newT[0];
		am_pm = newT[1];
		if (am_pm == 'PM') {
			const convertToFullDate = ['12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
			hours = convertToFullDate[hours];
		}
		return hours + ':' + minutes + ':00';
	}

	getEndTime() {
		const sDate = $('#startdate').val();
		const eDate = $('#enddate').val();
		const mnts = this.endTime.getMinutes() < 10 ? '0' + this.endTime.getMinutes() : this.endTime.getMinutes();
		const tTime = (this.endTime.getHours() > 12) ? (this.endTime.getHours() - 12 + ':' + mnts + ' PM') : (this.endTime.getHours() + ':' + mnts + ' AM');
		$('#etime').val(tTime);
		$('#petime').val(tTime);
		const facType = $('label.active').attr('id').toLowerCase().trim();
		this.etime = tTime;
		if (this.restrictRequestOnInit != 'stop') {
			if (facType == CustomCode.defaultFacilityType) {
				const lengthOfStay = SearchPageScript.getLengthOfStay(sDate, this.stime, eDate, this.etime);
				if (lengthOfStay > 0) {
					const reservationTime = sDate.toString().split('/');
					const m = reservationTime[1];
					const d = reservationTime[0];
					const y = reservationTime[2];
					const rTime = y + '-' + d + '-' + m + '' + this.changeToFullTime(this.stime);

				} else {

				}
			}
		}
	}

	loadMap(searchCriteria) {
		this.searchCriteria = searchCriteria;
		this.searchType = this.searchCriteria.type;
		const googleInit = CustomCode.getGoogleInitData(this.searchCriteria.lat, this.searchCriteria.long);
		this.nMap = new google.maps.Map(document.getElementById('map_canvas'), googleInit);
		this.centerMarker = new google.maps.Marker({
			position: new google.maps.LatLng(this.searchCriteria.lat, this.searchCriteria.long),
			map: this.nMap,
			/* icon: CustomCode.googleMapDefaultIcon, */
		});
		
		google.maps.event.addListener(this.nMap, 'dragend', () => {
		   this.centerMarker.setMap(null);
		   this.getMapRadius();
		   this.centerMarker = new google.maps.Marker({
				position: new google.maps.LatLng(this.searchCriteria.lat, this.searchCriteria.long),
				map: this.nMap,
				/* icon: CustomCode.googleMapDefaultIcon, */
			});
		   this.updateIsActiveFacility();

		 });
		this.nMap.setMapTypeId(this.mapType);

		
	}

	tempFunction(searchResult) {
	
	}

	sortBy(userVal) {
		if (userVal == 'price') {
			if (this.searchCriteria.type === CustomCode.defaultFacilityType) {
				this.searchResult.facilities.sort(function (a, b) {
					if ((a.price > 0) && (a.price > 0)) {
						return a.price - b.price;
					}
					else if (a.price > 0) {
						return -1;
					}
					else if (b.price > 0) {
						return 1;
					}

					return 0;
				});
			} else {
				this.searchResult.facilities.sort(function (a, b) {
					if ((a.monthly_rate > 0) && (a.monthly_rate > 0)) {
						return a.monthly_rate - b.monthly_rate;
					}
					else if (a.monthly_rate > 0) {
						return -1;
					}
					else if (b.monthly_rate > 0) {
						return 1;
					}
					return 0;
				});
			}
			this.facilityData = this.searchResult;
		}
		if (userVal == 'distance') {
			this.searchResult.facilities.sort(function (a, b) {
				if ((a.distance > 0) && (a.distance > 0)) {
					return a.distance - b.distance;
				}
				else if (a.distance > 0) {
					return -1;
				}
				else if (b.distance > 0) {
					return 1;
				}
				return 0;
			});
		}

	}

	validateFilterButton() {
		let url = window.location.href;
		if (url.indexOf('search') > 0) {
			let partials = url.split("search")[1].split("/")[6];
			$('div.searchFilter div.filterBtn:first').find('label').removeClass('active');
			$('div.searchFilter div.filterBtn:first').find('label#' + partials).addClass('active');
			$('div#dailyFilter').addClass('hidden');
			$('div#monthlyFilter').addClass('hidden');
			$('div#' + partials + 'Filter').removeClass('hidden');
		}
	}

	loadMarkers(searchResult) {
		// this.parkDetail = false;
		// this.parkList = false;
		this.validateFilterButton();

		

		this.dStartTime = FilterHelper.displayableTimeformat($('#startTime').val());
		this.dEndTime = FilterHelper.displayableTimeformat($('#endTime').val());
		this.searchResult = searchResult;
		const newDataLength = this.searchResult.facilities.length;

		if (this.searchCriteria.device == 'app') {
			if (!this.mobileFilterNavDisplay) {
				if (this.isActiveFacility) {
					this.loadActiveGrage(1, this.activeFacilityIndex, this.searchCriteria.type);
				}
			}
		} else {
			if (this.isActiveFacility) {
				this.loadActiveGrage(1, this.activeFacilityIndex, this.searchCriteria.type);
			}
		}

		this.facilityData = this.searchResult;

		for (let i = 0; i < this.markers.length; i++) {
	        this.markers[i].setMap(null);
	    }

	    this.markers = [];
		for (let i = 0; i < newDataLength; i++) {
			if (this.activeFacilityIndex != null) {
				if (this.allParkings.facility_id == this.searchResult.facilities[i].facility_id) {
					this.allParkings = this.searchResult.facilities[i];
				}
			}
			const facilityId = this.searchResult.facilities[i].facility_id;
			const latCurrent = this.searchResult.facilities[i].geolocation.latitude;
			const longCurrent = this.searchResult.facilities[i].geolocation.longitude;
			let currPrice = parseInt(this.searchResult.facilities[i].monthly_rate);
			if (this.searchCriteria.type === CustomCode.defaultFacilityType) {
				currPrice = this.searchResult.facilities[i].price;
			}
			const finalPrice = (currPrice > 0) ? '$' + currPrice : 'N/A';
			let defaultSize = 36;
			let labelX = 17;
			if (finalPrice.length > 2) {
				defaultSize = 40;
				labelX = 19.5;
			} if (finalPrice.length > 3) {
				defaultSize = 40;
				labelX = 19.5;
			}

			const marker = new google.maps.Marker({
				position: new google.maps.LatLng(latCurrent, longCurrent),
				map: this.nMap,
				icon: CustomCode.markerIconConfiguration(CustomCode.googleMapDefaultIcon, defaultSize, labelX),
               
				label: {
					text: finalPrice,
					fontWeight: 'normal',
					fontSize: '14px',
					color: 'white'
				}
			});

			marker.setValues({ id: facilityId, indexId: i, type: this.searchCriteria.type });
			((marker, l) => {
				google.maps.event.addListener(marker, 'click', () => {

					for (let mi = 0; mi < this.markers.length; mi++) {
						const lObj = this.markers[mi].label;
						lObj.color = 'white';
						this.markers[mi].setIcon(CustomCode.markerIconConfiguration(CustomCode.googleMapDefaultIcon, defaultSize, labelX));
					}
					if (this.searchCriteria.device == 'app') {
						this.mobileSearchFilter.updateStartTimeManually(this.startDuration);
						this.mobileSearchFilter.updateEndTimeManually(this.endDuration);
					}
					this.mobileFilterNavDisplay = false;

					marker.setIcon(CustomCode.markerIconConfiguration(CustomCode.googleMapDefaultIcon, defaultSize, labelX));
                    const labelObj = marker.label;

					labelObj.color = 'black';
					marker.setIcon(
					  CustomCode.markerIconConfiguration(CustomCode.googleMapActiveIcon, defaultSize, labelX)
					);
   					this.sidebarOn = true;
   					this.EarlyBrid = true;
	  				this.earlybirIcon = "fa fa-times";
					this.allParkings = {};
					this.allParkings = this.facilityData.facilities[marker.get('indexId')];
					this.loadActiveGrage(marker.get('id'), marker.get('indexId'), marker.get('type'));

					
				});
			})(marker, i);

			this.markers.push(marker);
		}

		// let tempFacilityId = 102;

		// for (var i = this.markers.length - 1; i >= 0; i--) {
		// 	if (this.markers[i].id == tempFacilityId) {
		// 		google.maps.event.trigger(this.markers[i], 'click');
		// 	}
		// }
	}

	updateFacilitySchedule(id) {
		this.searchPageObj.getFacilitySchedule(id).then(
			(res) => {
			  this.garageWorkingHours = res;
			  this.garageWorkingHours = this.garageWorkingHours.data;
			},
			(error) => { 

			}
		);
	}

	fetchFacilityDetails(id) {
		this.searchPageObj.getFacilityDetails(id).then(
	        (res) => {
	          this.respdata = res;
	          this.respdata = this.respdata.data;
			  if(this.respdata.facility_type_id==1){
				  $('.flyimg').html("<img [src]='"+this.icon_park_gray_icon+"'>");
			  } else if (this.respdata.facility_type_id == 2)
				  $('.flyimg').html("<img [src]='"+this.quikparkIcon+"'>");
	        },
	        (error) => { 
	        }
      	);
	}

		loadActiveGrage(id, index, type) {
			if(this.isDraggable){
				this.activeFacilityIndex = null;
				this.updateIsActiveFacility();
				this.isDraggable = false;
				
				return true;
			}
			$('body').addClass('noscroll');
			this.isActiveFacility = true;
			this.activeFacilityIndex = index;
			
			this.parkDetail = true;
			this.parkList = false;
			let facilityRate;
			let dailymonthlytext;
			let monthdailybuttontext;
			let couponDisplay;
			let updateTime;
			// this.mobileFilterType = 'inline';
			this.animationImg = false;

		if (type === CustomCode.monthlyConstant) {
			couponDisplay = 'none';
			this.isMonthlyAction = true;
			updateTime = 'none';
			facilityRate = this.searchResult.facilities[index].monthly_rate;
			dailymonthlytext = '';
			monthdailybuttontext = 'buy monthly';
			this.isPerMonth=true;
		} else {
			couponDisplay = 'block';
			this.isMonthlyAction = false;
			updateTime = 'inline-block';
			facilityRate = this.searchResult.facilities[index].price;
			dailymonthlytext = $('#stime').val() + ' - ' + $('#etime').val();
			monthdailybuttontext = 'Get Reservation';
			this.isPerMonth=false;
			
		}

		let mDB = 'none';
		if (facilityRate > 0) {
			mDB = 'block';
		}
		this.bookingRate = (facilityRate > 0) ? facilityRate : 'N/A';
		if(this.allParkings.photo_url) {
	    	$('.parkImg').html("<img [src]='"+this.allParkings.photo_url+"' class='full-width' height='320'>");
		} else {
			$('.parkImg').html("<img [src]='"+this.park_garageIcon+"' class='full-width' height='320'>");
		}

      	// if(this.allParkings.rate_type_id == 2 || this.allParkings.rate_type_id == 4) {
	    	$('.spsutoava').html('* Space Subject to Availability');
		// } else {
			// $('.spsutoava').html("");
		// }

		this.updateFacilitySchedule(this.facilityData.facilities[index].facility_id);
		this.fetchFacilityDetails(this.facilityData.facilities[index].facility_id);

		$('#update-daily-time').css('display', updateTime);
		$('#dailymonthlybutton').attr('facId', this.searchResult.facilities[index].facility_id);
		$('#dailymonthlybutton').attr('rate', facilityRate);
		$('#dailymonthlybutton').attr('buyType', type);
		$('#dailymonthlybutton').text(monthdailybuttontext);
		$('#dailymonthlybutton').css('display', mDB);
		$('#checkcoupon').css('display', mDB);

		const facRate = (facilityRate > 0) ? '$' + facilityRate : 'N/A';

		// $('#monthdaily').text(dailymonthlytext);
		$('#checkcoupon').css('display', couponDisplay);
		$('#couponcontent').css('display', couponDisplay);

		$('#mcheckcoupon').css('display', couponDisplay);
		$('#mcouponcontent').css('display', couponDisplay);

		$('#checkcoupon').attr('data-facid', this.searchResult.facilities[index].facility_id);
		$('#dailymonthlybutton').attr('data-facid', this.searchResult.facilities[index].facility_id);
		$('#base_rate').text(facRate);

		$('#parkfull_name').text(this.allParkings.entrance_location);
		$('#geolocationAddress').text(this.allParkings.geolocation.address_1);
		$('#entranceData').text('Entrance : ' + this.allParkings.entrance_location);
		// let miles = this.allParkings.distance;
		// miles = Math.round(miles*Math.pow(10,2))/Math.pow(10,2);
		// $('#locDistance').html('&nbsp;' + miles + ' Miles');
		$('#garage_phone').text(this.allParkings.phone_number);
		$('#callData').text('Call ' + this.allParkings.phone_number);

		const uLat = this.searchResult.facilities[index].geolocation.latitude;
		const uLong = this.searchResult.facilities[index].geolocation.longitude;
		
		if (this.searchCriteria.device == 'app') {
			this.initiliazePanormaMobileView(uLat, uLong);
		}

		$('[name="plist"] > input#parking').click();
		$('[name="pview"] > input#parking').click();
	}

	initiliazePanormaMobileView(uLat, uLong) {
		const panorama = new google.maps.StreetViewPanorama(
		document.getElementById('pano'),
		{
			position: {lat: parseFloat(uLat), lng: parseFloat(uLong)},
			pov: {heading: 165, pitch: 0},
			zoom: 1
		});
	}

	generateMobilePanorama(uLat, uLong) {
		const panorama = new google.maps.StreetViewPanorama(
		document.getElementById('pano'),
		{
		position: {lat: uLat, lng: uLong},
		pov: {heading: 165, pitch: 0},
		zoom: 1
		});
	}

	openDetails(facData, searchType) {
		this.allParkings = facData;
		//this.allParkings = allData.facilities[i];
		// const toFixedNum = (Math.round(facData.distance * 100) / 100).toFixed(2);
		// $('#locDistance').html(toFixedNum + ' miles away');
		let facilityRate;
		let dailymonthlytext;
		let monthdailybuttontext;
		let couponDisplay;
		if (searchType === CustomCode.monthlyConstant) {
			couponDisplay = 'none';
			facilityRate = facData.monthly_rate;
			dailymonthlytext = '';
			monthdailybuttontext = 'buy monthly';
			this.isPerMonth=true;
			this.parkDetail = true;
			this.parkList = false;
			


		} else {
			couponDisplay = 'block';
			facilityRate = facData.price;
			dailymonthlytext = this.stime + ' - ' + this.etime;
			monthdailybuttontext = 'Get Reservation';
			this.isPerMonth=false;
			this.parkDetail = true;
			this.parkList = false;
			

		}
		let mDB = 'none';
		if (facilityRate > 0) {
			mDB = 'block';
		}
		if(this.allParkings.photo_url){
			
		    $('.parkImg').html("<img [src]='"+this.allParkings.photo_url+"' class='full-width' height='320'>");
		}else{
			
			$('.parkImg').html("<img [src]='"+this.park_garageIcon+"' class='full-width' height='320'>");
		}

		// if(this.allParkings.rate_type_id==2 || this.allParkings.rate_type_id==4){
				
		    $('.spsutoava').html('* Space Subject to Availability');
			// }else{
				
				// $('.spsutoava').html("");
			// }
		$('#update-daily-time').css('display', couponDisplay);
		$('#dailymonthlybutton').attr('facId', facData.facility_id);
		$('#dailymonthlybutton').attr('rate', facilityRate);
		$('#dailymonthlybutton').attr('buyType', searchType);
		$('#dailymonthlybutton').text(monthdailybuttontext);
		const facRate = (facilityRate > 0) ? '$' + facilityRate : 'N/A';
		// $('#monthdaily').text(dailymonthlytext);
		$('#checkcoupon').css('display', couponDisplay);
		$('#checkcoupon').attr('data-facid', facData.facility_id);
		$('#dailymonthlybutton').attr('data-facid', facData.facility_id);
		
		$('#base_rate').text(facRate);
		$('.pmn').toggleClass('hidden');

		
	}

	tabList(activeTab, inactiveTab) {
		this.sidebarOn = true;
		this.parkDetail = false;
		this.parkList = true;
		$('[name=' + activeTab + ']').addClass('active');
		$('[name=' + inactiveTab + ']').addClass('active');
		if ($('[name="plist"]').hasClass('active')) {
			$('[name="pview"] img').attr('src', 'assets/img/singleView.png');
		} else {
			$('[name="pview"] img').attr('src', 'assets/img/listView.png');
		}
	}

	mapView(userVal) {
		let mapT = CustomCode.mapSatelliteView;
		let img = 'mapView.png';
		if(userVal === CustomCode.mapSatelliteView) {
			mapT = CustomCode.mapDefaultView;
			img = 'satView.png';
		}
		$('#mapViewLink img').attr('src', 'assets/img/' + img);
		$('.mapBtnToggle img').attr('src', 'assets/img/' + img);
		

		this.mapType = mapT;
		this.nMap.setMapTypeId(this.mapType);
	}

	parkView() {
		// const currentFacId = document.getElementById('dailymonthlybutton').getAttribute('data-facid');
		const currentFacId = this.allParkings.facility_id;
		if (parseInt(currentFacId) > 0) {
			this.parkDetail = true;
			this.parkList = false;			
		} else {
			this.sidebarOn = false;
			this.parkDetail = true;
			this.parkList = false;
		}
	}

	// LATEST MODIFIES CODE STARTS HERE

	manageCoupons() {
		$('#couponsErrorMessage').hide();
		$('#couponmail').hide();
		this.isLoaded = false;
		setTimeout(()=>{ 
			$('#triggercoupon').trigger('click');
		},500);
		//ga('send', 'event', { eventCategory: 'coupon view new', eventAction: 'click'});
		this.loggedUserName = CustomCode.getloggedInUserDetails();
		CustomCode.displayLoginUser();
		
		if (!this.loggedUserName) {
			$('#mailcoupon').css('display', 'none');
		} else {
			$('#mailcoupon').css('display', 'block');
		}
		const currentFacId = this.allParkings.facility_id;
		const couponId = this.allParkings.rate_id;
		if (couponId) {
			const date = new Date();
			const todayName = date.toLocaleDateString('en', { weekday: 'long' }).toLowerCase();
			// for (let cd = 0; cd < cData.data.length; cd++) {
				// if (cData.data[cd].rate_type === 'Coupons' && cData.data[cd].rates.length > 0) {
					// this.couponsData = cData.data[cd].rates;
					this.printEmailCoupon(couponId);
					this.printCoupon();
				// }

				// if (cData.data[cd].rate_type === 'Coupons' && cData.data[cd].rates.length == 0) {
					// this.isLoaded = true;
					// $('#couponsErrorMessage').removeClass('hidden').show();
				// }
			// }
		} else {
			this.isLoaded = true;
			$('#couponsErrorMessage').removeClass('hidden');
			$('#couponsErrorMessage').show();
		}
		
		// $('#coupon-modal .modal-dialog').removeClass('modal-sm');
		// $('#coupon-modal .modal-dialog').addClass('modal-lg');
		$('#couponheading').css('display', 'block');
		$('#couponprintdata').css('display', 'block');
		// $('#couponheading').text('Coupons Available for this Facility');
		$('#couponslisting').css('display', 'block');
		$('.coupons-other-areas').css('display', 'none');
	}

	printEmailCoupon(couponId) {
		$('#couponprintdata').css('display', 'block');
		this.getVisitorCouponTracking({"couponId":couponId, "type":this.VIEW_COUPON });
		$('#couponslisting').css('display', 'none');
		$('#coupon-mail-print').attr('data-id', couponId);
		// $('#coupon-modal .modal-dialog').removeClass('modal-lg');
		// $('#coupon-modal .modal-dialog').addClass('modal-sm');
		// $('#coupon-mail-print').css('display', 'block');
		// $('#couponheading').text('How would you like to receive your coupon?');
	}

	mailPopup() {
		
		var cId = $('#coupon-mail-print').attr('data-id');
		

		// $('#coupon-mail-print').css('display', 'none');
		$('#couponslisting').css('display', 'none');
		$('#couponmail').show();
		$('#couponprintdata').hide();
		// $('#couponheading').text('How would you like to receive your coupon?');
		// $('#coupon-modal .modal-dialog').removeClass('modal-lg');
		// $('#coupon-modal .modal-dialog').addClass('modal-sm');
	}

	cancelEmail(formValues, formObj: NgForm) {
		$('#couponmail').hide();
		$('#couponprintdata').show();
		formObj.resetForm();
	}

	saveCouponApi() {
	//	ga('send', 'event', { eventCategory: 'save coupon', eventAction: 'click'});
		const cId = $('#coupon-mail-print').attr('data-id');
		this.myAccountMonthlyParkingObj.fetchUser()
		.then(
			data => {
			this.activeUser = data;
			this.activeUser = this.activeUser.data.id;
			this.searchPageObj.saveCoupon(this.activeUser, cId)
			.subscribe(
			data => {
				this.respdata = data;
				if (this.respdata.status === 201) {

					// kochava.activity('save_coupon', {
					// 	coupon_id : cId,
					//   });

					// $('#coupon-mail-print').css('display', 'none');
					$('#couponmail').css('display', 'block');
					// $('#coupon-modal .modal-dialog').addClass('modal-lg');
					// $('#coupon-modal .modal-dialog').removeClass('modal-sm');
					this.router.navigate(['/thanks-coupon-saved']);
				}
			},
			error => {
				/* FormValidationErrors.displayServerErrors('signuperrors-container', error.errors); */
				const errorsList = error.errors;
				if (errorsList.message !== 'Validation Errors') {
					this.toasterObj.error(errorsList.message, 'Sorry!');
				} else {
					for (const key in errorsList.detail) {
						if (errorsList.detail.hasOwnProperty(key)) {
							if (typeof errorsList.detail === 'undefined') {
								this.toasterObj.error(errorsList.message, 'Sorry!');
							} else {
								this.toasterObj.error(errorsList.detail[key], 'Sorry!');
							}
						}
					}
				}
			}
			);

			},
			error => {

			}
		);
	}

	reset(formValues, formObj: NgForm) {
	    formObj.resetForm();
	}

	sendCoupon(formData, formObj: NgForm) {
		const cId = $('#coupon-mail-print').attr('data-id');
	//	ga('send', 'event', { eventCategory: 'email coupon', eventAction: 'click'});
		this.getVisitorCouponTracking({"couponId":cId,"type":this.EMAIL_COUPON,"to":formData.ucmail});
		this.searchPageObj.mailCoupon(formData, cId)
			.subscribe(
			data => {
				this.respdata = data;
				if (this.respdata.status === 201) {

					// kochava.activity('email_coupon', {
					// 	coupon_id : cId
					// });

					// $('#coupon-mail-print').css('display', 'none');
					$('#couponmail').css('display', 'block');
					// $('#coupon-modal .modal-dialog').addClass('modal-lg');
					// $('#coupon-modal .modal-dialog').removeClass('modal-sm');
					formObj.resetForm();
					this.router.navigate(['/thanks-email']);
				}
			},
			error => {
				const errorsList = error.errors;
				if (errorsList.message !== 'Validation Errors') {
					this.toasterObj.error(errorsList.message, 'Sorry!');
				} else {
					for (const key in errorsList.detail) {
						if (errorsList.detail.hasOwnProperty(key)) {
							if (typeof errorsList.detail === 'undefined') {
								this.toasterObj.error(errorsList.message, 'Sorry!');
							} else {
								this.toasterObj.error(errorsList.detail[key], 'Sorry!');
							}
						}
					}
				}
			}
			);

	}

	cancelCoupon() {
		// $('#couponheading').text('How would you like to receive your coupon?');
		// $('#coupon-mail-print').css('display', 'block');
		$('#couponmail').css('display', 'none');
	}

	couponOptions() {
		// // alert('testing');
	}

	printCoupon() {
	//	ga('send', 'event', { eventCategory: 'coupon print', eventAction: 'click'});
		const facId = $('#coupon-mail-print').attr('data-id');
		// $('#coupon-modal .modal-dialog').removeClass('modal-sm');
		// $('#couponheading').text('');
		this.isLoaded = false;
		this.searchPageObj.printCoupon(facId).then(
			(couponPrint) => {
				const urlCreator = window.URL;
				const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(couponPrint['_body']));
				this.printData = blobData['changingThisBreaksApplicationSecurity'];
				this.isLoaded = true;
				$('#invoicePrint').css('display', 'block');
				$('#couponvalid').css('display', 'block');
				// setTimeout(() => {
					$('div#couponprintdata').css('display', 'block');
				// }, 200);
			},
			(error) => {
				$('#couponsErrorMessage').removeClass('hidden').css('display', 'block');
				this.isLoaded = true;
			}
		);
		this.getVisitorCouponTracking({"couponId":facId,"type":this.PRINT_COUPON});


		$('#coupon-mail-print').css('display', 'none');
		$('#couponprintdata').css('display', 'block');
		$('#invoicePrint').css('display', 'none');
		$('#couponvalid').css('display', 'none');
	}

	closePopup() {
		$('button#closeCouponModal').click();
		// $('#coupon-modal .modal-dialog').addClass('modal-sm');
		// $('#couponheading').text('How would you like to receive your coupon?');
		// $('#coupon-mail-print').css('display', 'block');
		// $('#couponprintdata').css('display', 'none');
	}

	printMedia() {
		// $('#coupon-modal .modal-dialog').removeClass('modal-lg');
		// $('#coupon-modal .modal-dialog').removeClass('modal-sm');
		const facId = $('#coupon-mail-print').attr('data-id');
	//	ga('send', 'event', { eventCategory: 'coupon print', eventAction: 'click'});
		this.getVisitorCouponTracking({"couponId":facId,"type":this.PRINT_COUPON});
		$('div#printable').html($('div#printableContent').html()).show();
		$('div#content-Wrapper').hide();
		window.print();


		$('div#printable').html('').hide();
		$('div#content-Wrapper').show();
		this.router.navigate(['/thanks-print']);

	}

	// LATEST MODIFIED CODE ENDS HERE

	public openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template);
	}

	loadGarageDetails() {
		this.sidebarOn = true;
		this.parkDetail = true;
		
	}

	checkAvailability() {
		if(this.searchCriteria.length_of_stay >= 2) {
			$('#enddate').val($('#penddate').val());
			$('#startdate').val($('#pstartdate').val());
			$('#starttime').val($('#pstarttime').val());
			$('#endtime').val($('#pendtime').val());
			this.updateFilters.emit(this.searchCriteria);
			$('button.close').trigger('click');
			$('#stime').val($('#pstime').val());
			$('#etime').val($('#petime').val());
			const dailymonthlytext = $('#stime').val() + ' - ' + $('#etime').val();
			// $('#monthdaily').text(dailymonthlytext);
		} else {
			this.toasterObj.error('You can not make booking in the past', 'error');
		}
	}

	moveToReservation(facId, dailyRate, monthlyRate) {
		let routeNavigate = '/buy-monthly';
		if (this.searchCriteria.type === CustomCode.defaultFacilityType) {
			const sDate = $('#startdate').val().toString().trim();
			const eDate = $('#enddate').val().toString().trim();
			const sTime = $('#startTime').val().toString().trim();
			const eTime = $('#endTime').val().toString().trim();
			let redirect = sDate + '&' + sTime + '&&' + eDate + '&' + eTime;
			redirect = redirect.split('/').join('-');
			const navURL = "/payment/" + facId + "/" + redirect + "/" + 40;
			this.router.navigate([navURL]);
		} else {
			const mD = $('#monthlydate').val().toString().split('/');
			const fDate = mD[2] + '-' + mD[0] + '-' + mD[1];
			this.router.navigate([routeNavigate, facId, fDate]);

		}
	}

	updateIsActiveFacility() {
		this.sidebarOn = false;
		this.isActiveFacility = false;
		this.isLoaded = false;
		setTimeout(()=>{  
		    this.parkDetail = false;
		 },300);

		
	}

	mobileReservation(facId, facPrice) {

	}


	getMapRadius() {
		var bounds = this.nMap.getBounds();
		var center = bounds.getCenter();
		var ne = bounds.getNorthEast();
		var r = 3963.0;  
		var lat1 = center.lat() / 57.2958; 
		var lon1 = center.lng() / 57.2958;
		var lat2 = ne.lat() / 57.2958;
		var lon2 = ne.lng() / 57.2958;
		var radius = r * Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1));
		radius = radius >= 0.5 ? radius : 0.5
		this.searchCriteria.radius = radius;
		this.searchCriteria.lat = (center.lat()).toFixed(8);
		this.searchCriteria.long = (center.lng()).toFixed(8);
		this.isDraggable = true;
        this.updateFilters.emit(this.searchCriteria);
    }
	
	centerLocation(){
		let userLat = (<HTMLInputElement>document.getElementById('userlat')).value;
	    let userLong = (<HTMLInputElement>document.getElementById('userlong')).value;
	    let locationUser = (<HTMLInputElement>document.getElementById('locationname')).value;
	    /* locationUser = locationUser.toString().split(',')[0]; */
	    if (!userLat || !userLong || !locationUser) {
	      userLat = CustomCode.timeSquareCoords.lat;
	      userLong = CustomCode.timeSquareCoords.long;
	      locationUser = CustomCode.timeSquareCoords.defaultLocation;
	    }
	    let searchType = $('[name="monthlytype"].active').attr("id");
	    if(typeof searchType === 'undefined') {
	      searchType = 'daily';
	    }
	    this.router.navigate(['/search', userLat, userLong, this.currDate, this.facilityRadius, locationUser, searchType, CustomCode.searchMode()]);
		//this.routerObj.navigate(['/search', userLat, userLong, this.currDate, this.facilityRadius, locationUser, searchType, CustomCode.searchMode()]);userlat
	}

    getLengthOfStayStartDate(event) {
		let startTime:any = new Date(this.returnFormattedDate(event) + ' ' + this.returnFormattedTime(this.startTime));
		let endTime:any = new Date(this.returnFormattedDate(this.dailyEndDate) + ' ' + this.returnFormattedTime(this.endTime));
		const bookingHours:number = ((endTime - startTime) / 60000) / 60;
		return bookingHours;
	}

	getLengthOfStayStartTime(event) {
		let startTime:any = new Date(this.returnFormattedDate(this.dSD) + ' ' + this.returnFormattedTime(event));
		let endTime:any = new Date(this.returnFormattedDate(this.dailyEndDate) + ' ' + this.returnFormattedTime(this.endTime));
		const bookingHours:number = ((endTime - startTime) / 60000) / 60;
		return bookingHours;
	}

	getLengthOfStayEndTime(event) {
		let startTime:any = new Date(this.returnFormattedDate(this.dSD) + ' ' + this.returnFormattedTime(this.startTime));
		let endTime:any = new Date(this.returnFormattedDate(this.dailyEndDate) + ' ' + this.returnFormattedTime(event));
		const bookingHours:number = ((endTime - startTime) / 60000) / 60;
		return bookingHours;
	}

	getLengthOfStayEndDate(event) {
		let startTime:any = new Date(this.returnFormattedDate(this.dSD) + ' ' + this.returnFormattedTime(this.startTime));
		let endTime:any = new Date(this.returnFormattedDate(event) + ' ' + this.returnFormattedTime(this.endTime));
		const bookingHours:number = ((endTime - startTime) / 60000) / 60;
		return bookingHours;
	}

	returnFormattedDate(date) {
		let dateNew = date.toLocaleString('en-US', { hour12:false } ).split(" ");
	    let mdy = dateNew[0];
	    let mdy2 = mdy.split('/');
	    let month = mdy2[0];
	    let day = mdy2[1];
	    let year = mdy2[2];
	    let yearFix = year.split(',');
	    let finalDate = month + '/' + day + '/' + yearFix[0];
	    return finalDate;
	}

	returnFormattedTime(date) {
		let dateNew = date.toLocaleString('en-US', { hour12:false } ).split(" ");
		return dateNew[1];
	}

	getVisitorCouponTracking(data) {
		this.getReferrerForVisitor();
		this.userServiceObj.getVisitorCouponTracked(data)
	      .subscribe(
	      data => {

		},
	      error => {
	        //let errorMessage = error.errors.message;
	      }
    	);


	}

	getReferrerForVisitor() {
		this.userServiceObj.getReferrerForVisitor()
	      .subscribe(
	      data => {
	       },
	      error => {

		}
    	);
	}



}
