import { Pipe, PipeTransform } from '@angular/core';
import { CustomCode } from '../shared/CustomCode';
@Pipe({
  name: 'paymentdate'
})
export class PaymentdatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const selectedD = new Date(value);
    // return CustomCode.allMonths[selectedD.getMonth()] + ', ' + CustomCode.allDays[selectedD.getDay()] + ' ' + selectedD.getDate();
    return CustomCode.allDays[selectedD.getDay()] + ', ' + CustomCode.allMonths[selectedD.getMonth()]  + ' ' + selectedD.getDate() + ', ' + selectedD.getFullYear() ;

  }
}

