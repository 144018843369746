// core functions
import oktaconfig from './okta.config';
import { OKTA_CONFIG, OktaAuthGuard,   OktaCallbackComponent, OktaAuthModule} from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AgmCoreModule } from '@agm/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserModule } from '@angular/platform-browser';
import { DateValueAccessorModule } from 'angular-date-value-accessor';
import { NgModule, Component, APP_INITIALIZER, Injector } from '@angular/core';
// import { HttpModule } from '@angular/http';
import { FormsModule,ReactiveFormsModule,FormControl,FormGroup,FormBuilder } from '@angular/forms';
import { Router, RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { HttpModule, RequestOptions, XHRBackend } from '@angular/http';
//import { BrowserXhr } from '@angular/http';
import { HttpService } from './services/http.service';
import { RouterExtService } from './services/router-ext.service';
//import { NgProgressModule, NgProgressBrowserXhr } from 'ngx-progressbar';

import { ToastrModule } from 'ngx-toastr';
import { TextMaskModule } from 'angular2-text-mask';
import { MomentModule } from 'angular2-moment';
import { SearchpageserviceService } from './services/searchpageservice.service';
//import { AccordionModule, ModalModule,ProgressbarModule, BsDropdownModule, BsDatepickerModule, TimepickerModule, ButtonsModule, CarouselModule, PopoverModule, CollapseModule ,TooltipModule} from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { AccordionModule} from 'ngx-bootstrap/accordion';
import { ModalModule} from 'ngx-bootstrap/modal';
import { ProgressbarModule} from 'ngx-bootstrap/progressbar';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule} from 'ngx-bootstrap/timepicker';
import { ButtonsModule} from 'ngx-bootstrap/buttons';
import { CarouselModule} from 'ngx-bootstrap/carousel'; 
import { PopoverModule} from 'ngx-bootstrap/popover';
import { CollapseModule} from 'ngx-bootstrap/collapse';
import { TooltipModule} from 'ngx-bootstrap/tooltip';
import { FacebookModule } from 'ngx-facebook';
import { CommonModule }  from '@angular/common'
// Custome functions

// Added Plugins
//import { AngularFontAwesomeModule } from 'angular-font-awesome/angular-font-awesome';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
//import { GooglePlaceModule } from 'ng2-google-place-autocomplete';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { HeaderComponent,SafeHtmlHeaderPipe } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { MobileHeaderComponent } from './common/mobileheader/mobileheader.component';
import { SidenavComponent } from './common/sidenav/sidenav.component';
import { MbscModule } from '../assets/lib/mobiscroll/js/mobiscroll.custom-3.2.6.min.js';
// login componentjs

import { LoginComponent } from './login/login.component';
import { ResponsiveComponent } from './responsive/responsive.component';
import { GoogleSigninComponent } from './login/google.component';
// register component
import { CreateaccountComponent } from './createaccount/createaccount.component';

// site pages
import { HomeComponent } from './home/home.component';
import { LogoutComponent } from './home/logout.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
//import { CmsPageComponent } from './pages/cmspage/cmspage.component';
import { IcongreenComponent } from './pages/icongreen/icongreen.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { ContactusThanksComponent } from './pages/contactus/contactus-thanks/contactus-thanks.component';
import { FaqComponent,SafeHtmlPipes } from './pages/faq/faq.component';
import { FaqMobileComponent,SafeHtmlMobilePipes } from './pages/faq-mobile/faq-mobile.component';
import { EvchargingComponent } from './pages/evcharging/evcharging.component';
import { OversizedComponent } from './pages/oversized/oversized.component';
import { ApppromoComponent } from './pages/apppromo/apppromo.component';
import { ParkingtipsComponent } from './pages/parkingtips/parkingtips.component';
import { YankeestadiumComponent } from './yankeestadium/yankeestadium.component';
import { CareersComponent } from './pages/careers/careers.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
import { TermsofserviceComponent } from './termsofservice/termsofservice.component';
import { BigcarsComponent } from './pages/bigcars/bigcars.component';


// monthly parking section

import { MonthlyparkingComponent } from './monthly-parking/monthlyparking.component';
import { MonthlyParkingInformationComponent } from './monthly-parking/monthly-parking-information/monthly-parking-information.component';
import { MonthlyParkingThanksComponent } from './monthly-parking/monthly-parking-thanks/monthly-parking-thanks.component';
import { MonthlyParkingConfirmationComponent } from './monthly-parking/monthly-parking-confirmation/monthly-parking-confirmation.component';
import { MonthlyParkingConfirmationWebComponent,SafeHtmlConfirmationWebPipe } from './monthly-parking/monthly-parking-confirmation-web/monthly-parking-confirmation-web.component';
import { MonthlyParkingConfirmationAppComponent,SafeHtmlConfirmationAppPipe } from './monthly-parking/monthly-parking-confirmation-app/monthly-parking-confirmation-app.component';
import { BuymonthlyComponent,BuymonthlySorting,safeParkSafe } from './monthly-parking/buymonthly/buymonthly.component';
import { BuymonthlyThanksComponent } from './monthly-parking/buymonthly/buymonthly-thanks/buymonthly-thanks.component';
import { MonthlyPreferredComponent } from './monthly-parking/monthly-preferred/monthly-preferred.component';

import { EqualValidatorDirective } from './directives/equal-validator.directive';
import { EmploymentapplicationComponent } from './employmentapplication/employmentapplication.component';
import { AccountConfirmationComponent } from './account-confirmation/account-confirmation.component';
import { PaymentComponent } from './payment/payment.component';
import { AuthGuardGuard } from './guards/auth-guard.guard';



// user account pages
import { MonthlyparkingaccountComponent } from './monthlyparkingaccount/monthlyparkingaccount.component';
import { CurrentBalanceComponent } from './monthlyparkingaccount/current-balance/current-balance.component';
import { AutoPayComponent } from './monthlyparkingaccount/auto-pay/auto-pay.component';
import { CurrentInvoiceComponent } from './monthlyparkingaccount/current-invoice/current-invoice.component';
import { PreviousInvoicesComponent } from './monthlyparkingaccount/previous-invoices/previous-invoices.component';
import { MakePaymentComponent } from './monthlyparkingaccount/make-payment/make-payment.component';
import { PaymentMethodsComponent } from './monthlyparkingaccount/payment-methods/payment-methods.component';
import { PaymentHistoryComponent } from './monthlyparkingaccount/payment-history/payment-history.component';
import { BillingInformationComponent } from './monthlyparkingaccount/billing-information/billing-information.component';
import { FrequentlyAskedQuestionsComponent } from './monthlyparkingaccount/frequently-asked-questions/frequently-asked-questions.component';
import { LoyaltyClubComponent } from './monthlyparkingaccount/loyalty-club/loyalty-club.component';
import { RemoveAccountComponent } from './monthlyparkingaccount/remove-account/remove-account.component';
import { CancellationNoticeComponent } from './monthlyparkingaccount/cancellation-notice/cancellation-notice.component';
import { AddCreditCardComponent } from './monthlyparkingaccount/add-credit-card/add-credit-card.component';
import { AddBankAccountComponent } from './monthlyparkingaccount/add-bank-account/add-bank-account.component';
import { PayUsingCreditCardComponent } from './monthlyparkingaccount/pay-using-credit-card/pay-using-credit-card.component';
import { PayBankCreditCardComponent } from './monthlyparkingaccount/pay-bank-credit-card/pay-bank-credit-card.component';
import { PayUsingBankAccountComponent } from './monthlyparkingaccount/pay-using-bank-account/pay-using-bank-account.component';
import { LazyLoadComponent } from './Components/Global/lazy-load/lazy-load.component';
import { SaverLoadComponent } from './Components/Global/saver-load/saver-load.component';
import { WindowRefService } from './services/window-ref.service';


import { AccountComponent } from './my-account/account/account.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { MonthlyComponent } from './my-account/monthly/monthly.component';
import { CouponsComponent } from './my-account/coupons/coupons.component';
import { ReservationsComponent } from './my-account/reservations/reservations.component';
import { ReservationCancelComponent } from './my-account/reservations/reservation-cancel/reservation-cancel.component';
import { VehicleRequestComponent } from './my-account/vehicle-request/vehicle-request.component';
import { BrandPartnershipComponent } from './my-account/brand-partnership/brand-partnership.component';
import { PromoCodesComponent } from './my-account/promo-codes/promo-codes.component';
import { DailyconfirmationComponent } from './payment/dailyconfirmation/dailyconfirmation.component';
import { CreateOnlineAccountComponent } from './monthlyparkingaccount/create-online-account/create-online-account.component';

import { NypComponent } from './nyp/nyp.component';
import { CouponThanksComponent } from './searchlocation/coupon-thanks/coupon-thanks.component';
import { CampaignComponent } from './pages/campaign/campaign.component';


import { GoogleMapComponent,ArraySortPipe, SearchPageSortPipe,safeHtmlNeighborhood } from './Components/Global/google-map/google-map.component';
import { WebGarageDetailsComponent } from './Components/Global/web-garage-details/web-garage-details.component';
import { AppGarageDetailsComponent } from './Components/Global/app-garage-details/app-garage-details.component';
import { SearchFacilityComponent } from './Components/search/search-facility/search-facility.component';
import { SearchTrackComponent } from './Components/search/search-track/search-track.component';
// import { SearchNeighbourhoodComponent } from './Components/search/search-neighbourhood/search-neighbourhood.component';
import { SearchNeighbourhoodComponent, SearchSortPipe } from './Components/search/search-neighbourhood/search-neighbourhood.component';
import { SearchNeighbourhoodMonthlyComponent, SearchSortMonthlyPipe } from './Components/search/search-neighbourhood-monthly/search-neighbourhood-monthly.component';
import { LandingPageComponent } from './Components/campaign/landing-page/landing-page.component';
import { DistanceconversionPipe } from './pipes/distanceconversion.pipe';
import { PaymenttimePipe } from './pipes/paymenttime.pipe';
import { PaymentdatePipe } from './pipes/paymentdate.pipe';
import { ChangePasswordComponent } from './my-account/change-password/change-password.component';
import { RestEmailComponent } from './my-account/reset-email/reset-email.component';
import { ResetPasswordComponent } from './my-account/reset-password/reset-password.component';
import { RoundpricePipe } from './pipes/roundprice.pipe';
import { LoadViewDirective } from './directives/load-view.directive';
import { CouponSliderDirective } from './directives/couponslider.directive';
import { ColorPickerDirective } from './directives/color.directive';
import { rangeSliderDirective } from './directives/slider.directive';
import { owlSliderDirective } from './directives/owlslider.directive';

import { ScrollToModule } from 'ng2-scroll-to-el';
import { ParallaxScrollModule } from 'ng2-parallaxscroll';
import { SearchFiltersComponent } from './Components/Global/search-filters/search-filters.component';
import { FacilityComponent } from './Components/static/facility/facility.component';
import { AttractionComponent } from './Components/static/attraction/attraction.component';
import { GlobalcouponComponent } from './pages/globalcoupon/globalcoupon.component';
import { MobileSearchFilterComponent } from './Components/Global/mobile-search-filter/mobile-search-filter.component';
import { ResponsiveModule, ResponsiveConfig} from 'ng2-responsive';
import { MobileSearchFilterNavComponent } from './Components/Global/mobile-search-filter-nav/mobile-search-filter-nav.component';
import { RewardsComponent } from './home/rewards/popup/rewards.component';
import { preferredCustomerProgramComponent } from './pages/preferred-customer-program/preferred-customer-program.component';
import { BuyMonthlyTermsConditionsComponent } from './monthly-parking/buymonthly/buy-monthly-terms-conditions/buy-monthly-terms-conditions.component';
import { HotelsComponent } from './landing/hotels/hotels.component';


// address for coupons


// import { AstorParkingComponent } from './pages/globalcoupon/astor-parking/astor-parking.component'
// import { AaaComponent } from './pages/globalcoupon/aaa/aaa.component'

// import { AdvanceParkingComponent } from './pages/globalcoupon/advance-parking/advance-parking.component'
// import { QuikParkTheatureLotComponent } from './pages/globalcoupon/quik-park-theature/quik-park-theature.component'



import { GcouponSixthAvenueComponent } from './pages/gcoupon-sixth-avenue/gcoupon-sixth-avenue.component'

import { PromocodeLandingComponent } from './Components/campaign/promocode-landing/promocode-landing.component';
import { HolidayLandingComponent } from './Components/campaign/holiday-landing/holiday-landing.component';
import { Park10LandingComponent } from './Components/campaign/park10-landing/park10-landing.component';
import { GoogleAutosuggestComponent,SafeDatePipes } from './Components/Global/google/google-autosuggest/google-autosuggest.component';
import { WidgetGoogleAutosuggestComponent } from './Components/Global/google/widget-google-autosuggest/widget-google-autosuggest.component'
import { GoogleAnalyticsEventsService } from "./shared/google-analytics-events.service";
import { EmailLandingComponent } from './Components/campaign/email-landing/email-landing.component';
import { MonthlyLandingComponent } from './Components/campaign/monthly-landing/monthly-landing.component';
import { WinterMonthlyLandingComponent } from './Components/campaign/winter-monthly-landing/winter-monthly-landing.component';
// import { ParkingDetailsComponent } from './Components/Global/parking-details/parking-details.component';
import { IconCouponHolidayComponent } from './Components/campaign/icon-coupon-holiday/icon-coupon-holiday.component';
import { CouponNyeComponent } from './Components/campaign/coupon-nye/coupon-nye.component';
// import { NatgeoComponent } from './pages/globalcoupon/natgeo/natgeo.component';
import { WinterLandingComponent } from './Components/campaign/winter-landing/winter-landing.component';
import { ErrorlandingComponent } from './Components/campaign/errorlanding/errorlanding.component';
import { PartnersComponent } from './Components/static/partners/partners.component';
import { PartnersMapComponent } from './Components/Global/partners-map/partners-map.component';
import { GamedayComponent } from './Components/campaign/gameday/gameday.component';
import { SnowStormComponent } from './Components/campaign/snow-storm/snow-storm.component';
import { ValentineDayComponent } from './Components/campaign/valentine-day/valentine-day.component';
import { PromocodeCmsLandingComponent, SafeHtmlPipe } from './Components/promocode-cms-landing/promocode-cms-landing.component';
import { MarchMadnessComponent } from './Components/campaign/march-madness/march-madness.component';
import { PromocodeCmsService } from './services/promocode-cms.service';
import { FAQContentServic } from './services/faq-content.service';
import { FacebookAuthService } from './services/facebook.service';
import { WidgetFormComponent } from './pages/widget-form/widget-form.component';
import { Ng2DeviceDetectorModule } from 'ng2-device-detector';
import { SpringComponent } from './Components/campaign/spring/spring.component';
import { labourDayComponent } from './Components/campaign/labour-day/labourday.component';
import { labourDayPercentageComponent } from './Components/campaign/labourday/labour_day.component';
import { fallCouponComponent } from './Components/campaign/fall-coupon/fall-coupon.component';
import { blackFridayCouponComponent } from './Components/campaign/black-friday-coupon/black-friday-coupon.component';
import { CyberMondayCouponComponent } from './Components/campaign/cyber-monday-coupon/cyber-monday-coupon.component';
import { HappyNewYearCouponComponent } from './Components/campaign/happy-new-year-coupon/happy-new-year-coupon.component';

//  component for Social login page
import { SocialLoginComponent } from './login/social-login.component';
import { IconRewardsBenefitComponent } from './pages/icon-rewards-benefit/icon-rewards-benefit.component';
import { IconRewardsComponent } from './my-account/icon-rewards/icon-rewards.component';
import { EnrollInIconRewardsComponent } from './my-account/icon-rewards/enroll-in-icon-rewards/enroll-in-icon-rewards.component';
//import { patch } from 'webdriver-js-extender';
import { IconRewardsTabsComponent } from './pages/icon-rewards-tabs/icon-rewards-tabs.component';
import { IconRewardsDetailsComponent } from './my-account/icon-rewards/icon-rewards-details/icon-rewards-details.component';
import { IconRewardsCardComponent } from './my-account/icon-rewards/icon-rewards-card/icon-rewards-card.component';
import { IconRewardsRegisterComponent } from './pages/icon-rewards-register/icon-rewards-register.component';
import { TransactionHistoryComponent } from './my-account/icon-rewards/transaction-history/transaction-history.component';
import { IconRewardTermsComponent } from './pages/icon-reward-terms/icon-reward-terms.component';
import { LoyaltyComponent } from './home/loyalty/loyalty.component';
import { ManageDetailsComponent } from './my-account/manage-details/manage-details.component';
import { UpdateProfileComponent } from './my-account/update-profile/update-profile.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { WinterPopupComponent } from './home/winter-popup/winter-popup.component';
import { B2bComponent } from './home/b2b/b2b.component';
import { B2bFrameComponent } from './home/b2bframe/b2bframe.component';
import { B2bLandingComponent } from './b2b-landing/b2b-landing.component';
import { UtilityService } from '../app/services/utility.service';
import { SortHistoryPipe } from './pipes/sort-history.pipe';
import { MonthlySpecialCampaignComponent } from './monthly-parking/monthly-special-campaign/monthly-special-campaign.component';
import { BuyMonthlyCampaignComponent } from './monthly-parking/buy-monthly-campaign/buy-monthly-campaign.component';
import { BuyMonthlyCampaignNieghborhoodComponent } from './monthly-parking/buy-monthly-campaign-nieghborhood/buy-monthly-campaign-nieghborhood.component';
import { ShareIcongoAppComponent } from './pages/share-icongo-app/share-icongo-app.component';
import { BaseballComponent } from './pages/baseball/baseball.component';
import { ParksafeLandingComponent } from './pages/parksafe-landing/parksafe-landing.component';

import { DigitalCardComponent } from '../app/digital-card/digital-card.component';
import { QRCodeModule } from 'angularx-qrcode';
import { LoyalityBadgeComponent } from "../app/pages/loyality-badge/loyality-badge.component";
import { HowItWorksComponent } from './pages/how-it-works/how-it-works.component';
import { FacilityAutoSearchComponent } from './monthly-parking/monthly-parking-information/facility-auto-search/facility-auto-search.component';
import { NewpageComponent } from './newpage/newpage.component';
import { DailySuccessComponent,SafeHtmlConfirmationDailyPipe } from './payment/daily-success/daily-success.component';
import { NewYearComponent } from './landing/new-year/new-year.component';
//import { SocialLoginComponent } from './pages/social-login/social-login.component';
import { holidayPromocodeLandingComponent,safeHtmlCoupon } from './landing/holiday-promocode-landing/holiday-promocode-landing.component';
import { SpecialsLandingComponent } from './landing/specials-landing/specials-landing.component';
import { MoovitLandingComponent } from './landing/moovit-landing/moovit-landing.component';
import { AggregatorVoucherRedemptionComponent } from './monthly-parking/buymonthly/aggregator-voucher-redemption/aggregator-voucher-redemption.component';
import { VoucherRedemptionComponent } from './monthly-parking/buymonthly/voucher-redemption/voucher-redemption.component';

import { CmsSpecialsLandingComponent } from './Components/Global/cms-specials-landing/cms-specials-landing.component';
import { HeaderagrigatorComponent } from './common/headeragrigator/headeragrigator.component';
import { AggregatorVoucherConfirmationComponent } from './monthly-parking/buymonthly/aggregator-voucher-confirmation/aggregator-voucher-confirmation.component'
import { HttpClient,HttpClientModule } from '@angular/common/http';
import { LoadingBarModule } from '@ngx-loading-bar/core';
//import { LoadingBarHttpModule } from '@ngx-loading-bar/http';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { MonthlySubscriptionAgreementComponent } from './monthly-parking/buymonthly/monthly-subscription-agreement/monthly-subscription-agreement.component';
import { CreateaccountThanksComponent } from './createaccount/createaccount-thanks/createaccount-thanks.component';
// export const httpFactory = (backend: XHRBackend, options: RequestOptions) => {
//   return new HttpService(backend, options);
// };
// for swipe featers
export class MyHammerConfig extends HammerGestureConfig  {
  overrides = <any>{
      'swipe': {velocity: 0.4, threshold: 20, direction: 31} // override default settings
  };
};


let config = {
  breakPoints: {
      xs: {max: 767},
      sm: {min: 768, max: 1023},
      md: {min: 1024, max: 1300},
      lg: {min: 1301, max: 1919},
      xl: {min: 1920}
  },
  debounceTime: 5 // allow to debounce checking timer
};

export function ResponsiveDefinition(){ 
        return new ResponsiveConfig(config);
};
const appRoutes: Routes = [
  { path: '', component: AggregatorVoucherRedemptionComponent },
  { path: 'monthly-terms-and-conditions', component: MonthlySubscriptionAgreementComponent},
  { path: 'Monthly-Terms-And-Conditions', component: MonthlySubscriptionAgreementComponent},
  { path: 'MONTHLY-TERMS-AND-CONDITIONS', component: MonthlySubscriptionAgreementComponent},
  { path: 'aggregator-monthly-booking/:facId/:facType', component : VoucherRedemptionComponent },
  { path: 'aggregator-monthly-booking-confirmation/:resId/:id', component : AggregatorVoucherConfirmationComponent },
  { path: 'create-account', component: CreateaccountComponent },
  {path: 'create-account/thanks', component: CreateaccountThanksComponent},

  { path: 'reset-password', component: ResetPasswordComponent },
  { path: ':voucher', component : AggregatorVoucherRedemptionComponent },
  { path: 'login', component: NewpageComponent },
  {path: 'login/callback',component: OktaCallbackComponent},
  { path: '**', redirectTo: '' },
  // { path: '', redirectTo: '', pathMatch: 'full'},
  //{ path: '', component: HomeComponent },
  // { path: 'newhome', component:   NewpageComponent },
  // { path: 'newyear', component:   NewYearComponent },
  // { path: 'hotels', component:   HotelsComponent },
  // { path: 'cares', component:   CmsPageComponent },
  // { path: 'mobile-login', component: ResponsiveComponent },
  // { path: 'user/:userId/email/:encryptCode', component: HomeComponent },
  // { path: 'evcharging', component: EvchargingComponent },
  // { path: 'app', component: ApppromoComponent },
  // { path: 'oversized', component: OversizedComponent },
  // { path: 'search', component: SearchlocationComponent },
  // { path: 'search/:lat/:long/:currdate/:radius/:label/:facilityType', component: SearchlocationComponent },
  // { path: 'search', component: SearchFacilityComponent },
  // { path: 'search/:lat/:long/:currdate/:radius/:label/:type/:device', component: SearchFacilityComponent},
  // { path: 'search-track/:sdate/:edate/:lat/:long/:currdate/:radius/:label/:type/:device', component: SearchTrackComponent },
  // { path: 'search-track/:masquerade', component: SearchTrackComponent },
  // { path: 'search-track/admin/:admin', component: SearchTrackComponent },
  // { path: 'neighborhoods', component: SearchNeighbourhoodComponent },
  // { path: 'neighborhoods/:location', component: SearchNeighbourhoodComponent },
  // { path: 'neighborhoods/:location/:type', component: SearchNeighbourhoodComponent },

  // { path: 'neighborhoods', redirectTo: 'monthly-parking', pathMatch: 'full' },
  // { path: 'neighborhoods/:location', redirectTo: 'monthly-parking', pathMatch: 'full' },
  // { path: 'neighborhoods/:location/:type', redirectTo: 'monthly-parking', pathMatch: 'full' },
  // { path: 'create-account', component: CreateaccountComponent },
  // { path: 'account-confirmation', component: AccountConfirmationComponent },
  // { path: 'buy-monthly/:facId/:facType', component: BuymonthlyComponent },
  // { path: 'buy-monthly-parking/thanks/:facId/:monId', component: BuymonthlyThanksComponent },
  // { path: 'monthly-parking-information', component: MonthlyParkingInformationComponent },
  // { path: 'monthly-parking/request/thank-you', component: MonthlyParkingThanksComponent },
  // { path: 'monthly-parking-confirmation', component: MonthlyParkingConfirmationComponent },
  // { path: 'monthly-parking-confirmation/:resId/:id', component: MonthlyParkingConfirmationWebComponent },
  // { path: 'monthly-parking-confirmation-app/:resId/:id', component: MonthlyParkingConfirmationAppComponent },
  // { path: 'monthly-parking-terms-conditions', component: BuyMonthlyTermsConditionsComponent },
  
  // { path: 'preferred', component: MonthlyPreferredComponent },
  // { path: 'monthly-parking', component: SearchNeighbourhoodMonthlyComponent },
  // { path: 'monthly-parking/:location', component: SearchNeighbourhoodMonthlyComponent },
  // { path: 'monthly-parking/:location/:type', component: SearchNeighbourhoodMonthlyComponent },

  // { path: 'contact', component: ContactusComponent },
  // { path: 'contact/thanks', component: ContactusThanksComponent },
  // { path: 'payment/:facId/:facType/:facPay', component: PaymentComponent },
  /* { path: 'payment/:facId/:facPay', component: PaymentComponent }, */
  // { path: 'daily-confirmation', component: DailyconfirmationComponent },
  // { path: 'nyp', component: NypComponent },
  // { path: 'campaign', component: CampaignComponent },
  // { path: 'gcoupon/', component: GlobalcouponComponent },
  // { path: 'gcoupon/:coupon-type', component: GlobalcouponComponent },
  // { path: 'widget-form', component: WidgetFormComponent },


  // new homepage routing 
// { path: 'newhome', component:   NewpageComponent },



  //  coupon page listing
  // { path: 'gcoupon/astor-parking', component: AstorParkingComponent },
  // { path: 'gcoupon/aaa', component: AaaComponent },


  // { path: 'gcoupon/advance-parking', component: AdvanceParkingComponent },
  // { path: 'gcoupon/quik-park-theatre-lot', component: QuikParkTheatureLotComponent },
  // { path: 'gcoupon/natgeo', component: NatgeoComponent },
  // coupons url ends

  //{ path: 'gcoupon-sixth-avenue', component: GcouponSixthAvenueComponent },
  // { path: 'facility-detail', component: ParkingDetailsComponent },


  //{ path: 'reservations/:resId/:ticketId', component: DailySuccessComponent},

  /* cms pages routing start */
  // { path: 'icon/:partner_id', component: DigitalCardComponent},
  // { path: 'about-us', component: AboutusComponent },
  // { path: 'icon-green', component: IcongreenComponent },
  // { path: 'parking-tips', component: ParkingtipsComponent },
  // { path: 'yankee-stadium', component: YankeestadiumComponent },
  // { path: 'careers', component: CareersComponent },
  // { path: 'faq', component: FaqComponent },
  // { path: 'FAQ', component: FaqComponent },
  // { path: 'faq/:template_cat', component: FaqComponent },
  // { path: 'faq/:template_cat/:template_que', component: FaqComponent },
  //{ path: 'faq/:', component: FaqComponent },
  //  { path: 'faq-mobile', component: FaqMobileComponent },
  //   { path: 'faq-mobile/:template_cat', component: FaqMobileComponent },
  // { path: 'faq-mobile/:template_cat/:template_que', component: FaqMobileComponent },
  // { path: 'terms-of-service', component: PrivacypolicyComponent },
  //  route for Social login page
 // { path: 'social-login', component: SocialLoginComponent },
  //  router for normal login page
  // { path : 'monthly-special-campaign', component : MonthlySpecialCampaignComponent },
  // { path : 'monthly-special-campaign-neighborhood', component : MonthlySpecialCampaignComponent },
  // { path : 'monthly-special-campaign-facility', component : MonthlySpecialCampaignComponent },
  // { path : 'facility-hours-of-operation', component : MonthlySpecialCampaignComponent },
  // { path : 'buy-monthly-campaign/:facId/:facType', component : BuyMonthlyCampaignComponent },
  // { path : 'buy-monthly-campaign-neighborhood/:facId/:facType', component : BuyMonthlyCampaignNieghborhoodComponent},
  // { path: 'terms-of-service', component: TermsofserviceComponent },
  // { path: 'bigcars', component: BigcarsComponent },
  // { path: 'reservations/cancel/email', component: ReservationCancelComponent  },
  // { path: 'reservations/:resId/:ticketId', component: DailySuccessComponent  },
  // { path: 'guest-reservation/:resId/:ticketId', component: DailySuccessComponent  },
  // { path: 'reservation/:resCode/:ticketechCode/print', component: ReservationsComponent  },
  // { path: 'reservation/cancel/:canResCode/:canTicketechCode', component: ReservationsComponent  },
  // { path: 'employment-application', component: EmploymentapplicationComponent },
  // { path: 'thanks-email', component: CouponThanksComponent },
  // { path: 'thanks-print', component: CouponThanksComponent },
  // { path: 'thanks-coupon-saved', component: CouponThanksComponent },
  // { path: 'facility/:facility_slug', component: FacilityComponent },
  // { path: 'facility', component: FacilityComponent },
  // { path: 'facilities/:facility_slug', component: FacilityComponent },
  // { path: 'facilities', component: FacilityComponent },
  // { path: 'attractions/:attraction_slug', component: AttractionComponent },
  // { path: 'partners/:partner_slug', component: PartnersComponent },
  // { path: '404', component: PageNotFoundComponent },
  // { path:'logout',component:LogoutComponent},
  // { path: '', canActivate:[AuthGuardGuard],
  //   children: [
  //     { path: 'my-account', component: MyAccountComponent,
  //       children: [
  //         { path: 'account', component: AccountComponent  },
  //         { path: 'vehicle-request', component: VehicleRequestComponent  },
          // { path: 'enroll-in-icon-rewards', component: EnrollInIconRewardsComponent  },
          // { path: 'icon-rewards', component: IconRewardsComponent },
          // { path: 'enroll-in-icon-rewards', redirectTo: 'account', pathMatch: 'full'  },
          // { path: 'icon-rewards', redirectTo: 'account', pathMatch: 'full'  },
          
          // { path: 'manage-icon-rewards-card', component: IconRewardsCardComponent },
          // { path: 'manage-icon-rewards-card/:card_id', component: IconRewardsCardComponent },
      //     { path: 'manage-icon-rewards-details', component: IconRewardsDetailsComponent },
      //     { path: 'manage-details', component: ManageDetailsComponent },
      //     { path: 'update-profile', component: UpdateProfileComponent },
      // { path: 'change-password', component: ChangePasswordComponent },
      // { path: 'reset-email', component: RestEmailComponent },
      //     { path: 'brand-partnership', component: BrandPartnershipComponent  },
      //     { path: 'coupons', component: CouponsComponent  },
      //     { path: 'promo-codes', component: PromoCodesComponent  },
      //     { path: 'reservations', component: ReservationsComponent  },
      //     { path: 'reservations/:resId/:ticketId', component: DailySuccessComponent  },
      // { path: '', redirectTo: 'my-account/account', pathMatch: 'full'}
      //   ]
      // },
  //     { path: 'my-account/:monthlyparking', component: MonthlyparkingaccountComponent },
  //     { path: 'my-account/monthly-parking/create-online-account', component: CreateOnlineAccountComponent },
  //     { path: 'my-account/:monthly-parking/account/:account/:module', component: MonthlyparkingaccountComponent }
  //   ]
  // },
  // { path: 'reset-password', component: ResetPasswordComponent },
  // { path: 'reset-password/:resetpass_slug', component: ResetPasswordComponent },
  // { path: 'rewards', component: IconRewardsBenefitComponent },
  // business page solutions
  // { path: 'b2bsolutions', component: B2bLandingComponent },
  // { path: 'b2bsolutions', component: B2bComponent },
  // { path: 'b2bsolutions-frame', component: B2bFrameComponent },
  
  // { path: 'baseball', component : BaseballComponent },
  // { path: 'BASEBALL', component : BaseballComponent },
  // { path: 'Baseball', component : BaseballComponent },
  // { path: 'BaseBall', component : BaseballComponent },
  // { path: 'contest', component : BaseballComponent },
  // { path: 'Contest', component : BaseballComponent },
  // { path: 'CONTEST', component : BaseballComponent },
  // { path: 'cares', component:   BaseballComponent },
  // { path: 'CARES', component:   BaseballComponent },
  // { path: 'Cares', component:   BaseballComponent },

  // { path: 'ourgifttoyou-w', component:   holidayPromocodeLandingComponent },
  // { path: 'ourgifttoyou-e', component:   holidayPromocodeLandingComponent },
  // { path: 'ourgifttoyou-s', component:   holidayPromocodeLandingComponent },
  

  // { path: 'iconparksafe', component:   ParksafeLandingComponent },
  // { path: 'Iconparksafe', component:   ParksafeLandingComponent },
  // { path: 'ICONPARKSAFE', component:   ParksafeLandingComponent },
  
  // { path: 'preferred-customer-program', component: preferredCustomerProgramComponent},
  // {path: 'share-icon-app', component : ShareIcongoAppComponent},
  // { path: 'icon-rewards-register', component: IconRewardsRegisterComponent },
  // { path: 'icon-rewards-terms', component: IconRewardTermsComponent },
  // { path: 'offer/:template_slug/:promotion_id/:promo_code/:action_type', component: LandingPageComponent },
  // { path: 'offer-cms/:template_slug/:promotion_id/:promo_code/:action_type', component: PromocodeCmsLandingComponent },
  // { path: 'offer-cms/:template_slug', component: PromocodeCmsLandingComponent },
  // { path: ':promo_code/:action_type', component: PromocodeCmsLandingComponent },
  // { path: 'specials-promo/:promo_code/:action_type', component: CmsSpecialsLandingComponent },
  
  // { path: 'hotels-list', component:   HotelsComponent },
  // { path: 'hotels', component:   HotelsComponent },
  // { path: 'hotel', component:   HotelsComponent },
  // {path: 'specials', component : SpecialsLandingComponent},
  // {path: 'Specials', component : SpecialsLandingComponent},
  // {path: 'SPECIALS', component : SpecialsLandingComponent},
  // {path: 'moovit', component : MoovitLandingComponent},
  // {path: 'Moovit', component : MoovitLandingComponent},
  // {path: 'MOOVIT', component : MoovitLandingComponent},
  // {path: 'voucher-redemption', component : AggregatorVoucherRedemptionComponent},
  // {path: 'voucher-redemption/:voucher/monthly', component : AggregatorVoucherRedemptionComponent},
  //{ path: ':partner_slug', redirectTo: 'partners/:partner_slug'},
  
  
  
];
@NgModule({
  declarations: [	
    CreateaccountThanksComponent,
    MonthlySubscriptionAgreementComponent,	
    ResponsiveComponent,
    AppComponent,
    WidgetGoogleAutosuggestComponent,
    SearchTrackComponent,
    SpringComponent,
    labourDayComponent,
    labourDayPercentageComponent,
    fallCouponComponent,
    blackFridayCouponComponent,
    CyberMondayCouponComponent,
    SafeHtmlPipe,
    SafeHtmlPipes,
    SafeHtmlMobilePipes,
    SafeHtmlConfirmationWebPipe,
    SafeHtmlConfirmationAppPipe,
    SafeDatePipes,
    ArraySortPipe,
    SearchPageSortPipe,
    safeHtmlNeighborhood,
    SearchSortPipe,
    SearchSortMonthlyPipe,
    BuymonthlySorting,
    safeParkSafe,
    GoogleSigninComponent,
    HomeComponent,
    LogoutComponent,
    FooterComponent,
    PageNotFoundComponent,
    AboutusComponent,
    IcongreenComponent,
    HeaderComponent,
    FooterComponent,
    ParkingtipsComponent,
    YankeestadiumComponent,
    CareersComponent,
    FaqComponent,
    PrivacypolicyComponent,
    TermsofserviceComponent,
    BigcarsComponent,
    EmploymentapplicationComponent,
    MobileHeaderComponent,
    CreateaccountComponent,
    MonthlyparkingComponent,
    ContactusComponent,
    ContactusThanksComponent,
    EqualValidatorDirective,
    ColorPickerDirective,
    rangeSliderDirective,
    owlSliderDirective,
    AccountConfirmationComponent,
    PaymentComponent,
    MonthlyParkingInformationComponent,
    MonthlyParkingThanksComponent,
    MonthlyParkingConfirmationComponent,
    MonthlyParkingConfirmationWebComponent,
    MonthlyParkingConfirmationAppComponent,
    // Monthly Parking My Account Section
    MonthlyparkingaccountComponent,
    CurrentBalanceComponent,
    AutoPayComponent,
    CurrentInvoiceComponent,
    PreviousInvoicesComponent,
    MakePaymentComponent,
    PaymentMethodsComponent,
    PaymentHistoryComponent,
    BillingInformationComponent,
    FrequentlyAskedQuestionsComponent,
    LoyaltyClubComponent,
    RemoveAccountComponent,
    CancellationNoticeComponent,
    AddCreditCardComponent,
    AddBankAccountComponent,
    PayUsingCreditCardComponent,
    PayBankCreditCardComponent,
    PayUsingBankAccountComponent,
    LazyLoadComponent,
    SaverLoadComponent,

    SidenavComponent,
    LoginComponent,
    EvchargingComponent,
    OversizedComponent,
    ApppromoComponent,
    BuymonthlyComponent,
    BuymonthlyThanksComponent,
    MonthlyPreferredComponent,
    MyAccountComponent,
    MonthlyComponent,
    CouponsComponent,
    ReservationsComponent,
    ReservationCancelComponent,
    VehicleRequestComponent,
    BrandPartnershipComponent,
    PromoCodesComponent,
    AccountComponent,
    DailyconfirmationComponent,
    CreateOnlineAccountComponent,
    CouponThanksComponent,

    NypComponent,
    CouponThanksComponent,
    CampaignComponent,

    GoogleMapComponent,
    WebGarageDetailsComponent,
    AppGarageDetailsComponent,
    SearchFacilityComponent,
    SearchNeighbourhoodComponent,
    SearchNeighbourhoodMonthlyComponent,
    LandingPageComponent,
    DistanceconversionPipe,
    PaymenttimePipe,
    PaymentdatePipe,
    ChangePasswordComponent,
    RestEmailComponent,
    ResetPasswordComponent,
    RoundpricePipe,
    LoadViewDirective,
    CouponSliderDirective,
    SearchFiltersComponent,
    FacilityComponent,
    AttractionComponent,
    GlobalcouponComponent,
    MobileSearchFilterComponent,
    MobileSearchFilterNavComponent,
    GcouponSixthAvenueComponent,
    PromocodeLandingComponent,
    HolidayLandingComponent,
    Park10LandingComponent,
    GoogleAutosuggestComponent,
    EmailLandingComponent,
    MonthlyLandingComponent,
    WinterMonthlyLandingComponent,
    // ParkingDetailsComponent,
    // AstorParkingComponent,
    // AaaComponent,
    // AdvanceParkingComponent,
    // QuikParkTheatureLotComponent,
    IconCouponHolidayComponent,
    CouponNyeComponent,
    // NatgeoComponent,
    WinterLandingComponent,
    ErrorlandingComponent,
    PartnersComponent,
    PartnersMapComponent,
    GamedayComponent,
    SnowStormComponent,
    ValentineDayComponent,
    PromocodeCmsLandingComponent,
    MarchMadnessComponent,
    WidgetFormComponent,
    FaqMobileComponent,
    //  component for Social login page
    SocialLoginComponent,
    IconRewardsBenefitComponent,
    IconRewardsComponent,
    EnrollInIconRewardsComponent,
    IconRewardsTabsComponent,
    IconRewardsDetailsComponent,
    IconRewardsCardComponent,
    IconRewardsRegisterComponent,
    LoyaltyComponent,
    RewardsComponent,
    TransactionHistoryComponent,
    IconRewardTermsComponent,
    LoyaltyComponent,
    ManageDetailsComponent,
    UpdateProfileComponent,
    WinterPopupComponent,
    B2bComponent,
    B2bFrameComponent ,
    B2bLandingComponent,
    preferredCustomerProgramComponent,
    HappyNewYearCouponComponent,
    SortHistoryPipe,
    MonthlySpecialCampaignComponent,
    BuyMonthlyCampaignComponent,
    BuyMonthlyCampaignNieghborhoodComponent,
    ShareIcongoAppComponent,
    BaseballComponent,
    ParksafeLandingComponent,
    SafeHtmlHeaderPipe,
    DigitalCardComponent,
    LoyalityBadgeComponent,
    HowItWorksComponent,
    FacilityAutoSearchComponent,
    NewpageComponent,
    BuyMonthlyTermsConditionsComponent,
    DailySuccessComponent,
    SafeHtmlConfirmationDailyPipe,
    HotelsComponent,
    NewYearComponent,
    holidayPromocodeLandingComponent,
    safeHtmlCoupon, 
    SpecialsLandingComponent, 
    AggregatorVoucherRedemptionComponent, 
    VoucherRedemptionComponent,
    MoovitLandingComponent,
    CmsSpecialsLandingComponent,
    HeaderagrigatorComponent,
    AggregatorVoucherConfirmationComponent,
    
  ],
  imports: [
    BrowserModule,
    Ng2DeviceDetectorModule.forRoot(),
    FacebookModule.forRoot(),
    GooglePlaceModule,
    //HttpModule,
    HttpClientModule,
    
    //NgProgressModule,
    ToastrModule.forRoot({timeOut: 10000,preventDuplicates: true,progressBar: true,closeButton: true,tapToDismiss: true}),
    MomentModule,
    BrowserAnimationsModule,
    DateValueAccessorModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCkaAB7RbtzP6Us896IsLcVfmOcPnA1JR4'
    }),
    AngularFontAwesomeModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    RouterModule.forRoot(appRoutes),
    ProgressbarModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    ButtonsModule.forRoot(),
    PopoverModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    TextMaskModule,
    MbscModule,
    ScrollToModule.forRoot(),
    TooltipModule.forRoot(),
    ParallaxScrollModule,
     ResponsiveModule,
     AccordionModule.forRoot(),
     NgxBarcodeModule,
     QRCodeModule,
     LoadingBarModule,
     LoadingBarHttpClientModule,
     OktaAuthModule
  ],
  providers: [
    AuthGuardGuard,
    PromocodeCmsService,
    FAQContentServic,
    GoogleAnalyticsEventsService,
    FacebookAuthService,
    FAQContentServic,
    WindowRefService,
    
    // SearchpageserviceService,
    // {
    //   provide: HttpService,
    //   useFactory: httpFactory,
    //   deps: [XHRBackend, RequestOptions]
    // },
   
    //{ provide: BrowserXhr, useClass: NgProgressBrowserXhr},
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
  },
    RouterExtService,
    {provide: ResponsiveConfig, useFactory: ResponsiveDefinition},
    UtilityService,
    { provide: APP_INITIALIZER, useFactory: fooFactory, deps: [UtilityService], multi: true },
    { 
      provide: OKTA_CONFIG, 
      useFactory: () => {
        const oktaAuth = new OktaAuth(oktaconfig.oidc);
        return {
          oktaAuth,
          onAuthRequired: (oktaAuth: OktaAuth, injector: Injector) => {
            const triggerLogin = () => {
              // Redirect the user to your custom login page
              const router = injector.get(Router);
              router.navigate(['/login']);
            };
            
          }  
        }
      }
    },
    ],
    
  bootstrap: [AppComponent]

})
export class AppModule { }
export function foo(mainConfigService) {
  return function () {
    return mainConfigService.loadFacilities();
  };
};
  
export function fooFactory(mainConfigService) {
  return foo(mainConfigService);
}
