import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { UserserviceService } from '../../../services/userservice.service';
import { CustomCode } from '../../../shared/CustomCode';
// declare var ga: Function;
// declare var gtag: Function;
// declare var gtag_report_conversion : Function;
declare var google: any;
import * as $ from 'jquery';

@Component({
  selector: 'rewardpopup',
  templateUrl: './rewards1.component.html',
  styleUrls: ['./rewards1.component.scss']
})
export class RewardsComponent implements OnInit {

  popupOn:any;
  iconMessage:number=0;
  iconMessageRandom:number=0;
  rewarded_points:number= CustomCode.rewarded_points;
  constructor( private router:Router, private userServiceObj: UserserviceService ) {
    this.popupOn = true;
    
   }
   imgpath = 'assets/img/'; 
   rewaredlogo = this.imgpath + "loyalty/icon-reward-white.svg";
  ngOnInit() {
    let random = Array(2,3);
  let ranValue = random[Math.floor(Math.random()*random.length)]
  if (localStorage.getItem('access_token')) {
    if(localStorage.getItem('is_loyalty')=='1')
    {
      this.iconMessage=0;
    }else{
    if(ranValue==3 || ranValue==2)
    {
      if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/i)) {
        $('.intro-wrapper-footer').css('bottom','122px')
      }else{
        $('.intro-wrapper-footer').css('bottom','50px')
      }
    }
      this.iconMessage=1;
      this.iconMessageRandom=ranValue;
    }
  }else{
    if(ranValue==3 || ranValue==2)
    {
      if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/i)) {
          $('.intro-wrapper-footer').css('bottom','122px')
        }else{
          $('.intro-wrapper-footer').css('bottom','50px')
        }
      
    }
    this.iconMessage=1;
    this.iconMessageRandom=ranValue;
    
  }
  if(localStorage.getItem('access_token')) {
    this.userServiceObj.GetLoyaltyWallet().subscribe(
      response => {
        this.rewarded_points = response.data.loyalty_config.POINTS_AT_REGISTRATION;
      }
    );
  }
  }


close(){
  this.iconMessageRandom = 5;
}
gtmTrackingEventMoreBtn(i){

  if(i == 1){
   // ga('send', 'event', { eventCategory: 'read-more', eventAction: 'Click', eventLabel: 'DON’T MISS-(Square)',eventValue:'0' });
    let eventLabel = 'DON’T MISS-(Square)';
      if(sessionStorage.getItem('event_for_loyalty') != '1' ){
      sessionStorage.setItem('event_for_loyalty', '1');
      sessionStorage.setItem('eventlabel', eventLabel);
      sessionStorage.setItem('eventCategory', 'read-more');
    }
  } 
  if( i ==2 ) {
   // ga('send', 'event', { eventCategory: 'read-more', eventAction: 'Click', eventLabel: 'DON’T MISS-(Strip)',eventValue:'1' });
    let eventLabel = 'DON’T MISS-(Strip)';
      if(sessionStorage.getItem('event_for_loyalty') != '1' ){
      sessionStorage.setItem('event_for_loyalty', '1');
      sessionStorage.setItem('eventlabel', eventLabel);
      sessionStorage.setItem('eventCategory', 'read-more');
    }
  } 
  if( i ==3 ) {
    let eventLabel = 'Your 1,000 points-(Strip)';
      if(sessionStorage.getItem('event_for_loyalty') != '1' ){
      sessionStorage.setItem('event_for_loyalty', '1');
      sessionStorage.setItem('eventlabel', eventLabel);
      sessionStorage.setItem('eventCategory', 'read-more');
    }
  //  ga('send', 'event', { eventCategory: 'read-more', eventAction: 'Click', eventLabel: 'Your 1,000 points-(Strip)',eventValue:'0' });
  } 
   if( i ==4 ) {
    let eventLabel = 'Your 1,000 points-(Square)';
    if(sessionStorage.getItem('event_for_loyalty') != '1' ){
    sessionStorage.setItem('event_for_loyalty', '1');
    sessionStorage.setItem('eventlabel', eventLabel);
    sessionStorage.setItem('eventCategory', 'read-more');
  }
   // ga('send', 'event', { eventCategory: 'read-more', eventAction: 'Click', eventLabel: 'Your 1,000 points-(Square)',eventValue:'1' });
  }
  this.router.navigate(['/rewards']);
}

redirecttomonthlyrequest()
{
 // ga('send', 'event', { eventCategory: 'Winter-Special home page', eventAction: 'Click', eventLabel: 'Home Page winter special stripe' });
      sessionStorage.setItem('winterlabel', 'Winter Special stripe');
      this.router.navigate(['/monthly-parking-information']);
}
}
