
import { Component, OnInit, Input } from '@angular/core';
import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
  providers: [MyAccountMonthlyParkingService]
})
export class PaymentMethodsComponent implements OnInit {

	@Input() currentActive: string;
  @Input() accountNumber: any;
  @Input() activeAccount: any;
  
  activeUser: any;

  addCreditCard = false;
	addBankAccount = false;

	showAddCreditCardBtn = true;
	showAddBankAccountBtn = true;

	addingPaymentMethods = false;

  paymentMethods: any = { };

  isLoaded = false;

  constructor(
  	private toastr: ToastrService,
  	private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService) { }

  ngOnInit() {
  	this.isLoaded = false;
    // this.fetchMonthlyParkingAccount();
  	this.getpaymentMethods();
  }

  fetchMonthlyParkingAccount() {
    this.myAccountMonthlyParkingObj.getAccountDetails(this.accountNumber)
    .then(
      data => {
        this.isLoaded = true;
        this.activeAccount = data['data'];
        this.getpaymentMethods();
      },
      error => {
        this.isLoaded = true;
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }

  getpaymentMethods() {
    this.myAccountMonthlyParkingObj.paymentMethods(this.accountNumber)
    .then(
      data => {
        this.isLoaded = true;
        this.paymentMethods = data['data'];
      },
      error => {
      	this.toastr.error(error.errors.message, 'Sorry!');
      	this.isLoaded = true;
      }
    );
  }

  showAddCreditCard() {
  	this.addingPaymentMethods = true;
    this.addBankAccount = false;
    this.showAddBankAccountBtn = true;
    this.showAddCreditCardBtn = false;
    this.addCreditCard = true;
  }

  showAddBankAccount() {
  	this.addingPaymentMethods = true;
    this.addCreditCard = false;
    this.addBankAccount = true;
    this.showAddCreditCardBtn = true;
    this.showAddBankAccountBtn = false;
  }

  receiveCancel($event) {
    this.addingPaymentMethods = $event;
    this.showAddBankAccountBtn = true;
    this.showAddCreditCardBtn = true;
  }

  afterAdd($event) {
    this.getpaymentMethods();
    // this.addingPaymentMethods = $event;
    // this.showAddBankAccountBtn = true;
    // this.showAddCreditCardBtn = true;
  }

  deleteMethod(profile_id) {
    let currerntPaymentMethod;
    for (var i = this.paymentMethods.payments.length - 1; i >= 0; i--) {
      if (profile_id == this.paymentMethods.payments[i].payment_profile_id) {
        currerntPaymentMethod = this.paymentMethods.payments[i];
      }
    }
    let methodText;
    if (currerntPaymentMethod.card == null) {
      methodText = "Bank ending in (" + currerntPaymentMethod.bank.bank_account + '), for ' + currerntPaymentMethod.bank.bank_name
    } else {
      methodText = "Card ending in (" + 
      currerntPaymentMethod.card.card_number + 
      ') for ' + currerntPaymentMethod.address.first_name + 
      ' ' + currerntPaymentMethod.address.last_name +
      ', ' + 
      'Expires on ' + currerntPaymentMethod.expiration_month + '/' + currerntPaymentMethod.expiration_year;
    }
    let res = confirm('Are you sure you want to delete the payment method "' + methodText + '"?' + '\n' + ' PLEASE NOTE THAT DELETING THIS PAYMENT METHOD WILL DISABLE AUTOPAY ON YOUR ACCOUNT. PLEASE REMEMBER TO ACTIVATE AUTOPAY WITH YOUR NEW PAYMENT METHOD."');
    if (res === true) {
        this.myAccountMonthlyParkingObj.deletePaymentMethod(this.accountNumber, profile_id)
        .then(
          data => {
            this.getpaymentMethods();
            this.toastr.success('Payment Method Deleted Successfully', 'Great!');
          },
          error => {
            this.toastr.error(error.errors.message, 'Sorry!');
          }
        );
    } 
  }
}
