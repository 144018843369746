import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {
  icon_rewards_troffy = 'assets/img/loyalty/piggybank.svg';
  icon_rewards_gift = 'assets/img/icon-rewards-gift.svg';
  price_tag = 'assets/img/price-tag.svg';
  doller_tag = 'assets/img/loyalty/disocunt-credit.svg';
  @Input() isPayment: boolean = false;
  isIEBrowser = false;
  constructor() { }

  ngOnInit() {
    let currentWindow =  window.navigator.userAgent;
		if(currentWindow.indexOf("MSIE ") > -1 || currentWindow.indexOf("Trident/") > -1){
			this.isIEBrowser = true;
		 }else {
			this.isIEBrowser = false;
		 }
 
  }

}
