import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';

import { CustomCode } from '../../shared/CustomCode';
import { UserserviceService } from '../../services/userservice.service';
import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SearchpageserviceService } from '../../services/searchpageservice.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HomepageserviceService } from '../../services/homepageservice.service';
// declare var ga: Function;
// declare var gtag_report_conversion: Function;
@Component({
	selector: 'user-account',
	templateUrl: './account.component.html',
	providers: [UserserviceService, MyAccountMonthlyParkingService, SearchpageserviceService, HomepageserviceService]
})
export class AccountComponent implements OnInit {

	gtmUrl: SafeResourceUrl = window.location.pathname;
	accountNumber: any;
	pageSection: string;
	showMonthlyParking: any;
	showInnerPages: any;
	SmallDevice: any;

	isLoaded = false;
	isLoadedp = false;
	isLoadedv = false;
	formData: any = {};

	activeUser: any = [];
	monthlyParkingAccounts: any = {};
	showClassColorInActive=0;
	showClassColorActive=0;
	coupons: any = {};
	reservations: any = {};
	wallet: any = {};
	transactions: any = {};
	walletMessages: any = {};
	referralCode = '';

	walletConfig: any = {};

	accountDetails = true;
	creditDetails = false;
	printData;
	public modalRef: BsModalRef;
	fetchUserSavedCards;
	showMethodHeading:boolean=false;
	showornot:boolean=true
	searchCriteria: any = {
		'type': CustomCode.defaultFacilityType,
		'device': 'web',
		'lat': CustomCode.timeSquareCoords.lat,
		'long': CustomCode.timeSquareCoords.long,
		'currdate': CustomCode.getDateForMapSearchSlashFormat(),
		'radius': CustomCode.facilityDefaultRadius,
		'label': CustomCode.timeSquareCoords.defaultLocation,
		'length_of_stay': 2,
		'firstLoad': false
	};
	isLoyaltyMember: boolean = false;
	isLoyaltyActive: boolean = false;
	loyalty_points: number = 0;
	visa_Icon = 'assets/img/visa.svg';
    mastercardIcon =  'assets/img/mastercard.svg';
    discovercardIcon = 'assets/img/discovercard.svg';
    amexIcon = 'assets/img/amex.svg';
	card_grey_Icon = 'assets/img/card-grey.svg';
	jcbIcon = 'assets/img/jcbcard.svg';
	dinersIcon = 'assets/img/dinersclub-icon.svg';
	couponSelectedId:any=0;
	showPrintButton:boolean=false;
	showPrintCancel:any=1;
	barcodeValue:any='d37255005006728'
	qrcodeData;
	barcodeData;
	couponCodeVaue:any='341fce58'
	imgSrcLocation:any='';
	responseData;
	defaultImgSrcLocation:any="assets/img/park_garage.png";
	showoffer=0;
	array_data:any={
	"facility": {
		"id": 116,
		"short_name": "ZEN-DAYS",
		"full_name": "ZENITH PARKING LLC           ",
		"garage_code": "8025",
		"facility_type_id": "1",
		"between_streets": "48th St and 49th St",
		"entrance_location": "790-806 8th Avenue and 254 West 49th Street",
		"phone_number": "(212) 581-8590 ",
		"base_rate": "70.00",
		"reservation_bonus_hours": 1,
		"reservation_bonus_rate": 5,
		"active": "1",
		"base_event_rate": "40.00",
		"oversize_fee": "15.00",
		"processing_fee": "0.00",
		"tax_rate": "0.18375",
		"base_event_rate_max_stay": "13",
		"garage_type_id": "0",
		"service_type_id": "0",
		"vehicle_type_id": "0",
		"garage_located": "",
		"accept_cc": "1",
		"accept_echeck": "1",
		"monthly_rate": "300.00",
		"neighborhood_id": "8",
		"legacy_id": "139",
		"elimiwait_location_id": "299",
		"beacon_status": "0",
		"is_indoor_parking": "1",
		"is_outdoor_parking": "0",
		"is_tesla_charging": "0",
		"is_generic_ev_charging": "0",
		"is_motorcycle_parking": "0",
		"realtime_window": null,
		"coupon_threshold": null,
		"merchant_id": "650000009794770",
		"slug": "zen-days",
		"has_ticketech": true,
		"has_monthly": true,
		"is_elimiwait_active": false,
		"display_name": "790 Eighth Avenue",
		"geolocations": {
			"id": 896,
			"address_1": "790 Eighth Avenue",
			"address_2": "",
			"city": "New York",
			"state": "NY",
			"zip_code": "10019",
			"longitude": "-73.9864679",
			"latitude": "40.7614799"
		}
	},
	"geolocations": {
		"id": 896,
		"address_1": "790 Eighth Avenue",
		"address_2": "",
		"city": "New York",
		"state": "NY",
		"zip_code": "10019",
		"longitude": "-73.9864679",
		"latitude": "40.7614799"
	},
	"coupons": [
		{
			"id": 3531,
			"facility_id": "116",
			"rate_type_id": "2",
			"min_stay": "0.00",
			"max_stay": "3.00",
			"price": "26.00",
			"monday": "1",
			"tuesday": "1",
			"wednesday": "1",
			"thursday": "1",
			"friday": "1",
			"saturday": "1",
			"sunday": "1",
			"active": "1",
			"description": "3 Hours Coupon",
			"entry_time_begin": "00:00:00",
			"entry_time_end": "23:59:59",
			"exit_time_begin": "00:00:00",
			"exit_time_end": "23:59:59",
			"category_id": "27",
			"coupon_code": "dt237",
			"deleted_at": null,
			"details": "",
			"partner_id": null,
			"coupon_restrictions": "NOT VALID ON THANKSGIVING DAY",
			"mobile_coupon_code": "341fce58",
			"split_price": [
				"26",
				"00"
			],
			"open_time_description": "Enter any time",
			"days_open_description": "7 Days a week",
			"time_in": "2:43 AM on Nov 01, 2018",
			"time_out": "Nov 15, 2018",
			"min_stay_details": "",
			"max_stay_details": "Max Stay 3 Hours",
			"exit_time_details": "Exit any time",
			"is_coupon": true,
			"is_partner_coupon": false,
			"is_event": false,
			"can_repeat": false,
			"can_always_enter": true,
			"can_always_exit": true,
			"facility": {
				"id": 116,
				"short_name": "ZEN-DAYS",
				"full_name": "ZENITH PARKING LLC           ",
				"garage_code": "8025",
				"facility_type_id": "1",
				"between_streets": "48th St and 49th St",
				"entrance_location": "790-806 8th Avenue and 254 West 49th Street",
				"phone_number": "(212) 581-8590 ",
				"base_rate": "70.00",
				"reservation_bonus_hours": 1,
				"reservation_bonus_rate": 5,
				"active": "1",
				"base_event_rate": "40.00",
				"oversize_fee": "15.00",
				"processing_fee": "0.00",
				"tax_rate": "0.18375",
				"base_event_rate_max_stay": "13",
				"garage_type_id": "0",
				"service_type_id": "0",
				"vehicle_type_id": "0",
				"garage_located": "",
				"accept_cc": "1",
				"accept_echeck": "1",
				"monthly_rate": "300.00",
				"neighborhood_id": "8",
				"legacy_id": "139",
				"elimiwait_location_id": "299",
				"beacon_status": "0",
				"is_indoor_parking": "1",
				"is_outdoor_parking": "0",
				"is_tesla_charging": "0",
				"is_generic_ev_charging": "0",
				"is_motorcycle_parking": "0",
				"realtime_window": null,
				"coupon_threshold": null,
				"merchant_id": "650000009794770",
				"slug": "zen-days",
				"has_ticketech": true,
				"has_monthly": true,
				"is_elimiwait_active": false,
				"display_name": "790 Eighth Avenue",
				"geolocations": {
					"id": 896,
					"address_1": "790 Eighth Avenue",
					"address_2": "",
					"city": "New York",
					"state": "NY",
					"zip_code": "10019",
					"longitude": "-73.9864679",
					"latitude": "40.7614799"
				}
			},
			"rate_type": {
				"id": 2,
				"rate_type": "Coupons"
			}
		},
		{
			"id": 2814,
			"facility_id": "116",
			"rate_type_id": "2",
			"min_stay": "0.00",
			"max_stay": "12.00",
			"price": "36.00",
			"monday": "1",
			"tuesday": "1",
			"wednesday": "1",
			"thursday": "1",
			"friday": "1",
			"saturday": "1",
			"sunday": "1",
			"active": "1",
			"description": "12 Hours Coupon",
			"entry_time_begin": "00:00:00",
			"entry_time_end": "23:59:59",
			"exit_time_begin": "00:00:00",
			"exit_time_end": "23:59:59",
			"category_id": "20",
			"coupon_code": "dt237",
			"deleted_at": null,
			"details": "",
			"partner_id": null,
			"coupon_restrictions": "NOT VALID ON THANKSGIVING DAY",
			"split_price": [
				"36",
				"00"
			],
			"open_time_description": "Enter any time",
			"days_open_description": "7 Days a week",
			"time_in": "2:43 AM on Nov 01, 2018",
			"time_out": "Nov 15, 2018",
			"min_stay_details": "",
			"max_stay_details": "Max Stay 12 Hours",
			"exit_time_details": "Exit any time",
			"is_coupon": true,
			"is_partner_coupon": false,
			"is_event": false,
			"can_repeat": false,
			"can_always_enter": true,
			"can_always_exit": true,
			"rate_type": {
				"id": 2,
				"rate_type": "Coupons"
			}
		},
		{
			"id": 2816,
			"facility_id": "116",
			"rate_type_id": "2",
			"min_stay": "0.00",
			"max_stay": "6.00",
			"price": "28.00",
			"monday": "1",
			"tuesday": "1",
			"wednesday": "1",
			"thursday": "1",
			"friday": "1",
			"saturday": "1",
			"sunday": "1",
			"active": "1",
			"description": "6 Hours Coupon",
			"entry_time_begin": "00:00:00",
			"entry_time_end": "23:59:59",
			"exit_time_begin": "00:00:00",
			"exit_time_end": "23:59:59",
			"category_id": "22",
			"coupon_code": "dt237",
			"deleted_at": null,
			"details": "",
			"partner_id": null,
			"coupon_restrictions": "NOT VALID ON THANKSGIVING DAY",
			"split_price": [
				"28",
				"00"
			],
			"open_time_description": "Enter any time",
			"days_open_description": "7 Days a week",
			"time_in": "2:43 AM on Nov 01, 2018",
			"time_out": "Nov 15, 2018",
			"min_stay_details": "",
			"max_stay_details": "Max Stay 6 Hours",
			"exit_time_details": "Exit any time",
			"is_coupon": true,
			"is_partner_coupon": false,
			"is_event": false,
			"can_repeat": false,
			"can_always_enter": true,
			"can_always_exit": true,
			"rate_type": {
				"id": 2,
				"rate_type": "Coupons"
			}
		}
	],
	"mainCoupon": {
		"id": 3531,
		"facility_id": "116",
		"rate_type_id": "2",
		"min_stay": "0.00",
		"max_stay": "3.00",
		"price": "26.00",
		"monday": "1",
		"tuesday": "1",
		"wednesday": "1",
		"thursday": "1",
		"friday": "1",
		"saturday": "1",
		"sunday": "1",
		"active": "1",
		"description": "3 Hours Coupon",
		"entry_time_begin": "00:00:00",
		"entry_time_end": "23:59:59",
		"exit_time_begin": "00:00:00",
		"exit_time_end": "23:59:59",
		"category_id": "27",
		"coupon_code": "dt237",
		"deleted_at": null,
		"details": "",
		"partner_id": null,
		"coupon_restrictions": "NOT VALID ON THANKSGIVING DAY",
		"mobile_coupon_code": "341fce58",
		"split_price": [
			"26",
			"00"
		],
		"open_time_description": "Enter any time",
		"days_open_description": "7 Days a week",
		"time_in": "2:43 AM on Nov 01, 2018",
		"time_out": "Nov 15, 2018",
		"min_stay_details": "",
		"max_stay_details": "Max Stay 3 Hours",
		"exit_time_details": "Exit any time",
		"is_coupon": true,
		"is_partner_coupon": false,
		"is_event": false,
		"can_repeat": false,
		"can_always_enter": true,
		"can_always_exit": true,
		"facility": {
			"id": 116,
			"short_name": "ZEN-DAYS",
			"full_name": "ZENITH PARKING LLC           ",
			"garage_code": "8025",
			"facility_type_id": "1",
			"between_streets": "48th St and 49th St",
			"entrance_location": "790-806 8th Avenue and 254 West 49th Street",
			"phone_number": "(212) 581-8590 ",
			"base_rate": "70.00",
			"reservation_bonus_hours": 1,
			"reservation_bonus_rate": 5,
			"active": "1",
			"base_event_rate": "40.00",
			"oversize_fee": "15.00",
			"processing_fee": "0.00",
			"tax_rate": "0.18375",
			"base_event_rate_max_stay": "13",
			"garage_type_id": "0",
			"service_type_id": "0",
			"vehicle_type_id": "0",
			"garage_located": "",
			"accept_cc": "1",
			"accept_echeck": "1",
			"monthly_rate": "300.00",
			"neighborhood_id": "8",
			"legacy_id": "139",
			"elimiwait_location_id": "299",
			"beacon_status": "0",
			"is_indoor_parking": "1",
			"is_outdoor_parking": "0",
			"is_tesla_charging": "0",
			"is_generic_ev_charging": "0",
			"is_motorcycle_parking": "0",
			"realtime_window": null,
			"coupon_threshold": null,
			"merchant_id": "650000009794770",
			"slug": "zen-days",
			"has_ticketech": true,
			"has_monthly": true,
			"is_elimiwait_active": false,
			"display_name": "790 Eighth Avenue",
			"geolocations": {
				"id": 896,
				"address_1": "790 Eighth Avenue",
				"address_2": "",
				"city": "New York",
				"state": "NY",
				"zip_code": "10019",
				"longitude": "-73.9864679",
				"latitude": "40.7614799"
			}
		},
		"rate_type": {
			"id": 2,
			"rate_type": "Coupons"
		}
	},
	"couponCode": "341fce58",
	"barcode": "d37255005006728",
	"restriction": "NOT VALID ON THANKSGIVING DAY",
	"couponValidity": false
}
	constructor(
		private routeObj: Router,
		private activatedRouteObj: ActivatedRoute,
		private userserviceServiceObj: UserserviceService,
		private toastr: ToastrService,
		private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
		private modalService: BsModalService,
		private searchPagenObj: SearchpageserviceService,
		private homePageServiceObj: HomepageserviceService,
		private sanitizer: DomSanitizer, ) {
		this.SmallDevice = false;
	}

	ngOnInit() {
		this.fetchUserCards();
		//gtag_report_conversion(this.gtmUrl);

		// ga('event', 'conversion', {
		// 	'send_to': 'AW-876742851/JeWeCP-Vm3UQw5GIogM',
		// 	'eventCallback': window['callbackgtm']
		// });
		this.isLoaded = false;
		this.fetchUserDetails();
		if ($(window).width() <= 467) {
			this.SmallDevice = true;
		}

		if (localStorage.getItem('is_loyalty') === "1") {
			this.isLoyaltyMember = true;
			this.userserviceServiceObj.GetLoyaltyWallet().subscribe(
				res => {
					this.loyalty_points = res.data.wallet.balance;
				}
			)
		}
		if (localStorage.getItem('is_loyalty_active') === "1") { this.isLoyaltyActive = true; }
	}

	fetchUserCards() {
    this.userserviceServiceObj.fetchUserSavedCards().then(
      (res) => {
		this.fetchUserSavedCards = res;
		if(this.fetchUserSavedCards.data.payments.length>0)
		{
			this.showMethodHeading=true;
		}
		else{
			this.showMethodHeading=false;
		}
		
      },
      (error) => { 
      }
    );
  }

	showWalletTransactions() {
		this.accountDetails = false;
		this.creditDetails = true;
		window.scrollTo({ top: 0, behavior: 'smooth' });
		$('a#copyText').text('Copy Link');
	}

	closePopup() {
		$('.modal-dialog').hide();
		this.modalRef.hide()
		//$('#couponmail').css('display', 'block');
		$('#coupon-modal .modal-dialog').addClass('modal-sm');
		$('#couponheading').text('How would you like to receive your coupon?');
		$('#coupon-mail-print').css('display', 'block');
		$('#couponprintdata').css('display', 'none');
	}

	printMediaback() {
		$('.modal-dialog').hide();
		this.modalRef.hide()
		$('div#printable').html($('div#printableContent').html()).show();
		$('div#content-Wrapper').hide();
		window.print();
		$('div#printable').html('').hide();
		$('div#content-Wrapper').show();
		this.routeObj.navigate(['/thanks-print']);
	}
	printMedia(id) {
		$('body').removeClass('modal-open');
		setTimeout(()=>{
			$('div#printable').html($('#table'+id).html()).show();
			$('div#content-Wrapper').hide();
			window.print();
		},2000);

		setTimeout(()=>{
			$('div#printable').hide();
			$('div#content-Wrapper').show();
			this.routeObj.navigate(['/thanks-print']);
		},4000);
	}
	showMyAccountDetails() {
		this.accountDetails = true;
		this.creditDetails = false;
	}

	fetchWalletConfig() {
		this.userserviceServiceObj.fetchWalletConfig()
			.then(
				data => {
					this.walletConfig = data['data'].wallet_config;
				},
				error => {
					this.isLoaded = true;
					this.toastr.error('Couldn\'t Fetch Wallet Configurations', 'Sorry!');
				}
			);
	}

	fetchUserDetails() {
		this.myAccountMonthlyParkingObj.fetchUser()
			.then(
				data => {
		this.showornot=false
		this.activeUser = data['data'];
		let inactive:any=0;
        let active:any=0;
        let acvtiveInc=0;
        let inActiveInc=0
        this.showClassColorActive=1
		this.showClassColorInActive=1
		let activenumber=0
		let inactivenumber=0
		
		let times_run = setInterval(()=> {
			this.showornot=true;
			for(let j=0;j<this.activeUser.monthly_parking_facilities.length;j++)
			{
			  inactive=0;
			  active=0;
			  for(let p=0;p<this.activeUser.monthly_parking_facilities[j].accounts.length;p++)
			  {
				if(this.activeUser.monthly_parking_facilities[j].accounts[p].active==1)
				{
				  active=1
				  acvtiveInc++;
				  activenumber=this.activeUser.monthly_parking_facilities[j].accounts[p].account_number
				}
				if(this.activeUser.monthly_parking_facilities[j].accounts[p].active==0)
				{
				  inactive=1;
				  inActiveInc++;
				  inactivenumber=this.activeUser.monthly_parking_facilities[j].accounts[p].account_number
				}
			  }
			  //alert(activenumber);
			  $('#accountD'+activenumber).removeClass('dualAccount');
			  $('#accountM'+activenumber).removeClass('dualAccount');
			  $('#inaccountD'+inactivenumber).removeClass('dualAccount');
			  $('#inaccountM'+inactivenumber).removeClass('dualAccount');
			  if(inactive==0)
			  {
				
				$('#inactive'+this.activeUser.monthly_parking_facilities[j].id).addClass('hide');
			  }
			  if(active==0)
			  {
				//alert('#active'+this.activeUser.monthly_parking_facilities[j].id)
				$('#active'+this.activeUser.monthly_parking_facilities[j].id).addClass('hide');
			  }
			  if(inActiveInc==0)
			  {
				this.showClassColorInActive=0;
			  }else{
				this.showClassColorInActive=1;
			  }
			  if(acvtiveInc==0)
			  {
				this.showClassColorActive=0;
			  }else{
				this.showClassColorActive=1;
			  }
			//  alert("inactive"+inactive+"active"+active+""+this.activeUser.monthly_parking_facilities[j].id)
			}
			if(inActiveInc==0)
			{
			  this.showClassColorInActive=0;
			}else{
			  this.showClassColorInActive=1;
			}
			if(acvtiveInc==0)
			{
			  this.showClassColorActive=0;
			}else{
			  this.showClassColorActive=1;
			}	
		},1000); 

        setTimeout(()=>{
			clearInterval(times_run);
		},20000)
		
          if(inactive==0)
          {
            this.showClassColorInActive=0;
          }
          if(active==0)
          {
            this.showClassColorActive=0
          }
					this.monthlyParkingAccounts = data;
					this.fetchUserCouponDetails();
					this.fetchWalletConfig();
				},
				error => {
					this.isLoaded = true;
					this.toastr.error(error.errors.message, 'Sorry!');
				}
			);
	}

	fetchUserCouponDetails() {
		this.coupons = {};
		this.userserviceServiceObj.fetchUserCoupons(this.activeUser.id)
			.then(
				data => {
					this.coupons = data['data'].data;
					this.fetchUserReservationDetails();
				},
				error => {
					this.isLoaded = true;
					this.toastr.error(error.errors.message, 'Sorry!');
				}
			);
	}

	removeCoupon(couponId) {
		this.userserviceServiceObj.deleteUserCoupon(couponId)
			.then(
				data => {
					$('div#' + couponId).parent().remove();
					this.toastr.success('Offer has been removed successfully', 'success');
				},
				error => {
					this.isLoaded = true;
					this.toastr.error(error.errors.message, 'Sorry!');
				}
			);
	}

	fetchUserReservationDetails() {
		this.userserviceServiceObj.fetchUserReservations(this.activeUser.id)
			.then(
				data => {
					this.reservations = data['data'].data;
					this.ensureUserReferralCode();
				},
				error => {
					this.isLoaded = true;
					this.toastr.error(error.errors.message, 'Sorry!');
				}
			);
	}

	ensureUserReferralCode() {
		const data = {
			'user_id': this.activeUser.id
		};
		this.userserviceServiceObj.userReferral(data)
			.then(
				data => {
					this.fetchUserWalletDetails();
				},
				error => {
					this.isLoaded = true;
					this.toastr.error(error.errors.message, 'Sorry!');
					this.fetchUserWalletDetails();
				}
			);
	}

	fetchUserWalletDetails() {
		this.userserviceServiceObj.fetchUserWallet(this.activeUser.id)
			.then(
				data => {
					this.wallet = data['data'].wallet;
					this.wallet.balance = this.wallet.balance;
					this.transactions = data['data'].transactions;
					this.walletMessages = data['data'].messages;
					this.referralCode = data['data'].referral_code;
					// this.creditDetails = true;
					this.isLoaded = true;
				},
				error => {
					this.isLoaded = true;
					this.toastr.error(error.errors.message, 'Sorry!');
				}
			);
	}

	copyLink(val) {
		const selBox = document.createElement('textarea');

		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;

		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();

		document.execCommand('copy');
		document.body.removeChild(selBox);

		$('a#copyText').text('Link Copied!');
	}

	navigateMonthlyParking() {
		this.routeObj.navigate(['/my-account/monthly-parking/create-online-account']);
	}

	navigateCoupons() {
		this.routeObj.navigate(['/search', this.searchCriteria.lat, this.searchCriteria.long, this.searchCriteria.currdate, this.searchCriteria.radius, this.searchCriteria.label, this.searchCriteria.type, CustomCode.searchMode()]);
	}

	// findSpot() {

	// }

	navigateChangePassword() {
		this.routeObj.navigate(['/my-account/change-password']);
	}

	navigateUpdateProfile(){
		this.routeObj.navigate(['/my-account/update-profile']);
	}

	navigateResetEmail() {
		this.routeObj.navigate(['/my-account/reset-email']);
	}

	navigateReservations() {
		this.routeObj.navigate(['/search', this.searchCriteria.lat, this.searchCriteria.long, this.searchCriteria.currdate, this.searchCriteria.radius, this.searchCriteria.label, this.searchCriteria.type, CustomCode.searchMode()]);
	}

	redirectToDetails(resId,iD) {
		this.routeObj.navigate(['/my-account/reservations', resId,iD]);
	}

	findSpot() {
		this.routeObj.navigate(['/search', this.searchCriteria.lat, this.searchCriteria.long, CustomCode.getDateForMapSearchSlashFormatNew(), this.searchCriteria.radius, this.searchCriteria.label, this.searchCriteria.type, CustomCode.searchMode()]);
	}
	printCoupon(couponId,template) {
		
		//alert(couponId);
	//	this.modalRef = this.modalService.show(template);
		const facId = couponId;
		this.isLoadedp = false;
		this.searchPagenObj.printCouponDetails(facId).subscribe(
			(couponPrint) => {
			  $('#triggercoupon').trigger('click');
				//const urlCreator = window.URL;
				//const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(couponPrint['_body']));
				//this.printData = blobData['changingThisBreaksApplicationSecurity'];
				this.showPrintButton=true;
				couponPrint=couponPrint.data;
				this.barcodeValue=couponPrint.barcode;
				this.qrcodeData  = couponPrint.parkonect_id;
				this.barcodeData  = couponPrint.ticketech_id;
				if(couponPrint.facility.photos != null){
					this.imgSrcLocation=couponPrint.facility.photos.url
				}else{
					this.imgSrcLocation=this.defaultImgSrcLocation;
				}
	  		this.couponCodeVaue=couponPrint.couponCode
				this.array_data=couponPrint
				this.isLoadedp = true;
				$('#invoicePrint').css('display', 'block');
				$('#couponvalid').css('display', 'block');
			},
			(error) => {
				this.isLoadedp = true;
			}
		);
		
		$('#coupon-mail-print').css('display', 'none');
		$('#couponprintdata').css('display', 'block');
		$('#invoicePrint').css('display', 'none');
		$('#couponvalid').css('display', 'none');
	}
	printCouponback(couponId, template) {
		//alert(couponId);
		this.modalRef = this.modalService.show(template);
		const facId = couponId;
		this.isLoadedp = false;
		this.searchPagenObj.printCoupon(facId).then(
			(couponPrint) => {
				const urlCreator = window.URL;
				const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(couponPrint['_body']));
				this.printData = blobData['changingThisBreaksApplicationSecurity'];
				this.isLoadedp = true;
				$('#invoicePrint').css('display', 'block');
				$('#couponvalid').css('display', 'block');
			},
			(error) => {
				this.isLoadedp = true;
			}
		);

		$('#coupon-mail-print').css('display', 'none');
		$('#couponprintdata').css('display', 'block');
		$('#invoicePrint').css('display', 'none');
		$('#couponvalid').css('display', 'none');
	}
	viewCoupon(couponId,vtemplate,id) { 
		//alert(couponId);
		//alert(couponId);
		//this.modalRef = this.modalService.show(vtemplate);
		this.couponSelectedId=couponId
		const facId = couponId;
		this.isLoadedp = false;
		this.searchPagenObj.printCouponDetails(facId).subscribe(
			(couponPrint) => {
			  $('#triggercoupon').trigger('click');
			  this.showPrintCancel=id;
				//const urlCreator = window.URL;
				//const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(couponPrint['_body']));
				//this.printData = blobData['changingThisBreaksApplicationSecurity'];
				$('.viewhide').hide();
				couponPrint=couponPrint.data;
				this.barcodeValue=couponPrint.barcode;
				this.qrcodeData  = couponPrint.parkonect_id;
	            this.barcodeData  = couponPrint.ticketech_id;
				if(couponPrint.facility.photos != null){
					this.imgSrcLocation=couponPrint.facility.photos.url
				}else{
					this.imgSrcLocation=this.defaultImgSrcLocation;
				}
	  		this.couponCodeVaue=couponPrint.couponCode
				this.array_data=couponPrint
				this.isLoadedp = true;
				$('#invoicePrint').css('display', 'block');
				$('#couponvalid').css('display', 'block');
			},
			(error) => {
				this.isLoadedp = true;
			}
		);
	
		
		$('#coupon-mail-print').css('display', 'none');
		$('#couponprintdata').css('display', 'block');
		$('#invoicePrint').css('display', 'none');
		$('#couponvalid').css('display', 'none');
	}

	removeCard(id,idName,message)
	{
		let mes="Are you sure you want to remove the card ending with ("+message.replace('XXXX', '..')+")?";
		let confirmOption=confirm(mes)
		if(confirmOption)
		{
			this.userserviceServiceObj.deleteUserCard(id).subscribe(
				res => {
					if($('.cardReservation').length==1)
					{
						//alert("zzz");
						this.showMethodHeading=false;
					}else{
						this.showMethodHeading=true;
					}
					$('#cards'+idName).hide();
					//this.fetchUserCards();
				}
			)
		}
	}

	sendApp() {
		const appText = parseInt((<HTMLInputElement>document.getElementById('sendapp')).value.trim());
		if (appText) {
			const phoneNum = ((<HTMLInputElement>document.getElementById('sendapp'))).value;
			const userLat = ((<HTMLInputElement>document.getElementById('navigatetogoogle'))).getAttribute('href');
			const text = 'Welcome to icon parking. click on the link below ' + userLat;
			this.homePageServiceObj.messageToMobile(phoneNum, text)
				.subscribe(
					data => {
						this.responseData = data;
						this.toastr.success('Facility Location has been sent Successfully', 'success!');
						$('button.close').trigger('click');
					},
					error => {
						const errorsList = error.errors;
						if (errorsList.message !== 'Validation Errors') {
							this.toastr.error(errorsList.message, 'Sorry!');
						} else {
							for (const key in errorsList.detail) {
								if (errorsList.detail.hasOwnProperty(key)) {
									if (typeof errorsList.detail === 'undefined') {
										this.toastr.error(errorsList.message, 'Sorry!');
									} else {
										this.toastr.error(errorsList.detail[key], 'Sorry!');
									}
								}
							}
						}
					}
				);
		} else {
			this.toastr.error('Number is required', 'Sorry!');
		}
		(<HTMLInputElement>document.getElementById('sendapp')).value = '';
	}


}

