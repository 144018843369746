import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-gcoupon-sixth-avenue',
  templateUrl: './gcoupon-sixth-avenue.component.html'

})
export class GcouponSixthAvenueComponent implements OnInit {
  isLoyaltyMember:boolean = false;
  isLoyality = false;
  constructor(
		private router: Router,
	){}

  ngOnInit() {
    if(localStorage.getItem('is_loyalty')==="1" && localStorage.getItem('is_loyalty_active')=='1'){
      this.isLoyaltyMember = true;
    }
  }

  printCoupon(){
  	$('div#printable').html($('div#printableContent').html()).show();
	$('div#content-Wrapper').hide();
	window.print();

	// $('div#printable').html('').hide();
	// $('div#content-Wrapper').show();
	// this.router.navigate(['/thanks-print']);


	setTimeout(()=>{
		// $('div#printable').html($('div#printableContent').html()).show();
		// $('div#content-Wrapper').hide();
		// window.print();
	},1000);

	setTimeout(()=>{
		$('div#printable').html('').hide();
	$('div#content-Wrapper').show();
	this.router.navigate(['/thanks-print']);
	},2000);
  }
}