import { Injectable } from '@angular/core';

//import { Http, Headers, RequestOptions, Response } from '@angular/http';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { HttpService } from './http.service';
import { Observable } from 'rxjs/Rx';
import { CustomCode } from '../shared/CustomCode';
import { DomSanitizer } from '@angular/platform-browser';

import * as FileSaver from 'file-saver';

// Import RxJs required methods
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VehiclerequestService {

constructor(private http: HttpClient) { }
private Vehiclerequest = CustomCode.APIURL + 'elimiwait-account';
private getUsersDetails = CustomCode.APIURL + 'api';
private getMpUsersDetails = CustomCode.APIURL + 'elimiwait-account/accounts/';

private postCarRequest = CustomCode.APIURL + 'elimiwait/request/';

private postCarRequestStatus = CustomCode.APIURL + 'elimiwait-account/requests/';

activeUser:any = [];

activeMpUser:any = [];

AddVehiclerequest(VehiclerequestData) {
    const headers = new HttpHeaders();
    const access_token = localStorage.getItem('access_token');
  headers.append('Authorization', 'Bearer ' + access_token);
    //const options = new RequestOptions({ headers: headers });
  
    const formDetails = {'tenant_spot': VehiclerequestData.tenant_spot, 'cell': VehiclerequestData.cell1,
    'vehicle_make': VehiclerequestData.vehicle_make, 'vehicle_model': VehiclerequestData.vehicle_model, 'vehicle_plate': VehiclerequestData.vehicle_plate, 'vehicle_color': VehiclerequestData.vehicle_color, 'mp_user_id': VehiclerequestData.mp_user_id};
    
    return this.http.post(this.Vehiclerequest, formDetails, { headers: headers })
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error));
  
  }
  
  fetchUser() {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersDetails).subscribe(
        res => {
      
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  
  Confirmpickup(ConfirmpickupData,elimiid){
    
    const headers = new HttpHeaders();
    const access_token = localStorage.getItem('access_token');
  headers.append('Authorization', 'Bearer ' + access_token);
    //const options = new RequestOptions({ headers: headers });
  const formDetails = {'pickUpTime': ConfirmpickupData.pickUpTime};
  
    return this.http.post(this.postCarRequest + elimiid, formDetails, { headers: headers })
    .map((res: any) => res.json())
    .catch((error: any) => Observable.throw(error.json()));  
    
  }
  
  CarRequestStatus(userid){
    
    return new Promise((resolve, reject) => {
      this.http.get(this.postCarRequestStatus + userid).subscribe(
        res => {
      
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
    
  }
  
   fetchMPUser(userid) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getMpUsersDetails + userid).subscribe(
        res => {
      
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

}
