
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { CustomCode } from '../../shared/CustomCode';
import { FormValidationErrors } from '../../shared/formValidationErrors';
import { StaticpagesService } from '../../services/staticpages.service';
import { SafeResourceUrl } from '@angular/platform-browser';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
declare var gtag:Function;
declare var gtag_report_conversion : Function;

@Component({
  selector: 'app-monthly-parking-information',
  templateUrl: './monthly-parking-information.component.html',
  styleUrls: ['./monthly-parking-information.component.scss'],
  providers: [StaticpagesService]
})
export class MonthlyParkingInformationComponent implements OnInit {

  gtmUrl:SafeResourceUrl = window.location.pathname;

  pageId:string ='monthly-parking-information';
  cmsPageContent : any = { }
  isLoaded = false;

  formData: any = {};
  emailRegex = CustomCode.emailRegex;
  phoneMask: any = CustomCode.phoneMask;
  private respData;
  private parseData;
  mobileLenght=0;
  condition:any=1;
  // phoneMask: any = [
  //   '(', /[0-9]/, /[0-9]/, /[0-9]/, ')', '-',
  //   /[0-9]/, /[0-9]/, /[0-9]/ , '-',
  //   /[0-9]/, /[0-9]/, /[0-9]/ , '-',
  //   /[0-9]/, /[0-9]/, /[0-9]/
  // ];
  constructor(private routerObj: Router, private staticPageServiceObj: StaticpagesService, private toasterObj: ToastrService) { }

  ngOnInit() {
    let times_run= setInterval(function () {
      $('body').addClass('showChat');
    }, 3000);
    setTimeout(function () {
      clearInterval(times_run);
      localStorage.removeItem('preffred_location_bysearch');
    }, 10000);
    CustomCode.manageFormLabelsPosition();


    this.staticPageServiceObj.getCmsPageContent(this.pageId).subscribe(
      (data:any) => {
        this.cmsPageContent = data.result[0];
        this.isLoaded = true;
      }, (error) => { console.log('Error: ' + JSON.stringify(error));  
        this.isLoaded = true;
      }
    )
    if(localStorage.getItem('preffred_location_bysearch'))
    {
      this.condition=0
    }


  }
  keyPress(event: any) {
    const pattern = /[0-9\)\-\(\.\ ]/;
    //const pattern = /[0-9\-\\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  submitMPInformation(formValue, formObj: NgForm) {
    let preffred_location='';
    if(localStorage.getItem('preffred_location_type') && localStorage.getItem('preffred_location_type')=='1')
    {
      preffred_location=localStorage.getItem('preffred_location_bysearch_data');
    }
    if(localStorage.getItem('preffred_location_type') && localStorage.getItem('preffred_location_type')=='2')
    {
      preffred_location=localStorage.getItem('preffred_location');
    }
    formValue.preffred_location=preffred_location;
    const reg =/<(.|\n)*?>/g; 
    if (reg.test(formValue.name) == true) {
      this.toasterObj.error('HTML Tags are not allowed in Name.', 'Sorry!');
      return false;
    } else {
      if (reg.test(formValue.email) == true) {
        this.toasterObj.error('HTML Tags are not allowed in Email.', 'Sorry!');
        return false;
      }
      else{
        if (reg.test(formValue.desire_parking_area) == true) {
          this.toasterObj.error('HTML Tags are not allowed in Parking area.', 'Sorry!');
          return false;
        }
        else{
          if (reg.test(formValue.vehicle_make) == true) {
            this.toasterObj.error('HTML Tags are not allowed Vehicle make.', 'Sorry!');
            return false;
          }
          else{
            if (reg.test(formValue.vehicle_model) == true) {
              this.toasterObj.error('HTML Tags are not allowed in Vehicle model.', 'Sorry!');
              return false;
            }
            else{
              //return true;
            }
          }
        }
      }
    }
    if (typeof formValue.phone_number === "undefined" || formValue.phone_number === "") {
        this.mobileLenght=1;
    }
    else{
      let number= formValue.phone_number;
       formValue.phone=formValue.phone_number;
       formValue.phone = formValue.phone.replace(/[&\/\\# ,+()$~%.'":*?<>{}]/g, '');
       formValue.phone = formValue.phone.replace(/-/g,'');
       //formValue.phone = formValue.phone.replace(/./g,'');
       if(formValue.phone.length >=9 && formValue.phone.length <=12 ) {
         this.mobileLenght=1;
       }else{
         this.mobileLenght=0;
         formValue.phone_number=number;
       }
    }
  if(formValue.phone != undefined && formValue.phone != ''){
    let ph = formValue.phone.replace(/\D/g, '');
    if(ph.length !==0 && ph.length < 10){
      formObj.form.controls['phone_number'].setErrors({'invalid': true});
      return false;
    }
  }
   
    if(this.mobileLenght==1) { 
    formValue.phone=formValue.phone_number;
    this.staticPageServiceObj.submitMonthlyParkingInformation(formValue)
      .subscribe(
      data => {
        if(sessionStorage.getItem('winterlabel'))
        {
          (<any>window).dataLayer.push(
             		{
                  'event': 'userAction',
                    'lable': 'Ask query on monthly information page',
                    'pageCategory': 'Winter Special - Popup Banner',
            		});
          sessionStorage.removeItem('winterlabel')
        }
        this.respData = JSON.stringify(data);
        this.parseData = JSON.parse(this.respData);
        if (this.parseData.status === 201) {
          formObj.resetForm();
		      this.toasterObj.success('Thank you for submitting your monthly parking request. We will get in touch with you.', 'Success!');

          gtag_report_conversion(this.gtmUrl);

          gtag('event', 'conversion', {
            'send_to': 'AW-876742851/NQPmCIG1930Qw5GIogM',
            'eventCallback':window['callbackgtm']

          }); 
          localStorage.removeItem('preffred_location_type');
          localStorage.removeItem('preffred_location_bysearch');
          localStorage.removeItem('preffred_location_bysearch_data');
          this.routerObj.navigate(['/monthly-parking/request/thank-you']);
        }
      },
      error => {
        /* FormValidationErrors.displayServerErrors('parking-errors-container', error.errors); */
        const errorsList = error.errors;
        if (errorsList.message !== 'Validation Errors') {
          this.toasterObj.error(errorsList.message, 'Sorry!');
        } else {
          for (const key in errorsList.detail) {
            if (errorsList.detail.hasOwnProperty(key)) {
              if (typeof errorsList.detail === 'undefined') {
                this.toasterObj.error(errorsList.message, 'Sorry!');
              } else {
                this.toasterObj.error(errorsList.detail[key], 'Sorry!');
              }
            }
          }
        }
      }
      );
    }else{
          
          this.toasterObj.error('Phone number must contain 9 to 12 digit.', 'Error!');
    }
  }

  restSpace(event: any){
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }

  getLatLongFun($event)
{
$('#lat').val($event.lat);
$('#long').val($event.long);
$('#searchTextField').val($event.location);
}

}
