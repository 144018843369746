import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FilterHelper } from '../../../classes/filter-helper';
import { CustomCode } from '../../../shared/CustomCode';
import { MyAccountMonthlyParkingService } from '../../../services/my-account-monthly-parking.service';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
@Component({
  selector: 'app-attraction',
  templateUrl: './attraction.component.html',
  styleUrls: ['./attraction.component.scss'],
  providers: [MyAccountMonthlyParkingService]
})
export class AttractionComponent implements OnInit {

  	attraction_slug:string;
  	attraction_details:any = {};

	constructor(
		private activatedRoute: ActivatedRoute,
    	private router: Router,
    	private toastr: ToastrService,
    	private myAccountMonthlyParking: MyAccountMonthlyParkingService
	) { }

	ngOnInit() {
		window.scrollTo(0,0);
            $("body").animate({ scrollTop: 0 }, 500);
		this.activatedRoute.params.subscribe((params: Params) => {
			this.attraction_slug = params['attraction_slug'];
			// this.redirectToAttractionSearch(
				this.generateSearchRedirectLink()
			// );
      });
	}

	

	generateSearchRedirectLink() {
		this.myAccountMonthlyParking.fetchAttraction(this.attraction_slug)
	    .then(
			data => {
				this.attraction_details = data['data'];
				let redirect_link = '/search/' +  
				this.attraction_details.geolocations.latitude + '/' + 
				this.attraction_details.geolocations.longitude + '/' +
				CustomCode.getDateForMapSearch() + '/' +
				CustomCode.facilityDefaultRadius + '/' +
				this.attraction_details.name + '/' +
				'daily/' +  
				CustomCode.searchMode();
				this.router.navigate([redirect_link]);
				// return redirect_link;
			},
			error => {
				this.toastr.error(error.errors.message, 'Sorry!');
				this.router.navigate(['/']);
		    }
	    );
	}
}
