import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomCode } from '../shared/CustomCode';
import * as $ from 'jquery';
import { UserserviceService } from '../services/userservice.service';
import { ToastrService } from 'ngx-toastr';
//declare var kochava:any;
//import { parse } from 'path';

@Component({
  selector: 'app-logins',
  templateUrl: './responsive.component.html',
  styleUrls: ['./responsive.component.scss'],
  providers: [UserserviceService]
})
export class ResponsiveComponent implements OnInit {
  currDate = CustomCode.getDateForMapSearchSlashFormat();
  constructor(
      private routerObj: Router, 
      private userServiceObj: UserserviceService,
      private toasterObj: ToastrService,
      private activatedRouteObj: ActivatedRoute,
  ) { 
  }

  ngOnInit() {
    //CustomCode.manageFormLabelsPosition();
    //var bankName = this.activatedRouteObj.snapshot.paramMap.get('id');
    let userData='';
    this.activatedRouteObj.queryParams.subscribe(params => {
      if(params['key'])
      {
        let key = { keyword:params['key'] }
         this.userServiceObj.EncrypInformation(key)
          .subscribe(
            response => {
                let data = response.data;
                let userData=data.user_id
                if(userData=='guest')
                {
                  localStorage.removeItem('is_loyalty')
                  localStorage.removeItem('is_loyalty_active')
                  localStorage.removeItem('userid')
                  localStorage.removeItem('token_type')
                  localStorage.removeItem('expires_in')
                  localStorage.removeItem('access_token')
                  localStorage.removeItem('username')
                  localStorage.removeItem('refresh_token')
                  this.routerObj.navigate(['rewards']);
                }else{
                  this.userServiceObj.loginUserWithUserId({id:userData})
                  .subscribe(data =>{
                  CustomCode.markLoginUser(data.data);
                  if(data.data.user.is_loyalty_active)
                  {
                    this.routerObj.navigate(['my-account/icon-rewards']);
                  }else{
                    this.routerObj.navigate(['my-account/account']);
                  }
              
              
                  },
                  error=>{
                  this.toasterObj.error('Access denied', 'Sorry!');
                })
                }
                 
              },
              error=>{
                this.toasterObj.error('Access denied', 'Sorry!');
              });
        
      }
     
    },error=>{
      this.toasterObj.error('Access denied', 'Sorry!');
    })
    
    
  }
  

}
