import { Component, OnInit, ElementRef,ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { DomSanitizer } from '@angular/platform-browser'

import { Observable } from 'rxjs/Rx';

// Import RxJs required methods
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { StaticpagesService } from '../../services/staticpages.service';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [StaticpagesService]
})
export class PrivacypolicyComponent implements OnInit {

  page: String = 'privacy-policy';
  staticPageContent: any = {};
  pageId:string = 'terms-of-service';
  cmsPageContentTerms : any = {};
  isLoaded = false;

  constructor(private router: Router, private staticObj: StaticpagesService) { }

  ngOnInit() {
    this.cmsPageContentTerms.post_content=''
    this.staticObj.staticPagesContent(this.page).then(
      (res) => {
        this.staticPageContent = res;
        this.staticPageContent = this.staticPageContent.data;
        this.isLoaded = true;
      },
      (error) => { console.log('Error: ' + JSON.stringify(error)); 
      this.isLoaded = true;
    }
    );

    
    this.staticObj.getCmsPageContent(this.pageId).subscribe(
      (data:any) => {
        this.cmsPageContentTerms = data.result[0];
      }  , 
      error=>console.log("e"+error.object)
      );


  }

}
