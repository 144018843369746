import { Component, OnInit } from '@angular/core';
import {GoogleAnalyticsEventsService} from "../../../shared/google-analytics-events.service";
// declare var ga: Function;
// declare var gtag:Function;
@Component({
  selector: 'app-monthly-landing',
  templateUrl: './monthly-landing.component.html',
  styleUrls: ['./monthly-landing.component.scss']
})
export class MonthlyLandingComponent implements OnInit {

  phoneIcon = 'assets/img/campaign/monthly-landing/phone.svg';
  back_chevron_whiteIcon = 'assets/img/campaign/monthly-landing/back-chevron-white.svg';
  mailIcon = 'assets/img/campaign/monthly-landing/mail.svg';
  Customer_phone = 'tel:18887057845';
  arrowsIcon = 'assets/img/arrows.svg';
  map_markers = 'assets/img/map_markers.svg';
  vehicleIcon = 'assets/img/vehicle.svg'
  

  constructor() { }

  ngOnInit() {
  }
  scroll(el) {
    el.scrollIntoView();
  }
  callMonthlyCustomer()
  {
  //   gtag('config', 'AW-876742851/dsFECMy1930Qw5GIogM', {
  //   'phone_conversion_number': '1(888)705-7845'
  // });
  }
}
