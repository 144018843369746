import { Component, OnInit } from '@angular/core';
import { FilterHelper } from '../../../classes/filter-helper';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CustomCode } from '../../../shared/CustomCode';

@Component({
  selector: 'app-reservation-cancel',
  templateUrl: './reservation-cancel.component.html',
  styleUrls: ['./reservation-cancel.component.scss']
})
export class ReservationCancelComponent implements OnInit {
  isFrame:any;
  constructor(private router: Router) { }

  ngOnInit() {
    this.isFrame = false;
    if(window.self!==window.top)
    {
      this.isFrame = true;
    }else{
      this.isFrame = false;
    }
  }

  fordaily()
  {
      let startDuration = FilterHelper.defaultStartDuration();
      let startTime = FilterHelper.getFormattedTime(startDuration);
      localStorage.setItem('monthlyDate', startDuration.toString())
      let endDuration = FilterHelper.defaultEndDuration();
      let endTime = FilterHelper.getFormattedTime(endDuration);
      let endTimeDataIfLess = FilterHelper.makeDurationDateObject(endDuration, endTime);
      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(endDuration, endTime));
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(startDuration, startTime));
      let stime =localStorage.getItem('startimehome');
      this.router.navigate(['/search', '40.7589', '-73.9851', stime, 1,  'Times Square','daily', CustomCode.searchMode()]);
  }

  

}
