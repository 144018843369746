import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer,SafeResourceUrl } from '@angular/platform-browser';
// declare var ga: Function;
// declare var gtag: Function;
// declare var gtag_report_conversion : Function;

@Component({
	selector: 'app-valentine-day',
	templateUrl: './valentine-day.component.html',
	styleUrls: ['./valentine-day.component.scss']
})
export class ValentineDayComponent implements OnInit {

	@Input() promoCode: string;

	@Output() saveEvent = new EventEmitter<boolean>();
	
	gtmUrl: SafeResourceUrl = window.location.pathname;

	url: SafeResourceUrl;

	constructor(sanitizer: DomSanitizer) {
	    this.url = sanitizer.bypassSecurityTrustResourceUrl('https://open.spotify.com/embed/user/ah3nklzjrqesns8wztigsk2kg/playlist/0JpXhXzzghMu33LxcQru8y');      
	}

	ngOnInit() {
	}

	savePromocode(location) {
	 //  ga('send', 'event', { eventCategory: 'CUPID10', eventAction: 'click'});

      // gtag_report_conversion(this.gtmUrl);
    		
	//    gtag('event', 'conversion', {
    //     	'send_to': 'AW-876742851/xBakCPSEiXwQw5GIogM',
    //   	    'eventCallback':window['callbackgtm'],
    //   	    'value': 20.0,
    //   	    'currency': 'USD'
  	// 	});

  		(<any>window).dataLayer.push(
         {
            'promoCode': 'CUPID10',
            'event': 'Promocode Save',
            'eventCallback': window['callbackgtm'] 
         });
		this.saveEvent.emit(location);
	}

	// subscription() {

	// }

}
