import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { SearchpageserviceService } from '../../../services/searchpageservice.service';
import {GoogleAnalyticsEventsService} from "../../../shared/google-analytics-events.service";
import { DomSanitizer } from '@angular/platform-browser';
import { HomepageserviceService } from '../../../services/homepageservice.service';
import { LogsService } from '../../../services/logs.service';
// declare var ga: Function;
import * as $ from 'jquery';
//declare var kochava:any;

@Component({
	selector: 'app-spring',
	templateUrl: './spring.component.html',
	styleUrls: ['./spring.component.scss'],
	providers:[SearchpageserviceService, LogsService]
})
export class SpringComponent implements OnInit {

  respdata:any;
  printData:any;
  isLoaded;
  mailFormData: any = {};
	responseData: any;
	searchUrl = '/search';
  

  constructor(
  	private searchPageObj: SearchpageserviceService,
  	private router: Router,
  	private toasterObj: ToastrService,
  	public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
  	public sanitizer: DomSanitizer,
		public homePageServiceObj: HomepageserviceService,
		private logsService: LogsService
  	) { }

  ngOnInit() {

  }

  sendCoupon(formData, formObj: NgForm) {
 
  	   // ga('send', 'event', { eventCategory: 'Spring Coupon', eventAction: 'click'});
		const cId = 3969;
		//this.getVisitorCouponTracking({"couponId":cId,"type":this.EMAIL_COUPON,"to":formData.ucmail});
		this.searchPageObj.mailLandingCoupon(formData, cId)
		.subscribe(
			data => {
	
				this.respdata = data;
				this.searchPageObj.getMobileCode().subscribe((data)=>{

					(<any>window).dataLayer.push(
						{
						'promoCode': 'U13',
						'couponCode':data.data.code,
						'barCode':data.data.barcode,
						'event': 'Email Coupon',
						'location': data.data.facility.short_name,
						'landing page name':'Spring Coupon Page'
						});

						/* #Start Add GTM logged in db */
						let logData = {
							'iq_code': null,
							'coupon_code':data.data.code,
							'bar_code':data.data.barcode,
							'event_type':0
						}

						this.logsService.addLog(logData).subscribe( (result)=>{ }, (err) => { });
						/* #End GTM logged in db */
 				});
				if (this.respdata.status === 201) {
					// kochava.activity('email_coupon', {
					// 	coupon_id : cId
					// });
					this.router.navigate(['/thanks-email']);
				}
			},
			error => {
				const errorsList = error.errors;
				if (errorsList.message !== 'Validation Errors') {
					this.toasterObj.error(errorsList.message, 'Sorry!');
				} else {
					for (const key in errorsList.detail) {
						if (errorsList.detail.hasOwnProperty(key)) {
							if (typeof errorsList.detail === 'undefined') {
								this.toasterObj.error(errorsList.message, 'Sorry!');
							} else {
								this.toasterObj.error(errorsList.detail[key], 'Sorry!');
							}
						}
					}
				}
			}
		);
	}

	printCoupon() {
		//ga('send', 'event', { eventCategory: 'Spring Coupon', eventAction: 'click'});
		this.isLoaded = false;
		$('#invoicePrint').css('display', 'none');
		const cId = 3969;
		this.searchPageObj.printCoupon(cId).then(
			(couponPrint) => {
				const urlCreator = window.URL;
				const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(couponPrint['_body']));
				this.printData = blobData['changingThisBreaksApplicationSecurity'];
				this.isLoaded = true;
				this.searchPageObj.getMobileCode().subscribe((data)=>{
					(<any>window).dataLayer.push(
						{
						'promoCode': 'U13',
						'couponCode':data.data.code,
						'event': 'Print Coupon',
						'barCode':data.data.barcode,
						'location': data.data.facility.short_name,
						'landing page name':'Spring Coupon Page'
						});

						/* #Start Add GTM logged in db */
						let logData = {
							'iq_code': null,
							'coupon_code':data.data.code,
							'bar_code':data.data.barcode,
							'event_type':0
						}

						this.logsService.addLog(logData).subscribe( (result)=>{ }, (err) => { });
						/* #End GTM logged in db */
 				});
				$('#invoicePrint').css('display', 'block');
			},
			(error) => {
				
				this.isLoaded = true;
			}
		);
		//this.getVisitorCouponTracking({"couponId":facId,"type":this.PRINT_COUPON});
	}

	printMedia() {
		$('#coupon-modal .modal-dialog').removeClass('modal-sm');
		$('div#printable').html($('div#printableContent').html()).show();
		$('div#content-Wrapper').hide();
		window.print();
		$('div#printable').html('').hide();
		$('div#content-Wrapper').show();
		this.router.navigate(['/thanks-print']);
	}

	resetForm(formObj: NgForm) {
		formObj.resetForm();
	}

	subscription() {
    
	    const subscribeText = (<HTMLInputElement>document.getElementById('addsubscription')).value.trim();
	    if (subscribeText) {
	      let data = {};
	      if (localStorage.getItem('access_token')) {
	        data = {
	          "email" : (<HTMLInputElement>document.getElementById('addsubscription')).value.trim(),
	          "user_id" : localStorage.getItem('userid')
	        };
	      } else {
	        data = {
	          "email" : (<HTMLInputElement>document.getElementById('addsubscription')).value.trim()
	        };
	      }
	     
	      this.homePageServiceObj.subscribeToApplication(data)
	        .subscribe(
	        data => {
	          this.responseData = data;
	          if (this.responseData.status === 201) {
	            this.toasterObj.success('Email subscribed successfully', 'success!');
	          } else {
	            this.toasterObj.error('This email address is already subscribed to our mailing list', 'Sorry!');
	          }
	          (<HTMLInputElement>document.getElementById('addsubscription')).value = '';
	        },
	        error => {
	          const errorsList = error.errors;
	          if (errorsList.message !== 'Validation Errors') {
	            this.toasterObj.error(errorsList.message, 'Sorry!');
	          } else {
	            for (const key in errorsList.detail) {
	              if (errorsList.detail.hasOwnProperty(key)) {
	                let errorMessage = "This email address is already subscribed to our mailing list";
	                if (typeof errorsList.detail === 'undefined') {
	                  if (errorsList.message != "The email has already been taken.") {
	                    errorMessage = errorsList.message
	                  }
	                  // this.toasterObj.error(errorsList.message, 'Sorry!');
	                } else {
	                  if (errorsList.detail[key] != "The email has already been taken.") {
	                    errorMessage = errorsList.detail[key]
	                  }
	                  // this.toasterObj.error(errorsList.detail[key], 'Sorry!');
	                }
	                this.toasterObj.error(errorMessage, 'Sorry!');
	              }
	            }
	          }
	        }
	        );
	      (<HTMLInputElement>document.getElementById('addsubscription')).value = '';
	    } else {
	      this.toasterObj.error('email is required', 'Sorry!');
	    }
  }

}
