import { Component, OnInit } from '@angular/core';

import { StaticpagesService } from '../../services/staticpages.service';

import { HomepageserviceService } from '../../services/homepageservice.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-apppromo',
  templateUrl: './apppromo.component.html',
  styleUrls: ['./apppromo.component.scss'],
  providers: [HomepageserviceService]
})
export class ApppromoComponent implements OnInit {

  responseData;
  app_iconIcon = 'assets/img/app_icon.svg';
  itunesUrl = 'https://itunes.apple.com/us/app/icon-parking/id394377820?mt=8';
  play_google = 'https://play.google.com/store/apps/details?id=com.iconparking&hl=en';
  dollar_icon = 'assets/img/dollar_icon.svg';
  discount_icon = 'assets/img/discount_icon.svg';
  arrowsIcon = 'assets/img/arrows.svg';
  starIcon = 'assets/img/star.svg';
  appPhoneIcon = 'assets/img/appPhone.png';
  appPhoneXsIcon = 'assets/img/appPhoneXs.png';
  AppStoreIcon = 'assets/img/AppStore.svg';
  google_play_Icon = 'assets/img/google-play.svg'

  constructor(
    private homePageServiceObj: HomepageserviceService,
    private toasterObj: ToastrService
  ) { }

  ngOnInit() {
  }

  sendApp() {
    const appText = parseInt((<HTMLInputElement>document.getElementById('sendapp')).value.trim());
    if (appText) {
      const phoneNum = ((<HTMLInputElement>document.getElementById('sendapp'))).value;
      //const text = 'Welcome to icon parking. click on the link below https://www.google.com';
	  const text = 'Welcome to Icon Parking. click on the link below Android App https://play.google.com/store/apps/details?id=com.iconparking&hl=en and Iphone App https://itunes.apple.com/us/app/icon-parking/id394377820?mt=8';
	  
	  
      this.homePageServiceObj.messageToMobile(phoneNum, text)
          .subscribe(
          data => {
            this.responseData = data;
            this.toasterObj.success('App link sent successfully', 'success!');
          },
          error => {
            const errorsList = error.errors;
            if (errorsList.message !== 'Validation Errors') {
              this.toasterObj.error(errorsList.message, 'Sorry!');
            } else {
              for (const key in errorsList.detail) {
                if (errorsList.detail.hasOwnProperty(key)) {
                  if (typeof errorsList.detail === 'undefined') {
                    this.toasterObj.error(errorsList.message, 'Sorry!');
                  } else {
                    this.toasterObj.error(errorsList.detail[key], 'Sorry!');
                  }
                }
              }
            }
          }
          );
    } else {
      this.toasterObj.error('Number is required', 'Sorry!');
    }
    (<HTMLInputElement>document.getElementById('sendapp')).value = '';
  }

}
