import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router } from '@angular/router'
import { StaticpagesService } from './../../services/staticpages.service';
import { FilterHelper } from '../../classes/filter-helper';
import { CustomCode } from './../../shared/CustomCode'
// declare var ga: Function;

@Component({
  selector: 'app-specials-landing',
  templateUrl: './specials-landing.component.html',
  styleUrls: ['./specials-landing.component.scss'],
  providers: [StaticpagesService]
})

export class SpecialsLandingComponent implements OnInit {
  pageSlug: String = 'specials-landing-page';
  staticPageContent: any = {};
  isLoadeds = false;
  isLoggedIn:boolean = false
  isLoyality = false;
  sections_list : any = [];
  radioModel = 'All';
  allBlock:boolean =  true;
  daily:boolean =  false;
  monthly:boolean =  false;
  showShortContent = true;
  fixedLength = 200;
  isCollapsed = false;
headerImage= '../../assets/img/landing-pages/special/header.jpg';
listThumbnail = '../../assets/img/landing-pages/newyaer/hero-graphic_v1_600x400_animated.gif';

  constructor( private staticPagesService: StaticpagesService, private router:Router ) { }

  ngOnInit() {
    $('body').removeClass('noscroll');
    $('body').removeClass('noserch-scroll');
    
    if(localStorage.getItem('is_loyalty_active') && localStorage.getItem('is_loyalty_active')=='1')
    {
      this.isLoyality = true
    }

    let access_token = localStorage.getItem('access_token');
    let refresh_token = localStorage.getItem('refresh_token');
if(access_token && refresh_token){
  this.isLoggedIn = true
}else {
  this.isLoggedIn = false
}
  


// this.staticPagesService.getCMSpageContentList(this.page).subscribe( data => {
//   this.staticPageContent = data.result[0];
//   if( 'sections_list' in this.staticPageContent){
//     this.sections_list = this.staticPageContent['sections_list']
//   }else {
//     this.sections_list = []
//   }
//   console.log(this.staticPageContent)
//   this.isLoadeds = true
// } ),
// (error)=> {
//   console.log('Error' + JSON.stringify(error))
//   this.isLoadeds = true
// }

this.staticPagesService.getCMSpageContentWithFields(this.pageSlug).subscribe(
  data =>  {
    this.isLoadeds = true
    this.staticPageContent =  data[0];
   // console.log(this.staticPageContent);
  }
),
(error) => {
  console.log('Error' + JSON.stringify(error));
  this.isLoadeds = true
}


}
  updateFiler(i){
    if(i ==0){ 
       this.allBlock = true; this.daily = false;  this.monthly = false; 
     //  ga('send', 'event', { eventCategory: 'All Specials' , eventLabel: 'All Deals', eventAction: 'Btnclick'});
      }
    if(i == 1 ) { 
      this.allBlock = false; this.daily = true;  this.monthly = false; 
     // ga('send', 'event', { eventCategory: 'Daily Deals' , eventAction: 'Btnclick'});
    }
    if(i == 2 ) {
       this.allBlock = false; this.daily = false;  this.monthly = true;
     //  ga('send', 'event', { eventCategory: 'Monthly Deals' , eventAction: 'Btnclick'});
       }
    
  }

  checkLengthOfDescription(data, status){
    if(status ==0){
      if(data.length>this.fixedLength){
        return true
       }else {
        return false
       }
    }else {
      return false
    }
    
  }
  showFullContent(id) {
    $('#id-'+id).children('.limited-text').addClass('hide');
    $('#id-'+id).children('.full-text').removeClass('hide');
  }
  showLimitedContent(id){
    $('#id-'+id).children('.limited-text').removeClass('hide');
    $('#id-'+id).children('.full-text').addClass('hide');
  }
  
  getLimitedContent(data){
    return data.substring(0, this.fixedLength - 3) + "...";
  }

  redirectTOUrl(item:any){
// console.log(item)
    

    if( item['button_redirection_type'] =='external_url' && item['external_url'] !== '' ){
     // ga('send', 'event', { eventCategory: item.offer_title , eventLabel: 'Icon Specials All Deals', eventAction: (item.offer_button_text)?'Btnclick '+item.offer_button_text: 'Btnclick Book Now'});
    let pattern = /^((http|https):\/\/)/;
      let url = item.external_url;  
      if(!pattern.test(url)) {
          url = "http://" + url;
      }
      window.open(
        url,
        '_blank' 
      );
    }
    if( item['button_redirection_type'] =='internal_coupon' && item['internal_url'] !== '' ){
      
      let nav =  item['internal_url'];
      if(nav.indexOf('/apply') > -1 ){
         nav = 'specials-promo/'+item['internal_url'];
      }else {
        //nav = item['internal_url'];
      }
      
      if(nav.charAt(0) == '/'){
        nav = nav.replace('/', '');
      }
      if(nav.indexOf('/apply') > -1){
        let urlArr = nav.split('apply');
        let promocode = urlArr[0];
        if(nav.charAt(promocode.length-1) == '/'){
          promocode = promocode.replace('/', '');
        }
     //   ga('send', 'event', { eventCategory: item.offer_title , eventLabel: promocode, eventAction: (item.offer_button_text)?'Btnclick '+item.offer_button_text: 'Btnclick Book Now'});
      }else {
      //  ga('send', 'event', { eventCategory: item.offer_title , eventLabel: 'Icon Specials All Deals', eventAction: (item.offer_button_text)?'Btnclick '+item.offer_button_text: 'Btnclick Book Now'});
      }
      if( nav.indexOf('?') > -1 && nav.indexOf('/monthly') > -1 ){
         let urlArr = nav.split('?');
         let url  = urlArr[0];
         this.router.navigate([url], {queryParams: {coupontype:'monthly'}});
      }else {
        this.router.navigate([nav]);
      }
     
    }
    if( item['button_redirection_type'] =='internal_search' ){
    //  ga('send', 'event', { eventCategory: item.offer_title , eventLabel: 'Icon Specials All Deals', eventAction: (item.offer_button_text)?'Btnclick '+item.offer_button_text: 'Btnclick Book Now'});

      if( item['latitude_for_search_page'] !='' && item['longitude_for_search_page'] !='' && item['location_for_search_page'] !='' ){
        let lat = parseFloat(item['latitude_for_search_page']);
        let long = parseFloat(item['longitude_for_search_page']);
        let location = item['location_for_search_page'];
        let searchType = 'daily';
        if(item['search_type'] !='' ) { searchType = item['search_type'] }
       // console.log('/search', lat, long, this.formonthly(), 1,  location,searchType, CustomCode.searchMode())
        this.router.navigate(['/search', lat, long, this.formonthly(), 1,  location,searchType, CustomCode.searchMode()]);

      }else {
        let lat = 40.7782667
        let long = -73.9698797
        let location = 'Times Square';
        let searchType = 'daily';
        if(item['search_type'] !='' ) { searchType = item['search_type'] }

        this.router.navigate(['/search', lat, long, this.formonthly(), 1,  location,searchType, CustomCode.searchMode()]);
      }
  }
}



formonthly()
  {
    let startDuration:any = FilterHelper.defaultStartDuration();
      let startTime = FilterHelper.getFormattedTime(startDuration);
      localStorage.setItem('monthlyDate',startDuration)
      let endDuration = FilterHelper.defaultEndDuration();
      let endTime = FilterHelper.getFormattedTime(endDuration);
      let endTimeDataIfLess = FilterHelper.makeDurationDateObject(endDuration,endTime);
      //alert(endTimeDataIfLess)
     // alert(startDuration);
      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(endDuration,endTime));
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(startDuration,startTime));
      let stime =localStorage.getItem('startimehome');
      return stime;
      //this.router.navigate(['/search', '40.7782667', '-73.9698797', stime, 1,  'central-cark','monthly', CustomCode.searchMode()]);
  }

}
