import { Component, OnInit,TemplateRef,Pipe, PipeTransform, ViewChild, ElementRef } from '@angular/core';

import { Router, ActivatedRoute, Params } from '@angular/router';

import { SafeResourceUrl } from '@angular/platform-browser';

//import * as moment from 'moment-timezone';
import { NgForm } from '@angular/forms';
declare var google: any;
import { CustomCode } from '../../shared/CustomCode';
import { FormValidationErrors } from '../../shared/formValidationErrors';
import * as $ from 'jquery';
import { UserserviceService } from '../../services/userservice.service';
import { ReservationService } from '../../services/reservation.service';
import { SearchpageserviceService } from '../../services/searchpageservice.service';

import { Crypto } from '../../classes/crypto';
import { FilterHelper } from '../../classes/filter-helper';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
declare var gtag:Function;
declare var gtag_report_conversion : Function;
//declare var kochava:any;

@Component({
  selector: 'app-buy-monthly-campaign',
  templateUrl: './buy-monthly-campaign.component.html',
  styleUrls: ['./buy-monthly-campaign.component.scss'],
  providers: [UserserviceService, ReservationService, SearchpageserviceService, Crypto]
})
export class BuyMonthlyCampaignComponent implements OnInit {

  @ViewChild('PromocodePopup',{static:true}) PromocodePopup: ElementRef;

  phoneNumber ='tel:888-705-7845';
  infoIcon =  'assets/img/info.svg';
  cardSecurityIcon ='assets/img/cardSecurity.png';
  calendarIcon = '/assets/img/calendar.svg';
  clockIcon =  '/assets/img/clock.svg';
  helpIcon =  '/assets/img/help.svg'

  gtmUrl:SafeResourceUrl = window.location.pathname;
  formData: any = {};
  loginData: any = {};
  respdata;
  monthlyRate;
  parseData;
  emailRegex = CustomCode.emailRegex;
  phoneMask: any = CustomCode.phoneMask;
  loggedUserName;
  facId;
  monthlyDate: any = {};
  newMonthlyDate: any = {};
  minDate: Date;
  months: any = [];
  years: any = [];
  facilityImage : any ='';
  isCollapsed: boolean = false;
  selectedvahicletype:any;
  selectedvahicletype_Id:any;
  public modalRef: BsModalRef;
  garageWorkingHours;
  FgarageWorkingHours;
  isFrame = false;
  searchTypeMonthly:boolean= true;
  getZipCode= '';
  getFacilityName = '';
  getFacilityId = '';
  monthlySpecialPrice;
  monthlySpecialNetPrice;
  monthlySpecialTaxPrice;
  IsMonthlyCampaign;
  reservationDataEventLog:any;
  rateDetails:any='00.00';
  rateDetailsNetRate:any='00.00';
  taxForThis:any='00.00';
  dateMask: any = [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/];
  cardMask: any = [
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
  ];
  constructor(private activatedRouteObj: ActivatedRoute,
    private routerObj: Router,
    private userServiceObj: UserserviceService,
    private reservationServObj: ReservationService,
    private searchPageServiceObj: SearchpageserviceService,
    private cryptoObj: Crypto,
    private toasterObj: ToastrService,
    private modalService: BsModalService
  ) { }

  public openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template);
	}
  

  ngOnInit() {
    // setTimeout(() => {
    //   $('body').addClass('showChat');
    // },1000);

      if( !( localStorage.getItem('currentLatitude') && localStorage.getItem('currentLongitude')  )) {
          if( navigator.geolocation ) {
            navigator.geolocation.getCurrentPosition(function(position){ 
              localStorage.setItem('currentLatitude', position.coords.latitude.toString());
              localStorage.setItem('currentLongitude', position.coords.longitude.toString());
            });
            } else  {  //alert("Sorry, your browser does not support geolocation services.");
            }
      }

    let times_run= setInterval(function () {
      $('body').addClass('showChat');
    }, 4000);
    setTimeout(function () {
      clearInterval(times_run);
    }, 10000);

      this.IsMonthlyCampaign = sessionStorage.getItem('isMonthlyCampaign');

      if(this.IsMonthlyCampaign != '1'){
        this.routerObj.navigate(['/search']);
      }

    this.monthlySpecialPrice = sessionStorage.getItem('campaignMonthlyRate');
    this.monthlySpecialNetPrice = sessionStorage.getItem('campaignNetPrice');
    this.monthlySpecialTaxPrice = sessionStorage.getItem('campaignTaxPrice');

    if(window.self!==window.top)
    {
      this.isFrame = true;
    }else{
      this.isFrame = false;
    }
    this.minDate = new Date();
    var offsetTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //alert(offset);
    //moment.tz.setDefault(offsetTimezone)//('America/New_York');
    //moment.tz.setDefault('America/New_York');
    this.minDate.setDate(this.minDate.getDate());
    this.activatedRouteObj.params.subscribe((params: Params) => {
      this.facId = +params['facId'];
      const selectedD = FilterHelper.formatPaymentMonthlyDate(params['facType']);
      this.newMonthlyDate = selectedD;
      this.monthlyDate = params['facType']//moment(params['facType']).format('YYYY-MM-DD');
      this.searchPageServiceObj.getFacilityDetails(this.facId).then(
        (res) => {
          this.respdata = res;
          this.respdata = this.respdata.data;
          this.getFacilityName = this.respdata.facility_name_modified;
          this.getFacilityId = this.respdata.id;
          if(sessionStorage.getItem('selectedvahicletype'))
          {
            this.selectedvahicletype = sessionStorage.getItem('selectedvahicletype');
          }else{
            this.selectedvahicletype = '';
          }
                    
          if(this.respdata.photos != null) {
            this.facilityImage = this.respdata.photos.url;
          } else {
            this.facilityImage = '/assets/img/park_garage.png';
          }
          if(this.selectedvahicletype=='')
          {
            this.sortArray(this.respdata.facilityMonthlyTaxRates)
            for(let data of this.respdata.facilityMonthlyTaxRates ){
                this.selectedvahicletype=data.rate_description.name
                this.rateDetails=data.rate;
                this.rateDetailsNetRate=data.net_rate
                this.taxForThis=data.tax_rate
                this.selectedvahicletype_Id = data.id;
                break;
            }
          }
          for(let data of this.respdata.facilityMonthlyTaxRates ){
           if(data.rate_description.name == this.selectedvahicletype)
           {
             this.rateDetails=data.rate;
             this.rateDetailsNetRate=data.net_rate
             this.taxForThis=data.tax_rate
             this.selectedvahicletype_Id = data.id;
             break;
           }
          }
        },
        (error) => { console.log('Error: ' + JSON.stringify(error)); }
      );
      CustomCode.manageFormLabelsPosition();
      this.loggedUserName = CustomCode.getloggedInUserDetails();
      if (this.loggedUserName === '') {
        $('#secondloginbtn').removeClass('hidden');
        // $('#secondlogininfo').removeClass('hidden');
      } else {
       $('#secondloginbtn').addClass('hidden');
       // $('#secondlogininfo').addClass('hidden');
      }
      CustomCode.displayLoginUser();
    });
   // CustomCode.loadMapOnInit();
    this.getMonthAndYear();

    // get hours from api

    
    this.updateFacilitySchedule(this.facId);
    this.updateFacilityScheduleFormatted(this.facId);

   // respdata.facility_rate
    //if(this.selectedvahicletype == ){}
   

  }
  checkdate()
  {
    //this.monthlyDate = moment(this.newMonthlyDate).format('YYYY-MM-DD');
    this.monthlyDate = this.newMonthlyDate;
    //alert( this.monthlyDate);
     //
  }
  sortArray(array)
  {
    if(!array || array === undefined || array.length === 0) return null;
  
      array.sort((a: any, b: any) => {
        if (parseFloat(a.net_rate.replace(',','')) < parseFloat(b.net_rate.replace(',',''))) {
          return -1;
        } else if (parseFloat(a.net_rate.replace(',','')) > parseFloat(b.net_rate.replace(',',''))) {
          return 1;
        } else {
          return 0;
        }
      });
      return array;
  }
  getRate(rateDetails,rateDetailsNetRate,tax,name)
  {
    this.rateDetails=rateDetails
    this.rateDetailsNetRate=rateDetailsNetRate
    this.taxForThis=tax
    this.selectedvahicletype=name
    for(let data of this.respdata.facilityMonthlyTaxRates ){
      if(data.rate_description.name == this.selectedvahicletype)
      {
        this.selectedvahicletype_Id = data.id;
        break;
      }
     }
  }

  callMonthlyCustomer()
  {
   // (function(a,e,c,f,g,h,b,d){var k={ak:"876742851",cl:"oEyECNqX9nsQw5GIogM",autoreplace:"(888) 705-7845"};a[c]=a[c]||function(){(a[c].q=a[c].q||[]).push(arguments)};a[g]||(a[g]=k.ak);b=e.createElement(h);b.async=1;b.src="//www.gstatic.com/wcm/loader.js";d=e.getElementsByTagName(h)[0];d.parentNode.insertBefore(b,d);a[f]=function(b,d,e){a[c](2,b,k,d,null,new Date,e)};a[f]()})(window,document,"_googWcmImpl","_googWcmGet","_googWcmAk","script");
    gtag('config', 'AW-876742851/oEyECNqX9nsQw5GIogM', {
      'phone_conversion_number': '(888) 705-7845'
    });
  }
	restrictNumeric(event) {
		CustomCode.restrictNumericInCard(event);
	  }
  getMonthlyDate() {
    if ($('#monthlydate').val().toString().trim() !== '') {
      const mD = $('#monthlydate').val().toString().split('/');
      const fDate = mD[2] + '-' + mD[0] + '-' + mD[1];
      /* this.monthlyDate = fDate; */
    }
    $('#dpicker').css('display', 'block');
  }

  redirectToSignUp() {
    $('button.close').trigger('click');
    this.routerObj.navigate(['/create-account']);
  }

  continueAsGuest() {
    $('button.close').trigger('click');
    const userLat = CustomCode.timeSquareCoords.lat;
    const userLong = CustomCode.timeSquareCoords.long;
    const locationUser = CustomCode.timeSquareCoords.defaultLocation;
    this.routerObj.navigate(['/search', userLat, userLong, CustomCode.getDateForMapSearch(), CustomCode.facilityDefaultRadius, locationUser, CustomCode.defaultFacilityType, CustomCode.searchMode()]);
  }

  searchLocation() {
    let userLat = (<HTMLInputElement>document.getElementById('userlat')).value;
    let userLong = (<HTMLInputElement>document.getElementById('userlong')).value;
    let locationUser = (<HTMLInputElement>document.getElementById('locationname')).value;

    if (!userLat || !userLong || !locationUser) {
      userLat = CustomCode.timeSquareCoords.lat;
      userLong = CustomCode.timeSquareCoords.long;
      locationUser = CustomCode.timeSquareCoords.defaultLocation;
    }
    /* alert(userLat + '==' + userLong + '==' + CustomCode.getDateForMapSearch() + '==' + CustomCode.facilityDefaultRadius + '==' + locationUser + '==' + CustomCode.defaultFacilityType); */
    this.routerObj.navigate(['/search', userLat, userLong, CustomCode.getDateForMapSearch(), CustomCode.facilityDefaultRadius
    , locationUser, CustomCode.defaultFacilityType, CustomCode.searchMode()]);
  }
  redirecttoList(){
    this.routerObj.navigate(['/', 'oversized']);
    this.modalRef.hide();
  }

  signInUser(formValues, formObj: NgForm) {
    this.userServiceObj.loginUser(formValues)
      .subscribe(
      data => {
        this.parseData = data;
        if (this.parseData.status === 201) {
          CustomCode.markLoginUser(this.parseData.data);
          this.loggedUserName = CustomCode.getloggedInUserDetails();
          if (this.loggedUserName === '') {
            $('#secondloginbtn').css('display', 'block');
          } else {
            $('#secondloginbtn').css('display', 'none');
          }
          CustomCode.displayLoginUser();
          $('button.close').trigger('click');
          $('#secondloginbtn').css('display', 'none');
          formObj.resetForm();

        }
      },
      error => {
        /* FormValidationErrors.displayErrors(error.errors); */
        const errorsList = error.errors;
        if (errorsList.message !== 'Validation Errors') {
          this.toasterObj.error(errorsList.message, 'Sorry!');
        } else {
          for (const key in errorsList.detail) {
            if (errorsList.detail.hasOwnProperty(key)) {
              if (typeof errorsList.detail === 'undefined') {
                this.toasterObj.error(errorsList.message, 'Sorry!');
              } else {
                this.toasterObj.error(errorsList.detail[key], 'Sorry!');
              }
            }
          }
        }
      }
      );
  }

  logOut() {
    CustomCode.userLogout();
    CustomCode.displayLoginUser();
    $('#secondloginbtn').css('display', 'block');
    this.routerObj.navigate(['/']);
  }

  UpdatePromocodeStatus(promocodeStatus,text){
    this.modalRef.hide();
    this.monthlyPayment(promocodeStatus,text,0)
    
  }

  monthlyPayment(formValue, formObj: NgForm,val) {
    
    this.IsMonthlyCampaign = sessionStorage.getItem('isMonthlyCampaign');
    if(this.IsMonthlyCampaign != '1'){
        return false;
    }


      let user_id_ai='Unknown';
			if(localStorage.getItem('userid'))
			{
				user_id_ai=localStorage.getItem('userid');
			}else{
				user_id_ai='Unknown';
      }
			(<any>window).dataLayer.push(
				{
				'event': 'BuyMonthlyAccount_by_campaign',
					'UserId' :user_id_ai,
					'Facility': [{
					  'name': this.respdata.display_name,                      // Name or ID is required.
					  'id': this.respdata.id,
					  'price': this.monthlySpecialPrice,
					  'category': 'Monthly',
					 }]
      })
      
      // kochava.activity('BuyMonthlyAccount_by_campaign', {
      //   user_name: this.respdata.display_name,                      // Name or ID is required.
			// 	id: this.respdata.id,
			// 	price: this.monthlySpecialPrice,
      //   category: 'Monthly',
      //   user_id :user_id_ai,
      //   });
    const reg =/<(.|\n)*?>/g; 
    if (reg.test(formValue.firstname) == true) {
      this.toasterObj.error('HTML Tags are not allowed in First Name.', 'Sorry!');
      return false;
    } else {
        if (reg.test(formValue.lastname) == true) {
          this.toasterObj.error('HTML Tags are not allowed in Last Name.', 'Sorry!');
          return false;
        } else{
          if (reg.test(formValue.phone) == true) {
            this.toasterObj.error('HTML Tags are not allowed in Phone.', 'Sorry!');
            return false;
          } else {
            if (reg.test(formValue.companyname) == true) {
              this.toasterObj.error('HTML Tags are not allowed in Company Name.', 'Sorry!');
              return false;
            }else{
              if (reg.test(formValue.address1) == true) {
                this.toasterObj.error('HTML Tags are not allowed in Address.', 'Sorry!');
                return false;
              }
              else{
                if (reg.test(formValue.address2) == true) {
                  this.toasterObj.error('HTML Tags are not allowed in Address.', 'Sorry!');
                  return false;
                }
                else{
                  if (reg.test(formValue.city) == true) {
                    this.toasterObj.error('HTML Tags are not allowed in City.', 'Sorry!');
                    return false;
                  }
                  else{
                    if (reg.test(formValue.state) == true) {
                      this.toasterObj.error('HTML Tags are not allowed in state.', 'Sorry!');
                      return false;
                    }
                    else{
                      if (reg.test(formValue.zipcode) == true) {
                        this.toasterObj.error('HTML Tags are not allowed in zipcode.', 'Sorry!');
                        return false;
                      }
                      else{
                        if (reg.test(formValue.vehiclemake) == true) {
                          this.toasterObj.error('HTML Tags are not allowed in vehicle make.', 'Sorry!');
                          return false;
                        }
                        else{
                          if (reg.test(formValue.vehiclemodel) == true) {
                            this.toasterObj.error('HTML Tags are not allowed in vehicle model.', 'Sorry!');
                            return false;
                          }
                          else{
                            if (reg.test(formValue.licenceplate) == true) {
                              this.toasterObj.error('HTML Tags are not allowed in licence plate.', 'Sorry!');
                              return false;
                            }
                            else{
                              if (reg.test(formValue.vehiclecolor) == true) {
                                this.toasterObj.error('HTML Tags are not allowed in vehicle color.', 'Sorry!');
                                return false;
                              }
                              else{
                                if (reg.test(formValue.driver1) == true) {
                                  this.toasterObj.error('HTML Tags are not allowed in driver.', 'Sorry!');
                                  return false;
                                }
                                else{
                                  if (reg.test(formValue.driver2) == true) {
                                    this.toasterObj.error('HTML Tags are not allowed in driver.', 'Sorry!');
                                    return false;
                                  }
                                  else{
                                    if (reg.test(formValue.driver3) == true) {
                                      this.toasterObj.error('HTML Tags are not allowed in driver.', 'Sorry!');
                                      return false;
                                    }
                                    else{
                                      if (reg.test(formValue.name_on_card) == true) {
                                        this.toasterObj.error('HTML Tags are not allowed in name on card.', 'Sorry!');
                                        return false;
                                      }
                                      else{
                                        if (reg.test(formValue.promocode) == true) {
                                          this.toasterObj.error('HTML Tags are not allowed in promocode.', 'Sorry!');
                                          return false;
                                        }
                                        else{
                                          if (reg.test(formValue.cardzipcode) == true) {
                                            this.toasterObj.error('HTML Tags are not allowed in Card Zip code.', 'Sorry!');
                                            return false;
                                          }
                                          else{
                                            //return true;
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
    }
if(formValue.phone != undefined && formValue.phone != ''){
  let ph = formValue.phone.replace(/\D/g, '');
    if(ph.length !==0 && ph.length < 10){
      formObj.form.controls['phone'].setErrors({'invalid': true});
      return false;
  }
}
if(formValue.promocode && val==1){

  let el: HTMLElement = this.PromocodePopup.nativeElement as HTMLElement;
    el.click();
    return false;
}
this.getZipCode = formValue.zipcode;
const cryptoData = this.cryptoObj.encrypt({'name_on_card': formValue.name_on_card, 'card_number': formValue.card.replace(/\D/g, ''), 'expiration_date': formValue.expiration_date + '' + formValue.expiration_year, 'security_code': formValue.security_code, 'zip_code': formValue.cardzipcode});
    const mRate = (<HTMLInputElement>document.getElementById('mrate')).value;
    this.reservationServObj.makeMonthlyReservationForCampaign(formValue, this.monthlySpecialPrice, this.IsMonthlyCampaign, this.selectedvahicletype_Id, this.facId, cryptoData, this.monthlyDate)
    .subscribe(
    data => {
         this.parseData = data['data'];//buy-monthly/thanks/:facId
	  //tracking_code
	  CustomCode.markTrackingCode(this.parseData.tracking_code);
    gtag_report_conversion(this.gtmUrl);

    gtag('event', 'conversion', {
      'send_to': 'AW-876742851/p-W2CPCis3UQw5GIogM',
       'eventCallback':window['callbackgtm'],
    });

    // kochava.activity('conversion_Monthly_by_campaign', {
    //   user_name: formValue.display_name,                      // Name or ID is required.
		// 	 id: formValue.id,
		// 	 price: this.monthlySpecialPrice,
    //    category: 'Monthly',
    //    user_id :user_id_ai,
		//   });
      let errorMsg = '';
      this.updateReservationEventLogStatus( this.getFacilityId, this.getFacilityName, this.getZipCode, errorMsg);

      sessionStorage.removeItem('campaignMonthlyRate');
      sessionStorage.removeItem('campaignNetPrice');
      sessionStorage.removeItem('campaignTaxPrice');
      sessionStorage.removeItem('isMonthlyCampaign');
	  	  this.routerObj.navigate(['/buy-monthly-parking/thanks',this.parseData.facility_id,this.parseData.id]);
	  
    },
    error => {
      let errorMsg = '';
      /* FormValidationErrors.displayErrors(error.errors); */
      const errorsList = error.errors;
      if (errorsList.message !== 'Validation Errors') {
        this.toasterObj.error(errorsList.message, 'Sorry!');
        errorMsg = errorsList.message;
      } else {
        for (const key in errorsList.detail) {
          if (errorsList.detail.hasOwnProperty(key)) {
            if (typeof errorsList.detail === 'undefined') {
              this.toasterObj.error(errorsList.message, 'Sorry!');
              errorMsg = errorsList.message;
            } else {
              this.toasterObj.error(errorsList.detail[key], 'Sorry!');
              errorMsg = errorsList.detail[key];
              errorMsg =errorMsg[0];
            }
          }
        }
      }
      this.updateReservationEventLogStatus( this.getFacilityId, this.getFacilityName, this.getZipCode, errorMsg);
    }
    );
  }

  showPicker() {
    $('#dpicker').css('display', 'block');
  }

  getMonthAndYear() {
    var fullYear = new Date().getFullYear();
    for (var i = 0; i < 15; i++) {
        this.years.push({
          label: fullYear + i,
          value: parseInt(String(fullYear + i).slice(2, 4))
        });
    }
    var month = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
    for (var i = 1; i <= 12; i++) {
        
        this.months.push({
          label: month[i-1] ,
          value: String("00" + i).slice(-2)
        });
      
    }
  }


  updateFacilitySchedule(id) {
		this.searchPageServiceObj.getFacilitySchedule(id).then(
			(res) => {
			  this.garageWorkingHours = res;
			  this.garageWorkingHours = this.garageWorkingHours.data;
			},
			(error) => { 

      }
		);
  }
  updateFacilityScheduleFormatted(id) {
		this.searchPageServiceObj.getFacilityScheduleFormatted(id).then(
			(res) => {
			  this.FgarageWorkingHours = res;
			  this.FgarageWorkingHours = this.FgarageWorkingHours.data;
			},
			(error) => { 

      }
		);
  }
  
  restSpace(event: any){
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }

  restDigit(event: any){
    if (event.target.value.length > 1 && event.keyCode != 8 && event.keyCode != 9) {
      event.preventDefault();
      this.toasterObj.error('Please enter valid two digit state code.', 'Sorry!');
      return false;
    }else{
      return true;
    }
  }

  checkFormat(value)
  {
    if(value.toString().length==7)
    {
      let a = value.toString();;
      let b = ",";
      let position = 1;
      let output = [a.slice(0, position), b, a.slice(position)].join('');
      return output
    }else{
      return value;
    }
   
  }
  phoneFormat(event: any) {
    const pattern = /[0-9\)\-\(\.\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar) && event.keyCode != 9) {
      event.preventDefault();
    }
  }

  updateReservationEventLogStatus(facility_id, facility_name, getZipCode, errorMsg ){
            let currentLatitude = (localStorage.getItem('currentLatitude')) ? localStorage.getItem('currentLatitude') : '';
            let currentLongitude = (localStorage.getItem('currentLongitude')) ? localStorage.getItem('currentLongitude') : '';
            let getUserId='0';
            if(localStorage.getItem('userid')){  
               getUserId=localStorage.getItem('userid'); }
            else{ getUserId='0'; } 
            let reservation_status_data = {
               user_id:getUserId,
               event_name:"Facility Booked with " +'$'+this.monthlySpecialNetPrice,
               latitude:currentLatitude,
               longitude:currentLongitude,
               facility_id:facility_id,
               facility_name:facility_name,
               zipcode:getZipCode,
               app_version:"",
               error_message:errorMsg,
               device:"web",
               device_detail:"web",
               os_version:""
               }
        
        this.reservationServObj.updateReservationEventLog(reservation_status_data).subscribe(
          data => {
          this.reservationDataEventLog = data
        },
        error => {

        }
      )
    }
    licenceplateFormat(event:any){
      const pattern = /^[a-zA-Z0-9-]/;
      let inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar) ) {
        event.preventDefault();
      }
  }

}
