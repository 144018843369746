import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { StaticpagesService } from '../../services/staticpages.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-frequently-asked-questions',
  templateUrl: './frequently-asked-questions.component.html',
  styleUrls: ['./frequently-asked-questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [StaticpagesService],
})

export class FrequentlyAskedQuestionsComponent implements OnInit {

  pageId:string = 'frequently-asked-questions'; 
  cmsPageContent:any = {};
  sanatizeContent;
  isLoaded = false;

  @Input() currentActive: string;
  @Input() accountNumber: any;

  constructor( private staticPageObj: StaticpagesService, private sanitized: DomSanitizer, private router:Router ) { }

  ngOnInit() {
    this.staticPageObj.getCmsPageContent(this.pageId).subscribe(
      (data:any) => {
        this.cmsPageContent = data.result[0];
        this.sanatizeContent = this.sanitized.bypassSecurityTrustHtml(this.cmsPageContent.post_content);
        this.isLoaded = true;
      }, error => console.log("e" + error.object),
      
    )

  }

  getRouteWithAccountNumber(event){
    var routeUrl = event.target.getAttribute('routerlink');
    var goRoute = routeUrl.replace('accountNumber', this.accountNumber);
    this.router.navigate([goRoute]);
  }

}
