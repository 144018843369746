import { Injectable } from '@angular/core';

//import { Http, Headers, RequestOptions, Response, ResponseContentType } from '@angular/http';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {HttpService} from './http.service';

import { CustomCode } from '../shared/CustomCode';

// Import RxJs required methods
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GcouponService {
	
	constructor(private http: HttpClient) { }

	getCouponDetails(couponUrl)
	{	  
	  const headers = new Headers();
      const access_token = localStorage.getItem('access_token');
      const bigApple = localStorage.getItem('X-Big-Apple');
      headers.append('AuthorizationOkta', 'Bearer ' + access_token);
      headers.append('X-Big-Apple',  bigApple);
      // const options = new RequestOptions({ headers: headers });
      
      //const options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
      return  this.http.get(couponUrl,{ responseType: 'blob',headers: new HttpHeaders({'OktaTokenType':  'web','AuthorizationOkta':  access_token,'X-Big-Apple':  bigApple})}).map((res: any) => res);
  
	}
}