import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit {
  app_icon = 'assets/img/app_icon.svg';
  AppStoreIcon =  'assets/img/AppStore.svg';
  google_playIcon = 'assets/img/google-play.svg';
  dollar_icon = 'assets/img/dollar_icon.svg';
  discount_icon =  'assets/img/discount_icon.svg';
  arrowsIcon = 'assets/img/arrows.svg';
  starIcon = 'assets/img/star.svg'

  constructor() { }

  ngOnInit() {
  }

}
