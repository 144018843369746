import { Component, OnInit } from '@angular/core';
// declare var ga: Function;
import * as $ from 'jquery';

@Component({
  selector: 'winter-popup',
  templateUrl: './winter-popup.component.html',
  styleUrls: ['./winter-popup.component.scss']
})
export class WinterPopupComponent implements OnInit {
  winterflake = 'assets/img/Snowflakes-min.gif';
  arrowsIcon ='assets/img/cross-white.svg'
  showpopup=false;
  showtext:boolean=false;
  constructor() { }

  ngOnInit() {
    
    if(localStorage.getItem('winterSpecialDontShow'))
    {
      this.showpopup=false;
    }else if(localStorage.getItem('winterSpecialDontShowDate'))
    {
      
      this.showpopup=false;
      //alert(new Date(localStorage.getItem('winterSpecialDontShowDate')));
      const dt = new Date(localStorage.getItem('winterSpecialDontShowDate'))
       dt.setMinutes(dt.getMinutes() + 150);
        if(new Date()>new Date(dt))
        {
          this.showpopup=true;
          localStorage.removeItem('winterSpecialDontShowDate')
        }
    }
    else{
      $('body').addClass('noserch-scroll');
      this.showpopup=true;
    }
  }

  bgclick(){
    //alert('use this for bg event');
  }

  dontshowagain(data)
  { //alert(this.showtext)
    $('body').removeClass('noserch-scroll');
    if(data==3){
      (<any>window).dataLayer.push(
        {
           'event': 'userAction',
           'lable': 'User closed the POP UP',
           'pageCategory': 'Winter Special - Popup Banner',
       });
      if(!this.showtext)
      {
        this.showpopup=false;
        localStorage.setItem('winterSpecialDontShowDate',new Date().toString())
        localStorage.removeItem('winterSpecialDontShow')
      }else{
        this.showtext=false;
      }
      
    }
    else if(data==2)
    {
      (<any>window).dataLayer.push(
        {
           'event': 'userAction',
           'lable': 'Dont show again POP UP',
           'pageCategory': 'Winter Special - Popup Banner',
       });
      this.showpopup=false;
      localStorage.setItem('winterSpecialDontShow','1')
      localStorage.removeItem('winterSpecialDontShowDate')
    }else{
      (<any>window).dataLayer.push(
        {
           'event': 'userAction',
           'lable': 'User closed the POP UP',
           'pageCategory': 'Winter Special - Popup Banner',
       });
      this.showpopup=false;
      localStorage.setItem('winterSpecialDontShowDate',new Date().toString())
      localStorage.removeItem('winterSpecialDontShow')
    }
    
  }
  dontshowagainTrack(data,text)
  {
    $('body').removeClass('noserch-scroll');
    if(text=='desktop')
    {
      (<any>window).dataLayer.push(
        {
           'event': 'userAction',
           'lable': 'User redirect to monthly account information page',
           'pageCategory': 'Winter Special - Popup Banner',
       });
     // ga('send', 'event', { eventCategory: 'Winter Special - Popup Banner', eventAction: 'Click'});
      sessionStorage.setItem('winterlabel', 'Winter Special stripe');
    }else{
      (<any>window).dataLayer.push(
        {
           'event': 'userAction',
           'lable': 'User click on mobile number',
           'pageCategory': 'Winter Special - Popup Banner',
       });
    //  ga('send', 'event', { eventCategory: 'Winter special pop up call ', eventAction: 'Click'});
    }
    if(data==2)
    {
      this.showpopup=false;
      localStorage.setItem('winterSpecialDontShow','1')
      localStorage.removeItem('winterSpecialDontShowDate')
    }else{
      this.showpopup=false;
      localStorage.setItem('winterSpecialDontShowDate',new Date().toString())
      localStorage.removeItem('winterSpecialDontShow')
    }
  }
  show()
  {
    //alert(this.showtext)
    this.showtext=true;
    this.showpopup=true;
    localStorage.removeItem('winterSpecialDontShowDate')
    localStorage.removeItem('winterSpecialDontShow')
    
  }
}
