import { Component, OnInit, Pipe, ViewEncapsulation, PipeTransform } from '@angular/core';
import { pipe } from 'rxjs';
import * as $ from 'jquery';

import { StaticpagesService } from './../../services/staticpages.service';
import { DomSanitizer } from '@angular/platform-browser';

declare var google: any;

@Component({
  selector: 'app-hotels',
  templateUrl: './hotels.component.html',
  styleUrls: ['./hotels.component.scss'],
  providers: [StaticpagesService],
  encapsulation: ViewEncapsulation.None

})

// @Pipe({ name: 'safeInnerHtml' })
// export class safeInnerHtmlForBanner implements PipeTransform {
//   constructor( private domSanitizer: DomSanitizer ){ }
//   transform(value){
//     return this.domSanitizer.bypassSecurityTrustHtml(value);
//   } 
// } 
// @Pipe({ name: 'safeHtml'})


// export class safeInnerHtmlForBanner implements PipeTransform  {
//   constructor(public sanitizer: DomSanitizer) {}

//   transform(value) {
//     return this.sanitizer.bypassSecurityTrustHtml(value);
//   }
// }

export class HotelsComponent implements OnInit {
bannerImage = '../../../assets/img/landing-page-hotels/bg.png';
logoOne = '../../assets/img/landing-page-hotels/logo-1.png';
logotwo = '../../assets/img/landing-page-hotels/logo-2.png';
logothree = '../../assets/img/landing-page-hotels/logo-3.png';
logofour = '../../assets/img/landing-page-hotels/logo-5.png';
logofive = '../../assets/img/landing-page-hotels/logo-4.png';
mapicon = '../../assets/img/v8-design/marker_location_small_d.svg';
phoneicon = '../../assets/img/v8-design/phone_icon_d.svg';
markericon = '../../assets/img/v8-design/phone_icon_d.svg';

defaultImage ="../../assets/img/v8-design/map_pointer.png";
activeImage = '../../assets/img/v8-design/map_pointer_active.png';
isLoaded = false;

pageSlug:string = 'hotels';
fetchedData:any = {}
zoomLavel = 13;
  locations = [];
  centerLocationLat: number;
  centerLocationLong: number;
  map: any = [];
  markers_arr: any = [];
  marker: any = [];
  activeMarker
  contentString = ''
  infowindow:any;
  hotelName = '';
  hotelLogo = '';
  hotelAddress = '';
  hotelLink = '';
  dLat: number;
  dLong: number;
  constructor(
    private staticpageservice: StaticpagesService
  ) { }

  ngOnInit() {
    this.infowindow = new google.maps.InfoWindow();
    if ($(window).width() < 767) {
      this.zoomLavel = 12;
    }
    else {
      this.zoomLavel = 13;
    }

    
    this.staticpageservice.getCmsPageContentForHotels(this.pageSlug).subscribe( (data) => {
      this.fetchedData = data['result'];
      if(this.fetchedData){
        this.fetchedData = this.fetchedData[0];

        this.bannerImage = this.fetchedData['_thumbnail_id'];
        this.isLoaded = true;
        this.dLat=  this.centerLocationLat = this.fetchedData.hotels_list[0].location_latitude
        this.dLong = this.centerLocationLong = this.fetchedData.hotels_list[0].location_longnitude

        this.hotelLogo = this.fetchedData.hotels_list[0].logo_image.url
        this.hotelName = this.fetchedData.hotels_list[0].hotel_name
        this.hotelAddress = this.fetchedData.hotels_list[0].address
        this.hotelLink = this.fetchedData.hotels_list[0].website_link
        let i = 0;
        let defaultContentString =
    '<table style="border:none;width:100%; padding:0" border="0"><tr>' +
    '<td ><img width="80px" src="'+this.hotelLogo+'"/></td>'+
    '<td style="padding-left:20px"><h4  style="font-size:20px" class="text-primary">'+this.hotelName+'</h4>'+
    '<p style="color:#000; font-size:14px;" ><img width="12px" src="../../assets/img/v8-design/marker_location_small_d.svg"/> '+this.hotelAddress+'</p>'+
    '<a  style=" font-size:12px;" target="_blank" href="'+this.hotelLink+'" class="text-primary text-primary btn-primary btn btn-fill btn-sm mt-2">Offer Details</a>'+
    '</td'+
  '</tr></table>';
        
        for( let hotel of this.fetchedData.hotels_list ){
          let lat = Number(hotel.location_latitude);
          let long = Number(hotel.location_longnitude);
           this.locations.push([hotel.hotel_name, lat, long, i]);
           i++;
        } let defailtIndex =  0;
        setTimeout( ()=>{ $('.index-0').addClass('active');} , 1000);
        this.map = new google.maps.Map(document.getElementById('hotelSmap'), {
          zoom: this.zoomLavel,
          center: new google.maps.LatLng(this.centerLocationLat, this.centerLocationLong),
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          disableDefaultUI: true,
          zoomControl: true,
          scaleControl: true
        });
        this.loadGoogleMap( this.locations, this.centerLocationLat,  this.centerLocationLong, defailtIndex, defaultContentString);
      }
    },
    ( error => {
      this.isLoaded = true
      console.log('error' + error)
    })
    )
  }

  loadGoogleMap(locations, centerLocationLat, centerLocationLong, index, contentString){
    for (let mi = 0; mi < this.markers_arr.length; mi++) {
      // console.log(this.markers_arr[mi])
      this.markers_arr[mi].setMap(null);
        }
    this.markers_arr = [];
    var i, markers;
    for (i = 0; i < locations.length; i++) { 
      let image = this.defaultImage;
      

      this.map.setCenter({
        lat : centerLocationLat,
        lng : centerLocationLong
      });
      // console.log(i , index  )
      if(index === i){
        //alert(index+' '+i)
        this.marker = new google.maps.Marker({
          position: new google.maps.LatLng(centerLocationLat, centerLocationLong),
          map: this.map,
          icon: this.activeImage,
          zIndex: 1000
        });
      this.markers_arr.push(this.marker);
      this.infowindow.setContent(contentString);
      this.infowindow.open(this.map, this.marker);
      }else {
       // alert(index+' '+i)
        image = this.defaultImage;
        markers = new google.maps.Marker({
          position: new google.maps.LatLng(locations[i][1], locations[i][2]),
          map: this.map,
          icon: image
          
        });
        markers.setValues({ id: i, indexId: i, type: 'daily' });
				((markers, l) => {
          google.maps.event.addListener(markers, 'click', () => {
            this.marker.setMap(null);
            this.dropActiveMarker(markers.getPosition().lat(), markers.getPosition().lng());
            
          })

        })
      (markers, i);
      }
      
      
      
      
      
      

      
    }
  }

  
  dropActiveMarker( lat, long){
    let latClsas = lat.toString().trim().replace('.','').replace('-','');
    let longClsas = long.toString().trim().replace('.','').replace('-','');
    this.map.setCenter({
      lat : lat,
      lng : long
    });
  this.marker =   new google.maps.Marker({
      position: new google.maps.LatLng(lat, long),
      map: this.map,
      icon: this.activeImage,
      zIndex: 1000
    });
    this.markers_arr.push(this.marker);
    for( let hotel of this.fetchedData.hotels_list ){
      let defaultLat = Number(hotel.location_latitude);
      let defaultLong = Number(hotel.location_longnitude);
      if(defaultLat === lat && defaultLong === long ){
        this.hotelLogo = hotel.logo_image.url;
        this.hotelName = hotel.hotel_name;
        this.hotelAddress = hotel.address;
        this.hotelLink = hotel.website_link;
        this.contentString =
    '<table style="border:none;width:100%; padding:0" border="0"><tr>' +
    '<td ><img width="80px" src="'+this.hotelLogo+'"/></td>'+
    '<td style="padding-left:20px"><h4  style="font-size:20px" class="text-primary">'+this.hotelName+'</h4>'+
    '<p style="color:#000; font-size:14px;" ><img width="12px" src="../../assets/img/v8-design/marker_location_small_d.svg"/> '+this.hotelAddress+'</p>'+
    '<a  style=" font-size:12px;" target="_blank" href="'+this.hotelLink+'" class="text-primary text-primary btn-primary btn btn-fill btn-sm mt-2">Offer Details</a>'+
    '</td'+
  '</tr></table>';
  $('.listingPart li').removeClass('active');
  $('.class_'+latClsas).addClass('active');
  this.infowindow.setContent(this.contentString);
      }
    }
     this.infowindow.open(this.map, this.marker);
  }

  redirectToLocation( lat, long, index ){
    console.log(this.markers_arr);
    $('.listingPart li ').removeClass('active');
    $('.index-'+index).addClass('active');
    this.hotelLogo = this.fetchedData.hotels_list[index].logo_image.url
        this.hotelName = this.fetchedData.hotels_list[index].hotel_name
        this.hotelAddress = this.fetchedData.hotels_list[index].address
        this.hotelLink = this.fetchedData.hotels_list[index].website_link
        let updatedContentString =
    '<table style="border:none;width:100%; padding:0" border="0"><tr>' +
    '<td ><img width="80px" src="'+this.hotelLogo+'"/></td>'+
    '<td style="padding-left:20px"><h4  style="font-size:20px" class="text-primary">'+this.hotelName+'</h4>'+
    '<p style="color:#000; font-size:14px;" ><img width="12px" src="../../assets/img/v8-design/marker_location_small_d.svg"/> '+this.hotelAddress+'</p>'+
    '<a  style=" font-size:12px;" target="_blank" href="'+this.hotelLink+'" class="text-primary text-primary btn-primary btn btn-fill btn-sm mt-2">Offer Details</a>'+
    '</td'+
  '</tr></table>';
        
   this.loadGoogleMap(this.locations, lat, long, index,updatedContentString);
  }

  removeSpace(data){
    
    return data.toString().trim().replace('.','').replace('-','');
  }

}
