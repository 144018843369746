import { Directive } from '@angular/core';
import * as $ from 'jquery';
declare var Swiper:any;



@Directive({
  selector: '[appLoadView]'
})
export class LoadViewDirective {

  constructor() { }
  ngOnInit(){


 var mySwiper = new Swiper ('.swiper-container', {
					pagination: '.swiper-pagination',
			        paginationClickable: true,
			        nextButton: '.swiper-button-next',
			        prevButton: '.swiper-button-prev',
			        iOSEdgeSwipeDetection:'true',
			      	slidesPerView: 3,
			      	 autoplay: 2500,
        			autoplayDisableOnInteraction: false,
					breakpoints: {
						1200: {
							slidesPerView: 3,
							spaceBetween: 30
						},
						768: {
							slidesPerView: 1,
							spaceBetween: 20
						},
						320: {
							slidesPerView: 1,
							spaceBetween: 10
						}
					}
				});
}

}