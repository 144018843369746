import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-share-icongo-app',
  templateUrl: './share-icongo-app.component.html',
  styleUrls: ['./share-icongo-app.component.scss']
})
export class ShareIcongoAppComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf("android") > -1;
    if(isAndroid){
      window.location.href = "http://play.google.com/store/apps/details?id=com.google.android.apps.maps";
    }else{
      window.location.href = "https://itunes.apple.com/us/app/icon-go/id394377820?ls=1&mt=8";
    }

  }

}
