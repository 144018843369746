import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-garage-details',
  templateUrl: './app-garage-details.component.html',
  styleUrls: ['./app-garage-details.component.scss']
})
export class AppGarageDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
