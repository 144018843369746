import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationEnd,ActivatedRoute, Params } from '@angular/router';
import { CustomCode } from '../../shared/CustomCode';
import { SearchPageScript } from '../../shared/searchPageScript';
import { FormValidationErrors } from '../../shared/formValidationErrors';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs/Rx';
import * as $ from 'jquery';
declare var google: any;
import { VehiclerequestService } from '../../services/vehiclerequest.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-vehicle-request',
  templateUrl: './vehicle-request.component.html',
  styleUrls: ['./vehicle-request.component.scss'],
  providers: [VehiclerequestService]
})
export class VehicleRequestComponent implements OnInit {

formData: any = {};
isProcess:boolean = false;
private parseData;
activeUser:any = [];
activeMpUser:any = [];
elimiwaitAccountsDetails:any = {};
isLoaded = false;
isCarReqLoaded = false;
dSD:any = [];
reqforval:any = [];
carrequestststatus:any = [];
minDate: Date;
maxDate: Date;
restrictRequestOnInit = 'stop';
currDate = CustomCode.getDateForMapSearch();
currDateObj = new Date();
startTime:any = [];
phoneMask: any = CustomCode.phoneMask;
stime:any = [];

  constructor(private routerObj: Router, private VehiclerequestObj: VehiclerequestService,private toasterObj: ToastrService) { }
  ngOnInit() {
    SearchPageScript.openTimePicker();
    this.fetchUserDetails();
    CustomCode.manageFormLabelsPosition();
    $('.elimiwaitAccount').hide();
    $('#carreqstats').hide();
    $('#AllGarages').show();
    
  }

   getStartDate(elimiid) {
    var sDate = $('#startdate'+elimiid).val();
    let stime = $('#stime'+elimiid).val();
    //var eDate = $('#enddate').val();
   // const facType = $('label.active').attr("id").toLowerCase().trim();
    if(this.restrictRequestOnInit != 'stop') {
     // if(facType == 'daily') {
        //const lengthOfStay = SearchPageScript.getLengthOfStay(sDate, this.stime, eDate, this.etime);
        //if(lengthOfStay > 0) {
          const reservationTime = sDate.toString().split('/');
          const m = reservationTime[1];
          const d = reservationTime[0];
          const y = reservationTime[2];
          const rTime = y + '-' + d + '-' + m +'' + this.changeToFullTime(stime);
          //this.generateMap(this.lat, this.long, this.radius, lengthOfStay, rTime, facType);
        //}
      //}
    }
  }
  
   getStartTime(elimiid) {
    
    var mnts = this.startTime[elimiid].getMinutes() < 10 ? '0' + this.startTime[elimiid].getMinutes() : this.startTime[elimiid].getMinutes();
    var sTime = (this.startTime[elimiid].getHours() > 12) ? (this.startTime[elimiid].getHours()-12 + ':' + mnts +' PM') : (this.startTime[elimiid].getHours() + ':' + mnts +' AM');
  
   
    $('#stime'+elimiid).val(sTime);
    //$('#pstime').val(sTime);
    var sDate = $('#startdate'+elimiid).val();
    //var eDate = $('#enddate').val();
   // const facType = $('label.active').attr("id").toLowerCase().trim();
    //this.stime = sTime;
    //if(facType == 'daily') {
      //const lengthOfStay = SearchPageScript.getLengthOfStay(sDate, this.stime, eDate, this.etime);
      //if(lengthOfStay > 0) {
        const reservationTime = sDate.toString().split('/');
        const m = reservationTime[1];
        const d = reservationTime[0];
        const y = reservationTime[2];
        const rTime = y + '-' + d + '-' + m +'' + this.changeToFullTime(sTime);
        //this.generateMap(this.lat, this.long, this.radius, lengthOfStay, rTime, facType);
      //} 
    /* else {
        alert('end time should be greater than start time');
      } */
    //}
  }
  
 changeToFullTime(time) {
    var date = new Date();
    let hours;
    let minutes;
    let am_pm;
    let newTime = time.split(':');
    hours = newTime[0];
    let newT = newTime[1].split(' ');
    minutes = newT[0];
    am_pm = newT[1];
    if(am_pm == 'PM') {
      let convertToFullDate = ['12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
      hours = convertToFullDate[hours];
    }
    return hours + ':' + minutes + ':00'
    
    
    /* var lblTime = document.getElementById("lblTime");
    lblTime.innerHTML = time; */
};
  
  fetchUserDetails() {
    
     // this.VehiclerequestObj.fetchUser()
      //.then(
       // data => {
         this.activeUser = CustomCode.getloggedInUserId();
         
         this.VehiclerequestObj.fetchMPUser(this.activeUser).then(
        data => { 
        
       
        if(!data['data'].length){
                $('.elimiwaitAccount').hide();  
            $('#AllGarages').show();
            $('#carreqstats').hide();
            this.elimiwaitAccountsDetails='';
            this.carrequestststatus='';
            this.isCarReqLoaded = false;
            this.isLoaded = false;
            }else{
         this.isLoaded = true;
        this.elimiwaitAccountsDetails=data['data'][0].elimiwait_accounts; 
         //alert('test');
       
        for(var i=0;i<this.elimiwaitAccountsDetails.length;i++){
          
          this.dSD[this.elimiwaitAccountsDetails[i].id] = SearchPageScript.defaultDateForDailyMonthlyFacility();
          
          this.startTime[this.elimiwaitAccountsDetails[i].id] = new Date(this.currDateObj.setMinutes(this.currDateObj.getMinutes() + 30));
         
          this.stime[this.elimiwaitAccountsDetails[i].id] = (this.startTime[this.elimiwaitAccountsDetails[i].id].getHours() > 12) ? (this.startTime[this.elimiwaitAccountsDetails[i].id].getHours()-12 + ':' + this.startTime[this.elimiwaitAccountsDetails[i].id].getMinutes() +' PM') : (this.startTime[this.elimiwaitAccountsDetails[i].id].getHours() + ':' + this.startTime[this.elimiwaitAccountsDetails[i].id].getMinutes() +' AM');
          
        }
         this.activeMpUser =data['data'][0];
          if(!this.activeMpUser.id){
            $('.elimiwaitAccount').hide();  
            $('#AllGarages').show();
            $('#carreqstats').hide();
                                    
          }
          else{
            $('.elimiwaitAccount').show();
            $('#AllGarages').hide();  
            $('#carreqstats').show();
                        Observable.interval(10000)
            //.takeWhile(() => !stopCondition)
            .subscribe(i => { 
                     this.VehiclerequestObj.CarRequestStatus(this.activeUser)
            .then(
              data => {
                
                this.isCarReqLoaded = true;
                this.carrequestststatus=data['data'];
              }
            );
              // This will be called every 10 seconds until `stopCondition` flag is set to true
            }); 
                        this.VehiclerequestObj.CarRequestStatus(this.activeUser)
            .then(
              data => {
                
                this.isCarReqLoaded = true;
                this.carrequestststatus=data['data'];
              });           
          } 
          
        }

                      
        });  
         
        //}//,
        //error => {
          // alert(error.errors.message);
        //}
      //);
    }
    
   confirmPick(elimiid){
     
     var sDate = $('#startdate'+elimiid).val();
     let stime = $('#stime'+elimiid).val();
     
     const reservationTime = sDate.toString().split('/');
        const m = reservationTime[1];
        const d = reservationTime[0];
        const y = reservationTime[2];
        const rTime = y + '-' + d + '-' + m +' ' + this.changeToFullTime(stime);
    this.activeUser = CustomCode.getloggedInUserId();
    this.reqforval.pickUpTime=rTime;
     
     
     this.VehiclerequestObj.Confirmpickup(this.reqforval,elimiid)
      .subscribe(
        data => {
        this.parseData = data;
        
        if (this.parseData.status === 201) {
          
          this.toasterObj.success('Add elimiwait account successfully', 'success!');
          $('#carRequestBox'+elimiid).show();
            $('#vehiclepickup'+elimiid).hide();
          this.VehiclerequestObj.CarRequestStatus(this.activeUser)
            .then(
              data => {
                
                this.isCarReqLoaded = true;
                this.carrequestststatus=data['data'];
              }
            );
          }
        },
        error => {
          const errorsList = error.errors;
           if (errorsList.message !== 'Validation Errors') {
          this.toasterObj.error(errorsList.message, 'Sorry!');
          } else {
          for (const key in errorsList.detail) {
          if (errorsList.detail.hasOwnProperty(key)) {
            if (typeof errorsList.detail === 'undefined') {
              this.toasterObj.error(errorsList.message, 'Sorry!');
            } else {
              this.toasterObj.error(errorsList.detail[key], 'Sorry!');
            }
            }
          }
          }
         
        }
        );
     }
   
    requestCar(elimiid){
    //$('#carRequestBox'+elimiid).toggleClass('hidden');
       // $('#vehiclepickup'+elimiid).toggleClass('hidden');
    
    for(var i=0;i<this.elimiwaitAccountsDetails.length;i++){
      
       if(this.elimiwaitAccountsDetails[i].id==elimiid){
         $('#vehiclepickup'+elimiid).show();
         $('#carRequestBox'+elimiid).hide();
         }else{
         var clid=this.elimiwaitAccountsDetails[i].id;
         $('#carRequestBox'+clid).show();
         $('#vehiclepickup'+clid).hide();
       }
    }
     }
   
   cancelRequestCar(elimiid){
    //$('#carRequestBox'+elimiid).toggleClass('hidden');
       // $('#vehiclepickup'+elimiid).toggleClass('hidden');
    
    for(var i=0;i<this.elimiwaitAccountsDetails.length;i++){
      
       //if(this.elimiwaitAccountsDetails[i].id==elimiid){
         ///$('#vehiclepickup'+elimiid).show();
        // $('#carRequestBox'+elimiid).hide();
         //}else{
         var clid=this.elimiwaitAccountsDetails[i].id;
         $('#carRequestBox'+clid).show();
         $('#vehiclepickup'+clid).hide();
       //}
    }
     }

  addMonthlyVehicle(){
        $('.addMonthlyVehicle').addClass('hidden');
        $('#addMonthlyVehicleContainer').slideToggle();
     }
     addMonthlyVehiclecancel(){
      $('.addMonthlyVehicle').removeClass('hidden');
      //$('.addMonthlyVehicle').addClass('hidden');
      $('#addMonthlyVehicleContainer').slideToggle();
   }
   
  addMonthly(formValues, formObj: NgForm){
    this.isProcess = true;
    this.activeUser = CustomCode.getloggedInUserId();
    formValues.mp_user_id=this.activeMpUser.id;
    this.VehiclerequestObj.AddVehiclerequest(formValues)
      .subscribe(
      data => {
        this.parseData = data;
    
        if (this.parseData.status === 201) {
      this.isProcess = false;
      formObj.resetForm();
      this.toasterObj.success('Add elimiwait account successfully', 'success!');
      $('.addMonthlyVehicle').removeClass('hidden');
      $('#addMonthlyVehicleContainer').slideToggle();
      
    this.VehiclerequestObj.fetchMPUser(this.activeUser).then(
        data => {
                  if(!data['data'].length){
                $('.elimiwaitAccount').hide();  
            $('#AllGarages').show();
            $('#carreqstats').hide();
            this.elimiwaitAccountsDetails='';
            this.carrequestststatus='';
            this.isCarReqLoaded = false;
            this.isLoaded = false;
            }else{          
        this.isLoaded = true;
        this.elimiwaitAccountsDetails=data['data'][0].elimiwait_accounts; 
             for(var i=0;i<this.elimiwaitAccountsDetails.length;i++){
          
          this.dSD[this.elimiwaitAccountsDetails[i].id] = SearchPageScript.defaultDateForDailyMonthlyFacility();
          
          this.startTime[this.elimiwaitAccountsDetails[i].id] = new Date(this.currDateObj.setMinutes(this.currDateObj.getMinutes() + 30));
          // console.log(this.startTime[this.elimiwaitAccountsDetails[i].id])
          // console.log(this.startTime[this.elimiwaitAccountsDetails[i].id].getHours())
          // console.log(this.startTime[this.elimiwaitAccountsDetails[i].id].getHours()-12 + ':' + this.startTime[this.elimiwaitAccountsDetails[i].id].getMinutes())
          // console.log(this.elimiwaitAccountsDetails[i].id+'jj')
          // this.elimiwaitAccountsDetails[i].id
          this.stime[this.elimiwaitAccountsDetails[i].id] = (this.startTime[this.elimiwaitAccountsDetails[i].id].getHours() > 12) ? (this.startTime[this.elimiwaitAccountsDetails[i].id].getHours()-12 + ':' + this.startTime[this.elimiwaitAccountsDetails[i].id].getMinutes() +' PM') : (this.startTime[this.elimiwaitAccountsDetails[i].id].getHours() + ':' + this.startTime[this.elimiwaitAccountsDetails[i].id].getMinutes() +' AM');
          
        }
         this.activeMpUser =data['data'][0];
        //alert(this.activeMpUser.id);
         if(!this.activeMpUser.id){
            $('.elimiwaitAccount').hide();  
            $('#AllGarages').show();  
                        $('#carreqstats').hide();           
          }
          else{
            $('.elimiwaitAccount').show();  
            $('#AllGarages').hide();
                        $('#carreqstats').show();           
          }        
        }
        
        }
        
        );
        }
      },
      error => {
      const errorsList = error.errors;
       if (errorsList.message !== 'Validation Errors') {
            this.toasterObj.error(errorsList.message, 'Sorry!');
          } else {
            for (const key in errorsList.detail) {
      if (errorsList.detail.hasOwnProperty(key)) {
                if (typeof errorsList.detail === 'undefined') {
                  this.toasterObj.error(errorsList.message, 'Sorry!');
                } else {
                  this.toasterObj.error(errorsList.detail[key], 'Sorry!');
                }
              }
            }
          }
      
        //FormValidationErrors.displayErrors(error.errors);
    this.isProcess = false;
      }
      );
       
     }

  seeGarage(){
    
    if(!this.activeMpUser.id){
      $('.elimiwaitAccount').hide();
      $('#AllGarages').show();
      $('#carreqstats').hide();
    }
    else{
      $('.elimiwaitAccount').show();
      $('#AllGarages').hide();
      $('#carreqstats').show();
        }

            $('.viewOne').toggleClass('hidden');
            $('.viewgarages').toggleClass('hidden');
            $('html,body').animate({ scrollTop: 0 }, 'slow');
     }
    vehiclerequest(){
    if(!this.activeMpUser.id){
      $('.elimiwaitAccount').hide();
      $('#carreqstats').hide();
      $('#AllGarages').show();
    }
    else{
      $('.elimiwaitAccount').show();
      $('#AllGarages').hide();
      $('#carreqstats').show();
      }
      
            $('.viewOne').toggleClass('hidden');
            $('.viewgarages').toggleClass('hidden');
     }
     phoneFormat(event: any) {
      const pattern = /[0-9\)\-\(\.\ ]/;
      let inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode != 8 && !pattern.test(inputChar) && event.keyCode != 9) {
        event.preventDefault();
      }
    }
    unmask(val){
      let a = val.replace(/[/) ]/g, '')
      a=a.replace(/[/( ]/g, '')
      a=a.replace('-','')
      var val1 = a;
      this.formData.cell1=a.replace(/[/_ ]/g, '')
      return a.replace(/[/_ ]/g, '');
    }

}

