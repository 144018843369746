import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var google: any;

import * as $ from 'jquery';

import { CustomCode } from '../../shared/CustomCode';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  // initialSearch = {
  //   label: 'Times Square',
  //   latitude: 40.7589,
  //   longitude: -73.9851
  // };
  // findValue: String;
  // currDate = CustomCode.getDateForMapSearch();
  // facilityType = CustomCode.defaultFacilityType;
  // facilityRadius = 2;

  constructor(private router: Router) { }

  ngOnInit() {
    // $('form.searchBar').css('display', 'none');
  }
}
