import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-monthly-parking-confirmation',
  templateUrl: './monthly-parking-confirmation.component.html',
  styleUrls: ['./monthly-parking-confirmation.component.scss']
})
export class MonthlyParkingConfirmationComponent implements OnInit {
  visaIcon = 'assets/img/visa.svg';
  garageIcon = '/assets/img/park-garage.jpg';
  garageMapIcon = '/assets/img/garage-map.png';
  phoneNumber = 'tel:(877) 727-5464';
  mailId = 'mailto:customerservice@iconparking.com';
  phoneNumber2 = 'tel:(212) 643-7409';


  constructor() { }

  ngOnInit() {
  }

}
