
import { Component, OnInit } from '@angular/core';

import { ReservationService } from '../../services/reservation.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as $ from 'jquery';
@Component({
  selector: 'app-dailyconfirmation',
  templateUrl: './dailyconfirmation.component.html',
  styleUrls: ['./dailyconfirmation.component.scss'],
  providers: [ReservationService]
})
export class DailyconfirmationComponent implements OnInit {

  facId;
  reservationDetails;
  park_garage_2Icon = '/assets/img/park-garage-2.jpg';
  garage_map_icon = '/assets/img/garage-map.png';
  visaIcon = 'assets/img/visa.svg';
 phoneNumber =  'tel:(877) 727-5464';
 mailId = 'mailto:customerservice@iconparking.com'

  constructor(
    private reservationObj: ReservationService,
    private activatedRouteObj: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRouteObj.params.subscribe((params: Params) => {
      this.facId = +params['resId'];

      this.reservationObj.reservationDetails(this.facId).then(
        (res) => {
          this.reservationDetails = res;
          this.reservationDetails = this.reservationDetails.data;
		  var lat = parseFloat(this.reservationDetails.facility.geolocations.latitude);
			var lng = parseFloat(this.reservationDetails.facility.geolocations.longitude);

			 $( document ).ready(function() {
				
							$('#map-wrapper').html("<iframe   width='257'  height='170' frameborder='0'  scrolling='no'  marginheight='0'  marginwidth='0' src='https://maps.google.com/maps?q="+lat+","+lng+"&hl=es;z=14&amp;output=embed' >");
							
				  });
        },
        (error) => { console.log('Error: ' + JSON.stringify(error)); }
      );

    });
  }

}

