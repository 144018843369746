import { Component, OnInit, ViewChild,Pipe,PipeTransform } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { GoogleMapComponent } from '../../Global/google-map/google-map.component';

import { CustomCode } from '../../../shared/CustomCode';
import { SearchPageScript } from '../../../shared/searchPageScript';
import { FormValidationErrors } from '../../../shared/formValidationErrors';
import { SearchpageserviceService } from '../../../services/searchpageservice.service';
import { ToastrService } from 'ngx-toastr';
import { StaticpagesService } from '../../../services/staticpages.service';

@Pipe({
  name: "sort"
})
export class SearchSortMonthlyPipe  implements PipeTransform {
  transform(array: any,field:string): any[] {
    if (!Array.isArray(array)) {
      return;
    }
  if(field=='price')
  {
    array.sort((a: any, b: any) => {

    if(b.monthly_rate_mains==0)
		{
			b.monthly_rate_mains=2500000;
		}
		if(a.monthly_rate_mains==0)
		{
			a.monthly_rate_mains=2500001;
		}

      if (a.monthly_rate_mains < b.monthly_rate_mains) {
        return -1;
      } else if (a.monthly_rate_mains > b.monthly_rate_mains) {
        return 1;
      } else {
        return 0;
      } 
    });
  }else{
    array.sort((a: any, b: any) => {
      if (a.distance < b.distance) {
        return -1;
      } else if (a.distance > b.distance) {
        return 1;
      } else {
        return 0;
      } 
    });
  }
    
    return array;
  }
}
@Component({
  selector: 'app-search-neighbourhood-monthly',
  templateUrl: './search-neighbourhood-monthly.component.html',
  styleUrls: ['./search-neighbourhood-monthly.component.scss'],
  providers: [SearchpageserviceService,StaticpagesService]
})
export class SearchNeighbourhoodMonthlyComponent implements OnInit {

  @ViewChild(GoogleMapComponent,{static:true}) googleMap: GoogleMapComponent;

  firstLoad:boolean = false;
  LoadFacilities:boolean=false;
  isCollapsed = false;
  LoadCampaignFacilities:boolean=false;
  allfacility:any=''
  searchCriteriaCampaign: any = {
    'arrival_time': CustomCode.getDateWithoutTime(),
    'length_of_stay': 2,
  	'radius': CustomCode.facilityDefaultRadius,
    'verbose' : true,
  	'monthly_campaign' : 1,
  neighID: 0,
  latitude: 40.7589,
  longitude: -73.9851,
  };
  toggleMap :any ;
  is_show_loader:any=1;

  searchCriteria: any = {
    'type': 'daily',
    'device': 'web',
    'lat': parseFloat(CustomCode.timeSquareCoords.lat),
    'long': parseFloat(CustomCode.timeSquareCoords.long),
    'currdate': CustomCode.getDateForMapSearch(),
    'radius': CustomCode.facilityDefaultRadius,
    'label': CustomCode.timeSquareCoords.defaultLocation,
    'length_of_stay': 2,
    'firstLoad' : this.firstLoad
  };

  sidebarOn :any;
  swipeOn : any;
  showMonthlyCampaign = false
  showNbhMap:boolean = true;
  firstMapLoad:boolean;
  searchResult: any = {};
  searchResultCampaign: any = [];
  neighbourhoods: any = {};
  activeNeighbourhood: any = 'central-park';
  startDuration:any;
  showDeals = false;
  ev_charge_icon='assets/img/amenities/ev_charge_icon.svg';
  motorcycle_icon='assets/img/amenities/motorcycle_icon.svg'
  tesla_charger_icon='assets/img/amenities/tesla_charger_icon.svg'
  open_24hr_icon='assets/img/amenities/open_24hr_icon.svg'
  monthly_icon='assets/img/amenities/monthly_icon.svg'
  oversized_icon='assets/img/amenities/oversized_icon.svg'
  outdoor_icon='assets/img/amenities/outdoor_icon.svg'
  indoor_icon='assets/img/amenities/indoor_icon.svg'
  park_garage='/assets/img/park_garage.png'
  list_icon='assets/img/list_icon.svg'

  activeNeighbourhoodTitle ;
  campaignDetails:any;
  listNeighbourhood=[];
  campaignTitle:any;
  campaignDescription:any;
  campaignFooter:any;
  checkLoadFacility:boolean;
  thresold:any=0;
  is_show=1;
  opens24Hours=0;
  opensOnWeekends=0
  opensOnWeekDays=0
  temporaryClosed=0
  opens24HoursM: boolean =false
  opensOnWeekendsM: boolean =false
  opensOnWeekDaysM: boolean =false
  temporaryClosedM: boolean =false
  selectedLocation:any=''
  spaceLeftMessage:string;
  existData=0;
  facilityText=1;
  types:string='daily'
  sortingType='distance';
  byDefault:any=0;
  forMobile='daily';
  badgePad:boolean = false;
  showDealsMobile = false;
  currentLocation = '';
  hideDealBtn = false;
  moboleFirstLoad = false;
  moboleFirstLoadMonthly = false;
  moboleCheckSearchType = 'monthly';
  mobilePanFlag = false;
  onPhone=0;
  androidPhone=0;
  iphoneX=0;
  firstTym=true;
  apiCallFirstTym=0;

    constructor(private router: Router,private activatedRouteObj: ActivatedRoute,private toastr: ToastrService,
    private StaticpagesService : StaticpagesService,
    private searchPageObj: SearchpageserviceService) { 
      this.toggleMap = true;
    this.sidebarOn  = false;
    this.swipeOn = false;
  }

  ngOnInit() {

    //  gettig space for user agent navigation

    // let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    let isSafari = /iP(ad|hone|od).+Version\/[\d\.]+.*Safari/i.test(navigator.userAgent);

    // let iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream
    let iPhone = /iPhone/.test(navigator.userAgent)
    let aspect = window.screen.width / window.screen.height
    if (iPhone &&  aspect.toFixed(3) === "0.462" && isSafari) {
      if(iPhone && isSafari === true){
         this.iphoneX = 1;
      }else{
        this.iphoneX = 0;
      }
      // alert(iPhone);
      // alert(isSafari);
       

        // alert('is iphone X')
        }else{
          this.iphoneX = 0;
        } 

        if (iPhone && aspect.toFixed(3) === "0.562") {
          // alert('iphone 6');
          this.onPhone = 1
          }else {
            this.onPhone = 0;
            
          }

    if (navigator.userAgent.match(/(Android)/i)) {
      this.androidPhone = 1;
    
		
		} else {
			this.androidPhone = 0;
			
		}
    localStorage.setItem('ischecked','0')
    localStorage.removeItem('searcgPage');
    localStorage.removeItem('neighTypeformob');
    
    if (CustomCode.searchMode() == 'app') {
      this.firstMapLoad = false;
    } else {
      this.firstMapLoad = true;
  }
  this.mobilePanFlag = true
  this.moboleFirstLoad = true;
  this.moboleFirstLoadMonthly = true;
  if ($(window).width() < 768){
    this.isCollapsed = true;
    localStorage.setItem('neighTypeformob', 'monthly')
    this.types='monthly';
    this.searchCriteria.type ='monthly';
    this.forMobile='monthly'
   }


  setInterval(() => {
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
      $('.campians_list').addClass('IosDevice');
} else {
$('.campians_list').removeClass('IosDevice');
 };
  if ($(window).width() < 768 && localStorage.getItem('neighTypeformob') ) {
      this.searchCriteria.type =localStorage.getItem('neighTypeformob');
      if(localStorage.getItem('neighTypeformob') == 'daily'){
      this.showDealsMobile = false;
      }else if(localStorage.getItem('neighTypeformob') == 'monthly' && !this.hideDealBtn){
        this.showDealsMobile = true
        this.showDeals = true;
      } else if( localStorage.getItem('neighTypeformob') == 'monthly' ){
        this.showDeals = true;
        this.showDealsMobile = true
      }
  }
  if (this.router.url.indexOf('daily') > -1) {
    this.showDealsMobile = false
      this.showDeals = false;
      this.showMonthlyCampaign = false;
      localStorage.setItem('neighTypeformob', 'daily');
  }else { localStorage.setItem('neighTypeformob', 'monthly'); }
 
}
, 1);
	this.byDefault=1
    this.activatedRouteObj.params.subscribe((params: Params) => {
      if (params['location']) {
		this.updateActiveRoute(params['location']);
    this.activeNeighbourhood = params['location'];
    this.currentLocation = params['location'];
    if(this.currentLocation ==''){
      this.currentLocation = this.activeNeighbourhood;
    }
    this.moboleCheckSearchType = params['type']
        if(params['type']=='monthly')
        {
		  this.showDeals = true
		  this.types='monthly'
      this.searchCriteria.type ='monthly';
      this.forMobile='monthly'
      localStorage.setItem('neighTypeformob','monthly')
          setTimeout(()=>{
            
            // $('#'+params['type']).trigger('click');
          },5000)
        }else{
		  this.types='daily';
      this.searchCriteria.type ='daily';
      this.forMobile='daily'
        }
      }
    });
    setTimeout(()=>{
      this.searchCriteria.currdate = $('#startdate').val() + ' ' + $('#startTime').val();
      this.fetchNeighbourhoodsData();
      // $('label#' + this.searchCriteria.type).addClass('active');
  
      this.fetch_monthly_campaign();
    },2000)
    
  }

  fetch_monthly_campaign(){
    this.StaticpagesService.getMonthlyCampaignContent().subscribe(
      data => {
      this.campaignDetails  = data;
        this.campaignDetails = this.campaignDetails.result[0];
       this.campaignTitle = this.campaignDetails.campaign_title;
       this.campaignFooter = this.campaignDetails.campaign_footer;
       this.thresold=this.campaignDetails.threshold;
       this.spaceLeftMessage = this.campaignDetails.space_message;
	    let default_neighbour = this.campaignDetails.default_neighborhood.split('?');
	    let activeNeighbourhoodTitle = default_neighbour[1];

      activeNeighbourhoodTitle  = this.activeNeighbourhoodTitle
      activeNeighbourhoodTitle = activeNeighbourhoodTitle.toUpperCase();
      this.campaignDescription = this.campaignDetails.campaign_desc.replace("{{neighbour hood name}}",activeNeighbourhoodTitle);
      }
      , (error) => { console.log('Error: ' + JSON.stringify(error));
    }
    )
    }

    tryPrintScaleValue() {
    if(this.opens24HoursM)
    {
      this.opens24Hours=1;
    }else{
      this.opens24Hours=0;
    }
    //2nd 
    if(this.opensOnWeekendsM)
    {
      this.opensOnWeekends=1;
    }else{
      this.opensOnWeekends=0;
    }
    //3 rd 
    if(this.opensOnWeekDaysM)
    {
      this.opensOnWeekDays=1;
    }else{
      this.opensOnWeekDays=0;
    }
    //4th
    if(this.temporaryClosedM)
    {
      this.temporaryClosed=1;
    }else{
      this.temporaryClosed=0;
    }
    this.reloadMap(this.selectedLocation);
    }

  nbhMapToggle() {
    
    $('aside.contentBar').removeClass('active');
    if ($(window).width() < 768) {
      if(this.searchCriteria.type == 'daily') {
          if (this.showNbhMap) {
            this.toggleMap = false;
            this.showNbhMap = false;
            
          } else {
            this.toggleMap = true;
            this.showNbhMap = true;	
          }
       }
      else if(this.searchCriteria.type == 'monthly') {
        
        if (this.showNbhMap && this.byDefault ) {
          this.toggleMap = false;
          this.showNbhMap = false;
        } else {
          this.toggleMap = true;
          this.showNbhMap = true;        
        }
        
        if ($(window).width() < 768 && this.moboleFirstLoadMonthly) {
          $('.campians_listNeighbourhood').hide();
          this.isCollapsed = true;
          this.toggleMap = true;
          this.showNbhMap = true;

        }
       }
       if(this.moboleFirstLoad && this.searchCriteria.type == 'daily'){
        this.toggleMap = true;
        this.showNbhMap = true;
        this.moboleFirstLoad = false;
      }
      if(this.mobilePanFlag ){
        if ($(window).width() < 768 && this.moboleFirstLoadMonthly && this.currentLocation ) {
         $('.campians_listNeighbourhood').show();
         this.isCollapsed = false;
         this.toggleMap = false;
         this.showNbhMap = false;
       }
       else if ($(window).width() < 768 && this.moboleFirstLoadMonthly && !this.currentLocation) {
         this.isCollapsed = false;
         this.showMonthlyCampaign = false;
         $('.campians_listNeighbourhood').hide();
         this.toggleMap = true;
         this.showNbhMap = true;
       } else if( $(window).width() < 768 && this.moboleFirstLoadMonthly) {
         this.isCollapsed = true;
         this.toggleMap = false;
         this.showNbhMap = false;
       }
      }
        
    }
  }


  updateActiveRoute(location) {
    this.activeNeighbourhood = location;
    let tempLocation = this.activeNeighbourhood.split("-");
    for (var i = 0; i < tempLocation.length; i++) {
      tempLocation[i] = tempLocation[i].replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    }
    let searchText = tempLocation.join(" ");
    setTimeout(() => {
      $('#auto_suggest_input_field').val(searchText);
    }, 200);
    
  }

  fetchNeighbourhoodsData() {
    $('#auto_suggest_input_field').val(this.activeNeighbourhood);
    this.searchPageObj.getNeighbourhoods()
      .subscribe(
      data => {
        if (data.data) {
			for (let i = 0; i < data.data.length; i++) {
			this.neighbourhoods[data.data[i].slug] = data.data[i];
			if(data.data[i].slug == this.activeNeighbourhood){
				this.neighbourhoods[data.data[i].slug]	
				this.activeNeighbourhoodTitle =	this.neighbourhoods[data.data[i].slug].title
				this.searchCriteriaCampaign.latitude = this.neighbourhoods[data.data[i].slug].latitude;
      			this.searchCriteriaCampaign.longitude = this.neighbourhoods[data.data[i].slug].longitude;
			}
			
		    this.firstLoad = true;
          }
        } else {
          this.toastr.error('Error Occured While Loading Neighborhoods', 'Sorry!');
        }
        this.loadGoogleMapForNeighbourhood();
      },
      error => {
        this.toastr.error(error.error.message, 'Sorry!');
      }
    );
  }

  loadGoogleMapForNeighbourhood() {
    if (this.activeNeighbourhood != '') {
      const active = this.activeNeighbourhood;
      this.searchCriteria.lat = parseFloat(this.neighbourhoods[active].latitude);
      this.searchCriteria.long = parseFloat(this.neighbourhoods[active].longitude);
      this.searchCriteriaCampaign.lat = parseFloat(this.neighbourhoods[active].latitude);
      this.searchCriteriaCampaign.long = parseFloat(this.neighbourhoods[active].longitude);
    }

    let searchType = $('label[name="monthlytype"].active').text().trim().toLowerCase();
    this.searchCriteria.type = searchType;
    if(this.searchCriteria.type == 'monthly'){
	  this.showMonthlyCampaign = true
	  this.showDeals = true
    }else {
	  this.showMonthlyCampaign = false
	  this.showDeals = false
    }
    if(this.moboleCheckSearchType=='daily' && $(window).width() < 768 ){
      this.showMonthlyCampaign = false
    }

    $('label#' + this.searchCriteria.type).addClass('active');
    localStorage.setItem('neighbourhoodLocation',JSON.stringify(this.searchCriteria))
    this.googleMap.loadMap(this.searchCriteria);
    if (this.firstMapLoad) {
      setTimeout(() => {
        this.nbhMapToggle();
      }, 500);
      
    }
    setTimeout(() => {
        this.fetchFacilityData();
      }, 2000);
     
  }
  receiveFacilityNewSearchFilter($event) {
    let facility =JSON.parse(JSON.stringify(this.allfacility))
    this.searchResult =facility;
    let fac=[];
    let selectFacility=[]
    let k=0;
    let exit=1;
    var is_monthlypark='';
    var is_motorcycle_parking='';
    var is_oversized='';
    var is_generic_ev_charging='';
    var is_tesla_charging='';
    var is_outdoor_parking='';
    var is_indoor_parking='';
    var is_twentyhour='';
    let isselected=0;
    if($event.is_monthlypark)
    {
        is_monthlypark='is_monthly'
        selectFacility.push('is_monthly')
        localStorage.setItem('is_monthlypark','true')
        isselected=1;
    }
    else{
      localStorage.setItem('is_monthlypark','false')
    }
      if( $event.is_motorcycle_parking)
      {
        is_motorcycle_parking='is_motorcycle_parking'
        selectFacility.push('is_motorcycle_parking')
        localStorage.setItem('is_motorcycle_parking','true')
        isselected=1;
      }
      else{
      localStorage.setItem('is_motorcycle_parking','false')
    }
      if($event.is_oversized)
      {
        is_oversized='is_oversized'
        selectFacility.push('is_oversize')
        localStorage.setItem('is_oversized','true')
        isselected=1;
      }
      else{
      localStorage.setItem('is_oversized','false')
    }
      if($event.is_generic_ev_charging)
      {
        is_generic_ev_charging='is_generic_ev_charging'
        selectFacility.push('is_generic_ev_charging')
        localStorage.setItem('is_generic_ev_charging','true')
        isselected=1;
      }
      else{
        localStorage.setItem('is_generic_ev_charging','false')
      }
      if($event.is_tesla_charging)
      {
        is_tesla_charging='is_tesla_charging'
        selectFacility.push('is_tesla_charging')
        localStorage.setItem('is_tesla_charging','true')
        isselected=1;
      }
      else{
      localStorage.setItem('is_tesla_charging','false')
    }
      if($event.is_outdoor_parking)
      {
        is_outdoor_parking='is_outdoor_parking'
        selectFacility.push('is_outdoor_parking')
        localStorage.setItem('is_outdoor_parking','true')
        isselected=1;
      }
      else{
      localStorage.setItem('is_outdoor_parking','false')
    }
      if($event.is_indoor_parking)
      {
        is_indoor_parking='is_indoor_parking'
        selectFacility.push('is_indoor_parking')
       localStorage.setItem('is_indoor_parking','true')
        isselected=1;
      }
      else{
      localStorage.setItem('is_indoor_parking','false')
    }
    if($event.monthly_campaign)
     	{
     	  selectFacility.push('monthly_campaign')
     	  localStorage.setItem('monthly_campaign','true')
     	  isselected=1;
     	}
     	else{
			localStorage.setItem('monthly_campaign','false')
		}
      if($event.is_twentyhour)
      {
        is_twentyhour='is_24hour_open'
        selectFacility.push('is_24hour_open')
        localStorage.setItem('is_twentyhour','true')
        isselected=1;
      }
      else{
      localStorage.setItem('is_twentyhour','false')
    }
      if(isselected)
      {
        localStorage.setItem('ischecked','1')
      }else{
        localStorage.setItem('ischecked','0')
        localStorage.removeItem('is_twentyhour')
        localStorage.removeItem('is_indoor_parking')
        localStorage.removeItem('is_outdoor_parking')
        localStorage.removeItem('is_tesla_charging')
        localStorage.removeItem('is_generic_ev_charging')
        localStorage.removeItem('is_oversized')
        localStorage.removeItem('is_motorcycle_parking')
        localStorage.removeItem('is_motorcycle_parking')
      }
    for(let i=0; i<this.searchResult.facilities.length;i++)
    {
        let facilityCount=0;
        let z=0;
        for(;z<selectFacility.length;z++)
        {
          exit=0
          if(this.searchResult.facilities[i][selectFacility[z]]==1)
          {
            facilityCount++;
          }
        }
        if(z==facilityCount)
        {
          exit=0
          fac[k++]=this.searchResult.facilities[i]
        }

    }
    if(exit)
    {
      this.searchResult=JSON.parse(JSON.stringify(this.allfacility))
    }else{
      this.searchResult.facilities=fac;
    }

    this.firstLoad = true;
    this.loadGoogleMapMarkers();
    //this.preventUnusedAjax = true;
    this.searchCriteria.firstLoad = true;
  }

  setTime()
	{
		if(this.searchCriteria.currdate.indexOf('-')>-1)
		{
			let time=this.searchCriteria.currdate.split('-') //12/14/2020 06:30:00   //2020,12,14 06:30:00
			let times=time[2].split(' ');
			let newTime=time[1]+'/'+times[0]+'/'+time[0]+' '+times[1]
			$('#lenth_stay').val(this.searchCriteria.length_of_stay)
			$('#time_search').val(newTime)
		}else{
			$('#lenth_stay').val(this.searchCriteria.length_of_stay)
			$('#time_search').val(this.searchCriteria.currdate)
		}	
		
  }
  
  fetchFacilityData() {
    if(1){
      this.apiCallFirstTym=1;
      //alert(33)
      this.setTime();
      // $('#lenth_stay').val(this.searchCriteria.length_of_stay);
			// $('#time_search').val(this.searchCriteria.currdate);
    this.firstTym=false;
    this.searchPageObj.getFacility(this.searchCriteria.lat, this.searchCriteria.long, this.searchCriteria.radius, this.searchCriteria.length_of_stay, this.searchCriteria.currdate)
      .subscribe(
      data => {
        $('#filter-drpp').addClass('hide');
        $('#filter-drps').removeClass('hide');
        this.firstLoad = true;
        this.LoadFacilities = true;
          this.allfacility =JSON.parse(JSON.stringify(data.data));
          this.searchResult = data.data;
          let ischecked=localStorage.getItem('ischecked')
        if(ischecked=='1')
          {
            let is_generic_ev_charging:any=localStorage.getItem('is_generic_ev_charging')
            let is_indoor_parking:any=localStorage.getItem('is_indoor_parking')
            let is_monthlypark:any=localStorage.getItem('is_monthlypark')
            let is_motorcycle_parking:any=localStorage.getItem('is_motorcycle_parking')
            let is_outdoor_parking:any=localStorage.getItem('is_outdoor_parking')
            let is_tesla_charging:any=localStorage.getItem('is_tesla_charging')
            let is_oversized:any=localStorage.getItem('is_oversized')
            let is_twentyhour:any=localStorage.getItem('is_twentyhour')
            if(is_generic_ev_charging=='true')
            {
              is_generic_ev_charging=true;
            }else{
              is_generic_ev_charging=false;
            }
            if(is_indoor_parking=='true')
            {
              is_indoor_parking=true
            }else{
              is_indoor_parking=false
            }
            if(is_monthlypark=='true')
            {
              is_monthlypark=true
            }else{
              is_monthlypark=false;
            }
            if(is_motorcycle_parking=='true')
            {
              is_motorcycle_parking=true
            }else{
              is_motorcycle_parking=false;
            }
            if(is_outdoor_parking=='true')
            {
              is_outdoor_parking=true
            }else{
              is_outdoor_parking=false;
            }
            if(is_tesla_charging=='true')
            {
              is_tesla_charging=true
            }else{
              is_tesla_charging=false;
            }
            if(is_oversized=='true')
            {
              is_oversized=true
            }else{
              is_oversized=false;
            }
            if(is_twentyhour=='true')
            {
              is_twentyhour=true
            }else{
              is_twentyhour=false;
            }

            let event= {
            'is_generic_ev_charging':is_generic_ev_charging,
            'is_indoor_parking':is_indoor_parking,
            'is_monthlypark':is_monthlypark,
            'is_motorcycle_parking':is_motorcycle_parking,
            'is_outdoor_parking':is_outdoor_parking,
            'is_oversized':is_oversized,
            'is_tesla_charging':is_tesla_charging,
            'is_twentyhour':is_twentyhour}
            this.receiveFacilityNewSearchFilter(event)
          }else{
            this.loadGoogleMapMarkers();
            this.firstMapLoad = true;
          }
      },
      error => {
        this.toastr.error(error.errors.message, 'Sorry!');
        this.LoadFacilities = true;
      },
      () => {
        setTimeout(()=>{
          this.apiCallFirstTym=0;
        },5000)
        
      }
    );
    this.searchCriteriaCampaign.opens24Hours=this.opens24Hours
    this.searchCriteriaCampaign.opensOnWeekends=this.opensOnWeekends
    this.searchCriteriaCampaign.opensOnWeekDays=this.opensOnWeekDays
    this.searchCriteriaCampaign.temporaryClosed=this.temporaryClosed
    if(this.searchCriteria.type == 'monthly'){
	  this.showMonthlyCampaign = true
	  this.showDeals = true
    }else {
	  this.showMonthlyCampaign = false
	  this.showDeals = false
    }
  }
  }

  loadGoogleMapMarkers() {
    //alert(45544)
    this.googleMap.loadMarkers(this.searchResult);
  }

  receiveNewFilters($event) {
    //alert(2)
    this.searchCriteria = $event;
    this.fetchFacilityData();
  }

  reloadMap(location) {
    
    this.moboleFirstLoad = false;
    this.moboleFirstLoadMonthly = false;
    if ($(window).width() < 768) {
      this.showMonthlyCampaign = false;
    }
    this.byDefault=1
    let dailyClass = $('#daily').hasClass('active');
    let monthlyClass = $('#monthly').hasClass('active');
    
    if(this.searchCriteria.type =='daily')
    {
	  this.types='daily';
    this.showDeals = false
    this.isCollapsed = false;
    }
    if(this.searchCriteria.type =='monthly')
    {
	  this.types='monthly';
	  this.showDeals = true
    }
    if ($(window).width() < 768) {
      this.types=this.forMobile;
      this.isCollapsed = false;
      if(!(localStorage.getItem('showDeals'))){
        this.isCollapsed = true;
      }
    }
    this.showNbhMap = true;
    this.toggleMap = false;
    this.LoadCampaignFacilities = false;
    this.router.navigate(['/monthly-parking/' + location+'/'+this.types]);
    this.updateActiveRoute(location);
    this.loadGoogleMapForNeighbourhood();
    this.searchCriteriaCampaign.latitude = this.neighbourhoods[location].latitude;
      this.searchCriteriaCampaign.longitude = this.neighbourhoods[location].longitude;
    this.searchCriteriaCampaign.neighID = 0;
    this.activeNeighbourhoodTitle = this.neighbourhoods[location].title;
    this.activeNeighbourhoodTitle = this.activeNeighbourhoodTitle.toUpperCase();
    this.campaignDescription = this.campaignDetails.campaign_desc.replace("{{neighbour hood name}}",this.activeNeighbourhoodTitle);
  }

  buyMonthlyCampaign(monthlyRate, facID, netPrice, TaxPrice, IsMonthly_campaign){
    localStorage.setItem('clickBuyMonthly','1');
    if(0)
		{
			localStorage.setItem('clickBuyMonthly','1');
			let facilityId = facID;
			let mD = $('#monthlydate').val().toString().split('/');
					let fDate = mD[2] + '-' + mD[0] + '-' + mD[1];
					//this.router.navigate([routeNavigate, facId, fDate]);
					localStorage.setItem('monthlyParkingId',facilityId)
					localStorage.setItem('monthlyParkingDate',fDate)
					$("a#loginPopup")[0].click();
		}else {
    // kochava.activity('clickBuyMonthlyAccountByCampaign', {
    //   //user_name: this.respdata.display_name,                      // Name or ID is required.
    //   id: facID,
    //   price: monthlyRate,
    //   category: 'Monthly_Campaign',
    //   });

    sessionStorage.setItem('campaignMonthlyRate', monthlyRate);
    sessionStorage.setItem('campaignNetPrice', netPrice);
    sessionStorage.setItem('campaignTaxPrice', TaxPrice);
    sessionStorage.setItem('isMonthlyCampaign', IsMonthly_campaign);
    let Cdate = this.searchCriteria.currdate.split(' ');
		 let currentDate = Cdate[0].split('/')
     let date = currentDate[2]+'-'+currentDate[0]+'-'+currentDate[1];
    this.router.navigate(['/buy-monthly/'+ facID + '/' + date]);
    }
  }

  closeGrageDetails() {
    this.sidebarOn = false;
  }

  receiveMobileFilters($event) {
    this.searchCriteria = $event;
    this.fetchFacilityData();
  }
  receiveNewSearchFilter($event)
  {
   
    this.searchCriteria.length_of_stay = $event.length_of_stay;
    this.searchCriteria.currdate = $event.currdate;
    this.searchCriteria.type = $event.type;
    if(this.router.url.indexOf('daily')>-1)
					{
						this.searchCriteria.type='daily'
					}else{
						this.searchCriteria.type='monthly'
					}

  }
  receiveNewSearchFilterNei($event) {
    
    this.searchCriteria.length_of_stay = $event.length_of_stay;
    this.searchCriteria.currdate = $event.currdate;
    this.searchCriteria.type = $event.type;
    
    if(this.searchCriteria.type.indexOf('daily')>-1)
					{
						this.searchCriteria.type='daily'
					}else{
						this.searchCriteria.type='monthly'
					}
    if(this.currentLocation == ''  && this.searchCriteria.type == 'daily' ) {
      this.router.navigate(['/monthly-parking/central-park/'+this.searchCriteria.type]);
    }else if( this.currentLocation != '' ) {
      this.router.navigate(['/monthly-parking/'+this.currentLocation+'/'+this.searchCriteria.type]);
    }
    this.fetchFacilityData();
    
    
  }
  showPop()
	{
		if(localStorage.getItem('mapEvent'))
		{
			localStorage.setItem('headerEvenT','1')
			
		}
		
	}
  updateTier(showBadge){
    this.badgePad = showBadge;
  }

  convertIntoInteger(val)
  {
    return(parseInt(val))
  }

  checkData(var1,var2)
  {
    let t=this.checkAvaillity_campaign_remaining(var1,var2)
    if(t>0)
    {
      return '40%';
    }if(t<0 || t==0)
    {
      return '40%';
    }
    else{
      return '80%'
    }
  }
  checkAvaillity_campaign_remaining(totalAvailable, usedAvailable){
  let val =  parseInt(totalAvailable) - parseInt(usedAvailable);
  return val 
   }
   checkAvaillity_campaign_remaining_text(totalAvailable, usedAvailable,text)
   {
   let val =  parseInt(totalAvailable) - parseInt(usedAvailable);
   let newText='';
   if(val>1)
   {
     newText=text.replace('number Space',val+' Spaces')
   }else{
     newText=text.replace('number',val)
   }
  
   return newText;
   }
   sort(val)
   {
    if(val == 'distance'){
			$('.campaignSortingDistance').removeClass('active').addClass('active');
			$('.campaignSortingPrice').removeClass('active');
		 }else {
			$('.campaignSortingPrice').removeClass('active').addClass('active');
			$('.campaignSortingDistance').removeClass('active');
		 }
    this.sortingType=val
   }

   redirects(dailyMonthly)
   {
     this.searchCriteria.type=dailyMonthly
     this.forMobile=dailyMonthly;
     if(!this.currentLocation){  this.currentLocation = this.activeNeighbourhood }
    this.router.navigate(['/monthly-parking/'+this.currentLocation+'/'+dailyMonthly]);
    localStorage.setItem('neighTypeformob',dailyMonthly)
    setTimeout(()=>{
      this.searchCriteria.currdate = $('#startdate').val() + ' ' + $('#startTime').val();
      this.fetchNeighbourhoodsData();
      // $('label#' + this.searchCriteria.type).addClass('active');
  
      this.fetch_monthly_campaign();
    },2000)
   }

   showNeighbourhoodMap(){
    this.moboleFirstLoad = false;
     this.showDealsMobile = false
     this.hideDealBtn = true;
   }

   
   toogleDealList(){
     if ($('.campians_listNeighbourhood').hasClass("d-none")) {
      $('.campians_listNeighbourhood').removeClass('d-none');
      $('.campians_listNeighbourhood').style('display','block !important')
      this.isCollapsed = true;
     } else {
      if(this.isCollapsed){       
        this.isCollapsed = false
       }else {
        this.isCollapsed = true
       }
     }
     
   }

   closeMonthlyCampaignFlayer(){
    this.mobilePanFlag = false
    if(this.isCollapsed){       
      this.isCollapsed = false
     }else {
      this.isCollapsed = true
      if ($(window).width() < 768) {
        localStorage.removeItem('showDeals');
      }
     }
    }
     
    chnageText(val)
		  {
			  if(1)
			  {
				  if(val!=undefined)
				  {
					return val.replace("LLC", "LLC.")
				  }else{
					  return val
				  }
			  }else{
				  return val
			  }
      }
      neighborhoodSearchFilter($event) {
        //alert(1)
       this.searchCriteria = $event;
       //alert(2);
       //this.mobileStartTime=this.searchCriteria.mobStarttime
       this.fetchFacilityData();
     }
     receiveNewSearchFilterOnSubmit($event) {
      //alert($event.length_of_stay);
      if(parseFloat($event.length_of_stay)<2 && this.searchCriteria.type == 'daily')
      {
        //his.toastr.error('stay time should be 2 hours or ore','Sorry'); 
        this.toastr.error('In order to book a reservation, minimum booking time must be at least 2 hours', 'Error');
        
        //return false;
      }
      this.searchCriteria.length_of_stay = $event.length_of_stay;
      this.searchCriteria.currdate = $event.currdate;
      //alert(3);
      this.fetchFacilityData();
    }
    showpopUpOnmobile()
	{
	  if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/i)) {
		localStorage.setItem('headerEvenT','1');
	  }
  }
  receiveNewSearchFilterMobile($event)
	{
		this.searchCriteria.length_of_stay = $event.length_of_stay;
		this.searchCriteria.currdate = $event.currdate;
		this.searchCriteria.lat = $event.lat;
		this.searchCriteria.long = $event.long;
		this.searchCriteria.label = $event.label;
		this.fetchFacilityData();
	}
}
