
import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { CustomCode } from '../shared/CustomCode';
import { SearchPageScript } from '../shared/searchPageScript';
import * as $ from 'jquery';
import {GoogleAnalyticsEventsService} from "../shared/google-analytics-events.service";
import { StaticpagesService } from '../services/staticpages.service';

import { SafeResourceUrl } from '@angular/platform-browser';
// declare var ga: Function;
// declare var gtag:Function;
// declare var gtag_report_conversion : Function;

@Component({
  selector: 'app-monthlyparking',
  templateUrl: './monthlyparking.component.html',
  styleUrls: ['./monthlyparking.component.scss'],
  providers: [StaticpagesService],
  encapsulation:ViewEncapsulation.None
})
export class MonthlyparkingComponent implements OnInit {

  pageId:string = 'monthly-parking';
  cmsPageContent: any = {};
  isLoaded = false;

  arrowIcon = 'assets/img/arrows.svg';
  mapmarkerIcon = 'assets/img/map_markers.svg';
  vehicleIcon = 'assets/img/vehicle.svg';
  accountIcon = 'assets/img/account.svg';
  cardIcon = 'assets/img/card.svg'

  contactTelNo = CustomCode.contactTelephoneNumber;
  isLoggedIn = false;
  gtmUrl:SafeResourceUrl = window.location.pathname;

  constructor(private routerObj: Router, private staticObj:StaticpagesService, public googleAnalyticsEventsService: GoogleAnalyticsEventsService) { }

  ngOnInit() {
    // setTimeout(() => {
    //   $('body').addClass('showChat');
    // },1000);
    let times_run= setInterval(function () {
      $('body').addClass('showChat');
    }, 4000);
    setTimeout(function () {
      clearInterval(times_run);
    }, 10000);
      if (localStorage.getItem('access_token')) {
          this.isLoggedIn = true;
      }
      SearchPageScript.manageHeaderPosition();

      this.staticObj.getCmsPageContent(this.pageId).subscribe((data:any) => {
        this.cmsPageContent = data.result[0];
        this.isLoaded = true;
      }, (error) => { console.log('Error: ' + JSON.stringify(error)); 
        this.isLoaded = true;
    } 
    );





  }
  callMonthlyCustomer()
  {
   // ga('send', 'event', { eventCategory: 'monthly LeftCall', eventAction: 'click'});
    //(function(a,e,c,f,g,h,b,d){var k={ak:"876742851",cl:"oEyECNqX9nsQw5GIogM",autoreplace:"(888) 705-7845"};a[c]=a[c]||function(){(a[c].q=a[c].q||[]).push(arguments)};a[g]||(a[g]=k.ak);b=e.createElement(h);b.async=1;b.src="//www.gstatic.com/wcm/loader.js";d=e.getElementsByTagName(h)[0];d.parentNode.insertBefore(b,d);a[f]=function(b,d,e){a[c](2,b,k,d,null,new Date,e)};a[f]()})(window,document,"_googWcmImpl","_googWcmGet","_googWcmAk","script");
    // gtag('config', 'AW-876742851/dsFECMy1930Qw5GIogM', {
    //   'phone_conversion_number': '(888) 705-7845'
    // });

  }
  callMonthlyCustomer_M()
  {

   // ga('send', 'event', { eventCategory: 'monthly MobileCall', eventAction: 'click'});
    //(function(a,e,c,f,g,h,b,d){var k={ak:"876742851",cl:"oEyECNqX9nsQw5GIogM",autoreplace:"(888) 705-7845"};a[c]=a[c]||function(){(a[c].q=a[c].q||[]).push(arguments)};a[g]||(a[g]=k.ak);b=e.createElement(h);b.async=1;b.src="//www.gstatic.com/wcm/loader.js";d=e.getElementsByTagName(h)[0];d.parentNode.insertBefore(b,d);a[f]=function(b,d,e){a[c](2,b,k,d,null,new Date,e)};a[f]()})(window,document,"_googWcmImpl","_googWcmGet","_googWcmAk","script");
    // gtag('config', 'AW-876742851/dsFECMy1930Qw5GIogM', {
    //   'phone_conversion_number': '(888) 705-7845'
    // });
  }
  addgta()
  {
    
  }
  continueAsGuest() {
  //  ga('send', 'event', { eventCategory: 'buy online', eventAction: 'click'})
    
   // gtag_report_conversion(this.gtmUrl);

    // gtag('event', 'conversion', { 'send_to': 'AW-876742851/rhhgCLKWm3UQw5GIogM',
    //   'currency': 'USD',
    //   'transaction_id': '',
    //    'eventCallback':window['callbackgtm']
    // });
    const userLat = CustomCode.timeSquareCoords.lat;
    const userLong = CustomCode.timeSquareCoords.long;
    const locationUser = CustomCode.timeSquareCoords.defaultLocation;
    this.routerObj.navigate(['/search', userLat, userLong, CustomCode.getDateForMapSearchSlashFormat(), CustomCode.facilityDefaultRadius, locationUser, CustomCode.monthlyConstant, CustomCode.searchMode()]);
  }

}
