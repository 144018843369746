import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute, Params } from '@angular/router';
import { SearchpageserviceService } from './../../../services/searchpageservice.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-aggregator-voucher-redemption',
  templateUrl: './aggregator-voucher-redemption.component.html',
  styleUrls: ['./aggregator-voucher-redemption.component.scss'],
  providers: [SearchpageserviceService]
})
export class AggregatorVoucherRedemptionComponent implements OnInit {
  getUserVoucher:String = '';
  constructor( private router:Router , private activatedRoute:ActivatedRoute, 
    private searchPageObj : SearchpageserviceService,
    private toaster:ToastrService
    ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe( (paramsData: Params) => {
        if(paramsData['voucher'] && paramsData['voucher'] !== undefined && paramsData['voucher'] !== '')
        {
          this.getVoucher(paramsData);
        }
      })
  }

  getVoucher(formData){
    this.fetchUserStatusForTracking();
    let vouchernumber = formData.voucher.trim();
    if( vouchernumber !== undefined && vouchernumber !== '' ){
      this.searchPageObj.checkAggrigatorVoucher(vouchernumber).then(
        (res) => {
          let data =  res['data'];
          if(data['errors']){
            this.toaster.error('Voucher is not valid, please enter a valid voucher','Sorry');
          }else {
            let reservationDate = data['aggregator_detail'].start_time;
            console.log(reservationDate)
           this.router.navigate(['aggregator-monthly-booking/'+ vouchernumber+'/'+reservationDate]);
          }
        },
        (error) => { 
          console.log(error);
          //let error = error.errors;
          //error = error['errors'];
          this.toaster.error(error.errors.message,'Sorry');
        }
      );
    }
  }


  fetchUserStatusForTracking(){
    let getUserId = '0';
    let getUserEmail = '0';
    let user_login_type = '';
    let isLogin = false;
    if(localStorage.getItem('access_token')){
     isLogin = true;
       getUserId = localStorage.getItem('userid');
       getUserEmail = localStorage.getItem('email');
       user_login_type = localStorage.getItem('user_login_type');
    }
    const loginStatusTrack = {
      'event' : 'pageview',
      'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
      'authenticationMethod' : user_login_type,
      'userId' : getUserId,
      'pageType' : this.router.url,
      'email': getUserEmail,
   };
  (<any>window).dataLayer.push(loginStatusTrack);
  
  }

}
