import { Component, OnInit, ElementRef,ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers, RequestOptions, Response } from '@angular/http';

import { Observable } from 'rxjs/Rx';

// Import RxJs required methods
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { StaticpagesService } from '../../services/staticpages.service';

@Component({
  selector: 'app-careers',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss'],
  providers: [StaticpagesService]
})
export class CareersComponent implements OnInit {
    pageId: string = 'careers';
    careerContent : any ={};

  page: String = 'careers';
  staticPageContent: any = {};
  isLoaded = false;

  constructor(private router: Router, private staticObj: StaticpagesService) { }

  ngOnInit() {
    this.staticObj.staticPagesContent(this.page).then(
      (res) => {
        this.staticPageContent = res;
        this.staticPageContent = this.staticPageContent.data;
      },
      (error) => { console.log('Error: ' + JSON.stringify(error)); }
    );



    this.staticObj.getCmsPageContent(this.pageId).subscribe((data:any) => {
      this.careerContent = data.result[0];
      this.isLoaded = true;
    }, (error) => { console.log('Error: ' + JSON.stringify(error));
    this.isLoaded = true;
      }
  );  

  }

}
