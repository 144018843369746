import { Component, OnInit } from '@angular/core';
import { UserserviceService } from '../../services/userservice.service';
import { NgForm } from '@angular/forms';
//import * as moment from 'moment-timezone';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-promo-codes',
  templateUrl: './promo-codes.component.html',
  providers: [UserserviceService]
})
export class PromoCodesComponent implements OnInit {

	isLoaded = false;
	activeUser: any = {};
	promoCodes: any = {};

	formData: any = {};
	isProcess = false;

	noPromoCode = false;
	promocode = '';
	showPromocodeForm = false;

	addPromocodeError = '';

  constructor(private userserviceServiceObj: UserserviceService, private toastr: ToastrService) { }

  ngOnInit() {
  	this.fetchUserDetails();
  	this.showPromocodeForm = false;
  	this.noPromoCode = false;
  	this.addPromocodeError = '';
  }

  fetchUserDetails() {
    this.userserviceServiceObj.fetchUser()
    .then(
      data => {
        this.activeUser = data['data'];
        this.fetchUserPromocodes();
      },
      error => {
        this.isLoaded = true;
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }

  fetchUserPromocodes() {
	//moment.tz.setDefault('America/New_York');
  	this.isLoaded = false;
  	const data = {
  		'user_id' : this.activeUser.id
  	};
  	this.userserviceServiceObj.fetchMyPromoCodes(data)
    .then(
      data => {
      	this.isLoaded = true;
		
		for(var i=0; i<data['data'].promocodedetail.length;i++){
			var date = data['data'].promocodedetail[i].validto.split("-");
			 data['data'].promocodedetail[i].validto=date[1]+'/'+date[2]+'/'+date[0];
			}
        this.promoCodes = data['data'];
      },
      error => {
      	this.isLoaded = true;
      	this.noPromoCode = true;
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }
  // showAddPromoCode() {
  // 	if (this.showPromocodeForm) {
  // 		this.showPromocodeForm = false;
  // 		$('button.addPromoCodeFormBtn').text('Add New Promocode');
  //     this.formData = {};
  // 	} else {
  // 		this.showPromocodeForm = true;
  // 		$('button.addPromoCodeFormBtn').text('Close Form');
  // 	}
  // }
  addPromoCode(formData, form: NgForm) {
  	const data = {'promocode' : formData.promocode, 'user_id' : this.activeUser.id};
  	this.addPromocodeError = '';
    this.userserviceServiceObj.addPromoCode(data)
    .then(
      data => {
        if (!data['errors']) {
            form.resetForm();
            $('#txtSendTo').removeClass('focused');
            this.toastr.success('Promo Code Successfully Added!', 'Great!');
            // this.showAddPromoCode();
        	  this.fetchUserPromocodes();
        } else {
        }
      },
      error => {
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }

  deletePromoCode(promocode) {
    const data = {
      'promocode' : promocode, 
      'user_id' : this.activeUser.id
    };
    // this.addPromocodeError = '';
    this.userserviceServiceObj.deletePromoCode(data)
    .then(
      data => {
        if (!data['errors']) {
            this.toastr.success('Promo Code Successfully Deleted!', '');
            // this.showAddPromoCode();
            this.fetchUserPromocodes();
        } else {
        }
      },
      error => {
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }

}
