import { Component, OnInit } from '@angular/core';
import { StaticpagesService } from '../../services/staticpages.service';
import { Observable } from 'rxjs/Rx';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
@Component({
  selector: 'app-baseball',
  templateUrl: './baseball.component.html',
  styleUrls: ['./baseball.component.scss'],
  providers: [StaticpagesService]
})
export class BaseballComponent implements OnInit {

  page: String = 'baseball';
  staticPageContent: any = {};
  isLoadeds = false;
  isLoggedIn:boolean = false
isLoyality = false;
  constructor( private staticObj:StaticpagesService,private router: Router ) {
   
   }

  ngOnInit() {

    if(localStorage.getItem('is_loyalty_active') && localStorage.getItem('is_loyalty_active')=='1')
    {
      this.isLoyality = true
    }

    let access_token = localStorage.getItem('access_token');
    let refresh_token = localStorage.getItem('refresh_token');
if(access_token && refresh_token){
  this.isLoggedIn = true
}else {
  this.isLoggedIn = false
}
    this.isLoadeds=false;
    if(this.router.url.toLowerCase().includes('baseball')){
      this.page = 'baseball';
    }
    if(this.router.url.toLowerCase().includes('contest')){
      this.page = 'contest';
    }
    if(this.router.url.toLowerCase().includes('cares')){
      this.page = 'cares';
    }
    if(this.router.url.toLowerCase().includes('iconparksafe')){
      this.page = 'iconparksafe';
    }
   
    this.staticObj.getCmsPageContent(this.page).subscribe((data:any) => {
      this.staticPageContent = data.result[0];
      this.isLoadeds = true;
    }, (error) => { console.log('Error: ' + JSON.stringify(error));
      this.isLoadeds = true;
      }
  ); 
}

}
