import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers, RequestOptions, Response } from '@angular/http';

import { Observable } from 'rxjs/Rx';

// Import RxJs required methods
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { StaticpagesService } from '../services/staticpages.service';

@Component({
  selector: 'app-termsofservice',
  templateUrl: './termsofservice.component.html',
  styleUrls: ['./termsofservice.component.scss'],
  providers: [StaticpagesService]
})
export class TermsofserviceComponent implements OnInit {

  page: String = 'terms-of-service';
  staticPageContent: any = {};
  tremsService = false

  constructor(private router: Router, private staticObj: StaticpagesService) { }

  ngOnInit() {
    this.tremsService = true;
    this.staticObj.staticPagesContent(this.page).then(
      (res) => {
        this.staticPageContent = res;
        this.staticPageContent = this.staticPageContent.data;
      },
      (error) => { console.log('Error: ' + JSON.stringify(error)); }
    );
  }

}
