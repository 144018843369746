import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-remove-account',
  templateUrl: './remove-account.component.html',
  styleUrls: ['./remove-account.component.scss'],
  providers: [MyAccountMonthlyParkingService]
})
export class RemoveAccountComponent implements OnInit {

  @Input() currentActive: string;
  @Input() accountNumber: any;

  constructor(
  private routeObj: Router,
  private toastr: ToastrService,
  private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService) { }

  ngOnInit() {

  }

  deleteAccount(event) {
    this.myAccountMonthlyParkingObj.deleteAccount(this.accountNumber)
    .then(
      data => {
        this.toastr.success('Account Successfully Removed!', 'Great!');
        this.routeObj.navigate(['/my-account/monthly-parking']);
      },
      error => {
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }

}
