
import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-cancellation-notice',
  templateUrl: './cancellation-notice.component.html',
  styleUrls: ['./cancellation-notice.component.scss'],
  providers: [MyAccountMonthlyParkingService]
})

export class CancellationNoticeComponent implements OnInit {
  
  @Input() currentActive: string;
  @Input() accountNumber: any;

  formData: any = {};
  autopay = 0;
  successRes;
  isCancelled = false;
  showForm = true;
  cnNotify = false;
  minDate = new Date();
  termination_date:any = new Date();
  defaultTerminationDate: '';
  Validation = false;
  disableBtn = false;
  tenant_status: any = [];
  counter = 0 ;

  isLoaded = false;

  tenantDetails:any = {}; 

  cancel_reason:any = [
                    {
                    "reason_name": "Relocating Within NYC",
                    "id" : "1"
                    },
                    {
                    "reason_name": "No Longer Require Monthly Parking",
                    "id" : "2"
                    },
                    {
                    "reason_name": "Price",
                    "id" : "3"
                    },
                    {
                      "reason_name": "Service",
                      "id" : "4"
                      },
                    {
                    "reason_name": "Other",
                    "id" : "5"
                    }
                  ]            
                  
  constructor(
    private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {

    if(!localStorage.getItem('pageRefreshNew')){
     // window.location.reload(true);
      //localStorage.setItem("pageRefreshNew", "1");
    }

      this.termination_date = new Date(this.termination_date.setDate(this.termination_date.getDate() + 1));
      this.cnNotify = true;
      this.isCancelled = false;
      this.showForm = true;
      this.minDate = new Date(this.minDate.setDate(this.minDate.getDate() + 1 ));

      this.getTenantNumberDataCancelApi();
      
     
  }
  getTenantNumberDataCancelApi(){
    
  this.myAccountMonthlyParkingObj.monthlyCancellationRequest(this.accountNumber)
        .then(
          data => {     
            this.tenantDetails = data;
            this.successRes = true;
            this.isLoaded = true;
           
            var k = 0;
            for (var key in this.tenantDetails['data']) {
              var status = this.tenantDetails['data'][key].Status;
             
              if(status == '1' || status == '0'){
                 this.tenant_status[k] = k;
                 k++;
              }
             
          }
          if(typeof this.tenant_status != "undefined"){
          if(this.tenant_status.length == this.tenantDetails['data'].length){
             this.disableBtn = true;
          } 
        }
        

          },
          error => {
            this.successRes = false;
            this.isLoaded = true;
           
              this.toastr.error(error.errors.message, 'Sorry!');
            
          }
        );
      }


      
  // get tenant number of account
  
  updateLabel() {
    $('.termination_date_label').addClass('focused');
  }

  submitCancellation(formData, form: NgForm) {

     if (!this.termination_date){
       this.toastr.error("Please provide final day of parking", 'Error!');
     }else{
       if($('.cancel-options li input:checkbox:checked').length <= 0){
          this.toastr.error("Please select tenant number(s)", 'Error!');
        }else{
          if($('.cancel-reason-fields li input:checkbox:checked').length <= 0){
            this.toastr.error("please select a reason for cancelling", 'Error!');
          }else{
            this.Validation = true;
            //  if (!this.formData.comments){
            //     this.toastr.error("Please enter a comment", 'Error!');
            //   }else{
                    //this.Validation = true;
            //   }
          }
        }
     }

    
    if((this.termination_date) && ($('.cancel-reason-fields li input:checkbox:checked').length > 0) && ($('.cancel-options li input:checkbox:checked').length > 0)  ){
      var cancel_reason = [];
      var  tenant_val =[];
      
      
      
      $('.cancel-options li input:checkbox:checked').each(function(i){
        var dataId = $(this).attr('id');
        let Tenant_ID = dataId.split('-')[0];
        let Account_ID = dataId.split('-')[1]; 
        let Account_Code = $(this).attr('ng-reflect-name');
        let Tenant_Spot = $(this).attr('value');
        tenant_val[i] = {
                        ['Tenant_ID'] : Tenant_ID,
                        ['Account_ID'] : Account_ID,
                        ['Account_Code'] : Account_Code,
                        ['Tenant_Spot'] : Tenant_Spot,
                        };
        
        
      });
      $('.cancel-reason-fields li input:checkbox:checked').each(function(i){
        cancel_reason[i] = $(this).attr('id');
      });
      
          
      let newDate = new Date(this.termination_date);
      let day = newDate.getDate();
      let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
      let year = newDate.getFullYear();
      const terminationDate = year + '-' + month + '-' + day;
      
      let CancelRequestData = {
        "account_no" : this.accountNumber,
        "reasons" : cancel_reason,
        "termination_date" : terminationDate,
        "tenants" : tenant_val,
        "comments" : formData.comments
      }

      
      
      

      if(this.Validation==true){
      this.myAccountMonthlyParkingObj.CancelRequestWithTenantNumber(CancelRequestData)
      .then(
        data => {
          if(!data['errors']){
            //this.checkAutopay();
            this.toastr.success('Cancellation Request Submitted Successfully', 'Great!');
            this.showForm = false;
            this.isCancelled = true;
          } else {

          }
        }, error =>{
          this.toastr.error(error.errors.detail.termination_date[0], 'Sorry!');
        }
      );

    }  // end else
  }
  }

  // checkAutopay() {
  //   this.myAccountMonthlyParkingObj.autoPay(this.accountNumber)
  //   .then(
  //     data => {
  //       this.autopay = data['data'].autopay;
  //       this.showForm = false;
  //       this.isCancelled = true;
  //     },
  //     error => {
  //       this.toastr.error(error.errors.message, 'Sorry!');
  //     }
  //   );
  // }
}
