import { Directive, EventEmitter, ElementRef, Input,Output  } from '@angular/core';


declare var $:any;

@Directive({
  selector: '[app-color-picker]'
})
export class ColorPickerDirective {

  @Input() options:any;
  @Output() onColorChange = new EventEmitter();

  thisRef: any;

  constructor(private el: ElementRef) {
      setTimeout(() => {
        this.init();
      }, 200);
      this.thisRef = this;
  }

  init() {
    let thisRef = this.thisRef;

    $(this.el.nativeElement)
      .minicolors({
      	inline:true,
        change: function (value, opacity) {
          thisRef.onColorChange.emit(value);
        }
      });

  }

}
