import { Component, OnInit,AfterViewInit,ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CustomCode } from '../shared/CustomCode';
import { FormValidationErrors } from '../shared/formValidationErrors';
import * as $ from 'jquery';
declare var google: any;
import { UserserviceService } from '../services/userservice.service';
import { MyAccountMonthlyParkingService } from '../services/my-account-monthly-parking.service';
import { ToastrService } from 'ngx-toastr';
import { FacebookService, InitParams, LoginResponse,LoginOptions } from 'ngx-facebook';
import { FacebookAuthService } from '../services/facebook.service';
@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss'],
  providers: [UserserviceService, MyAccountMonthlyParkingService]
})
export class SocialLoginComponent implements OnInit {
 formData: any = {};
  private respdata;
  private parseData;
  public currentRoute = '';
  facilityRadius = CustomCode.facilityDefaultRadius;
  userDetails;
  promoCode;
  email:any;
  isLandingPage:boolean = false;
  loginForm: NgForm;
  bigApple;

  emailRegex = CustomCode.emailRegex;
  landingLocations:any = {};

  fbBtnIcon =  'assets/img/fb-btn.png';
  currDate = CustomCode.getDateForMapSearch();
  constructor(
      private routerObj: Router, 
      private userServiceObj: UserserviceService,
      private activatedRouteObj: ActivatedRoute,
      private toasterObj: ToastrService,
      private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
      private fb: FacebookService,
      private facebookAuthService: FacebookAuthService
  ) { 
      let initParams: InitParams = {
      appId: CustomCode.Fbkey,
      xfbml: true,
      version: 'v2.8'
      };
       fb.init(initParams);
  }

  ngOnInit() {
    //CustomCode.manageFormLabelsPosition();
    this.initializeLocationData();
    if (this.routerObj.url.indexOf('offer') > -1) {
      this.isLandingPage = true;
    }
    this.getVisitorCode();
    
  }
  fblogin()
  {
      let options: LoginOptions = {
          scope: 'public_profile,email',
          return_scopes: true,
          enable_profile_selector: true
          };

      this.fb.login(options)
      .then((res: LoginResponse) =>
      {
       if(res.status == 'connected') 
         {
           this.facebookAuthService.getUserFacebookProfileService(res.authResponse.accessToken)
           .subscribe( data => {
             
             this.formData = {'social_type':'0', 'social_id':data.id, 'name':data.name,'picture':data.picture.data.url}
             if (data != '' && data.email != undefined)
             {
                this.email = data.email;
                this.formData['email']=this.email
                this.userSignInWithSocial(this.formData);
             }
             else{
               this.userServiceObj.loginCheckSocialUser(data.id).subscribe(data=>{
                 if(data.data.is_email_found==1)
                 {
                   this.email = data.data.email;
                   this.formData['email']=this.email
                   this.userSignInWithSocial(this.formData);

                 }else{
                   localStorage.setItem('loginwithfb','1');
                   $('.normallogin').addClass('hide');
                    $('.fblogin').removeClass('hide');
                 }
               })
               
              }
              

           })
         }
       
    })
      .catch((error: any) => console.error(error));
  }
  loginFbAfterEmail(formValues)
  {
    localStorage.removeItem('loginwithfb');
    this.userSignInWithSocial(formValues);
  }

  initializeLocationData() {
      this.landingLocations['default'] = {
        'lat': CustomCode.timeSquareCoords.lat,
        'long': CustomCode.timeSquareCoords.long,
        'label': CustomCode.timeSquareCoords.defaultLocation,
        'type' : 'location'
      };

      this.landingLocations['all-nbh'] = {
        'slug': "central-park",
        'type' : 'neighborhood'
      };

      this.landingLocations['bryant-park'] = {
        'lat': 40.753597,
        'long': -73.983233,
        'label': "Bryant Park Winter Village, NY, United States",
        'type' : 'location'
      };
      this.landingLocations['rockefeller'] = {
        'lat': 40.758701,
        'long': -73.978595,
        'label': "Rockefeller Center Christmas Tree, NY, United States",
        'type' : 'location'
      };
      this.landingLocations['5th-ave'] = {
        'lat': 40.758074,
        'long': -73.977059,
        'label': "5th Ave Window Displays, NY, United States",
        'type' : 'location'
      };
      this.landingLocations['union-square'] = {
        'lat': 40.735863,
        'long': -73.991084,
        'label': "Union Square, NY, United States",
        'type' : 'location'
      };

      this.landingLocations['madison-square'] = {
        'lat': 40.7505085,
        'long': -73.9956274,
        'label': "Madison Square Garden Towers, New York, NY, United States",
        'type' : 'location'
      };

      this.landingLocations['times-square'] = {
        'lat': 40.7589,
        'long': -73.9851,
        'label': "Times Square, New York, NY, United States",
        'type' : 'location'
      };

      this.landingLocations['timessquare'] = {
        // 'lat': 40.7589,
        // 'long': -73.9851,
        // 'label': "Times Square, New York, NY, United States",
        'type' : 'neighborhood',
        'slug' : 'times-square'
      };

      this.landingLocations['midtown-west'] = {
        // 'lat': 40.7637581,
        // 'long': -73.99181809999999,
        // 'label': "Midtown West, New York, NY, United States",
        'type' : 'neighborhood',
        'slug' : 'midtown-west',
      };

      this.landingLocations['upper-west-side'] = {
        // 'lat': 40.7870106,
        // 'long': -73.97536760000003,
        // 'label': "Upper West Side, New York, NY, United States",
        'type' : 'neighborhood',
        'slug' : 'upper-west-side'
      };

      this.landingLocations['midtown-east'] = {
        // 'lat': 40.75403689999999,
        // 'long': -73.9668408,
        // 'label': "Midtown East, New York, NY, United States",
        'type' : 'neighborhood',
        'slug' : 'midtown-east'
      };
    }

  reset(formValues, formObj: NgForm) {
    $('.fblogin').addClass('hide');
    $('.normallogin').removeClass('hide');
    formObj.resetForm();
    if(localStorage.getItem('loginwithfb'))
    {
      this.cancelUsingFb()
    }
  }
  cancelUsingFb()
  {
    let currentUrl= window.location.href;
    let fbl=0
    let gml=0
    if(localStorage.getItem('loginwithfb'))
    {
      fbl=1
    }
    if(localStorage.getItem('loginwithgm'))
    {
      gml=1
    }
   CustomCode.userLogout();
   CustomCode.displayLoginUser();
     if(fbl)
      {
       
        this.fb.logout().then((res: LoginResponse)=> console.log('Logged out!'));
      }
    
    if (this.routerObj.url.indexOf('payment') > -1 || this.routerObj.url.indexOf('buy-monthly') > -1) {
      if(gml)
      {
        document.location.href = "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue="+CustomCode.BASEURL;
      }else{
          window.location.reload();
      }
     
    } else {

      if(gml)
      {
        document.location.href = "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue="+CustomCode.BASEURL;
      }else{
        window.location.href=CustomCode.BASEURL;
        //window.location.reload();
      }
      
    }
  }
  userSignIn(formValues, formObj: NgForm) {
    this.userServiceObj.loginUser(formValues)
      .subscribe(
      data => {
        this.parseData = data;
        if (this.parseData.status === 201) {
          formObj.resetForm();
          CustomCode.markLoginUser(this.parseData.data);
          const userLat = CustomCode.timeSquareCoords.lat;
          const userLong = CustomCode.timeSquareCoords.long;
          const userLocation = CustomCode.timeSquareCoords.defaultLocation;
          $('button.close').trigger('click');
          if (this.routerObj.url.indexOf('payment') > -1 || this.routerObj.url.indexOf('buy-monthly') > -1) {
            window.location.reload();
          } else {
            if (localStorage.getItem('is_landing_page') && this.routerObj.url.indexOf('offer') > -1 ) {
              let action_type = localStorage.getItem('action_type');
              let promocode = localStorage.getItem('promocode');
              this.promoCode = promocode
              let promotion_id = localStorage.getItem('promotion_id');
              let template_slug = localStorage.getItem('template_slug');
              let landing_page_url = "/offer/" + template_slug + "/" + promotion_id + "/" + promocode + "/" + action_type;
              if (this.routerObj.url.indexOf('offer') > -1) {
                if (localStorage.getItem('save')) {
                  if (action_type == 'save') {
                    this.fetchUserDetails();
                  } else {
                    window.location.reload();
                  }
                } else {
                  window.location.reload();
                }
              }
            } else {
              this.routerObj.navigate(['/my-account/account']);
            }
            // this.routerObj.navigate(['/search', userLat, userLong, this.currDate,
            // this.facilityRadius, userLocation, CustomCode.defaultFacilityType, CustomCode.searchMode()]);
          }
        }
      },
      error => {
        //FormValidationErrors.displayErrors(error.errors);
        const errorsList = error.errors;
        if (errorsList.message !== 'Validation Errors') {
          this.toasterObj.error(errorsList.message, 'Sorry!');
        } else {
          for (const key in errorsList.detail) {
            if (errorsList.detail.hasOwnProperty(key)) {
              if (typeof errorsList.detail === 'undefined') {
                this.toasterObj.error(errorsList.message, 'Sorry!');
              } else {
                this.toasterObj.error(errorsList.detail[key], 'Sorry!');
              }
            }
          }
        }
      }
      );
  }
  userSignInWithSocial(formValues) {
    
    this.userServiceObj.loginSocialUser(formValues)
      .subscribe(
      data => {
        this.parseData = data;
        if (this.parseData.status === 201) {
          CustomCode.markSocilaLoginUser(this.parseData.data);
          const userLat = CustomCode.timeSquareCoords.lat;
          const userLong = CustomCode.timeSquareCoords.long;
          const userLocation = CustomCode.timeSquareCoords.defaultLocation;
          $('button.close').trigger('click');
          localStorage.setItem('loginwithfb','1');
          if (this.routerObj.url.indexOf('payment') > -1 || this.routerObj.url.indexOf('buy-monthly') > -1) {
            window.location.reload();
          } else {
            if (localStorage.getItem('is_landing_page') && this.routerObj.url.indexOf('offer') > -1 ) {
              let action_type = localStorage.getItem('action_type');
              let promocode = localStorage.getItem('promocode');
              this.promoCode = promocode
              let promotion_id = localStorage.getItem('promotion_id');
              let template_slug = localStorage.getItem('template_slug');
              let landing_page_url = "/offer/" + template_slug + "/" + promotion_id + "/" + promocode + "/" + action_type;
              if (this.routerObj.url.indexOf('offer') > -1) {
                if (localStorage.getItem('save')) {
                  if (action_type == 'save') {
                    this.fetchUserDetails();
                  } else {
                    window.location.reload();
                  }
                } else {
                  window.location.reload();
                }
              }
            } else {
              this.routerObj.navigate(['/my-account/account']);
            }
            // this.routerObj.navigate(['/search', userLat, userLong, this.currDate,
            // this.facilityRadius, userLocation, CustomCode.defaultFacilityType, CustomCode.searchMode()]);
          }
        }
      },
      error => {
        //FormValidationErrors.displayErrors(error.errors);
        const errorsList = error.errors;
        if (errorsList.message !== 'Validation Errors') {
          this.toasterObj.error(errorsList.message, 'Sorry!');
        } else {
          for (const key in errorsList.detail) {
            if (errorsList.detail.hasOwnProperty(key)) {
              if (typeof errorsList.detail === 'undefined') {
                this.toasterObj.error(errorsList.message, 'Sorry!');
              } else {
                this.toasterObj.error(errorsList.detail[key], 'Sorry!');
              }
            }
          }
        }
      }
      );
  }
  fetchUserDetails() {
    this.myAccountMonthlyParkingObj.fetchUser()
    .then(
      data => {
        this.userDetails = data['data'];
        this.validatePromoCode('save');
      },
      error => {
      }
    );
  }

  validatePromoCode(mode) {
    let data = {
        'promocode': this.promoCode,
        'user_id' : this.userDetails.id
      };
    this.userServiceObj.validatePromoCode(data)
    .then(
      data => {
        if (data['data'].is_promocode_valid) {
          this.savePromocode();
        } else {
          this.toasterObj.error('Promocode has been expired', 'Sorry!');
          window.location.reload();
        }
      },
      error => {
        if (error.errors.message == "Please log in or create an account to redeem this promo code.") {
          this.toasterObj.error('Please login to your existing account or create a new one in order to save and redeem this promotion.', 'Sorry!');
        } else {
          this.toasterObj.error(error.errors.message, 'Sorry!');
          window.location.reload();
        }
      }
    );
  }

  savePromocode() {
    const data = {'promocode' : this.promoCode, 'user_id' : this.userDetails.id};
    this.userServiceObj.addPromoCode(data)
    .then(
      data => {
        if (!data['errors']) {
            this.toasterObj.success('Promocode successfully added, Please make a reservation to redeem this offer.', 'Great!');
            this.navidateToSearch();
        } else {
          this.toasterObj.error('Something wrong happened, try again later', 'Sorry!');
          window.location.reload();
        }
      },
      error => {
        // this.toasterObj.error(error.errors.message, 'Sorry!');
        this.navidateToSearch();
      }
    );
  }

  navidateToSearch() {
    let location = "times-square";
    if(localStorage.getItem('by_cms'))
    {
        location = localStorage.getItem('neighborhood');
        localStorage.removeItem('by_cms');
        this.navigateToLocation_test(location);
    }else
    {
      if (localStorage.getItem('neighborhood')) {
        location = localStorage.getItem('neighborhood');
      }
      if (this.landingLocations[location]) {
        if (this.landingLocations[location].type == "location") {
          this.navigateToLocation(this.landingLocations[location]);
        } else {
          this.navigateToNeighborhood(this.landingLocations[location]);
        }
      } else {
        this.navigateToLocation(this.landingLocations['default']);
      }
     }
  }

  navigateToLocation(details) {
      this.routerObj.navigate([
          '/search', 
          details.lat, 
          details.long, 
          CustomCode.getDateForMapSearch(), 
          CustomCode.facilityDefaultRadius, 
          details.label, 
          CustomCode.defaultFacilityType, 
          CustomCode.searchMode()
      ]);
    }
    
    navigateToLocation_test(details) {
      let details_location=JSON.parse(details);
      this.routerObj.navigate([
          '/search', 
          details_location.Lat, 
          details_location.Long, 
          CustomCode.getDateForMapSearch(), 
          CustomCode.facilityDefaultRadius, 
          details_location.label, 
          CustomCode.defaultFacilityType, 
          CustomCode.searchMode()
      ]);
    }

    navigateToNeighborhood(details) {
      this.routerObj.navigate(['/neighborhoods/', details.slug]);
    }

  showUserLogin() {

  }

  redirectToSignUp() {
    $('button.close').trigger('click');
    this.routerObj.navigate(['/create-account']);
  }
  
  navigateresetPassword() {
	    this.routerObj.navigate(['/reset-password']);
		  $('button.close').trigger('click');
      $('.sidenav').removeClass('open');
      $('body').removeClass('noscroll');
      $('.sidenavBackDrop').removeClass('open');
	  }

  continueAsGuest() {
    $('button.close').trigger('click');
    const userLat = CustomCode.timeSquareCoords.lat;
    const userLong = CustomCode.timeSquareCoords.long;
    const locationUser = CustomCode.timeSquareCoords.defaultLocation;
    this.routerObj.navigate(['/search', userLat, userLong, this.currDate, this.facilityRadius, locationUser, CustomCode.defaultFacilityType, CustomCode.searchMode()]);
  }
  getVisitorCode() {
   this.userServiceObj.getVisitorCode()
      .subscribe(
      data => {
        // var test = JSON.parse(data);
        
         localStorage.setItem('X-Big-Apple', data.data.visitor_code);
       },
      error => {
        let errorMessage = error.errors.message;
      }
    );
  }

}
