import { Component, OnInit, Input } from '@angular/core';
import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';

@Component({
  selector: 'app-previous-invoices',
  templateUrl: './previous-invoices.component.html',
  styleUrls: ['./previous-invoices.component.scss'],
  providers: [MyAccountMonthlyParkingService]
})
export class PreviousInvoicesComponent implements OnInit {

  @Input() currentActive: string;
  @Input() accountNumber: any;

  previousInvoices: any = [];
  currentPage: any = 1;
  imageCode: any;
  lastPage: any;
  nextPage: any = null;
  prevPage: any = null;
  nextPageUrl = '';

  isLoaded = false;
  prevPageUrl = '';
  showDialog = false;

  constructor(
      private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
      private toastr: ToastrService,
      private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getPreviousInvoices(this.currentPage);
  }

  getPreviousInvoices(pageNum) {
    this.myAccountMonthlyParkingObj.previousInvoices(this.accountNumber, pageNum)
    .then(
      data => {
        this.isLoaded = true;
        if (data['data'].data != '' && data['data'].data != null) {
          this.previousInvoices = data['data'].data;
          this.lastPage = data['data'].last_page;
          this.currentPage = data['data'].current_page;
          if (data['data'].next_page_url != null) {
            this.nextPageUrl = data['data'].next_page_url;
            this.nextPage = this.nextPageUrl.split('?page=')[1];
          } else {
            this.nextPage = null;
          }

          if (data['data'].prev_page_url != null) {
            this.prevPageUrl = data['data'].prev_page_url;
            this.prevPage = this.prevPageUrl.split('?page=')[1];
          } else {
            this.prevPage = null;
          }
        } else {
          this.toastr.error('Couldn\'t Find Any Previous Invoice For This Account', 'Sorry!');
        }
      },
      error => {
      }
    );
  }

  loadPreviousInvoices(pageNum) {
    this.getPreviousInvoices(pageNum);
  }

  receiveShowDialog($event) {
    this.showDialog = $event;
    if(this.showDialog == false){
      $('body').removeClass('modal-open');
    }
  }

  openPdf(date) {
    this.myAccountMonthlyParkingObj.previousInvoiceJpg(this.accountNumber, date)
    .then(
      data => {
        const urlCreator = window.URL;
        const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data['_body']));
        this.imageCode = blobData['changingThisBreaksApplicationSecurity'];
        $('body').addClass('modal-open');
        //window.open(this.imageCode);
        this.showDialog = true;
      },
      error => {
      }
    );
  }

  downloadInvoice(date) {
    this.myAccountMonthlyParkingObj.previousInvoicePdf(this.accountNumber, date)
    .then(
      data => {
      },
      error => {
      }
    );
  }
}
