
import { Pipe, PipeTransform } from '@angular/core';
import { CustomCode } from '../shared/CustomCode';

@Pipe({
  name: 'paymenttime'
})
export class PaymenttimePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const selectedD = new Date(value);
    const minutes =  (selectedD.getMinutes() < 10) ? '0' + selectedD.getMinutes() : selectedD.getMinutes();
    return (selectedD.getHours() > 12) ? (selectedD.getHours() - 12 + ':' + minutes + ' PM') : (selectedD.getHours() + ':' + minutes + ' AM');
  }

}
