import { Injectable } from '@angular/core';
//import { Response } from '@angular/http';

import { HttpService } from './http.service';
import { Observable } from 'rxjs/Rx';
import { CustomCode } from '../shared/CustomCode';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import {HttpClient,HttpHeaders} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  
  constructor(private http: HttpClient) { }
  private getFacilityUrl = CustomCode.APIURL + 'facility_llc';
  private versionUrl = CustomCode.APIURL + 'mobile-device-version/web';

  writeFacilities() {
    const options = CustomCode.returnHeaders();
    return this.http.get(this.getFacilityUrl, {headers:options})
      .map((res: any) =>res)
      .catch((error: any) => Observable.throw(error));
  }

  public loadFacilities(){
    const options = CustomCode.returnHeaders();

    new Promise((resolve, reject) => {
      const options = CustomCode.returnHeaders();
      this.http.get(this.versionUrl, {headers:options}).map( res => res ).catch((error: any):any => {
        //resolve(true);
        return Observable.throw(error.error || 'Server error');
      }).subscribe( (response:any) => {
        let res=response['data'].web_version
        //console.log(res+'323232323232');
        //localStorage.setItem('firsttest','1')
        // this.setCookie('versions',res,10000)
        //alert(res)
        resolve(response);
      },error => {
        reject(error);
      }
      );
    })
    return new Promise((resolve, reject) => {
      const options = CustomCode.returnHeaders();
      this.http.get(this.getFacilityUrl, {headers:options}).map( (res:any) => res ).catch((error: any):any => {
        //resolve(true);
        return Observable.throw(error.error || 'Server error');
      }).subscribe( (response:any) => {
        let facilities = response.data.facilities;
        let tmp = [];
        facilities.forEach(element => {
          if(element.active == '1'){
            let fc = {
              full_name:element.full_name.toLowerCase(),
              longitude:element.geolocations.longitude,
              latitude:element.geolocations.latitude
            }
            tmp.push(fc);
          }
        });
        CustomCode.facilityListSearch = tmp;
        resolve(response);
      },error => {
        reject(error);
      });
    });
  }
  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

}
