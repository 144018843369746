
import { Component, OnInit, Input,Output,ViewChild,EventEmitter,ElementRef,Pipe,PipeTransform} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient,HttpClientModule } from '@angular/common/http';

import { AutosuggestEventService } from '../../../../services/autosuggest-event.service';
import { GoogleMapComponent } from '../../../../Components/Global/google-map/google-map.component';
import { Ng2DeviceService } from 'ng2-device-detector';
import { UtilityService } from '../../../../services/utility.service';
declare var google: any;
// Import RxJs required methods
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser'
import { CustomCode } from '../../../../shared/CustomCode';
import * as $ from 'jquery';
import { FormControl,FormGroup,FormBuilder,ReactiveFormsModule } from '@angular/forms';
import { FilterHelper } from '../../../../classes/filter-helper';
@Pipe({ name: 'safeDate'})
export class SafeDatePipes implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    let tzones:any = new Date()
    let startDurati=tzones.toString()
    let locs = startDurati.search("GMT");
    let sbustring_timezone = tzones.toString().slice(parseInt(locs)+3,parseInt(locs)+8);
    let  txt2 = sbustring_timezone.slice(0, 3) + ":" + sbustring_timezone.slice(3)
    value = value+''+txt2
    let date = new Date(value)
    //date.setMinutes(date.getMinutes() + 240);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    let minutesR = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutesR + ' ' + ampm;
    return strTime;
  }
}
@Component({
  selector: 'app-google-autosuggest',
  templateUrl: './google-autosuggest.component.html',
  styleUrls: ['./google-autosuggest.component.scss'],
  providers: [ AutosuggestEventService, UtilityService]
})
export class GoogleAutosuggestComponent implements OnInit {
  @ViewChild(GoogleMapComponent,{static:true}) googlemapcomponent;
  defaultSearchLocation:any = {
      label: 'Times Square',
      latitude: 40.7589,
      longitude: -73.9851
    };

  @ViewChild("auto",{static:true})
  public autoSuggestElementDesktop: ElementRef;

  @ViewChild("automobile",{static:true})
  public autoSuggestElementMobile: ElementRef;


    currentRoute:any;
    @Input() isSideNav: string;
    @Input() widget:string;
    @Output() getLatLong = new EventEmitter<object>();
    @Output() iconClick = new EventEmitter<object>();
    private googleFetchUrl = "https://maps.googleapis.com/maps/api/geocode/json?address=";
    private googleFetchKey: string;
 
    findValue: String;
    mapProp;
    key:any;
    autosuggest=[]
    facilityRadius = 2;
    places=[]
    redirectTonewLocation:any=true;
    searchField: FormControl;
    currentTime:any=new Date();
    coolForm: FormGroup;
    currentHour:any=''
    currentEventTime:any=''
    currentDate:any;
    timeZoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
    deviceInfo=null
    todayDate:any;
    scrollTop:any;
    location_pin='assets/img/location_pin.svg'
    llc_pin='assets/img/flats.svg'
    searchIconSvg='/assets/img/search-icon.svg'
    facilities:any;
    filterFacilities:any;
    is_new_home_page:any=''
    showSearchIcon:any=true
    ispartner:any=false
  constructor(
    private deviceService: Ng2DeviceService,
    private router: Router,
    private activatedRouteObj: ActivatedRoute,
    private http: HttpClient,
    private autoSuggestEvent: AutosuggestEventService,
 
        private elementRef:ElementRef,
        private fb:FormBuilder,
        private utilityService:UtilityService
  ) {
    this.searchField = new FormControl();
     this.coolForm = fb.group({search: this.searchField});
 
   }
 

  ngOnInit() {
    
    setInterval(()=>{
    // p++;
      if(this.router.url.indexOf('monthly-parking')>-1)
      {
        if(localStorage.getItem('searchLocation'))
        {
          const searchValue = localStorage.getItem('searchLocation')
        
          this.findValue = searchValue

        }
      
      }
    },1000)
    if( this.router.url=='/' ||this.router.url.indexOf('/?')>-1 && localStorage.getItem('isHone')){
      console.log('find home');
      setTimeout(()=>{
        $('#auto_suggest_input_field').val('');
      },1000)
    }
    if (this.router.url.indexOf('search') > -1 || this.router.url.indexOf('newhome') > -1 || this.router.url=='/' ||this.router.url.indexOf('/?')>-1)
    {
      this.showSearchIcon=false
    }else{
      this.showSearchIcon=true;
    }
    if(this.router.url.indexOf('partners') > -1)
    {
      this.ispartner=true;
    } 
    if(CustomCode.facilityListSearch === undefined){
      this.utilityService.writeFacilities().subscribe(
        (data) => {
          let facilities = data.data.facilities;
          let tmp = [];
          facilities.forEach(element => {
            if(element.active == '1'){
              let fc = {
                full_name:element.full_name.toLowerCase(),
                longitude:element.geolocations.longitude,
                latitude:element.geolocations.latitude
              }
              tmp.push(fc);
            }
          });
          CustomCode.facilityListSearch = tmp;
          this.facilities = tmp;
        }
      );
    }else{
      this.facilities = CustomCode.facilityListSearch;
    }

    var getKeyCode = function (str) {
      return str.charCodeAt(str.length);
    }

    
    
    if(localStorage.getItem('searchLocation')){
      const searchValue = localStorage.getItem('searchLocation');
    
      $('#auto_suggest_input_field').val(searchValue);
    }

    setInterval(() => {
      
      if(localStorage.getItem('searchLocation') && localStorage.getItem('showNeighbordood')){
        const searchValueNew=localStorage.getItem('searchLocation');
        if(searchValueNew!='new-york')
        {
          $('#auto_suggest_input_field').val(searchValueNew);
          this.findValue = searchValueNew
        }
        

      }
      }, 1000);

    this.deviceInfo = this.deviceService.getDeviceInfo().browser;
    let currentTime = new Date();
    currentTime.setMinutes(Math.ceil(currentTime.getMinutes() + 120));
   let cuHours=("0" + (currentTime.getHours())).slice(-2); // => 9
    let cuMin=("0" + (currentTime.getMinutes())).slice(-2);; // =>  30
    let cuSec=("0" + (currentTime.getSeconds())).slice(-2);;
    this.currentEventTime =cuHours+':00:00'  // $(".selectAuto").focus(function(){
    //   this.selectionStart=0;
    //   this.selectionEnd=this.value.length;
    //   $(this).select();
    // });
    this.googleFetchKey = CustomCode.GlobalGoogleMapAPIKey;
   
    this.currentRoute = this.router.url;
    this.searchField.valueChanges
      .debounceTime(300)
      .switchMap(term => this.autoSuggestEvent.getRelatedEvents(term))
      .subscribe(data => {
        this.filterNewYorkSuggestions(this.searchField.value);
        this.filterNewYorkLLC(this.searchField.value);
        let input_val;
        let input_val1 = ''
        let input_val2 = ''
        this.todayDate = new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + new Date().getDate();

        if ($('#auto_suggest_input_field_side') !== undefined) {
          input_val1 = $('#auto_suggest_input_field_side').val();

          input_val2 = $('#auto_suggest_input_field').val().trim();

          if (input_val1 != '') {
            input_val = input_val1
          }
          if (input_val2 != '') {
            input_val = input_val2
          }
          if (this.searchField.value != undefined) {
            if (data.data.length > 0) {
              var height: any = $('.pac-container').height()
              let gg = parseInt(height) + 2
              this.autosuggest = data['data']
              $('.customevent').show();
              $('.relatedevent').show();
            }
            else {
              this.autosuggest = []
              $('.customevent').show();
              $('.relatedevent').hide();
            }
          }
          if (input_val == '') {
            this.autosuggest = [];
            $('.customevent').hide();
            $('.relatedevent').hide();
            $('.locationevent').hide();
          }
          if (input_val != undefined) {
            $('input').focusout(function (e) {
              setTimeout(() => {
                $('.customevent').hide();
                //$('#auto_suggest_input_field_side').val('');
                //$('#auto_suggest_input_field').val('');
                $('.pac-container').empty();
              }, 300);
            });
          }
        }
      });
    setTimeout(() => {
      $('.customevent').hide();
    },500);
  
  }
  
  ngAfterViewInit(){
    this.InitiliazeAutosuggest();
  }

  focusFunction($event){
    if (navigator.userAgent.match(/(iPod|iPhone)/i)) {
    let scrollTop     = $(window).scrollTop();
    let elementOffset = $('#auto_suggest_input_field').offset().top;
    let distance      = (elementOffset - scrollTop - 10);
    window.scrollTo(0,distance);
    }else{}
  }

  checkData(value)
  {
      localStorage.removeItem('getResult');
      localStorage.removeItem('searchLocation')
      if(value.key!='ArrowUp' && value.key!='ArrowDown')
      {
       //this.InitiliazeAutosuggest()     
      }
      let search
      if (this.isSideNav) {
          //search = $('#auto_suggest_input_field_side').val().trim();
        } else {
         
        }
        search = $('#auto_suggest_input_field').val().trim();
      if(search=='')
      {
          $('.customevent').hide();
          $('.relatedevent').hide();
          $('.locationevent').hide();
      }
  }
  filterNewYorkSuggestions(value) {
    var increment=0;
    let localplaces=[]
    this.places=[];
      $('.pac-container .pac-item').each(function() {
        if ($(this).text().indexOf('NY') > 0 || $(this).text().indexOf('New York') > 0 || $(this).text().indexOf('Newark') > -1 || $(this).text().indexOf('Boston') > -1 || $(this).text().indexOf('Prudential') > -1 ) {
            let loc=$(this).html().replace(/<\/span>/g,'').replace(/(<([^>]+)>)/ig, ' ').replace(/&amp;/g,"&");;
                    if(increment<5)
                    localplaces[increment++]=loc
        } else {
          $(this).remove();
        }
       
      });
      this.places=[];
                //$('.pac-container').empty();
            $('.customevent').show();
            $('.locationevent').show();
            this.places=localplaces
            localStorage.setItem('googleSuggest',JSON.stringify(this.places))
  }

  filterNewYorkLLC(value) {
    if(value!=='' && value!==undefined){
      let localplaces=[];
      this.filterFacilities = [];
      let fac = this.facilities;
      if(fac!== undefined){
        fac.forEach(element => {
          if(element.full_name.indexOf(value.toLowerCase()) > -1){
            let fc = {
              full_name:element.full_name,
              longitude:element.longitude,
              latitude:element.latitude
            }
            localplaces.push(fc);
          }
        });
        this.filterFacilities = localplaces;
        if((this.filterFacilities).length>0){
          $('.customevent').show();
          $('.locationllc').show();
          if(localStorage.getItem('getResult')){
            $('.locationllc').hide();
          }else {
            $('.locationllc').show();
            localStorage.setItem('getResult', '1');
          }
        }else{
          $('.locationllc').hide();
        }
      }
    }else{
      $('.locationllc').hide();
    }
  }

  redirectToSearchByLocationHamburg(id){
    
        localStorage.setItem('resetSearch','1');
        localStorage.removeItem('sortBy');
        localStorage.removeItem('showNeighbordood');
        $('.closeBtnSidenav').trigger('click');
        localStorage.setItem('searchbygoogleclick','1')
        $('.relatedevent').hide()
        $('.locationevent').hide()
        localStorage.removeItem('searchType');
        let addressText= $('#textham'+id).html().replace(/&amp;/g,"").trim();
        $('#auto_suggest_input_field_side').val(addressText)
        let address = addressText.split(" ").join("+");
        $.get("https://maps.googleapis.com/maps/api/geocode/json?address="+address+"+NY&key="+ this.googleFetchKey, ( data ) => {
        let googleResults = data.results;
        let resultLength = googleResults.length;
        if (googleResults && resultLength > 0) {
        let newLat = googleResults[0].geometry.location.lat;
        let newLong = googleResults[0].geometry.location.lng;
        this.triggerSearchLocationHamburg(newLat, newLong, addressText);
        localStorage.setItem('locationNew',addressText);
        localStorage.setItem('latNew',newLat);
        localStorage.setItem('longNew',newLong);
        //this.triggerSearchLocation(newLat, newLong, newLoc);
        } else {
        //this.triggerSearchLocation(40.7589, -73.9851, 'Times Square');
        }});
   }
  redirectToSearchByLocation(id){

        localStorage.setItem('searchbygoogleclick','1');
        localStorage.setItem('resetSearch','1')
        $('.relatedevent').hide()
        $('.locationevent').hide()
        localStorage.removeItem('searchType');
        localStorage.removeItem('sortBy');
        localStorage.removeItem('showNeighbordood');
        let addressText= $('#text'+id).html().replace(/&amp;/g,"").trim();
        $('#auto_suggest_input_field').val(addressText)
        this.findValue=addressText
        let address = addressText.split(" ").join("+");
        $.get("https://maps.googleapis.com/maps/api/geocode/json?address="+address+"+NY&key="+ this.googleFetchKey, ( data ) => {
        let googleResults = data.results;
        let resultLength = googleResults.length;
        if (googleResults && resultLength > 0) {
          let newLat = googleResults[0].geometry.location.lat;
          let newLong = googleResults[0].geometry.location.lng;
          if(this.widget=='1')
          {
            $('#auto_suggest_input_field').val(addressText)
            var latLong={'lat':newLat,'long':newLong,'location':addressText}
            this.getLatLong.emit(latLong)
          }
          else{
            this.triggerSearchLocation(newLat, newLong, addressText);
          }
          localStorage.setItem('locationNew',addressText);
          localStorage.setItem('latNew',newLat);
          localStorage.setItem('longNew',newLong);
        }});
    }

    redirectToSearchByLocationLlc(id){
      localStorage.removeItem('sortBy');
      localStorage.removeItem('showNeighbordood');
      localStorage.setItem('searchbygoogleclick','1')
      localStorage.setItem('resetSearch','1');
      localStorage.setItem('SearchLLC','1')
      $('.relatedevent').hide()
      $('.locationevent').hide()
      localStorage.removeItem('searchType');
      let addressText= $('#llctext'+id).html().replace(/&amp;/g,"").trim();
      let newLat= $('#llclat'+id).html().replace(/&amp;/g,"").trim();
      let newLong= $('#llclong'+id).html().replace(/&amp;/g,"").trim();
      localStorage.setItem('locationNew',addressText);
      localStorage.setItem('latNew',newLat);
      localStorage.setItem('longNew',newLong);
      if (this.router.url.indexOf('search') > -1 || this.router.url.indexOf('newhome') > -1 || this.router.url=='/' ||this.router.url.indexOf('/?')>-1)
      {
        $('#auto_suggest_input_field').val(addressText)
        this.findValue=addressText
        
        ///return false;
      }else{
        $('#auto_suggest_input_field').val('')
      }
      
      let address = addressText.split(" ").join(" ");
      this.triggerSearchLocation(newLat, newLong, address);
      $('.locationllc').hide();
    }
    
    redirectToSearchByEvent(id)
    {
        localStorage.removeItem('sortBy');
        localStorage.setItem('resetSearch','1');
        localStorage.removeItem('showNeighbordood');  
        localStorage.setItem('searchbygoogleclick','1');
        localStorage.setItem('searchbygoogleclickEvent','1');
        $('.relatedevent').hide()
        $('.locationevent').hide()
        let lat= $('#lat'+id).html()
        let venue= $('#venue'+id).html().trim().replace(/&amp;/g,"");
        $('#auto_suggest_input_field').val(venue);
        let long= $('#long'+id).html()
        let stime= $('#stime'+id).html().trim();
        let hourFromStime=stime.substr(11,2)
        let minusTime=parseInt(hourFromStime)-2;
 
        if(minusTime<0)
        {
          minusTime=23;
        }
        let twoMinusTime = ("0" + (minusTime)).slice(-2)
        let beforeHourTime=stime.substr(0,11)
        let afterHourTime=stime.substr(13,6);
        if(parseInt(afterHourTime.substr(1,2))>0 && parseInt(afterHourTime.substr(1,2))<29)
        {
          afterHourTime=':30:00'
        }
        if(parseInt(afterHourTime.substr(1,2))>30 && parseInt(afterHourTime.substr(1,2))<59)
        {
          afterHourTime=':00:00'
        }
        stime=beforeHourTime+''+twoMinusTime+''+afterHourTime;
        
        let etime= $('#etime'+id).html().trim();
        let starttime = new Date(stime);
        let endtime:any='';
        let curr=new Date();
        
        if(etime=='T')
        {
            endtime = stime;
        }else{
            endtime =etime
        }
        localStorage.setItem('searchType','event');
        localStorage.setItem('endtime',endtime);
        if(venue=='Yankee Stadium Station, New York, NY, USA' || venue.toLowerCase()=='yankee' || venue.toLowerCase()=='yankee stadium' )
        {
         //window.open("https://yankees.iconparkingsystems.com/yankee-reservations/", "_blank");
        }
        //sthis.router.navigate(['/search', lat, long, CustomCode.getDateForMapSearchSlashFormatForUrlForEvent(moveDate), this.facilityRadius, venue,'daily', CustomCode.searchMode()]);
       if(window.self!==window.top)
        {
                    localStorage.setItem('locationNew',venue);
                    localStorage.setItem('latNew',lat);
                    localStorage.setItem('longNew',long);
                    if(localStorage.getItem('isHone'))
                    {
                      window.location.href='/search/'+lat+'/'+long+'/'+stime+'/'+this.facilityRadius+'/'+venue+'/daily/'+CustomCode.searchMode()      //setTimeout(this.myFunction, 2000);
                    }else {  }
        }else{
          if (0)
                  {
                    
                    localStorage.setItem('seachLat',lat)
                    localStorage.setItem('seachLong',long)
                    localStorage.setItem('seachLocation',venue)
                    localStorage.setItem('locationNew',venue);
                    localStorage.setItem('latNew',lat);
                    localStorage.setItem('longNew',long);
                    if(localStorage.getItem('is_ham')=='1')
              {
                  this.navigate();
              }
                  }else{
                    localStorage.setItem('locationNew',venue);
                    localStorage.setItem('latNew',lat);
                    localStorage.setItem('longNew',long);
                    localStorage.removeItem('is_ham')
                    if(localStorage.getItem('isHone'))
                    {
                      this.router.navigate(['/search', lat, long, stime, this.facilityRadius, venue,'daily', CustomCode.searchMode()]);
                    }else {  }
                  }
          return;
          //window.location.href='/search/'+lat+'/'+long+'/'+stime+'/'+this.facilityRadius+'/'+venue+'/daily/'+CustomCode.searchMode()
        }
    }
  InitiliazeAutosuggest() {
    // this.loadAutoC();
    this.autoI();
    // $('auto_suggest_input_field_side').val(' ');
    // $('auto_suggest_input_field').val(' ');
  }
  loadAutoC() {
       //google.maps.event.addDomListener($(window), 'load', this.autoI());
    //  this.autoI();
           }
 
  autoI() {
    
      const newYorkBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(40.518673, -74.266017),
        new google.maps.LatLng(40.861947, -73.785519)
      );
 
       const options = {
          bounds: newYorkBounds,
          //types: ['geocode'],
          componentRestrictions: { country: 'us' }
        };
 

    if (this.isSideNav) {
      this.mapProp = new google.maps.places.Autocomplete(this.autoSuggestElementMobile.nativeElement, options);
    } else {
      this.mapProp = new google.maps.places.Autocomplete(this.autoSuggestElementDesktop.nativeElement, options);
    }
 
      google.maps.event.addListener(this.mapProp, 'place_changed', () => {
        //$('.pac-container').remove();
      const place = this.mapProp.getPlace();
        if(place!=undefined)
        {
          if(this.currentRoute!='/widget-form')
              {
                  if (place.geometry && place.geometry) {
              const userLat = place.geometry.location.lat();
              const userLong = place.geometry.location.lng();
              let userLoc;
              if (this.isSideNav) {
                userLoc = $('#auto_suggest_input_field_side').val();
              } else {
                userLoc = $('#auto_suggest_input_field').val();
              }
             
              $('.closeBtnSidenav').click();
         
             // this.triggerSearchLocation(userLat, userLong, userLoc);
              } else {
                  this.getManualLatLong(place.name);
              }
            }
            else{
 
                if (place.geometry && place.geometry)
                {
                  const userLat = place.geometry.location.lat();
                  const userLong = place.geometry.location.lng();
                  let userLoc;
                  if (this.isSideNav) {
                    userLoc = $('#auto_suggest_input_field_side').val();
                  } else {
                    userLoc = $('#auto_suggest_input_field').val();
                 }
                  var latLong={'lat':userLat,'long':userLong,'location':userLoc}
                  this.getLatLong.emit(latLong)
                }
 
            }
        }
       
      
      
      });
 
  }
 
  getManualLatLong(loc) {
 
    let address = loc.split(" ").join("+");
    $.get(this.googleFetchUrl + address + "+NY&key=" + this.googleFetchKey, ( data ) => {
      let googleResults = data.results;
      let resultLength = googleResults.length;
      if (googleResults && resultLength > 0) {
        let newLat = googleResults[0].geometry.location.lat;
        let newLong = googleResults[0].geometry.location.lng;
        let newLoc = address.split("+").join(" ");
        this.triggerSearchLocation(newLat, newLong, newLoc);
     } else {
        this.triggerSearchLocation(40.7589, -73.9851, 'Times Square');
      }
    });
  }
 
  triggerSearchLocation(lat, long, loc) {
    (<HTMLInputElement>document.getElementById('userlat')).value = lat;
    (<HTMLInputElement>document.getElementById('userlong')).value = long;
    (<HTMLInputElement>document.getElementById('locationname')).value = loc;
    $('.closeBtnSidenav').trigger('click');
    this.searchLocation('');
  }
  triggerSearchLocationHamburg(lat, long, loc) {
    $('.closeBtnSidenav').trigger('click');
    (<HTMLInputElement>document.getElementById('userlat')).value = lat;
    (<HTMLInputElement>document.getElementById('userlong')).value = long;
    (<HTMLInputElement>document.getElementById('locationname')).value = loc;
    $('.closeBtnSidenav').trigger('click');
    this.searchLocationHamburg('')
  }
 
  searchLocation(type) {
       if(localStorage.getItem('searchbygoogleclick'))
       {
         
       //  return
        localStorage.removeItem('searchbygoogleclick')
        let input_val=''
       let input_val1=''
        let input_val2=''
        input_val1 =''
        input_val2 =$('#auto_suggest_input_field').val().trim();
        if(input_val1!='')
        {
          input_val =input_val1
        }
        if(input_val2!='')
        {
         input_val =input_val2
        }
        $('.relatedevent').hide()
        $('.locationevent').hide()
        if (type === '') {

            if(this.router.url.indexOf('daily')>-1)
            {
              type='daily';
            }else{
              type='monthly'
            }
        }
        if (type == "" || type == null) {
            type = CustomCode.defaultFacilityType;
        }
        let userLat = (<HTMLInputElement>document.getElementById('userlat')).value;
        let userLong = (<HTMLInputElement>document.getElementById('userlong')).value;
        let locationUser = '';
        if(input_val=='')
        {
           locationUser = (<HTMLInputElement>document.getElementById('locationname')).value;
        }else{
           locationUser = input_val;
        }
 
        let usedFirstTime=1;
        if(input_val.toLowerCase()=="yankees" || input_val=="yankees" || input_val.toLowerCase()=="yankee's" || input_val=="yankee's" || input_val=='Yankee Stadium Station, New York, NY, USA' || input_val.toLowerCase()=='yankee' || input_val.toLowerCase()=='yankee stadium' )
        {
          userLat='40.8296426'
          userLong='-73.9261745'
          locationUser = input_val;
          usedFirstTime=0;
         // window.open("https://yankees.iconparkingsystems.com/yankee-reservations/", "_blank");
        }
       
        if (!userLat || !userLong || !locationUser || locationUser == 'Times Square, New York, NY, United States') {
          userLat = CustomCode.timeSquareCoords.lat;
          userLong = CustomCode.timeSquareCoords.long;
          locationUser = (locationUser == 'Times Square, New York, NY, United States')?locationUser:CustomCode.timeSquareCoords.defaultLocation;
          }
        if(usedFirstTime==1)
        {
           if(locationUser.toLowerCase()=="yankees" || locationUser=="yankees" || locationUser.toLowerCase()=="yankee's" || input_val=="yankee's" || locationUser=='Yankee Stadium Station, New York, NY, USA' || locationUser.toLowerCase()=='yankee' || locationUser.toLowerCase()=='yankee stadium' )
            {
            // window.open("https://yankees.iconparkingsystems.com/yankee-reservations/", "_blank");
            }
        }
        if(window.self!==window.top)
        {
          window.location.href='/search/'+userLat+'/'+userLong+'/'+CustomCode.getDateForMapSearchSlashFormatNew()+'/'+this.facilityRadius+'/'+locationUser+'/'+type+'/app'
        }else{
          if (this.router.url.indexOf('search') > -1 || this.router.url.indexOf('newhome') > -1 || this.router.url=='/' ||this.router.url.indexOf('/?')>-1)
          {
            localStorage.setItem('seachLat',userLat)
            localStorage.setItem('seachLong',userLong)
            localStorage.setItem('seachLocation',locationUser)
            if(localStorage.getItem('is_ham')=='1')
              {
                  this.navigate();
              }
          }else{
            localStorage.removeItem('is_ham')
            this.router.navigate(['/search', userLat, userLong, CustomCode.getDateForMapSearchSlashFormatNew(), this.facilityRadius, locationUser,type, CustomCode.searchMode()]);
          }
            return;
        //window.location.href='/search/'+userLat+'/'+userLong+'/'+CustomCode.getDateForMapSearchSlashFormat()+'/'+this.facilityRadius+'/'+locationUser+'/'+type+'/'+CustomCode.searchMode()
        }
        $('.sidenav').removeClass('open');
        $('.sidenavBackDrop').removeClass('open');
        $('body').removeClass('noscroll');
      }
      else{
        this.centerLocation(type);
      }
    }
   
    searchLocationHamburg(type) {
       $('.closeBtnSidenav').trigger('click');
       $('.sidenav').removeClass('open')
       $('.sidenavBackDrop').removeClass('open')
       if(localStorage.getItem('searchbygoogleclick'))
       {
        localStorage.removeItem('searchbygoogleclick')
       let input_val=''
        let input_val1=''
        let input_val2=''
        input_val1 =$('#auto_suggest_input_field_side').val().trim();
        input_val2 =''
        if(input_val1!='')
        {
          input_val =input_val1
        }
        if(input_val2!='')
        {
          input_val =input_val2
        }
        $('.relatedevent').hide()
        $('.locationevent').hide()
        if (type === '') {
            type = $('label[name="monthlytype"].active').text().trim().toLowerCase();
        }
        if (type == "" || type == null) {
            type = CustomCode.defaultFacilityType;
        }
        let userLat = (<HTMLInputElement>document.getElementById('userlat')).value;
        let userLong = (<HTMLInputElement>document.getElementById('userlong')).value;
        let locationUser = '';
        if(input_val=='')
        {
           locationUser = (<HTMLInputElement>document.getElementById('locationname')).value;
        }else{
           locationUser = input_val;
        }
        let usedFirstTime=1;
        if(input_val.toLowerCase()=="yankees" || input_val=="yankees" || input_val.toLowerCase()=="yankee's" || input_val=="yankee's" || input_val=='Yankee Stadium Station, New York, NY, USA' || input_val.toLowerCase()=='yankee' || input_val.toLowerCase()=='yankee stadium' )
        {
          userLat='40.8296426'
          userLong='-73.9261745'
          locationUser = input_val;
          usedFirstTime=0;
          //window.open("https://yankees.iconparkingsystems.com/yankee-reservations/", "_blank");
        }
       
        if (!userLat || !userLong || !locationUser || locationUser == 'Times Square, New York, NY, United States') {
          userLat = CustomCode.timeSquareCoords.lat;
          userLong = CustomCode.timeSquareCoords.long;
          locationUser = (locationUser == 'Times Square, New York, NY, United States')?locationUser:CustomCode.timeSquareCoords.defaultLocation;
          }
        if(usedFirstTime==1)
        {
           if(locationUser.toLowerCase()=="yankees" || locationUser=="yankees" || locationUser.toLowerCase()=="yankee's" || input_val=="yankee's" || locationUser=='Yankee Stadium Station, New York, NY, USA' || locationUser.toLowerCase()=='yankee' || locationUser.toLowerCase()=='yankee stadium' )
            {
            // window.open("https://yankees.iconparkingsystems.com/yankee-reservations/", "_blank");
            }
        }
        if(window.self!==window.top)
        {
          window.location.href='/search/'+userLat+'/'+userLong+'/'+CustomCode.getDateForMapSearchSlashFormatNew()+'/'+this.facilityRadius+'/'+locationUser+'/'+type+'/app'
        }else{
          if (this.router.url.indexOf('search') > -1 || this.router.url.indexOf('newhome') > -1 || this.router.url=='/' ||this.router.url.indexOf('/?')>-1)
                  {
                    localStorage.setItem('seachLat',userLat)
                    localStorage.setItem('seachLong',userLong)
                    localStorage.setItem('seachLocation',locationUser)
                    if(localStorage.getItem('is_ham')=='1')
              {
                  this.navigate();
              }
                  }else{
                    localStorage.removeItem('is_ham')
                    
                    this.router.navigate(['/search', userLat, userLong, CustomCode.getDateForMapSearchSlashFormatNew(), this.facilityRadius, locationUser,type, CustomCode.searchMode()]);
                  }
                    return;
         // window.location.href='/search/'+userLat+'/'+userLong+'/'+CustomCode.getDateForMapSearchSlashFormat()+'/'+this.facilityRadius+'/'+locationUser+'/'+type+'/'+CustomCode.searchMode()
        }
       
        $('.sidenav').removeClass('open');
        $('.sidenavBackDrop').removeClass('open');
        $('body').removeClass('noscroll');
      }
      else{
        this.centerLocationHamburg(type);
      }
    }
    centerLocation(type){
      if(this.router.url.indexOf('monthly-parking')>-1){
        localStorage.removeItem('showNeighbordood');
        localStorage.removeItem('sortBy');
       }
      this.activatedRouteObj.params.subscribe((params: Params) => {
        if (params['lat'] && params['long'] ) {
          let userLat = params['lat']
          let userLong = params['long']
          let type=params['type'];
          let locationUser =  params['label'];
          this.currentDate=params['currdate']
          if(localStorage.getItem('widgetStartTime'))
          {
            let widgetStartvalue=''
          }
          else{
            localStorage.setItem('widgetStartTime',params['currdate'])
          }
          if (!userLat || !userLong || !locationUser) {
            userLat = CustomCode.timeSquareCoords.lat;
            userLong = CustomCode.timeSquareCoords.long;
            locationUser = CustomCode.timeSquareCoords.defaultLocation;
          }
        let searchType = params['type'];
          if(typeof searchType === 'undefined') {
            searchType = 'daily';
          }
        let input_val2 =$('#auto_suggest_input_field').val().trim();
        if(localStorage.getItem('searchLocation')==params['label'].trim())
        {
        //  this.redirectTonewLocation=false
        //  this.router.navigate(['/search', userLat, userLong, CustomCode.getDateForMapSearchSlashFormat(), 3, locationUser, searchType, CustomCode.searchMode()]);
        this.redirectTonewLocation=false
        if(!localStorage.getItem('searchbygoogleclickEvent'))
        {
          if (this.router.url.indexOf('search') > -1 || this.router.url.indexOf('newhome') > -1 || this.router.url=='/' ||this.router.url.indexOf('/?')>-1)
                  {
                    localStorage.setItem('seachLat',userLat)
                    localStorage.setItem('seachLong',userLong)
                    localStorage.setItem('seachLocation',locationUser)
                    if(localStorage.getItem('is_ham')=='1')
                    {
                      this.navigate();
                    }
                    
                  }else{
                    localStorage.removeItem('is_ham')
                    this.router.navigate(['/search', userLat, userLong, CustomCode.getDateForMapSearchSlashFormatNew(), 2, locationUser, searchType, CustomCode.searchMode()]);
                  }
        }
      }else{
          this.redirectTonewLocation=true
          this.redirectAfterClickonsearchButton(type)
        }
     
     }else if(params['location']){
         let array = JSON.parse(localStorage.getItem('neighbourhoodLocation'))
         if(array.type.indexOf('monthly') > -1){
          array.type = 'monthly'
         }else {
          array.type = 'daily';
         }
        
         let name=''
         let namesArray=params['location'].split('-')
         for(var count = 0; count < namesArray.length; count++){
               name +=this.toTitleCase(namesArray[count])+' '
            }
            if (this.router.url.indexOf('search') > -1 || this.router.url.indexOf('newhome') > -1 || this.router.url=='/' ||this.router.url.indexOf('/?')>-1)
            {
              localStorage.setItem('seachLat',array.lat)
              localStorage.setItem('seachLong',array.long)
              localStorage.setItem('seachLocation',name)
              if(localStorage.getItem('is_ham')=='1')
              {
                  this.navigate();
              }
            }else{ 
              localStorage.removeItem('is_ham')  
        this.router.navigate(['/search',array.lat, array.long, CustomCode.getDateForMapSearchSlashFormatNew(), this.facilityRadius, name, array.type, CustomCode.searchMode()]);
      } }
     else{
       this.redirectTonewLocation=true
       this.redirectAfterClickonsearchButton(type)
     }
   });
    
    //ng, this.currDate, this.facilityRadius, locationUser, searchType, CustomCode.searchMode()]);userlat
  }
   centerLocationHamburg(type){
    $('.closeBtnSidenav').trigger('click');
      this.activatedRouteObj.params.subscribe((params: Params) => {
        if (params['lat'] && params['long'] ) {
          let userLat = params['lat']
          let userLong = params['long']
          let type=params['type'];
          let locationUser =  params['label'];
          if (!userLat || !userLong || !locationUser) {
            userLat = CustomCode.timeSquareCoords.lat;
            userLong = CustomCode.timeSquareCoords.long;
            locationUser = CustomCode.timeSquareCoords.defaultLocation;
          }
        let searchType = params['type'];
          if(typeof searchType === 'undefined') {
            searchType = 'daily';
          }
        let input_val2 =$('#auto_suggest_input_field').val().trim();
        if(localStorage.getItem('searchLocation')==params['label'].trim())
        {
         this.redirectTonewLocation=false
         //$('.closeBtnSidenav').trigger('click');
         //window.location.href='/search/'+userLat+'/'+userLong+'/'+CustomCode.getDateForMapSearchSlashFormat()+'/'+this.facilityRadius+'/'+locationUser+'/'+type+'/'+CustomCode.searchMode()
         if (this.router.url.indexOf('search') > -1 || this.router.url.indexOf('newhome') > -1 || this.router.url=='/' ||this.router.url.indexOf('/?')>-1)
                  {
                    localStorage.setItem('seachLat',userLat)
                    localStorage.setItem('seachLong',userLong)
                    localStorage.setItem('seachLocation',locationUser)
                    if(localStorage.getItem('is_ham')=='1')
              {
                  this.navigate();
              }
                  }else{
                    localStorage.removeItem('is_ham')
                    
         this.router.navigate(['/search', userLat, userLong, CustomCode.getDateForMapSearchSlashFormatNew(), this.facilityRadius, locationUser, searchType, CustomCode.searchMode()]);
                  } 
         return
        }else{
          this.redirectTonewLocation=true
          this.redirectAfterClickonsearchButtonHamburg(type)
        }
     
     }else if(params['location']){
         let array = JSON.parse(localStorage.getItem('neighbourhoodLocation'))
         if (this.router.url.indexOf('search') > -1 || this.router.url.indexOf('newhome') > -1 || this.router.url=='/' || this.router.url.indexOf('/?')>-1)
                  {
                    localStorage.setItem('seachLat',array.lat)
                    localStorage.setItem('seachLong',array.long)
                    localStorage.setItem('seachLocation',params['location'])
                    if(localStorage.getItem('is_ham')=='1')
              {
                  this.navigate();
              }
                  }else{
                    localStorage.removeItem('is_ham')
                    
                    this.router.navigate(['/search',array.lat, array.long, CustomCode.getDateForMapSearchSlashFormatNew(), this.facilityRadius, params['location'], array.type, CustomCode.searchMode()]);
                  }            
      }
     else{
       this.redirectTonewLocation=true
       this.redirectAfterClickonsearchButtonHamburg(type)
     }
   });
    
    //ng, this.currDate, this.facilityRadius, locationUser, searchType, CustomCode.searchMode()]);userlat
  }
   redirectAfterClickonsearchButton(type)
  {
   if (type === '')
    {
      type = $('label[name="monthlytype"].active').text().trim().toLowerCase();
      if(type.indexOf('monthly')>-1)
      {
        type='monthly'
      }else{
        type='daily';
      }
    }
    if (type == "" || type == null) {
      type = CustomCode.defaultFacilityType;
     }
    $('.relatedevent').hide()
    $('.locationevent').hide()
    let input_val=''
    let input_val1 =''
    let input_val2 =$('#auto_suggest_input_field').val().trim();
    if(input_val1!='')
    {
       input_val =input_val1
    }
        if(input_val2!='')
        {
          input_val =input_val2
        }
        let array = JSON.parse(localStorage.getItem('googleSuggest'))
        if(input_val.toLowerCase()=="yankees" || input_val=="yankees" || input_val.toLowerCase()=="yankee's" || input_val=="yankee's" || input_val=='Yankee Stadium Station, New York, NY, USA' || input_val.toLowerCase()=='yankee' || input_val.toLowerCase()=='yankee stadium' )
        {
         // window.open("https://yankees.iconparkingsystems.com/yankee-reservations/", "_blank");
        }
        if(input_val=='')
        {
          let userLat = CustomCode.timeSquareCoords.lat;
          let userLong = CustomCode.timeSquareCoords.long;
          let locationUser = 'Times Square, New York, NY, United States'
          if(window.self!==window.top)
        {
          window.location.href='/search/'+userLat+'/'+userLong+'/'+CustomCode.getDateForMapSearchSlashFormatNew()+'/'+this.facilityRadius+'/'+locationUser+'/'+type+'/app'
        }else{
          //window.location.href='/search/'+userLat+'/'+userLong+'/'+CustomCode.getDateForMapSearchSlashFormatNew()+'/'+this.facilityRadius+'/'+locationUser+'/'+type+'/'+CustomCode.searchMode()
          if (this.router.url.indexOf('search') > -1 || this.router.url.indexOf('newhome') > -1 || this.router.url=='/' ||this.router.url.indexOf('/?')>-1)
                  {
                    localStorage.setItem('seachLat',userLat)
                    localStorage.setItem('seachLong',userLong)
                    localStorage.setItem('seachLocation',locationUser)
                    if(localStorage.getItem('is_ham')=='1')
              {
                  this.navigate();
              }
                  }else{
                    localStorage.removeItem('is_ham')
                    
                      this.router.navigate(['/search', userLat, userLong, CustomCode.getDateForMapSearchSlashFormatNew(), this.facilityRadius, locationUser,type, CustomCode.searchMode()]);
                   }
                    return;
        }
        
        }
        if(array.length)
        {
 
          let addressText=array[0].replace(/&amp;/g,"").trim();;
          if (input_val.indexOf('NY') > 0 || input_val.indexOf('New York') > 0 || input_val.indexOf('Newark') > -1 || input_val.indexOf('Boston') > -1 || input_val.indexOf('Prudential') > -1) {
               addressText=input_val.replace(/&amp;/g,"");;
          }else{
               addressText=array[0].replace(/&amp;/g,"");;
          }
          localStorage.removeItem('searchType');
          
          $('#auto_suggest_input_field').val(addressText)
           let address = addressText.split(" ").join("+");
           $.get("https://maps.googleapis.com/maps/api/geocode/json?address="+address+"+NY&key="+ this.googleFetchKey, ( data ) => {
          let googleResults = data.results;
          let resultLength = googleResults.length;
          if (googleResults && resultLength > 0) {
          let newLat = googleResults[0].geometry.location.lat;
          let newLong = googleResults[0].geometry.location.lng;
          if(this.widget=='1')
          {
            $('#auto_suggest_input_field').val(addressText)
            
            var latLong={'lat':newLat,'long':newLong,'location':addressText}
            this.getLatLong.emit(latLong)
          }
          else{
              if(this.redirectTonewLocation)
              {
                if(window.self!==window.top)
                {
                  window.location.href='/search/'+newLat+'/'+newLong+'/'+CustomCode.getDateForMapSearchSlashFormatNew()+'/'+this.facilityRadius+'/'+addressText+'/'+type+'/app'
                }else{
                  if (this.router.url.indexOf('search') > -1 || this.router.url.indexOf('newhome') > -1 || this.router.url=='/' ||this.router.url.indexOf('/?')>-1)
                  {
                    localStorage.setItem('seachLat',newLat)
                    localStorage.setItem('seachLong',newLong)
                    localStorage.setItem('seachLocation',addressText)
                    if(localStorage.getItem('is_ham')=='1')
              {
                  this.navigate();
              }
                  }else{
                    localStorage.removeItem('is_ham')
                    
                  this.router.navigate(['/search', newLat, newLong, CustomCode.getDateForMapSearchSlashFormatNew(), this.facilityRadius, addressText,type, CustomCode.searchMode()]);
                  }
                  return;
                  //window.location.href='/search/'+newLat+'/'+newLong+'/'+CustomCode.getDateForMapSearchSlashFormatNew()+'/'+this.facilityRadius+'/'+addressText+'/'+type+'/'+CustomCode.searchMode()
                }
              
              }
             $('.sidenav').removeClass('open');
             $('.sidenavBackDrop').removeClass('open');
             $('body').removeClass('noscroll');
          }
 
         }});
        }
        else{
           let userLat = CustomCode.timeSquareCoords.lat;
          let userLong = CustomCode.timeSquareCoords.long;
          let locationUser = 'Times Square, New York, NY, United States'
          if(this.redirectTonewLocation)
          {
            if(window.self!==window.top)
            {
              this.activatedRouteObj.params.subscribe((params: Params) => {
                if (params['lat'] && params['long'] ) {
                  let userLat = params['lat']
                  let userLong = params['long']
                  let type=params['type'];
                  let locationUser =  params['label'];
                  window.location.href='/search/'+userLat+'/'+userLong+'/'+ localStorage.getItem('widgetStartTime')+'/'+this.facilityRadius+'/'+locationUser+'/'+type+'/app'
                }
              });
            }else{
                  //window.location.href='/search/'+userLat+'/'+userLong+'/'+CustomCode.getDateForMapSearchSlashFormatNew()+'/'+this.facilityRadius+'/'+locationUser+'/'+type+'/'+CustomCode.searchMode()
                  if (this.router.url.indexOf('search') > -1 || this.router.url.indexOf('newhome') > -1 || this.router.url=='/' ||this.router.url.indexOf('/?')>-1)
                  {
                    localStorage.setItem('seachLat',userLat)
                    localStorage.setItem('seachLong',userLong)
                    localStorage.setItem('seachLocation',locationUser)
                    if(localStorage.getItem('is_ham')=='1')
              {
                  this.navigate();
              }
                  }else{
                  localStorage.removeItem('is_ham')
                 
                  this.router.navigate(['/search', userLat, userLong, CustomCode.getDateForMapSearchSlashFormatNew(), this.facilityRadius, locationUser,type, CustomCode.searchMode()]);
                  
                  }
                  return;
                }
           
          }
       
        }
 
  }
   redirectAfterClickonsearchButtonHamburg(type)
 {
    $('.closeBtnSidenav').trigger('click');
    if (type === '')
    {
      type = $('label[name="monthlytype"].active').text().trim().toLowerCase();
    }
    if (type == "" || type == null) {
      type = CustomCode.defaultFacilityType;
     }
    $('.relatedevent').hide()
    $('.locationevent').hide()
    let input_val=''
    let input_val1 =$('#auto_suggest_input_field_side').val().trim();
    let input_val2 =''
    if(input_val1!='')
    {
       input_val =input_val1
    }
        if(input_val2!='')
        {
          input_val =input_val2
        }
        let array = JSON.parse(localStorage.getItem('googleSuggest'))
        if(input_val.toLowerCase()=="yankees" || input_val=="yankees" || input_val.toLowerCase()=="yankee's" || input_val=="yankee's" || input_val=='Yankee Stadium Station, New York, NY, USA' || input_val.toLowerCase()=='yankee' || input_val.toLowerCase()=='yankee stadium' )
        {
          //window.open("https://yankees.iconparkingsystems.com/yankee-reservations/", "_blank");
        }
        if(input_val=='')
        {
          let userLat = CustomCode.timeSquareCoords.lat;
          let userLong = CustomCode.timeSquareCoords.long;
          let locationUser = 'Times Square, New York, NY, United States'
          if(window.self!==window.top)
                {
                  window.location.href='/search/'+userLat+'/'+userLong+'/'+CustomCode.getDateForMapSearchSlashFormatNew()+'/'+this.facilityRadius+'/'+locationUser+'/'+type+'/app'
                }else{
                  if (this.router.url.indexOf('search') > -1 || this.router.url.indexOf('newhome') > -1 || this.router.url=='/' ||this.router.url.indexOf('/?')>-1)
                  {
                    localStorage.setItem('seachLat',userLat)
                    localStorage.setItem('seachLong',userLong)
                    localStorage.setItem('seachLocation',locationUser)
                    if(localStorage.getItem('is_ham')=='1')
              {
                  this.navigate();
              }
                  }else{
                    localStorage.removeItem('is_ham')
                   
                    this.router.navigate(['/search', userLat, userLong, CustomCode.getDateForMapSearchSlashFormatNew(), this.facilityRadius, locationUser,type, CustomCode.searchMode()]);
                  }
                  return;
                 // window.location.href='/search/'+userLat+'/'+userLong+'/'+CustomCode.getDateForMapSearchSlashFormatNew()+'/'+this.facilityRadius+'/'+locationUser+'/'+type+'/'+CustomCode.searchMode()
                }
         
        }
        if(array.length)
        {
 
          let addressText=array[0].replace(/&amp;/g,"").trim();;
          if (input_val.indexOf('NY') > 0 || input_val.indexOf('New York') > 0 || input_val.indexOf('Newark') > -1 || input_val.indexOf('Boston') > -1 || input_val.indexOf('Prudential') > -1) {
               addressText=input_val.replace(/&amp;/g,"");;
          }else{
               addressText=array[0].replace(/&amp;/g,"");;
          }
          localStorage.removeItem('searchType');
          $('#auto_suggest_input_field').val(addressText)
          
           let address = addressText.split(" ").join("+");
           $.get("https://maps.googleapis.com/maps/api/geocode/json?address="+address+"+NY&key="+ this.googleFetchKey, ( data ) => {
          let googleResults = data.results;
          let resultLength = googleResults.length;
          if (googleResults && resultLength > 0) {
          let newLat = googleResults[0].geometry.location.lat;
          let newLong = googleResults[0].geometry.location.lng;
          if(this.widget=='1')
          {
            $('#auto_suggest_input_field').val(addressText)
            
            var latLong={'lat':newLat,'long':newLong,'location':addressText}
            this.getLatLong.emit(latLong)
          }
          else{
              if(this.redirectTonewLocation)
              {
                if(window.self!==window.top)
                {
                  window.location.href='/search/'+newLat+'/'+newLong+'/'+CustomCode.getDateForMapSearchSlashFormatNew()+'/'+this.facilityRadius+'/'+addressText+'/'+type+'/app'
                }else{
                  if (this.router.url.indexOf('search') > -1 || this.router.url.indexOf('newhome') > -1 || this.router.url=='/' ||this.router.url.indexOf('/?')>-1)
                  {
                    localStorage.setItem('seachLat',newLat)
                    localStorage.setItem('seachLong',newLong)
                    localStorage.setItem('seachLocation',addressText)
                    if(localStorage.getItem('is_ham')=='1')
              {
                  this.navigate();
              }
                  }else{
                    localStorage.removeItem('is_ham')
                   
                  this.router.navigate(['/search', newLat, newLong, CustomCode.getDateForMapSearchSlashFormatNew(), this.facilityRadius, addressText,type, CustomCode.searchMode()]);
                  }
                  return;
                 // window.location.href='/search/'+newLat+'/'+newLong+'/'+CustomCode.getDateForMapSearchSlashFormatNew()+'/'+this.facilityRadius+'/'+addressText+'/'+type+'/'+CustomCode.searchMode()
                }
              
              }
             $('.sidenav').removeClass('open');
             $('.sidenavBackDrop').removeClass('open');
             $('body').removeClass('noscroll');
          }
 
         }});
        }
        else{
           let userLat = CustomCode.timeSquareCoords.lat;
          let userLong = CustomCode.timeSquareCoords.long;
          let locationUser = 'Times Square, New York, NY, United States'
          if(this.redirectTonewLocation)
          {
            if(window.self!==window.top)
                {
                  window.location.href='/search/'+userLat+'/'+userLong+'/'+CustomCode.getDateForMapSearchSlashFormatNew()+'/'+this.facilityRadius+'/'+locationUser+'/'+type+'/app'
                }else{
                  if (this.router.url.indexOf('search') > -1 || this.router.url.indexOf('newhome') > -1 || this.router.url=='/' ||this.router.url.indexOf('/?')>-1)
                  {
                    localStorage.setItem('seachLat',userLat)
                    localStorage.setItem('seachLong',userLong)
                    localStorage.setItem('seachLocation',locationUser)
                    if(localStorage.getItem('is_ham')=='1')
              {
                  this.navigate();
              }
                  }else{
                    localStorage.removeItem('is_ham')
                    
                    this.router.navigate(['/search', userLat, userLong, CustomCode.getDateForMapSearchSlashFormatNew(), this.facilityRadius, locationUser,type, CustomCode.searchMode()]);
                  }
                  return;
                 // window.location.href='/search/'+userLat+'/'+userLong+'/'+CustomCode.getDateForMapSearchSlashFormatNew()+'/'+this.facilityRadius+'/'+locationUser+'/'+type+'/'+CustomCode.searchMode()
                }
           
          }
       
        }
 
  }
  toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
replaceText(text)
{
  return text.replace('Llc','LLC')
}

navigate()
  {
    localStorage.removeItem('showNeighbordood');
    let stime =localStorage.getItem('startimehome')
    //let estime =localStorage.getItem('endttimehome')
    $('.closeBtnSidenav').trigger('click');
    let exacTime = FilterHelper.defaultStartDurationUrlHomePageData(FilterHelper.changeNyTime());
    let startTime = FilterHelper.getFormattedTime(exacTime);
    let exacttimeaftert=CustomCode.getDateForMapSearchSlashFormatHome(exacTime,startTime)
    let exacttimeaftertNewDate=new Date(exacttimeaftert);
    let selecttimrNewDate=new Date(stime);
    if(this.toTimestamp(selecttimrNewDate)<this.toTimestamp(exacttimeaftertNewDate))
    {

      let startDuration:any = FilterHelper.defaultStartDuration();
      let startTime = FilterHelper.getFormattedTime(startDuration);
      localStorage.setItem('monthlyDate',startDuration)
      let endDuration = FilterHelper.defaultEndDuration();
      let endTime = FilterHelper.getFormattedTime(endDuration);
      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(endDuration,endTime));
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(startDuration,startTime));
    }
    stime =localStorage.getItem('startimehome')
    let lat:any=40.7589
    let long:any =-73.9851
    let location:any ='Times Square'
    
    if(localStorage.getItem('seachLat'))
    {
      lat =localStorage.getItem('seachLat')
      long =localStorage.getItem('seachLong')
      location =localStorage.getItem('seachLocation')
    }

    let typeMode=localStorage.getItem('typeMode');
    if(this.router.url.indexOf('search')>-1)
    {
      if(this.router.url.indexOf('daily')>-1)
      {
        typeMode='daily';
      }else{
        typeMode='monthly';
      }
    }
    if(this.router.url.indexOf('search')>-1 || this.router.url.indexOf('monthly-parking')>-1 )
    {
      //this.router.navigate(['/search', lat, long, stime, 2, location,typeMode,CustomCode.searchMode()]);
      setTimeout(()=>{
        this.iconClick.emit();
      },1000)
    }else{
      this.router.navigate(['/search', lat, long, stime, 2, location,typeMode,CustomCode.searchMode()]);
    }
   
    // if(this.router.url.indexOf('search')>-1 || this.router.url.indexOf('monthly-parking')>-1 )
    // {
    //   localStorage.setItem('navigatesearch','1')
    // }
    // this.router.navigate(['/search', lat, long, stime, 2, location,localStorage.getItem('typeMode'),CustomCode.searchMode()]);
  }
  toTimestamp(strDate){
    var datum = Date.parse(strDate);
    return datum/1000;
  }

  
 
}
 
 