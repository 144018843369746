import { Component, OnInit, Input } from '@angular/core';

import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';

@Component({
  selector: 'app-current-invoice',
  templateUrl: './current-invoice.component.html',
  styleUrls: ['./current-invoice.component.scss'],
  providers: [MyAccountMonthlyParkingService]
})
export class CurrentInvoiceComponent implements OnInit {

  @Input() currentActive: string;
  @Input() accountNumber: any;

  currentInvoice: any = [];
  invoiceDate: any;
  billTotel: any;

  imageCode: any;

  isLoaded = false;
  showDialog = false;
  noInvoice:boolean = false;

  constructor(
    private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getCurrentInvoice();
  }

  receiveShowDialog($event) {
    this.showDialog = $event;
    if(this.showDialog == false){
      $('body').removeClass('modal-open');
    }
  }

  getCurrentInvoice() {
    this.myAccountMonthlyParkingObj.currentInvoice(this.accountNumber)
    .then(
      data => {
        this.isLoaded = true;
        if (data['data'] != '' && data['data'] != null) {
          this.invoiceDate = data['data'][0].invoice_date;
          this.billTotel = data['data'][0].bill_total;
          this.currentInvoice = data['data'];
          this.noInvoice = false;
        } else {
          this.noInvoice = true;
          this.toastr.error('Couldn\'t Find Any Current Invoice For This Account', 'Sorry!');
        }
      },
      error => {
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }

  openPdf(event) {
    this.myAccountMonthlyParkingObj.currentInvoiceJpg(this.accountNumber)
    .then(
      data => {
        const urlCreator = window.URL;
        const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data['_body']));
        this.imageCode = blobData['changingThisBreaksApplicationSecurity'];
        $('body').addClass('modal-open');
        //window.open(this.imageCode);
        this.showDialog = true;
      },
      error => {
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );

  }

  downloadInvoice(event) {
    this.myAccountMonthlyParkingObj.currentInvoicePdf(this.accountNumber, this.invoiceDate)
    .then(
      data => {
       
      },
      error => {
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }

}
